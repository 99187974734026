export default {
  absenceType: '',
  absenceTypeFormat: '',
  startDate: '',
  endDate: '',
  days: '',
  reincorporation_date: '',
  observation: '',
  employeeId: ''
};
