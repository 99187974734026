import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAbsenceRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/absences', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexUserAbsenceRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/absences/user_index', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAbsenceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/absences', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const createMassiveAbsenceRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', '/absences/massive_create', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const updateAbsenceRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/absences/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAbsenceRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/absences/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const exportAbsencesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/absences/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportAbsenceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/absences/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importAbsenceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/absences/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateAbsenceRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/absences/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};
export const indexAllAbsenceRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/absences/index_all', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexAbsenceRequest = AwesomeDebouncePromise(indexAbsenceRequest, 300);
