import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { useAbility } from '@casl/react';
import { FormikInput, Icon, UploadImage } from '../../components';
import { requestSignIn } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { resetPasswordRequest, verifyTokenRequest } from '../../requests/utils';
import { AbilityContext } from '../../config/abilityContext';
import { updateAbility } from '../../config/ability';
import PasswordChecklist from './PasswordChecklist';

const ResetPassword = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    currentEmployee,
    signedIn,
    user,
    history,
    location,
    setFieldValue
  } = props;

  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const ability = useAbility(AbilityContext);
  const view = new URLSearchParams(location.search).get('view');

  const redirect = () => {
    if (signedIn) {
      updateAbility(ability);
      if (user.role === 'admin' && !currentEmployee) {
        history.replace('admin/companies');
      } else {
        history.replace('/profile/dashboard');
      }
    }
  };

  const handleInputPassword = e => {
    setNewPassword(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const handleDisableButton = toogle => {
    setIsDisable(!toogle);
  };

  const handleVerifyToken = () => {
    const { dispatch } = props;
    const token = new URLSearchParams(location.search).get('token');
    verifyTokenRequest({
      dispatch,
      params: {
        token,
      },
      failureCallback: (error) => {
        const { response } = error
        dispatch(sendAlert({ kind: 'error', message: response?.data?.error }));
        history.replace('/login')
      },
      successCallback: () => { }
    });
  }

  useEffect(redirect, [signedIn, user, currentEmployee]);

  useEffect(handleVerifyToken, []);

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={4}>
        <Form className="d-flex flex-column justify-content-end mt-5">
          <h3 className="text-uppercase">{view === 'create' ? 'CREAR' : 'RECUPERAR'} CONTRASEÑA</h3>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
                onChange={e => handleInputPassword(e)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowPassword(!showPassword)}
              />
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showPasswordConfirmation ? 'text' : 'password'}
                placeholder="Confirmar Contraseña"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              />
            )}
          </Field>
          {view === 'create' && (
            <>
              <h3 className="text-uppercase">CREAR PIN DE FIRMA DIGITAL</h3>
              <Field name="passwordSignature">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    placeholder="PIN"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
              <Field name="passwordSignatureConfirmation">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    placeholder="Confirmar PIN"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
              <h3 className="text-uppercase">DATOS DEL COLABORADOR</h3>
              <Field name="employeeProfileImage">
                {({ field }) => (
                  <UploadImage
                    {...field}
                    circleImage
                    name="Adjuntar foto del colaborador."
                    onChange={avatar => setFieldValue(field.name, avatar)}
                    fileAccept=".png, .jpg, .gif, .jpeg"
                    helpText="Formato sugerido 400x400px de máximo 10mb."
                  />
                )}
              </Field>
              <Field name="nationalIdSerial">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Número de serie o documento"
                    inputType="text"
                    placeholder="123456789"
                    maxLength={9}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </>
          )}
          <PasswordChecklist password={newPassword} achieveRequirements={handleDisableButton} />
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4 ml-auto"
            type="submit"
            onClick={onHide}
            disabled={isDisable}
          >
            {view === 'create' ? 'CREAR' : 'RECUPERAR'} CONTRASEÑA
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn, currentEmployee, user } = state.auth;
  return {
    ongoingRequest,
    signedIn,
    currentEmployee,
    user
  };
};

const initialValues = props => {
  const { location } = props;
  const view = new URLSearchParams(location.search).get('view');

  return {
    password: '',
    passwordConfirmation: '',
    passwordSignature: '',
    passwordSignatureConfirmation: '',
    view,
    nationalIdSerial: '',
    employeeProfileImage: {}
  };
};
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Este campo es obligatorio'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  passwordSignature: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
  }),
  passwordSignatureConfirmation: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .oneOf([Yup.ref('passwordSignature'), null], 'Las contraseñas no coinciden')
  }),
  nationalIdSerial: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .min(8, 'El serial debe tener al menos 9 dígitos')
      .matches(/^[a-zA-Z0-9]*$/, 'Deben ser caracteres alfanuméricos')
  })
});

const handleFailureRequest = (error, dispatch) => {
  const { response } = error;
  dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
};

const handleSubmit = (values, { props }) => {
  const { dispatch, location } = props;
  const token = new URLSearchParams(location.search).get('token');
  const view = new URLSearchParams(location.search).get('view');

  resetPasswordRequest({
    dispatch,
    params: {
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      password_signature: values.passwordSignature,
      password_signature_confirmation: values.passwordSignatureConfirmation,
      employee_national_id_serial: values.nationalIdSerial,
      employee_image: values.employeeProfileImage,
      token,
      view
    },
    formData: true,
    failureCallback: error => {
      handleFailureRequest(error, dispatch);
    },
    successCallback: result => {
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
      dispatch(
        requestSignIn({
          user: {
            login: result.data.user.email,
            password: values.password
          }
        })
      );
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: props => initialValues(props),
      validationSchema,
      handleSubmit
    })(ResetPassword)
  )
);
