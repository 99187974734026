import ApiService from '../services/apiService';

export const legalreportOvertimeReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/legalreports/overtime_reports/overtime_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const legalAttendanceReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/legalreports/attendance_reports/attendance_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const legalreportOffdaysReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/legalreports/holiday_reports/offdays_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const legalreportWorkdayReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/legalreports/workday_reports/workinghours_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const legalreportShiftReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/legalreports/shift_reports/shifts_modification${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const markingCorrectionAttendanceReport = ({
  dispatch,
  params,
  format,
  responseType,
  successCallback,
  callback
}) => {
  ApiService.request('get', `/legalreports/marking_correction_reports/index${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const markingCorrectionUpdate = ({
  dispatch,
  params,
  successCallback,
  callback
}) => {
  ApiService.request('post', `/legalreports/marking_correction_reports/update`, {
    dispatch,
    params,
    successCallback,
    callback
  });
};