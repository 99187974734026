export const periods = [
  { label: 'Primer trimestre', value: 'first_quarter' },
  { label: 'Segundo trimestre', value: 'second_quarter' },
  { label: 'Tercer trimestre', value: 'third_quarter' },
  { label: 'Cuarto trimestre', value: 'fourth_quarter' },
  { label: 'Primer semestre', value: 'first_semester' },
  { label: 'Segundo semestre', value: 'second_semester' },
  { label: 'Anual', value: 'annual' }
];

export const statuses = [
  { label: 'Pendiente', value: 'pending' },
  { label: 'Terminada', value: 'finished' }
];

export const departments = [
  { label: 'Departamento 1', value: 'department1' },
  { label: 'Departamento 2', value: 'department2' },
  { label: 'Departamento 3', value: 'department3' }
];

export const statusEmployeeDnc = [
  { label: 'Pendiente', value: 'pending' },
  { label: 'Aceptado', value: 'approved' },
  { label: 'Rechazado', value: 'rejected' }
];

export const levelsEmployeeDnc = [
  { label: 'Baja', value: 'low' },
  { label: 'Media', value: 'medium' },
  { label: 'Alta', value: 'high' }
];
