import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { Tab, Nav, Row, Col, Dropdown } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useSetTab } from '../../../services/hooks';
import { createAnnouncementRequest } from '../../../requests/announcements';
import { SimpleCenteredModal, Icon } from '../../../components';
import AnnouncementFeed from './AnnouncementFeed';
import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import AnnouncementCalendar from './AnnouncementCalendar';
import AnnouncementForm from '../../Announcement/AnnouncementForm';
import announcement from '../../Announcement/announcement';

const AnnouncementIndex = () => {
  const ability = useAbility(AbilityContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const [key, setKey] = useSetTab('posts', location);
  const { currentEmployee } = useSelector(state => state.auth);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleCreateRequest = (announcementValues, setSubmitting) => {
    const { image } = announcementValues.announcement;
    const myParams = snakeCaseKeys(announcementValues);
    myParams.announcement.image = image;
    createAnnouncementRequest({
      dispatch,
      params: myParams,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Anuncio creado con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      callback: () => setSubmitting(false)
    });
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <Row className="m-top mb-3">
        <Col>
          <h2>Comunicación</h2>
        </Col>
        <Col md={2}>
          <Dropdown alignRight className="shift-dropdown z-index-1">
            {(currentEmployee && ability.can('create', 'Announcement') && ability.can('boss', 'Employee')) ||
            (currentEmployee && ability.can('create', 'CompanyTask') && ability.can('boss', 'Employee')) ? (
              <Dropdown.Toggle className="dropdown-no-arrow mt-3 w-100">
                Nuevo
                <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
              </Dropdown.Toggle>
            ) : null}
            <Dropdown.Menu>
              {ability.can('create', 'Announcement') && <Dropdown.Item onClick={toggleModal}>Anuncio</Dropdown.Item>}
              {ability.can('create', 'CompanyTask') && (
                <Dropdown.Item as={Link} to="/profile/tasks/new">
                  Tarea
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter unmountOnExit>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="posts">
              Mural
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="calendar">
              Calendario
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="posts">
            <AnnouncementFeed moreData={moreData} currentEmployee={currentEmployee} />
          </Tab.Pane>
          <Tab.Pane eventKey="calendar">
            <AnnouncementCalendar moreData={moreData} setMoreData={setMoreData} currentEmployee={currentEmployee} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title="Nueva Fecha Destacada"
        body={
          <AnnouncementForm
            announcement={{ ...announcement, employeeId: currentEmployee, status: 'visible' }}
            action="new"
            boss
            formRequest={handleCreateRequest}
          />
        }
        show={modalShow}
        onHide={toggleModal}
      />
    </>
  );
};

export default AnnouncementIndex;
