import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { NavbarLanding, FooterLanding } from '../../components';

const LandingLayout = ({ module, action, resource, component: Screen, ...otherProps }) => (
  <>
    <Route
      {...otherProps}
      render={rest => (
        <>
          <NavbarLanding />
          <Container fluid>
            <Screen {...rest} />
          </Container>
          <FooterLanding />
        </>
      )}
    />
  </>
);

export default LandingLayout;
