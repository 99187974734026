import ApiService from '../services/apiService';

export const indexDirectoryNodesRequest = ({ employeeId, dispatch, params = {}, callback, successCallback }) => {
  ApiService.request('get', `/employees/${employeeId}/directory_nodes`, {
    dispatch,
    params,
    callback,
    successCallback
  });
};

export const createDirectoryNodeRequest = ({
  employeeId,
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) => {
  ApiService.request('post', `/employees/${employeeId}/directory_nodes`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const deleteDirectoryNodeRequest = (id, { employeeId, dispatch, callback, successCallback }) =>
  ApiService.request('delete', `/employees/${employeeId}/directory_nodes/${id}`, {
    dispatch,
    callback,
    successCallback
  });

// Files Requests
export const createDirectoryFilesRequest = (
  id,
  { employeeId, dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('post', `/employees/${employeeId}/directory_nodes/${id}/create_files`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const deleteDirectoryFileRequest = ({ params, dispatch, callback, successCallback, failureCallback }) =>
  ApiService.request('delete', '/directory_nodes/destroy_file', {
    params,
    dispatch,
    successCallback,
    failureCallback,
    callback
  });

export const updateFolderRequest = (id, { employeeId, dispatch, params, callback, successCallback }) =>
  ApiService.request('put', `/employees/${employeeId}/directory_nodes/${id}`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const downloadDirectoryNodeRequest = (id, { employeeId, dispatch, callback, successCallback }) =>
  ApiService.request('get', `/employees/${employeeId}/directory_nodes/${id}/export.xlsx`, {
    dispatch,
    callback,
    responseType: 'text',
    successCallback
  });

export const exportDirectoryNodeRequest = (id, { employeeId, dispatch, callback, successCallback }) =>
  ApiService.request('get', `/employees/${employeeId}/directory_nodes/${id}/download.zip`, {
    dispatch,
    callback,
    responseType: 'blob',
    successCallback
  });

export const importTemplateDirectoryNodeRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/directory_nodes/import_template`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });

export const preImportDirectoryNodeRequest = (id, { employeeId, dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/employees/${employeeId}/directory_nodes/${id}/pre_import`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const importDirectoryNodeRequest = (id, { employeeId, dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('post', `/employees/${employeeId}/directory_nodes/${id}/import`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const massiveImportTemplateDirectoryNodeRequest = ({ dispatch, successCallback, callback }) =>
  ApiService.request('get', `/directory_nodes/massive_import_template`, {
    dispatch,
    successCallback,
    responseType: 'text',
    callback
  });

export const massivePreImportDirectoryNodeRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('get', `/directory_nodes/massive_pre_import`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const massiveImportDirectoryNodeRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', `/directory_nodes/massive_import`, {
    dispatch,
    params,
    formData: true,
    failureCallback,
    successCallback,
    callback
  });
