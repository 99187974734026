import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const SettlementStepMap = ({ setStep, step, steps }) => {
  const drawLine = (stepNumber, compareNumber, lineMobileNone) => {
    const lineNone = stepNumber === compareNumber ? 'line-none' : '';
    const lineXsNone = lineMobileNone ? 'line-xs-none' : '';
    return <span className={`custom-step-line ${lineNone} ${lineXsNone}`} />;
  };

  return (
    <Row className="text-uppercase my-4 d-flex justify-content-center">
      {steps.map((stepName, index) => {
        const stepNumber = index + 1;
        const isPar = stepNumber % 2 === 0;
        return (
          <Col xs={6} sm={3} md={2} className={`my-2 px-0 text-${step > index ? 'warning' : 'dark'}`}>
            <Row>
              <Col xs={12} className="px-0 d-flex justify-content-center">
                {drawLine(stepNumber, 1, !isPar)}
                <Button
                  block
                  variant="circle-warning"
                  className={`text-${step > index ? 'white' : 'dark'} bg-${step > index ? 'warning' : 'light'}`}
                  onClick={() => setStep(stepNumber)}
                >
                  {stepNumber}
                </Button>
                {drawLine(stepNumber, steps.length, isPar)}
              </Col>
              <Col xs={12} className="d-flex justify-content-center mt-2">
                <p className="mb-0">{stepName}</p>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default SettlementStepMap;
