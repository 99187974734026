export const authTypes = {
  CLEAR_AUTH_INFO: 'CLEAR_AUTH_INFO',
  RESTORE_AUTH_INFO: 'RESTORE_AUTH_INFO',
  CURRENT_COMPANY_FAILURE: 'CURRENT_COMPANY_FAILURE',
  CURRENT_COMPANY_REQUEST: 'CURRENT_COMPANY_REQUEST',
  CURRENT_COMPANY_SUCCESS: 'CURRENT_COMPANY_SUCCESS',
  SELECT_COMPANY: 'SELECT_COMPANY',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_REQUEST_DT: 'SIGN_IN_REQUEST_DT',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  VALIDATE_TOKEN_REQUEST: 'VALIDATE_TOKEN_REQUEST',
  VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS'
};

export const requestSignIn = params => ({
  type: authTypes.SIGN_IN_REQUEST,
  params
});

export const requestDtSignIn = params => ({
  type: authTypes.SIGN_IN_REQUEST_DT,
  params
});
export const requestSignOut = () => ({ type: authTypes.SIGN_OUT_REQUEST });
export const restoreAuthInfo = auth => ({
  type: authTypes.RESTORE_AUTH_INFO,
  auth
});
export const validateToken = () => ({ type: authTypes.VALIDATE_TOKEN_REQUEST });
export const signUpRequest = params => ({
  type: authTypes.SIGN_UP_REQUEST,
  params
});

export const requestCurrentCompany = params => ({ type: authTypes.CURRENT_COMPANY_REQUEST, params });
