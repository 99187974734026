import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import FormikInput from '../../components/Utils/Input/FormikInput';

const SignatureKeyForm = ({
  onHide,
  errors,
  touched,
  isSubmitting,
  rightBtn,
  rightBtnClickHandler,
  initialCounterValue = 0
}) => {
  const [addonCounter, setAddonCounter] = useState(initialCounterValue);

  // Third Attempts
  useEffect(() => {
    const timer = addonCounter > 0 && setInterval(() => setAddonCounter(addonCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [addonCounter]);

  return (
    <Form className="mx-3">
      <p>Introduzca el PIN para firmar el documento(s).</p>
      <Field name="signatureKey">
        {({ field }) => (
          <FormikInput
            {...field}
            error={getIn(errors, field.name)}
            label="PIN de firma:"
            addon={addonCounter > 0 ? addonCounter : ''}
            rightBtn={addonCounter > 0 ? '' : rightBtn}
            rightBtnClickHandler={rightBtnClickHandler}
            touched={getIn(touched, field.name)}
            inputType="password"
          />
        )}
      </Field>

      <Row className="d-flex justify-content-end mt-5 mb-2">
        <Col xs={6} sm={4}>
          <Button block variant="outline-info" onClick={onHide}>
            Cancelar
          </Button>
        </Col>
        <Col xs={6} sm={4}>
          <Button block type="submit" variant="primary" disabled={isSubmitting}>
            Confirmar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { signatureKey } = props;
  return { signatureKey };
};

const validationSchema = Yup.object().shape({
  signatureKey: Yup.string()
    .required('Debes ingresar el PIN')
    .min(6, 'Debe tener mas de 6 dígitos')
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, item } = props;
  formRequest(values, item, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SignatureKeyForm);
