import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { debounceIndexShiftsRequest, deleteShiftRequest, massiveDestroyShiftsRequest } from '../../requests/shifts';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, BasicActionBtns, DefaultModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import ShiftMassiveActions from '../../components/DatatableActions/ShiftMassiveActions';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE TURNO',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TRABAJADORES ASOCIADOS',
    selector: 'associated_employee',
    sortable: false,
    grow: '1',
    center: true
  },
  {
    name: 'HORARIO',
    selector: 'schedule_type',
    sortable: false,
    grow: '1'
  },
  {
    name: 'COLACION',
    selector: 'colation',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOLERANCIA',
    selector: 'tolerance',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'shift_type',
    cell: item => item.translated_shift_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        destroyColor="#d33f49"
        resource="Shift"
        item={item}
        withShow={false}
        clickHandler={clickHandler}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const ShiftDataTable = ({ moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [shifts, setShifts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalBody, setModalBody] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setShifts(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    const request = async () => {
      setOnRequest(true);
      debounceIndexShiftsRequest({ dispatch, params, successCallback: handleSuccessIndex });
    };
    request();
  };

  const handleSuccessRemove = () => {
    setModalShow(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message: 'Turno eliminado correctamente' }));
  };

  const handleFailureRequest = () => {
    setModalShow(false);
    dispatch(sendAlert({ kind: 'error', message: 'Aún tienes trabajadores/solicitudes asociados a este turno' }));
  };

  const removeShift = shift => {
    const { id } = shift;
    setOnRequest(true);
    deleteShiftRequest(id, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        if (ability.can('update', 'Shift')) history.push(`/shifts/${item.id}/edit`);
        break;
      case 'destroy':
        setModalTitle('Eliminar Turno');
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar el turno ${item.name}?`);
        setModalAction(() => removeShift);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleMassiveFailureRequest = error => {
    const { response } = error;
    setModalShow(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const masiveDeleteShift = () => {
    const shiftSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyShiftsRequest({
      params: { ids: shiftSelected },
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'massive_destroy':
        setModalTitle('Eliminar Turnos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los turnos seleccionados?');
        setModalAction(() => masiveDeleteShift);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, 'shifts')}
        data={shifts}
        totalRows={amount}
        selectableRows
        moreData={moreData}
        resourceRequest={handleRequest}
        withMassActions
        onRowClicked={item => handleButtonClick(item, 'edit')}
        pointerOnHover={ability.can('update', 'Shift')}
        onSelectedRowsChange={handleSelectedRows}
        massActions={<ShiftMassiveActions model="Shift" handleClick={handleMassAction} disabled={!selectedCount} />}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default ShiftDataTable;
