import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, DatatableEmployeeName } from '../../../components';

import Status from './Status';

const columns = memoize(clickHandler => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    minWidth: '185px',
    grow: '2'
  },
  {
    name: 'FECHA',
    selector: 'parse_attendance_date',
    grow: '2'
  },
  {
    name: 'HORA ENTRADA TURNO',
    selector: 'shift_day_start_time',
    grow: '1'
  },
  {
    name: 'HORA MARCA ENTRADA',
    selector: 'arrival_mark',
    grow: '1'
  },
  {
    name: 'HORA SALIDA TURNO',
    selector: 'shift_day_end_time',
    grow: '1'
  },
  {
    name: 'HORA MARCA SALIDA',
    selector: 'departure_mark',
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <Status status={item.status} />,
    sortable: true,
    grow: '3'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns resource="AttendanceChangeRequest" item={item} clickHandler={clickHandler} withDestroy={false} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '2',
    minWidth: '123px'
  }
]);

export default columns;
