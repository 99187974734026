import React from 'react';
import PropTypes from 'prop-types';
import { ButtonTooltip } from '../Utils/Tooltips';
import { useAuthorization } from '../../services/hooks';
import Icon from '../Icons';

const ImportActions = ({ handleClick, model }) => (
  <>
    {useAuthorization('export', model) && (
      <ButtonTooltip
        variant="circle-primary"
        className="mr-2 mb-3 btn-circle"
        text="Exportar"
        onClick={() => handleClick('export')}
      >
        <Icon icon="download-2" />
      </ButtonTooltip>
    )}
    {useAuthorization('import', model) && (
      <ButtonTooltip
        variant="circle-primary"
        className="mr-2 mb-3 btn-circle"
        text="Importar"
        onClick={() => handleClick('import')}
      >
        <Icon icon="cloud-upload-outline" />
      </ButtonTooltip>
    )}
  </>
);

ImportActions.propTypes = {
  handleClick: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired
};

ImportActions.defaultProps = {};

export default ImportActions;
