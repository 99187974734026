import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { requestDtSignIn } from '../../actions/auth';
import { MainLogo } from '../../components';
import { updateAbility } from '../../config/ability';
import { AbilityContext } from '../../config/abilityContext';
import '../../services/yupCustomMethods';



const Auth = props => {
  const ability = useAbility(AbilityContext);
  const { userCompanies, currentCompany } = useSelector(state => state.utils);
  const { signedIn, user } = useSelector(state => state.auth);
  const locationState = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const { token } = match.params;

  const handleSubmit = () => {

    dispatch(
      requestDtSignIn({
        user: {
          token,
        }
      })
    );
  };

  const redirect = () => {
    if (signedIn) {
      updateAbility(ability);
      if (locationState?.from?.pathname) {
        const { from } = locationState;
        history.replace(from);
      } else if (user.role === 'admin' && !Object.keys(user.employeeCard).length) {
        history.replace('admin/companies');
      } else if (userCompanies > 1 || user.role === 'dt') {
        history.replace('/profile/companies');
      } else {
        history.replace('/profile/dashboard');
      }
    }
  };

  useEffect(handleSubmit, [])
  useEffect(redirect, [signedIn, user, currentCompany]);

  return (
    <Row className="justify-content-center align-items-center h-100 img-login text-center">
      <Col sm={6} md={4}>
        <MainLogo />
        <p>Cargando por favor espere...</p>
      </Col>
    </Row>
  );
};

export default Auth;
