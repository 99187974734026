import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexGendersRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/genders', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexGendersRequest = AwesomeDebouncePromise(indexGendersRequest, 300);
