import React from 'react';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { FormikCheckBox, FormikDatePicker, FormikInput, FormikSelect } from '../../../../components';
import { occupation, processingCodList, workerQuality } from '../../FormOptions';

const EmployeeTab = props => {
  const {
    handleOccuppation,
    setFieldTouched,
    errors,
    touched,
    values,
    anotherOccupation,
    listTypePensionScheme,
    codeTypePensionScheme,
    codePensionScheme,
    codePayingEntity,
    handlePensionInstitution,
    listPensionInstitution,
    codeOcupation,
    handleCodePensionInstitution,
    codeWorkerQuality,
    setFieldValue,
    listLetterBox,
    isInp,
    processingCodeCCAF,
    listPayingEntityCode
  } = props;
  return (
    <Row>
      <Col md={6}>
        <Field name="transact[codeOcupation]">
          {({ field }) => (
            <FormikSelect
              {...field}
              label="Ocupación"
              abbr
              placeholder="Seleccione Ocupación"
              options={occupation}
              defaultValue={codeOcupation}
              onChange={data => handleOccuppation(field, data)}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="transact[otherOcupation]">
          {({ field }) => (
            <FormikInput
              {...field}
              label="Especificar Ocupación"
              placeholder=""
              values={values}
              disabled={anotherOccupation}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3}>
        <Field name="transact[previsionDateReceptionCcaf]">
          {({ field }) => (
            <FormikDatePicker
              {...field}
              isOutsideRange={() => false}
              label="Fecha Recepción CCAF"
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3}>
        <Field name="transact[codeTypePensionScheme]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Régimen Previsional"
              placeholder="Seleccione Régimen Prov."
              options={listTypePensionScheme}
              defaultValue={codeTypePensionScheme}
              onChange={data => handlePensionInstitution(field, data)}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3}>
        <Field name="transact[codePensionScheme]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Institución Previsional"
              placeholder="Seleccione Institución Prov."
              options={listPensionInstitution}
              defaultValue={codePensionScheme}
              onChange={data => handleCodePensionInstitution(field, data)}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3}>
        <Field name="transact[codeLetterBox]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Letra"
              placeholder="Seleccione Letra."
              options={listLetterBox}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              isDisabled={isInp}
            />
          )}
        </Field>
      </Col>
      <Col md={8}>
        <Field name="transact[codeWorkerQuality]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Calidad del Trabajador"
              placeholder="Seleccione Calidad del Trabajador"
              options={workerQuality}
              defaultValue={codeWorkerQuality}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={2} className="mt-4">
        <Field name="transact[codeSafeAfc]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Afiliado AFC" custom />}
        </Field>
      </Col>
      <Col md={2} className="mt-4">
        <Field name="transact[codeSafeUndefined]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Contrato Indefinido" custom />}
        </Field>
      </Col>

      <Col md={4}>
        <Field name="transact[affiliationDate]">
          {({ field }) => (
            <FormikDatePicker
              {...field}
              abbr
              isOutsideRange={() => false}
              label="Fecha Afiliación Previsional"
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="transact[dateContract]">
          {({ field }) => (
            <FormikDatePicker
              {...field}
              abbr
              isOutsideRange={() => false}
              label="Fecha de Contrato de Trabajo"
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="transact[codePayingEntity]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Entidad Pagadora"
              placeholder="Seleccione Entidad Pagadora."
              options={listPayingEntityCode}
              defaultValue={codePayingEntity}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="transact[processingCodeCCAF]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Entidad Tramitadora"
              placeholder="Seleccione Entidad Tramitadora."
              options={processingCodList}
              defaultValue={processingCodeCCAF}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default EmployeeTab;
