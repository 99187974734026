import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn } from '../../components';
import GroupDataTable from './GroupDataTable';
import columns from './Columns';

const GroupIndex = () => (
  <>
    <Row className="mt-4 mb-4">
      <Col>
        <h2 className="mt-3 mb-3">Grupos de Comunicación</h2>
      </Col>
      <Col md={3}>
        <LinkBtn variant="primary" block to="/groups/new">
          Nuevo
        </LinkBtn>
      </Col>
    </Row>
    <GroupDataTable columns={columns} />
  </>
);

export default GroupIndex;
