/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useAbility } from '@casl/react';
import moment from 'moment';
import { BasicCard, DefaultModal, FormikInput, FormikNumber, FormikSelect, LongCard } from '../../components';
import { contractTypes, paymentSchedules, gratificationTypes } from './FormOptions';
import previredShowRequest from '../../requests/previreds';
import siiTaxesShowRequest from '../../requests/siiTaxes';
import indexPensionFundsRequest from '../../requests/pensionFunds';
import { camelCaseEmptyStringRecursive, sortByAttribute } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import { indexEmployeesRequest, stunnerEmployeeRequest } from '../../requests/employees';
import indexHealthPrevisionsRequest from '../../requests/healthPrevisions';

const weekDays = 7;
const overtimeValue = 1.5;
const monthDays = 30;

const PayrollSimulator = ({ values, setFieldValue, setFieldTouched, errors, touched, setValues }) => {
  const ability = useAbility(AbilityContext);
  const [previred, setPrevired] = useState({});
  const [siiRequest, setSiiRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [employeeRequest, setEmployeeRequest] = useState(true);
  const [skipSimulation, setSkipSimulation] = useState(false);
  const [selector, setSelector] = useState([]);
  const [previredRequest, setPreviredRequest] = useState(true);
  const [pensionFunds, setPensionFunds] = useState([]);
  const [siiTaxes, setSiiTaxes] = useState([]);
  const [siiTax, setSiiTax] = useState({});
  const [healthPrevisions, setHealthPrevisions] = useState([]);
  const [redo, setRedo] = useState(false);
  const dispatch = useDispatch();
  const {
    baseSalary,
    bonus,
    collation,
    commission,
    contractType,
    discountsTotalAmount,
    gratificationAmount,
    gratificationType,
    grossAmount,
    healthPrevision,
    unitOfAccount,
    firstAgreedAmount,
    secondAgreedAmount,
    agreedQuantity,
    householdAllowance,
    legalDiscounts,
    liquid,
    mobilization,
    otherDiscounts,
    otherNonTaxable,
    overtime,
    overtimeAmount,
    paymentSchedule,
    pensionFund,
    taxableBaseAmount,
    weeklyHours,
    wholeWeek
  } = values;

  const { uf, afpMaxTaxableRent, undefinedContractEmployeeAfc, retirementMaxTaxableRent } = previred;
  const fetchHealthPrevisions = () => {
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexHealthPrevisionsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        setHealthPrevisions(data);
      }
    });
  };

  const fetchPrevired = () => {
    previredShowRequest({
      dispatch,
      params: { month: moment().month(), year: moment().year() },
      successCallback: response => setPrevired(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setPreviredRequest(false)
    });
  };

  const fetchSiiTaxes = () => {
    siiTaxesShowRequest({
      dispatch,
      params: { month: moment().month(), year: moment().year() },
      successCallback: response => setSiiTaxes(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setSiiRequest(false)
    });
  };

  const fetchPensionFunds = () => {
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      display_length: 100
    };
    indexPensionFundsRequest({
      dispatch,
      params,
      successCallback: response => setPensionFunds(response.data.data)
    });
  };

  const fetchEmployees = () => {
    indexEmployeesRequest({
      dispatch,
      params: { active_staffing: true, is_dt: false, paginate: false },
      successCallback: response => setSelector(camelCaseEmptyStringRecursive(response.data.data)),
      callback: () => setEmployeeRequest(false)
    });
  };

  const getTaxData = (nonTaxable, taxable = undefined, newSiiTax = undefined, legal = undefined) => {
    const validSii = newSiiTax || siiTax;
    const partialTaxable = taxable - legal || parseInt(liquid || 0, 10) + otherDiscounts + legalDiscounts - nonTaxable;
    for (let i = 0; i < 7; i += 1) {
      const { [`bound${i}`]: vBound } = validSii;
      if (vBound?.lowBound <= partialTaxable && partialTaxable <= vBound?.highBound) {
        return [vBound?.factor, parseFloat(vBound?.reduce)];
      }
    }
    return [validSii.bound7?.factor, parseFloat(validSii.bound7?.reduce)];
  };

  const taxableFormula = (nonTaxable, taxRate, reduce, partialLegalSum = 0, healthAmount = 0) =>
    (nonTaxable -
      parseInt(otherDiscounts || 0, 10) -
      parseInt(liquid || 0, 10) -
      healthAmount -
      parseInt(legalDiscounts || 0, 10) +
      reduce) /
    (partialLegalSum + (1 - partialLegalSum) * taxRate - 1);

  const getTaxable = (nonTaxable, taxRate, reduce) => {
    let partialLegalSum = 0;
    const { pensionFundRate, afcRate } = values;
    if (pensionFund) {
      partialLegalSum += pensionFundRate;
      partialLegalSum += afcRate;
    }
    if (healthPrevision === 5) {
      partialLegalSum += 0.07;
      return taxableFormula(nonTaxable, taxRate, reduce, partialLegalSum);
    }
    const healthAmount =
      firstAgreedAmount !== 0 || typeof firstAgreedAmount !== 'undefined' ? firstAgreedAmount * uf * (1 - taxRate) : 0;
    return taxableFormula(nonTaxable, taxRate, reduce, partialLegalSum, healthAmount);
  };

  const baseSalarySimpleGratification = (taxable, gratAmount) =>
    Math.round(
      (taxable - parseInt(commission || 0, 10) - parseInt(wholeWeek || 0, 10) - gratAmount - parseInt(bonus || 0, 10)) /
        (1 + (overtime * weekDays * overtimeValue) / (monthDays * weeklyHours))
    ) || 0;

  const getBaseSalary = taxable => {
    if (gratificationType === 'edit') {
      return baseSalarySimpleGratification(taxable, gratificationAmount);
    }
    if (gratificationType === 'no') return baseSalarySimpleGratification(taxable, 0);
    const minSalary = parseFloat(previred.particularMinTaxableRent?.replace('.', ',') || 0);
    const baseGratification = Math.round((minSalary * 4.75) / 12);
    const initialBS = Math.round(
      (taxable - parseInt(commission || 0, 10) - parseInt(wholeWeek || 0, 10) - parseInt(bonus || 0, 10)) /
        (1.25 + (overtime * weekDays * overtimeValue) / (monthDays * weeklyHours))
    );
    if (baseGratification > initialBS / 4) {
      return initialBS || 0;
    }

    return baseSalarySimpleGratification(taxable, baseGratification);
  };

  const updateGratification = base => {
    const minSalary = parseFloat(previred.particularMinTaxableRent?.replace('.', ',') || 0);
    if (gratificationType === 'yes') {
      setFieldValue('gratificationAmount', Math.round(Math.min((minSalary * 4.75) / 12, parseInt(base || 0, 10) / 4)));
    } else if (gratificationType === 'no') {
      setFieldValue('gratificationAmount', 0);
    }
  };

  const updateOvertime = base => {
    const newOvertime = (parseFloat(overtime, 10) * base * weekDays * overtimeValue) / (monthDays * weeklyHours);
    setFieldValue('overtimeAmount', newOvertime || 0);
  };

  const payrollSimulation = () => {
    if (skipSimulation) {
      return;
    }
    const { pensionFundRate, afcRate } = values;
    const nonTaxable =
      parseInt(collation, 10) +
      parseInt(mobilization, 10) +
      parseInt(householdAllowance, 10) +
      parseInt(otherNonTaxable, 10);
    const [taxRate, reduce] = getTaxData(nonTaxable);
    const taxable = getTaxable(nonTaxable, taxRate, reduce);
    const newBaseSalary = getBaseSalary(taxable);
    updateGratification(newBaseSalary);
    updateOvertime(newBaseSalary);
    const maxTaxable = afpMaxTaxableRent < taxable ? afpMaxTaxableRent : taxable;
    const afpAmount = Math.round(pensionFundRate * maxTaxable || 0);
    const legalPercentage = Math.round(0.07 * maxTaxable);
    const healthAmount =
      healthPrevision !== 'fonasa' && firstAgreedAmount > 0 ? firstAgreedAmount * uf : legalPercentage;
    const afc = pensionFund !== '' ? afpMaxTaxableRent * afcRate : 0;
    const taxAmount = Math.round((maxTaxable - (afpAmount + healthAmount + afc)) * taxRate) - reduce;
    const discountsLegalTotal = Math.round(afpAmount + legalPercentage + afc + taxAmount);
    const aditionalAmount = Math.round(
      firstAgreedAmount > 0 && legalPercentage < firstAgreedAmount * uf ? Math.abs(healthAmount - legalPercentage) : 0
    );
    setFieldValue('pensionFundAmount', afpAmount || 0);
    setFieldValue('baseSalary', newBaseSalary);
    setFieldValue('taxableBaseAmount', taxable);
    setFieldValue('grossAmount', taxable + nonTaxable || 0);
    setFieldValue('firstAgreedAmount', firstAgreedAmount);
    setFieldValue('healthPrevisionLegal', legalPercentage);
    setFieldValue('tax', taxAmount);
    setFieldValue('afc', afc);
    setFieldValue('discountsLegalTotal', discountsLegalTotal);
    setFieldValue('healthPrevisionAditional', aditionalAmount);
    setFieldValue('discountsTotalAmount', discountsLegalTotal + aditionalAmount + otherDiscounts + legalDiscounts || 0);
  };

  const employeeParams = (newValues, employee) => {
    newValues.baseSalary = employee.baseSalary || 0;
    newValues.contractType = employee.contractType;
    newValues.paymentSchedule = employee.paymentSchedule;
    newValues.collation = employee.collation;
    newValues.bonus = employee.bonus;
    newValues.mobilization = employee.mobilization;
    newValues.pensionFund = employee.pensionFund;
    newValues.afcRate = newValues.contractType === 'undefined_term' ? undefinedContractEmployeeAfc / 100 : 0;
    newValues.overtime = 0;
    newValues.overtimeAmount = 0;
    newValues.wholeWeek = 0;
    newValues.unitOfAccount = employee.unitOfAccount || 0;
    newValues.agreedQuantity = employee.agreedQuantity || 0;
    newValues.firstAgreedAmount = employee.firstAgreedAmount;
    newValues.secondAgreedAmount = employee.secondAgreedAmount || 0;
    newValues.otherDiscounts = employee.otherDiscounts;
    newValues.legalDiscounts = employee.legalDiscounts;
    newValues.otherNonTaxable = employee.otherNonTaxable;
    newValues.weeklyHours = employee.weeklyHours || 45;
  };

  const employeeHouseholdAllowance = (newValues, employee) => {
    if (employee.familyAllowanceSection === 'no_family_allowance') {
      newValues.householdAllowance = 0;
    } else {
      const allowance = previred[`section${employee.familyAllowanceSection.toLowerCase()}HouseholdAllowance`] || 0;
      newValues.householdAllowance = Math.round(allowance * employee.familyCharges);
    }
  };

  const employeeGratification = (newValues = {}, employee) => {
    if (employee.gratificationType === 'without_gratification') {
      newValues.gratificationType = 'no';
      newValues.gratificationAmount = 0;
    } else if (employee.gratificationPeriod === 'annually') {
      newValues.gratificationType = 'edit';
      newValues.gratificationAmount = 0;
    } else {
      newValues.gratificationType = 'yes';
      const minSalary = parseFloat(previred.particularMinTaxableRent?.replace('.', ',') || 0);
      newValues.gratificationAmount = Math.round(Math.min((minSalary * 4.75) / 12, newValues.baseSalary / 4));
    }
  };

  const employeePensionFund = (newValues, employee) => {
    if (newValues.pensionFund) {
      newValues.pensionFundRate =
        previred[`${employee.pensionFundName?.toLowerCase()}DependentAfpQuotationRate`] / 100 || 0;
      const taxable = newValues.taxableBaseAmount > afpMaxTaxableRent ? afpMaxTaxableRent : newValues.taxableBaseAmount;
      const afpAmount = Math.round(newValues.pensionFundRate * taxable);
      newValues.pensionFundAmount = afpAmount;
    } else {
      newValues.pensionFundAmount = 0;
      newValues.pensionFundRate = 0;
    }
  };

  const employeeHealthPrevision = (newValues, employee) => {
    const taxable = newValues.taxableBaseAmount > afpMaxTaxableRent ? afpMaxTaxableRent : newValues.taxableBaseAmount;
    if (employee.healthPrevision && employee.healthPrevisionName !== 'FONASA') {
      newValues.healthPrevision = employee.healthPrevision;
      newValues.healthPrevisionLegal = Math.round(taxable * 0.07);
      newValues.healthPrevisionAditional = Math.round(
        employee.firstAgreedAmount ? employee.firstAgreedAmount * uf - newValues.healthPrevisionLegal : 0
      );
    } else {
      newValues.healthPrevision = employee.healthPrevision;
      newValues.healthPrevisionLegal = Math.round(taxable * 0.07);
      newValues.healthPrevisionAditional = 0;
    }
  };

  const getSii = pSchedule => {
    let sii;
    siiTaxes.forEach(item => {
      if (item.siiType === pSchedule) {
        setSiiTax(item);
        sii = item;
      }
    });
    return sii || siiTaxes[0];
  };

  const checkPrivateRole = employee => {
    if (!employee.privateRole) {
      return;
    }
    if (ability.cannot('private_role', 'Employee')) {
      setModalShow(true);
    }
  };

  const updateEmployeeData = employee => {
    const newValues = {};
    setSkipSimulation(true);
    employeeParams(newValues, employee);
    employeeHouseholdAllowance(newValues, employee);
    employeeGratification(newValues, employee);
    newValues.taxableBaseAmount = newValues.baseSalary + newValues.gratificationAmount + newValues.bonus;
    const maxTaxableToAfc =
      newValues.taxableBaseAmount > retirementMaxTaxableRent ? retirementMaxTaxableRent : newValues.taxableBaseAmount;
    employeePensionFund(newValues, employee);
    employeeHealthPrevision(newValues, employee);
    const sii = getSii(newValues.paymentSchedule);
    newValues.afc = newValues.pensionFund !== '' ? Math.round(maxTaxableToAfc * newValues.afcRate) : 0;
    let legal = newValues.pensionFundAmount + newValues.healthPrevisionLegal + newValues.afc;
    const [taxRate, reduce] = getTaxData(0, newValues.taxableBaseAmount, sii, legal);
    newValues.tax = Math.round((newValues.taxableBaseAmount - legal) * taxRate - reduce);
    const nonTaxable =
      newValues.collation + newValues.mobilization + newValues.otherNonTaxable + newValues.householdAllowance;
    newValues.grossAmount = newValues.taxableBaseAmount + nonTaxable || 0;
    legal += newValues.tax + newValues.legalDiscounts;
    newValues.discountsLegalTotal = legal;
    newValues.discountsTotalAmount =
      newValues.otherDiscounts + newValues.healthPrevisionAditional + newValues.discountsLegalTotal || 0;
    newValues.liquid = newValues.taxableBaseAmount + nonTaxable - newValues.discountsTotalAmount || 0;

    setValues({ ...newValues });
    setSkipSimulation(false);
    checkPrivateRole(employee);
  };

  const handleSelector = () => {
    if (values.employeeSelector === '') {
      return;
    }
    setEmployeeRequest(true);
    stunnerEmployeeRequest(values.employeeSelector, {
      dispatch,
      successCallback: response => updateEmployeeData(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setEmployeeRequest(false)
    });
  };

  const updateSiiTax = () => {
    if (skipSimulation) {
      return;
    }

    siiTaxes.forEach(item => {
      if (item.siiType === paymentSchedule) {
        setSiiTax(item);
      }
    });
  };

  useEffect(fetchEmployees, []);
  useEffect(fetchPensionFunds, []);
  useEffect(fetchPrevired, []);
  useEffect(fetchSiiTaxes, []);
  useEffect(updateSiiTax, [paymentSchedule, siiTaxes]);
  useEffect(fetchHealthPrevisions, []);
  useEffect(payrollSimulation, [
    bonus,
    collation,
    commission,
    contractType,
    gratificationAmount,
    healthPrevision,
    householdAllowance,
    legalDiscounts,
    liquid,
    unitOfAccount,
    firstAgreedAmount,
    secondAgreedAmount,
    agreedQuantity,
    mobilization,
    otherDiscounts,
    otherNonTaxable,
    pensionFund,
    overtime,
    redo,
    weeklyHours,
    wholeWeek
  ]);

  const onRequest = siiRequest || previredRequest || employeeRequest;

  return (
    <Form>
      <Row className="mt-4 mb-4">
        {onRequest && (
          <div className="containerSpinnerLoad center-spinner ml-n2 ml-lg-n0 w-100">
            <Spinner className="mb-4" animation="border" variant="primary" />
          </div>
        )}
        <Col xs={12}>
          <h2 className="mt-3 mb-3">Embrutecedor de Sueldo</h2>
        </Col>
        <Col xs={12} md={4} className="d-flex">
          <BasicCard
            text=""
            subtitle={`Fecha de la UF: 
                ${previred.ufDate || ''}
            `}
            size="100%"
            title={`UF: $
                ${(uf || 0)
                  .toString()
                  .replace('.', ',')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
            classNameCard="z-index-auto flex-fill"
          />
        </Col>
        <Col xs={12} md={8} className="d-flex">
          <BasicCard
            size="100%"
            classNameCard="z-index-auto flex-fill"
            divBody
            text={
              <Row>
                <Col xs={6} md={8}>
                  <Field name="employeeSelector">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        margin="mb-0"
                        label="Seleccionar Trabajador"
                        placeholder="Seleccionar Trabajador"
                        options={sortByAttribute(selector, 'fullName')}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={6} md={2} xl={2}>
                  <Button
                    variant="primary"
                    disabled={values.employeeSelector === '' || onRequest}
                    onClick={handleSelector}
                    className="mt-4"
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            }
          />
        </Col>
        <Col md={12}>
          <BasicCard
            divBody
            title="Sueldo Líquido Objetivo"
            size="100%"
            classNameCard="z-index-auto"
            text={
              <Row>
                <Col md={4}>
                  <Field name="parsedLiquid">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        abbr
                        leftAddon="$"
                        fieldName="liquid"
                        value={liquid}
                        label="Sueldo Líquido Objetivo"
                        onValueChange={data => setFieldValue('liquid', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="parsedBaseSalary">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        disabled
                        leftAddon="$"
                        fieldName="baseSalary"
                        value={baseSalary}
                        label="Sueldo Base"
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="parsedGrossAmount">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        disabled
                        leftAddon="$"
                        fieldName="grossAmount"
                        value={grossAmount}
                        label="Sueldo Bruto"
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
        </Col>
        <Col md={12}>
          <BasicCard
            divBody
            title="Detalle Liquidación"
            size="100%"
            classNameCard="z-index-auto"
            text={
              <Row>
                <Col md={4}>
                  <Field name="paymentSchedule">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Contrato"
                        placeholder="Seleccionar contrato"
                        options={paymentSchedules}
                        defaultValue={values.paymentSchedule}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="contractType">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Plazo Contrato"
                        placeholder="Seleccionar plazo contrato"
                        options={contractTypes}
                        defaultValue={values.contractType}
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                          setFieldValue('afcRate', data.value === 'undefined_term' ? 0.006 : 0);
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="weeklyHours">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        inputType="number"
                        min={0}
                        max={45}
                        label="Horas Semanales"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
        </Col>
        <Col md={6}>
          <BasicCard
            divBody
            title="Haberes Imponibles"
            size="100%"
            classNameCard="z-index-auto"
            text={
              <Row>
                <Col md={12}>
                  <Field name="parsedBaseSalary">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        disabled
                        leftAddon="$"
                        fieldName="baseSalary"
                        value={baseSalary}
                        label="Sueldo Base"
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="gratificationType">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Gratificación"
                        placeholder="Seleccionar gratificación"
                        tooltipText="Al incluir, automáticamente se calcula el menor entre el 25% del sueldo base o
                                     4,75 sueldos mínimos mensuales y mensualizado"
                        options={gratificationTypes}
                        defaultValue={values.gratificationType}
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                          if (data.value === 'yes') {
                            const minSalary = parseFloat(previred.particularMinTaxableRent?.replace('.', ',') || 0);
                            setFieldValue(
                              'gratificationAmount',
                              Math.round(Math.min((minSalary * 4.75) / 12, parseInt(baseSalary || 0, 10) / 4))
                            );
                          } else if (data.value === 'no') {
                            setFieldValue('gratificationAmount', 0);
                          }
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedGratificationAmount">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        disabled={gratificationType !== 'edit'}
                        leftAddon="$"
                        fieldName="gratificationAmount"
                        label="Valor"
                        value={gratificationAmount}
                        onValueChange={data => setFieldValue('gratificationAmount', data?.floatValue || 0)}
                        onBlur={() => setRedo(!redo)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedOvertime">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        rightAddon="Hrs"
                        fieldName="overtime"
                        label="Horas extra"
                        decimalScale={2}
                        value={overtime}
                        onValueChange={data => setFieldValue('overtime', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedOvertimeAmount">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        disabled
                        leftAddon="$"
                        fieldName="overtimeAmount"
                        value={overtimeAmount}
                        label="Valor hrs. extra"
                        onValueChange={data => setFieldValue('overtimeAmount', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12}>
                  <Field name="parsedCommission">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="commission"
                        label="Comisiones"
                        value={values.commission}
                        onValueChange={data => setFieldValue('commission', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12}>
                  <Field name="parsedWholeWeek">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="wholeWeek"
                        label="Semana Corrida"
                        value={wholeWeek}
                        onValueChange={data => setFieldValue('wholeWeek', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12}>
                  <Field name="parsedBonus">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        leftAddon="$"
                        setFieldValue={setFieldValue}
                        fieldName="bonus"
                        label="Bonos"
                        value={bonus}
                        onValueChange={data => setFieldValue('bonus', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
          <BasicCard
            divBody
            title="Haberes No Imponibles"
            size="100%"
            classNameCard="z-index-auto"
            text={
              <Row>
                <Col md={6}>
                  <Field name="parsedCollation">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="collation"
                        label="Colación"
                        value={collation}
                        onValueChange={data => setFieldValue('collation', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedMobilization">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="mobilization"
                        label="Movilización"
                        value={mobilization}
                        onValueChange={data => setFieldValue('mobilization', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedHouseholdAllowance">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="householdAllowance"
                        value={householdAllowance}
                        label="Asignación Familiar"
                        onValueChange={data => setFieldValue('householdAllowance', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedOtherNonTaxable">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="otherNonTaxable"
                        value={otherNonTaxable}
                        label="Otros No Imponibles"
                        onValueChange={data => setFieldValue('otherNonTaxable', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
        </Col>
        <Col md={6}>
          <BasicCard
            divBody
            title="Descuentos Legales"
            size="100%"
            text={
              <Row>
                <Col md={6}>
                  <Field name="pensionFund">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        isClearable
                        label="AFP"
                        placeholder="No Cotiza"
                        options={pensionFunds}
                        defaultValue={values.pensionFund}
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                          if (!data) {
                            setFieldValue('pensionFundRate', 0);
                            setFieldValue('pensionFundAmount', 0);
                          } else {
                            const rate = previred[`${data.label.toLowerCase()}DependentAfpQuotationRate`] / 100;
                            const maxTaxableRentAfp = previred[`${data.label.toLowerCase()}afpMaxTaxableRent`];
                            const amount =
                              taxableBaseAmount > maxTaxableRentAfp ? maxTaxableRentAfp : taxableBaseAmount;
                            setFieldValue('pensionFundRate', rate || 0);
                            setFieldValue('pensionFundAmount', rate * amount || 0);
                          }
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="parsedPensionFundAmount">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        disabled
                        fieldName="pensionFundAmount"
                        onValueChange={data => {
                          setFieldValue('pensionFundAmount', data?.floatValue || 0);
                        }}
                        value={values.pensionFundAmount}
                        label="Total AFP"
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="healthPrevision">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Previsión"
                        placeholder="Seleccionar previsión"
                        options={healthPrevisions}
                        defaultValue={values.healthPrevision}
                        onChange={data => {
                          setFieldValue('healthPrevision', data ? data.value : '');
                          if (data.value === 5) {
                            setFieldValue('firstAgreedAmount', 0);
                            setFieldValue('healthPrevisionAditional', 0);
                          }
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="healthPrevisionLegal">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        disabled
                        fieldName="healthPrevisionLegal"
                        onValueChange={data => {
                          setFieldValue('healthPrevisionLegal', data?.floatValue || 0);
                        }}
                        value={values.healthPrevisionLegal || 0}
                        label="Prevision 7% Legal"
                      />
                    )}
                  </Field>
                </Col>
                <Col md={{ span: 6, offset: 6 }}>
                  <Field name="parsedAfc">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        disabled
                        fieldName="afc"
                        label="Seguro de Cesantía"
                        value={values.afc}
                        onValueChange={data => {
                          setFieldValue('afc', data?.floatValue || 0);
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={{ span: 6, offset: 6 }}>
                  <Field name="parsedTax">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        disabled
                        fieldName="tax"
                        label="Impuesto"
                        value={values.tax}
                        onValueChange={data => setFieldValue('tax', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={{ span: 6, offset: 6 }}>
                  <Field name="discountsLegalTotal">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="discountsLegalTotal"
                        onValueChange={data => {
                          setFieldValue('discountsLegalTotal', data?.floatValue || 0);
                        }}
                        value={values.discountsLegalTotal}
                        label="Total Descuentos Legales"
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
          <BasicCard
            divBody
            title="Descuentos Adicionales"
            size="100%"
            text={
              <Row>
                <Col md={6}>
                  <Field name="firstAgreedAmount">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="UF"
                        disabled={healthPrevision === 5}
                        fieldName="firstAgreedAmount"
                        label="Monto Pactado"
                        value={firstAgreedAmount}
                        defaultValue={values.firstAgreedAmount || 0}
                        decimalSeparator=","
                        decimalScale={2}
                        onValueChange={data => {
                          setFieldValue('firstAgreedAmount', data?.floatValue || 0);
                        }}
                      />
                    )}
                  </Field>
                </Col>

                <Col md={6}>
                  <Field name="healthPrevisionAditional">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        disabled={healthPrevision === 5}
                        fieldName="healthPrevisionAditional"
                        value={values.healthPrevisionAditional}
                        onValueChange={data => {
                          setFieldValue('healthPrevisionAditional', data?.floatValue || 0);
                        }}
                        label="Prevision Adicional"
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
          <BasicCard
            divBody
            title="Otros Descuentos"
            size="100%"
            text={
              <Row>
                {/* <Col md={12}>
                  <Field name="parsedLegalDiscounts">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="legalDiscounts"
                        label="Otros Descuentos Legales"
                        tooltipText="Otros descuentos legales como pensión alimenticia (dictada por juez) y aporte de 
                                     sindicato."
                        value={legalDiscounts}
                        onValueChange={data => setFieldValue('legalDiscounts', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col> */}
                <Col md={12}>
                  <Field name="parsedOtherDiscounts">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        setFieldValue={setFieldValue}
                        leftAddon="$"
                        fieldName="otherDiscounts"
                        label="Otros Descuentos"
                        value={otherDiscounts}
                        onValueChange={data => setFieldValue('otherDiscounts', data?.floatValue || 0)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            }
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="primary"
            label="Total Haberes"
            title={`$ ${parseInt(grossAmount, 10)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            label="Total Descuentos"
            title={`$ ${parseInt(discountsTotalAmount, 10)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            label="Sueldo Líquido"
            title={`$ ${parseInt(liquid, 10)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
          />
        </Col>
      </Row>
      <DefaultModal
        title="Rol Privado"
        body="Este trabajador tiene rol privado y no posees los permisos necesarios para ver su sueldo base."
        show={modalShow}
        handleClose={() => setModalShow(false)}
        titleBtnClose="Cerrar"
        withConfirm={false}
      />
    </Form>
  );
};

const setInitialValues = () => {
  return {
    afcRate: 0.006,
    baseSalary: 0,
    bonus: 0,
    collation: 0,
    commission: 0,
    contractType: 'undefined_term',
    discountsTotalAmount: 0,
    employeeSelector: '',
    gratificationAmount: 0,
    gratificationType: 'yes',
    grossAmount: 0,
    healthPrevision: 'fonasa',
    firstAgreedAmount: 0,
    householdAllowance: 0,
    legalDiscounts: 0,
    liquid: 0,
    mobilization: 0,
    otherDiscounts: 0,
    otherNonTaxable: 0,
    overtime: 0,
    overtimeAmount: 0,
    paymentSchedule: 'monthly',
    pensionFund: '',
    pensionFundRate: 0,
    tax: 0,
    taxableBaseAmount: 0,
    weeklyHours: 45,
    wholeWeek: 0
  };
};

const validationSchema = Yup.object().shape({
  afcRate: Yup.number(),
  baseSalary: Yup.number(),
  bonus: Yup.number(),
  collation: Yup.number(),
  commission: Yup.number(),
  contractType: Yup.string(),
  discountsTotalAmount: Yup.number(),
  gratificationAmount: Yup.number(),
  gratificationType: Yup.string(),
  grossAmount: Yup.number(),
  healthPrevision: Yup.string(),
  firstAgreedAmount: Yup.number(),
  householdAllowance: Yup.number(),
  legalDiscounts: Yup.number(),
  liquid: Yup.number(),
  mobilization: Yup.number(),
  otherDiscounts: Yup.number(),
  otherNonTaxable: Yup.number(),
  overtime: Yup.number(),
  overtimeAmount: Yup.number(),
  paymentSchedule: Yup.string(),
  pensionFundAmount: Yup.number(),
  pensionFundRate: Yup.number(),
  tax: Yup.number(),
  taxableBaseAmount: Yup.number(),
  weeklyHours: Yup.number()
    .positive('Debe ser mayor a 0')
    .max(45, 'Debe ser menor o igual a 45')
    .required('Debes ingresar un número de horas'),

  wholeWeek: Yup.number()
});

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PayrollSimulator);
