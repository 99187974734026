import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { withFormik, Form, Field } from 'formik';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { debounceIndexCostCentersRequest } from '../../requests/costCenters';
import { InputSelect, FormikRangePicker } from '../../components';
import { delayMethod } from '../../services/utils';

const PayrollProcessBudgetForm = ({ cancelAction, values, setFieldValue, isSubmitting }) => {
  const dispatch = useDispatch();

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: element.name,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchCostCenters = (inputValue, callback) => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const handleNewJobManagement = (data, field) => {
    const newData = data.map(element => element.value);
    if (!newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col xs={12} md={12}>
              <Field name="rangeDate">
                {({ field }) => (
                  <FormikRangePicker
                    {...field}
                    startDateName="filterDateFrom"
                    endDateName="filterDateTo"
                    startDate={values.filterDateFrom}
                    endDate={values.filterDateTo}
                    showClearDates
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={6}>
              <Field name="jobManagementIds">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    isMulti
                    label="Áreas"
                    placeholder="Todas"
                    values={values}
                    model={[values, 'jobManagement']}
                    request={fetchJobManagments}
                    onChange={data => handleNewJobManagement(data || [], field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={6}>
              <Field name="costCenterIds">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    isMulti
                    label="Centro de Costos"
                    placeholder="Todos"
                    value={values.costCenterIds}
                    values={values}
                    model={[values, 'costCenter']}
                    request={fetchCostCenters}
                    onChange={data => handleNewJobManagement(data || [], field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn" variant="info" onClick={() => delayMethod(cancelAction)} disabled={isSubmitting}>
            Cancelar
          </Button>
          <Button type="submit" className="btn" variant="primary" disabled={isSubmitting}>
            Generar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = () => ({
  rangeDate: '',
  costCenterIds: '',
  jobManagementIds: ''
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit
})(PayrollProcessBudgetForm);
