import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../../actions/utils';
import { sendContactFormRequest } from '../../../requests/utils';
import { LinkBtn } from '../Button';
import MainLogo from '../MainLogo';
import SimpleCenteredModal from '../Modal/SimpleCenteredModal';
import HomeContact from '../../../screens/Home/HomeContact';
import './style.scss';

const NavbarLanding = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const { signedIn } = useSelector(state => state.auth);
  const handleContact = params => {
    sendContactFormRequest({
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cotización enviada con éxito' }));
        setModalShow(false);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Hubo un problema al enviar la cotización' }));
        setModalShow(false);
      }
    });
  };

  return (
    <>
      <Navbar bg="white" expand="lg" className="navbar-landing-responsive">
        <Navbar.Brand className="landing" as={Link} to="/">
          <MainLogo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-center">
          <Nav>
            <Nav.Link className="landing-nav-link" href="/como-funciona">
              Cómo funciona
            </Nav.Link>
            <Nav.Link className="landing-nav-link" href="#action/3.2">
              Planes
            </Nav.Link>
            <Nav.Link className="landing-nav-link" onClick={() => setModalShow(true)}>
              Contacto
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end mr-xl-5 mr-lg-2">
          <div className="d-flex justify-content-center justify-content-lg-end">
            {signedIn ? (
              <LinkBtn className="m-responsive" variant="outline-primary" to="/profile/dashboard">
                Volver al Portal
              </LinkBtn>
            ) : (
              <LinkBtn className="m-responsive" variant="outline-primary" to="/login">
                Iniciar Sesión
              </LinkBtn>
            )}
            <Button variant="primary" onClick={() => setModalShow(true)}>
              Comenzar
            </Button>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <SimpleCenteredModal
        title="Contactar"
        size="lg"
        body={<HomeContact onHide={() => setModalShow(false)} formRequest={handleContact} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default withRouter(NavbarLanding);
