import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBranchOfficesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/branch_offices`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showBranchOfficeRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/branch_offices/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createBranchOfficeRequest = ({ dispatch, params, callback, formData, successCallback }) =>
  ApiService.request('post', '/branch_offices', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });

export const updateBranchOfficeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/branch_offices/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteBranchOfficeRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/branch_offices/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const massiveDestroyBranchOficcesRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/branch_offices/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportBranchOfficesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/branch_offices/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportBranchOfficeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/branch_offices/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importBranchOfficeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/branch_offices/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateBranchOfficeRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/branch_offices/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const activeBranchOfficeRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/branch_offices/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateBranchOfficeRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/branch_offices/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexBranchOfficesRequest = AwesomeDebouncePromise(indexBranchOfficesRequest, 300);
