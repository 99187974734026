import React, { useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useSetTab } from '../../../services/hooks';
import PerformanceEvaluationDataTable from '../../PerformanceEvaluation/PerformanceEvaluationDataTable';
import MyEvaluationColumns from './MyEvaluationColumns';

const PerformanceEvaluationIndex = ({ location }) => {
  const { currentEmployee } = useSelector(state => state.auth);
  const [key, setKey] = useSetTab('my_evaluations', location);
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <h2 className="m-top mb-3">Evaluaciones</h2>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="my_evaluations">
              Mis Evaluaciones
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="boss_evaluations">
              Jefatura
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="evaluator">
              Evaluador
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="my_evaluations">
            {currentEmployee && (
              <PerformanceEvaluationDataTable
                columns={MyEvaluationColumns}
                path="/profile"
                customParams={{ my_evaluations: currentEmployee, employee: true }}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="boss_evaluations">
            {currentEmployee && (
              <PerformanceEvaluationDataTable
                columns={MyEvaluationColumns}
                path="/profile"
                customParams={{ boss_evaluations: currentEmployee, boss: true }}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="evaluator">
            {currentEmployee && (
              <PerformanceEvaluationDataTable
                columns={MyEvaluationColumns}
                path="/profile"
                customParams={{ evaluators_evaluation: currentEmployee, evaluator: true }}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PerformanceEvaluationIndex;
