import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Modal } from 'react-bootstrap';
import { FormikInput } from '../../components';
import { delayMethod } from '../../services/utils';

const WorkflowRequestRejectOvertime = ({ errors, onHide, cancelAction, touched }) => {
  return (
    <Form>
      <Modal.Body>
        <Field name="workflowRequest[rejectionReason]">
          {({ field }) => (
            <FormikInput
              {...field}
              as="textarea"
              label="Observaciones"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn" variant="info" onClick={() => delayMethod(cancelAction)}>
          Cancelar
        </Button>
        <Button type="submit" className="btn" variant="danger" onClick={onHide}>
          Rechazar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { workflowRequest } = props;
  return {
    workflowRequest: {
      id: workflowRequest.id || workflowRequest,
      record: {
        id: props.record?.id
      },
      observation: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  workflowRequest: Yup.object().shape({
    observation: Yup.string()
      .required('No puede estar en blanco')
      .min(5, 'Debe tener minimo 5 caracteres')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit
})(WorkflowRequestRejectOvertime);
