import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DefaultModal } from '../Utils/Modal';
import { resendNotificationRequest } from '../../requests/workflowRequests';
import { sendAlert } from '../../actions/utils';

const Reminder = ({ item, show, modalShow, moreData, setOnRequest, setMoreData }) => {
  const dispatch = useDispatch();

  const resendNotification = () => {
    setOnRequest(true);
    resendNotificationRequest(item.workflow_request_id, {
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Recordatorio enviado con éxito.' }));
      },
      callback: () => setOnRequest(false)
    });
    modalShow(false);
  };

  return (
    <DefaultModal
      title="Recordatorio"
      body={
        <>
          <p>Este recordatorio se enviará a las siguientes personas:</p>
          <p>{item.request_reviewer_names}</p>
        </>
      }
      show={show}
      handleClose={() => modalShow(false)}
      handleConfirm={() => resendNotification()}
    />
  );
};

Reminder.propTypes = {
  item: PropTypes.shape({
    request_reviewer_names: PropTypes.string,
    workflow_request_id: PropTypes.number
  }).isRequired,
  show: PropTypes.bool.isRequired,
  moreData: PropTypes.bool.isRequired,
  modalShow: PropTypes.func.isRequired,
  setOnRequest: PropTypes.func.isRequired,
  setMoreData: PropTypes.func.isRequired
};

export default Reminder;
