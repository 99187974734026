import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { ComponentDataTable } from '../../components';
import { indexSettlementsRequest } from '../../requests/settlements';
import columns from './Columns';

const SettlementDataTable = ({ fromGroup, settlementGroupId }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [settlements, setSettlements] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRequest = async params => {
    setOnRequest(true);
    let sendParams = params;
    if (fromGroup) sendParams = { ...params, settlementGroupId };
    indexSettlementsRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => setSettlements(response.data.data),
      callback: () => setOnRequest(false)
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/settlements/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      case 'settlement_group':
        return { sort_settlement_groups: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <ComponentDataTable
      withEndDate
      withStartDate
      pointerOnHover
      preName="settlements"
      resourceRequest={handleRequest}
      columns={columns(handleActions)}
      handleSortCase={sortColumnCase}
      onRowClicked={item => handleActions(item, 'show')}
      onRequest={onRequest}
      data={settlements}
    />
  );
};

export default SettlementDataTable;
