import ApiService from '../services/apiService';

const indexSafetyOrganizationsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/safety_organizations', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export default indexSafetyOrganizationsRequest;
