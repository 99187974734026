import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexEmployeeDncRequest = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/employee_dncs${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const debounceIndexEmployeeDncRequest = AwesomeDebouncePromise(indexEmployeeDncRequest, 300);
