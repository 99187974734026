import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import { SimpleCenteredModal } from '../../../components';
import { AbilityContext } from '../../../config/abilityContext';

import ShiftChangeNew from './ShiftChangeNew';
import columns from './Columns';
import ShiftChangeDataTable from '../../Shared/ShiftChange/ShiftChangeDataTable';

const ShiftChangeIndex = ({ currentEmployee, moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [modalBody, setModalBody] = useState('');

  const [modalShow, setModalShow] = useState(false);

  const { advanceSettings } = useSelector(state => state.utils);
  const canNew = advanceSettings?.filter(asItem => asItem.code === 'shift_change')[0]?.textValue === 'true';

  const handleSuccessCreate = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleOpenModal = () => {
    setModalShow(true);
    setModalBody(
      <ShiftChangeNew
        onSuccess={handleSuccessCreate}
        employeeId={currentEmployee}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  return (
    <Row className="mb-3">
      <Col>
        {canNew && ability.can('create', 'ShiftChangeRequest') && (
          <Row>
            <Col xs={{ offset: 6 }} md={{ span: 3, offset: 9 }}>
              <Button block variant="primary" onClick={() => handleOpenModal()}>
                Nuevo
              </Button>
            </Col>
          </Row>
        )}
        <ShiftChangeDataTable
          columns={columns}
          moreData={moreData}
          setMoreData={setMoreData}
          customParams={{ filter_requests_involved: currentEmployee }}
          rrhh={false}
          employee
        />
        <SimpleCenteredModal
          title="Solicitar Cambio de Turno"
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Col>
    </Row>
  );
};

export default ShiftChangeIndex;
