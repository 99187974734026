import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { debounceIndexBanksRequest } from '../../requests/banks';
import indexCompensationFundsRequest from '../../requests/compensationFunds';
import { rutFormat, validRutInput } from '../../services/utils';
import { FormikSelect, InputSelect } from '../Utils/Select';
import { FormikInput } from '../Utils/Input';

import { bankAccountTypes, paymentTypes, paymentTypesWithCompensation } from './FormOptions';

const BankAccount = ({ modelKey, currentModel, paymentTypeAbbr, holderData, withCompensationFund, withEmail }) => {
  const [compensationFunds, setCompensationFunds] = useState([]);
  const dispatch = useDispatch();
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext();

  const currentValues = getIn(values, modelKey);
  const { paymentType, bankAccountType, compensationFundId } = currentModel;
  const { paymentType: vPaymentType } = currentValues;
  const paymentOptions = withCompensationFund ? paymentTypesWithCompensation : paymentTypes;

  const fetchBanks = async (inputValue, callback) => {
    debounceIndexBanksRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        display_length: 70
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchCompensationFunds = () => {
    if (vPaymentType === 'compensation_fund_type') {
      indexCompensationFundsRequest({
        dispatch,
        params: { sort_column: 'name', sort_direction: 'asc', display_length: 100 },
        successCallback: response => setCompensationFunds(response.data.data)
      });
    }
  };

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const handlePaymentType = (field, value) => {
    if (value === 'cash' || value === 'paycheck' || value === 'compensation_fund_type') {
      setFieldValue(`${modelKey}[bankId]`, '');
    }
    if (value !== 'bank_transfer') {
      setFieldValue(`${modelKey}[bankAccount]`, '');
      setFieldValue(`${modelKey}[bankAccountHolderName]`, '');
      setFieldValue(`${modelKey}[bankAccountType]`, '');
      setFieldValue(`${modelKey}[bankAccountNationalIdentification]`, '');
    }
    if (withCompensationFund && value !== 'compensation_fund_type') {
      setFieldValue(`${modelKey}[compensationFundId]`, '');
    }
    setFieldValue(field, value); // Important to leave at the end of method
  };

  useEffect(fetchCompensationFunds, [vPaymentType]);

  return (
    <>
      <Col md={4}>
        <Field name={`${modelKey}[paymentType]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr={paymentTypeAbbr}
              isClearable={!paymentTypeAbbr}
              label="Forma de Pago"
              placeholder="Seleccionar Forma de Pago"
              onChange={data => handlePaymentType(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              options={paymentOptions}
              defaultValue={paymentType}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      {vPaymentType && (vPaymentType === 'bank_transfer' || vPaymentType === 'cashiers_check') && (
        <Col md={4}>
          <Field name={`${modelKey}[bankId]`}>
            {({ field }) => (
              <InputSelect
                {...field}
                abbr
                label="Banco para Pago"
                placeholder="Seleccionar Banco"
                values={currentValues}
                model={[currentModel, 'bank']}
                request={fetchBanks}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}
      {vPaymentType && vPaymentType === 'bank_transfer' && (
        <>
          <Col md={4}>
            <Field name={`${modelKey}[bankAccount]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Cuenta para depósito"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name={`${modelKey}[bankAccountType]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Cuenta"
                  placeholder="Seleccionar Tipo de Cuenta"
                  options={bankAccountTypes}
                  defaultValue={bankAccountType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {holderData && (
            <>
              <Col md={4}>
                <Field name={`${modelKey}[bankAccountHolderName]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Nombre del titular de la cuenta"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>

              <Col md={4}>
                <Field name={`${modelKey}[bankAccountNationalIdentification]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="RUT del titular de la cuenta"
                      onChange={e => handleNationalIdentificationFormat(e)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </>
      )}
      {vPaymentType && vPaymentType === 'compensation_fund_type' && (
        <Col md={4}>
          <Field name={`${modelKey}[compensationFundId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Caja de compensación"
                placeholder="Seleccionar Caja de Compensación"
                options={compensationFunds}
                defaultValue={compensationFundId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}
      {withEmail && vPaymentType && (
        <Col md={4}>
          <Field name={`${modelKey}[receptorEmail]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Email"
                tooltipText="Email de la persona que sera notificada del pago de este descuento"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}
    </>
  );
};

export default BankAccount;
