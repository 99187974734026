import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { useAuthorization } from '../../services/hooks';

import { LinkBtn } from '../../components';
import { showDocumentTemplateRequest } from '../../requests/documentTemplates';
import DocumentTemplateInfo from './DocumentTemplateInfo';
import DocumentPreview from './DocumentPreview';
import documentTemplate from './documentTemplate';

const DocumentTemplateShow = ({ match }) => {
  const [document, setDocument] = useState(documentTemplate);
  const dispatch = useDispatch();

  const fetchDocument = () => {
    const request = async () => {
      const documentTemplateId = match.params.id;
      showDocumentTemplateRequest(documentTemplateId, {
        dispatch,
        successCallback: response => setDocument(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchDocument, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="text-uppercase mb-0">{document.name}</h2>
        </Col>
        <Col md={2}>
          {useAuthorization('update', 'DocumentTemplate') && (
            <LinkBtn variant="warning" block to={`/document_templates/${document.id}/edit`}>
              Editar
            </LinkBtn>
          )}
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col>
          <DocumentTemplateInfo document={document} />
        </Col>
      </Row>
      <Row>
        <DocumentPreview body={document.body} />
      </Row>
    </>
  );
};

export default DocumentTemplateShow;
