/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ApproveRejectActions, DatatableEmployeeName, ApprovementStatus } from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA TÉRMINO',
    selector: 'end_date',
    cell: item => (item.end_date ? item.end_date : `${item.start_time} - ${item.end_time}`),
    sortable: true,
    grow: '2'
  },
  {
    name: 'TIPO DE PERMISO',
    selector: 'permission_type',
    cell: item => item.translated_permission_type || item.translated_vacation_type,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ApproveRejectActions
        item={item}
        resource="Permission"
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
        withDestroy
      />
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '123px'
  }
]);

export default columns;
