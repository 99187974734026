import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import RiskPreventionDocumentForm from './RiskPreventionDocumentForm';
import { sendAlert } from '../../actions/utils';
import {
  debounceIndexRiskPreventionDocumentRequest,
  updateRiskPreventionDocumentRequest
} from '../../requests/riskPreventionDocuments';

const RiskPreventionDocumentIndex = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [riskPreventionDocument, setRiskPreventionDocument] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleIndexRequest = () => {
    setOnRequest(true);
    debounceIndexRiskPreventionDocumentRequest({
      dispatch,
      params: {
        document_type: [
          'order_hygiene_safety',
          'labor_direction',
          'emergency_evaluation',
          'riohs_labor_inspection',
          'riohs_seremi'
        ],
        sort_column: 'document_type',
        sort_direction: 'asc'
      },
      successCallback: response => setRiskPreventionDocument(camelCaseRecursive(response.data.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    setOnRequest(true);
    const { document } = values.riskPrevention;
    const myParams = snakeCaseKeys(values);
    myParams.risk_prevention.document = document;

    updateRiskPreventionDocumentRequest(values.id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => dispatch(sendAlert({ kind: 'success', message: 'Documento subido con éxito' })),
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
        setMoreData(!moreData);
      }
    });
  };
  useEffect(handleIndexRequest, [moreData]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mb-3 mt-3">Documentación Legal</h2>
        </Col>
      </Row>
      <Row className={`${onRequest && 'bg-opacity'}`}>
        {riskPreventionDocument.map((document, index) => (
          <Col key={document.id} xs={12} md={6}>
            <RiskPreventionDocumentForm
              id={document.id}
              index={index}
              title={document.translatedDocumentType}
              name={document.name}
              document={document.document}
              documentInfo={document.documentInfo}
              documentType={document.documentType}
              documentTranslated={document.translatedDocumentType}
              isPublic={document.isPublic}
              formRequest={handleUpdateRequest}
              handleExport={handleIndexRequest}
              setOnRequest={setOnRequest}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default RiskPreventionDocumentIndex;
