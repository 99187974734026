import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { UploadFile } from '..';
import { delayMethod } from '../../services/utils';

const FILE_SIZE = 20000 * 1024;
const SUPPORTED_FORMATS = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const AttendanceImportForm = props => {
  const { onHide, setFieldValue, handleModalClose, errors, touched, isSubmitting } = props;
  const [disabled, setDisabled] = useState(true);

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col>
            <Field name="attendance[file]">
              {({ field }) => (
                <UploadFile
                  {...field}
                  tooltipText="Formatos permitidos: Excel"
                  name="Adjuntar Archivo"
                  onChange={file => {
                    setFieldValue(field.name, file);
                    setDisabled(false);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  formats={['.xls', '.xlsx']}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" disabled={disabled || isSubmitting} onClick={onHide}>
          Importar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    attendance: {
      file: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    file: Yup.mixed()
      .required('Debes agregar un archivo')
      .test('fileType', 'El formato del archivo es inválido', value => value && SUPPORTED_FORMATS.includes(value.type))
      .test('fileSize', 'El archivo es demasiado grande', value => value && value.size <= FILE_SIZE)
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(AttendanceImportForm);
