import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { createProfileRequest } from '../../requests/profiles';
import { LinkBtn, Icon } from '../../components';
import { sendAlert } from '../../actions/utils';
import RoleForm from './RoleForm';
import basicProfile from './role';
import { AbilityContext } from '../../config/abilityContext';
import { updateAbility } from '../../config/ability';

const RoleNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ability = useAbility(AbilityContext);

  useEffect(() => window.scrollTo(0, 0), []);

  const handleCreate = async role => {
    createProfileRequest({
      dispatch,
      params: snakeCaseKeys(role),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Perfil creado con éxito' }));
        updateAbility(ability);
        history.push('/roles');
      }
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/roles">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Nuevo Perfil</h2>
        </Col>
      </Row>
      <RoleForm profile={basicProfile} action="new" formRequest={handleCreate} onRequest={false} />
    </>
  );
};

export default RoleNew;
