export default {
  actionType: 1,
  activityType: '',
  address: '',
  addressApt: '',
  addressNumber: '',
  bipartiteCommittee: false,
  communeId: '',
  countryId: '',
  countryName: '',
  courseCertificate: null,
  courseImage: {},
  courseAgency: '',
  courseModality: '',
  description: '',
  employeeCourses: [],
  endDate: '',
  endTime: '',
  friday: false,
  instructorsAttributes: [],
  limitDate: '',
  mandateIndicator: '',
  maxAvailability: '',
  monday: false,
  name: '',
  regionId: '',
  saturday: false,
  status: '',
  senceCode: '',
  senceRegister: false,
  startDate: '',
  startTime: '',
  sunday: false,
  theme: '',
  thursday: false,
  totalValue: 0,
  tuesday: false,
  wednesday: false
};
