import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BasicCard = ({
  bodyMarginBottom,
  colorBorder,
  colorText,
  classNameCard,
  classNameImg,
  classTitle,
  direction,
  divBody,
  path,
  size,
  sizeHeight,
  src,
  subtitle,
  text,
  title,
  titleBtn,
  variantBtn
}) => (
  <Card
    text={colorText}
    className={`box-shadow ${classNameCard}`}
    style={{ width: size, height: sizeHeight }}
    border={colorBorder}
  >
    {src ? <Card.Img variant={direction} className={classNameImg} src={src} /> : <Card.Img variant={direction} />}
    <Card.Body style={{ marginBottom: bodyMarginBottom }}>
      {title && <Card.Title className={`${classTitle}`}>{title}</Card.Title>}
      {subtitle && <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>}
      {divBody ? <div>{text}</div> : <Card.Text>{text}</Card.Text>}
      {titleBtn && (
        <Button as={Link} variant={variantBtn} to={path}>
          {titleBtn}
        </Button>
      )}
    </Card.Body>
  </Card>
);

BasicCard.propTypes = {
  colorBorder: PropTypes.string,
  colorText: PropTypes.string,
  classNameCard: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classNameImg: PropTypes.string,
  classTitle: PropTypes.string,
  direction: PropTypes.string,
  divBody: PropTypes.bool,
  path: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleBtn: PropTypes.string,
  variantBtn: PropTypes.string
};

BasicCard.defaultProps = {
  colorBorder: null,
  colorText: 'black',
  classNameCard: '',
  classNameImg: '',
  classTitle: '',
  direction: 'top',
  divBody: false,
  path: null,
  size: '18rem',
  src: null,
  subtitle: null,
  titleBtn: null,
  variantBtn: 'info'
};

export default BasicCard;
