import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexEnrollmentRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/documents/enrollments', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showEnrollmentRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/documents/enrollments/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateEnrollmentRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/documents/enrollments/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateStatusEnrollmentRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/documents/enrollments/update_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const massApprovalEnrollmentRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/documents/enrollments/mass_approval`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const sendEnrollmentRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/documents/enrollments/send_enrollment', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const validateTokenRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/documents/enrollments/validate_token', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const debounceIndexEnrollmentRequest = AwesomeDebouncePromise(indexEnrollmentRequest, 300);
