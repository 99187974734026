import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../../components';
import { basicDnc } from '../../DNC/dnc';
import { showDncRequest, updateDncRequest } from '../../../requests/dncs';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import EmployeeDncDatatableProfile from './EmployeeDncDatatableProfile';

const DncProfileEdit = ({ match }) => {
  const [dnc, setDnc] = useState(basicDnc);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleShowRequest = () => {
    setOnRequest(true);
    showDncRequest(match.params.id, {
      dispatch,
      successCallback: res => setDnc(camelCaseEmptyStringRecursive(res.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateRequest = (dncValues, setSubmitting) => {
    updateDncRequest(match.params.id, {
      dispatch,
      params: snakeCaseKeys(dncValues, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'DNC actualizado con éxito' }));
        history.push('/profile/dnc');
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      <Row className="mt-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/profile/dnc">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase mt-3 mb-5">Editar {dnc.name}</h2>
      </Row>
      <EmployeeDncDatatableProfile dnc={dnc} formRequest={handleUpdateRequest} onRequest={onRequest} action="edit" />
    </>
  );
};

export default DncProfileEdit;
