export default {
  endDate: '',
  endText: '',
  introText: '',
  name: '',
  startDate: '',
  status: 'pending',
  evaluatedEmployeeIds: [],
  evaluatedEmployees: [],
  toEvaluate: [],
  evaluatorEmployeeIds: [],
  evaluatorEmployees: [],
  skillsAttributes: [],
  evaluationStepsAttributes: [
    {
      active: true,
      name: 'Definición de Competencias',
      stepType: 'competence'
    },
    {
      active: true,
      name: 'Objetivos para el Evaluado',
      stepType: 'objective'
    },
    {
      active: true,
      name: 'Seguimiento',
      stepType: 'trace'
    },
    {
      active: true,
      name: 'Evaluación',
      stepType: 'evaluation'
    },
    {
      active: true,
      name: 'Autoevaluación',
      stepType: 'self_evaluation'
    },
    {
      active: true,
      name: 'Resultado',
      stepType: 'results'
    },
    {
      active: true,
      name: 'Retroalimentación',
      stepType: 'feedback'
    }
  ]
};
