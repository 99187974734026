import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';

import {
  BasicTextArea,
  ButtonTooltip,
  EmployeeSearchModal,
  FormikDatePicker,
  FormikInput,
  FormikNumber,
  FormikSelect,
  Icon,
  LinkBtn,
  SimpleCenteredModal
} from '../../../components';
import { indexEmployeesRequest } from '../../../requests/employees';
import { camelCaseEmptyStringRecursive, sortByAttribute } from '../../../services/utils';
import SettlementForm from '../../Settlement/Form/SettlementForm';
import terminationReasonTypes from './FormOptions';
import SettlementGroupTable from './SettlementGroupTable';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const SettlementGroupForm = ({ errors, isSubmitting, setFieldValue, settlementGroup, touched, values }) => {
  const { employeeSelector, settlementGroup: vSettlementGroup } = values;
  const { settlementsAttributes: vSettlementsAttributes } = vSettlementGroup;
  const { settlementsAttributes } = settlementGroup;

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [selector, setSelector] = useState(selectorTypes);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const toggleStep = ({ back = false }) => {
    let steps = { 0: 1, 1: 2, 2: 3 };
    if (back) steps = { 4: 3, 3: 2, 2: 1 };
    const limitStep = back ? 0 : 4;
    setStep(steps[step] || limitStep);
  };

  const handleFieldValues = (employee, index) => {
    const findSettlementIndex = vSettlementsAttributes.findIndex(settlement => settlement.employeeId === employee.id);
    const settlementIndex = findSettlementIndex >= 0 ? findSettlementIndex : index;
    const fieldValue = `settlementGroup[settlementsAttributes][${settlementIndex}]`;
    if (findSettlementIndex !== -1) setFieldValue(`[${fieldValue}][_destroy]`, false);
    setFieldValue(`[${fieldValue}][employee]`, employee);
    setFieldValue(`[${fieldValue}][employeeId]`, employee.id);
    setFieldValue(`[${fieldValue}][updatedAt]`, Date.now());
  };
  const handleSuccessRequest = response => {
    const employees = camelCaseEmptyStringRecursive(response.data.data);
    setSelector([...selectorTypes, ...employees]);
    setOnRequest(false);
  };

  const fetchEmployees = () => {
    setOnRequest(true);
    indexEmployeesRequest({
      dispatch,
      params: { active: true, is_dt: false, payroll_process: true, paginate: false },
      successCallback: handleSuccessRequest
    });
  };

  const handleRemoveValue = ({ index, _destroy, employee, updateValues = false }) => {
    if (updateValues) {
      const allSettlements = vSettlementsAttributes.filter(vSettlement => vSettlement.employeeId !== employee.id);
      setFieldValue('settlementGroup[settlementsAttributes]', allSettlements);
    } else {
      const fieldValue = `settlementGroup[settlementsAttributes][${index}]`;
      setFieldValue(`[${fieldValue}][_destroy]`, _destroy);
    }
  };

  const removeAnEmployee = employee => {
    const addToSelector = [...selector, employee];
    setSelector(addToSelector);
    vSettlementsAttributes.forEach((settlement, index) => {
      if (settlement.employeeId !== employee.id) return;
      if (settlement.id) handleRemoveValue({ index, _destroy: true });
      else handleRemoveValue({ employee, updateValues: true });
    });
  };

  const addAnEmployee = vSelector => {
    const addEmployee = selector.find(selected => selected.value === employeeSelector);
    handleFieldValues(addEmployee, vSettlementsAttributes.length);
    setSelector(vSelector);
  };

  const addAllEmployees = () => {
    const addEmployees = selector.filter(selected => selected.id !== undefined);
    addEmployees.forEach((employee, index) => handleFieldValues(employee, vSettlementsAttributes.length + index));
    setSelector([]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== employeeSelector);
    if (vSelector.length === selector.length) return;
    if (employeeSelector === 'all_employees') addAllEmployees();
    else addAnEmployee(vSelector);
  };

  const handleSearch = selectedEmployees => {
    setModalShow(false);
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    if (vSelector.length === selector.length) return;
    const addEmployees = selector.filter(selected => selectedEmployees.includes(selected.value));
    addEmployees.forEach((employee, index) => handleFieldValues(employee, vSettlementsAttributes.length + index));
    setSelector(vSelector);
  };

  const setModal = (title, body, size = 'lg') => {
    setModalSize(size);
    setModalTitle(title);
    setModalBody(body);
    setModalShow(true);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchEmployees, [settlementsAttributes]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {step === 0 && (
        <Form>
          <Card>
            <Card.Body>
              <h4 className="text-uppercase text-dark mt-2 mb-4">Definiciones para el cálculo</h4>
              <Row>
                <Col md={6}>
                  <Field name="settlementGroup[name]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Nombre de Grupo"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="settlementGroup[parsedUf]">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        disabled
                        leftAddon="UF"
                        label="Valor UF"
                        fieldName="settlementGroup[parsedUf]"
                        value={settlementGroup.uf}
                        decimalScale={2}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="settlementGroup[parsedUfLimit]">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        disabled
                        leftAddon="UF"
                        label="Tope 90 UF"
                        fieldName="settlementGroup[ufLimit]"
                        value={settlementGroup.uf * 90}
                        decimalScale={2}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="settlementGroup[terminationReason]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Causal de Término"
                        placeholder="Seleccionar Causal de Término"
                        options={terminationReasonTypes}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="settlementGroup[terminationDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        abbr
                        isOutsideRange={() => false}
                        label="Fecha de Término"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="settlementGroup[paymentDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        abbr
                        isOutsideRange={() => false}
                        label="Fecha de Pago"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12}>
                  <Field name="settlementGroup[justification]">
                    {({ field }) => (
                      <BasicTextArea
                        {...field}
                        abbr
                        label="Justificación de la Causal"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="align-items-center mb-3">
            <Col md={4}>
              <Field name="employeeSelector">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Agregar Trabajadores"
                    placeholder="Seleccionar Trabajador"
                    options={sortByAttribute(selector, 'fullName')}
                    defaultValue="all_employees"
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xs={7} md={2} xl={2}>
              <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
                Agregar
              </Button>
            </Col>
            <Col xs={5} md={1}>
              <ButtonTooltip
                variant="circle-primary"
                className="advance-search"
                text="Búsqueda Avanzada"
                onClick={() =>
                  setModal(
                    'Buscar Empleados',
                    <EmployeeSearchModal
                      customParams={{ active: true }}
                      handleClose={() => setModalShow(false)}
                      formRequest={handleSearch}
                    />
                  )
                }
              >
                <Icon className="w-100 h-100" icon="people-circle" />
              </ButtonTooltip>
            </Col>
          </Row>
          <SimpleCenteredModal
            size={modalSize}
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <Row className="table-custom-background mb-5 mx-1">
            <SettlementGroupTable settlements={vSettlementsAttributes} removeAnEmployee={removeAnEmployee} />
          </Row>

          <Row className="my-4">
            <Col md={2} className="mt-3">
              <LinkBtn block variant="outline-info" to="/settlements">
                Cancelar
              </LinkBtn>
            </Col>
            <Col md={{ span: 2, offset: 6 }} className="mt-3">
              <Button block variant="info" onClick={toggleStep}>
                Siguiente
              </Button>
            </Col>
            <Col md={2} className="mt-3">
              <Button block type="submit" variant="primary" disabled={isSubmitting}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      {step !== 0 && <SettlementForm settlement={{}} step={step} setStep={setStep} toggleStep={toggleStep} />}
    </>
  );
};

const setInitialValues = ({ settlementGroup }) => {
  const {
    justification,
    name,
    paymentDate,
    settlementsAttributes,
    terminationDate,
    terminationReason,
    uf
  } = settlementGroup;
  return {
    employeeSelector: 'all_employees',
    settlementGroup: {
      justification,
      name,
      paymentDate,
      settlementsAttributes,
      terminationDate,
      terminationReason,
      uf
    }
  };
};

const validationSchema = Yup.object().shape({
  settlementGroup: Yup.object().shape({
    justification: Yup.string().required('Debes ingresar una justificación de la causal'),
    name: Yup.string().required('Debes ingresar un nombre de grupo'),
    paymentDate: Yup.date().required('Debes ingresar una fecha de pago'),
    terminationDate: Yup.date().required('Debes ingresar una fecha de término'),
    terminationReason: Yup.string().required('Debes seleccionar una causal de término')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SettlementGroupForm);
