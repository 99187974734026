import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns, ActiveDot, DatatableEmployeeName } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'Nº',
    selector: 'correlative_identification',
    sortable: true,
    grow: '0.1',
    center: true
  },
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TÉRMINO CONTRATO',
    selector: 'end_date',
    sortable: true,
    grow: '0.5',
    center: true
  },
  {
    name: 'ACTIVO',
    selector: 'collaborator',
    cell: item => (item.collaborator ? <ActiveDot item={item} /> : ''),
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Employee"
        path="employees"
        clickHandler={clickHandler}
        withArchive
        disableDestroy={item.enrolled_or_contract}
        archiveText={item.active ? 'Desactivar' : 'Activar'}
        archiveColor={item.active ? '' : '#87ce03'}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '160px',
    right: true
  }
]);

export default columns;
