import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ComponentDataTable } from '../../components';
import { debounceIndexTicketsRequest } from '../../requests/tickets';

const TicketDataTable = props => {
  const [tickets, setTickets] = useState();
  const [onRequest, setOnRequest] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const { columns, moreData } = props;

  const handleRequest = async params => {
    setOnRequest(true);
    debounceIndexTicketsRequest({
      dispatch,
      params,
      successCallback: response => {
        setTickets(response.data.data);
        setOnRequest(false);
      }
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/tickets/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'full_name':
        return { sort_employees: 'full_name' };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleButtonClick)}
        data={tickets}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        onRequest={onRequest}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
    </>
  );
};

export default TicketDataTable;
