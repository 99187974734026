import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import BasicCard from './BasicCard';
import { ButtonTooltip } from '../Tooltips';
import Icon from '../../Icons';

const CardImage = ({
  item,
  image,
  rrhh,
  canEdit,
  showInfo,
  nameShow,
  showEdit,
  className,
  classNameNoImage,
  classNameDescription,
  nameKey,
  resend,
  location
}) => {
  const imgSize = location === 'dashboard' ? 'img-size-dash' : 'img-size';
  const titleDash = location === 'dashboard' ? 'title-dash mb-0' : '';
  const heightCard = location === 'dashboard' ? '190px' : '320px';

  return (
    <div className="card-image">
      <BasicCard
        classNameCard={`card-box ${className} ${!image && `bg-card-colors ${classNameNoImage}`}`}
        classNameImg={`${imgSize} ${rrhh && canEdit && 'img-size-edit'}`}
        classTitle={`${titleDash}`}
        src={image}
        title={item[nameKey]}
        size="100%"
        sizeHeight={heightCard}
        variantBtn="primary"
        divBody
        text={
          image ? (
            <div className="container-buttons">
              <div className="text-justify box-size-description mr-xl-n3">
                <p className={`${classNameDescription || 'b-description'}`}>{item.description}</p>
              </div>
              <div className={rrhh && canEdit ? 'container-btn-edit' : 'container-btn'}>
                {rrhh && canEdit && (
                  <Button block className="margin-sm" variant="outline-info" onClick={() => showEdit(item)}>
                    Editar
                  </Button>
                )}
                <Button
                  block
                  className={`${rrhh && canEdit ? 'mb-0 mt-2' : 'mb-2'} ${location === 'dashboard' ? 'btn-sm' : ''}`}
                  variant="primary"
                  onClick={() => showInfo(item)}
                >
                  {nameShow}
                </Button>

                {location !== 'dashboard' && rrhh && canEdit ? (
                  <ButtonTooltip
                    block
                    className={rrhh && canEdit ? 'mb-0 mt-2' : 'mb-2'}
                    variant="warning"
                    size="sm"
                    text="Enviar Recordatorio"
                    onClick={() => resend(item)}
                  >
                    <Icon width={18} icon="notifications" />
                  </ButtonTooltip>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div>
              <p className={`b-description-multiline ${rrhh && canEdit && 'multiline-edit'} text-justify`}>
                {item.description}
              </p>
              <div className="container-flex">
                <div />
                <div className={rrhh && canEdit ? 'container-btn-edit' : 'd-flex justify-content-end'}>
                  {rrhh && canEdit && (
                    <Button className="btn-size margin-btn" variant="outline-info" onClick={() => showEdit(item)}>
                      Editar
                    </Button>
                  )}
                  <Button
                    className={rrhh && canEdit ? 'btn-size mb-0' : 'mt-md-2 mt-lg-1 mb-3'}
                    variant="primary"
                    onClick={() => showInfo(item)}
                  >
                    {nameShow}
                  </Button>
                  {location !== 'dashboard' && (
                    <ButtonTooltip
                      block
                      className={rrhh && canEdit ? 'mb-0 mt-2' : 'mb-2'}
                      variant="warning"
                      size="sm"
                      text="Enviar Recordatorio"
                      onClick={() => resend(item)}
                    >
                      <Icon width={18} icon="notifications" />
                    </ButtonTooltip>
                  )}
                </div>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

CardImage.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  rrhh: PropTypes.bool,
  canEdit: PropTypes.bool,
  showEdit: PropTypes.func,
  showInfo: PropTypes.func.isRequired,
  nameShow: PropTypes.string,
  className: PropTypes.string,
  classNameNoImage: PropTypes.string,
  classNameDescription: PropTypes.string,
  nameKey: PropTypes.string
};

CardImage.defaultProps = {
  item: null,
  nameKey: 'name',
  image: '',
  rrhh: false,
  canEdit: false,
  nameShow: 'Más info',
  className: '',
  classNameNoImage: '',
  classNameDescription: '',
  showEdit: () => null
};

export default CardImage;
