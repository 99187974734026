/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, ApproveRejectActions, DatatableEmployeeName } from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '4'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '2'
  },
  {
    name: 'MONTO',
    selector: 'amount',
    cell: item => item.parsed_amount,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE OTORGAMIENTO',
    selector: 'grant_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'PRIMER DESCUENTO',
    selector: 'start_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ApproveRejectActions
        resource="Loan"
        item={item}
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
        withDestroy
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '100px'
  }
]);

export default columns;
