import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const debounceIndexNotificationsRequest = AwesomeDebouncePromise(
  ({ dispatch, params = {}, failureCallback, successCallback }) =>
    ApiService.request('get', '/notifications', {
      dispatch,
      params,
      failureCallback,
      successCallback
    }),
  300
);

export const readNotificationRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/notifications/${id}/read`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const readAllNotificationRequest = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('post', '/notifications/massive_read', {
    dispatch,
    failureCallback,
    successCallback
  });
