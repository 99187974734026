import React, { useContext } from 'react';
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Icon from '../../Icons';
import { ButtonTooltip } from '../Tooltips';
import './style.scss';

const CustomToggle = ({ item, fetchRequestWithId, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const fetch = item && item.has_children && (!item.children || item.children.length === 0);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (fetch) {
      fetchRequestWithId(item);
    }
    if (callback) {
      callback(eventKey);
    }
  });

  const isCurrentEventKey = currentEventKey === eventKey && !fetch;

  return (
    <Button variant="circle-dark" className="btn-circle circle-hover mr-2" onClick={decoratedOnClick}>
      <Icon icon={isCurrentEventKey ? 'chevron-down' : 'chevron-forward'} />
    </Button>
  );
};

const ActionButtons = ({ permitedActions, item, handleButtonClick }) => {
  if (!permitedActions) {
    return <></>;
  }
  return (
    <>
      {permitedActions.find(e => e === 'show') && (
        <ButtonTooltip
          toolbarVariant="ml-auto"
          variant="circle-info"
          className="btn-circle circle-hide"
          size="sm"
          text="Ver"
          onClick={() => handleButtonClick(item, 'show')}
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      )}
      {permitedActions.find(e => e === 'edit') && (
        <ButtonTooltip
          toolbarVariant={permitedActions.find(e => e === 'show') ? '' : 'ml-auto'}
          variant="circle-warning"
          className="btn-circle circle-hide"
          size="sm"
          text="Editar"
          onClick={() => handleButtonClick(item, 'edit')}
        >
          <Icon icon="pencil" />
        </ButtonTooltip>
      )}
      {permitedActions.find(e => e === 'remove') && (
        <ButtonTooltip
          variant="circle-danger"
          className="btn-circle circle-hide"
          size="sm"
          text="Eliminar"
          onClick={() => handleButtonClick(item, 'remove')}
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      )}
    </>
  );
};

const IterateArray = ({
  childrenArray,
  parentIndex,
  handleButtonClick,
  permitedActions,
  fetchRequestWithId,
  root,
  onRequest
}) => {
  const nodes = childrenArray.map((item, index) => (
    <Row key={`accordion-${parentIndex}-${index.toString()}`}>
      {!root && <Col md={1}>{index === 0 && <Icon icon="return-down-forward" className="right-arrow" />}</Col>}
      <Col>
        <Card className="box">
          <Card.Header>
            {((item.children && item.children.length > 0) || item.has_children) && (
              <CustomToggle
                eventKey={`accordion-${parentIndex}-${index}${!item.children || !item.children.length ? '1' : ''}`}
                fetchRequestWithId={fetchRequestWithId}
                item={item}
              />
            )}
            {item.title}
            <ActionButtons permitedActions={permitedActions} item={item} handleButtonClick={handleButtonClick} />
          </Card.Header>
          {item.children && item.children.length > 0 && (
            <Accordion.Collapse eventKey={`accordion-${parentIndex}-${index}`}>
              <IterateArray
                childrenArray={item.children}
                parentIndex={index}
                handleButtonClick={handleButtonClick}
                permitedActions={permitedActions}
                fetchRequestWithId={fetchRequestWithId}
              />
            </Accordion.Collapse>
          )}
        </Card>
      </Col>
    </Row>
  ));
  if (nodes.length) {
    return (
      <Accordion className={`${onRequest ? 'bg-opacity' : ''}`} defaultActiveKey={`accordion-${parentIndex}-0`}>
        {nodes}
      </Accordion>
    );
  }
  if (nodes.length === 0 && !onRequest) {
    return <p className="msg-empty">No se encontraron datos.</p>;
  }
  return nodes;
};

const AccordionTree = ({ items, handleButtonClick, permitedActions, fetchRequestWithId, onRequest }) => (
  <IterateArray
    childrenArray={items}
    parentIndex={0}
    handleButtonClick={handleButtonClick}
    permitedActions={permitedActions}
    fetchRequestWithId={fetchRequestWithId}
    onRequest={onRequest}
    root
  />
);

export default AccordionTree;
