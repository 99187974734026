import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Card, Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { showProfileRequest } from '../../../requests/adminProfiles';
import { LinkBtn, Icon } from '../../../components';
import basicProfile from '../../Role/role';

const RoleShow = ({ match }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [profile, setProfile] = useState(basicProfile);
  const profileId = match.params.id;
  const dispatch = useDispatch();

  const fetchRole = () => {
    const request = async () => {
      setOnRequest(true);
      await showProfileRequest(profileId, {
        dispatch,
        successCallback: response => {
          setProfile(camelCaseRecursive(response.data));
          setOnRequest(false);
        }
      });
    };
    request();
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchRole, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/admin/roles">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">{profile.name}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="warning" className="mt-3" block to={`/admin/roles/${profile.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} className="d-flex">
          <Card className="flex-fill">
            <Card.Body>
              <Card.Title>Información</Card.Title>
              <div className="info">
                <span className="type">Nombre:</span>
                <span className="line" />
                <span className="answer">{profile.name}</span>
              </div>
              <Card.Title>Permisos</Card.Title>
              <div className="default-scroll-box lower-height">
                {profile.roles.map(role => (
                  <div className="info" key={`role-${role.value}`}>
                    {role.label}
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RoleShow;
