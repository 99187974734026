import React, { useState } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import AttendanceReportIndex from './AttendanceReportIndex';
import WorkdayReportIndex from './WorkdayReportIndex';
import OffdaysReportIndex from './OffdaysReportIndex';
import ShiftReportIndex from './ShiftReportIndex';
import OvertimeReportIndex from './OvertimeReportIndex';
import { Icon } from '../../components';
import { useSetTab } from '../../services/hooks';
import { AbilityContext } from '../../config/abilityContext';

const LegalReports = ({ location }) => {
  const [moreData, setMoreData] = useState(false);
  const [key, setKey] = useSetTab('attendance-report', location);
  const ability = useAbility(AbilityContext);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Reportes Legales</h2>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('index', 'AttendanceReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="attendance-report">
                <Icon icon="location" />
                Reporte de Asistencia
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'WorkdayReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="workday-report">
                <Icon icon="location" />
                Reporte de Jornada Diaria
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'HolidayReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="offday-report">
                <Icon icon="location" />
                Reporte de días domingo y/o días festivos
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'ShiftReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="shift-report">
                <Icon icon="location" />
                Reporte de modificaciones y/o alteraciones de turnos.
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="attendance-report">
            <AttendanceReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="workday-report">
            <WorkdayReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="offday-report">
            <OffdaysReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="shift-report">
            <ShiftReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="overtime-report">
            <OvertimeReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default LegalReports;
