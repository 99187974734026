import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, DatatableEmployeeName, ContractBalanceStatus } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'Nº',
    selector: 'contract_correlative_identification',
    cell: item => item.correlative_identification,
    sortable: true,
    grow: '0.1',
    center: true
  },
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'current_status',
    cell: item => (
      <ContractBalanceStatus status={item.current_status} margin="my-0" onClick={() => clickHandler(item, 'show')} />
    ),
    grow: '0.5',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'show')}
        variant="circle-info"
        className="btn-circle"
        size="sm"
        text="Ver"
      >
        <Icon icon="eye" />
      </ButtonTooltip>
    ),
    ignoreRowClick: true,
    grow: '1.5',
    minWidth: '185px',
    right: true
  }
]);

export default columns;
