import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner, Carousel } from 'react-bootstrap';
import moment from 'moment';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, LongCard, ButtonTooltip, Icon, CustomGraph, DefaultModal } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import {
  debounceIndexSelectionProcessesRequest,
  selectionProcessCardsRequest,
  deleteSelectionProcessRequest
} from '../../requests/selectionProcesses';

const SelectionProcessDataTable = props => {
  const [selectionProcesses, setSelectionProcesses] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [destroyItem, setDestroyItem] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [cards, setCards] = useState({});
  const [customParams, setCustomParams] = useState({});
  const [activeCard, setActiveCard] = useState('all');
  const [defaultStartDate, setDefaultStartDate] = useState(null);

  const { columns, moreData, setMoreData } = props;

  const fetchIndexCards = () => {
    const request = async () => {
      selectionProcessCardsRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada correctamente' }));
    setMoreData(!moreData);
  };

  const handleSelectionProcessDelete = () => {
    setOnRequest(true);
    deleteSelectionProcessRequest(destroyItem.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleSuccessIndex = response => {
    const responseSelectionProcesses = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setSelectionProcesses(responseSelectionProcesses);
    setAmount(responseAmount);
    setOnRequest(false);
  };

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams
    };
    const request = async () =>
      debounceIndexSelectionProcessesRequest({
        dispatch,
        params: sendParams,
        successCallback: handleSuccessIndex
      });
    request();
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/selection_processes/${item.id}`);
        break;
      case 'edit':
        history.push(`/selection_processes/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setDestroyItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleClick = card => {
    switch (card) {
      case 'active':
        setCustomParams({ status: 'active' });
        setActiveCard('active');
        setDefaultStartDate(moment().startOf('year'));
        break;
      case 'suspended':
        setCustomParams({ status: 'suspended' });
        setActiveCard('suspended');
        setDefaultStartDate(moment().startOf('year'));
        break;
      case 'deserted':
        setCustomParams({ status: 'deserted' });
        setActiveCard('deserted');
        setDefaultStartDate(moment().startOf('year'));
        break;
      case 'closed':
        setCustomParams({ status: 'closed' });
        setActiveCard('closed');
        setDefaultStartDate(moment().startOf('year'));
        break;
      case 'generated':
        setCustomParams({ status: 'generated' });
        setActiveCard('generated');
        setDefaultStartDate(moment().startOf('year'));
        break;
      default:
        setCustomParams({});
        setActiveCard('all');
        setDefaultStartDate(moment());
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'status':
        return { sort_status: name };
      case 'job_title_name':
        return { sort_job_titles: name };
      case 'job_management_name':
        return { sort_job_managements: name };
      default:
        return { sort_column: name };
    }
  };

  useEffect(fetchIndexCards, [moreData]);

  return (
    <>
      <Row className="mt-4 mb-3">
        <Col md={6}>
          <h3 className="mb-3 text-uppercase">Filtros</h3>
          <h4 className="text-uppercase mb-3">Postulantes en Proceso Activos: {cards.totalActivePostulations}</h4>
          <Row>
            <Col md={6} xs={6}>
              <LongCard
                variant="info"
                title={cards.total}
                label="Total"
                active={activeCard === 'all'}
                onClick={() => handleClick('all')}
              />
            </Col>
            <Col md={6} xs={6}>
              <LongCard
                variant="primary"
                title={cards.active}
                label="Activos"
                active={activeCard === 'active'}
                onClick={() => handleClick('active')}
              />
            </Col>
            <Col md={6} xs={6}>
              <LongCard
                variant="warning"
                title={cards.suspended}
                label="Suspendidos"
                active={activeCard === 'suspended'}
                onClick={() => handleClick('suspended')}
              />
            </Col>
            <Col md={6} xs={6}>
              <LongCard
                variant="warning"
                title={cards.deserted}
                label="Desiertos"
                active={activeCard === 'deserted'}
                onClick={() => handleClick('deserted')}
              />
            </Col>
            <Col md={6} xs={6}>
              <LongCard
                variant="danger"
                title={cards.closed}
                label="Cerrados"
                active={activeCard === 'closed'}
                onClick={() => handleClick('closed')}
              />
            </Col>
            <Col md={6} xs={6}>
              <LongCard
                variant="info"
                title={cards.generated}
                label="Generados"
                active={activeCard === 'generated'}
                onClick={() => handleClick('generated')}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <h3 className="mb-3 text-uppercase">Rendimiento</h3>
          <Row>
            <Col md={12} xs={12} className="selection-charts-container">
              {cards.candidateBySelectionProcess ? (
                <Carousel
                  indicators={false}
                  interval={null}
                  fade
                  prevIcon={
                    cards.candidateBySelectionProcess.length > 1 && (
                      <Icon width={20} style={{ color: 'black' }} icon="chevron-back" />
                    )
                  }
                  nextIcon={
                    cards.candidateBySelectionProcess.length > 1 && (
                      <Icon width={20} style={{ color: 'black' }} icon="chevron-forward" />
                    )
                  }
                >
                  {cards.candidateBySelectionProcess.map((element, index) => (
                    <Carousel.Item key={`candidate-graph-${index.toString()}`}>
                      <CustomGraph
                        customHeight={300}
                        chartType={element.chart}
                        data={element.data}
                        title={element.title}
                        activeAnimation={false}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <div className="containerSpinnerLoad">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {activeCard !== 'all' && (
        <Col xs={12}>
          <ButtonTooltip
            onClick={() => handleClick('all')}
            variant="link"
            className="text-primary"
            size="sm"
            text="Volver"
          >
            <Icon icon="chevron-back" width={15} />
            <span>Volver a tabla inicial</span>
          </ButtonTooltip>
        </Col>
      )}
      <ComponentDataTable
        defaultStartDate={defaultStartDate}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        totalRows={amount}
        data={selectionProcesses}
        moreData={moreData}
        onRequest={onRequest}
        resourceRequest={handleIndexRequest}
        customParams={customParams}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title="Eliminar Proceso de Selección"
        body="¿Estás seguro que deseas eliminar esta solicitud?"
        show={modalShow}
        disabled={onRequest}
        handleClose={() => setModalShow(false)}
        handleConfirm={handleSelectionProcessDelete}
        variantBtnSave="primary"
      />
    </>
  );
};

export default SelectionProcessDataTable;
