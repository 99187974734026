import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form, Modal, Button, Spinner, Col } from 'react-bootstrap';
import Dropzone from '../Utils/Upload/Dropzone';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../requests/dropzone';
import { sendAlert } from '../../actions/utils';
import { downloadFile, delayMethod } from '../../services/utils';

const ImportModal = ({
  updateData,
  onDropUploaded,
  handleTemplate,
  onHide,
  params,
  hideModal,
  sendNotifications,
  handleExceptions
}) => {
  const [onRequest, setOnRequest] = useState(false);
  const [document, setDocument] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    if (handleExceptions === undefined) {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    } else {
      handleExceptions(error);
    }
    setOnRequest(false);
    hideModal();
    updateData();
  };

  const handleOnDrop = async (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'import',
        documents
      }
    };
    setOnRequest(true);
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        setOnRequest(false);
        return response;
      }
    });
  };

  const handleOnDropUploaded = async uploadedDocuments => {
    setOnRequest(true);
    onDropUploaded({
      dispatch,
      params: { document_key: uploadedDocuments[0].code, ...params },
      successCallback: response => {
        setOnRequest(false);
        setWarnings(response.data.collisions);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleTemplateClick = async () => {
    setOnRequest(true);
    handleTemplate({
      dispatch,
      params,
      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleImport = async documentsValue => {
    setOnRequest(true);
    onHide({
      dispatch,
      params: { send_notification: checked, document_key: documentsValue[0].code, ...params },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Archivo importado con éxito' }));
        setWarnings([]);
        updateData();
        hideModal(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Form>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner ml-n2 ml-lg-n0 w-100">
          <Spinner className="mb-4" animation="border" variant="primary" />
        </div>
      )}
      <Form.Row>
        <Col xs={5} md={5} sm={5}>
          <Button variant="warning" className="text-uppercase mt-0 mb-3" onClick={handleTemplateClick}>
            Descargar Ejemplo
          </Button>
        </Col>
        {sendNotifications && (
          <>
            <Col xs={6} md={5} sm={5}>
              <p className="futura text-uppercase ml-md-1 mt-1 title-switch text-center text-md-right">
                Habilitar envio de notificaciones
              </p>
            </Col>
            <Col xs={1} md={2} sm={2}>
              <Form.Group onClick={() => setChecked(!checked)}>
                <Form.Check checked={checked} className="custom-checkbox" label="" type="switch" />
              </Form.Group>
            </Col>
          </>
        )}
      </Form.Row>
      <div className={`${onRequest && 'bg-opacity'}`}>
        <Dropzone
          customClass="import-modal"
          multiple={false}
          shortDropzone
          onDrop={handleOnDrop}
          onDropUploaded={files => {
            setDocument(files);
            handleOnDropUploaded(files);
          }}
          onDelete={file => {
            deleteDropZoneFileRequest(file.id, {
              dispatch,
              params: { dropZoneFile: { documentKey: file.code } }
            });
            setDocument([]);
            setWarnings([]);
          }}
          fileAccept=".xls, .xlsx"
        />
        <small className="text-muted form-text mt-1 mb-3">Tamaño máximo 20MB por archivo</small>
        {warnings.length > 0 && (
          <>
            <p>
              {' '}
              La siguiente información ya existe, al importar el archivo esta será actualizada, si está de acuerdo
              presione importar.
            </p>
            <div className="warning-message-box">
              {warnings.map((warning, index) => (
                <p key={`warning-message-${warning}`}>
                  {index + 1}. {warning}
                </p>
              ))}
            </div>
          </>
        )}
      </div>

      <Modal.Footer>
        <Button variant="outline-info" onClick={() => delayMethod(hideModal)}>
          Cancelar
        </Button>
        <Button disabled={document.length === 0 || onRequest} variant="primary" onClick={() => handleImport(document)}>
          Importar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

ImportModal.propTypes = {
  updateData: PropTypes.func,
  onDropUploaded: PropTypes.func.isRequired,
  handleTemplate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
  hideModal: PropTypes.func.isRequired,
  sendNotifications: PropTypes.bool
};

ImportModal.defaultProps = {
  updateData: () => null,
  params: {},
  sendNotifications: false
};

export default ImportModal;
