/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Image, Form, Row, Col } from 'react-bootstrap';
import Icon from '../../Icons';
import defaultImg from '../../../assets/images/default.png';
import './styles.scss';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      name: props.name || 'Buscar'
    };
  }

  handleClick = () => {
    this.imageInput.click();
  };

  _handleImageChange(e) {
    const { onChange } = this.props;
    e.preventDefault();
    if (e.target.files.length === 0) {
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        url: reader.result,
        name: file.name
      });
    };
    reader.readAsDataURL(file);
    onChange(file);
  }

  render() {
    const { url, name } = this.state;
    const {
      fileAccept,
      imageUrl,
      circleImage,
      error,
      inputFileId,
      touched,
      helpText,
      customClass,
      onTop,
      disabled
    } = this.props;
    return (
      <div className="form-group">
        <label className="text-truncate d-block d-sm-none" htmlFor={inputFileId}>
          {name}
        </label>
        <Row className="align-items-center">
          <Col md={onTop ? 12 : 3} className="mr-n3">
            <div
              className={`custom-file-img ${circleImage ? 'circle-image' : ''} ${onTop ? 'on-top' : ''}`}
              onClick={this.handleClick}
            >
              <div className="content">
                {url || imageUrl ? <Image src={url || imageUrl || defaultImg} fluid /> : <Icon icon="camera" />}
              </div>
            </div>
          </Col>
          <Col>
            <div className={`input-group ${customClass || ''}`}>
              <div className={`custom-file ${onTop ? 'on-top' : ''}`}>
                <input
                  disabled={disabled}
                  type="file"
                  className="custom-file-input secondary"
                  id={inputFileId}
                  ref={ref => {
                    this.imageInput = ref;
                  }}
                  aria-describedby="inputGroupFileAddon01"
                  onChange={e => this._handleImageChange(e)}
                  accept={fileAccept}
                />
                <label
                  className={`custom-file-label text-truncate ${error && touched ? 'error' : ''}`}
                  htmlFor={inputFileId}
                >
                  {name}
                </label>
              </div>
            </div>
            {error && touched && <Form.Text className="text-danger mb-2">{error}</Form.Text>}
            {helpText && <Form.Text className="mt-2 text-muted">{helpText}</Form.Text>}
          </Col>
        </Row>
      </div>
    );
  }
}

UploadImage.defaultProps = {
  onChange: () => {},
  inputFileId: 'inputGroupFile01'
};

export default UploadImage;
