const timeLineElements = [
  {
    title: 'Jhon Doe',
    body: 'Observacion 1',
    type: 'Firma',
    status: 'Approved',
    dateTime: '11/01/2021 10:20:03',
    key: 1
  },
  {
    title: 'Lyn Fitcher',
    body: 'Observacion 2',
    type: 'Aprobación',
    status: 'Rejected',
    dateTime: '11/01/2021 15:20:03',
    key: 2
  },
  {
    title: 'Mary Oldman',
    body: 'Observacion 3',
    type: 'Aprobación',
    status: 'Pending',
    dateTime: '11/01/2021 17:20:03',
    key: 3
  }
];

export default timeLineElements;
