import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const SkillCard = ({ skill, objective }) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={3}>
            <h6 className="text-uppercase mb-3">Nombre</h6>
            {skill.name}
          </Col>
          {objective ? (
            <>
              <Col xs={4}>
                <h6 className="text-uppercase mb-3">Descripción</h6>
                {skill.description}
              </Col>
              <Col xs={2}>
                <h6 className="text-uppercase mb-3">Plazo</h6>
                {skill.endDate}
              </Col>
            </>
          ) : (
            <>
              <Col xs={4}>
                <h6 className="text-uppercase mb-3">Definición</h6>
                {skill.description}
              </Col>
              <Col xs={2}>
                <h6 className="text-uppercase mb-3">Tipo</h6>
                {skill.translatedCompetenceType}
              </Col>
            </>
          )}
          <Col xs={3}>
            <h6 className="text-uppercase mb-3">Cumplimiento Requerido</h6>
            {skill.parsedRequireAmount}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const PerformanceEvaluationDetails = ({ performanceEvaluation }) => {
  return (
    <>
      <hr />
      <Row>
        <Col xs={12}>
          <h4 className="text-uppercase mb-3">Competencias</h4>
          <div className="default-scroll-box comment-height">
            {performanceEvaluation.skillsAttributes
              .filter(item => item.skillType === 'competence')
              .map(skill => (
                <SkillCard skill={skill} key={skill.id} />
              ))}
          </div>
        </Col>
        {performanceEvaluation.withObjectives && (
          <Col xs={12}>
            <h4 className="text-uppercase mb-3">Objetivos</h4>
            <div className="default-scroll-box comment-height">
              {performanceEvaluation.skillsAttributes
                .filter(item => item.skillType === 'objective')
                .map(skill => (
                  <SkillCard skill={skill} key={skill.id} objective />
                ))}
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default PerformanceEvaluationDetails;
