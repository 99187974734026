import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ReviewerTemplateInfo = ({ reviewer }) => {
  const employees = reviewer.reviewerTemplateIds.map(employee => employee.label).join(', ');
  return (
    <p className="info">
      <span>
        {reviewer.position} - {reviewer.approval ? 'Aprobación' : 'Firma'}
      </span>{' '}
      {employees}
    </p>
  );
};

const WorkflowTemplateShow = ({ workflowTemplate, handleClose }) => (
  <>
    <Modal.Body>
      <div className="info-box">
        {workflowTemplate.name && (
          <p className="info">
            <span>Nombre:</span>
            {workflowTemplate.name}
          </p>
        )}
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Pasos a Seguir</h4>
        <p className="info">
          <span>Nº - Acción</span> <span>Responsables</span>
        </p>
        {workflowTemplate.reviewerTemplatesAttributes.map(reviewer => (
          <ReviewerTemplateInfo reviewer={reviewer} key={`reviewer-${reviewer.id}`} />
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-info" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </>
);

export default WorkflowTemplateShow;
