import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import CompanyTaskForm from '../../Shared/CompanyTask/CompanyTaskForm';
import { createCompanyTaskRequest, updateCompanyTaskRequest } from '../../../requests/companyTasks';
import basicTask from '../../Shared/CompanyTask/task';

const CompanyTaskNewProfile = ({
  isEmployee,
  setModalShowTask,
  moreData,
  setMoreData,
  modalShow,
  selectedDate,
  notSendNotification,
  task,
  setTask
}) => {
  const { currentEmployee } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const formRequest = (taskValues, setSubmitting) => {
    if (task?.itemId) {
      updateCompanyTaskRequest(task.itemId, {
        dispatch,
        params: snakeCaseKeys(taskValues),
        successCallback: () => {
          dispatch(sendAlert({ kind: 'success', message: 'Tarea actualizada con éxito' }));
          history.push('/profile/announcements?tab=calendar');
          if (modalShow) {
            setMoreData(!moreData);
            setModalShowTask(false);
            setTask(undefined);
          }
        },
        callback: () => setSubmitting(false)
      });
    } else {
      createCompanyTaskRequest({
        dispatch,
        params: snakeCaseKeys(taskValues),
        successCallback: () => {
          dispatch(sendAlert({ kind: 'success', message: 'Tarea creada con éxito' }));
          history.push('/profile/announcements?tab=calendar');
          if (modalShow) {
            setMoreData(!moreData);
            setModalShowTask(false);
          }
        },
        callback: () => setSubmitting(false)
      });
    }
  };

  return (
    <>
      <Row className={`${!isEmployee && 'my-4'}`}>
        {!isEmployee && (
          <>
            <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/profile/announcements?tab=calendar">
              <Icon icon="chevron-back" />
            </LinkBtn>
            <h2 className="text-uppercase ml-1 mt-3 d-inline">Crear Tarea</h2>
          </>
        )}
      </Row>
      <CompanyTaskForm
        companyTask={task || { ...basicTask, employeeId: currentEmployee }}
        action="new"
        formRequest={formRequest}
        isEmployee={isEmployee}
        rrhh={false}
        selectedDate={selectedDate}
        notSendNotification={notSendNotification}
        currentEmployee={currentEmployee}
      />
    </>
  );
};

export default CompanyTaskNewProfile;
