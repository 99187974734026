import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Image, Nav, Row, Tab, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { useHistory } from 'react-router-dom';

import previredLogo from '../../assets/images/previred_logo.png';
import { BasicTooltip, CustomGraph, Icon, LinkBtn, LongCard } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { downloadFile, camelCaseEmptyStringRecursive } from '../../services/utils';
import {
  showPayrollProcessRequest,
  closedPayrollProcessRequest,
  exportPreviredRequest
} from '../../requests/payrollProcesses';
import { useSetTab } from '../../services/hooks';
import PayrollGroupDataTable from '../PayrollGroup/PayrollGroupDataTable';
import PayrollDataTable from '../Payroll/PayrollDataTable';
import basicPayrollProcess from './payrollProcess';

const PayrollProcessShow = ({ match, location }) => {
  const { id } = match.params;
  const ability = useAbility(AbilityContext);
  const [key, setKey] = useSetTab('payroll-groups', location);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [payrollProcess, setPayrollProcess] = useState(basicPayrollProcess);
  const dispatch = useDispatch();
  const history = useHistory();

  const graphColors = ['#29B48F', '#FB7474'];
  const graphLegend = { layout: 'vertical', verticalAlign: 'middle', align: 'right' };
  const closedProcess = payrollProcess.status === 'closed';
  const nPayrollGroups = payrollProcess.payrollGroupsCount;

  const successCallback = useCallback(response => setPayrollProcess(camelCaseEmptyStringRecursive(response.data)), []);

  const fetchPayrollProcess = () => {
    showPayrollProcessRequest(id, { dispatch, successCallback, callback: () => setOnRequest(false) });
  };

  const closedPayrollProcess = () => {
    setOnRequest(true);
    closedPayrollProcessRequest(id, { dispatch, successCallback, callback: () => setOnRequest(false) });
  };

  const exportPrevired = () => {
    setOnRequest(true);
    exportPreviredRequest(id, {
      dispatch,
      successCallback: response => downloadFile(response),
      callback: () => setOnRequest(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchPayrollProcess, [moreData]);

  const monthStatus = useMemo(() => {
    if (payrollProcess.status === 'closed') {
      return <h4 className="text-uppercase text-primary my-0">MES CERRADO</h4>;
    }
    if (payrollProcess.status === 'prenomina') {
      return <h4 className="text-uppercase text-danger my-0">MES NO CERRADO</h4>;
    }
    return '';
  }, [payrollProcess]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute h-100" style={{ bottom: '35px', left: '130px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to={`/payroll_processes?year=${payrollProcess.year}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={7} className="mt-3">
          <h2 className="text-uppercase mt-1">Proceso de Remuneración {payrollProcess.month}</h2>
          {monthStatus}
        </Col>
        <Col md={2} className="mt-3">
          <Button block variant="warning" disabled={!closedProcess}>
            Solicitar Firmas
          </Button>
        </Col>
        <Col md={2} className="mt-3">
          <Button block variant="info" disabled={closedProcess} onClick={closedPayrollProcess}>
            Cerrar Mes
          </Button>
        </Col>
      </Row>
      {nPayrollGroups > 0 && (
        <>
          <Row>
            <Col md={4}>
              <CustomGraph
                customizedLabel
                chartType="pie"
                title="Liquidación por Trabajador"
                data={[
                  { name: 'Con Liquidación', value: payrollProcess.totalPayrolls },
                  { name: 'Sin Liquidación', value: payrollProcess.totalEmployeesWithoutPayroll }
                ]}
                customColors={graphColors}
                pieLegend={graphLegend}
                activeAnimation={false}
              />
            </Col>
            {closedProcess && (
              <Col md={{ span: 4, offset: 1 }}>
                <CustomGraph
                  customizedLabel
                  chartType="pie"
                  title="Firmas"
                  data={[
                    { name: 'Firmadas', value: 33 },
                    { name: 'No Firmadas', value: 67 }
                  ]}
                  customColors={graphColors}
                  pieLegend={graphLegend}
                  activeAnimation={false}
                />
              </Col>
            )}
          </Row>
          <hr />
          <Row className="mt-4">
            <Col md={3} sm={4} xs={12}>
              <LongCard
                variant="primary"
                label="Líquido a pagar"
                title={payrollProcess.liquidTotal}
                paddingContent="p-0"
                customSize="font-24"
              />
            </Col>
            <Col md={3} sm={4} xs={12}>
              <LongCard
                variant="danger"
                label="Sobregiros"
                title={payrollProcess.overdraftTotal}
                paddingContent="p-0"
                customSize="font-24"
              />
            </Col>
            <Col md={3} sm={4} xs={12}>
              <LongCard
                variant="info"
                label="Total haberes"
                title={payrollProcess.assetTotal}
                paddingContent="p-0"
                customSize="font-24"
              />
            </Col>
            <Col md={3} sm={4} xs={12}>
              <LongCard
                variant="info"
                label="Total descuentos"
                title={payrollProcess.discountTotal}
                paddingContent="p-0"
                customSize="font-24"
              />
            </Col>
            <Col md={3} sm={4} xs={12}>
              <LongCard
                variant="warning"
                label="Total impuestos"
                title={payrollProcess.taxTotal}
                paddingContent="p-0"
                customSize="font-24"
              />
            </Col>
          </Row>
          {ability.can('report', 'PayrollProcess') && (
            <>
              <div className="d-flex align-items-center">
                <h4 className="text-uppercase text-dark pl-4 my-2">Acciones</h4>
                {!closedProcess && (
                  <BasicTooltip toolbarVariant="ml-1 gray-tooltip" text="Cerrar mes para abilitar las acciones">
                    <Icon icon="help-circle" height="15px" width="15px" />
                  </BasicTooltip>
                )}
              </div>
              <Card>
                <Card.Body className="px-4 pt-4 pb-0">
                  <Row>
                    <Col md={5}>
                      <Row>
                        <Col md={6} className="text-uppercase py-1">
                          <Image src={previredLogo} width="150px" />
                        </Col>
                        <Col md={6} className="mb-4">
                          <Button block variant="primary" disabled={!closedProcess} onClick={() => exportPrevired()}>
                            Generar Archivo
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 1 }}>
                      <Row>
                        <Col md={7} className="text-uppercase py-1">
                          <h4 className="text-uppercase text-dark m-0">Documentos Bancarios</h4>
                        </Col>
                        <Col md={5} className="mb-4">
                          <Button
                            variant="primary"
                            block
                            className={!closedProcess ? 'disabled' : ''}
                            onClick={() => {
                              history.push({
                                pathname: '/bank_documents',
                                state: { ids: id, backUrl: location.pathname, type: 'payroll_process' }
                              });
                            }}
                          >
                            Generar Documentos
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <Row>
                        <Col md={6} className="text-uppercase py-1">
                          <h4 className="text-uppercase text-dark m-0">Reportes Legales</h4>
                        </Col>
                        <Col md={6} className="mb-4">
                          <Button
                            block
                            variant="primary"
                            className={!closedProcess ? 'disabled' : ''}
                            onClick={() => {
                              history.push({
                                pathname: `/payroll_processes/${id}/legal_reports`,
                                state: { employeeIds: payrollProcess.includedEmployees }
                              });
                            }}
                          >
                            Generar Reportes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          )}
        </>
      )}

      <Row className="d-flex justify-content-end my-4">
        <Col md={7}>
          <Row>
            <Col md={4} className="mb-3">
              <Button block variant="outline-info" disabled>
                Comparador
              </Button>
            </Col>
            <Col md={4} className="mb-3">
              <LinkBtn
                block
                variant="primary"
                className={closedProcess ? 'disabled' : ''}
                to={`/payroll_processes/${id}/payroll_groups/new`}
              >
                Nueva Prenómina
              </LinkBtn>
            </Col>
            <Col md={4} className="mb-3">
              <Button block variant="info" disabled>
                Cargar Grupo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Tab.Container id="payroll-datatables.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="payroll-groups">Grupos liquidaciones</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="payrolls">Por trabajador</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="payroll-groups">
            <PayrollGroupDataTable payrollProcessId={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="payrolls">
            <PayrollDataTable
              payrolls={payrollProcess.payrolls}
              payrollProcessId={id}
              moreData={moreData}
              setMoreData={setMoreData}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
export default PayrollProcessShow;
