import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { debounceIndexAnnouncementRequest } from '../../../requests/announcements';
import { debounceIndexCompanyTaskRequest } from '../../../requests/companyTasks';
import { Calendar, SimpleCenteredModal } from '../../../components';
import AnnouncementModalShow from '../../Announcement/AnnouncementModalShow';
import CompanyTaskNewProfile from '../CompanyTask/CompanyTaskNewProfile';

const dateToIso = date =>
  date
    .split('/')
    .reverse()
    .join('-');

const eventProps = (item, eventType) => {
  let color = '#186868';
  let sDate = dateToIso(item.startDate);
  let eDate = dateToIso(item.endDate);
  if (item.startDate !== item.endDate) {
    sDate = `${sDate}T00:00:00`;
    eDate = `${eDate}T23:59:59`;
  }
  if (eventType === 'task') {
    color = item.status === 'pending' ? '#ffc107' : '#29b48f';
  }
  return { id: item.id, itemId: item.id, title: item.title, start: sDate, end: eDate, color, eventType };
};

const addEventProps = (response, eventType = 'announcement') => {
  return camelCaseEmptyStringRecursive(response.data.data).map(item => ({ ...item, ...eventProps(item, eventType) }));
};

const AnnouncementCalendar = ({ moreData, setMoreData, currentEmployee }) => {
  const ability = useAbility(AbilityContext);
  const [announcements, setAnnouncements] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [task, setTask] = useState({});
  const [modalShowTask, setModalShowTask] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const dispatch = useDispatch();
  const showTasks = ability.can('index', 'CompanyTask');
  const createTasks = ability.can('create', 'CompanyTask');

  const customParams = useMemo(
    () => ({
      range_date: [
        moment()
          .startOf('month')
          .format('DD/MM/YYYY'),
        moment()
          .endOf('month')
          .format('DD/MM/YYYY')
      ],
      paginate: false,
      filter_employee: currentEmployee
    }),
    [currentEmployee]
  );

  const fetchAnnouncements = params => {
    debounceIndexAnnouncementRequest({
      dispatch,
      params: {
        ...customParams,
        ...params,
        status: 'visible',
        announcement_types: 'event'
      },
      successCallback: response => {
        setAnnouncements(addEventProps(response));
        if (!showTasks) setOnRequest(false);
      }
    });
  };

  const fetchCompanyTasks = params => {
    debounceIndexCompanyTaskRequest({
      dispatch,
      params: { ...customParams, ...params },
      successCallback: response => setTasks(addEventProps(response, 'task')),
      callback: () => setOnRequest(false)
    });
  };

  const handleRequest = params => {
    setEvents([]);
    if (currentEmployee) {
      setOnRequest(true);
      fetchAnnouncements(params);
      if (showTasks) {
        fetchCompanyTasks(params);
      }
    }
  };

  const eventClick = ({ event }) => {
    const { extendedProps, title } = event._def;
    if (extendedProps.eventType === 'task' && ability.can('update', 'CompanyTask')) {
      setTask({ ...extendedProps, title });
      setModalShowTask(true);
    } else {
      setModalItem({ ...extendedProps, title });
      setModalShow(true);
    }
  };

  const handleDateClick = date => {
    if (createTasks) setModalShowTask(true);
    setSelectedDate(moment(date.dateStr).format('DD/MM/YYYY'));
  };

  const calendarLegend = () => (
    <Col xs={12} md={{ offset: 1, span: 5 }}>
      <p className="text-muted mb-1">
        <span className="mr-1 dot dot-info" /> Evento
      </p>
      <p className="text-muted mb-1">
        <span className="mr-1 dot dot-primary" /> Tarea completada
      </p>
      <p className="text-muted mb-1">
        <span className="mr-1 dot dot-warning" /> Tarea Pendiente
      </p>
    </Col>
  );

  useEffect(handleRequest, [currentEmployee]);
  useEffect(() => setEvents([...announcements, ...tasks]), [tasks, announcements]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <hr />
      <Row className={`${onRequest && 'bg-opacity'}  mb-3`}>
        <Col>
          <Calendar
            lastEvents={false}
            request={handleRequest}
            events={events}
            moreData={moreData}
            eventClick={eventClick}
            dateClick={handleDateClick}
            moreLinkContent={args => `Ver más (${args.num})`}
            dayMaxEvents={3}
            customHead={calendarLegend}
          />
        </Col>
      </Row>
      <SimpleCenteredModal
        title={modalItem.translatedAnnouncementType || 'Tarea'}
        body={<AnnouncementModalShow announcement={modalItem} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SimpleCenteredModal
        title="Tarea"
        body={
          <CompanyTaskNewProfile
            setModalShowTask={setModalShowTask}
            moreData={moreData}
            setMoreData={setMoreData}
            selectedDate={selectedDate}
            isEmployee
            modalShow
            notSendNotification
            task={task}
            setTask={setTask}
          />
        }
        show={modalShowTask}
        size="md"
        onHide={() => {
          setModalShowTask(false);
          setTask(undefined);
          setSelectedDate('');
        }}
      />
    </>
  );
};

export default AnnouncementCalendar;
