import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';

import '../../../services/yupCustomMethods';
import { BankAccount, FormikCheckBox, FormikInput, FormikSelect, InputSelect, FormikNumber } from '../../../components';
import { debounceIndexBookkeepersRequest } from '../../../requests/bookkeepers';
import {
  amountTypeEnum,
  calculationTypeAssetEnum,
  calculationTypeDisEnum,
  defaultToShowEnum,
  discountTypeEnum,
  ineCodeEnum,
  remunerationTypeEnum,
  unitOfAccountEnum,
  judicialRetentionEnum
} from './FormOptions';

const BalanceForm = props => {
  const dispatch = useDispatch();
  const {
    balance,
    errors,
    onHide,
    setFieldValue,
    setFieldTouched,
    submitVariant,
    touched,
    values,
    isSubmitting
  } = props;
  const {
    advanceGratification,
    amountType: vAmountType,
    balanceType,
    calculationType: vCalculationType,
    discountType: vDiscountType,
    paymentReceptor,
    unitOfAccount: vUnitOfAccount
  } = values.balance;
  const {
    calculationType,
    defaultToShow,
    discountType,
    ineCode,
    remunerationType,
    amountType,
    unitOfAccount
  } = balance;
  const asset = balanceType && balanceType === 'asset';
  const distribute = vAmountType && vAmountType === 'distribute';
  const title = asset ? 'Haber' : 'Descuento';
  const sindicalQuota = vDiscountType === 'sindical_quota';
  const judicialRetention = vDiscountType === 'judicial_retention';
  const calculationTypeDiscountEnum =
    !asset && !sindicalQuota ? calculationTypeDisEnum : [{ label: 'Fijo', value: 'fixed' }];
  const calculationTypeEnum = asset ? calculationTypeAssetEnum : calculationTypeDiscountEnum;

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: element.name,
      value: element.id
    }));
  };

  const fetchData = (inputValue, callback) => {
    debounceIndexBookkeepersRequest({
      dispatch,
      params: {
        query: inputValue,
        paginate: false
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const handleFieldValues = ({ sindical, judicial, cleanUnit, defineAmount, amountValue = '' }) => {
    if (sindical) setFieldValue('balance[calculationType]', '');
    if (judicial) setFieldValue('balance[paymentReceptor]', true);
    if (cleanUnit) setFieldValue('balance[unitOfAccount]', '');
    if (defineAmount) {
      setFieldValue('balance[amount]', amountValue);
      setFieldValue('balance[parsedAmount]', amountValue);
    }
  };

  const clearAmount = () => {
    const judicialRetentionUnit = ['liquid_reach', 'min_wage', 'no_remunerational', 'utm'].includes(vUnitOfAccount);
    if (!judicialRetention && judicialRetentionUnit) handleFieldValues({ cleanUnit: true, defineAmount: true });
    if (discountType !== vDiscountType || amountType !== vAmountType) handleFieldValues({ defineAmount: true });
    if (sindicalQuota && vCalculationType !== 'fixed') handleFieldValues({ sindical: true });
    if (judicialRetention) handleFieldValues({ judicial: true });
  };

  const clearPaymentOptions = () => {
    if (!paymentReceptor) {
      setFieldValue('balance[paymentType]', '');
      setFieldValue('balance[bankId]', '');
      setFieldValue('balance[bankAccount]', '');
      setFieldValue('balance[bankAccountType]', '');
      setFieldValue('balance[compensationFundId]', '');
      setFieldValue('balance[receptorEmail]', '');
    }
  };

  const onChangeAdvanceGratification = () => {
    if (advanceGratification) {
      setFieldValue('balance[taxable]', true);
      setFieldValue('balance[tributable]', true);
      setFieldValue('balance[mobilizationAssignment]', false);
    }
  };

  useEffect(clearAmount, [vDiscountType, vAmountType]);
  useEffect(clearPaymentOptions, [paymentReceptor]);
  useEffect(onChangeAdvanceGratification, [advanceGratification]);

  const addon = { uf: 'UF', utm: 'UTM', liquid_reach: '%', min_wage: '%', no_remunerational: '%' };
  const leftAddon = addon[vUnitOfAccount] || '$';

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="balance[code]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={`Código ${title}`}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="balance[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={`Nombre ${title}`}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {!asset && (
        <Row>
          <Col md={6}>
            <Field name="balance[discountType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Descuento"
                  placeholder="Seleccionar Tipo de Descuento"
                  options={discountTypeEnum}
                  defaultValue={discountType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      )}
      <Row>
        {asset ? (
          <>
            <Col md={6}>
              <Field name="balance[taxable]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Imponible"
                    custom
                    type="switch"
                    disabled={advanceGratification}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="balance[tributable]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Tributable"
                    custom
                    type="switch"
                    disabled={advanceGratification}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="balance[overdraft]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Sobregiro"
                    custom
                    type="switch"
                    tooltipClass="ml-2 mt-2"
                    tooltipText="Seleccionar si el haber no permite ser descontado del sobregiro (ejemplo: asignación familiar)."
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="balance[benefit]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Permitir asignación vía beneficios"
                    custom
                    type="switch"
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="balance[mobilizationAssignment]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Asignación de Movilización"
                    custom
                    type="switch"
                    disabled={advanceGratification}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="balance[advanceGratification]">
                {({ field }) => (
                  <FormikCheckBox {...field} field={field} label="Anticipo de Gratificación" custom type="switch" />
                )}
              </Field>
            </Col>
          </>
        ) : (
          <>
            <Col md={6}>
              <Field name="balance[settlementConsideration]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Considerar para el cálculo del finiquito"
                    custom
                    type="switch"
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="balance[paymentReceptor]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Receptor de pago"
                    custom
                    tooltipClass="ml-2 mt-2"
                    type="switch"
                    tooltipText="Seleccionar para permitir pagar el item a un tercero."
                    disabled={judicialRetention}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        <Col md={6}>
          <Field name="balance[active]">
            {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" custom type="switch" />}
          </Field>
        </Col>
        {!asset && vDiscountType === 'voluntary' && (
          <Col md={6}>
            <Field name="balance[overdraft]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Sobregiro"
                  custom
                  type="switch"
                  tooltipClass="ml-2 mt-2"
                  tooltipText="Seleccionar para permitir que este ítem genere sobregiros"
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <Field name="balance[calculationType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Forma de Cálculo"
                placeholder="Seleccionar Forma de Cálculo"
                tooltipText="Fijo: el monto es el mismo independiente el N° de días que trabajó la persona. Proporcional Ausencia: el monto se proporciona de acuerdo a los días trabajados."
                options={calculationTypeEnum}
                defaultValue={calculationType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {asset && (
          <>
            <Col md={6}>
              <Field name="balance[remunerationType]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Tipo de Remuneración"
                    placeholder="Seleccionar Tipo de Remuneración"
                    tooltipText="Para efectos del cálculo del finiquito, considerar el concepto como ocasional (no se incluye),
                                    fijo (último mes), variable (promedio últimos 3 meses)."
                    options={remunerationTypeEnum}
                    defaultValue={remunerationType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        <Col md={6}>
          <Field name="balance[bookkeeperId]">
            {({ field }) => (
              <InputSelect
                {...field}
                isClearable
                label="Cuenta Contable"
                placeholder="Seleccionar Cuenta Contable"
                values={values.balance}
                model={[balance, 'bookkeeper']}
                request={fetchData}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {asset && (
          <Col md={6}>
            <Field name="balance[ineCode]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Agrupación INE"
                  placeholder="Seleccionar Agrupación INE"
                  tooltipText="Código de agrupación de cargos para las empresas que deben responder la
                                  Encuesta INE Mensual de Remuneraciones y Costo de la Mano de Obra.
                                  Si completa este campo podrá generar automáticamente dicho informe."
                  options={ineCodeEnum}
                  defaultValue={ineCode}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <Field name="balance[calculationOrder]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                min={0}
                label="Orden en la liquidación"
                tooltipText="Define el orden en que se mostrará en la liquidación."
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="balance[defaultToShow]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Mostrar de Forma Predeterminada"
                placeholder="Seleccionar Predeterminado"
                options={defaultToShowEnum}
                defaultValue={defaultToShow}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {paymentReceptor && (
        <>
          <h4 className="text-uppercase">Receptor de Pago</h4>
          <Row>
            <BankAccount modelKey="balance" currentModel={balance} paymentTypeAbbr withCompensationFund withEmail />
          </Row>
        </>
      )}
      <h4 className="text-uppercase">Monto</h4>
      <Row>
        <Col md={4}>
          <Field name="balance[amountType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Monto a Asignar"
                placeholder="Seleccionar Monto a Asignar"
                options={amountTypeEnum}
                defaultValue={amountType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="balance[unitOfAccount]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Moneda"
                placeholder="Seleccionar Moneda"
                options={judicialRetention ? judicialRetentionEnum : unitOfAccountEnum}
                defaultValue={unitOfAccount}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {distribute && (
          <Col md={4}>
            <Field name="balance[parsedAmount]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  leftAddon={leftAddon}
                  decimalScale={leftAddon === '$' ? 0 : 2}
                  fieldName="balance[amount]"
                  label="Monto"
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>

      <Row className="d-flex justify-content-end mt-1 mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block disabled={isSubmitting} onClick={onHide}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { balance } = props;
  return { balance };
};

const validationSchema = Yup.object().shape({
  balance: Yup.object().shape({
    active: Yup.boolean(),
    advanceGratification: Yup.boolean(),
    amount: Yup.number().when('amountType', {
      is: val => val === 'distribute',
      then: Yup.number()
        .required('Debes ingresar un monto')
        .positive('Debe ser mayor a 0'),
      otherwise: Yup.number().nullable()
    }),
    amountType: Yup.string().required('Debes seleccionar un monto a asignar'),
    balanceType: Yup.string().required('Debes seleccionar si es haber o descuento'),
    bankAccount: Yup.string().when('paymentType', {
      is: val => val === 'bank_transfer',
      then: Yup.string()
        .required('Debes ingresar una cuenta bancaria')
        .typeError('Debes ingresar una cuenta bancaria'),
      otherwise: Yup.string().nullable()
    }),
    bankAccountType: Yup.string().when('paymentType', {
      is: val => val === 'bank_transfer',
      then: Yup.string()
        .required('Debes seleccionar un tipo de cuenta')
        .typeError('Debes seleccionar un tipo de cuenta'),
      otherwise: Yup.string().nullable()
    }),
    bankId: Yup.string().when('paymentType', {
      is: val => val === 'bank_transfer' || val === 'cashiers_check',
      then: Yup.string().required('Debes seleccionar un banco'),
      otherwise: Yup.string().nullable()
    }),
    benefit: Yup.boolean(),
    bookkeeperId: Yup.string().nullable(),
    calculationOrder: Yup.number()
      .min(0, 'Debe ser mayor o igual a 0')
      .nullable(),
    calculationType: Yup.string().required('Debes seleccionar una forma de cálculo'),
    code: Yup.string()
      .required('Debes ingresar un código')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    compensationFundId: Yup.string().when('paymentType', {
      is: val => val === 'compensation_fund_type',
      then: Yup.string().required('Debes seleccionar una caja de compensación'),
      otherwise: Yup.string().nullable()
    }),
    defaultToShow: Yup.string().required('Debes seleccionar un predeterminado'),
    discountType: Yup.string().when('balanceType', {
      is: val => val === 'discount',
      then: Yup.string().required('Debes seleccionar un tipo de descuento'),
      otherwise: Yup.string().nullable()
    }),
    ineCode: Yup.string().nullable(),
    mobilizationAssignment: Yup.boolean(),
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    overdraft: Yup.boolean(),
    paymentReceptor: Yup.boolean(),
    paymentType: Yup.string().when('paymentReceptor', {
      is: val => val === true,
      then: Yup.string().required('Debes seleccionar un tipo de cuenta'),
      otherwise: Yup.string().nullable()
    }),
    settlementConsideration: Yup.boolean(),
    receptorEmail: Yup.string()
      .email('Debes ingresar un email válido')
      .nullable(),
    remunerationType: Yup.string().when('balanceType', {
      is: val => val === 'asset',
      then: Yup.string().required('Debes seleccionar un tipo de remuneración'),
      otherwise: Yup.string().nullable()
    }),
    taxable: Yup.boolean(),
    tributable: Yup.boolean(),
    unitOfAccount: Yup.string().required('Debes seleccionar una moneda')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BalanceForm);
