export const basicDnc = {
  allowAccess: false,
  limitDate: '',
  jobManagementId: '',
  employeeId: '',
  name: '',
  period: '',
  employeeDncsAttributes: []
};

export const employeeDnc = {
  topicId: 1,
  dncId: '',
  employeeId: '',
  necessityAreaId: 1,
  depthLevel: 'low',
  urgencyLevel: 'low',
  suggestedDate: '',
  status: 'pending',
  cost: '',
  observation: ''
};
