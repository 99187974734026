import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { LinkBtn } from '../../components';
import Disconnect from './disconnect.png';

const Page404 = () => (
  <>
    <Row className="mx-5">
      <Col md={6} xs={12} className="align-self-center">
        <h1 className="text-dark">404</h1>
        <h4>La página que estás buscando no existe.</h4>
        <LinkBtn to="/" variant="primary">
          Volver
        </LinkBtn>
      </Col>
      <Col md={6}>
        <Image src={Disconnect} fluid alt="404" />
      </Col>
    </Row>
  </>
);

export default Page404;
