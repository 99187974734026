import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLogo from '../MainLogo';

const Footer = props => {
  const { settings } = props;
  return (
    // Le borre la clase "footer" para arreglarlo mientras.
    <Navbar className="" expand="lg" fixed="bottom" variant="light" bg="light">
      <Navbar.Brand as={Link} to="/">
        <MainLogo />
      </Navbar.Brand>
      <Navbar.Collapse>
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/contact">
            Formulario de contacto
          </Nav.Link>
        </Nav>
        <Navbar.Text>
          <a href="mailto:{settings.mainContactEmail}">{settings.mainContactEmail}</a>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  const { settings } = state.utils;
  return {
    ongoingRequest,
    signedIn,
    settings
  };
};

export default connect(mapStateToProps)(Footer);
