import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal } from '../../components';
import AnnouncementDataTable from './AnnouncementDataTable';
import AnnouncementForm from './AnnouncementForm';
import columns from './Columns';
import announcement from './announcement';
import { createAnnouncementRequest } from '../../requests/announcements';
import { sendAlert } from '../../actions/utils';

const AnnouncementIndex = () => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const toggleModal = () => setModalShow(!modalShow);

  const handleCreateRequest = (announcementValues, setSubmitting) => {
    const { image } = announcementValues.announcement;
    const myParams = snakeCaseKeys(announcementValues);
    myParams.announcement.image = image;
    createAnnouncementRequest({
      dispatch,
      params: myParams,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Anuncio creado con éxito' }));
        setMoreData(!moreData);
        setModalShow(false);
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-0">Fechas Destacadas</h2>
        </Col>
        <Col md={3}>
          <Button variant="primary" block onClick={toggleModal}>
            Nuevo
          </Button>
        </Col>
      </Row>
      <AnnouncementDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
      <SimpleCenteredModal
        title="Nueva Fecha Destacada"
        body={<AnnouncementForm announcement={announcement} action="new" formRequest={handleCreateRequest} />}
        show={modalShow}
        onHide={toggleModal}
      />
    </>
  );
};

export default AnnouncementIndex;
