import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexDocumentsRequest = ({ dispatch, params, successCallback, callback, format, responseType }) =>
  ApiService.request('get', `/documents${format}`, {
    dispatch,
    params,
    successCallback,
    responseType: responseType || 'json',
    callback
  });

export const showDocumentPreviewRequest = (id, { employeeId, dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/employees/${employeeId}/documents/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const publicShowDocumentPreviewRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/public/documents/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateDocumentRequest = (id, { employeeId, dispatch, params, callback, successCallback }) =>
  ApiService.request('put', `/employees/${employeeId}/documents/${id}`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const debounceIndexDocumentsRequest = AwesomeDebouncePromise(indexDocumentsRequest, 300);
