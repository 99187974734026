import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { InputSelect } from '../../components';
import { debounceIndexSelectionProcessesRequest } from '../../requests/selectionProcesses';
import CandidateDataTable from './CandidateDataTable';

const CandidateIndex = () => {
  const [filteredSelectionProcessId, setFilteredSelectionProcessId] = useState('');
  const dispatch = useDispatch();

  const fetchSelectionProcesses = (inputValue, callback) => {
    debounceIndexSelectionProcessesRequest({
      dispatch,
      params: { selector: true, name: inputValue, sort_column: 'name', display_length: 100 },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h2 className="mt-3 mb-1">Candidatos</h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <h4 className="text-uppercase mb-3">Filtros</h4>
        </Col>
        <Col md={4}>
          <InputSelect
            isClearable
            label="Proceso de Selección"
            placeholder="Seleccionar Proceso de Selección"
            request={fetchSelectionProcesses}
            onChange={data => setFilteredSelectionProcessId(data ? data.value : '')}
          />
        </Col>
      </Row>
      <CandidateDataTable filteredSelectionProcessId={filteredSelectionProcessId} />
    </>
  );
};

export default CandidateIndex;
