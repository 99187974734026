import React from 'react';
import ShiftInfo from './ShiftInfo'


const ShiftProfileShow = () => {

  return (
    <>
      <ShiftInfo />
    </>
  );
};

export default ShiftProfileShow;