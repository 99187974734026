const competenceTypes = [
  { value: 'transversal', label: 'Transversal' },
  { value: 'specific', label: 'Específica' }
];

const defaultQuestionChoices = [
  { label: 'Muy en desacuerdo', position: 1 },
  { label: 'En desacuerdo', position: 2 },
  { label: 'Neutral', position: 3 },
  { label: 'De acuerdo', position: 4 },
  { label: 'Muy de acuerdo', position: 5 }
];

const requireAmountTypes = [
  { label: 'Porcentaje', value: 'percentage' },
  { label: 'Binario', value: 'binary' },
  { label: 'Numérico', value: 'numeric' },
  { label: 'Likert', value: 'likert' }
];

const binaryRequirements = [
  { label: 'Sí', value: 2 },
  { label: 'No', value: 1 }
];

export { binaryRequirements, competenceTypes, defaultQuestionChoices, requireAmountTypes };
