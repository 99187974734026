import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useAbility } from '@casl/react';
import { SimpleCenteredModal } from '../../../components';
import { AbilityContext } from '../../../config/abilityContext';
import AdvancePaymentNew from './AdvancePaymentNew';
import AdvancePaymentDataTable from '../../Shared/AdvancePayment/AdvancePaymentDataTable';
import columns from './Columns';

const AdvancePaymentIndex = ({ currentEmployee, moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const { advanceSettings } = useSelector(state => state.utils);
  const canNew = advanceSettings?.filter(asItem => asItem.code === 'advance_payment_request')[0]?.textValue === 'true';
  const apDayLimit = advanceSettings?.filter(asItem => asItem.code === 'limit_day_advance_payment')[0]?.integerValue;
  const disableNew = apDayLimit && apDayLimit < moment().date();
  const newDate = new Date();
  const currentMonth = moment().format('MMMM');

  const handleSuccessCreate = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleOpenModal = nextMonth => {
    setModalShow(true);
    setModalBody(
      <AdvancePaymentNew
        nextMonth={nextMonth}
        onSuccess={handleSuccessCreate}
        employeeId={currentEmployee}
        cancelAction={() => setModalShow(false)}
      />
    );
  };
  return (
    <Row className="mb-3">
      <Col>
        {canNew && (
          <Row>
            {apDayLimit > 0 && (
              <>
                <Col xs={12}>
                  <p className="text-muted mt-3">
                    {disableNew
                      ? `Ya expiró la fecha límite de solicitud de anticipos para este mes, la fecha límite es el día
                    ${apDayLimit}. Para más información comunicarse con el área de Recursos Humanos.`
                      : `Recuerda solicitar Anticipos antes del ${apDayLimit} de
                    ${currentMonth[0].toUpperCase() + currentMonth.slice(1)}`}
                  </p>
                </Col>
              </>
            )}
            {ability.can('create', 'AdvancePayment') && (
              <Col xs={{ offset: 6 }} md={{ span: 3, offset: 9 }}>
                <Button
                  block
                  variant="primary"
                  disabled={disableNew}
                  onClick={() => handleOpenModal(newDate.getDate() <= apDayLimit)}
                >
                  Nuevo
                </Button>
              </Col>
            )}
          </Row>
        )}
        <AdvancePaymentDataTable
          columns={columns}
          moreData={moreData}
          setMoreData={setMoreData}
          customParams={{ filter_employee: currentEmployee }}
          employee
        />
        <SimpleCenteredModal
          title="Solicitar Anticipo"
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Col>
    </Row>
  );
};

export default AdvancePaymentIndex;
