import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexDocumentImedRequest = (numLicense, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/imed/integration_document_imeds/', {
    dispatch,
    params: {
      ...params,
      num_license: numLicense
    },
    failureCallback,
    successCallback
  });

export const destroyDocumentImedRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('delete', `/imed/integration_document_imeds/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexDocumentImedRequest = AwesomeDebouncePromise(indexDocumentImedRequest, 300);
