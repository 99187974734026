import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const debounceIndexAdminNotificationsRequest = AwesomeDebouncePromise(
  ({ dispatch, params = {}, failureCallback, successCallback }) =>
    ApiService.request('get', '/admin/notifications', {
      dispatch,
      params,
      failureCallback,
      successCallback
    }),
  300
);

export const readAdminNotificationRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/notifications/${id}/read`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });
