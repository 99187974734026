import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Field, getIn } from 'formik';

import Select, { components } from 'react-select';
import { countryCodes } from './FormOptions';
import { validPhoneInput } from '../../services/utils';
import './style.scss';

const handlePhoneInput = (e, field, setFieldValue) => {
  if (validPhoneInput(e)) {
    setFieldValue(field.name, e.target.value);
  }
};

const findDefaultValue = (value, defaultValue, options) => {
  if (defaultValue && value && defaultValue !== '' && value !== '' && value === defaultValue) {
    const vAttribute = options.filter(e => {
      return defaultValue === e.value;
    });
    return vAttribute[0];
  }
  return undefined;
};

const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props;
  const selected = getValue();
  return (
    <components.ValueContainer {...props}>
      {selected[0]?.value}
      {children[1]}
    </components.ValueContainer>
  );
};

const PhoneWithCode = ({
  label,
  disabled,
  errors,
  phoneField,
  countryCodeField,
  abbr,
  helpText,
  workPhoneCountryCode,
  touched,
  setFieldValue
}) => {
  const hasError =
    (getIn(errors, phoneField) && getIn(touched, phoneField)) ||
    (getIn(errors, countryCodeField) && getIn(touched, countryCodeField));
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${hasError ? 'is-invalid' : ''} phone-input`}>
      {label ? (
        <Form.Label>
          {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
        </Form.Label>
      ) : (
        false
      )}
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">
            <Field name={countryCodeField}>
              {({ field }) => (
                <Select
                  {...field}
                  className={`${isOpen ? 'border-on ' : ''}`}
                  placeholder="+56 CL"
                  options={countryCodes}
                  onMenuOpen={() => setIsOpen(true)}
                  onMenuClose={() => setIsOpen(false)}
                  value={findDefaultValue(field.value, workPhoneCountryCode, countryCodes)}
                  getOptionLabel={option => `${option.value}: ${option.countryName}`}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  components={{ ValueContainer }}
                />
              )}
            </Field>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Field name={phoneField}>
          {({ field }) => (
            <Form.Control {...field} disabled={disabled} onChange={e => handlePhoneInput(e, field, setFieldValue)} />
          )}
        </Field>
      </InputGroup>
      {hasError && (
        <Form.Text className="text-danger">{getIn(errors, phoneField) || getIn(errors, countryCodeField)}</Form.Text>
      )}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default PhoneWithCode;
