import ApiService from '../services/apiService';

export const indexBenefitsRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('get', '/benefits', {
    dispatch,
    params,
    callback,
    successCallback
  });

export const showBenefitsRequest = (id, { dispatch, successCallback }) =>
  ApiService.request('get', `/benefits/${id}`, {
    dispatch,
    successCallback
  });

export const createBenefitsRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', `/benefits`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const updateBenefitsRequest = (id, { dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('put', `/benefits/${id}`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const deleteBenefitsRequest = (id, { dispatch, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', `/benefits/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback
  });

export const reminderNotificationRequest = (id, { dispatch, params, callback, successCallback, failureCallback }) =>
  ApiService.request('post', `/benefits/${id}/reminder_benefit`, {
    dispatch,
    params,
    callback,
    successCallback,
    failureCallback
  });
