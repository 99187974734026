import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner, Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../actions/utils';
import CourseForm from './CourseForm';
import { LinkBtn, Icon } from '../../components';
import basicCourse from './course';
import { updateCourseRequest, showCourseRequest } from '../../requests/courses';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const CourseEdit = ({ match }) => {
  const [course, setCourse] = useState(basicCourse);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleShowRequest = () => {
    setOnRequest(true);
    showCourseRequest(match.params.id, {
      dispatch,
      successCallback: res => setCourse(camelCaseEmptyStringRecursive(res.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateRequest = (courseValues, setSubmitting) => {
    const { courseImage, courseCertificate } = courseValues.course;
    const myParams = snakeCaseKeys(courseValues);
    myParams.course.banner = courseImage;
    myParams.course.certificate = courseCertificate;

    updateCourseRequest(match.params.id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Curso actualizado con éxito' }));
        history.push(`/courses/${match.params.id}`);
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to={`/courses/${match.params.id}`}>
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Editar Curso</h2>
        </Col>
      </Row>
      <div className={`${onRequest && 'bg-opacity'}`}>
        <CourseForm course={course} action="edit" formRequest={handleUpdateRequest} />
      </div>
    </>
  );
};

export default CourseEdit;
