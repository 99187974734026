import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showCostCenterRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/cost_centers/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexCostCentersRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/cost_centers${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const exportCostCentersRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/cost_centers/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportCostCenterRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/cost_centers/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importCostCenterRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/cost_centers/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateCostCenterRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/cost_centers/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const createCostCenterRequest = ({ dispatch, params, callback, formData, successCallback }) => {
  ApiService.request('post', '/cost_centers', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });
};

export const updateCostCenterRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/cost_centers/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteCostCenterRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/cost_centers/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexCostCentersRequest = AwesomeDebouncePromise(indexCostCentersRequest, 300);
