import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';
import { indexBenefitsRequest } from '../../requests/benefits';
import { SimpleCenteredModal, InfiniteScroll } from '../../components';
import BenefitCard from '../Shared/Benefit/BenefitCard';
import BenefitNew from './BenefitNew';
import { AbilityContext } from '../../config/abilityContext';
import './style.scss';

const BenefitIndex = () => {
  const [benefits, setBenefits] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    if (response.data.data.length + benefits.length === response.data.metadata.amount) {
      setHasMore(false);
    }
    setPage(page + 1);
    setBenefits([...benefits, ...camelCaseRecursive(response.data.data)]);
  };

  const handleRequest = () => {
    setOnRequest(true);
    indexBenefitsRequest({
      dispatch,
      params: {
        display_start: page * 15,
        display_length: 15,
        sort_column: 'created_at',
        sort_direction: 'desc'
      },
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const openModal = () => setModalShow(true);

  const handleRefresh = () => {
    setHasMore(true);
    setPage(0);
    setBenefits([]);
    setRefresh(!refresh);
  };

  useEffect(handleRequest, [refresh]);
  useEffect(handleRefresh, [moreData]);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mb-3 mt-3">Beneficios</h2>
        </Col>
        {ability.can('create', 'Benefit') && (
          <Col md={3} className="mt-3 d-flex flex-row-reverse">
            <Button block variant="primary" onClick={openModal}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <InfiniteScroll dataLength={benefits.length} next={handleRequest} hasMore={hasMore} label="beneficios">
        <Row className={`${onRequest && 'bg-opacity'} `}>
          {benefits.map(item => (
            <Col key={item.id} xs={12} md={6}>
              <BenefitCard
                rrhh
                key={item.id}
                benefits={item}
                onRequest={onRequest}
                moreData={moreData}
                setMoreData={setMoreData}
              />
            </Col>
          ))}
        </Row>
      </InfiniteScroll>
      <SimpleCenteredModal
        title="Crear beneficio"
        show={modalShow}
        size="xl"
        body={
          <BenefitNew
            moreData={moreData}
            setMoreData={setMoreData}
            setOnRequest={setOnRequest}
            hideModal={() => setModalShow(false)}
          />
        }
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default BenefitIndex;
