import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikDatePicker } from '../Utils/Input';
import { InputSelect } from '../Utils/Select';
import DocumentEditor from '../Utils/CKEditor';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';

class AnnexContractForm extends Component {
  fetchDocumentTemplates = (inputValue, callback) => {
    const { dispatch } = this.props;
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: 'contract_annex',
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => callback(response.data.data)
    });
  };

  render() {
    const { submitVariant, errors, touched, setFieldValue, isSubmitting } = this.props;

    return (
      <>
        <Form>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Field name="templatePdf[templateId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Plantilla"
                      placeholder="Seleccionar Plantilla"
                      value={undefined}
                      request={this.fetchDocumentTemplates}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="templatePdf[extraKeys][0][value]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      isOutsideRange={() => false}
                      label="Fecha Inicio"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="templatePdf[extraKeys][1][value]">
                  {({ field }) => (
                    <DocumentEditor
                      label="Cláusulas Adicionales"
                      onChange={(event, editor) => {
                        const editorData = editor.getData();
                        setFieldValue(field.name, editorData);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn" type="submit" variant={submitVariant} disabled={isSubmitting}>
              Crear
            </Button>
          </Modal.Footer>
        </Form>
      </>
    );
  }
}

const setInitialValues = props => {
  const { employeeIds } = props;
  return {
    templatePdf: {
      documentType: 'contract_annex',
      templateId: '',
      employeeIds,
      extraKeys: [
        { code: '[[fecha_inicio_clausula]]', value: '' },
        { code: '[[clausula_adicional]]', value: '' }
      ]
    }
  };
};

const validationSchema = Yup.object().shape({
  templatePdf: Yup.object().shape({
    templateId: Yup.string().required('Debes seleccionar una plantilla')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(connect()(AnnexContractForm));
