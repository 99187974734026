import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../components';
import { sendAlert } from '../../actions/utils';
import { forgotPasswordRequest } from '../../requests/utils';

const RecoverPassword = props => {
  const { onHide, submitVariant, errors, touched } = props;
  const history = useHistory();
  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={4}>
        <Form className="d-flex flex-column justify-content-end mt-5">
          <h3 className="text-uppercase">RECUPERAR CONTRASEÑA</h3>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                placeholder="Email"
                inputType="email"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Container className="d-flex">
            <Button variant="outline-info" size="lg" className="mt-4" onClick={() => history.push('/login')}>
              Volver
            </Button>
            <Button variant={submitVariant} size="lg" className="mt-4 ml-auto" type="submit" onClick={onHide}>
              Enviar instrucciones
            </Button>
          </Container>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch, history } = props;
  forgotPasswordRequest({
    dispatch,
    params: {
      email: values.email
    },
    successCallback: result => {
      history.push('/login');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPassword)
  )
);
