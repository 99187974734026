import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';
import Icon from '../../Icons/Icon';
import ButtonTooltip from '../Tooltips/ButtonTooltip';

const ImporterCard = ({ colorBorder, colorText, handleImport, handleExport, card, importText, exportText }) => {
  const ability = useAbility(AbilityContext);
  return (
    <Card border={colorBorder}>
      <Card.Body className="flex-row mt-3 d-flex">
        <div className="ml-3">
          <Card.Title className={`text-${colorText}`}>{card.title}</Card.Title>
        </div>
        {ability.can(exportText, card.model) && (
          <ButtonTooltip
            onClick={() => handleExport(card)}
            variant="circle-primary"
            toolbarVariant="ml-auto"
            className="btn-circle"
            size="sm"
            text="Exportar"
          >
            <Icon icon="download-2" />
          </ButtonTooltip>
        )}
        {ability.can(importText, card.model) && (
          <ButtonTooltip
            onClick={() => handleImport(card)}
            variant="circle-primary"
            toolbarVariant={`${ability.can(exportText, card.model) ? '' : 'ml-auto'}`}
            className="btn-circle"
            size="sm"
            text="Importar"
          >
            <Icon width={25} icon="cloud-upload-outline" />
          </ButtonTooltip>
        )}
      </Card.Body>
    </Card>
  );
};

ImporterCard.propTypes = {
  colorBorder: PropTypes.string,
  colorText: PropTypes.string,
  importText: PropTypes.string,
  exportText: PropTypes.string,
  card: PropTypes.shape({
    title: PropTypes.string,
    model: PropTypes.string
  }).isRequired
};

ImporterCard.defaultProps = {
  colorBorder: null,
  colorText: 'black',
  importText: 'import',
  exportText: 'export'
};

export default ImporterCard;
