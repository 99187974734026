import React from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { createSurveyRequest } from '../../requests/surveys';
import { sendAlert } from '../../actions/utils';
import SurveyForm from './SurveyForm';
import survey from './survey';

const SurveyNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (surveyValues, setSubmitting) => {
    createSurveyRequest({
      dispatch,
      params: snakeCaseKeys(surveyValues),
      successCallback: res => {
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta creada con éxito' }));
        history.push(`/surveys/${res.data.id}`);
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="my-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/surveys">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">Nueva Encuesta</h2>
      </Row>
      <SurveyForm survey={survey} action="new" formRequest={handleCreateRequest} />
    </>
  );
};

export default SurveyNew;
