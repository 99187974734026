import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBookkeeperDocumentTypeRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/bookkeeper_document_types${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createBookkeeperDocumentTypeRequest = ({ dispatch, params, callback, formData, successCallback }) => {
  ApiService.request('post', '/bookkeeper_document_types', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });
};

export const updateBookkeeperDocumentTypeRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/bookkeeper_document_types/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteBookkeeperDocumentTypeRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/bookkeeper_document_types/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const exportBookkeeperDocumentTypeRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/bookkeeper_document_types/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportBookkeeperDocumentTypeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/bookkeeper_document_types/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importBookkeeperDocumentTypeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) => {
  ApiService.request('post', '/bookkeeper_document_types/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateBookkeeperDocumentTypeRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/bookkeeper_document_types/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const deactivateTemplateBookkeeperDocumentTypeRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('post', '/bookkeeper_document_types/massive_deactivate', {
    dispatch,
    params,
    formData: true,
    callback,
    successCallback
  });

export const activateTemplateBookkeeperDocumentTypeRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('post', '/bookkeeper_document_types/massive_activate', {
    dispatch,
    params,
    formData: true,
    callback,
    successCallback
  });

export const massDeleteBookkeeperDocumentTypeRequest = ({
  params,
  dispatch,
  callback,
  successCallback,
  failureCallback
}) =>
  ApiService.request('delete', '/bookkeeper_document_types/massive_destroy', {
    params,
    dispatch,
    successCallback,
    failureCallback,
    callback
  });

export const debounceIndexBookkeeperDocumentTypesRequest = AwesomeDebouncePromise(
  indexBookkeeperDocumentTypeRequest,
  300
);
