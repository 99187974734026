import React from 'react';

const jointCommitteeInfo = props => {
  const { jointCommittee } = props;
  const { name, code, fullAddress, parsedPhone, employeeJointCommitteesAttributes } = jointCommittee;

  const members = employeeJointCommitteesAttributes.map(employee => {
    return (
      <p key={employee.id} className="info">
        <span>Nombre:</span> {employee.fullName}
        <br />
        <span>Cargo:</span> {employee.jobTitle}
      </p>
    );
  });

  return (
    <>
      <div className="info-box">
        <h4 className="text-uppercase">Información Comité</h4>
        <p className="info">
          <span>Nombre:</span> {name}
        </p>
        <p className="info">
          <span>Código:</span> {code}
        </p>
        <hr />
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Contacto</h4>
        <p className="info">
          <span>Dirección:</span> {fullAddress}
        </p>
        {parsedPhone && (
          <p className="info">
            <span>Teléfono:</span> {parsedPhone}
          </p>
        )}
        <hr />
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Miembros</h4>
        {members}
      </div>
    </>
  );
};

export default jointCommitteeInfo;
