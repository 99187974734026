import React from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import { Icon, LinkBtn } from '../../components';
import { useSetTab } from '../../services/hooks';
import SettlementGroupDataTable from '../SettlementGroup/SettlementGroupDataTable';
import SettlementDataTable from './SettlementDataTable';

const SettlementIndex = () => {
  const location = useLocation();
  const [key, setKey] = useSetTab('settlement-groups', location);

  return (
    <>
      <Row className="my-4">
        <Col>
          <h2 className="text-uppercase mt-3 mb-1">Finiquitos</h2>
        </Col>
        <Col md={4} lg={3} className="mt-3">
          <LinkBtn block variant="primary" to="settlement_groups/new">
            Simular Nuevo Finiquito
          </LinkBtn>
        </Col>
      </Row>
      <Tab.Container id="settlement-datatables.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="settlement-groups">
              <Icon icon="people" />
              Grupos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="settlements">
              <Icon icon="person" />
              Individual
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="settlement-groups">
            <SettlementGroupDataTable />
          </Tab.Pane>
          <Tab.Pane eventKey="settlements">
            <SettlementDataTable />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default SettlementIndex;
