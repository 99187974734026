import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner } from 'react-bootstrap';

import { updateAbsenceRequest } from '../../requests/absences';
import AbsenceForm from './AbsenceForm';

const AbsenceEdit = props => {
  const { absence, handleSuccessAction } = props;
  const { id: absenceId } = absence;
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessUpdate = () => {
    const message = 'Ausencia actualizada con éxito';
    setOnRequest(false);
    handleSuccessAction(message);
  };

  const handleFailureRequest = error => {
    const { handleFailureAction } = props;
    setOnRequest(false);
    handleFailureAction(error);
  };

  const handleUpdate = (absenceValues, setSubmitting) => {
    setOnRequest(true);
    const params = snakeCaseKeys(absenceValues);

    updateAbsenceRequest(absenceId, {
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: error => {
        handleFailureRequest(error);
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <AbsenceForm absence={absence} action="edit" formRequest={handleUpdate} />
    </>
  );
};

export default AbsenceEdit;
