const basicSelectionProcess = {
  id: '',
  candidatesCount: '',
  companyId: '',
  correlativeIdentification: '',
  description: '',
  desiredKnowledge: '',
  expertiseLevel: '',
  jobDescription: '',
  jobManagement: {},
  jobManagementId: '',
  jobTitle: {},
  jobTitleId: '',
  name: '',
  processType: '',
  remote: '',
  selectionProcessStagesAttributes: [],
  startDate: '',
  status: '',
  vacancies: '',
  workSchedule: ''
};

export default basicSelectionProcess;
