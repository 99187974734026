import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import PropTypes from 'prop-types';
import FormikSelect from '../Utils/Select/FormikSelect';
import BasicTooltip from '../Utils/Tooltips/BasicTooltip';
import ButtonTooltip from '../Utils/Tooltips/ButtonTooltip';
import Icon from '../Icons/Icon';
import './style.scss';

const INITIAL_YEAR = 1850;

const Calendar = ({ dragEvents, lastEvents, request, moreData, customHead, ...props }) => {
  const calendar = useRef();
  const [date, setDate] = useState(moment());
  const len = moment().year() + 100 - INITIAL_YEAR + 1;
  const years = useMemo(
    () =>
      Array(len)
        .fill()
        .map((_, idx) => ({ label: INITIAL_YEAR + idx, value: INITIAL_YEAR + idx })),
    [len]
  );
  const months = useMemo(
    () =>
      moment
        .months()
        .map((label, value) => ({ label: label.charAt(0).toUpperCase() + label.slice(1), value: value + 1 })),
    []
  );

  const setDateField = (year, month) => setDate(moment(`${year}-${month + 1}-1`));

  const gotoDate = () => {
    const calendarApi = calendar.current.getApi();
    calendarApi.gotoDate(date.format('YYYY-MM-DD'));
  };

  const nextMonth = () => setDate(moment(date.add(1, 'months')));

  const previousMonth = () => setDate(moment(date.subtract(1, 'months')));

  useEffect(() => {
    if (lastEvents) {
      const draggableEl = document.getElementById('external-events');
      // eslint-disable-next-line no-new
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData(eventEl) {
          const title = eventEl.getAttribute('title');
          return { title, create: false };
        }
      });
    }
  }, [lastEvents]);

  const handleRequest = () => {
    if (request) {
      request({ range_date: [date.startOf('month').format('DD/MM/YYYY'), date.endOf('month').format('DD/MM/YYYY')] });
    }
  };

  useEffect(gotoDate, [date]);
  useEffect(handleRequest, [date, moreData]);

  return (
    <>
      <Row>
        <Col xs={12} md={1}>
          <Row className="mt-n2 mt-md-n0 mt-md-2">
            <Col xs={{ span: 2, offset: 4 }} sm={{ span: 6, offset: 0 }}>
              <ButtonTooltip
                onClick={previousMonth}
                variant="circle-info"
                className="btn-circle"
                toolbarVariant="my-3"
                size="sm"
                text="Mes anterior"
              >
                <Icon icon="chevron-back" />
              </ButtonTooltip>
            </Col>
            <Col xs={6}>
              <ButtonTooltip
                onClick={nextMonth}
                variant="circle-info"
                className="btn-circle"
                toolbarVariant="my-3"
                size="sm"
                text="Mes siguiente"
              >
                <Icon icon="chevron-forward" />
              </ButtonTooltip>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2}>
          <FormikSelect
            options={years}
            label="AÑO"
            value={date.year()}
            defaultValue={date.year()}
            placeholder=""
            onChange={e => setDateField(e.value, date.month())}
          />
        </Col>
        <Col xs={6} md={3}>
          <FormikSelect
            options={months}
            label="MES"
            value={date.month() + 1}
            defaultValue={date.month() + 1}
            placeholder=""
            onChange={e => setDateField(date.year(), e.value - 1)}
          />
        </Col>
        {lastEvents && (
          <Col xs={12} md={6}>
            <Row>
              <Col xs={6} md={{ offset: 1, span: 5 }} className="flex-column my-auto">
                <p className="text-uppercase text-muted mb-0 font-weight-bold">Último Horario Usado:</p>
                <p className="text-muted">Puedes arrastrar este detalle de horario a un día del calendario.</p>
              </Col>
              <Col xs={4}>
                <div id="external-events" className="flex-column py-1">
                  {dragEvents.length >= 1 ? (
                    dragEvents.map(event => (
                      <div
                        className="fc-event my-1 text-light bg-primary"
                        title={event.title}
                        key={`drag-${event.date}`}
                      >
                        {event.title}
                      </div>
                    ))
                  ) : (
                    <p className="text-muted text-center px-1 pt-3">Haz click en un día para crear un horario</p>
                  )}
                </div>
              </Col>
              <Col className="calendar-help">
                <BasicTooltip text="Puedes arrastrar hasta los últimos 3 horarios creados para replicar el turno.">
                  <Icon icon="help-circle" width={22} />
                </BasicTooltip>
              </Col>
            </Row>
          </Col>
        )}
        {customHead()}
      </Row>
      <FullCalendar
        height="auto"
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{ left: '', center: '', right: '' }}
        firstDay={1}
        buttonText={{ today: 'hoy' }}
        locale="es"
        weekends
        droppable
        ref={calendar}
        eventColor="#29b48f"
        {...props}
      />
    </>
  );
};

Calendar.propTypes = {
  dragEvents: PropTypes.arrayOf(PropTypes.shape()),
  lastEvents: PropTypes.bool,
  request: PropTypes.func,
  customHead: PropTypes.func
};

Calendar.defaultProps = {
  dragEvents: [],
  lastEvents: true,
  request: undefined,
  customHead: () => null
};

export default Calendar;
