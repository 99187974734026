import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import './styles.scss';

const Slides = ({ items, specific }) => (
  <Carousel
    className={specific}
    controls={items.length > 1}
    indicators={items.length > 1}
  >
    {items.map(item => (
      <Carousel.Item key={`slide-${item.id}`}>
        <img src={item.fileInfo.fileUrl} alt={item.caption} />
        <Carousel.Caption>
          {item.caption && <h3>{item.caption}</h3>}
        </Carousel.Caption>
      </Carousel.Item>
    ))}
  </Carousel>
);

Slides.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      fileInfo: PropTypes.objectOf(PropTypes.object).isRequired,
      specific: PropTypes.string,
      id: PropTypes.number.isRequired
    })
  ).isRequired
};

export default Slides;
