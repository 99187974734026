import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { legalreportOvertimeReport } from '../../requests/legalreports';
import { ComponentDataTable } from '../index';

const OvertimeFilterDataTable = ({ customParams, moreData, columns }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [overtimes, setOvertimes] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    setOvertimes(response.data);
    setOnRequest(false);
  };

  const handleOvertimeReportRequest = params => {
    const overtimeParams = { ...params };
    if (overtimes.length > 0) {
      return;
    }
    setOnRequest(true);
    overtimeParams.sort_column = '';
    overtimeParams.sort_direction = '';
    const request = async () =>
      legalreportOvertimeReport({
        dispatch,
        params: { ...overtimeParams, ...customParams },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={overtimes}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleOvertimeReportRequest}
        pointerOnHover
        preName="overtimes"
        withMassActions
        withSearch={false}
        pagination
        paginationServer={false}
      />
    </>
  );
};

export default OvertimeFilterDataTable;
