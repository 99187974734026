import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, FormCheck } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { FormikInput, FormikSelect, Icon } from '../../components';
import RenderMonthElement from '../../components/Utils/Input/RenderMonthElement';
import { indexLicenseImedRequest } from '../../requests/licenseImed';
import { rutFormat, validRutInput, licenseFormat } from '../../services/utils';
import { statusLicence } from './FormOptions';
import LicenseImedDataTable from './LicenseImedDataTable';

const LicenseImedIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const [folio, setFolio] = useState('');
  const [rut, setRut] = useState('');
  const [startDate, setStartDate] = useState();
  const [statusLicences, setStatusLicences] = useState(1);
  const [focusStartDate, setFocusStartDate] = useState();
  const [focusEndDate, setFocusEndDate] = useState();
  const [endDate, setEndDate] = useState();
  const [license, setLicense] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data } = response.data;
    let dataFilter = [];
    if (Array.isArray(data)) {
      dataFilter = data.sort().reverse();
    }
    if (!Array.isArray(data)) {
      dataFilter = [data].sort().reverse();
    }
    const rutEmployee = rut.replace(/[^a-zA-Z0-9 -]/g, '').split('-');
    const numLicence = folio.replace(/[^a-zA-Z0-9 -]/g, '').split('-');
    const _rut = rutEmployee[0] !== undefined ? rutEmployee[0] : '';
    const _dv = rutEmployee[1] !== undefined ? rutEmployee[1] : '';
    const _license = numLicence[0] !== undefined ? numLicence[0] : '';
    const _dvLicense = numLicence[1] !== undefined ? numLicence[1] : '';

    const cleanList = dataFilter.filter(
      (list, index, array) =>
        array.findIndex(arrayVal => arrayVal.num_licencia === list.num_licencia && arrayVal.fecha) === index
    );
    setLicense(
      cleanList.filter(list =>
        list.rut_trabajador.toLowerCase().includes(_rut.toLowerCase()) &&
          list.dig_trabajador.toLowerCase().includes(_dv.toLowerCase()) &&
          list.num_licencia.toLowerCase().includes(_license.toLowerCase()) &&
          list.dig_licencia.toLowerCase().includes(_dvLicense.toLowerCase()) &&
          statusLicences !== ''
          ? list.estado === statusLicences.toString()
          : list.estado.includes(statusLicences)
      )
    );
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    indexLicenseImedRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex,
      failureCallback: handleFailureRequest
    });
  };

  const handleFilter = event => {
    event.preventDefault();
    handleRequest({
      date_from: moment.isMoment(startDate) ? startDate.format('DD/MM/YYYY') : '',
      date_to: moment.isMoment(endDate) ? endDate.format('DD/MM/YYYY') : ''
    });
  };

  const handleReFilter = () => {
    handleRequest({
      date_from: moment.isMoment(startDate) ? startDate.format('DD/MM/YYYY') : '',
      date_to: moment.isMoment(endDate) ? endDate.format('DD/MM/YYYY') : ''
    });
  };
  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setRut(formattedValue);
    }
  };

  const handleNumLicenseFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = licenseFormat(e.target.value);
      setFolio(formattedValue);
    }
  };

  return (
    <>
      <Form onSubmit={handleFilter}>
        <Row className="mt-4 mb-4">
          <Col>
            <h2 className="mt-3 mb-3 m-top">Licencias por Tramitar </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="text-uppercase font-weight-light mb-3">Filtros</h4>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={2}>
            <FormCheck.Label className="align-items-center form-label" style={{ marginBottom: '-5px' }}>
              {'Desde'}
              <abbr className="text-danger ml-1">*</abbr>
            </FormCheck.Label>
            <SingleDatePicker
              block
              id="start-date-input"
              date={startDate}
              placeholder="dd/mm/aaaa"
              focused={focusStartDate}
              onFocusChange={({ focused }) => {
                setFocusEndDate(false);
                setFocusStartDate(focused);
              }}
              numberOfMonths={1}
              onDateChange={date => {
                setStartDate(date);
              }}
              showClearDate
              customCloseIcon={<Icon icon="close" width={18} />}
              customInputIcon={<Icon icon="calendar" width={18} />}
              hideKeyboardShortcutsPanel
              inputIconPosition={ICON_AFTER_POSITION}
              firstDayOfWeek={1}
              renderMonthElement={RenderMonthElement}
              isOutsideRange={day => day.isAfter(endDate)}
              required
            />
          </Col>
          <Col md={2}>
            <FormCheck.Label className="align-items-center form-label" style={{ marginBottom: '-5px' }}>
              {'Hasta'}
              <abbr className="text-danger ml-1">*</abbr>
            </FormCheck.Label>
            <SingleDatePicker
              block
              id="end-date-input"
              date={endDate}
              placeholder="dd/mm/aaaa"
              focused={focusEndDate}
              onFocusChange={({ focused }) => {
                setFocusStartDate(false);
                setFocusEndDate(focused);
              }}
              numberOfMonths={1}
              onDateChange={date => {
                setEndDate(date);
              }}
              showClearDate
              customCloseIcon={<Icon icon="close" width={18} />}
              customInputIcon={<Icon icon="calendar" width={18} />}
              hideKeyboardShortcutsPanel
              inputIconPosition={ICON_AFTER_POSITION}
              firstDayOfWeek={1}
              renderMonthElement={RenderMonthElement}
              isOutsideRange={day => day.isBefore(startDate)}
              required
            />
          </Col>
          <Col md={3}>
            <FormikInput
              label="RUT Trabajador"
              name="rut"
              placeholder="12.345.678-9"
              onChange={e => handleNationalIdentificationFormat(e)}
              value={rut}
            />
          </Col>
          <Col md={2}>
            <FormikInput
              label="Licencia"
              name="folio"
              placeholder="12345-6"
              onChange={e => handleNumLicenseFormat(e)}
              value={folio}
            />
          </Col>
          <Col md={3}>
            <FormikSelect
              label="Estado de Licencia"
              placeholder="Seleccionar Estado de Licencia"
              name="status_license"
              options={statusLicence}
              defaultValue={statusLicences}
              value={statusLicences}
              onChange={data => {
                setStatusLicences(data ? data.value : '');
              }}
              isClearable
            />
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col>
            <div className="text-uppercase mt-3" />
          </Col>
          <Col md={2} className="mt-3 d-flex flex-row-reverse">
            <Button variant="primary" type="submit">
              Filtrar Licencias
            </Button>
          </Col>
        </Row>
        <LicenseImedDataTable license={license} handleReFilter={handleReFilter} onRequest={onRequest} />
      </Form>
    </>
  );
};

export default LicenseImedIndex;
