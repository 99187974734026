import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'ENCUESTA',
    selector: 'survey',
    cell: item => item.survey?.label,
    sortable: true,
    grow: '5'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'answer')}
        variant="circle-info"
        className="btn-circle"
        size="sm"
        disabled={item.enrolled}
        text="Responder"
      >
        <Icon icon="eye" />
      </ButtonTooltip>
    ),
    ignoreRowClick: true,
    grow: '1.5',
    minWidth: '185px',
    right: true
  }
]);

export default columns;
