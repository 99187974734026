import ApiService from '../services/apiService';

export const previredIndicatorsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', '/public/previreds', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const siiTaxesIndicatorsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', '/public/sii_taxes', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });
