import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';
import { Eye, Edit, Trash } from 'react-feather';
import { Button } from 'react-bootstrap';

import { indexSlidesRequest, deleteSlideRequest } from '../../requests/slides';
import { ComponentDataTable, DefaultModal } from '../../components';

class SlideDataTable extends Component {
  state = {
    onRequest: true,
    slides: [],
    amount: 0,
    moreData: false,
    modalShow: false,
    modalItem: null,
    modalBody: ''
  };

  handleRequest = async params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    indexSlidesRequest({
      dispatch,
      params,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const slides = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      slides,
      amount,
      onRequest: false
    });
  };

  removeSlide = slideId => {
    const { dispatch } = this.props;
    deleteSlideRequest(slideId, {
      dispatch,
      successCallback: this.handleSuccessRemove
    });
  };

  handleButtonClick = (item, action) => {
    const { history } = this.props;
    switch (action) {
      case 'show':
        history.push(`/slides/${item.id}`);
        break;
      case 'edit':
        history.push(`/slides/${item.id}/edit`);
        break;
      case 'remove':
        this.setState({
          modalShow: true,
          modalItem: item,
          modalBody: `¿Realmente quires eliminar slide: ${item.id}`
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeSlide(modalItem.id);
  };

  handleSuccessRemove = () => {
    this.handleModalClose();
    const { moreData } = this.state;
    this.setState({ moreData: !moreData });
  };

  render() {
    const columns = memoize(clickHandler => [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
        grow: '1'
      },
      {
        name: 'Posición',
        selector: 'position',
        sortable: true,
        grow: '1'
      },
      {
        name: 'Caption',
        selector: 'caption',
        sortable: true,
        grow: '2'
      },
      {
        name: 'Creación',
        selector: 'created_at',
        sortable: true,
        grow: '1'
      },
      {
        name: 'Activo',
        grow: '1',
        sortable: true,
        selector: 'active',
        cell: item => (item.active ? 'Si' : 'No')
      },
      {
        cell: item => (
          <>
            <Button variant="outline-primary" className="mr-2" size="sm" onClick={() => clickHandler(item, 'show')}>
              <Eye width="15px" height="15px" />
            </Button>
            <Button variant="outline-warning" className="mr-2" size="sm" onClick={() => clickHandler(item, 'edit')}>
              <Edit width="15px" height="15px" />
            </Button>
            <Button variant="outline-danger" className="mr-2" size="sm" onClick={() => clickHandler(item, 'remove')}>
              <Trash width="15px" height="15px" />
            </Button>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        grow: '1',
        minWidth: '123px'
      }
    ]);
    const { slides, amount, onRequest, moreData, modalShow, modalBody } = this.state;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          data={slides}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
        />
        <DefaultModal
          title="Eliminar Slide"
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      </>
    );
  }
}

export default withRouter(connect()(SlideDataTable));
