import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSubsidiariesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/subsidiaries`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showSubsidiaryRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/subsidiaries/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createSubsidiaryRequest = ({ dispatch, params, callback, formData, successCallback }) =>
  ApiService.request('post', '/subsidiaries', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });

export const updateSubsidiaryRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/subsidiaries/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteSubsidiaryRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/subsidiaries/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const massiveDestroySubsidiariesRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/subsidiaries/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportSubsidiariesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/subsidiaries/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportSubsidiaryRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/subsidiaries/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importSubsidiaryRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/subsidiaries/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateSubsidiaryRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/subsidiaries/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const activeSubsidiaryRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/subsidiaries/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateSubsidiaryRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/subsidiaries/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexSubsidiariesRequest = AwesomeDebouncePromise(indexSubsidiariesRequest, 300);
