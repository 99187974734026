import React, { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal, ImportModal } from '../../../components';
import DirectoryNodeNew from './DirectoryNodeNew';
import DirectoryNodeEdit from './DirectoryNodeEdit';
import DirectoryNodeBoxes from './DirectoryNodeBoxes';
import {
  importTemplateDirectoryNodeRequest,
  preImportDirectoryNodeRequest,
  importDirectoryNodeRequest
} from '../../../requests/directoryNodes';
import DocumentsDatatable from '../../Document/DocumentDataTable';

const DirectoryNodeIndex = ({ employeeId, rrhh }) => {
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [search, setSearch] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleTemplate = (folderId, values) => {
    importTemplateDirectoryNodeRequest(folderId, {
      employeeId,
      ...values
    });
  };

  const onDropUploaded = (folderId, values) => {
    preImportDirectoryNodeRequest(folderId, {
      employeeId,
      ...values
    });
  };

  const handleImport = (folderId, values) => {
    importDirectoryNodeRequest(folderId, {
      employeeId,
      ...values
    });
  };

  const handleOpenModal = (parentId, parentName, fileType) => {
    setModalBody(
      <DirectoryNodeNew
        onSuccess={handleSuccessCreate}
        employeeId={employeeId}
        parentId={parentId}
        fileType={fileType}
        parentName={parentName}
        onHide={() => setModalShow(false)}
      />
    );
    if (fileType === 'folder') {
      setModalTitle('Crear Carpeta');
    } else {
      setModalTitle('Agregar Archivos');
    }
    setModalShow(true);
  };

  const handleEditModal = (folderId, folderName, setOnRequest) => {
    setModalShow(true);
    setModalTitle('Cambiar Nombre');
    setModalBody(
      <DirectoryNodeEdit
        onSuccess={handleSuccessCreate}
        folderId={folderId}
        employeeId={employeeId}
        folderName={folderName}
        onHide={() => setModalShow(false)}
        setOnRequest={setOnRequest}
      />
    );
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleOpenImportModal = folder => {
    setModalShow(true);
    setModalTitle('Importar Plantilla de Actualización');
    setModalBody(
      <ImportModal
        onDropUploaded={values => onDropUploaded(folder.id, values)}
        handleTemplate={values => handleTemplate(folder.id, values)}
        onHide={values => handleImport(folder.id, values)}
        hideModal={handleSuccessCreate}
        handleExceptions={handleImportModalExceptions}
      />
    );
  };

  return (
    <>
      <Form.Group className="w-100 max-width-290 ml-2 mr-2">
        <FormControl
          placeholder="Buscar"
          name="textInput"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            setMoreData(!moreData);
          }}
        />
      </Form.Group>
      {search === '' ? (
        <DirectoryNodeBoxes
          employeeId={employeeId}
          handleOpenCenterModal={handleOpenModal}
          handleOpenImportModal={handleOpenImportModal}
          handleEditModal={handleEditModal}
          moreDataCenterModal={moreData}
          rrhh={rrhh}
        />
      ) : (
        <DocumentsDatatable
          customParams={{ filter_employee: employeeId, employee_query: search, with_directory: true }}
          moreData={moreData}
          setMoreData={setMoreData}
          employeeSearch
          allowedActions={['show', 'download']}
          rrhh={rrhh}
        />
      )}
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default DirectoryNodeIndex;
