import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikInput, FormikSelect, InputSelect, FormikCheckBox } from '../../components';
import typeAccounts, { auxiliaries } from './FormOptions';
import { debounceIndexBookkeeperDocumentTypesRequest } from '../../requests/bookkeeperDocumentTypes';

const BookkeeperForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue,
    setFieldTouched,
    values,
    bookkeeper,
    isSubmitting
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const { typeAccount, auxiliary, analyticsAccount } = bookkeeper;
  const [haveAuxiliary, setHaveAuxiliary] = useState(true);

  const setTypeAccount = () => {
    if (typeAccount !== 'result_loss' && typeAccount !== 'result_profit') {
      setFieldValue('bookkeeper[costCenter]', false);
    }
  };

  const setAnalyticsAccount = () => {
    if (analyticsAccount === true) {
      setHaveAuxiliary(false);
    } else {
      setHaveAuxiliary(true);
    }
  };
  useEffect(setTypeAccount, [typeAccount]);
  useEffect(setAnalyticsAccount, [analyticsAccount]);

  const fetchBookkeeperDocumentTypes = (inputValue, callback) => {
    const { dispatch } = props;
    debounceIndexBookkeeperDocumentTypesRequest({
      dispatch,
      params: {
        filter_name: inputValue,
        sort_column: 'name',
        active: true,
        display_length: 150
      },
      successCallback: response => callback(response.data.data)
    });
  };
  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={5}>
            <Field name="bookkeeper[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={5}>
            <Field name="bookkeeper[account]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Cuenta"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="bookkeeper[code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Código"
                  inputType="number"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Field name="bookkeeper[typeAccount]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Cuenta"
                  placeholder="Seleccionar Tipo de Cuenta"
                  options={typeAccounts}
                  defaultValue={typeAccount}
                  onChange={data => {
                    const value = data ? data.value : '';
                    if (value === 'result_loss' || value === 'result_profit') {
                      setFieldValue('bookkeeper[costCenter]', true);
                      setFieldValue('bookkeeper[analyticsAccount]', false);
                      setHaveAuxiliary(true);
                      setFieldValue('bookkeeper[auxiliary]', '');
                    } else {
                      setFieldValue('bookkeeper[costCenter]', false);
                      setFieldValue('bookkeeper[analyticsAccount]', true);
                      setHaveAuxiliary(false);
                      setFieldValue('bookkeeper[auxiliary]', '');
                    }
                    setFieldValue(field.name, value);
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  isClearable
                />
              )}
            </Field>
            <Field name="bookkeeper[auxiliary]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  isDisabled={haveAuxiliary}
                  isHidden={haveAuxiliary}
                  label="Auxiliar"
                  placeholder="Auxiliar"
                  options={auxiliaries}
                  defaultValue={auxiliary}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  isClearable
                />
              )}
            </Field>
          </Col>
          <Col md={7} className=" d-flex justify-content-around align-self-center">
            <Field name="bookkeeper[costCenter]">
              {({ field }) => (
                <FormikCheckBox {...field} field={field} margin="mt-4" disabled label="Centro de Costo" />
              )}
            </Field>
            <Field name="bookkeeper[analyticsAccount]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  margin="mt-4"
                  field={field}
                  disabled
                  onClick={() => {
                    setHaveAuxiliary(!haveAuxiliary);
                    setFieldValue('bookkeeper[auxiliary]', '');
                  }}
                  label="Cuenta Analisis"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <Field name="bookkeeper[bookkeeperDocumentTypeId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  label="Tipo de Documento"
                  placeholder="Seleccionar Tipo de Documento"
                  values={values.bookkeeper}
                  model={[bookkeeper, 'bookkeeperDocumentType']}
                  request={fetchBookkeeperDocumentTypes}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  isClearable
                />
              )}
            </Field>
          </Col>
          <Col md={2} className="d-flex">
            <Field name="bookkeeper[active]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Activo"
                  custom
                  type="switch"
                  disabled={action === 'new'}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    name,
    code,
    account,
    typeAccount,
    bookkeeperDocumentTypeId,
    costCenter,
    auxiliary,
    analyticsAccount,
    active
  } = props.bookkeeper;
  return {
    bookkeeper: {
      id,
      name,
      code,
      account,
      typeAccount,
      bookkeeperDocumentTypeId,
      costCenter,
      auxiliary,
      analyticsAccount,
      active
    }
  };
};

const validationSchema = Yup.object().shape({
  bookkeeper: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .nullable()
      .max(60, 'Deben ser menos que 60 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    code: Yup.number()
      .required('Debes ingresar un código')
      .nullable()
      .typeError('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0')
      .max(9999999999, 'Deben ser menos que 10 caracteres'),
    account: Yup.string()
      .required('Debes ingresar la cuenta ')
      .nullable()
      .max(20, 'Deben ser menos que 20 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    typeAccount: Yup.string()
      .required('Debes seleccionar un tipo de cuenta')
      .nullable(),
    bookkeeperDocumentTypeId: Yup.string().nullable(),
    auxiliary: Yup.string()
      .when('analyticsAccount', {
        is: val => val === true,
        then: Yup.string()
          .required('Debes seleccionar un auxiliar')
          .nullable()
      })
      .nullable()
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(connect()(BookkeeperForm));
