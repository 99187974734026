import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';

import './style.scss';

const DocumentEditor = ({ abbr, data, label, margin, error, helpText, touched, isReadOnly, ...props }) => {
  const [isTouched, setIsTouched] = useState(false);

  return (
    <Form.Group className={margin}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
        </Form.Label>
      )}
      <InputGroup>
        <div className={`document-editor ${error && (touched || isTouched) ? ' is-invalid' : ''}`}>
          <div className={`document-editor__toolbar ${isReadOnly && 'is-read-only'}`} />
          <div className="document-editor__editable-container">
            <div className="document-editor__editable">
              <CKEditor
                onInit={editor => {
                  const toolbarContainer = document.querySelector('.document-editor__toolbar');
                  if (!isReadOnly) {
                    toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                  }
                }}
                editor={DecoupledEditor}
                config={{
                  language: 'es',
                  removePlugins: [
                    'imageTextAlternative',
                    'imageUpload',
                    'imageStyle:full',
                    'imageStyle:alignLeft',
                    'imageStyle:alignRight',
                    'fontBackgroundColor'
                  ],
                  toolbar: [
                    'heading',
                    'pageBreak',
                    'fontFamily',
                    'fontSize',
                    'fontColor',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'alignment',
                    'numberedList',
                    'bulletedList',
                    'indent',
                    'outdent',
                    'link',
                    'blockQuote',
                    'insertTable',
                    'undo',
                    'redo'
                  ]
                }}
                data={data}
                onBlur={() => setIsTouched(true)}
                {...props}
              />
            </div>
          </div>
        </div>
      </InputGroup>
      {error && (touched || isTouched) && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default DocumentEditor;
