import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexVacationsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/vacations`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexUserVacationsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/vacations/user_index`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createVacationRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/vacations', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const deleteVacationRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/vacations/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const approveVacationRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback }
) =>
  ApiService.request('post', `/vacations/${id}/approve`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const rejectVacationRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', `/vacations/${id}/reject`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const typeUnavailableVacationRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('get', '/vacations/type_unavailable', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const showEmployeeCardsRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', '/vacations/employee_card', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const exportVacationsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/vacations/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const preImportVacationRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/vacations/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importVacationRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/vacations/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateVacationRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/vacations/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const debounceIndexVacationsRequest = AwesomeDebouncePromise(indexVacationsRequest, 300);
