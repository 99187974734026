import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Spinner, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { sendAlert } from '../../actions/utils';
import { showCompanyTaskRequest, updateCompanyTaskRequest } from '../../requests/companyTasks';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import CompanyTaskForm from '../Shared/CompanyTask/CompanyTaskForm';
import defaultTask from '../Shared/CompanyTask/task';

const CompanyTaskEdit = ({ match }) => {
  const [task, setTask] = useState(defaultTask);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleShowRequest = () => {
    setOnRequest(true);
    showCompanyTaskRequest(match.params.id, {
      dispatch,
      successCallback: res => setTask(camelCaseEmptyStringRecursive(res.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateRequest = (taskValues, setSubmitting) => {
    updateCompanyTaskRequest(match.params.id, {
      dispatch,
      params: snakeCaseKeys(taskValues),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Tarea actualizada con éxito' }));
        history.push('/tasks');
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to="/tasks">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Editar Tarea</h2>
        </Col>
      </Row>
      <div className={`${onRequest && 'bg-opacity'}`}>
        <CompanyTaskForm companyTask={task} action="edit" formRequest={handleUpdateRequest} rrhh />
      </div>
    </>
  );
};

export default CompanyTaskEdit;
