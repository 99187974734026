import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { showSurveyRequest, updateSurveyRequest } from '../../requests/surveys';
import { sendAlert } from '../../actions/utils';
import SurveyForm from './SurveyForm';
import basicSurvey from './survey';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const SurveyEdit = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const [survey, setSurvey] = useState(basicSurvey);
  const history = useHistory();

  const fetchSurvey = () => {
    const request = async () => {
      showSurveyRequest(id, {
        dispatch,
        successCallback: response => setSurvey(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleUpdateRequest = (surveyValues, setSubmitting) => {
    updateSurveyRequest(id, {
      dispatch,
      params: snakeCaseKeys(surveyValues, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta actualizada con éxito' }));
        history.push(`/surveys/${id}`);
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(fetchSurvey, [id]);

  return (
    <>
      <Row className="my-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/surveys">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">{survey.name}</h2>
      </Row>
      <SurveyForm survey={survey} action="new" formRequest={handleUpdateRequest} />
    </>
  );
};

export default SurveyEdit;
