import React, { useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput } from '../../components';

const QuestionChoicesFields = ({ valuePath, questionType, position }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  const updatePosition = () => {
    setFieldValue(`${valuePath}[position]`, position);
  };

  const placeholders = {
    likert: ['Muy en desacuerdo', 'En desacuerdo', 'Neutral', 'De acuerdo', 'Muy de acuerdo'],
    choices: ['Alternativa', 'Alternativa'],
    binary: ['Sí', 'No']
  };

  useEffect(updatePosition, [position]);
  return questionType === 'likert' ? (
    <div className="mx-3 likert-choice-form">
      <Field name={`${valuePath}[label]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            label={position}
            placeholder={placeholders[questionType][(position - 1) % 5]}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </div>
  ) : (
    <Field name={`${valuePath}[label]`}>
      {({ field }) => (
        <FormikInput
          {...field}
          label={`Respuesta ${position}`}
          placeholder={placeholders[questionType][(position - 1) % 2]}
          error={getIn(errors, field.name)}
          touched={getIn(touched, field.name)}
        />
      )}
    </Field>
  );
};

export default QuestionChoicesFields;
