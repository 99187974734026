import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import moment from 'moment';

import {
  BasicTextArea,
  FormikTimePicker,
  FormikInput,
  FormikNumber,
  InputSelect,
  FormikSelect
} from '../../../components';
import { debounceIndexBookkeepersRequest } from '../../../requests/bookkeepers';
import { indexSelectEmployeesRequest } from '../../../requests/employees';
import { delayMethod } from '../../../services/utils';
import '../../../services/yupCustomMethods';

const LoanForm = ({ action, errors, isSubmitting, setFieldValue, setFieldTouched, touched, values, ...props }) => {
  const { employee, handleModalClose, loan, newLoan, onHide, reviewerSignature, rrhh } = props;
  const [grantDate, setGrantDate] = useState('');
  const dispatch = useDispatch();
  const { loan: vLoan } = values;

  const { amount, bookkeeperId, parsedAmount, installments, status } = vLoan;
  const isResolved = status === 'approved' || status === 'rejected';
  const btnMessage = newLoan ? 'Solicitar' : 'Confirmar';
  const today = new Date();

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  const fetchBookkeepers = (inputValue, callback) => {
    debounceIndexBookkeepersRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, filter_name: inputValue, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  const initialDefaultValue = attribute => {
    const currentValues = getIn(values, 'loan');
    if (currentValues) {
      const { [`${attribute}Id`]: vAttributeId } = currentValues;
      const { [`${attribute}Id`]: attributeId } = loan;
      if (attributeId && vAttributeId && attributeId === vAttributeId) {
        return getIn(loan, attribute);
      }
    }
    return undefined;
  };

  const defaultBookkeeper = initialDefaultValue('bookkeeper');
  const isDisabled = action === 'edit' && !bookkeeperId;
  const quota = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 }
  ];

  return (
    <Form className="mx-3">
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      {!employee ? (
        <>
          <Row>
            {rrhh && (
              <Col md={12}>
                <Field name="loan[employeeId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Trabajador"
                      placeholder="Seleccionar Trabajador"
                      values={values}
                      model={[loan, 'employee']}
                      request={fetchEmployees}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
            <Col md={4}>
              <Field name="loan[parsedAmount]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    leftAddon="$"
                    fieldName="loan[amount]"
                    value={amount}
                    setFieldValue={setFieldValue}
                    label="Monto Préstamo"
                    errors={errors}
                    touched={touched}
                    disabled={isResolved}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="loan[installments]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Número de Cuotas"
                    placeholder="Seleccionar Número de Cuotas"
                    options={quota}
                    defaultValue={installments}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="loan[installmentAmount]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    leftAddon="$"
                    fieldName="loan[installmentAmount]"
                    value={parseFloat(parsedAmount.replace(/[.$]/g, '')) / installments}
                    setFieldValue={setFieldValue}
                    label="Monto Cuota"
                    errors={errors}
                    touched={touched}
                    disabled
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Field name="loan[bookkeeperId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="Cuenta Contable"
                    placeholder="Seleccionar Cuenta Contable"
                    value={defaultBookkeeper}
                    request={fetchBookkeepers}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={isResolved}
                    isClearable
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="loan[grantDate]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    abbr
                    setLocale
                    minDate={new Date(today.getFullYear(), today.getMonth(), 1)}
                    maxDate={new Date(today.getFullYear(), 12, 0)}
                    label="Fecha Otorgamiento Crédito"
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={values.loan.grantDate}
                    onChange={date => {
                      setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
                      setFieldValue('loan[startDate]', '');
                      setGrantDate(
                        date !== null
                          ? moment(date)
                              .add(1, 'months')
                              .startOf('month')
                          : ''
                      );
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={!newLoan}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="loan[startDate]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    abbr
                    setLocale
                    label="Fecha Primer Descuento"
                    minDate={new Date(moment(grantDate, 'D-MM-YYYY'))}
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={values.loan.startDate}
                    onChange={date =>
                      setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date)
                    }
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={!newLoan}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="loan[reason]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    label="Motivo"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={isResolved}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </>
      ) : (
        <p>¿Estás seguro de querer {reviewerSignature ? 'firmar' : 'aprobar'} este préstamo?</p>
      )}
      {reviewerSignature && bookkeeperId ? (
        <>
          <Row>
            <Col md={12}>
              <Field name="loan[signatureKey]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Pin de firma"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </>
      ) : (
        employee && !bookkeeperId && <p>Contacta a RRHH para asignar una cuenta contable</p>
      )}

      <Row className="d-flex justify-content-end mt-3 mb-2">
        {!newLoan && (
          <Col xs={6} sm={3}>
            <Button block variant="outline-info" onClick={() => delayMethod(handleModalClose)} disabled={isSubmitting}>
              Cancelar
            </Button>
          </Col>
        )}
        <Col xs={6} sm={3}>
          <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting || isDisabled}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const tranformDate = date => {
  if (date) {
    const dateList = date.split('/');
    return new Date(dateList[1], dateList[0] - 1, 1);
  }
  return '';
};

const setInitialValues = props => {
  const { loan, newLoan, signatureKey, reviewerSignature, reqAmountLimit } = props;
  return {
    loan: {
      ...loan,
      grantDate: tranformDate(loan.grantDate),
      startDate: tranformDate(loan.startDate),
      bookkeeperId: loan.bookkeeperId || '',
      directApprove: newLoan,
      reqAmountLimit,
      reviewerSignature,
      signatureKey,
      installments: loan.installments || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  loan: Yup.object().shape({
    amount: Yup.number()
      .required('Debes ingresar un monto')
      .positive('Debe ser mayor a 0')
      .when('reqAmountLimit', (reqAmountLimit, schema) =>
        reqAmountLimit !== 0 ? schema.max(reqAmountLimit, `Monto máximo: ${reqAmountLimit}`) : schema
      ),
    bookkeeperId: Yup.string().required('Debes seleccionar una cuenta contable'),
    employeeId: Yup.string().required('Debes seleccionar un trabajador'),
    grantDate: Yup.date()
      .formatdate()
      .required('Debes ingresar la fecha del otorgamiento del crédito'),
    installments: Yup.number().required('Debes ingresar cantidad de cuotas'),
    reason: Yup.string(),
    signatureKey: Yup.string().when('reviewerSignature', {
      is: val => val,
      then: Yup.string().required('Debes ingresar el pin'),
      otherwise: Yup.string().nullable()
    }),
    startDate: Yup.date()
      .formatdate()
      .required('Debes ingresar la fecha del primer descuento')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(LoanForm);
