import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSettlementGroupsRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/settlement_groups', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showSettlementGroupRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/settlement_groups/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createSettlementGroupRequest = ({ dispatch, params, formData, successCallback, callback }) => {
  ApiService.request('post', '/settlement_groups', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });
};

export const updateSettlementGroupRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/settlement_groups/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    showLeafArrayIndexes: true
  });

export const deleteSettlementGroupRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/settlement_groups/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexSettlementGroupsRequest = AwesomeDebouncePromise(indexSettlementGroupsRequest, 300);
