import React from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from '../Utils/Tooltips/BasicTooltip';

const ActiveDotAttendance = ({ item }) => (
  <>
    {item.attendance ? (
      <BasicTooltip text="Ingresada">
        <span className="dot dot-success" data-tag="allowRowEvents"/>
      </BasicTooltip>
    ) : (
      <BasicTooltip text="No Ingresada">
        <span className="dot dot-danger" data-tag="allowRowEvents"/>
      </BasicTooltip>
    )}
  </>
);

ActiveDotAttendance.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

ActiveDotAttendance.defaultProps = {};

export default ActiveDotAttendance;
