import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
// import { ButtonTooltip, Icon } from '../../components';

// const handleNames = items => {
// const names = items.map(item => item.label);
// return names.join(', ');
// };

const BasicEmployeeDataModalShow = ({ item }) => {
  const {
    funNomAfiCr,
    funApePatCr,
    funApeMatCr,
    funRutAfiNn,
    funDvAfiCr,
    funFolFunNn,
    funEstNotReDesc,
    cisCodPrdCrDesc,
    funOriFunCrDesc,
    period
  } = item;

  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between mb-3">
            <span>Información del Empleado</span>
          </Card.Title>
          <Row>
            <Col>
              <div className="info">
                <span className="type">Nombre Completo:</span>
                <span className="line" />
                <span className="answer">{funNomAfiCr} {funApePatCr} {funApeMatCr}</span>
              </div>
              <div className="info">
                <span className="type">Rut:</span>
                <span className="line" />
                <span className="answer">{funRutAfiNn}-{funDvAfiCr}</span>
              </div>
              <div className="info">
                <span className="type">Folio del Fun:</span>
                <span className="line" />
                <span className="answer">{funFolFunNn}</span>
              </div>
              <div className="info">
                <span className="type">Tipo de Fun:</span>
                <span className="line" />
                <span className="answer">{funOriFunCrDesc}</span>
              </div>
              <div className="info">
                <span className="type">Codigo de Isapre:</span>
                <span className="line" />
                <span className="answer">{cisCodPrdCrDesc}</span>
              </div>
              <div className="info">
                <span className="type">Periodo:</span>
                <span className="line" />
                <span className="answer">{period}</span>
              </div>

              <div className="info">
                <span className="type">Estado de Notificacion:</span>
                <span className="line" />
                <span className="answer">{funEstNotReDesc}</span>
              </div>

            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default BasicEmployeeDataModalShow;
