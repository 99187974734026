import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { moveCard } from '@lourenci/react-kanban';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';

import { AbilityContext } from '../../config/abilityContext';
import { updateSelectionProcessCandidateRequest } from '../../requests/selectionProcessCandidates';
import { showCandidatesRequest, sendNotificationCandidatesRequest } from '../../requests/candidates';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import { Icon, BasicDropdown, SimpleCenteredModal } from '../../components';
import BasicCandidateShowModal from './BasicCandidateModalShow';
import Kanban from '../../components/Utils/Kanban/Kanban';
import SelectionProcessNoticeModal from './SelectionProcessNoticeModal';
import SelectionProcessCandidateEdit from './SelectionProcessCandidateEdit';

import './style.scss';

const SelectionProcessShowDetail = ({ selectionProcess, setMoreData, statusFilter }) => {
  const { selectionProcessStagesAttributes } = selectionProcess;
  const [board, setBoard] = useState({ columns: [] });
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [selectionProcessStages, setSelectionProcessStages] = useState([]);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleStages = () => {
    const stages = selectionProcessStagesAttributes.map(stage => {
      const candidates = stage.selectionProcessCandidates.filter(item => !statusFilter || item.status === statusFilter);
      return { ...stage, selectionProcessCandidates: candidates };
    });
    setSelectionProcessStages(stages);
  };

  useEffect(handleStages, [selectionProcessStagesAttributes, statusFilter]);

  const boardColumns = () => {
    setBoard({
      columns: selectionProcessStages.map(stage => ({
        id: stage.id,
        title: stage.name,
        cards: stage?.selectionProcessCandidates.map(item => ({
          id: item.candidate.id,
          fullName: item.candidate.fullName,
          nationalIdentification: item.candidate.parsedNationalIdentification,
          email: item.candidate.email,
          selectionProcessCandidateId: item.id,
          status: item.status,
          hired: item.candidate.hired,
          employeeId: item.candidate.employeeId
        }))
      }))
    });
  };

  useEffect(boardColumns, [selectionProcessStages]);

  const handleSuccessUpdate = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(moreData => !moreData);
    setModalShow(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdateSelectionProcessCandidate = (selectionProcessCandidateId, stageId, status) => {
    setOnRequest(true);
    updateSelectionProcessCandidateRequest(selectionProcess.id, selectionProcessCandidateId, {
      dispatch,
      params: snakeCaseKeys({ selectionProcessStageId: stageId, status }),
      successCallback: () => handleSuccessUpdate('Candidato actualizado con éxito'),
      callback: () => setOnRequest(false)
    });
  };

  const handleCandidateStage = (card, _source, destination) => {
    const updatedBoard = moveCard(board, _source, destination);
    setBoard(updatedBoard);
    handleUpdateSelectionProcessCandidate(card.selectionProcessCandidateId, destination.toColumnId, card.status);
  };

  const editCandidateModal = candidateId => {
    setModalShow(true);
    setModalTitle('Editar Candidato');
    setModalBody(
      <SelectionProcessCandidateEdit
        id={candidateId}
        selectionProcess={selectionProcess}
        setModalShow={setModalShow}
        setMoreData={setMoreData}
      />
    );
  };

  const showCandidateModal = candidate => {
    setModalShow(true);
    setModalTitle(candidate.fullName);
    setModalBody(<BasicCandidateShowModal item={candidate} />);
  };

  const fetchCandidate = id => {
    showCandidatesRequest(id, {
      dispatch,
      successCallback: response => showCandidateModal(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const noticeCandidate = (params, id, status) => {
    const myParams = snakeCaseKeys({
      status,
      selectionProcessId: selectionProcess.id,
      templatePdf: { ...params.templatePdf }
    });
    sendNotificationCandidatesRequest(id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessUpdate('Notificación enviada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const sendNotificationToCandidate = (id, status) => {
    setModalShow(true);
    setModalTitle('Seleccione una plantilla');
    setModalBody(<SelectionProcessNoticeModal formRequest={values => noticeCandidate(values, id, status)} />);
  };

  const handleClick = (action, id, selectionProcessStageId) => {
    switch (action) {
      case 'showCandidate':
        fetchCandidate(id);
        break;
      case 'editCandidate':
        editCandidateModal(id);
        break;
      case 'showEmployee':
        history.push(`/employees/${id}`);
        break;
      case 'acceptCandidate':
        handleUpdateSelectionProcessCandidate(id, selectionProcessStageId, 'accepted');
        break;
      case 'hireCandidate':
        history.push('/employees/new', { candidateId: id });
        break;
      case 'rejectCandidate':
        handleUpdateSelectionProcessCandidate(id, selectionProcessStageId, 'rejected');
        break;
      case 'sendAcceptance':
        sendNotificationToCandidate(id, 'accepted');
        break;
      case 'sendRejection':
        sendNotificationToCandidate(id, 'rejected');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const dropwdownItems = ({ id, employeeId, hired, selectionProcessCandidateId, selectionProcessStageId, status }) => {
    const vStatus = { applied: status === 'applied', accepted: status === 'accepted', rejected: status === 'rejected' };
    let approveCandidate = { id: selectionProcessCandidateId, key: 'acceptCandidate', title: 'Aceptar Candidato' };
    if (vStatus.accepted) approveCandidate = { id, key: 'hireCandidate', title: 'Contratar Candidato' };

    const items = [
      {
        key: 'showCandidate',
        title: 'Ver Candidato',
        props: {
          onClick: () => handleClick('showCandidate', id, null)
        }
      }
    ];

    if (hired) {
      items.push({
        key: 'showEmployee',
        title: 'Ver Trabajador',
        props: {
          onClick: () => handleClick('showEmployee', employeeId, null)
        }
      });
    } else {
      if (!vStatus.accepted) {
        items.push({
          key: 'editCandidate',
          title: 'Editar Candidato',
          props: {
            onClick: () => handleClick('editCandidate', id, null)
          }
        });
      }

      items.push({
        key: approveCandidate.key,
        title: approveCandidate.title,
        props: {
          onClick: () => handleClick(approveCandidate.key, approveCandidate.id, selectionProcessStageId),
          className: `item-${vStatus.accepted ? 'hire' : 'accept'}`
        }
      });

      if (!vStatus.rejected) {
        items.push({
          key: 'rejectCandidate',
          title: 'Rechazar Candidato',
          props: {
            onClick: () => handleClick('rejectCandidate', selectionProcessCandidateId, selectionProcessStageId),
            className: 'item-reject'
          }
        });
      }

      if (ability.can('notification', 'Candidate')) {
        if (vStatus.applied || vStatus.accepted) {
          items.push({
            key: 'sendAcceptance',
            title: 'Enviar Carta de Aceptación',
            props: {
              onClick: () => handleClick('sendAcceptance', id, null)
            }
          });
        }
        if (vStatus.applied || vStatus.rejected) {
          items.push({
            key: 'sendRejection',
            title: 'Enviar Carta de Rechazo',
            props: {
              onClick: () => handleClick('sendRejection', id, null)
            }
          });
        }
      }
    }

    return items;
  };

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad kanban-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Kanban
        board={board}
        onCardDragEnd={handleCandidateStage}
        renderCard={({ email, fullName, nationalIdentification, ...candidate }) => (
          <div className={`mb-1 mr-2 ${candidate.hired ? 'hired' : candidate.status}`}>
            <BasicDropdown
              variant="no-border"
              responsive
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  <Icon icon="ellipsis-vertical" height="20px" width="20px" />
                </span>
              }
              items={dropwdownItems(candidate)}
            />
            <div className="py-2 px-3 mr-4">
              <h5 className="mt-1 mb-3">{fullName}</h5>
              <p>{nationalIdentification}</p>
              <p className="mb-1">{email}</p>
            </div>
          </div>
        )}
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default SelectionProcessShowDetail;
