import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { useHistory } from 'react-router-dom';

import { debounceIndexEmployeesRequest } from '../../requests/employees';
import { ComponentDataTable } from '../index';
import { AbilityContext } from '../../config/abilityContext';

const DailyAttendanceDataTable = ({ moreData, columns }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexEmployeesRequest({
        dispatch,
        params: { ...params, is_dt: false, active: true, filter_work_schedules: 'full_time,part_time' },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/employees/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability.can('show', 'Employee'))}
        data={employees}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        preName="dailyAttendance"
        query="filtered_cost_center"
      />
    </>
  );
};

export default DailyAttendanceDataTable;
