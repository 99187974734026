import ApiService from '../services/apiService';

export const indexPayrollProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showPayrollProcessRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createPayrollProcessRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/payroll_processes`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const closedPayrollProcessRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('put', `/payroll_processes/${id}/close`, {
    dispatch,
    successCallback,
    callback
  });

export const budgetPayrollProcessRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/budget.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const remunerationsReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/remunerations.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const summarizedRemunerationsReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/summarized_remunerations.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const remunerationsSummaryReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/remunerations_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const singleRemunerationsReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/single_remunerations.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const senceReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/sence_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const exportPreviredRequest = (
  id,
  { dispatch, responseType = 'text', params = {}, callback, successCallback }
) =>
  ApiService.request('get', `/payroll_processes/${id}/previred.text`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
