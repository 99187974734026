import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { indexContractBalancesRequest } from '../../requests/balances';
import { ComponentDataTable } from '../../components';
import columns from './ContractBalanceColumns';

const ContractBalanceDataTable = ({ balanceId }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [contractBalances, setContractBalances] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();

  const handleMoreData = () => {
    setMoreData(!moreData);
  };

  const handleSuccessIndex = response => {
    const responseEmployees = response.data.data;
    const { responseAmount } = response.data.metadata;
    setContractBalances(responseEmployees);
    setAmount(responseAmount);
  };

  const handleIndexRequest = async params => {
    if (balanceId) {
      setOnRequest(true);
      indexContractBalancesRequest(balanceId, {
        dispatch,
        params,
        successCallback: handleSuccessIndex,
        callback: () => setOnRequest(false)
      });
    }
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        window.open(`/employees/${item.employee_id}`, '_blank');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'contract_correlative_identification':
        return { sort_by_correlative_identification: name };
      case 'name':
        return { sort_employees: name };
      default:
        return { sort_column: name };
    }
  };

  useEffect(handleMoreData, [balanceId]);

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        handleSortCase={sortColumnCase}
        data={contractBalances}
        moreData={moreData}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
      />
    </>
  );
};

export default ContractBalanceDataTable;
