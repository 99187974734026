import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { updateCostCenterRequest } from '../../requests/costCenters';
import { sendAlert } from '../../actions/utils';
import CostCenterForm from './CostCenterForm';
import CostCenterAccordion from './CostCenterAccordion';

const CostCenterIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Centro de Costo');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = costCenterValue => {
    const {
      costCenter: { id }
    } = costCenterValue;
    updateCostCenterRequest(id, {
      dispatch,
      params: snakeCaseKeys(costCenterValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Centro de costo actualizado con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editCostCenter = costCenterRow => {
    setModalShow(true);
    setModalBody(
      <CostCenterForm
        costCenter={camelCaseRecursive(costCenterRow)}
        costCenterId={costCenterRow.id}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => setModalShow(false)}
      />
    );
    setModalTitle('Editar Centro de costo');
  };

  return (
    <>
      <CostCenterAccordion editCostCenter={editCostCenter} moreData={moreData} setMoreData={setMoreData} />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default CostCenterIndex;
