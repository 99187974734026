import React from 'react';
import { useSelector } from 'react-redux';
import EmployeeSurveyDataTable from '../../Survey/EmployeeSurveyDataTable';
import columns from './EmployeeSurveyColumns';

const EmployeeSurveyIndex = () => {
  const { currentEmployee } = useSelector(state => state.auth);

  return (
    <>
      <h2 className="m-top mb-3">Mis Encuestas Pendientes</h2>
      {currentEmployee && (
        <EmployeeSurveyDataTable customParams={{ employee_id: currentEmployee, status: 'sent' }} columns={columns} />
      )}
    </>
  );
};

export default EmployeeSurveyIndex;
