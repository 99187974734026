import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { updateJobTitleRequest } from '../../requests/jobTitles';
import { sendAlert } from '../../actions/utils';
import { delayMethod } from '../../services/utils';
import JobTitleForm from './JobTitleForm';
import JobTitleDataTable from './JobTitleDataTable';
import JobTitleShow from './JobTitleShow';

const JobTitleIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Cargo');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = jobTitleValue => {
    const {
      jobTitle: { id }
    } = jobTitleValue;
    updateJobTitleRequest(id, {
      dispatch,
      params: snakeCaseKeys(jobTitleValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cargo actualizado con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editJobTitle = jobTitleRow => {
    setModalShow(true);
    setModalBody(
      <JobTitleForm
        jobTitle={camelCaseRecursive(jobTitleRow)}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => delayMethod(() => setModalShow(false))}
      />
    );
    setModalTitle('Editar cargo');
  };

  const showJobTitle = jobTitleRow => {
    setModalShow(true);
    setModalBody(<JobTitleShow jobTitle={camelCaseRecursive(jobTitleRow)} hideModal={() => setModalShow(false)} />);
    setModalTitle(jobTitleRow.name);
  };

  return (
    <>
      <JobTitleDataTable
        editJobTitle={editJobTitle}
        moreData={moreData}
        setMoreData={setMoreData}
        showJobTitle={showJobTitle}
        withMassActions
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default JobTitleIndex;
