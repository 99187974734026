import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { LinkBtn } from '../../components';
import SlideForm from './SlideForm';
import { createSlideRequest } from '../../requests/slides';

class SlideNew extends Component {
  state = {
    slide: {
      fileInfo: {},
      position: '',
      caption: '',
      active: true
    }
  };

  handleCreateRequest = slide => {
    const { dispatch } = this.props;
    createSlideRequest({
      dispatch,
      params: slide,
      formData: true,
      successCallback: response => this.handleSuccessCreate(response)
    });
  };

  handleSuccessCreate = response => {
    const { history } = this.props;
    const slide = response.data;
    history.push(`/slides/${slide.id}`);
  };

  render() {
    const { slide } = this.state;
    return (
      <>
        <Row className="mt-3">
          <Col>
            <h2>Crear Slide</h2>
          </Col>
          <Col md={2}>
            <LinkBtn variant="light" block to="/slides">
              Volver
            </LinkBtn>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <SlideForm
              slide={slide}
              action="new"
              formRequest={this.handleCreateRequest}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default connect()(SlideNew);
