import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { sendAlert } from '../../actions/utils';
import {
  ComponentDataTable,
  DefaultModal,
  ImportActions,
  ImportModal,
  SettingsMassiveActions,
  SimpleCenteredModal
} from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import {
  debounceIndexBookkeepersRequest,
  activeBookkeeperRequest,
  deactivateBookkeeperRequest,
  deleteBookkeeperRequest,
  exportBookkeepersRequest,
  importBookkeeperRequest,
  importTemplateBookkeeperRequest,
  preImportBookkeeperRequest
} from '../../requests/bookkeepers';
import BookkeeperShow from './BookkeeperShow';
import { downloadFile } from '../../services/utils';

const BookkeeperDataTable = ({ columns, moreData, setMoreData, editBookkeeper }) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [centerModalSize, setCenterModalSize] = useState('md');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [query, setQuery] = useState({});

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setWorkflowRequests(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    setQuery(params);
    debounceIndexBookkeepersRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
  };

  const handleSuccessRemove = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
    setOnRequest(false);
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
    setOnRequest(false);
    setMoreData(!moreData);
  };

  const deleteBookkeeper = item => {
    setOnRequest(true);
    deleteBookkeeperRequest({
      params: { ids: item.id },
      dispatch,
      successCallback: handleSuccessRemove('Cuenta Contable Eliminada correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const massiveDeleteBookkeeper = () => {
    const bookkeepersSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deleteBookkeeperRequest({
      params: { ids: bookkeepersSelected },
      dispatch,
      successCallback: handleSuccessRemove('Cuentas Contables Eliminadas correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveActiveBookkeeper = () => {
    const bookkeepersSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    activeBookkeeperRequest({
      params: { ids: bookkeepersSelected },
      dispatch,
      successCallback: handleSuccessRemove('Cuentas Contables Activadas correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveDeactivateBookkeeper = () => {
    const bookkeepersSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deactivateBookkeeperRequest({
      params: { ids: bookkeepersSelected },
      dispatch,
      successCallback: handleSuccessRemove('Cuentas Contables Desactivadas correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleActions = (item, action) => {
    setModalItem({});
    switch (action) {
      case 'destroy':
        setModalTitle('Eliminar Cuenta Contable');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar esta cuenta contable?');
        setModalItem(item);
        setModalAction(() => deleteBookkeeper);
        break;
      case 'show':
        setModalTitle('Cuenta Contable');
        setCenterModalSize('lg');
        setModalBody(<BookkeeperShow item={item} handleClose={() => setCenterModalShow(false)} />);
        setModalItem(item);
        setCenterModalShow(true);
        break;
      case 'edit':
        editBookkeeper(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    exportBookkeepersRequest({
      dispatch,
      params: { ...query, paginate: false },
      successCallback: response => downloadFile(response),
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setCenterModalSize('lg');
        setModalTitle('Importar Cuentas Contables');
        setModalBody(
          <ImportModal
            onDropUploaded={preImportBookkeeperRequest}
            handleTemplate={importTemplateBookkeeperRequest}
            hideModal={() => setCenterModalShow(false)}
            onHide={importBookkeeperRequest}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setCenterModalShow(true);

        break;
      case 'export':
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar cuentas contables?');
        setModalTitle('Exportar Cuentas Contables');
        setModalAction(() => handleExportRequest);
        break;
      case 'massive_destroy':
        setModalTitle('Eliminar Cuentas Contables');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar las cuentas contables seleccionadas?');
        setModalAction(() => massiveDeleteBookkeeper);
        break;
      case 'massive_active':
        setModalTitle('Activar Cuentas Contables');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas activar las cuentas contables seleccionadas?');
        setModalAction(() => massiveActiveBookkeeper);
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Cuentas Contables');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar las cuentas contables seleccionadas?');
        setModalAction(() => massiveDeactivateBookkeeper);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'type_account':
        return { sort_type_account: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability)}
        data={workflowRequests}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        selectableRows
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleActions(item, 'show')}
        withMassActions
        massActions={
          <>
            <ImportActions model="Bookkeeper" handleClick={handleMassAction} />
            <SettingsMassiveActions model="Bookkeeper" handleClick={handleMassAction} disabled={!selectedCount} />
          </>
        }
        pointerOnHover
        preName="bookkeepers"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        size={centerModalSize}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
    </>
  );
};

export default BookkeeperDataTable;
