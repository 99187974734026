import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner } from 'react-bootstrap';

import { createAbsenceRequest } from '../../requests/absences';
import AbsenceForm from './AbsenceForm';

const basicAbsence = employeeId => ({
  absenceType: '',
  absenceTypeFormat: '',
  startDate: '',
  endDate: '',
  days: '',
  observation: '',
  employeeId
});

const AbsenceNewSimple = props => {
  const { handleSuccessAction, handleModalClose, employee } = props;
  const { id } = employee;
  const [onRequest, setOnRequest] = useState(false);
  const [absence, setAbsence] = useState(basicAbsence(id));
  const dispatch = useDispatch();

  useEffect(() => {
    setAbsence(basicAbsence(id));
  }, [id, setAbsence]);

  const handleSuccessCreate = () => {
    const message = 'Ausencia creada con éxito';
    handleSuccessAction(message);
  };

  const handleFailureRequest = error => {
    const { handleFailureAction } = props;
    setOnRequest(false);
    handleFailureAction(error.response?.data?.message);
  };

  const handleCreate = (absenceValues, setSubmitting) => {
    setOnRequest(true);
    const params = snakeCaseKeys(absenceValues);

    createAbsenceRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <AbsenceForm
        absence={absence}
        action="new"
        formRequest={handleCreate}
        handleModalClose={handleModalClose} />
    </>
  );
};

export default AbsenceNewSimple;
