import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { createCourseRequest } from '../../requests/courses';
import { sendAlert } from '../../actions/utils';
import CourseForm from './CourseForm';
import basicCourse from './course';

const CourseNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (courseValues, setSubmitting) => {
    const { courseImage, courseCertificate } = courseValues.course;
    const myParams = snakeCaseKeys(courseValues);
    myParams.course.banner = courseImage;
    myParams.course.certificate = courseCertificate;

    createCourseRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: res => {
        dispatch(sendAlert({ kind: 'success', message: 'Curso creado con éxito' }));
        history.push(`/courses/${res.data.id}`);
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to="/courses">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Nuevo Curso</h2>
        </Col>
      </Row>
      <CourseForm course={basicCourse} action="new" formRequest={handleCreateRequest} />
    </>
  );
};

export default CourseNew;
