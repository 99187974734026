import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { ButtonTooltip, Icon } from '../../../components';

const InfoShow = ({ ticket, displayModal }) => {
  const truncateString = (str, num) => {
    if (str?.length <= num) {
      return str;
    }
    return `${str?.slice(0, num)}...`;
  };

  const defaultRow = (attribute, value) => (
    <Row>
      <Col md={3}>
        <p className="show-info-title">{attribute}</p>
      </Col>
      <Col md={9}>
        <p>{value}</p>
      </Col>
    </Row>
  );

  const responseChannel =
    ticket.viaEmail || ticket.viaPhone
      ? `${ticket.viaEmail ? 'Correo' : ''}
      ${ticket.viaEmail && ticket.viaPhone ? '/' : ''}
      ${ticket.viaPhone ? 'Teléfono' : ''}`
      : '-';

  return (
    <>
      <h3 className="mt-4 mb-4">INFORMACIÓN</h3>
      {defaultRow('Nº de ticket', ticket.code)}
      {defaultRow('Fecha del reporte', ticket.parsedDate)}
      {defaultRow('Hora del reporte', ticket.parsedTime)}
      {defaultRow(
        'Solicitado por',
        `${ticket.employee?.fullName} ${ticket.company ? `(${ticket.company?.label})` : ''}`
      )}
      {defaultRow('Título', ticket.title)}
      {defaultRow('Teléfono', ticket.parsedPhone)}
      {defaultRow('Correo', ticket.email)}
      {defaultRow('Canal de respuesta', responseChannel)}
      <Row>
        <Col md={3}>
          <p className="show-info-title">Detalle del incidente</p>
        </Col>
        <Col md={8}>
          <p>{ticket.description ? truncateString(ticket.description, 200) : ''}</p>
        </Col>
        {ticket.description?.length > 200 && (
          <Col md={1}>
            <Icon
              className="ticket-show-eye"
              width={18}
              icon="eye"
              onClick={() => displayModal('Observación Ticket', ticket.description, 'Cerrar', 'primary')}
            />
          </Col>
        )}
      </Row>
      <Row className="mt-4">
        <Col md={3}>
          <p className="show-info-title">Archivos adjuntos</p>
        </Col>
        <Col md={9}>
          {ticket.infoFiles &&
            ticket.infoFiles.map(file => (
              <Row key={file.id}>
                <Col xs={10}>
                  <p className="mb-0">{file.cleanFilename}</p>
                </Col>
                <Col xs={2}>
                  <ButtonTooltip
                    variant="circle-primary"
                    className="pb-1"
                    onClick={() => window.open(file.fileUrl, '_blank', 'noopener')}
                    text={`Descargar ${file.cleanFilename}`}
                  >
                    <Icon icon="download-2" size={20} />
                  </ButtonTooltip>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default InfoShow;
