import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal } from '../../components';
import { delayMethod } from '../../services/utils';
import { debounceIndexCompanyTaskRequest, deleteCompanyTaskRequest } from '../../requests/companyTasks';
import columns from './columns';
import CompanyTaskInfo from './CompanyTaskInfo';

const CompanyTaskDatatable = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [modalItem, setModalItem] = useState(null);
  const [moreData, setMoreData] = useState(false);
  const [modalTitle, setModalTitle] = useState('Eliminar Tarea');
  const [withConfirm, setWithConfirm] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleIndexRequest = params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexCompanyTaskRequest({
        dispatch,
        params: { ...params },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setTasks(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleDeleteRequest = id => {
    setOnRequest(true);
    deleteCompanyTaskRequest(id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Tarea eliminada con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => delayMethod(() => setModalShow(false))
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'edit':
        setWithConfirm(true);
        history.push(`/tasks/${item.id}/edit`);
        break;
      case 'destroy':
        setModalTitle('Eliminar tarea');
        setWithConfirm(true);
        setModalItem(item);
        setModalShow(true);
        setModalBody(`¿Estás seguro que deseas eliminar ${item.title}?`);
        break;
      case 'show':
        setModalTitle('Detalle de la tarea');
        setModalItem(item);
        setModalShow(true);
        setWithConfirm(false);
        setModalBody(<CompanyTaskInfo item={item} />);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={tasks}
        moreData={moreData}
        handleSortCase={sortColumnCase}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        onRowClicked={row => handleActions(row, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        titleBtnClose="Cerrar"
        handleConfirm={() => handleDeleteRequest(modalItem.id)}
        withConfirm={withConfirm}
        disabled={onRequest}
      />
    </>
  );
};

export default CompanyTaskDatatable;
