import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import CamelCase from 'camelcase';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { withFormik, Form, Field, getIn } from 'formik';
import { FormikInput, ButtonTooltip, FormikRangePicker, FormikSelect } from '../../components';
import { documentStatus, documentTypes } from './DocumentForm/FormOptions';

import { searchEmployeeRequest } from '../../requests/employees';

const DocumentFilter = ({ values, errors, touched, setFieldValue }) => {
  const dispatch = useDispatch();
  const [selector, setSelector] = useState('');
  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          advance: false
        },
        successCallback: response => setSelector(camelCaseRecursive(response.data.data))
      });
    request();
  };

  useEffect(searchEmployeesParams, []);

  return (
    <Form>
      <Row>
        <Col md={4} sm={6} xs={12}>
          <Field name="filterDocumentRangeDate">
            {({ field }) => (
              <FormikRangePicker
                name={field.name}
                value={field.value}
                startDateName="dateFrom"
                endDateName="dateTo"
                startDate={values.dateFrom}
                endDate={values.dateTo}
                showClearDates
              />
            )}
          </Field>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field name="filterDocumentType">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Tipo de documento:"
                placeholder="Selecciona un tipo"
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                }}
                options={documentTypes}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isClearable
              />
            )}
          </Field>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field name="filterDocumentStatus">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Status del documento:"
                placeholder="Selecciona un estatus"
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                }}
                options={documentStatus}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isClearable
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6} xs={12}>
          <Field name="filterEmployeeNationalId">
            {({ field }) => (
              <FormikInput
                {...field}
                label="N° de Identificación:"
                placeholder="Sin puntos (ej: 11111111-1)"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={8} sm={6} xs={12}>
          <Field name="filterDocumentAdvandceEmployeeRelatedData">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Filtro Avanzado"
                placeholder="Selecciona"
                options={selector}
                isMulti
                onChange={data => {
                  const advanceOptions = {
                    filter_active_job_managements: [],
                    filter_active_job_titles: [],
                    filter_active_cost_centers: [],
                    filter_active_branch_offices: []
                  };

                  if (data) {
                    data.forEach(item => {
                      advanceOptions[item.queryValue].push(item.id);
                    });
                  }

                  const options = Object.keys(advanceOptions);
                  options.forEach(item => {
                    setFieldValue(CamelCase(item), advanceOptions[item]);
                  });
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 8 }} sm={{ span: 6, offset: 6 }} xs={12}>
          <ButtonTooltip variant="primary" className="btn-block" text="Filtrar" type="submit">
            Filtrar
          </ButtonTooltip>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    filterEmployeeNationalId: '',
    filterDocumentStatus: '',
    filterDocumentType: '',
    filterDocumentName: '',
    filterDocumentAdvandceEmployeeRelatedData: [],
    dateFrom: '',
    dateTo: ''
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => setInitialValues(),
  handleSubmit,
  enableReinitialize: false
})(DocumentFilter);
