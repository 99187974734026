import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAuthorization } from '../../services/hooks';
import SelectionProcessDataTable from './SelectionProcessDataTable';
import { LinkBtn } from '../../components';
import columns from './Columns';

const SelectionProcessIndex = () => {
  const [moreData, setMoreData] = useState(false);
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="mt-3">Proceso de Selección</h2>
        </Col>
        <Col md={3} xl={2}>
          {useAuthorization('create', 'SelectionProcess') && (
            <LinkBtn variant="primary" className="mt-3" block to="/selection_processes/new">
              Nuevo
            </LinkBtn>
          )}
        </Col>
      </Row>
      <SelectionProcessDataTable columns={columns} moreData={moreData} setMoreData={value => setMoreData(value)} />
    </>
  );
};

export default SelectionProcessIndex;
