import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import CompanyInfo from '../../Shared/Company/CompanyInfo';
import { CompanyTopView } from '../../../components';
import { showAdminCompanyRequest } from '../../../requests/adminCompanies';

const basicCompany = {
  bankAccountsAttributes: [],
  compensationFund: {},
  economicActivity: {},
  safetyOrganization: {},
  businessLogo: {
    fileUrl: ''
  }
};

const CompanyShow = props => {
  const [company, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();

  const fetchCompany = () => {
    const { params } = props.match;

    showAdminCompanyRequest(params.id, {
      dispatch,
      successCallback: response => setCompany(camelCaseRecursive(response.data))
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchCompany, []);

  return (
    <>
      <CompanyTopView company={company} editTo="/admin/companies" returnTo="/admin/companies" />
      <CompanyInfo company={company} admin />
    </>
  );
};

export default CompanyShow;
