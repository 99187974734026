import React, { Component } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

import { indexWorkflowTemplatesRequest, deleteWorkflowTemplateRequest } from '../../requests/workflowTemplates';
import { ComponentDataTable, DefaultModal, BasicActionBtns, SimpleCenteredModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import WorkflowTemplateShow from './WorkflowTemplateShow';
import { sendAlert } from '../../actions/utils';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE FLUJO',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DOCUMENTO',
    selector: 'workflow_type',
    cell: item => item.translated_workflow_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="WorkflowTemplate" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

class WorkflowTemplateDataTable extends Component {
  state = {
    onRequest: true,
    workflowTemplates: [],
    amount: 0,
    modalShow: false,
    modalItem: {},
    modalBody: '',
    simpleModalShow: false
  };

  handleRequest = async params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    indexWorkflowTemplatesRequest({
      dispatch,
      params,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const workflowTemplates = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      workflowTemplates,
      amount,
      onRequest: false
    });
  };

  removeWorkflowTemplate = workflowTemplateId => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    deleteWorkflowTemplateRequest(workflowTemplateId, {
      dispatch,
      successCallback: this.handleSuccessRemove('Flujo Eliminado correctamente')
    });
  };

  handleButtonClick = (item, action) => {
    const { editWorkflowTemplate } = this.props;
    const { context } = this;
    switch (action) {
      case 'show':
        this.setState({
          simpleModalShow: true,
          modalItem: camelCaseEmptyStringRecursive(item)
        });
        break;
      case 'edit':
        if (context.can('update', 'WorkflowTemplate')) editWorkflowTemplate(item);
        break;
      case 'destroy':
        this.setState({
          modalShow: true,
          modalItem: item,
          modalBody: '¿Estás seguro que deseas eliminar este flujo?'
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeWorkflowTemplate(modalItem.id);
  };

  handleSuccessRemove = message => {
    const { setMoreData, moreData, dispatch } = this.props;
    this.setState({
      onRequest: false
    });
    this.handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  render() {
    const { workflowTemplates, amount, onRequest, modalShow, modalBody, simpleModalShow, modalItem } = this.state;
    const { moreData } = this.props;
    const { context } = this;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          data={workflowTemplates}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
          onRowClicked={item => this.handleButtonClick(item, 'show')}
          pointerOnHover={context.can('update', 'WorkflowTemplate')}
        />
        <DefaultModal
          title="Eliminar flujo"
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
          disabled={onRequest}
        />
        <SimpleCenteredModal
          title="Flujo Personalizado"
          body={
            <WorkflowTemplateShow
              workflowTemplate={modalItem}
              handleClose={() => this.setState({ simpleModalShow: false })}
            />
          }
          show={simpleModalShow}
          onHide={() => this.setState({ simpleModalShow: false })}
        />
      </>
    );
  }
}

WorkflowTemplateDataTable.contextType = AbilityContext;

export default connect()(WorkflowTemplateDataTable);
