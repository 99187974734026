import React from 'react';
import moment from 'moment';

const INITIAL_YEAR = 1850;

const RenderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
  const len = moment().year() + 50 - INITIAL_YEAR + 1;
  const yearRange = Array(len)
    .fill()
    .map((_, idx) => INITIAL_YEAR + idx);
  // const yearRange = Array(len).fill().map((_, idx) => ({ label: INITIAL_YEAR + (idx), value: INITIAL_YEAR + (idx) }))
  // const monthsSelect = moment.months().map((label, value) => ({ label: label.charAt(0).toUpperCase() + label.slice(1), value }))
  return (
    <div className="d-flex justify-content-center">
      <div className="mb-1">
        {/* <Select
          className="custom-month-select"
          options={monthsSelect}
          value={month.month()}
          defaultValue={month.month()}
          placeholder=''
          onSelect={e => onMonthSelect(month, e.value)}
        /> */}
        <select
          className="form-control"
          value={month.month()}
          onChange={e => {
            onMonthSelect(month, e.target.value);
          }}
        >
          {moment.months().map((label, value) => (
            <option key={label} value={value}>
              {label.charAt(0).toUpperCase() + label.slice(1)}
            </option>
          ))}
        </select>
      </div>
      <div>
        {/* <Select
          className="custom-month-select"
          options={yearRange}
          value={month.year()}
          defaultValue={month.year()}
          placeholder=''
          onSelect={e => onYearSelect(month, e.value)}
        /> */}
        <select
          className="form-control"
          value={month.year()}
          onChange={e => {
            onYearSelect(month, e.target.value);
          }}
        >
          {yearRange.map(yearValue => (
            <option key={`year-${yearValue}`} value={yearValue}>
              {yearValue}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RenderMonthElement;
