import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { ButtonTooltip, Icon } from '../../components';

const handleNames = items => {
  const names = items.map(item => item.label);
  return names.join(', ');
};

const BasicCandidateModalShow = ({ item }) => {
  const {
    birthdate,
    careers,
    countryName,
    createdAt,
    email,
    gender,
    filesInfo,
    fullAddress,
    fullName,
    hired,
    languages,
    nationalIdentification,
    observation,
    otherCareer,
    parsedPhone,
    resumeInfo,
    selectionProcesses,
    translatedCandidateType,
    translatedCivilState,
    translatedEducationLevel,
    translatedIdentificationType
  } = item;
  const careersMapped = handleNames(careers);

  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between mb-3">
            <span>Información del Proceso</span>
            {hired && <span className="text-hired">Candidato Contratado</span>}
          </Card.Title>
          <Row>
            <Col>
              <div className="info">
                <span className="type">Tipo de Candidato:</span>
                <span className="line" />
                <span className="answer">{translatedCandidateType}</span>
              </div>
              <div className="info">
                <span className="type">Fecha de Postulación:</span>
                <span className="line" />
                <span className="answer">{createdAt}</span>
              </div>
              <div className="info">
                <span className="type">Proceso de Selección:</span>
                <span className="line" />
                <span className="answer">
                  {selectionProcesses.map((selectionProcess, index) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold underline"
                      key={`selection-process-${index.toString()}`}
                      href={`/selection_processes/${selectionProcess.value}`}
                    >
                      {selectionProcess.label}
                    </a>
                  ))}
                </span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Información Personal</Card.Title>
          <Row>
            <Col>
              <div className="info">
                <span className="type">Identificación ({translatedIdentificationType}):</span>
                <span className="line" />
                <span className="answer">{nationalIdentification}</span>
              </div>
              <div className="info">
                <span className="type">Nombre Completo:</span>
                <span className="line" />
                <span className="answer">{fullName}</span>
              </div>
              <div className="info">
                <span className="type">Fecha de Nacimiento:</span>
                <span className="line" />
                <span className="answer">{birthdate}</span>
              </div>
              <div className="info">
                <span className="type">Género:</span>
                <span className="line" />
                <span className="answer"> {gender?.label}</span>
              </div>
              <div className="info">
                <span className="type">Estado Civil:</span>
                <span className="line" />
                <span className="answer">{translatedCivilState}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Información de Contacto</Card.Title>
          <Row>
            <Col>
              <div className="info">
                <span className="type">Email:</span>
                <span className="line" />
                <span className="answer">{email}</span>
              </div>
              <div className="info">
                <span className="type">Teléfono:</span>
                <span className="line" />
                <span className="answer">{parsedPhone}</span>
              </div>
              <div className="info">
                <span className="type">Dirección:</span>
                <span className="line" />
                <span className="answer">
                  {fullAddress}. {countryName}
                </span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Información Académica</Card.Title>
          <Row>
            <Col>
              <div className="info">
                <span className="type">Nivel Educacional:</span>
                <span className="line" />
                <span className="answer">{translatedEducationLevel}</span>
              </div>
              {careers.length > 0 && (
                <>
                  <div className="info">
                    <span className="type">Profesión u Oficio:</span>
                    <span className="line" />
                    <span className="answer">{careersMapped}</span>
                  </div>
                  {careersMapped.includes('Otro') && (
                    <div className="info">
                      <span className="type">Otra Profesión u Oficio:</span>
                      <span className="line" />
                      <span className="answer">{otherCareer}</span>
                    </div>
                  )}
                </>
              )}
              {languages.length > 0 && (
                <div className="info">
                  <span className="type">Idiomas:</span>
                  <span className="line" />
                  <span className="answer">{handleNames(languages)}</span>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Observaciones</Card.Title>
          <Row>
            <Col>
              {observation ? (
                <pre>
                  <p className="text-justify">{observation}</p>
                </pre>
              ) : (
                <p>Sin observaciones</p>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {(filesInfo.length > 0 || Object.keys(resumeInfo).length > 0) && (
        <Card>
          <Card.Body>
            <Card.Title>Documentos Adjuntos</Card.Title>
            <Row>
              <Col md={12} className="list-two-columns">
                {Object.keys(resumeInfo).length > 0 && (
                  <Row>
                    <Col xs={2}>
                      <ButtonTooltip
                        onClick={() => window.open(resumeInfo.fileUrl, '_blank', 'noopener', 'noreferrer')}
                        variant="circle-primary"
                        className="btn-circle"
                        text="Exportar"
                      >
                        <Icon icon="download-2" />
                      </ButtonTooltip>
                    </Col>
                    <Col xs={10} className="d-flex align-items-center">
                      <p className="mb-0">{resumeInfo.cleanFilename}</p>
                    </Col>
                  </Row>
                )}
                {filesInfo.map(file => (
                  <Row key={file.id}>
                    <Col xs={2}>
                      <ButtonTooltip
                        onClick={() => window.open(file.fileUrl, '_blank', 'noopener', 'noreferrer')}
                        variant="circle-primary"
                        className="btn-circle"
                        text="Exportar"
                      >
                        <Icon icon="download-2" />
                      </ButtonTooltip>
                    </Col>
                    <Col xs={10} className="d-flex align-items-center">
                      <p className="mb-0">{file.cleanFilename}</p>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default BasicCandidateModalShow;
