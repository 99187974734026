import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn, LongCard, ButtonTooltip, Icon } from '../../components';
import { debounceIndexDncRequest, dncCardsRequest } from '../../requests/dncs';
import DncDatatable from './DncDatatable';
import columnDnc from './columnDnc';
import './style.scss';

const DncIndex = () => {
  const [dncs, setDncs] = useState([]);
  const [cards, setCards] = useState({});
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [customParams, setCustomParams] = useState({});
  const [activeCard, setActiveCard] = useState('all');
  const dispatch = useDispatch();

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams
    };
    const request = async () =>
      debounceIndexDncRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { amount: responseAmount } = response.data.metadata;
          setDncs(response.data.data);
          setAmount(responseAmount);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const fetchIndexCards = () => {
    const request = async () => {
      dncCardsRequest({
        dispatch,
        successCallback: response => setCards(response.data)
      });
    };
    request();
  };

  const handleClick = card => {
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setActiveCard('pending');
        setMoreData(!moreData);
        break;
      case 'finished':
        setCustomParams({ status: 'finished' });
        setActiveCard('finished');
        setMoreData(!moreData);
        break;
      default:
        setCustomParams({});
        setActiveCard('all');
        setMoreData(!moreData);
    }
  };

  useEffect(fetchIndexCards, [moreData]);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3">Detección de Necesidades de Capacitación</h2>
        </Col>
        <Col md={3} className="px-md-0 pr-xl-2 my-3 mr-md-2 d-flex justify-content-end">
          <LinkBtn variant="primary" className="mb-md-3" to="/dnc/new">
            Nuevo
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <Row>
            <Col xs={12} md={6} xl={3}>
              <LongCard
                variant="warning"
                title={cards.pending_count}
                label="Pendiente"
                active={activeCard === 'pending'}
                onClick={() => handleClick('pending')}
              />
            </Col>
            <Col xs={12} md={6} xl={3}>
              <LongCard
                variant="primary"
                title={cards.finished_count}
                label="Terminada"
                active={activeCard === 'finished'}
                onClick={() => handleClick('finished')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="mb-5" />
      {activeCard !== 'all' && (
        <Col xs={12}>
          <ButtonTooltip
            onClick={() => handleClick('all')}
            variant="link"
            className="text-primary"
            size="sm"
            text="Volver"
          >
            <Icon icon="chevron-back" width={15} />
            <span>Volver a tabla inicial</span>
          </ButtonTooltip>
        </Col>
      )}
      <DncDatatable
        dncs={dncs}
        amount={amount}
        onRequest={onRequest}
        handleIndexRequest={handleIndexRequest}
        customParams={customParams}
        moreData={moreData}
        setMoreData={setMoreData}
        columnDnc={columnDnc}
      />
    </>
  );
};

export default DncIndex;
