import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';
import { SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { profileAssistanceRequest } from '../../../requests/dashboards';
import VacationCard from '../../../components/Vacation/VacationCard';
import VacationDataTable from '../../Shared/Vacation/VacationDataTable';
import VacationNew from './VacationNew';
import columns from './Columns';

const VacationIndex = ({ currentEmployee }) => {
  const [additionalVacationCard, setAdditionalVacationCard] = useState({});
  const { advanceSettings } = useSelector(state => state.utils);
  const [excessAccumulatedPeriods, setExcessAccumulatedPeriods] = useState(false);
  const [legalVacationCard, setLegalVacationCard] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [progressiveVacationCard, setProgressiveVacationCard] = useState(false);
  // const [vacationsAvailable, setVacationsAvailable] = useState(0);
  const [toggle, setToggle] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const { data } = response;
    const legalVacationCardResponse = camelCaseRecursive(data.legal_vacation_card);
    setAdditionalVacationCard(camelCaseRecursive(data.additional_days_vacation_card));
    setExcessAccumulatedPeriods(legalVacationCardResponse.excessAccumulatedPeriods);
    setLegalVacationCard(legalVacationCardResponse);
    setProgressiveVacationCard(camelCaseRecursive(data.progressive_vacation_card));
    // setVacationsAvailable(data.vacations_available);
  };

  const fetchEmployeeCards = () => {
    if (currentEmployee) {
      profileAssistanceRequest({
        dispatch,
        successCallback: handleSuccessShow
      });
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessAction = message => {
    handleModalClose();
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  useEffect(fetchEmployeeCards, [currentEmployee]);

  const handleOpenModal = () => {
    setModalTitle('Solicitar Vacaciones');
    setModalBody(<VacationNew onSuccess={handleSuccessAction} employeeId={currentEmployee} />);
    setModalShow(true);
  };

  const buttonToggle = () => setToggle(!toggle);

  const legalHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'legal_holiday')[0]?.textValue === 'true';
  const progressiveHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'progressive_holiday')[0]?.textValue === 'true';
  const additionalDaysEnabled =
    advanceSettings.filter(asItem => asItem.code === 'additional_days')[0]?.textValue === 'true';
  const newBtnVisible = legalHolidayEnabled || progressiveHolidayEnabled || additionalDaysEnabled;

  return (
    <Row className="mt-2 mb-3">
      <Col>
        <Row>
          <Col md={{ span: 3, offset: 9 }}>
            {ability.can('create', 'Vacation') && newBtnVisible && (
              <Button variant="primary" className="d-none d-md-block mt-0" block onClick={handleOpenModal}>
                Nuevo
              </Button>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-between d-md-none mt-3">
          <Button variant="outline-primary" onClick={buttonToggle}>
            {toggle ? 'Ver datos' : 'Ver registro'}
          </Button>
          {ability.can('create', 'Vacation') && newBtnVisible && (
            <Button variant="primary" onClick={handleOpenModal}>
              Nuevo
            </Button>
          )}
        </div>
        <div className={`${toggle ? '' : 'd-block'} d-none d-md-block`}>
          <Row className="mt-4">
            {legalHolidayEnabled && (
              <Col md={4}>
                <VacationCard
                  title="Mis Vacaciones"
                  vacationCard={legalVacationCard}
                  excessAccumulatedPeriods={excessAccumulatedPeriods}
                  proportionalVacation
                />
              </Col>
            )}
            {progressiveHolidayEnabled && (
              <Col md={4}>
                <VacationCard title="Mis Vacaciones Progresivas" vacationCard={progressiveVacationCard} />
              </Col>
            )}
            {additionalDaysEnabled && (
              <Col md={4}>
                <VacationCard title="Mis Vacaciones Adicionales" vacationCard={additionalVacationCard} />
              </Col>
            )}
          </Row>
        </div>
        <div className={`${toggle ? 'd-block' : ''} d-none d-md-block`}>
          <VacationDataTable
            columns={columns}
            moreData={moreData}
            setMoreData={setMoreData}
            customParams={{
              filter_employee: currentEmployee,
              filter_vacation_exception: 'administrative_days, administrative_days_openning_balance',
              sort_status: 'status'
            }}
          />
        </div>
        <SimpleCenteredModal title={modalTitle} size="lg" body={modalBody} show={modalShow} onHide={handleModalClose} />
      </Col>
    </Row>
  );
};

export default VacationIndex;
