import React from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import workflowTypes from './FormOptions';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';
import { FormikSelect, InputSelect, ReviewerFields } from '../../components';
import { delayMethod } from '../../services/utils';

const defaultReviewer = {
  position: 0,
  approval: false,
  signature: false,
  reviewerTemplateIds: []
};

const WorkflowTemplateForm = ({ ...props }) => {
  const dispatch = useDispatch();

  const fetchDocumentTemplates = (inputValue, callback) => {
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: response => callback(response.data?.data)
    });
  };

  // Initial remote selects values
  const initialDefaultValue = (attribute, attrObject) => {
    const { values, workflowTemplate } = props;
    const { [`${attribute}`]: vAttributeId } = values.workflowTemplate;
    const { [`${attribute}`]: attributeId } = workflowTemplate;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      return getIn(workflowTemplate, `${attrObject}`);
    }
    return undefined;
  };

  const {
    onHide,
    errors,
    touched,
    action,
    values,
    setFieldValue,
    setFieldTouched,
    workflowTemplate,
    cancelAction,
    isSubmitting
  } = props;
  const { workflowTemplate: vWorkflowTemplate } = values;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const initialTemplate = initialDefaultValue('documentTemplateId', 'documentTemplate');

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col md={vWorkflowTemplate.workflowType === 'document_template' ? 6 : 12}>
              <Field name="workflowTemplate[workflowType]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Tipo"
                    placeholder="Seleccionar Tipo de Flujo"
                    options={workflowTypes}
                    defaultValue={workflowTemplate.workflowType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {vWorkflowTemplate.workflowType === 'document_template' && (
              <Col md={6}>
                <Field name="workflowTemplate[documentTemplateId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Plantilla"
                      placeholder="Seleccionar Plantilla"
                      value={initialTemplate}
                      request={fetchDocumentTemplates}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
          </Row>
          <h4 className="mb-1"> Pasos a seguir:</h4>
          <ReviewerFields
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            reviewerAttributes={vWorkflowTemplate.reviewerTemplatesAttributes}
            attributesPath="workflowTemplate[reviewerTemplatesAttributes]"
            attributeReviewerIds="reviewerTemplateIds"
            attributeEmployeeReviewers="employeeReviewerTemplatesAttributes"
            defaultReviewer={defaultReviewer}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={() => delayMethod(cancelAction)}>
            Cancelar
          </Button>
          <Button type="submit" className="btn" disabled={isSubmitting} variant="primary" onClick={onHide}>
            {btnMessage}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { workflowTemplate } = props;
  return { workflowTemplate };
};

const validationSchema = Yup.object().shape({
  workflowTemplate: Yup.object().shape({
    workflowType: Yup.string().required('Debes ingresar un tipo'),
    documentTemplateId: Yup.string().when('workflowType', {
      is: val => val === 'document_template',
      then: Yup.string().required('Debes seleccionar un tipo de documento'),
      otherwise: Yup.string().nullable()
    }),
    reviewerTemplatesAttributes: Yup.array().of(
      Yup.object().shape({
        approval: Yup.boolean().test('check-signature', 'Debe seleccionar al menos una acción', function oneBoolean(
          value
        ) {
          if (value || this.parent.signature) {
            return true;
          }
          return false;
        }),
        position: Yup.number(),
        signature: Yup.boolean(),
        reviewerTemplateIds: Yup.array()
          .min(1, 'Debes seleccionar al menos un responsable')
          .required('Debes seleccionar un responsable')
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(WorkflowTemplateForm);
