import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { AbilityContext } from '../../config/abilityContext';
import absenceColumns from './Columns';
import AbsenceDataTable from './AbsenceDataTable';
import { LinkBtn } from '../../components';

const AbsenceIndex = () => {
  const [moreData, setMoreData] = useState(false);
  const ability = useAbility(AbilityContext);

  return (
    <>
      <Row className="m-top mb-3">
        <Col>
          <h2 className="m-top mt-1 mb-0">Ausentismo</h2>
        </Col>
        {ability.can('massive_create', 'Absence') && (
          <Col md={4} lg={3}>
            <LinkBtn variant="info" block to="/absences/new">
              Agregar Ausentismo
            </LinkBtn>
          </Col>
        )}
      </Row>
      <AbsenceDataTable columns={absenceColumns} moreData={moreData} setMoreData={setMoreData} />
    </>
  );
};

export default AbsenceIndex;
