import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { LinkBtn, Icon } from '../../components';
import { showSurveyRequest } from '../../requests/surveys';
import { showEmployeeSurveyRequest } from '../../requests/employeeSurveys';
import basicSurvey from '../Survey/survey';
import SurveyPreview from '../Survey/SurveyPreview';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const EmployeeSurveyShow = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const [survey, setSurvey] = useState(basicSurvey);
  const [employeeSurvey, setEmployeeSurvey] = useState({ employeeQuestionChoicesAttributes: [] });

  const fetchEmployeeSurvey = () => {
    const request = async () => {
      showEmployeeSurveyRequest(id, {
        dispatch,
        successCallback: response => setEmployeeSurvey(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const fetchSurvey = () => {
    if (employeeSurvey.survey?.value) {
      const request = async () => {
        showSurveyRequest(employeeSurvey.survey.value, {
          dispatch,
          successCallback: response => setSurvey(camelCaseEmptyStringRecursive(response.data))
        });
      };
      request();
    }
  };

  useEffect(fetchEmployeeSurvey, [id]);
  useEffect(fetchSurvey, [employeeSurvey]);

  return (
    <>
      <Row className="my-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to={`/surveys/${survey.id}`} disabled={!survey.id}>
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">{survey.name}</h2>
      </Row>
      <SurveyPreview survey={survey} employeeSurvey={employeeSurvey} />
    </>
  );
};

export default EmployeeSurveyShow;
