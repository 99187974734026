import React, { useState } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import FamilyGroupInfo from './FamilyGroupInfo';
import { useAuthorization } from '../../../services/hooks';
import PersonalData from './PersonalData';
import ContactData from './ContactData';
import AcademicInformation from './AcademicInformation';
import EmergencyContact from './EmergencyContact';

const EmployeeInfo = ({ employee, admin }) => {
  const [activeKey, setKey] = useState('personal-data');
  const { familyGroupsAttributes } = employee;

  return (
    <>
      <Accordion defaultActiveKey="0" className="ml-2">
        <Row>
          <Col sm={12} md={6} xs={12} className="mt-2">
            <PersonalData employee={employee} activeKey={activeKey} activeAccordion={() => setKey('personal-data')} />
            <EmergencyContact
              employee={employee}
              activeKey={activeKey}
              activeAccordion={() => setKey('emergency-contact')}
            />
          </Col>
          <Col sm={12} md={6} xs={12} className="mt-2">
            <ContactData employee={employee} activeKey={activeKey} activeAccordion={() => setKey('contact-data')} />
            <AcademicInformation
              employee={employee}
              activeKey={activeKey}
              activeAccordion={() => setKey('academic-information')}
            />
          </Col>
          <Col sm={12} md={12} xs={12} className="mt-2">
            {(useAuthorization('read', 'FamilyGroup') || admin) && (
              <FamilyGroupInfo
                activeKey={activeKey}
                activeAccordion={() => setKey('family-group')}
                familyGroupsAttributes={familyGroupsAttributes}
              />
            )}
          </Col>
        </Row>
      </Accordion>
    </>
  );
};

export default EmployeeInfo;
