export default {
  title: '',
  startDate: '',
  endDate: '',
  description: '',
  sendEmail: true,
  sendNotification: true,
  priority: '',
  status: '',
  employeeIds: [],
  employees: []
};
