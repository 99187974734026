import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCourseRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/courses', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showCourseRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/courses/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createCourseRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/courses', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const updateCourseRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/courses/${id}/`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const deleteCourseRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/courses/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexCourseRequest = AwesomeDebouncePromise(indexCourseRequest, 300);
