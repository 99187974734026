import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'TITULO',
    selector: 'title',
    sortable: true,
    grow: '2'
  },
  {
    name: 'ASIGNADO A',
    selector: 'test',
    cell: item => {
      const employeeNames = item.employees.map(currentValue => {
        return `${currentValue.short_name} `;
      });

      return employeeNames;
    },
    sortable: false,
    grow: '1'
  },
  {
    name: 'VENCIMIENTO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'PRIORIDAD',
    selector: 'priority',
    cell: item => item.translated_priority,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      switch (item.status) {
        case 'pending':
          itemColor = 'warning';
          break;
        case 'finished':
          itemColor = 'primary';
          break;
        default:
          itemColor = 'warning';
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns item={item} resource="CompanyTask" clickHandler={clickHandler} withShow withEdit withDestroy />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
