/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';
import './styles.scss';

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || ' '
    };
  }

  componentDidUpdate(prevState) {
    const { name } = this.props;
    const { name: sName } = this.state;
    if (name !== sName && prevState.name === sName) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ name });
    }
  }

  _handleFileChange(e) {
    const { onChange } = this.props;
    e.preventDefault();
    if (e.target.files.length === 0) {
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        name: file.name
      });
    };
    reader.readAsDataURL(file);
    onChange(file);
  }

  render() {
    const { name } = this.state;
    const {
      abbr,
      error,
      inputFileId,
      label,
      touched,
      formats,
      disabled,
      direction,
      delayShow,
      delayHide,
      tooltipText
    } = this.props;
    const acceptedFormats = formats?.length ? formats.join(',') : 'application/*,.pdf';
    return (
      <div className={`${error && touched ? 'is-invalid mb-3' : 'mb-3'} ${disabled && 'disabled'}`}>
        {label && (
          <Form.Label className="d-flex align-items-center">
            {label} {abbr && <abbr className="text-danger">*</abbr>}
            {tooltipText && (
              <ButtonToolbar className="ml-2">
                <OverlayTrigger
                  key={direction}
                  placement={direction}
                  delay={{ show: delayShow, hide: delayHide }}
                  overlay={<Tooltip>{tooltipText}</Tooltip>}
                >
                  <Icon icon="help-circle" height="15px" width="15px" />
                </OverlayTrigger>
              </ButtonToolbar>
            )}
          </Form.Label>
        )}
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input secondary"
              id={inputFileId}
              aria-describedby="inputGroupFileAddon01"
              onChange={e => this._handleFileChange(e)}
              accept={acceptedFormats}
            />
            <label
              className={`custom-file-label text-truncate ${error && touched ? 'error' : ''}`}
              htmlFor={inputFileId}
            >
              {name}
            </label>
          </div>
        </div>
        {error && touched && <Form.Text className="text-danger mb-3">{error}</Form.Text>}
      </div>
    );
  }
}

UploadFile.defaultProps = {
  onChange: () => {},
  inputFileId: 'inputGroupFile01',
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  tooltipText: ''
};

export default UploadFile;
