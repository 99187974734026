import React from 'react';
import { Image } from 'react-bootstrap';
import Icon from '../Icons';

const TopView = ({ businessLogo, businessName, nationalIdentification, representants }) => (
  <div className="d-flex align-items-center mt-4 mt-lg-3">
    <div className="avatar company">
      <div className={businessLogo.fileUrl ? 'content' : 'empty content'}>
        {businessLogo.fileUrl ? <Image src={businessLogo.fileUrl} /> : <Icon icon="camera" />}
      </div>
    </div>
    <div className="margin-top-box">
      <h3 className="text-dark text-uppercase mb-0 font-size-responsive">{businessName}</h3>
      <p>{nationalIdentification}</p>
      <h4 className="text-uppercase mt-0 mb-0 font-size-responsive">Representantes</h4>
      <p className="represent">{representants}</p>
    </div>
  </div>
);

export default TopView;
