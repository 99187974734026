import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';

const StatusLicenseInfo = ({ statusLicence }) => {
  const listStatusLicense =
    statusLicence instanceof Array ? (
      statusLicence.map(status => (
        <tr>
          <td>
            {status.cod_estado} - {status.glosa_estado.toUpperCase()}
          </td>
          <td>{status.observacion}</td>
          <td>{moment(status.fecha).format('DD/MM/YYYY HH:mm:ss')}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          {statusLicence.cod_estado} - {statusLicence.glosa_estado.toUpperCase()}
        </td>
        <td>{statusLicence.observacion}</td>
        <td>{moment(statusLicence.fecha).format('DD/MM/YYYY HH:mm:ss')}</td>
      </tr>
    );
  return (
    <div className="info-box">
      <Table size="sm">
        <thead>
          <tr>
            <th className="col-4">Estado</th>
            <th className="col-5">Observación</th>
            <th className="col-3">Fecha</th>
          </tr>
        </thead>
        <tbody>{listStatusLicense}</tbody>
      </Table>
    </div>
  );
};

export default StatusLicenseInfo;
