import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import {
  updatePerformanceEvaluationRequest,
  showPerformanceEvaluationRequest
} from '../../requests/performanceEvaluations';
import { sendAlert } from '../../actions/utils';
import PerformanceEvaluationForm from './PerformanceEvaluationForm';
import basicPerformanceEvaluation from './performanceEvaluation';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const PerformanceEvaluationEdit = ({ match }) => {
  const { id } = match.params;
  const [performanceEvaluation, setPerformanceEvaluation] = useState(basicPerformanceEvaluation);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchPerformanceEvaluation = () => {
    const request = async () => {
      showPerformanceEvaluationRequest(id, {
        dispatch,
        successCallback: response => setPerformanceEvaluation(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleUpdateRequest = (performanceEvaluationValues, setSubmitting) => {
    updatePerformanceEvaluationRequest(id, {
      dispatch,
      params: snakeCaseKeys(performanceEvaluationValues, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Evaluación actualizada con éxito' }));
        history.push(`/performance_evaluations`);
      },
      callback: () => setSubmitting(false)
    });
  };

  const toggleStep = () => {
    setStep(step === 1 ? 2 : 1);
  };

  useEffect(fetchPerformanceEvaluation, [id]);

  return (
    <>
      <Row className="m-top mb-4">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" to="/performance_evaluations">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase">Editar {performanceEvaluation.name}</h2>
        </Col>
        <Col md={2}>
          <Button variant="info" block onClick={toggleStep}>
            {step === 1 ? 'Siguiente' : 'Volver'}
          </Button>
        </Col>
      </Row>
      <PerformanceEvaluationForm
        performanceEvaluation={performanceEvaluation}
        step={step}
        setStep={setStep}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </>
  );
};

export default PerformanceEvaluationEdit;
