import React from 'react';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const AttendanceShow = ({ attendance }) => {
  const { attendanceDate, arrivalMark, departureMark, lastReplacement } = camelCaseEmptyStringRecursive(attendance);
  const translateStatus = {
    pending: 'Pendiente',
    approved: 'Aprobado',
    rejected: 'Rechazado'
  };
  return (
    <div className="info-box">
      <p className="info">
        <span>Fecha:</span> {attendanceDate}
      </p>
      <p className="info">
        <span>Hora Entrada:</span> {arrivalMark}
      </p>
      <p className="info">
        <span>Hora Salida:</span> {departureMark}
      </p>

      {Object.keys(lastReplacement).length > 0 && (
        <div className="info-box">
          <h4> Reemplazando a: </h4>
          <p className="info">
            <span>Fecha:</span> {lastReplacement.attendanceDate}
          </p>

          {lastReplacement.arrivalMark && (
            <p className="info">
              <span>Hora / Entrada:</span> {lastReplacement.arrivalMark}
            </p>
          )}

          {lastReplacement.arrivalMark && (
            <p className="info">
              <span>Hora / Salida:</span> {lastReplacement.departureMark}
            </p>
          )}

          <p className="info">
            <span>Remplazado Por:</span> {lastReplacement.reaplacedBy}
          </p>

          <p className="info">
            <span>Estatus Remplazo:</span> {translateStatus[lastReplacement.status]}
          </p>
        </div>
      )}
    </div>
  );
};

export default AttendanceShow;
