import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const SmallCard = ({ variant, onClick, label, total, card, active, actionBtn, defaultCursor = true }) => (
  <Card className={`small-card bl-0 ${active ? 'active-card' : ''} card-${variant} ${defaultCursor === true ? '' : 'no-link'}`} onClick={onClick}>
    <div className="d-flex flex-row">
      {active && <span className="active-border" />}
      <Card.Body>
        <div className={`flex-col my-auto ${active ? '' : 'inactive-margin'}`}>
          <Card.Subtitle className="mb-2">{label}</Card.Subtitle>
          <Card.Title>{card[1]} %</Card.Title>
          <div className="d-flex flex-row">
            <Card.Subtitle className="mt-2">
              {card[0]} / {total}
            </Card.Subtitle>
            {actionBtn}
          </div>
        </div>
      </Card.Body>
    </div>
  </Card>
);

SmallCard.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  card: PropTypes.arrayOf(PropTypes.number)
};

SmallCard.defaultProps = {
  variant: 'info',
  label: '',
  card: [0, 0]
};

export default SmallCard;
