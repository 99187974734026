import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { FormikSelect, InputSelect } from '../../../components';
import { clasificationTypes, criticalityTypes } from './FormOptions';
import { indexUserAdminRequest } from '../../../requests/adminUsers';

const TicketEdit = props => {
  const { errors, handleModalClose, setFieldTouched, setFieldValue, ticket, touched, values, isSubmitting } = props;
  const dispatch = useDispatch();

  const fetchUsers = (inputValue, callback) => {
    indexUserAdminRequest({
      dispatch,
      params: {
        business_name: inputValue,
        sort_column: 'email',
        paginate: false
      },
      successCallback: data => callback(data.data.data)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Form className="container">
        <Row>
          <Col md={6}>
            <Field name="ticket[userId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  label="Responsable"
                  placeholder={ticket.user?.label || 'Seleccionar responsable'}
                  values={values}
                  model={[ticket, 'user']}
                  request={fetchUsers}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="ticket[clasification]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Clasificación"
                  placeholder={ticket.translatedClasification || 'Seleccionar Clasificación'}
                  options={clasificationTypes}
                  defaultValue={ticket.clasification}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="ticket[criticality]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Criticidad"
                  placeholder={ticket.translatedCriticality || 'Seleccionar Criticidad'}
                  options={criticalityTypes}
                  defaultValue={ticket.criticality}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="justify-content-end mt-3">
          <Col md={{ span: 3, offset: 6 }}>
            <Button className="btn" variant="outline-info" block onClick={handleModalClose}>
              CANCELAR
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting} block>
              CONFIRMAR
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { id, clasification, criticality, user } = props.ticket;
  return {
    ticket: {
      id,
      clasification,
      criticality,
      userId: user?.value
    }
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit
})(TicketEdit);
