import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import {
  deleteAdvancePaymentRequest,
  exportAdvancePaymentsRequest,
  importAdvancePaymentRequest,
  importTemplateAdvancePaymentRequest,
  indexAdvancePaymentRequest,
  preImportAdvancePaymentRequest,
  generatePaymentAdvancePaymentRequest,
  generateMassPaymentAdvancePaymentRequest,
  generateMassSignAdvancePaymentRequest
} from '../../../requests/advancePayments';
import { rejectWorkflowRequestRequest } from '../../../requests/workflowRequests';
import { sendAlert } from '../../../actions/utils';
import { delayMethod, downloadFile } from '../../../services/utils';
import { AbilityContext } from '../../../config/abilityContext';
import {
  ButtonTooltip,
  ComponentDataTable,
  DefaultModal,
  Icon,
  ImportModal,
  Reminder,
  SimpleCenteredModal
} from '../../../components';
import WorkflowRequestReject from '../../WorkflowRequest/WorkflowRequestReject';
import AdvancePaymentEdit from './AdvancePaymentEdit';
import ItemsInfo from '../../Employee/Items/ItemsInfo';

const AdvancePaymentDataTable = ({
  columns,
  customParams,
  defaultEndDate,
  defaultStartDate,
  employee,
  hhrr,
  moreData,
  preName,
  setMoreData,
  setWorkflowIds = () => null,
  withDateSelect,
  withImport
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [disabledSelectdCount, setDisabledSelectdCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [query, setQuery] = useState({});
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setWorkflowRequests(data);
    setWorkflowIds(data.map(element => element.id));
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    setQuery({ ...params, ...customParams });
    indexAdvancePaymentRequest({
      dispatch,
      params: { ...params, ...customParams },
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
  };

  const handleSuccessAction = message => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const rejectDocument = values => {
    const {
      workflowRequest: { id }
    } = values;
    setOnRequest(true);
    rejectWorkflowRequestRequest(id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessAction('Solicitud rechazada con éxito'),
      callback: () => setOnRequest(false)
    });
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada correctamente' }));
    setMoreData(!moreData);
  };

  const removeAdvancePayment = item => {
    setOnRequest(true);
    deleteAdvancePaymentRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const generatePaymentDocument = (item = modalItem) => {
    setOnRequest(true);
    generatePaymentAdvancePaymentRequest(item.id, {
      dispatch,
      params: { date: defaultStartDate.format('MM_YY') },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documento generado con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleMassPaymentDocument = () => {
    setOnRequest(true);
    generateMassPaymentAdvancePaymentRequest({
      dispatch,
      params: { ids: selectedRows.map(item => item.id), date: defaultStartDate.format('MM_YY') },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documentos generados con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleMassSign = (singleId = modalItem) => {
    const params = {
      date: customParams?.date,
      ids: Object.keys(singleId).length === 0 ? selectedRows.map(item => item.id) : [singleId.id]
    };
    setOnRequest(true);
    generateMassSignAdvancePaymentRequest({
      dispatch,
      params,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documentos enviados a firmar con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    setModalItem({});
    const reviewerSignature = item.request_reviewer_signature;
    const rut = item.employee.national_identification.replace(/\W+/g, '');
    const fileName = `${rut}_${customParams?.date}`;
    switch (action) {
      case 'payment_document':
        if (item.documents.filter(doc => doc.name.includes(fileName)).length >= 1) {
          setModalTitle('¡Ya existe un documento para este anticipo!');
          setModalShow(true);
          setModalBody('¿Estás seguro que deseas remplazarlo?');
          setModalAction(() => generatePaymentDocument);
          setModalItem(item);
        } else {
          generatePaymentDocument(item);
        }
        break;
      case 'approved':
        setModalTitle(reviewerSignature ? 'Firmar Documento' : 'Aprobar Solicitud');
        setModalBody(
          <AdvancePaymentEdit
            item={item}
            reviewerSignature={reviewerSignature}
            handleSuccessAction={handleSuccessAction}
            cancelAction={() => delayMethod(() => setCenterModalShow(false))}
            employee={employee}
          />
        );
        setModalItem(item);
        setCenterModalShow(true);
        break;
      case 'destroy':
        setModalTitle('Eliminar Solicitud');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
        setModalAction(() => removeAdvancePayment);
        setModalItem(item);
        break;
      case 'rejected':
        setModalTitle('Rechazar Solicitud');
        setModalBody(
          <WorkflowRequestReject
            workflowRequest={item.workflow_request_id}
            cancelAction={() => delayMethod(() => setCenterModalShow(false))}
            formRequest={rejectDocument}
          />
        );
        setModalItem(item);
        setCenterModalShow(true);
        break;
      case 'show':
        setModalTitle('Anticipo');
        setModalBody(<ItemsInfo type="advancePayment" item={item} />);
        setModalItem(item);
        setCenterModalShow(true);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      case 'sign_request':
        setModalTitle(`Enviar a Firmar`);
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas enviar a firmar el documento de pago del mes seleccionado?`);
        setModalAction(() => handleMassSign);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_title':
        return { sort_job_titles: name };
      case 'job_management':
        return { sort_job_managements: name };
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleDownload = () => {
    setOnRequest(true);

    exportAdvancePaymentsRequest({
      dispatch,
      params: query,
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassAction = action => {
    setModalItem({});
    switch (action) {
      case 'import':
        setModalTitle('Importar anticipos');
        setModalBody(
          <ImportModal
            onDropUploaded={preImportAdvancePaymentRequest}
            handleTemplate={importTemplateAdvancePaymentRequest}
            onHide={importAdvancePaymentRequest}
            hideModal={handleModalClose}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setCenterModalShow(true);
        break;
      case 'export':
        setModalTitle(`Exportar anticipos`);
        setModalShow(true);
        setModalBody(`¿Estás seguro que deseas descargar anticipos?`);
        setModalAction(() => handleDownload);
        break;
      case 'payment_document':
        setModalTitle(`Generar Documento de Pago`);
        setModalShow(true);
        setModalBody(`¿Estás seguro que deseas generar el documento para ${selectedCount} anticipos?`);
        setModalAction(() => handleMassPaymentDocument);
        break;
      case 'sign_request':
        setModalTitle(`Enviar a Firmar`);
        setModalShow(true);
        setModalBody(
          `¿Estás seguro que deseas enviar a firmar el documento de pago del mes seleccionado, para 
           ${selectedCount - disabledSelectdCount} anticipos?`
        );
        setModalAction(() => handleMassSign);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
    setDisabledSelectdCount(item.selectedRows.filter(ele => ele.workflow_request_status !== 'No Emitido').length);
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability)}
        handleSortCase={sortColumnCase}
        data={workflowRequests}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        withStartDate
        withEndDate
        rangePicker
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        selectableRows={hhrr}
        onSelectedRowsChange={handleSelectedRows}
        preName={preName || 'advancePayments'}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        withMassActions
        withDateSelect={withDateSelect}
        massActions={
          hhrr ? (
            <>
              <ButtonTooltip
                variant="circle-primary"
                className="btn-circle"
                onClick={() => handleMassAction('payment_document')}
                text="Generar documentos de pago"
                disabled={selectedCount === 0}
              >
                <Icon icon="document" />
              </ButtonTooltip>
              {ability.can('create_document', 'WorkflowRequest') && (
                <ButtonTooltip
                  variant="circle-primary"
                  className="btn-circle mr-2"
                  text="Enviar a Firmar"
                  onClick={() => handleMassAction('sign_request')}
                  disabled={selectedCount - disabledSelectdCount === 0}
                >
                  <Icon icon="finger-print" />
                </ButtonTooltip>
              )}
            </>
          ) : (
            <>
              {ability.can('export', 'AdvancePayment') && (
                <ButtonTooltip
                  variant="circle-primary"
                  className="mr-2 btn-circle mb-3"
                  text="Exportar"
                  onClick={() => handleMassAction('export')}
                >
                  <Icon icon="download-2" />
                </ButtonTooltip>
              )}
              {ability.can('import', 'AdvancePayment') && withImport && (
                <ButtonTooltip
                  variant="circle-primary"
                  className="mr-2 btn-circle mb-3"
                  text="Importar"
                  onClick={() => handleMassAction('import')}
                >
                  <Icon icon="cloud-upload-outline" />
                </ButtonTooltip>
              )}
            </>
          )
        }
      />
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default AdvancePaymentDataTable;
