const translateStatus = {
  ok: 'OK',
  incomplete: 'Incompleto',
  justified: 'Justificado',
  incorrect: 'Incorrecto',
  corrected: 'Corregido',
  corrected_rejected: 'Corregido - Rechazado',
  corrected_approved: 'Corregido - Aprobado',
}

export default translateStatus;