import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { showCandidatesRequest, updateCandidatesRequest } from '../../requests/candidates';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import CandidateForm from './CandidateForm';
import basicCandidate from './candidate';

const SelectionProcessCandidateEdit = ({ id, selectionProcess, setOnRequest, setModalShow, setMoreData }) => {
  const [candidate, setCandidate] = useState(basicCandidate);
  const dispatch = useDispatch();

  const fetchCandidate = () => {
    if (setOnRequest) setOnRequest(true);
    showCandidatesRequest(id, {
      dispatch,
      successCallback: response => {
        setCandidate(camelCaseEmptyStringRecursive(response.data));
        if (setOnRequest) setOnRequest(false);
      }
    });
  };

  useEffect(fetchCandidate, [id]);

  const handleUpdate = values => {
    const { resume } = values.candidate;
    const params = snakeCaseKeys(values.candidate);

    updateCandidatesRequest(id, {
      dispatch,
      params: { candidate: { ...params, resume } },
      formData: true,
      successCallback: () => {
        setModalShow(false);
        setMoreData(moreData => !moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Candidato actualizado con éxito' }));
      }
    });
  };

  return (
    <CandidateForm candidate={candidate} selectionProcess={selectionProcess} action="edit" formRequest={handleUpdate} />
  );
};

export default SelectionProcessCandidateEdit;
