import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';

import {
  indexJobTitleRequest,
  deleteJobTitleRequest,
  exportJobTitleRequest,
  importJobTitleRequest,
  importTemplateJobTitleRequest,
  preImportJobTitleRequest,
  massiveDestroyJobTitleRequest,
  activeJobTitlesRequest,
  deactivateJobTitlesRequest
} from '../../requests/jobTitles';
import {
  ActiveDot,
  ComponentDataTable,
  DefaultModal,
  BasicActionBtns,
  ImportActions,
  ImportModal,
  SimpleCenteredModal,
  SettingsMassiveActions
} from '../../components';
import { sendAlert } from '../../actions/utils';

import { downloadFile } from '../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DESCRIPCIÓN',
    selector: 'description',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'job_managements',
    cell: item => item.job_managements.map(job => job.label).join(', '),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="JobTitle" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const JobTitleDataTable = ({ moreData, setMoreData, editJobTitle, showJobTitle }) => {
  const [amount, setAmount] = useState(0);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [query, setQuery] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [jobTitles, setJobTitles] = useState([]);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setJobTitles(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(false);
    setQuery(params);
    indexJobTitleRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSimpleModalShow(false);
    setOnRequest(false);
  };

  const handleSuccessRemove = message => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    handleModalClose();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const removeJobTitle = item => {
    const { id } = item;
    setOnRequest(true);
    deleteJobTitleRequest(id, {
      dispatch,
      successCallback: handleSuccessRemove('Cargo eliminado correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
      }
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_managements':
        return { sort_by_job_managements: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
    setOnRequest(false);
    setModalShow(false);
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    exportJobTitleRequest({
      dispatch,
      params: { ...query, paginate: false },
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        showJobTitle(item);
        break;
      case 'edit':
        editJobTitle(item);
        break;
      case 'destroy':
        setModalTitle('Eliminar Cargo');
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este cargo?');
        setModalAction(() => removeJobTitle);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const massiveDeleteJobTitle = () => {
    const jobTitlesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyJobTitleRequest({
      params: { ids: jobTitlesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Cargos Eliminados correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveActiveJobTitle = () => {
    const jobTitlesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    activeJobTitlesRequest({
      params: { ids: jobTitlesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Cargos Activados correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveDeactivateJobTitle = () => {
    const jobTitlesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deactivateJobTitlesRequest({
      params: { ids: jobTitlesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Cargos Desactivados correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setSimpleModalShow(true);
        break;
      case 'export':
        setModalTitle('Exportar Cargos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar cargos?');
        setModalAction(() => handleExportRequest);
        break;
      case 'massive_destroy':
        setModalTitle('Eliminar cargos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los cargos seleccionados?');
        setModalAction(() => massiveDeleteJobTitle);
        break;
      case 'massive_active':
        setModalTitle('Activar Cargos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas activar los cargos seleccionados?');
        setModalAction(() => massiveActiveJobTitle);
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Cargos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar los cargos seleccionados?');
        setModalAction(() => massiveDeactivateJobTitle);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={jobTitles}
        totalRows={amount}
        selectableRows
        moreData={moreData}
        resourceRequest={handleRequest}
        withMassActions
        massActions={
          <>
            <ImportActions handleClick={handleMassAction} model="JobTitle" />
            <SettingsMassiveActions model="JobTitle" handleClick={handleMassAction} disabled={!selectedCount} />
          </>
        }
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        preName="job_titles"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title="Importar Cargos"
        body={
          <ImportModal
            onDropUploaded={preImportJobTitleRequest}
            handleTemplate={importTemplateJobTitleRequest}
            hideModal={() => setSimpleModalShow(false)}
            onHide={importJobTitleRequest}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default JobTitleDataTable;
