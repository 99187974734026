/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, ApprovementStatus, ApproveRejectActions } from '../../../components';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA TÉRMINO',
    selector: 'end_date',
    cell: item => (item.end_date ? item.end_date : `${item.start_time} - ${item.end_time}`),
    sortable: true,
    grow: '2'
  },
  {
    name: 'TIPO DE PERMISO',
    selector: 'permission_type',
    cell: item => item.translated_permission_type || 'Días Administrativo',
    sortable: true,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'APROBAR',
    cell: item => (
      <ApproveRejectActions
        resource="Permission"
        item={item}
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
      />
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        {ability.can('destroy', 'Permission') && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
            disabled={item.status !== 'pending'}
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '123px'
  }
]);

export default columns;
