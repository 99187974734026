export default {
  active: true,
  address: '',
  addressApt: '',
  addressNumber: '',
  birthdate: '',
  careerId: '',
  careers: [],
  civilState: '',
  collaborator: true,
  communeId: '',
  communeName: '',
  company: {},
  companyId: '',
  contract: {},
  correlativeIdentification: '',
  countryId: '',
  educationLevel: '',
  email: '',
  emergencyContactCountryCode: '+56',
  emergencyContactName: '',
  emergencyContactPhone: '',
  emergencyContactRelation: '',
  employeeCard: {
    fileInfo: {}
  },
  employeeCertificationsAttributes: [],
  familyGroupsAttributes: [],
  fileInfo: {},
  firstLastName: '',
  fullName: '',
  genderId: '',
  id: '',
  languageIds: [],
  languages: [],
  name: '',
  nationalIdExpirationDate: '',
  nationalIdentification: '',
  phone: '',
  phoneCountryCode: '+56',
  postTitle: '',
  regionId: '',
  profileIds: [],
  profiles: [],
  secondLastName: '',
  senceLevel: '',
  userId: '',
  workEmail: '',
  workPhone: '',
  workPhoneCountryCode: '+56'
};
