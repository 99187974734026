import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FormikSelect, UploadFile } from '../../../components';
import { delayMethod } from '../../../services/utils';
import { typeDocumentImed } from '../FormOptions';

const FILE_SIZE = 20000 * 1024;
// const SUPPORTED_FORMATS = [
//   'application/vnd.ms-excel',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
// ];

const UploadFileImedForm = props => {
  const { onHide, setFieldValue, setFieldTouched, handleModalClose, errors, touched, isSubmitting } = props;
  const [disabled, setDisabled] = useState(true);

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <Field name="lme_inf_adjunto[file_type]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  placeholder="Tipo de Documento"
                  options={typeDocumentImed}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={8}>
            <Field name="lme_inf_adjunto[file]">
              {({ field }) => (
                <UploadFile
                  {...field}
                  name="Adjuntar Archivo"
                  onChange={file => {
                    setFieldValue(field.name, file);
                    setDisabled(false);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  formats={['.xls', '.xlsx', '.doc', '.docx', '.pdf', '.jpg']}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" disabled={disabled || isSubmitting} onClick={onHide}>
          Adjuntar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const numLicense = props.numLicense.split('-');
  return {
    lme_inf_adjunto: {
      num_license: numLicense[0],
      dv_license: numLicense[1],
      file_type: '',
      file: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  lme_inf_adjunto: Yup.object().shape({
    file_type: Yup.string().required('El campo es requerido'),
    file: Yup.mixed()
      .required('Debes agregar un archivo')
      // .test('fileType', 'El formato del archivo es inválido', value => value && SUPPORTED_FORMATS.includes(value.type))
      .test('fileSize', 'El archivo es demasiado grande', value => value && value.size <= FILE_SIZE)
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(UploadFileImedForm);
