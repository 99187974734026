import React, { useEffect, useState } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSetTab } from '../../services/hooks';
import { LinkBtn, LongCard, CustomGraph } from '../../components';
import './style.scss';

import CourseDatatable from './CourseDatatable';
import EmployeeCourseDataTable from '../EmployeeCourse/EmployeeCourseDataTable';
import { coursesDashboardRequest } from '../../requests/dashboards';

const CourseIndex = ({ location }) => {
  const [dashboard, setDashboard] = useState({});
  const [key, setKey] = useSetTab('course-datatable', location, 'course-tab');
  const dispatch = useDispatch();

  const graphColors = ['#29B48F', '#FB7474', '#FFC107', '#17A2B8'];
  const graphLegend = { layout: 'vertical', verticalAlign: 'middle', align: 'right' };

  const fetchDashboard = () => {
    coursesDashboardRequest({ dispatch, successCallback: response => setDashboard(response.data) });
  };
  useEffect(fetchDashboard, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Administración de Cursos</h2>
        </Col>
        <Col md={3} className="px-md-0 pr-xl-2 my-3 mr-md-2 d-flex justify-content-end">
          <LinkBtn variant="primary" className="mb-1" to="/courses/new">
            Nuevo Curso
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <Row>
            <Col xs={12} xl={6}>
              <LongCard variant="primary" title={dashboard.finished_courses} label="Cursos realizados" />
            </Col>
            <Col xs={12} xl={6}>
              <LongCard variant="primary" title={dashboard.approved} label="Colaboradores capacitados" />
            </Col>
            <Col xs={12}>
              <LongCard variant="info" title={dashboard.average_investment} label="Inversión promedio" />
            </Col>
          </Row>
        </Col>
        <Col md={6} className="h-custom-graph">
          <CustomGraph
            customizedLabel
            chartType="pie"
            data={[
              { name: 'Trabajadores aprobados', value: dashboard.approved },
              { name: 'Trabajadores reprobados', value: dashboard.rejected },
              { name: 'Trabajadores en proceso', value: dashboard.in_process },
              { name: 'Trabajadores sin comenzar', value: dashboard.not_started }
            ]}
            customColors={graphColors}
            pieLegend={graphLegend}
            activeAnimation={false}
          />
        </Col>
      </Row>
      <Tab.Container id="course-datatables.tabs" activeKey={key} mountOnEnter onSelect={k => setKey(k)}>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="course-datatable">Cursos</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="participants-datatable">Participantes</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="course-datatable">
            <Row>
              <Col md={12}>
                <CourseDatatable />
              </Col>
            </Row>
          </Tab.Pane>
          <Tab.Pane eventKey="participants-datatable">
            <Row>
              <Col md={12}>
                <EmployeeCourseDataTable />
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default CourseIndex;
