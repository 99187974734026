import React from 'react';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import { useSetTab } from '../../../services/hooks';
import { Icon } from '../../../components';
import SyndicateDataTable from './SyndicateDataTable';
import JointCommitteeDataTable from './JointCommitteeDataTable';

const GroupingIndex = ({ location }) => {
  const [key, setKey] = useSetTab('syndicates', location);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Agrupaciones</h2>
        </Col>
      </Row>
      <Tab.Container id="grouping-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="syndicates">
              <Icon icon="people-circle" />
              Sindicatos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="joint-committees">
              <Icon icon="chatbubbles" />
              Comités
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="syndicates">
            <SyndicateDataTable />
          </Tab.Pane>
          <Tab.Pane eventKey="joint-committees">
            <JointCommitteeDataTable />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default GroupingIndex;
