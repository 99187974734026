export default {
  id: '',
  assetTotal: '$ 0',
  discountTotal: '$ 0',
  grossTotal: '$ 0',
  liquidTotal: '$ 0',
  month: '',
  overdraftTotal: '$ 0',
  status: '',
  taxTotal: '$ 0',
  payrolls: [],
  payrollGroups: []
};
