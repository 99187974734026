/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ComponentDataTable, SimpleCenteredModal } from '../../components';
import { debounceIndexCandidatesRequest } from '../../requests/candidates';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import BasicCandidateShowModal from '../SelectionProcess/BasicCandidateModalShow';
import columns from './Columns';

const CandidateDatatable = ({ filteredSelectionProcessId }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setAmount(metadata.amount);
    setCandidates(data);
  };

  const handleParams = params => {
    const customParams = { display_length: 15, display_start: 0, sort_column: 'created_at', sort_direction: 'desc' };
    if (filteredSelectionProcessId) customParams.selection_process_id = filteredSelectionProcessId;
    return { ...customParams, ...params };
  };

  const handleRequest = params => {
    setOnRequest(true);
    debounceIndexCandidatesRequest({
      dispatch,
      params: handleParams(params),
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setModalShow(true);
        setModalTitle(item.full_name);
        setModalBody(<BasicCandidateShowModal item={camelCaseEmptyStringRecursive(item)} />);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'careers':
        return { sort_careers: name };
      case 'selection_processes':
        return { sort_selection_processes: name };
      default:
        return { sort_column: name };
    }
  };

  useEffect(handleRequest, [filteredSelectionProcessId]);

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={candidates}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default CandidateDatatable;
