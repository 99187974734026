import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import BasicTextArea from '../../components/Utils/Input/BasicTextArea';

import { delayMethod } from '../../services/utils';

const WorkflowRequestReject = ({ errors, onHide, cancelAction, touched, isSubmitting }) => (
  <Form className="mx-3">
    <Row>
      <Col>
        <Field name="workflowRequest[observation]">
          {({ field }) => (
            <BasicTextArea
              {...field}
              label="Observaciones"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
    <Row className="d-flex justify-content-end mt-3 mb-2">
      <Col xs={6} sm={4}>
        <Button block variant="outline-info" onClick={() => delayMethod(cancelAction)}>
          Cancelar
        </Button>
      </Col>
      <Col xs={6} sm={4}>
        <Button block type="submit" variant="danger" disabled={isSubmitting} onClick={onHide}>
          Rechazar
        </Button>
      </Col>
    </Row>
  </Form>
);

const setInitialValues = ({ workflowRequest, record }) => {
  return {
    workflowRequest: {
      id: workflowRequest.id || workflowRequest,
      record: { id: record?.id },
      observation: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  workflowRequest: Yup.object().shape({
    observation: Yup.string()
      .required('No puede estar en blanco')
      .min(5, 'Debe tener minimo 5 caracteres')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit
})(WorkflowRequestReject);
