const basicContract = {
  additionalHolidays: '',
  administrativeDays: '',
  bankAccount: '',
  bankAccountType: '',
  bankId: '',
  branchOfficeId: '',
  checkAssistance: false,
  contractType: '',
  costCenterId: '',
  disability: false,
  disabilityNationalIdentification: '',
  documentTemplateId: '',
  employerSalary: false,
  employee: {},
  endDate: '',
  friday: false,
  gratificationType: '',
  gratificationPeriod: '',
  gratificationCondition: '',
  jacketSize: '',
  jobManagementId: '',
  jobTitleId: '',
  law19010: false,
  law19966: false,
  markingType: '',
  monday: false,
  outSourced: false,
  outSourceAddress: '',
  outSourceAddressFlat: '',
  outSourceAddressNumber: '',
  outSourceAddressOffice: '',
  outSourceBusinessName: '',
  outSourceCommuneId: '',
  outSourceEstablishmentName: '',
  outSourceExceptionalSystem: false,
  outSourceExceptionalSystemNumber: '',
  outSourceNationalIdentification: '',
  outSourceRegionId: '',
  pantsSize: '',
  parentId: '',
  payFirstLicenseDays: false,
  paymentSchedule: '',
  paymentType: '',
  presenceCheckAssistance: false,
  progressiveFileInfo: {},
  progressiveVacationsCertificate: {},
  recognizedMonths: '',
  recognizedMonthsFrom: '',
  salary: '',
  salaryWithLicense: false,
  saturday: false,
  senceFranchise: '',
  shiftIds: [],
  shifts: [],
  shirtSize: '',
  shoesSize: '',
  startDate: '',
  sunday: false,
  syndicateId: '',
  tShirtSize: '',
  thursday: false,
  tuesday: false,
  wednesday: false,
  weeklyHours: '',
  workEndTime: '',
  workSchedule: '',
  workStartTime: '',
  contractBalancesAttributes: [],
  employeePrevisionAttributes: []
};

export default basicContract;
