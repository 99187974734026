import ApiService from '../services/apiService';

export const changePasswordRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', '/change_password', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const stopImpersonateAccountAdminRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('post', `/users/stop_impersonate`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const sendSignatureKeyUserRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/users/send_confirmation_code`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const changePinRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', '/change_signature_pin', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });


export const validateToken = (token, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/validate_token/${token}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });