import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Form as BSForm } from 'react-bootstrap';
import { UploadFile, Icon, ButtonTooltip, BasicTooltip, BasicCard } from '../../components';
import {
  updateRiskPreventionDocumentRequest,
  downloadRiskPreventionDocumentRequest
} from '../../requests/riskPreventionDocuments';
import { sendAlert } from '../../actions/utils';
import { downloadFile } from '../../services/utils';

const RiskPreventionDocumentForm = props => {
  const {
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    index,
    id,
    title,
    values,
    documentInfo,
    documentType,
    documentTranslated,
    setOnRequest
  } = props;

  const dispatch = useDispatch();
  const noDocument = Object.keys(documentInfo).length === 0;
  const { currentEmployee } = useSelector(state => state.auth);

  const handleUpdateRequest = checked => {
    updateRiskPreventionDocumentRequest(id, {
      dispatch,
      params: {
        risk_prevention: {
          is_public: !values.riskPrevention.isPublic
        }
      },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documento actualizado con éxito' }));
        setFieldValue('riskPrevention[isPublic]', checked);
      },
      callback: () => setOnRequest(false)
    });
  };

  const downloadRiskPreventionDocument = () => {
    setOnRequest(true);
    downloadRiskPreventionDocumentRequest(id, {
      dispatch,
      params: {
        template_pdf: {
          document_type: documentType,
          employee_ids: [currentEmployee]
        }
      },
      formData: true,
      successCallback: response => {
        downloadFile(response);
        dispatch(sendAlert({ kind: 'success', message: 'Documento descargado exitosamente' }));
      },
      callback: () => setOnRequest(false)
    });
  };

  const bodyButtonSwitch = (
    <Row>
      <Col xs={3} md={2}>
        <BSForm.Group>
          <BSForm.Check
            id={`checkbox-${index}`}
            checked={values.riskPrevention.isPublic}
            className="as-checkbox"
            label=""
            type="switch"
            onClick={e => setFieldValue('riskPrevention[isPublic]', e.target.checked)}
            onChange={e => !noDocument && handleUpdateRequest(e.target.checked)}
          />
        </BSForm.Group>
      </Col>
      <Col xs={10} sm={8} xl={7}>
        <p className="futura font-weight-bold text-uppercase ml-md-3 mt-1 title-switch">Documento Público</p>
      </Col>
      <Col className="d-flex justify-content-start">
        <BSForm.Label className="mt-1">
          <BasicTooltip text={`Publicar ${documentTranslated} a los trabajadores`}>
            <Icon icon="help-circle" width={22} />
          </BasicTooltip>
        </BSForm.Label>
      </Col>
    </Row>
  );

  return (
    <BasicCard
      size="100%"
      classNameCard="z-index-auto"
      bodyMarginBottom="-10px"
      colorCard="white"
      divBody
      text={
        <Form>
          <Row>
            <Col xs={10} xl={11}>
              <h3 className="mb-4 futura text-uppercase font-size-title">{title}</h3>
            </Col>
            <Col xs={2} xl={1}>
              <ButtonTooltip
                onClick={() => downloadRiskPreventionDocument()}
                variant="circle-primary"
                toolbarVariant="ml-auto"
                className="btn-circle"
                size="sm"
                text="Descargar plantilla"
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <Row>
                <Col md={12}>
                  <Field name="riskPrevention[document]">
                    {({ field }) => (
                      <UploadFile
                        {...field}
                        name={values.riskPrevention?.name || 'Adjuntar Archivo'}
                        onChange={file => {
                          setFieldValue(field.name, file);
                          setFieldValue('riskPrevention[name]', file.name.split('.')[0]);
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        formats={['.pdf', '.doc', '.docx']}
                        tooltipText="Formatos permitidos ( DOC, DOCX PDF)"
                      />
                    )}
                  </Field>
                </Col>
                <Col>{bodyButtonSwitch}</Col>
              </Row>
            </Col>
            <Col className="d-flex flex-column">
              <Button
                variant="outline-info"
                disabled={noDocument}
                className="mb-2"
                onClick={() => window.open(documentInfo?.fileUrl, '_blank', 'noopener', 'noreferrer')}
              >
                Ver Archivo
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting || !values.riskPrevention.document?.size}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      }
    />
  );
};

const setInitialValues = props => {
  return {
    id: props.id,
    riskPrevention: {
      document: props.document,
      documentType: props.documentType,
      isPublic: props.isPublic,
      name: props.name
    }
  };
};
const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const validationSchema = Yup.object().shape({
  riskPrevention: Yup.object().shape({
    document: Yup.mixed()
      .required('Debes agregar un archivo')
      .test(
        'FILE_SIZE',
        'El archivo cargado excede el tamaño maximo permitido (10mb).',
        value => !value?.size || (value && value?.size <= 10485760)
      )
      .test(
        'FILE_FORMAT',
        'El archivo cargado tiene un formato no compatible.',
        value => !value?.type || (value && SUPPORTED_FORMATS.includes(value?.type))
      )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(RiskPreventionDocumentForm);
