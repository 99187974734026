import ApiService from '../services/apiService';

const indexCompensationFundsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/compensation_funds', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export default indexCompensationFundsRequest;
