import React, { useState, useEffect } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { AbilityContext } from '../../../config/abilityContext';
import Icon from '../../Icons';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { profileTeamDashboardRequest } from '../../../requests/dashboards';
import VacationDataTable from '../../../screens/Shared/Vacation/VacationDataTable';
import PermissionDataTable from '../../../screens/Shared/Permission/PermissionDataTable';
import Columns from '../../../screens/Shared/Permission/approveColumns';
import LoanDataTable from '../../../screens/Shared/Loan/LoanDataTable';
import LoanColumns from '../../../screens/Profile/Loan/Columns';
import AdvancePaymentDataTable from '../../../screens/Shared/AdvancePayment/AdvancePaymentDataTable';
import AdvancePaymentColumns from '../../../screens/Shared/AdvancePayment/approveColumns';
import ShiftChangeDataTable from '../../../screens/Shared/ShiftChange/ShiftChangeDataTable';
import shiftChangeColumns from '../../../screens/Profile/ShiftChange/Columns';
import VacationColumns from '../../../screens/Shared/Vacation/approveColumns';

const MyTeamRequests = ({ currentEmployee }) => {
  const ability = useAbility(AbilityContext);
  const { advanceSettings } = useSelector(state => state.utils);
  const dispatch = useDispatch();

  const requestsAbility = [
    ability.can('index', 'AdvancePayment'),
    ability.can('index', 'Permission'),
    ability.can('index', 'Vacation'),
    ability.can('index', 'Loan'),
    ability.can('index', 'ShiftChangeRequest')
  ];

  const [cards, setCards] = useState({});
  const [key, setKey] = useState('');
  const [moreData, setMoreData] = useState(false);

  const vacationsRole = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const loanRole = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');

  const permissionsRole = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave', 'administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const shiftChangeRole = advanceSettings
    .filter(asItem => asItem.code === 'shift_change')
    .some(x => x.textValue === 'true');

  const advancePaymentsRole = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue === 'true');

  const dataTableHead = () => {
    switch (key) {
      case 'vacations-datatable':
        return 'Solicitudes de Vacaciones Pendientes';
      case 'permissions-datatable':
        return 'Solicitudes de Permisos Pendientes';
      case 'loans-datatable':
        return 'Solicitudes de Préstamos Pendientes';
      case 'advance-payments-datatable':
        return 'Solicitudes de Anticipos Pendientes';
      case 'shift-change-datatable':
        return 'Solicitudes de Cambios de Turno Pendientes';
      case 'administrative-datatable':
        return 'Días Administrativos';
      default:
        return '';
    }
  };

  const setDatatable = () => {
    switch (key) {
      case 'vacations-datatable':
        return (
          <Col>
            <VacationDataTable
              moreData={moreData}
              setMoreData={setMoreData}
              columns={VacationColumns}
              customParams={{
                status: 'pending',
                filter_vacation_exception: 'administrative_days',
                pending_review: currentEmployee
              }}
              preName="pending-vacations"
            />
          </Col>
        );
      case 'administrative-datatable':
        return (
          <Col>
            <VacationDataTable
              columns={VacationColumns}
              moreData={moreData}
              setMoreData={setMoreData}
              preName="administrative_days"
              customParams={{
                status: 'pending',
                filter_vacation_type: 'administrative_days',
                pending_review: currentEmployee
              }}
            />
          </Col>
        );
      case 'permissions-datatable':
        return (
          <PermissionDataTable
            columns={Columns}
            moreData={moreData}
            setMoreData={setMoreData}
            customParams={{
              sort_permissions: 'start_date desc',
              status: 'pending',
              pending_review: currentEmployee
            }}
            preName="pending-permissions"
          />
        );
      case 'loans-datatable':
        return (
          <LoanDataTable
            columns={LoanColumns}
            customParams={{
              status: 'pending',
              pending_review: currentEmployee
            }}
            moreData={moreData}
            setMoreData={setMoreData}
            withImport
          />
        );
      case 'advance-payments-datatable':
        return (
          <AdvancePaymentDataTable
            columns={AdvancePaymentColumns}
            customParams={{
              status: 'pending',
              pending_review: currentEmployee
            }}
            moreData={moreData}
            setMoreData={setMoreData}
            withImport
          />
        );
      case 'shift-change-datatable':
        return (
          <ShiftChangeDataTable
            columns={shiftChangeColumns}
            moreData={moreData}
            setMoreData={setMoreData}
            customParams={{
              review: currentEmployee,
              status: 'pending',
              sort: 'created_at'
            }}
          />
        );
      default:
        return '';
    }
  };

  const handleIndexRequest = () => {
    if (currentEmployee) {
      profileTeamDashboardRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data).allRequestsCount)
      });
    }
  };
  useEffect(handleIndexRequest, [currentEmployee, moreData]);

  return (
    <>
      <Row className="mb-1">
        {vacationsRole && requestsAbility[2] && (
          <Col sm={12} xs={12} md={2} className=" mt-1">
            {cards.cardBoss && cards.cardBoss.vacationsCount > 0 && (
              <Badge variant="danger" className="offset-md-9 badge-circle">
                {cards.cardBoss.vacationsCount}
              </Badge>
            )}
            <Button
              variant="blue-1"
              block
              onClick={() => {
                setKey('vacations-datatable');
                setMoreData(!moreData);
              }}
            >
              <Icon className="icon-15" icon="sunny" /> Vacaciones
            </Button>
          </Col>
        )}

        {permissionsRole && requestsAbility[1] && (
          <Col sm={12} xs={12} md={2} className=" mt-1">
            {cards.cardBoss && cards.cardBoss.administrativeCount > 0 && (
              <Badge variant="danger" className="offset-md-9 badge-circle">
                {cards.cardBoss.administrativeCount}
              </Badge>
            )}
            <Button
              variant="blue-2"
              block
              onClick={() => {
                setKey('administrative-datatable');
                setMoreData(!moreData);
              }}
            >
              <Icon className="icon-15" icon="calendar" /> Días Admin.
            </Button>
          </Col>
        )}

        {permissionsRole && requestsAbility[1] && (
          <Col sm={12} xs={12} md={2} className=" mt-1">
            {cards.cardBoss && cards.cardBoss.permissionsCount > 0 && (
              <Badge variant="danger" className="offset-md-9 badge-circle">
                {cards.cardBoss.permissionsCount}
              </Badge>
            )}
            <Button variant="green" block onClick={() => setKey('permissions-datatable')}>
              <Icon className="icon-15" icon="clock2" /> Permisos
            </Button>
          </Col>
        )}

        {loanRole && requestsAbility[3] && (
          <Col sm={12} xs={12} md={2} className=" mt-1">
            {cards.cardBoss && cards.cardBoss.loansCount > 0 && (
              <Badge variant="danger" className="offset-md-9 badge-circle">
                {cards.cardBoss.loansCount}
              </Badge>
            )}
            <Button variant="orange" block onClick={() => setKey('loans-datatable')}>
              <Icon className="icon-15" icon="wallet" /> Préstamos
            </Button>
          </Col>
        )}

        {advancePaymentsRole && requestsAbility[0] && (
          <Col sm={12} xs={12} md={2} className=" mt-1">
            {cards.cardBoss && cards.cardBoss.advancePaymentsCount > 0 && (
              <Badge variant="danger" className="offset-md-9 badge-circle">
                {cards.cardBoss.advancePaymentsCount}
              </Badge>
            )}
            <Button variant="red" block onClick={() => setKey('advance-payments-datatable')}>
              <Icon className="icon-15" icon="calendar" /> Anticipos
            </Button>
          </Col>
        )}

        {shiftChangeRole && requestsAbility[4] && (
          <Col sm={12} xs={12} md={2} className=" mt-1">
            {cards.shiftChangeCount > 0 && (
              <Badge variant="danger" className="offset-md-9 badge-circle">
                {cards.shiftChangeCount}
              </Badge>
            )}
            <Button variant="gray" block onClick={() => setKey('shift-change-datatable')}>
              <Icon className="icon-15" icon="pencil" /> Turnos
            </Button>
          </Col>
        )}
      </Row>
      {key !== '' && (
        <Row>
          <Col>
            <h4 className="mb-0">{dataTableHead()}</h4>
            {setDatatable(key)}
          </Col>
        </Row>
      )}
    </>
  );
};

export default MyTeamRequests;
