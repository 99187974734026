import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { markingCorrectionAttendanceReport } from '../../../requests/legalreports';
import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, SimpleCenteredModal } from '../../../components';

import AttendanceReplaceForm from './AttendanceReplaceForm';
import { AbilityContext } from '../../../config/abilityContext';
import Filters from './Filters';
import AttendanceShow from './AttendanceShow';
import { createAttendanceChangeRequest } from '../../../requests/AttendanceChange';

const MarkingCorrectionDatatable = ({ moreData, setMoreData, columns }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [size, setSize] = useState('lg');

  const [amount, setAmount] = useState(0);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setSimpleModalShow(false);
  };

  const handleSuccessRequest = status => {
    let message = '';

    switch (status) {
      case 'updated':
        setMoreData(!moreData);
        message = 'Solicitud de Correción de Marcación Realizada de Manera Correcta.';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleRequest = (params, format, responseType = 'json') => {
    if (Object.keys(params).length > 0) {
      setOnRequest(true);
      const empParams = { ...params };
      delete empParams.date_to;
      delete empParams.date_from;
      markingCorrectionAttendanceReport({
        dispatch,
        params: { ...filters, ...empParams },
        format,
        responseType,
        successCallback: response => {
          const { data } = response;
          setAmount(data.metadata.amount);
          setAttendances(data.data);
          setOnRequest(false);
        },
        callback: () => setOnRequest(false)
      });
    }
  };
  const handleFilters = params => {
    setFilters(params);
    setSearchFirstStart(true);
    handleRequest(params);
  };

  const handleUpdateRequest = attendance => {
    setOnRequest(true);
    setMoreData(!moreData);
    createAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(attendance),
      successCallback: () => handleSuccessRequest('updated'),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateModal = attendance => {
    const parsedAttendance = camelCaseRecursive(attendance);
    const modalTitle =
      !parsedAttendance.arrivalMark && !parsedAttendance.departureMark
        ? 'AGREGAR MARCACION'
        : 'CORRECCION DE MARCACION';
    const correctionType = !parsedAttendance.arrivalMark && !parsedAttendance.departureMark ? 'add' : 'replacing';
    setSimpleModalBody(
      <AttendanceReplaceForm
        attendance={parsedAttendance}
        correctionType={correctionType}
        formRequest={handleUpdateRequest}
        handleModalClose={handleModalClose}
      />
    );

    setSimpleModalTitle(modalTitle);
    setSimpleModalShow(true);
    setSize('lg');
  };

  const handleShowModal = item => {
    const parsedAttendance = camelCaseRecursive(item);
    setSimpleModalTitle(`Asistencia - ${item.name}`);
    setSimpleModalShow(true);
    setSimpleModalBody(<AttendanceShow attendance={parsedAttendance} />);
    setSize('md');
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        handleShowModal(item);
        break;
      case 'edit':
        if (ability.can('create', 'AttendanceChangeRequest')) handleUpdateModal(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Filters formRequest={handleFilters} />
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability.can('create', 'AttendanceChangeRequest'))}
        data={attendances}
        totalRows={amount}
        resourceRequest={handleRequest}
        searchFirstStart={searchFirstStart}
        onRowClicked={null}
        progressPending={onRequest}
        preName="attendance"
        withMassActions
        moreData={moreData}
        massActions={<></>}
      />

      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default MarkingCorrectionDatatable;
