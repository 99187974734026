import React, { useState, useEffect } from 'react';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import BenefitForm from './BenefitForm';
import { sendAlert } from '../../actions/utils';
import { showBenefitsRequest, updateBenefitsRequest, deleteBenefitsRequest } from '../../requests/benefits';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import defaultBenefit from './benefit';

const BenefitEdit = ({ id, hideModal, moreData, setMoreData }) => {
  const dispatch = useDispatch();
  const [benefit, setBenefit] = useState(defaultBenefit);
  const [onRequest, setOnRequest] = useState(true);
  const [isDelete, setIsDelete] = useState(false);

  const handleUpdateRequest = (benefitValues, setSubmitting) => {
    const { benefitsImage } = benefitValues.benefit;
    const myParams = snakeCaseKeys(benefitValues);
    myParams.benefit.image = benefitsImage;

    updateBenefitsRequest(id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        hideModal();
        dispatch(sendAlert({ kind: 'success', message: 'Beneficio actualizado con éxito' }));
      },
      failureCallback: error => {
        setSubmitting(false);
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
      },
      callback: () => setMoreData(!moreData)
    });
  };

  const handleShowRequest = () => {
    setOnRequest(true);
    showBenefitsRequest(id, {
      dispatch,
      successCallback: res => {
        setBenefit(camelCaseEmptyStringRecursive(res.data));
        setOnRequest(false);
      }
    });
  };

  const handleDelete = () => setIsDelete(true);

  const handleDeleteRequest = () => {
    deleteBenefitsRequest(id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Beneficio eliminado con éxito' }));
      },
      failureCallback: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
      },
      callback: () => {
        hideModal();
        setIsDelete(false);
        setMoreData(!moreData);
      }
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && <Spinner className="spinner-modal fix-spinner" animation="border" variant="primary" />}
      <div className={`${onRequest && 'bg-opacity'}`}>
        <BenefitForm
          benefit={benefit}
          action="edit"
          formRequest={handleUpdateRequest}
          hideModal={hideModal}
          handleDelete={handleDelete}
          handleDeleteRequest={handleDeleteRequest}
          isDelete={isDelete}
        />
      </div>
    </>
  );
};

export default BenefitEdit;
