import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';

import { FormikInput } from '../../components/Utils/Input';
import { UploadImage } from '../../components/Utils/Upload';

const EnrollmentForm = props => {
  const { onHide, submitVariant, errors, touched, setFieldValue, enrollment } = props;
  const btnMessage = 'Autenticarme';

  return (
    <Form>
      <Field name="enrollment[serialNumber]">
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            label="Ingresa el N° de serie de tu cédula de identidad"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      <Row>
        <Col xs={6}>
          <Field name="enrollment[idFront]">
            {({ field }) => (
              <UploadImage
                {...field}
                name="Agregar documento frontal"
                customClass="d-none d-sm-flex"
                inputFileId="frontFile01"
                imageUrl={getIn(enrollment.id_front_info, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                error={getIn(errors, field.name)}
                onTop
                touched={getIn(touched, field.name)}
                helpText="Subir foto frontal de la cédula de identidad (Máximo 10mb)."
                fileAccept=".png, .jpg, .gif, .jpeg"
              />
            )}
          </Field>
        </Col>
        <Col xs={6}>
          <Field name="enrollment[idBack]">
            {({ field }) => (
              <UploadImage
                {...field}
                onTop
                name="Agregar documento posterior"
                customClass="d-none d-sm-flex"
                inputFileId="backFile01"
                imageUrl={getIn(enrollment.id_front_info, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                helpText="Subir foto posterior de la cédula de identidad (Máximo 10mb)."
                fileAccept=".png, .jpg, .gif, .jpeg"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={6}>
          <Field name="enrollment[photo]">
            {({ field }) => (
              <UploadImage
                {...field}
                name="Agregar selfie"
                customClass="d-none d-sm-flex"
                inputFileId="photoFile01"
                circleImage
                onTop
                imageUrl={getIn(enrollment.id_front_info, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                helpText="Formato sugerido 400x400px de máximo 10mb."
                fileAccept=".png, .jpg, .gif, .jpeg"
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="justify-content-end my-3 mb-4">
        <Col md={4}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { enrollment, token } = props;
  return {
    enrollment,
    token
  };
};

const validationSchema = Yup.object().shape({
  enrollment: Yup.object().shape({
    serialNumber: Yup.string().required('Debes ingresar el n° de serie de tu cédula de identidad.'),
    idBack: Yup.mixed().required('Debes adjuntar una imagen'),
    idFront: Yup.mixed().required('Debes adjuntar una imagen'),
    photo: Yup.mixed().required('Debes adjuntar una imagen')
  }),
  token: Yup.mixed()
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(EnrollmentForm);
