export default {
  address: '',
  addressApt: '',
  addressNumber: '',
  birthdate: '',
  candidateType: '',
  careerIds: [],
  careers: [],
  civilState: '',
  communeId: '',
  countryId: '',
  countryName: '',
  educationLevel: '',
  email: '',
  firstLastName: '',
  genderId: '',
  identificationType: '',
  languageIds: [],
  name: '',
  nationalIdentification: '',
  observation: '',
  otherCareer: '',
  phone: '',
  phoneCountryCode: '+56',
  regionId: '',
  resume: {},
  secondLastName: ''
};
