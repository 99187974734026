const processTypes = [
  { label: 'Interno', value: 'internal' },
  { label: 'Externo', value: 'external' },
  { label: 'Mixto', value: 'mixed' }
];

const statuses = [
  { label: 'Generado', value: 'generated' },
  { label: 'Activo', value: 'active' },
  { label: 'Suspendido', value: 'suspended' },
  { label: 'Desierto', value: 'deserted' },
  { label: 'Cerrado', value: 'closed' }
];

const workSchedules = [
  { label: 'Media jornada', value: 'part_time' },
  { label: 'Jornada completa', value: 'full_time' },
  { label: 'Freelance', value: 'freelance' },
  { label: 'Práctica', value: 'practice' }
];

const expertiseLevels = [
  { label: 'Junior', value: 'junior' },
  { label: 'Semi Senior', value: 'semi_senior' },
  { label: 'Senior', value: 'senior' },
  { label: 'Experto', value: 'expert' }
];

const remotes = [
  { label: 'Sin opción remota', value: 'no_remote_option' },
  { label: 'Remoto temporalmente', value: 'temporarily_remote' },
  { label: 'Remoto sólo localmente', value: 'remote_only_locally' },
  { label: 'Totalmente remoto', value: 'totally_remote' }
];

export { processTypes, statuses, workSchedules, expertiseLevels, remotes };
