import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  debounceIndexPerformanceEvaluationRequest,
  destroyPerformanceEvaluationRequest
} from '../../requests/performanceEvaluations';
import { ComponentDataTable, DefaultModal } from '../../components';
import { sendAlert } from '../../actions/utils';

const PerformanceEvaluationDataTable = ({ customParams, columns, path = '' }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [performanceEvaluations, setPerformanceEvaluations] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  // const ability = useAbility(AbilityContext);

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setPerformanceEvaluations(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    debounceIndexPerformanceEvaluationRequest({
      dispatch,
      params: { ...customParams, ...params },
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setOnRequest(false);
  };

  const handleDestroy = item => {
    setOnRequest(true);
    destroyPerformanceEvaluationRequest(item.id, {
      dispatch,
      successCallback: () => {
        setOnRequest(false);
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Evaluación eliminada con éxito' }));
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`${path}/performance_evaluations/${item.id}`, customParams);
        break;
      case 'edit':
        history.push(`${path}/performance_evaluations/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar a ${item.name}?`);
        setModalTitle('Eliminar Evaluación de Desempeño');
        setModalAction(() => handleDestroy);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'evaluators':
        return { sort_evaluators: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={performanceEvaluations}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        onRowClicked={item => handleActions(item, 'show')}
        resourceRequest={handleRequest}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default PerformanceEvaluationDataTable;
