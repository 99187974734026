import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { parse } from 'date-fns';
import { useDispatch } from 'react-redux';
import {
  BasicTextArea,
  FormikCheckBox,
  FormikDatePicker,
  FormikSelect,
  FormikTimePicker,
  InputSelect,
  FormikInput,
  FormikRangePicker
} from '../../../components';
import '../../../services/yupCustomMethods';
import { indexSelectEmployeesRequest } from '../../../requests/employees';
import { workdaysBetweenHolidayRequest } from '../../../requests/holidays';

const PermissionForm = props => {
  const [permissionStartTime, setPermissionStartTime] = useState('');
  const [permissionEndTime, setPermissionEndTime] = useState('');
  const [vacationDays, setVacationDays] = useState(0);
  const dispatch = useDispatch();

  const {
    action,
    errors,
    filterPermissionTypes,
    from,
    isSubmitting,
    permission,
    rrhh,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = props;

  const permTypesFiltered = filterPermissionTypes(from);
  const { permissionType } = permission;
  const { startDate, endDate, middayStart, middayEnd } = values.permission;
  const btnMessage = action === 'new' ? 'Solicitar' : 'Guardar';
  const today = new Date();
  today.setDate(today.getDate() - 1);

  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, filter_name: inputValue, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  const administrativeType = values.permission.permissionType === 'administrative_days';

  const calculateDays = () => {
    if (startDate && endDate) {
      workdaysBetweenHolidayRequest({
        dispatch,
        params: {
          start_date: startDate,
          end_date: endDate,
          midday_start: middayStart,
          midday_end: middayEnd
        },
        successCallback: response => {
          setVacationDays(response.data);
          setFieldValue('days', response.data);
          setFieldTouched('days');
        }
      });
    }
  };

  useEffect(calculateDays, [endDate, middayEnd, middayStart, startDate]);

  return (
    <Form>
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row>
        {rrhh && (
          <Col md={12}>
            <Field name="permission[employeeId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Trabajador"
                  placeholder="Seleccionar Trabajador"
                  values={values}
                  model={[permission, 'employee']}
                  request={fetchEmployees}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {permTypesFiltered.length > 0 && (
          <Col md={12}>
            <Field name="permission[permissionType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Permiso"
                  placeholder="Seleccionar Tipo de Permiso"
                  options={permTypesFiltered}
                  defaultValue={permissionType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {from === 'days' ? (
          <>
            <Col md={10}>
              <Field name="permission[rangeDate]">
                {({ field }) => (
                  <FormikRangePicker
                    name={field.name}
                    abbr
                    minCurrentDate={!rrhh}
                    startDateName="permission[startDate]"
                    endDateName="permission[endDate]"
                    startDate={startDate}
                    endDate={endDate}
                    showClearDates
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={2}>
              <Field name="days">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    disabled
                    inputType="number"
                    label="Total Días"
                    value={vacationDays}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="permission[middayStart]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Medio día al Inicio" />}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="permission[middayEnd]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Medio día al Final" />}
              </Field>
            </Col>
          </>
        ) : (
          <>
            <Col md={12}>
              <Field name="permission[startDate]">
                {({ field }) => (
                  <FormikDatePicker
                    name={field.name}
                    value={field.value}
                    abbr
                    isOutsideRange={day => (!rrhh ? day.isBefore(today) : false)}
                    label="Fecha de Inicio"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="permission[startTime]">
                {({ field }) => (
                  <FormikTimePicker
                    name={field.name}
                    value={field.value}
                    abbr
                    label="Hora de Inicio"
                    selected={permissionStartTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeFormat="h:mm aa"
                    placeholder="9:00 AM"
                    onChange={date => {
                      setPermissionStartTime(date);
                      setFieldValue(
                        field.name,
                        date.toLocaleTimeString('en-ES', {
                          hour: 'numeric',
                          minute: '2-digit'
                        })
                      );
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="permission[endTime]">
                {({ field }) => (
                  <FormikTimePicker
                    name={field.name}
                    value={field.value}
                    abbr
                    label="Hora de Término"
                    selected={permissionEndTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeFormat="h:mm aa"
                    placeholder="6:00 PM"
                    onChange={date => {
                      setPermissionEndTime(date);
                      setFieldValue(
                        field.name,
                        date.toLocaleTimeString('en-ES', {
                          hour: 'numeric',
                          minute: '2-digit'
                        })
                      );
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {!administrativeType && (
          <Col md={12}>
            <Field name="permission[reason]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Motivo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>

      <Row className="d-flex justify-content-end mt-3 mb-1">
        <Col md={4}>
          <Button block type="submit" variant="primary" disabled={isSubmitting}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { permission, from, rrhh, employeeId: currentEmployee, filterPermissionTypes } = props;
  const {
    employeeId,
    endDate,
    endTime,
    middayEnd,
    middayStart,
    permissionType,
    reason,
    startDate,
    startTime
  } = permission;

  const permTypesFiltered = filterPermissionTypes(from);

  return {
    permission: {
      employeeId: rrhh ? employeeId : currentEmployee,
      endDate,
      endTime,
      from,
      middayEnd,
      middayStart,
      permissionType,
      permTypesFiltered,
      reason,
      startDate,
      startTime,
      directApprove: rrhh,
      rangeDate: [startDate, endDate]
    }
  };
};

const validationSchema = Yup.object().shape({
  days: Yup.number().positive('Debe ser mayor a 0'),
  permission: Yup.object().shape({
    employeeId: Yup.string().required('Debes seleccionar un trabajador'),
    endDate: Yup.mixed().when('from', {
      is: val => val === 'days',
      then: Yup.date().formatdate(),
      otherwise: Yup.string().nullable()
    }),
    endTime: Yup.string().when('from', {
      is: val => val === 'hours',
      then: Yup.string()
        .required('Debes seleccionar una hora de término')
        .when('startTime', (startTime, schema, element) => {
          const start = parse(startTime, 'h:mm aa', new Date());
          const minStart = start.setMinutes(start.getMinutes() + 30);
          const end = parse(element.parent.endTime, 'h:mm aa', new Date());
          const validation = startTime && minStart > end;
          return (validation && schema.min(minStart, 'Debe ser mayor que la hora de inicio')) || schema;
        }),
      otherwise: Yup.string().nullable()
    }),
    middayEnd: Yup.boolean(),
    middayStart: Yup.boolean(),
    permissionType: Yup.string().when('permTypesFiltered', {
      is: val => val.length > 0,
      then: Yup.string().required('Debes seleccionar un tipo de permiso'),
      otherwise: Yup.string().nullable()
    }),
    rangeDate: Yup.mixed().when('from', {
      is: val => val === 'days',
      then: Yup.array().rangedate(true),
      otherwise: Yup.array().nullable()
    }),
    reason: Yup.string().nullable(),
    startDate: Yup.date()
      .formatdate()
      .required('Debes seleccionar una fecha de inicio'),
    startTime: Yup.string().when('from', {
      is: val => val === 'hours',
      then: Yup.string().required('Debes seleccionar una hora de inicio'),
      otherwise: Yup.string().nullable()
    })
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PermissionForm);
