import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Row, Col, Image, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';

import { validateToken, requestCurrentCompany } from '../../../actions/auth';
import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import { debounceCompaniesRequest, selectCompanyRequest } from '../../../requests/auth';
import { delayMethod } from '../../../services/utils';
import Icon from '../../Icons/Icon';
import LinkBtn from '../Button/LinkBtn';

const CompaniesModal = ({ fromSidebar, handleModalClose }) => {
  const ability = useAbility(AbilityContext);
  const { user, currentCompany } = useSelector(state => state.auth);
  const [companies, setCompanies] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const dispatch = useDispatch();

  const handleActiveModalClose = () => {
    if (handleModalClose) delayMethod(handleModalClose());
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessGetCompanies = response => {
    const { data } = response.data;
    setCompanies(camelCaseRecursive(data));
    setOnRequest(false);
  };

  const getCompanies = params => {
    setOnRequest(true);
    debounceCompaniesRequest({
      dispatch,
      params,
      successCallback: handleSuccessGetCompanies,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessSelectCompany = company => {
    handleActiveModalClose();
    if (!company && user.role === 'admin') {
      window.location.replace('/admin/companies');
    } else if (user.role === 'dt') {
      window.location.replace('/dt/welcome');
    } else {
      window.location.replace('/profile/dashboard');
    }
  };

  const selectCompany = company => {
    selectCompanyRequest({
      dispatch,
      params: { current_company_id: company ? company.id : '' },
      successCallback: () => handleSuccessSelectCompany(company),
      failureCallback: handleFailureRequest
    });
  };

  const userData = () => {
    if (user.id === 0) dispatch(validateToken());
    if (currentCompany.id !== '') {
      dispatch(requestCurrentCompany({ id: currentCompany.id }));
    }
    getCompanies();
  };

  useEffect(userData, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Buscar"
          name="companyName"
          value={companyName}
          onChange={e => {
            setCompanyName(e.target.value);
            getCompanies({ select_filtered: e.target.value });
          }}
        />
      </InputGroup>
      <ListGroup variant="flush" className="mb-3">
        {companies.map(company => (
          <ListGroup.Item
            key={company.id}
            className={company.id.toString() === currentCompany.id ? 'active' : ''}
            onClick={() => selectCompany(company)}
          >
            <Row>
              <Col xs={2} md={1} className="select-company p-0">
                {company.businessLogo?.fileUrl ? (
                  <Image className="round-10" fluid width="38px" src={company.businessLogo.fileUrl} />
                ) : (
                  <Icon icon="camera" width="38px" />
                )}
              </Col>
              <Col className="select-company d-flex align-items-center pl-2 pl-md-3">
                <div className="d-flex flex-column">
                  <span className="text-muted">{company.nationalIdentification}</span>
                  <span className="text-uppercase">{company.businessName}</span>
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Row className="d-flex justify-content-end mt-3">
        {user.role === 'admin' && (
          <Col md={4}>
            <LinkBtn block variant="primary" to="/admin/companies" onClick={() => selectCompany()}>
              Soporte
            </LinkBtn>
          </Col>
        )}
        {fromSidebar && currentCompany?.id && ability.can('create', 'Company') && (
          <Col md={4}>
            <LinkBtn block variant="success" to="/companies/new" onClick={handleActiveModalClose}>
              Nueva Empresa
            </LinkBtn>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CompaniesModal;
