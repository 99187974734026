export default [
  { label: 'Art. 159 Nº 1 - Mutuo acuerdo de las partes', value: 'mutual_agreement' },
  { label: 'Art. 159 Nº 2 - Renuncia del trabajador', value: 'voluntary_resignation' },
  { label: 'Art. 159 Nº 3 - Muerte del trabajador', value: 'employee_death' },
  { label: 'Art. 159 Nº 4 - Vencimiento del plazo convenido en el contrato', value: 'agreed_term_ended' },
  { label: 'Art. 159 Nº 5 - Conclusión del trabajo o servicio que dio origen al contrato', value: 'work_ended' },
  { label: 'Art. 159 Nº 6 - Caso fortuito o fuerza mayor', value: 'fortuitous_event' },
  { label: 'Art. 160 Nº 1A - Falta a la Probidad', value: 'lack_of_honesty' },
  { label: 'Art. 160 Nº 1B - Conductas de acoso sexual', value: 'sexual_harassment' },
  { label: 'Art. 160 Nº 1C - Vías de hecho ejercidas por el trabajador', value: 'bully_coworker' },
  { label: 'Art. 160 Nº 1D - Injurias proferidas al empleador', value: 'insult_coworker' },
  { label: 'Art. 160 Nº 1E - Conducta inmoral que afecte a la empresa', value: 'immoral_act' },
  { label: 'Art. 160 Nº 2 - Negociaciones prohibidas por contrato', value: 'prohibited_negotiation' },
  { label: 'Art. 160 Nº 3 - No concurrencia sin causa justificada', value: 'no_concurrence' },
  { label: 'Art. 160 Nº 4 - Abandono del trabajo', value: 'work_abandonment' },
  { label: 'Art. 160 Nº 5 - Actos, omisiones o imprudencias temerarias', value: 'recklessness' },
  { label: 'Art. 160 Nº 6 - Perjuicio material causado intencionalmente', value: 'material_injury' },
  { label: 'Art. 160 Nº 7 - Incumplimiento grave de las obligaciones que impone el contrato', value: 'serious_breach' },
  { label: 'Art. 161 - Desahucio del empleador', value: 'eviction' },
  { label: 'Art. 161 - Necesidades de la Empresa', value: 'company_necessity' },
  {
    label: 'Art. 161 bis - Invalidez, total o parcial del trabajador, sujeto a indemnización',
    value: 'employee_disability'
  }
];
