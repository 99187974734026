import React, { useState, useEffect, useMemo } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import {
  ButtonTooltip,
  ComponentDataTable,
  DefaultModal,
  Icon,
  LinkBtn,
  LongCard,
  SimpleCenteredModal
} from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { createCandidateRequest, deleteCandidateRequest } from '../../requests/candidates';
import { showSelectionProcessRequest } from '../../requests/selectionProcesses';
import { camelCaseEmptyStringRecursive, filterArray } from '../../services/utils';
import BasicCandidateShowModal from './BasicCandidateModalShow';
import basicSelectionProcess from './basicSelectionProcess';
import basicCandidate from './candidate';
import CandidateForm from './CandidateForm';
import SelectionProcessCandidateEdit from './SelectionProcessCandidateEdit';
import SelectionProcessInfo from './SelectionProcessInfo';
import SelectionProcessShowDetail from './SelectionProcessShowDetail';
import columns from './ShowColumns';

import './style.scss';

const SelectionProcessShow = ({ match }) => {
  const selectionProcessId = match.params.id;
  const ability = useAbility(AbilityContext);
  const [activeCard, setActiveCard] = useState('all');
  const [copySuccess, setCopySuccess] = useState('');
  const [customParams, setCustomParams] = useState(null);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalItemId, setDefaultModalItemId] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [filterCandidates, setFilterCandidates] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(true);
  const [onRequest, setOnRequest] = useState(false);
  const [selectionProcess, setSelectionProcess] = useState(basicSelectionProcess);
  const [step, setStep] = useState(0);
  const [textInput, setTextInput] = useState('');
  const dispatch = useDispatch();

  const filters = useMemo(
    () => [
      'candidate.fullName',
      'candidate.nationalIdentification',
      'candidate.translatedCandidateType',
      'candidate.careers',
      'candidate.communeName',
      'selectionProcessStage.label'
    ],
    []
  );

  const fetchSelectionProcess = () => {
    setOnRequest(true);
    showSelectionProcessRequest(selectionProcessId, {
      dispatch,
      successCallback: response => setSelectionProcess(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleClick = card => {
    switch (card) {
      case 'applied':
        setCustomParams('applied');
        setActiveCard('applied');
        break;
      case 'accepted':
        setCustomParams('accepted');
        setActiveCard('accepted');
        break;
      case 'rejected':
        setCustomParams('rejected');
        setActiveCard('rejected');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
    setMoreData(!moreData);
  };

  const handleDefaultModalClose = () => setDefaultModalShow(false);

  const handleDeleteRequest = () => {
    setOnRequest(true);
    deleteCandidateRequest(defaultModalItemId, {
      dispatch,
      successCallback: () => dispatch(sendAlert({ kind: 'success', message: 'Candidato eliminado con éxito' })),
      callback: () => {
        handleDefaultModalClose();
        setMoreData(!moreData);
        setOnRequest(false);
      }
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setModalShow(true);
        setModalTitle(item.candidate.fullName);
        setModalBody(<BasicCandidateShowModal item={item.candidate} />);
        break;
      case 'edit':
        setModalShow(true);
        setModalTitle('Editar candidato');
        setModalBody(
          <SelectionProcessCandidateEdit
            id={item.candidate.id}
            selectionProcess={selectionProcess}
            setOnRequest={setOnRequest}
            setModalShow={setModalShow}
            setMoreData={setMoreData}
          />
        );
        break;
      case 'destroy':
        setDefaultModalShow(true);
        setDefaultModalItemId(item.candidate.id);
        setDefaultModalBody(`¿Estás seguro que deseas eliminar a ${item.candidate.fullName}?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const subHeaderComponent = () => (
    <Form.Group className="ml-auto w-25">
      <FormControl
        placeholder="Buscar"
        name="textInput"
        value={textInput}
        onChange={e => setTextInput(e.target.value)}
      />
    </Form.Group>
  );

  const filteredArray = () => {
    let filtered = selectionProcess.selectionProcessCandidates;
    if (customParams) filtered = filtered.filter(item => item.status === customParams);
    setFilterCandidates(filterArray(filtered, textInput, filters));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectionProcess.publicUrl);
    setCopySuccess('Copiado');
  };

  const handleCreate = values => {
    const { resume } = values.candidate;
    const params = snakeCaseKeys(values.candidate);

    createCandidateRequest(selectionProcess.id, {
      dispatch,
      params: { candidate: { ...params, resume } },
      formData: true,
      successCallback: () => dispatch(sendAlert({ kind: 'success', message: 'Candidato creado con éxito' })),
      callback: () => {
        setMoreData(!moreData);
        setModalShow(false);
      }
    });
  };

  const handleNewCandidate = () => {
    setModalShow(true);
    setModalTitle('Nuevo candidato');
    setModalBody(
      <CandidateForm
        candidate={basicCandidate}
        selectionProcess={selectionProcess}
        action="new"
        formRequest={handleCreate}
      />
    );
  };

  const statusAddsCandidates = status => ['active', 'generated', 'suspended'].includes(status);

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchSelectionProcess, [moreData]);
  useEffect(filteredArray, [textInput, customParams, selectionProcess.selectionProcessCandidates]);

  return (
    <>
      <Row className="my-3">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/selection_processes">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={9} className="mt-3">
          <Row>
            <Col md={12}>
              <h2 className="text-uppercase mt-1">
                {selectionProcess.correlativeIdentification} - {selectionProcess.name}
              </h2>
            </Col>
            <Col md={10}>
              <pre>
                <p className="text-justify">{selectionProcess.description}</p>
              </pre>
            </Col>
          </Row>
        </Col>
        <Col md={2} className="mt-3">
          <LinkBtn block variant="warning" to={`/selection_processes/${selectionProcessId}/edit`}>
            Editar
          </LinkBtn>
        </Col>
      </Row>

      <SelectionProcessInfo selectionProcess={selectionProcess} />

      <Row className="my-1">
        <Col md={3} xs={12}>
          <LongCard
            variant="warning"
            title={selectionProcess.appliedCandidates}
            label="Candidatos activos"
            active={activeCard === 'applied'}
            onClick={() => handleClick('applied')}
          />
        </Col>
        <Col md={3} xs={12}>
          <LongCard
            variant="primary"
            title={selectionProcess.acceptedCandidates}
            label="Candidatos aceptados"
            active={activeCard === 'accepted'}
            onClick={() => handleClick('accepted')}
          />
        </Col>
        <Col md={3} xs={12}>
          <LongCard
            variant="danger"
            title={selectionProcess.rejectedCandidates}
            label="Candidatos rechazados"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
      </Row>
      {statusAddsCandidates(selectionProcess.status) && (
        <Row className="d-flex justify-content-between">
          <Col md={6} lg={4} className="mb-3">
            <Row>
              <Col md={6}>
                <Button block variant="info" onClick={copyToClipboard}>
                  Copiar Link Público
                </Button>
              </Col>
              {copySuccess && (
                <Col md={2} className="d-flex align-items-center justify-content-center my-1">
                  {copySuccess}
                </Col>
              )}
            </Row>
          </Col>
          <Col md={3} lg={2}>
            {ability.can('create', 'Candidate') && (
              <Button block variant="primary" onClick={handleNewCandidate}>
                Nuevo Candidato
              </Button>
            )}
          </Col>
        </Row>
      )}
      <Row className="mt-3 pb-0">
        <Col md={12}>
          <div className="float-right">
            <ButtonTooltip
              disabled={onRequest}
              onClick={() => setStep(1)}
              variant="circle-primary"
              className={`btn-circle toogle ${step === 0 ? '' : 'active'} `}
              text="Etapas"
            >
              <Icon icon="trello" />
            </ButtonTooltip>
          </div>
          <div className="float-right">
            <ButtonTooltip
              onClick={() => setStep(0)}
              variant="circle-primary"
              className={`btn-circle toogle ${step === 0 ? 'active' : ''} `}
              text="Candidatos"
            >
              <Icon icon="list-outline" />
            </ButtonTooltip>
          </div>
        </Col>
      </Row>
      {activeCard !== 'all' && (
        <Col xs={12}>
          <ButtonTooltip
            onClick={() => {
              setFilterCandidates(selectionProcess.selectionProcessCandidates);
              setActiveCard('all');
              setCustomParams(null);
            }}
            variant="link"
            className="text-primary"
            size="sm"
            text="Volver"
          >
            <Icon icon="chevron-back" width={15} />
            <span>Volver a tabla inicial</span>
          </ButtonTooltip>
        </Col>
      )}
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
      <DefaultModal
        title="Eliminar Candidato"
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={handleDeleteRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      {step === 0 ? (
        <ComponentDataTable
          columns={columns(handleButtonClick)}
          totalRows={filterCandidates?.length}
          data={filterCandidates}
          moreData={moreData}
          onRequest={onRequest}
          sortServer={false}
          subHeaderComponent={subHeaderComponent()}
          customParams={customParams}
          onRowClicked={item => handleButtonClick(item, 'show')}
          pointerOnHover
        />
      ) : (
        <SelectionProcessShowDetail
          selectionProcess={selectionProcess}
          statusFilter={customParams}
          setMoreData={setMoreData}
        />
      )}
    </>
  );
};

export default SelectionProcessShow;
