import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon } from '../../../components';

export default memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'EVALUADOR/ES',
    selector: 'evaluators',
    cell: item =>
      item.evaluator_employees.length === 1
        ? item.evaluator_employees[0]?.label
        : `${item.evaluator_employees.length} evaluadores`,
    sortable: true,
    grow: '1'
  },
  {
    name: 'CANTIDAD DE TRABAJADORES',
    selector: 'employee_count',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      const colors = { pending: 'warning', finished: 'primary' };
      const itemColor = colors[item.status] || 'dark';
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'show')}
        variant="circle-info"
        className="btn-circle"
        size="sm"
        text="Ver"
      >
        <Icon icon="eye" />
      </ButtonTooltip>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);
