import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexNecessityAreaRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/necessity_areas', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createNecessityAreaRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/necessity_areas', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const updateNecessityAreaRequest = (id, { dispatch, params, formData, successCallback }) =>
  ApiService.request('put', `/necessity_areas/${id}`, {
    dispatch,
    params,
    formData,
    successCallback
  });

export const deleteNecessityAreaRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/necessity_areas/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const exportNecessityAreaRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/necessity_areas/export.xlsx', {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const preImportNecessityAreaRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/necessity_areas/pre_import', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const importNecessityAreaRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/necessity_areas/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const importTemplateNecessityAreaRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/necessity_areas/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });

export const debounceIndexNecessityAreaRequest = AwesomeDebouncePromise(indexNecessityAreaRequest, 300);
