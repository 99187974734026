const getFolderAbility = (folder, ability) => {
  let read = ability.can('read', 'DirectoryNode');
  let destroy = ability.can('destroy_file', 'DirectoryNode');
  let createFile = ability.can('create_file', 'DirectoryNode');
  let destroyFile = ability.can('destroy_file', 'DirectoryNode');
  let updateFile = ability.can('update_file', 'DirectoryNode');
  let updateFolder = ability.can('update_folder', 'DirectoryNode');
  switch (folder.documentType) {
    case 'work_contract':
      read = read || ability.can('read_contract', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_contract', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_contract', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_contract', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_contract', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_contract', 'DirectoryNode');
      break;
    case 'certificate':
      read = read || ability.can('read_certificate', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_certificate', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_certificate', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_certificate', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_certificate', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_certificate', 'DirectoryNode');
      break;
    case 'loan_document':
      read = read || ability.can('read_requests', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_requests', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_requests', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_requests', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_requests', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_requests', 'DirectoryNode');
      break;
    case 'contract_annex':
      read = read || ability.can('read_annex', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_annex', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_annex', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_annex', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_annex', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_annex', 'DirectoryNode');
      break;
    case 'work_settlement':
      read = read || ability.can('read_settlement', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_settlement', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_settlement', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_settlement', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_settlement', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_settlement', 'DirectoryNode');
      break;
    case 'salary_settlement':
      read = read || ability.can('read_salary', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_salary', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_salary', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_salary', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_salary', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_salary', 'DirectoryNode');
      break;
    case 'payment_advance':
      read = read || ability.can('read_requests', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_requests', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_requests', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_requests', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_requests', 'DirectoryNode');
      break;
    case 'other_documents':
      read = read || ability.can('read_others', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_others', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_others', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_others', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_others', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_others', 'DirectoryNode');
      break;
    case 'reprimand_document':
      read = read || ability.can('read_others', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_others', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_others', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_others', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_others', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_others', 'DirectoryNode');
      break;
    case 'risk_prevention':
      read = read || ability.can('read_prevention', 'DirectoryNode');
      destroy = destroy || ability.can('destroy_file_prevention', 'DirectoryNode');
      createFile = createFile || ability.can('create_file_prevention', 'DirectoryNode');
      destroyFile = destroyFile || ability.can('destroy_file_prevention', 'DirectoryNode');
      updateFile = updateFile || ability.can('update_file_prevention', 'DirectoryNode');
      updateFolder = updateFolder || ability.can('update_folder_prevention', 'DirectoryNode');
      break;
    default:
      break;
  }
  return [
    read && 'show',
    read && 'show',
    destroy,
    createFile && 'node',
    destroyFile && 'remove',
    updateFile && 'update',
    updateFolder && 'update_folder'
  ];
};

export default getFolderAbility;
