import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import * as Yup from 'yup';
import { withFormik, Field, Form, getIn } from 'formik';
import { sendAlert } from '../../actions/utils';
import FormikInput from '../../components/Utils/Input/FormikInput';
import { changePasswordRequest } from '../../requests/user';
import PasswordChecklist from './PasswordChecklist';
import Icon from '../../components/Icons';

const ChangePassword = props => {
  const { onHide, submitVariant, errors, touched, setFieldValue } = props;

  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const [isDisable, setIsDisable] = useState(true);

  const handleInputPassword = e => {
    setNewPassword(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const handleDisableButton = toogle => {
    setIsDisable(!toogle);
  };

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={11}>
        <Form className="d-flex flex-column justify-content-end mt-2">
          <Field name="user[currentPassword]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showCurrentPassword ? 'text' : 'password'}
                placeholder="Contraseña Actual"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            )}
          </Field>
          <Field name="user[password]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showPassword ? 'text' : 'password'}
                placeholder="Nueva Contraseña"
                onChange={e => handleInputPassword(e)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowPassword(!showPassword)}
              />
            )}
          </Field>
          <Field name="user[passwordConfirmation]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showPasswordConfirmation ? 'text' : 'password'}
                placeholder="Confirmar Nueva Contraseña"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              />
            )}
          </Field>
          <PasswordChecklist password={newPassword} achieveRequirements={handleDisableButton} />

          <Button
            variant={submitVariant}
            size="lg"
            disabled={isDisable}
            className="mt-4 ml-auto"
            type="submit"
            onClick={onHide}
          >
            CAMBIAR CONTRASEÑA
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { user, currentEmployee } = state.auth;
  return { user, currentEmployee };
};

const initialValues = {
  user: {
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  }
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    currentPassword: Yup.string().required('Este campo es obligatorio'),
    password: Yup.string().required('Este campo es obligatorio'),
    passwordConfirmation: Yup.string()
      .required('Este campo es obligatorio')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
  })
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  changePasswordRequest({
    dispatch,
    params: snakeCaseKeys(values),
    successCallback: () => {
      const { setModalShow } = props;
      setModalShow(false);
      const message = 'Contraseña modificada con éxito';
      dispatch(sendAlert({ kind: 'success', message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ChangePassword)
  )
);
