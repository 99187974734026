import ApiService from '../services/apiService';

export const indexContractVersionRequest = ({ dispatch, contractId, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/contracts/${contractId}/contract_versions`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showContractVersionRequest = (
  id,
  { dispatch, contractId, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/contracts/${contractId}/contract_versions/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
