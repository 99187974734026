import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../components';
import CompanyTaskDatatable from './CompanyTaskDatatable';

const CompanyTaskIndex = () => (
  <>
    <Row className="mt-4 mb-4">
      <Col>
        <h2 className="mb-3 mt-3">Anuncios/Tareas</h2>
      </Col>
      <Col md={2} className="my-3">
        <LinkBtn block variant="primary" className="mb-1 ml-md-n4" to="/tasks/new">
          Nuevo
        </LinkBtn>
      </Col>
    </Row>
    <CompanyTaskDatatable />
  </>
);

export default CompanyTaskIndex;
