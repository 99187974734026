import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { showBalanceRequest, updateBalanceRequest } from '../../requests/balances';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { LinkBtn, Icon } from '../../components';
import BalanceForm from './Form/BalanceForm';
import { emptyBalance } from './balance';

const BalanceEdit = ({ asset, match }) => {
  const [balance, setBalance] = useState(emptyBalance);
  const dispatch = useDispatch();
  const history = useHistory();
  const balanceId = match.params.id;
  const path = asset ? 'assets' : 'discounts';
  const model = asset ? 'Haber' : 'Descuento';
  const tab = asset ? 'asset' : 'discount';

  const fetchBalance = () => {
    showBalanceRequest(balanceId, {
      dispatch,
      successCallback: response => setBalance(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: `${model} actualizado con éxito` }));
    history.push(`/balances/${path}/${balanceId}`);
  };

  const handleUpdateRequest = (params, setSubmitting) => {
    updateBalanceRequest(balanceId, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchBalance, []);
  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to={`/balances?tab=${tab}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Editar {model}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <BalanceForm
            balanceId={balanceId}
            balance={balance}
            action="edit"
            submitVariant="success"
            formRequest={handleUpdateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default BalanceEdit;
