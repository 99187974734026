import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCompanyLogRequest = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/company_logs${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const debounceIndexCompanyLogRequest = AwesomeDebouncePromise(indexCompanyLogRequest, 300);
