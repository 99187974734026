import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';

import { indexProfilesRequest, deleteProfileRequest } from '../../../requests/adminProfiles';
import { ComponentDataTable, DefaultModal, ButtonTooltip, Icon } from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'hide',
    cell: item =>
      item.hide ? 'Oculto' : 'Visible',
    sortable: true,
    grow: '1'
  },

  {
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
        >
          <Icon icon="pencil" />
        </ButtonTooltip>

        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text="Eliminar"
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const RoleDataTable = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [roles, setRoles] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setRoles(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    indexProfilesRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleSuccessRemove = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const removeRole = roleId => {
    deleteProfileRequest(roleId, {
      dispatch,
      successCallback: handleSuccessRemove
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/admin/roles/${item.id}`);
        break;
      case 'edit':
        history.push(`/admin/roles/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar el perfil '${item.name}'?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={roles}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title="Eliminar Perfil"
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => removeRole(modalItem.id)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default RoleDataTable;
