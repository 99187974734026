import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { LinkBtn, Icon, DefaultModal, Dropzone, BasicTooltip } from '../../components';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import {
  showTicketRequest,
  cancelTicketRequest,
  updateTicketRequest,
  denyAccountAccessRequest
} from '../../requests/tickets';
import { createDropZoneFileRequest } from '../../requests/dropzone';
import { InfoShow, InfoTicketComments } from '../Shared/Ticket';
import './style.scss';

const TicketShow = ({ match }) => {
  const ticketId = match.params.id;
  const [ticket, setTicket] = useState({ ticketComments: [] });
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalSize, setModalSize] = useState('md');
  const [modalConfirmBtn, setModalConfirmBtn] = useState('');
  const [modalConfirmBtnColor, setModalConfirmBtnColor] = useState('primary');
  const [newFiles, setNewFiles] = useState({});
  const [destroyFiles, setDestroyFiles] = useState([]);
  const [comment, setComment] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchTicket = params => {
    showTicketRequest(ticketId, {
      dispatch,
      params,
      successCallback: response => {
        setTicket(camelCaseEmptyStringRecursive(response.data));
      }
    });
  };

  useEffect(fetchTicket, []);
  useEffect(() => window.scrollTo(0, 0), []);

  const handleSuccessAction = (message, redirect = false) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    if (redirect) {
      history.push(`/tickets/`);
    }
    setComment('');
    fetchTicket();
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setModalShow(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCancelTicket = params => {
    cancelTicketRequest(ticketId, {
      dispatch,
      params,
      successCallback: () => handleSuccessAction('Ticket cancelado con éxito', true),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateTicket = (params, isComment = false) => {
    const message = isComment ? 'Comentario agregado con éxito' : 'Ticket actualizado con éxito';
    updateTicketRequest(ticketId, {
      dispatch,
      params,
      successCallback: () => handleSuccessAction(message)
    });
  };

  const denyAccountAccess = params => {
    denyAccountAccessRequest(ticketId, {
      dispatch,
      params,
      successCallback: () => handleSuccessAction('Acceso denegado con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleUploadMorefiles = () => {
    const documentKeys = newFiles.filter(arr => !destroyFiles.includes(arr));
    handleUpdateTicket({ ticket: { document_keys: documentKeys } });
    setModalShow(false);
  };

  const handleAddComment = () => {
    handleUpdateTicket({ ticket: { ticket_comments_attributes: [{ comment }] } }, true);
    setModalShow(false);
  };

  const modalHandleConfirm = () => {
    switch (modalTitle) {
      case 'Observación Ticket':
        setModalShow(false);
        break;
      case 'Cancelar Ticket':
        handleCancelTicket();
        break;
      case 'Agregar Archivos':
        setModalSize('md');
        handleUploadMorefiles();
        break;
      case 'Confirmar Acceso':
        handleUpdateTicket({ ticket: { account_access: 'granted' } });
        break;
      case 'Denegar Acceso':
        denyAccountAccess();
        break;
      case 'Agregar Comentario':
        handleAddComment();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const displayModal = (title, body, confirmBtn, confirmBtnColor) => {
    setModalTitle(title);
    setModalBody(body);
    setModalConfirmBtn(confirmBtn);
    setModalConfirmBtnColor(confirmBtnColor);
    setModalShow(true);
  };

  const onDropUploadedFile = document => {
    const documentKeys = document.map(doc => doc.code);
    setNewFiles(documentKeys);
  };

  const handleDestroyFile = ({ code }) => {
    const newArray = destroyFiles;
    newArray.push(code);
    setDestroyFiles(newArray);
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => response
    });
  };

  const accessRequestMotive = () => (
    <>
      <p>Autorizo que el usuario de soporte de Peoplework tome el control de la aplicación con el siguiente motivo:</p>
      <p>{ticket.accessMotive ? ticket.accessMotive : '-'}</p>
    </>
  );

  const itemColorNormal = ticket.translatedStatus === 'Ingresado' ? 'primary' : '';
  const ticketStatusColor = ticket.translatedStatus === 'Pendiente' ? 'warning' : itemColorNormal;
  const isOpen = ticket.translatedStatus !== 'Cerrado' && ticket.translatedStatus !== 'Cancelado';
  const accessRequest = ticket.translatedAccountAccess;

  return (
    <>
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to="/tickets">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={11} className="mt-3">
          <h2 className="text-uppercase my-1">Ticket #{ticket.code}</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={11} className="mx-auto">
          <Row>
            <Col md={8} className="ticket-card card-left">
              <InfoShow displayModal={displayModal} ticket={ticket} />
            </Col>
            <Col className="ticket-card card-right">
              <h3 className="mt-4 mb-5 text-uppercase">Acciones</h3>
              {isOpen && (
                <>
                  <Button
                    block
                    variant="primary"
                    className="mb-4"
                    onClick={() => {
                      setModalSize('lg');
                      displayModal(
                        'Agregar Archivos',
                        <Dropzone
                          fileAccept=".pdf .xls .doc .docx .rft .xlsxl .ppt .pptx .jpg .jpeg .gif .mp3 .mp4 .wmv .avi .png" // add space at the beginning of the string
                          maxSize={50000000}
                          multiple
                          customClass="long-dropzone"
                          onDelete={handleDestroyFile}
                          onDrop={handleOnDrop}
                          onDropUploaded={onDropUploadedFile}
                        />,
                        'Agregar',
                        'primary'
                      );
                    }}
                  >
                    Agregar Archivos
                  </Button>
                  <Button
                    block
                    variant="danger"
                    className="mb-5"
                    onClick={() =>
                      displayModal(
                        'Cancelar Ticket',
                        '¿Está seguro que desea cancelar este ticket?',
                        'Cancelar Ticket',
                        'danger'
                      )
                    }
                  >
                    Cancelar Ticket
                  </Button>
                </>
              )}
              {accessRequest === 'Pendiente' ? (
                <>
                  <Row>
                    <Col xs={10} md={9} lg={10}>
                      <h3 className="text-uppercase">Solicitud de Acceso</h3>
                    </Col>
                    <Col xs={2} md={3} lg={2} className="mt-1">
                      <BasicTooltip text="Soporte ha solicitado acceso a su cuenta">
                        <Icon icon="help-circle" width={24} />
                      </BasicTooltip>
                    </Col>
                  </Row>
                  <p className="show-info-title my-2">
                    El usuario de soporte está solicitando acceso a su cuenta para investigar el problema.
                  </p>
                  <Row className="mt-4 mb-5">
                    <Col md={6}>
                      <Button
                        block
                        variant="success"
                        className="mb-xs-4"
                        onClick={() => {
                          displayModal('Confirmar Acceso', accessRequestMotive(), 'Confirmar Acceso', 'primary');
                        }}
                      >
                        Autorizar
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        block
                        variant="warning"
                        onClick={() => {
                          displayModal('Denegar Acceso', accessRequestMotive(), 'Denegar Acceso', 'warning');
                        }}
                      >
                        Denegar
                      </Button>
                    </Col>
                  </Row>
                  <p className="show-info-title">Estado:</p>
                  <p className={`ml-3 text-${ticketStatusColor}`}>{ticket.translatedStatus}</p>
                </>
              ) : (
                <>
                  <p className="mt-4 show-info-title">Estado:</p>
                  <p className={`ml-3 text-${ticketStatusColor}`}>{ticket.translatedStatus}</p>
                  <p className="mt-4 show-info-title">Solicitud de Acceso:</p>
                  <p className={`ml-3 text-${accessRequest === 'Denegado' ? 'warning' : 'primary'}`}>{accessRequest}</p>
                  {accessRequest === 'Otorgado' && (
                    <Button
                      block
                      variant="warning"
                      onClick={() => {
                        displayModal('Denegar Acceso', accessRequestMotive(), 'Denegar Acceso', 'warning');
                      }}
                    >
                      Denegar
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <InfoTicketComments
        ticketComments={ticket.ticketComments}
        displayModal={displayModal}
        setComment={setComment}
        isOpen={isOpen}
      />
      <DefaultModal
        body={modalBody}
        handleClose={() => {
          setModalShow(false);
          setModalSize('md');
        }}
        handleConfirm={modalHandleConfirm}
        modalSize={modalSize}
        show={modalShow}
        title={modalTitle}
        titleBtnSave={modalConfirmBtn}
        variantBtnSave={modalConfirmBtnColor}
        withClose={false}
      />
    </>
  );
};

export default TicketShow;
