import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { LinkBtn, Icon } from '../../../components';
import CompanyForm from '../../Shared/Company/CompanyForm';
import { showAdminCompanyRequest, updateAdminCompanyRequest } from '../../../requests/adminCompanies';
import { sendAlert } from '../../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import basicCompany from './company';

const CompanyEdit = ({ match }) => {
  const [company, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    params: { id: companyId }
  } = match;

  const fetchCompany = () => {
    showAdminCompanyRequest(companyId, {
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const handleUpdateRequest = companyValues => {
    const { logo } = companyValues.company;
    const myParams = snakeCaseKeys(companyValues, { exclude: ['_destroy'] });
    myParams.company.logo = logo;

    companyValues.company.representantsAttributes.forEach((value, index) => {
      myParams.company.representants_attributes[index].image = value.image !== '' ? value.image : null;
    });
    updateAdminCompanyRequest(companyId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Empresa actualizada con éxito' }));
        history.push(`/admin/companies/${companyId}`);
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchCompany, []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle ml-n2 ml-md-2 mt-md-3" to="/admin/companies">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-2">Editar Empresa</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <CompanyForm
            company={company}
            action="edit"
            submitVariant="success"
            formRequest={handleUpdateRequest}
            admin
          />
        </Col>
      </Row>
    </>
  );
};

export default CompanyEdit;
