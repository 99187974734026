import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { basicDnc } from './dnc';
import { showDncRequest, updateDncRequest } from '../../requests/dncs';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import DncForm from './DncForm';

const DncEdit = ({ match }) => {
  const [dnc, setDnc] = useState(basicDnc);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleShowRequest = () => {
    showDncRequest(match.params.id, {
      dispatch,
      successCallback: res => setDnc(camelCaseEmptyStringRecursive(res.data))
    });
  };

  const handleUpdateRequest = (dncValues, setSubmitting) => {
    updateDncRequest(match.params.id, {
      dispatch,
      params: snakeCaseKeys(dncValues, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'DNC actualizado con éxito' }));
        history.push('/dnc');
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to="/dnc">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Editar {dnc.name}</h2>
        </Col>
      </Row>
      <DncForm dnc={dnc} action="edit" formRequest={handleUpdateRequest} />
    </>
  );
};

export default DncEdit;
