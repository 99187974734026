const civilStateTypes = [
  { label: 'Soltero(a)', value: 'single' },
  { label: 'Casado(a)', value: 'married' },
  { label: 'Viudo(a)', value: 'widower' },
  { label: 'Conviviente Civil', value: 'civil_union_agreement' },
  { label: 'Divorciado(a)', value: 'divorced' }
];

const dateTypes = [
  { label: 'Fecha Inicio de Contrato', value: 'contract_start_date' },
  { label: 'Fecha Termino de Contrato', value: 'contract_end_date' },
  { label: 'Fecha de vencimiento Cédula de Identidad', value: 'dni_expiration_date' }
];

const educationLevelTypes = [
  { label: 'Sin Escolaridad', value: 'no_school' },
  { label: 'Básica Incompleta', value: 'basic_incomplete' },
  { label: 'Básica Completa', value: 'basic_complete' },
  { label: 'Media Incompleta', value: 'media_incomplete' },
  { label: 'Media Completa', value: 'media_complete' },
  { label: 'Superior técnica Profesional Incompleta', value: 'technical_incomplete' },
  { label: 'Superior Técnica Profesional Completa', value: 'technical_complete' },
  { label: 'Universitaria Incompleta', value: 'university_incomplete' },
  { label: 'Universitaria Completa', value: 'university_complete' }
];

const contractTypes = [
  { label: 'Indefinido', value: 'undefined_term' },
  { label: 'Plazo fijo', value: 'fixed_term' },
  { label: 'Por obra o faena', value: 'work_or_task' },
  { label: 'Part time (jornada parcial)', value: 'contract_part_time' }
];

const identificationTypes = [
  { label: 'RUT', value: 'rut' },
  { label: 'Pasaporte', value: 'passport' },
  { label: 'Otra Identificación', value: 'other_id' }
];

const workSchedules = [
  { label: 'Jornada Completa (45 horas semanales)', value: 'full_time' },
  { label: 'Jornada Parcial', value: 'part_time' },
  { label: 'Articulo 22', value: 'article_22' }
];

const contractStatuses = [
  { label: 'Activo', value: 'active' },
  { label: 'Finiquitado', value: 'finished' }
];

const gratificationTypes = [
  { label: 'Garantizada', value: 'guaranteed' },
  { label: 'No Garantizada', value: 'unsure' },
  { label: 'Sin Gratificación', value: 'without_gratification' }
];

const gratificationPeriods = [
  { label: 'Anual', value: 'annual' },
  { label: 'Mensual', value: 'monthly' }
];

const gratificationConditions = [
  { label: 'Subtotal haberes imponibles', value: 'assets_subtotal' },
  { label: 'Sueldo Base', value: 'base_salary' }
];

const markingTypes = [
  { label: 'Celular', value: 'cellphone' },
  { label: 'Huella', value: 'fingerprint' },
  { label: 'Libro de Asistencia', value: 'book' },
  { label: 'Reconocimiento Facial', value: 'facial_recognition' },
  { label: 'Tarjeta', value: 'card' },
  { label: 'Contraseña', value: 'password' }
];

const paymentSchedules = [
  { label: 'Diario', value: 'daily' },
  { label: 'Mensual', value: 'monthly' },
  { label: 'Por Hora', value: 'hourly' },
  { label: 'Semanal', value: 'weekly' },
  { label: 'Quincenal', value: 'biweekly' }
];

const ineCodeTypes = [
  {
    label: 'Directivos de la Administración Pública y de Empresas',
    value: 'directors'
  },
  {
    label: 'Profesionales, Científicos e Intelectuales',
    value: 'professionals_scientists'
  },
  {
    label: 'Técnicos y Profesionales de Nivel Medio',
    value: 'technicians'
  },
  {
    label: 'Empleados de Oficina',
    value: 'office_employees'
  },
  {
    label: 'Trabajadores de los Servicios Personales, de Protección y Seguridad',
    value: 'personal_protection'
  },
  {
    label: 'Modelos, Vendedores y Demostradores',
    value: 'models_vendors'
  },
  {
    label: 'Agricultores y Trabajadores Calificados Agropecuarios y Pesqueros',
    value: 'farmers'
  },
  {
    label: 'Oficiales, Operarios y Artesanos de Artes Mecánicas y de otros Oficios',
    value: 'officials_operators'
  },
  {
    label: 'Operadores de Instalaciones y Máquinas y Montadores',
    value: 'operators'
  },
  {
    label: 'Trabajadores no Calificados',
    value: 'workers_without_skills'
  }
];

const unitOfAccountTypes = [
  { label: 'UF', value: 'uf' },
  { label: '%', value: 'percentage' },
  { label: '$', value: 'pesos' },
  { label: '7% + UF', value: 'seven_percent_uf' },
  { label: '7% + $', value: 'seven_percent_pesos' },
  { label: 'UF + $', value: 'uf_pesos' },
  { label: '7% + UF + $', value: 'seven_percent_uf_pesos' }
];

const senceFranchises = [
  { label: '0%', value: 'zero_percent' },
  { label: '15%', value: 'fifteen' },
  { label: '50%', value: 'fifty' },
  { label: '100%', value: 'hundred' }
];

const senceLevels = [
  { label: '1 - Ejecutivo', value: 'executive' },
  { label: '2 - Profesional', value: 'professional' },
  { label: '3 - Mando Medio', value: 'mid_level' },
  { label: '4 - Administrativo', value: 'administrative' },
  { label: '5 - Trabajador calificado', value: 'skilled' },
  { label: '6 - Trabajador semi calificado', value: 'semi_skilled' },
  { label: '7 - Trabajador NO Calificado', value: 'unskilled' }
];

const unitOfAccountVoluntaryTypes = [
  { label: 'UF', value: 'uf' },
  { label: '$', value: 'pesos' },
  { label: '%', value: 'percentage' }
];

const voluntaryPensionFundTypes = [
  { label: 'A', value: 'type_a' },
  { label: 'B', value: 'type_b' }
];

const voluntaryPrevisionTypes = [
  { label: 'APV', value: 'apv' },
  { label: 'Cuenta 2', value: 'count_two' },
  { label: 'Depósito Convenido', value: 'deposit_agreed' },
  { label: 'APV Colectivo', value: 'collective_apv' },
  { label: 'Afiliado Voluntario', value: 'voluntary_affiliate' }
];

const relationTypes = [
  { label: 'Cónyuge', value: 'spouse' },
  { label: 'Hijo', value: 'children' },
  { label: 'Nieto', value: 'grandchild' },
  { label: 'Madre Viuda', value: 'widowed_mother' },
  { label: 'Ascendiente mayor a 65 años', value: 'greater_ascendant' },
  { label: 'Conviviente Civil', value: 'civil_coexistence' },
  { label: 'Hijo entre 18 y 24 años (estudiante)', value: 'child_of_legal_age' },
  { label: 'Cónyuge Embarazada', value: 'pregnant_spouse' },
  { label: 'Trabajadora Embarazada', value: 'pregnant_worker' },
  { label: 'Cónyuge Inválido', value: 'invalid_spouse' },
  { label: 'Hijo Inválido', value: 'invalid_child' },
  { label: 'Ascendiente Inválido', value: 'invalid_ascendant' },
  { label: 'Padre', value: 'father' },
  { label: 'Madre', value: 'mother' }
];

const pensionSchemes = [
  { label: 'AFP', value: 'afp' },
  { label: 'No Cotiza', value: 'not_listed' },
  { label: 'IPS (ex INP)', value: 'ips' }
];

const pensionerTypes = [
  { label: 'No', value: 'not_pensioner' },
  { label: 'Sí (Pero cotiza)', value: 'with_afp' }
];

const noPensionPensionerTypes = [
  { label: 'Sí (No cotiza AFP)', value: 'with_no_afp' },
  { label: 'Extranjero', value: 'foreigner' }
];

const unemploymentInsuranceReasons = [
  { label: 'Contrato anterior a Octubre del 2002', value: 'old_contract' },
  { label: 'Pensionado', value: 'pensioned' }
];

const ipsTypes = [
  { label: 'Servicios de Seguro Social – Régimen 1', value: 'social_insurance' },
  { label: 'Empart – Régimen 1', value: 'empart' },
  { label: 'Campremer – Régimen 1', value: 'campremer' }
];

const heavyLaborAdditionals = [
  { label: 'No', value: 'no_heavy_labor' },
  { label: 'Sí, trabajo menos pesado', value: 'less_heavy_labor' },
  { label: 'Sí, trabajo pesado', value: 'with_heavy_labor' }
];

const familyAllowanceSections = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'No aplica', value: 'no_family_allowance' }
];

const workScheduleTooltipText = {
  hour45:
    'Jornada de 45 horas: La jornada ordinaria de trabajo es la que establece el Código del Trabajo para todos los ' +
    'trabajadores, salvo algunas excepciones. Ésta tiene una duración máxima de 45 horas semanales, que no podrán ' +
    'distribuirse en más de seis días ni en menos de cinco, y en ningún caso podrá exceder las 10 horas diarias.',
  article22:
    'Artículo 22: Pueden quedar excluidos de la limitación de jornada de trabajo, esto es, del tope de las 45 horas ' +
    'semanales: - Los trabajadores que presten servicios a distintos empleadores - Los gerentes, administradores, ' +
    'apoderados con facultades de administración - Los agentes comisionistas y de seguro, vendedores viajantes, ' +
    'cobradores y demás similares que no ejerzan sus funciones en el local del establecimiento - Los contratados por ' +
    'el Código del Trabajo para prestar servicios en su propio hogar o en un lugar libremente elegidos por ellos; - ' +
    'Todos aquellos que trabajen sin fiscalización superior inmediata Sin perjuicio de los trabajadores señalados ' +
    'anteriormente, el artículo 22 considera la exclusión de jornada de otros trabajadores: - Los trabajadores que ' +
    'se desempeñan a bordo de naves pesqueras. - Los contratados para prestar servicios preferentemente fuera del ' +
    'lugar o sitio de funcionamiento de la empresa, mediante la utilización de medios informáticos o de ' +
    'telecomunicaciones. - Los deportistas profesionales y trabajadores que desempeñan actividades conexas.',
  partTime:
    'Jornada parcial: es aquella de 30 horas o menos de trabajo a la semana pactada en el contrato y tiene las ' +
    'siguientes características: - Se permite el pacto de horas extraordinarias(en caso de existir una necesidad o ' +
    'situación temporal) - La jornada diaria debe ser continua y no puede exceder de 10 horas, debiendo ' +
    'interrumpirse por un lapso no inferior a media hora ni superior a una hora para la colación - Las partes ' +
    'pueden pactar distintas alternativas para distribuir la jornada.'
};
const workerQualities = [
  { label: 'Trabajador sector público afecto a la ley 18.834', value: 'public_worker_subject_to_law' },
  { label: 'Trabajador sector público no afecto a la ley 18.834', value: 'public_worker_not_subject_to_law' },
  { label: 'Trabajador dependiente sector privado', value: 'dependent_worker_private_sector' }
];
export {
  civilStateTypes,
  contractStatuses,
  contractTypes,
  dateTypes,
  educationLevelTypes,
  familyAllowanceSections,
  gratificationTypes,
  gratificationPeriods,
  gratificationConditions,
  heavyLaborAdditionals,
  identificationTypes,
  ineCodeTypes,
  ipsTypes,
  markingTypes,
  noPensionPensionerTypes,
  paymentSchedules,
  pensionSchemes,
  pensionerTypes,
  relationTypes,
  senceFranchises,
  senceLevels,
  unemploymentInsuranceReasons,
  unitOfAccountTypes,
  unitOfAccountVoluntaryTypes,
  voluntaryPensionFundTypes,
  voluntaryPrevisionTypes,
  workSchedules,
  workScheduleTooltipText,
  workerQualities
};
