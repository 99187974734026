import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, DatatableEmployeeName } from '../../components';

export default memoize((clickHandler, selfEvaluation, reviewer) => [
  {
    name: 'TRABAJADOR',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item.employee} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    cell: item => item.employee?.jobTitle,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    cell: item => item.employee?.jobManagement,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      const colors = { pending: 'warning', finished: 'primary' };
      const itemColor = colors[item.status] || 'dark';
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translatedStatus}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'AUTOEVALUACIÓN',
    selector: 'self_evaluation',
    cell: item => {
      const colors = { sent: 'warning', answered: 'primary' };
      const itemColor = colors[item.selfEvaluation] || 'dark';
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translatedSelfEvaluation}
        </p>
      );
    },
    sortable: true,
    omit: !selfEvaluation,
    center: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {selfEvaluation && reviewer && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'self_evaluation')}
            variant="circle-dark"
            className="btn-circle"
            size="sm"
            text="Enviar Autoevaluación"
            disabled={item.selfEvaluation === 'answered'}
          >
            <Icon icon="paper-plane" />
          </ButtonTooltip>
        )}
        {reviewer && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'evaluate')}
            variant="circle-primary"
            className="btn-circle"
            size="sm"
            text="Evaluar"
          >
            <Icon icon="clipboard-outline" />
          </ButtonTooltip>
        )}
        <ButtonTooltip
          onClick={() => clickHandler(item, 'feedback')}
          variant="circle-success"
          className="btn-circle"
          size="sm"
          text="Seguimiento"
        >
          <Icon icon="calendar" />
        </ButtonTooltip>
        {/* <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Seguimiento"
        >
          <Icon icon="pencil" />
        </ButtonTooltip> */}
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text="Eliminar"
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '170px'
  }
]);
