import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FormikSelect from '../../components/Utils/Select/FormikSelect';
import ButtonTooltip from '../../components/Utils/Tooltips/ButtonTooltip';
import Icon from '../../components/Icons/Icon';
import filters from './FormOptions';
import './style.scss';

const INITIAL_YEAR = 1850;

const CalendarAssistance = ({ handleAbsenceRequest, handlePermissionRequest, handleVacationRequest, ...props }) => {
  const calendar = useRef();
  const [date, setDate] = useState(moment());
  const [selectValue, setSelectValue] = useState('absenceLicense');
  const len = moment().year() + 100 - INITIAL_YEAR + 1;
  const years = useMemo(
    () =>
      Array(len)
        .fill()
        .map((_, idx) => ({ label: INITIAL_YEAR + idx, value: INITIAL_YEAR + idx })),
    [len]
  );
  const months = useMemo(
    () =>
      moment
        .months()
        .map((label, value) => ({ label: label.charAt(0).toUpperCase() + label.slice(1), value: value + 1 })),
    []
  );

  const setDateField = (year, month) => setDate(moment(`${year}-${month + 1}-1`));

  const gotoDate = () => {
    const calendarApi = calendar.current.getApi();
    calendarApi.gotoDate(date.format('YYYY-MM-DD'));
  };

  const nextMonth = () => setDate(moment(date.add(1, 'months')));

  const previousMonth = () => setDate(moment(date.subtract(1, 'months')));

  const filterType = () => {
    const currentDate = [
      moment(date)
        .clone()
        .startOf('month')
        .format('DD/MM/YYYY'),
      moment(date)
        .clone()
        .endOf('month')
        .format('DD/MM/YYYY')
    ];

    switch (selectValue) {
      case 'absenceLicense':
        handleAbsenceRequest(
          {
            filter_absence_exception: 'unjustified_absence',
            date_range: currentDate
          },
          'Licencias Médicas',
          '#29b48f'
        );
        break;

      case 'unjustifiedAbsence':
        handleAbsenceRequest(
          {
            filter_type: 'unjustified_absence',
            date_range: currentDate
          },
          'Ausencias Sin Justificar',
          '#186868'
        );
        break;

      case 'legalHoliday':
        handleVacationRequest(
          {
            filter_vacation_type: 'legal',
            status: 'approved',
            date_range: currentDate
          },
          'Vacaciones Legales + Proporcionales',
          '#ffc107'
        );
        break;

      case 'progressiveHoliday':
        handleVacationRequest(
          {
            filter_vacation_type: 'progressive',
            status: 'approved',
            date_range: currentDate
          },
          'Vacaciones Progresivas',
          '#ffc107'
        );
        break;

      case 'additionalDays':
        handleVacationRequest(
          {
            filter_vacation_type: 'additional_days',
            status: 'approved',
            date_range: currentDate
          },
          'Vacaciones Adicionales',
          '#ffc107'
        );
        break;

      case 'administrativeDays':
        handleVacationRequest(
          {
            filter_vacation_type: 'administrative_days',
            status: 'approved',
            date_range: currentDate
          },
          'Días Administrativos',
          '#ffc107'
        );
        break;

      case 'permissionPaidLeave':
        handlePermissionRequest(
          {
            filter_permission_type: 'with_pay',
            status: 'approved',
            date_range: currentDate
          },
          'Permisos con Goce de Sueldo',
          '#87ce03'
        );
        break;

      case 'permissionWithoutPaidLeave':
        handlePermissionRequest(
          {
            filter_permission_type: 'without_pay',
            status: 'approved',
            date_range: currentDate
          },
          'Permisos sin Goce de Sueldo',
          '#87ce03'
        );
        break;

      default:
        handleAbsenceRequest(
          {
            filter_absence_exception: 'unjustified_absence',
            date_range: currentDate
          },
          'Licencias Médicas',
          '#29b48f'
        );
        break;
    }
  };

  useEffect(gotoDate, [date]);
  useEffect(filterType, [selectValue, date]);

  return (
    <>
      <Row>
        <Col xs={12} md={1}>
          <Row className="mt-n2 mt-md-n0 mt-md-2">
            <Col xs={{ span: 2, offset: 4 }} sm={{ span: 6, offset: 0 }}>
              <ButtonTooltip
                onClick={previousMonth}
                variant="circle-info"
                className="btn-circle"
                toolbarVariant="my-3"
                size="sm"
                text="Mes anterior"
              >
                <Icon icon="chevron-back" />
              </ButtonTooltip>
            </Col>
            <Col xs={6}>
              <ButtonTooltip
                onClick={nextMonth}
                variant="circle-info"
                className="btn-circle"
                toolbarVariant="my-3"
                size="sm"
                text="Mes siguiente"
              >
                <Icon icon="chevron-forward" />
              </ButtonTooltip>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2}>
          <FormikSelect
            options={years}
            label="AÑO"
            value={date.year()}
            defaultValue={date.year()}
            placeholder=""
            onChange={e => setDateField(e.value, date.month())}
          />
        </Col>
        <Col xs={6} md={3}>
          <FormikSelect
            options={months}
            label="MES"
            value={date.month() + 1}
            defaultValue={date.month() + 1}
            placeholder=""
            onChange={e => setDateField(date.year(), e.value - 1)}
          />
        </Col>
        <Col md={6}>
          <FormikSelect
            label="Tipo de filtro"
            defaultValue={selectValue}
            value={selectValue}
            placeholder="Seleccionar filtro"
            options={filters}
            onChange={data => setSelectValue(data.value)}
          />
        </Col>
      </Row>
      <FullCalendar
        height="auto"
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{ left: '', center: '', right: '' }}
        firstDay={1}
        buttonText={{ today: 'hoy' }}
        locale="es"
        weekends
        droppable
        ref={calendar}
        eventColor="#29b48f"
        {...props}
      />
    </>
  );
};

export default CalendarAssistance;