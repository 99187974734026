import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAdminCompanyRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/admin/companies', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const businessNameAdminRequest = AwesomeDebouncePromise(
  ({ dispatch, params = {}, failureCallback, successCallback }) =>
    ApiService.request('get', `/admin/companies/business_name`, {
      dispatch,
      params,
      failureCallback,
      successCallback
    }),
  300
);

export const showAdminCompanyRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/admin/companies/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createAdminCompanyRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/admin/companies', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const updateAdminCompanyRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/companies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const changeActiveAdminCompanyRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/companies/${id}/change_active`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateStatusAdminCompanyRequest = (id, { dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('put', `/admin/companies/${id}/update_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const generateSecretKeyAdminCompanyRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/admin/companies/${id}/generate_secret_key`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminCompaniesRequest = AwesomeDebouncePromise(indexAdminCompanyRequest, 300);
