import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Form as BSForm } from 'react-bootstrap';
import * as Yup from 'yup';

import {
  NestedAttributes,
  FormikCheckBox,
  FormikInput,
  FormikSelect,
  UploadImage,
  InputSelect,
  RegionCommune,
  PhoneWithCode,
  FocusError,
  DefaultModal,
  FormikDatePicker
} from '../../../components';
import { gratificationTypes, representantIdentificationTypes, workActivityOptions } from './FormOptions';
import { indexBanksRequest } from '../../../requests/banks';
import { businessNameRequest } from '../../../requests/companies';
import { businessNameAdminRequest } from '../../../requests/adminCompanies';
import indexCompensationFundsRequest from '../../../requests/compensationFunds';
import { debounceIndexEconomicActivitiesRequest } from '../../../requests/economicActivities';
import indexSafetyOrganizationsRequest from '../../../requests/safetyOrganizations';
import { rutFormat, validRutInput } from '../../../services/utils';
import '../../../services/yupCustomMethods';

const BankAccountInput = ({ bankId, banks, errors, touched, index, setFieldValue, setFieldTouched }) => (
  <>
    <Col md={6}>
      <Field name={`company[bankAccountsAttributes][${index}][bankId]`}>
        {({ field }) => (
          <FormikSelect
            {...field}
            abbr
            label="Banco Pago Nómina"
            placeholder="Seleccionar Banco"
            options={banks}
            defaultValue={bankId}
            onChange={data => setFieldValue(field.name, data ? data.value : '')}
            setFieldTouched={() => setFieldTouched(field.name)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={5}>
      <Field name={`company[bankAccountsAttributes][${index}][account]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            inputType="number"
            label="N° Cuenta Pago Nómina"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
  </>
);

const RepresentantInput = ({
  errors,
  handleNationalIdentificationFormat,
  idType,
  index,
  setFieldTouched,
  setFieldValue,
  touched,
  representantAttribute
}) => (
  <>
    {index > 0 && (
      <Col className="mt-3 mb-3" md={12}>
        <hr />
      </Col>
    )}
    <Col md={4}>
      <Field name={`company[representantsAttributes][${index}][name]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            label="Nombre"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={4}>
      <Field name={`company[representantsAttributes][${index}][firstLastName]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            label="Primer Apellido"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={4}>
      <Field name={`company[representantsAttributes][${index}][secondLastName]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            label="Segundo Apellido"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={4}>
      <Field name={`company[representantsAttributes][${index}][identificationType]`}>
        {({ field }) => (
          <FormikSelect
            {...field}
            abbr
            defaultValue={idType}
            label="Tipo de Identificación"
            options={representantIdentificationTypes}
            placeholder="Seleccionar Tipo de Identificación"
            tooltipText=""
            onChange={data => {
              setFieldValue(`company[representantsAttributes][${index}][nationalIdentification]`, '');
              setFieldValue(field.name, data ? data.value : 'RUT');
            }}
            setFieldTouched={() => setFieldTouched(field.name)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={4}>
      <Field name={`company[representantsAttributes][${index}][nationalIdentification]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            label="Nº de Identificación"
            onChange={e => handleNationalIdentificationFormat(e, idType === 'rut')}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={4}>
      <Field name={`company[representantsAttributes][${index}][image]`}>
        {({ field }) => (
          <UploadImage
            {...field}
            name="Agregar firma"
            imageUrl={getIn(representantAttribute.imageSignature, 'fileUrl')}
            onChange={_avatar => setFieldValue(field.name, _avatar)}
            helpText="Formato sugerido 320x200px de máximo 5mb."
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            fileAccept=".png, .jpg, .gif, .jpeg"
          />
        )}
      </Field>
    </Col>
  </>
);

const CompanyForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  company,
  onHide,
  submitVariant,
  action,
  admin,
  isSubmitting
}) => {
  const [banks, setBanks] = useState([]);
  const [compensationFunds, setCompensationFunds] = useState([]);
  const [safetyOrganizations, setSafetyOrganizations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dispatch = useDispatch();

  const fetchSafetyOrganizations = () => {
    indexSafetyOrganizationsRequest({
      dispatch,
      params: { sort_column: 'name', sort_direction: 'asc', paginate: false },
      successCallback: response => setSafetyOrganizations(response.data.data)
    });
  };

  const fetchCompensationFunds = () => {
    indexCompensationFundsRequest({
      dispatch,
      params: { sort_column: 'name', sort_direction: 'asc', paginate: false },
      successCallback: response => setCompensationFunds(response.data.data)
    });
  };

  const fetchBanks = () => {
    indexBanksRequest({
      dispatch,
      params: { sort_column: 'name', sort_direction: 'asc', paginate: false },
      successCallback: response => setBanks(response.data.data)
    });
  };

  const initFetch = () => {
    fetchBanks();
    fetchCompensationFunds();
    fetchSafetyOrganizations();
  };

  useEffect(initFetch, []);

  const handleNationalIdentificationFormat = (e, isRut = true) => {
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const fetchEconomicActivities = (inputValue, callback) => {
    debounceIndexEconomicActivitiesRequest({
      dispatch,
      params: { filtered: inputValue, sort_column: 'name', sort_direction: 'asc' },
      successCallback: response => callback(response.data.data)
    });
  };

  const banksInputs = () => {
    const { bankAccountsAttributes } = values.company;

    const mapResults = bankAccountsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }
      return (
        <BankAccountInput
          key={`bank-${index.toString()}`}
          banks={banks}
          bankId={body.bankId}
          errors={errors}
          touched={touched}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    });

    mapResults.push(
      <>
        <Col md={6} className="sample-row">
          <FormikSelect label="Banco Pago Nómina" placeholder="Seleccionar Banco" isDisabled />
        </Col>
        <Col md={5} className="sample-row">
          <FormikInput label="N° Cuenta Pago Nómina" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={bankAccountsAttributes}
          setFieldValue={setFieldValue}
          valuePath="company[bankAccountsAttributes]"
          newAttributes={{ bankId: '', account: '' }}
        />
      </>
    );
  };

  const representantsInputs = () => {
    const { representantsAttributes } = values.company;

    const mapResults = representantsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }
      return (
        <RepresentantInput
          errors={errors}
          handleNationalIdentificationFormat={handleNationalIdentificationFormat}
          idType={body.identificationType}
          index={index}
          key={`bank-${index.toString()}`}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched}
          representantAttribute={body}
        />
      );
    });

    mapResults.push(
      <>
        <Col className="mt-3 mb-3" md={12}>
          <hr />
        </Col>
        <Col md={4} className="sample-row">
          <FormikInput label="Nombre" disabled />
        </Col>
        <Col md={4} className="sample-row">
          <FormikInput label="Primer Apellido" disabled />
        </Col>
        <Col md={3} className="sample-row">
          <FormikInput label="Segundo Apellido" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={representantsAttributes}
          setFieldValue={setFieldValue}
          valuePath="company[representantsAttributes]"
          newAttributes={{
            firstLastName: '',
            identificationType: '',
            name: '',
            nationalIdentification: '',
            secondLastName: '',
            image: ''
          }}
        />
      </>
    );
  };

  const initialDefaultValue = attribute => {
    const { [`${attribute}Id`]: vAttributeId } = values.company;
    const { [`${attribute}Id`]: attributeId } = company;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      return getIn(company, attribute);
    }
    return undefined;
  };

  const handleBusinessName = e => {
    setFieldValue('company[businessName]', e.target.value);
    if (action === 'new') {
      const allParams = {
        dispatch,
        params: { business_name: e.target.value },
        successCallback: response => {
          if (!response.data) return null;
          setModalBody('Ya existe una empresa con esta razón social');
          setModalShow(true);
          return null;
        }
      };
      if (admin) businessNameAdminRequest(allParams);
      else businessNameRequest(allParams);
    }
  };

  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const initEconomicActivity = initialDefaultValue('economicActivity');

  const {
    safetyOrganizationId,

    compensationFundId,
    gratificationType,
    phoneCountryCode,
    workActivity
  } = company;
  return (
    <Form>
      <FocusError />
      <h4 className="text-uppercase">DATOS DE LA EMPRESA</h4>
      <Row>
        <Col md={6}>
          <Field name="company[businessName]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                onChange={handleBusinessName}
                label="Razón social"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="company[fantasyName]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre de Fantasía"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="company[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                onChange={e => handleNationalIdentificationFormat(e)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="company[economicActivityId]">
            {({ field }) => (
              <InputSelect
                {...field}
                abbr
                label="Giro"
                placeholder="Seleccionar Actividad Económica"
                value={initEconomicActivity}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                request={fetchEconomicActivities}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="company[workActivity]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                defaultValue={workActivity}
                label="Actividad Laboral"
                options={workActivityOptions}
                placeholder="Seleccionar Actividad Laboral"
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="company[website]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Sitio web"
                placeholder="peoplework.cl"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[logo]">
            {({ field }) => (
              <UploadImage
                {...field}
                name="Agregar logo"
                imageUrl={getIn(company.businessLogo, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                helpText="Formato sugerido 320x200px de máximo 5mb."
                fileAccept=".png, .jpg, .gif, .jpeg"
              />
            )}
          </Field>
        </Col>
      </Row>
      <h4 className="text-uppercase">DATOS REPRESENTANTE LEGAL</h4>
      {representantsInputs()}
      <h4 className="text-uppercase">Datos de Contacto</h4>
      <Row>
        <Col md={6}>
          <Field name="company[address]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="company[addressNumber]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Número"
                inputType="number"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="company[addressFloor]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Piso"
                inputType="number"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="company[addressOffice]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Oficina"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <RegionCommune regionAbbr communeAbbr modelKey="company" />
        <Col md={6}>
          <Field name="company[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Email"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <PhoneWithCode
            label="Teléfono"
            countryCodeField="company[phoneCountryCode]"
            phoneField="company[phone]"
            workPhoneCountryCode={phoneCountryCode}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <h4 className="text-uppercase">Cuentas Bancarias</h4>
      {banksInputs()}
      <h4 className="text-uppercase">Otros</h4>
      <Row>
        <Col md={6}>
          <Field name="company[safetyOrganizationId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Mutual"
                placeholder="Seleccionar Mutual"
                options={safetyOrganizations}
                defaultValue={safetyOrganizationId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[mutualRate]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="number"
                label="Tasa de Cotización Mutualidad"
                addon="%"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[compensationFundId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Caja de compensación"
                placeholder="Seleccionar Caja de Compensación"
                options={compensationFunds}
                defaultValue={compensationFundId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isClearable
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[gratificationType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Modalidad de Pago Gratificación"
                placeholder="Seleccionar Modalidad"
                options={gratificationTypes}
                defaultValue={gratificationType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {admin && (
        <>
          <h4 className="text-uppercase">Funcionalidades Permitidas</h4>
          <Row>
            <Col md={4}>
              <Field name="company[peopleManagement]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Gestión de Personas" type="switch" />}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="company[signature]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Firma Digital" type="switch" />}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="company[attendanceManagement]">
                {({ field }) => (
                  <FormikCheckBox {...field} field={field} label="Control de Asistencia y Turnos" type="switch" />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Field name="company[remunerations]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Remuneraciones" type="switch" />}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="company[talentManagement]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Gestión de Talento" type="switch" />}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="company[consultativeDtAccess]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Acceso Consultivo DT" type="switch" />}
              </Field>
            </Col>
          </Row>
          <h4 className="text-uppercase">Exportar Empresa</h4>
          <Row>
            <Col md={3} className="mt-3">
              <Field name="company[allowDownload]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Exportar Empresa" type="switch" />}
              </Field>
              {values.company.lastZipRequest && (
                <BSForm.Text className="text-muted">Última descarga el {values.company.lastZipRequest}</BSForm.Text>
              )}
            </Col>
            {values.company.allowDownload && (
              <Col md={3}>
                <Field name="company[zipRequestEndDate]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      abbr
                      label="Vigente hasta"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
          </Row>
        </>
      )}
      <Row className="d-flex justify-content-end mt-3 mb-5">
        <Col md={3}>
          <Button type="submit" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
      <DefaultModal
        title="Razón social en uso"
        body={modalBody}
        show={modalShow}
        handleConfirm={() => setModalShow(false)}
        handleClose={() => setModalShow(false)}
        withClose={false}
        titleBtnSave="Confirmar"
        closeButton={false}
      />
    </Form>
  );
};

const setInitialValues = props => {
  const { company } = props;
  const { bankAccountsAttributes } = company;
  if (bankAccountsAttributes.length === 0) {
    company.bankAccountsAttributes = [{ bankId: '', account: '' }];
  }
  return { company };
};

const validationSchema = Yup.object().shape({
  company: Yup.object().shape(
    {
      address: Yup.string()
        .required('Debes ingresar una dirección')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      addressFloor: Yup.number().nullable(true),
      addressNumber: Yup.number().required('Debes ingresar un número'),
      addressOffice: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(true),
      bankAccountsAttributes: Yup.array().of(
        Yup.object().shape({
          account: Yup.number()
            .min(0, 'Debe ser positivo')
            .required('Debes ingresar un número de cuenta'),
          bankId: Yup.string().required('Debes seleccionar un banco')
        })
      ),
      businessName: Yup.string()
        .required('Debes ingresar una razón social')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      communeId: Yup.number().required('Debes seleccionar una comuna'),
      compensationFundId: Yup.number().nullable(true),
      economicActivityId: Yup.number().required('Debes seleccionar un giro'),
      workActivity: Yup.string().required('Debes seleccionar una actividad laboral'),

      email: Yup.string()
        .email('Debes ingresar un e-mail válido')
        .nullable(true),
      fantasyName: Yup.string()
        .required('Debes ingresar un nombre de fantasía')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      gratificationType: Yup.string().required('Debes seleccionar una modalidad de pago'),
      logo: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          'FILE_SIZE',
          'La imagen cargada excede el tamaño maximo permitido (5mb).',
          value => !value?.size || (value && value?.size <= 5242880)
        )
        .test(
          'FILE_FORMAT',
          'El archivo cargado tiene un formato no compatible.',
          value =>
            (!value && value?.type !== '') ||
            (value && ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(value?.type))
        )
        .test(
          'REPLACE_LETTER',
          'El archivo no debe tener la  letra Ñ en su nombre',
          value => (!value && typeof (value?.name === 'undefined')) || (value && !value?.name.includes('ñ'))
        ),
      mutualRate: Yup.number()
        .required('Debes ingresar una tasa de mutualidad')
        .max(100, 'La tasa no puede ser mayor a 100')
        .min(0, 'Debe ser mayor o igual a 0'),
      phone: Yup.string()
        .when('phoneCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .nullable(true),
      phoneCountryCode: Yup.string().when('phone', {
        is: val => val,
        then: Yup.string().required('Debes seleccionar un código'),
        otherwise: Yup.string().nullable()
      }),
      regionId: Yup.number().required('Debes seleccionar una región'),
      zipRequestEndDate: Yup.string().when('allowDownload', {
        is: val => val === true,
        then: Yup.string().required('Debe indicar la fecha de vigencia'),
        otherwise: Yup.string().nullable()
      }),
      representantsAttributes: Yup.array().of(
        Yup.object().shape({
          firstLastName: Yup.string()
            .required('Debes ingresar un apellido')
            .max(120, 'Deben ser menos que 120 caracteres')
            .alphanumeric('Deben ser caracteres alfanuméricos'),
          identificationType: Yup.string().required('Debes seleccionar un tipo de identificación'),
          name: Yup.string()
            .required('Debes ingresar un nombre')
            .max(120, 'Deben ser menos que 120 caracteres')
            .alphanumeric('Deben ser caracteres alfanuméricos'),
          nationalIdentification: Yup.string()
            .required('Debes ingresar un número de identificación')
            .max(30, 'Deben ser menos que 30 caracteres')
            .when('identificationType', (identificationType, schema) =>
              identificationType === 'rut'
                ? schema.rut('Debe ingresar un RUT válido')
                : schema.alphanumeric('Deben ser caracteres alfanuméricos')
            ),
          image: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              'FILE_SIZE',
              'La imagen cargada excede el tamaño maximo permitido (5mb).',
              value => !value?.size || (value && value?.size <= 5242880)
            )
            .test(
              'FILE_FORMAT',
              'El archivo cargado tiene un formato no compatible.',
              value =>
                (!value && value?.type !== '') ||
                (value && ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(value?.type))
            )
            .test(
              'REPLACE_LETTER',
              'El archivo no debe tener la  letra Ñ en su nombre',
              value => (!value && typeof (value?.name === 'undefined')) || (value && !value?.name.includes('ñ'))
            )
        })
      ),
      safetyOrganizationId: Yup.string().required('Debes seleccionar una mutual'),
      website: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .nullable()
    },
    [['phone', 'phoneCountryCode']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CompanyForm);
