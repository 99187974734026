import React from 'react';
import { Table } from 'react-bootstrap';

import { ApprovementStatus, ButtonTooltip, DatatableEmployeeName, Icon } from '../../../components';

const settlementGroupTable = ({ removeAnEmployee, settlements }) => {
  const settlementsToShow = settlements.filter(settlement => settlement._destroy !== true);

  const dataNotFound = () => (
    <tbody>
      <tr className="d-flex justify-content-center text-uppercase">
        <td style={{ color: '#959595' }}>No se encontraron datos.</td>
      </tr>
    </tbody>
  );

  const handleHeadItems = () => (
    <tr>
      <th className="align-middle">Nombre del Trabajador</th>
      <th className="text-center align-middle">Fecha Creación</th>
      <th className="text-center align-middle">Simulación Finiquito</th>
      <th className="text-center align-middle" style={{ maxWidth: '60px' }}>
        Acciones
      </th>
    </tr>
  );

  return (
    <Table responsive>
      {settlementsToShow.length === 0 ? (
        dataNotFound()
      ) : (
        <>
          <thead className="text-uppercase">{handleHeadItems()}</thead>
          <tbody>
            {settlementsToShow.map((settlement, index) => (
              <tr key={`settlement-group-${index.toString()}`}>
                <td className="employee-name">
                  <DatatableEmployeeName
                    item={settlement.employee}
                    name="fullName"
                    fileName="fileInfo"
                    fileUrl="fileUrl"
                  />
                </td>
                <td className="text-center align-middle">dd/mm/aaaa</td>
                <td className="text-center align-middle">
                  <ApprovementStatus status="pending" />
                </td>
                <td className="align-middle">
                  <ButtonTooltip
                    onClick={() => removeAnEmployee(settlement.employee)}
                    toolbarVariant="d-flex justify-content-center"
                    variant="circle-danger"
                    text="Eliminar"
                  >
                    <Icon icon="trash" />
                  </ButtonTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </>
      )}
    </Table>
  );
};

export default settlementGroupTable;
