import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {
  ButtonTooltip,
  EmployeeSearchModal,
  FormikInput,
  FormikSelect,
  FormikTimePicker,
  Icon,
  LinkBtn,
  SimpleCenteredModal
} from '../../components';
import { indexBalancesRequest } from '../../requests/balances';
import { indexEmployeesRequest } from '../../requests/employees';
import { addLabelValue, camelCaseEmptyStringRecursive, groupBy, sortByAttribute } from '../../services/utils';
import ScheduledGroupTable from './ScheduledGroupTable';
import scheduledTypes from './FormOptions';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const ScheduledGroupForm = ({ errors, isSubmitting, setFieldTouched, setFieldValue, touched, values, ...props }) => {
  const { action, onHide, scheduledGroup, submitVariant, formTitle } = props;
  const { scheduledType, scheduledBalancesAttributes, balances: savedBalances } = scheduledGroup;
  const { assetSelector, discountSelector, employeeSelector, scheduledGroup: vScheduledGroup } = values;
  const balancesIsNotEmpty = assetSelector.length > 0 || discountSelector.length > 0;
  const {
    endDate,
    startDate,
    scheduledType: vScheduledType,
    scheduledBalancesAttributes: vScheduledBalancesAttributes
  } = vScheduledGroup;
  const today = new Date();

  const [assets, setAssets] = useState([]);
  const [balances, setBalances] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [selector, setSelector] = useState(selectorTypes);
  const dispatch = useDispatch();

  const selectedEndDate = useMemo(() => {
    if (!endDate || (endDate && endDate instanceof Date)) return endDate;
    const dateArray = endDate.split('/');
    return new Date(dateArray[1], dateArray[0], 1);
  }, [endDate]);

  const selectedStartDate = useMemo(() => {
    if (!startDate || (startDate && startDate instanceof Date)) return startDate;
    const dateArray = startDate.split('/');
    return new Date(dateArray[1], dateArray[0], 1);
  }, [startDate]);

  const findSavedScheduledBalances = dataEmployees => {
    if (action === 'new') return dataEmployees;
    setEmployees([...employees, ...scheduledGroup.employees]);
    const employeeIds = scheduledGroup.employees.map(obj => obj.id);
    const vSelector = dataEmployees.filter(employee => !employeeIds.includes(employee.id));
    if (vSelector.length === dataEmployees.length) return dataEmployees;
    return vSelector;
  };

  const handleSuccessEmployeesRequest = response => {
    const dataEmployees = camelCaseEmptyStringRecursive(response.data.data);
    const addEmployees = findSavedScheduledBalances(dataEmployees);
    setSelector([...selectorTypes, ...addEmployees]);
  };

  const fetchEmployees = () => {
    setOnRequest(true);
    indexEmployeesRequest({
      dispatch,
      params: { active: true, is_dt: false, dotation_today: true, payroll_process: true, paginate: false },
      successCallback: handleSuccessEmployeesRequest,
      callback: () => setOnRequest(false)
    });
  };

  const groupBalances = data => {
    const array = groupBy(data, 'balanceType');
    const keys = ['asset', 'discount'];
    return array.reduce((obj, item) => {
      const key = keys.find(k => (item[k] ? k : undefined));
      return { ...obj, [key]: item[key] };
    }, {});
  };

  const findSavedBalances = () => {
    if (savedBalances.length === 0) return;
    const allBalances = addLabelValue(savedBalances, 'name');
    const balanceGroup = groupBalances(allBalances);
    if (balanceGroup.asset) setFieldValue('assetSelector', balanceGroup.asset);
    if (balanceGroup.discount) setFieldValue('discountSelector', balanceGroup.discount);
    setBalances(allBalances);
  };

  const handleSuccessBalancesRequest = response => {
    if (action === 'edit') findSavedBalances();
    const { data } = camelCaseEmptyStringRecursive(response.data);
    const balanceGroup = groupBalances(addLabelValue(data, 'name'));
    setAssets(balanceGroup.asset);
    setDiscounts(balanceGroup.discount);
  };

  const fetchBalances = () => {
    indexBalancesRequest({
      dispatch,
      params: { actives: true, filter_default_to_show: 'prenomine', sort_column: 'name' },
      successCallback: handleSuccessBalancesRequest
    });
  };

  const handleRemoveValue = ({ index, _destroy, balanceId, employeeId, updateValues = false }) => {
    if (updateValues) {
      let allAttributes = vScheduledBalancesAttributes.filter(sb => sb.employeeId !== employeeId);
      if (balanceId) allAttributes = vScheduledBalancesAttributes.filter(sb => sb.balanceId !== balanceId);
      setFieldValue('scheduledGroup[scheduledBalancesAttributes]', allAttributes);
    } else {
      const fieldValue = `scheduledGroup[scheduledBalancesAttributes][${index}]`;
      setFieldValue(`[${fieldValue}][_destroy]`, _destroy);
    }
  };

  const removeObject = (key, attributeIds, _destroy) => {
    vScheduledBalancesAttributes.forEach((scheduledBalance, index) => {
      const attributeId = attributeIds.find(id => id === scheduledBalance[key]);
      if (attributeId === undefined) return;
      if (scheduledBalance.id) handleRemoveValue({ [key]: attributeId, index, _destroy });
      else handleRemoveValue({ [key]: attributeId, updateValues: true });
    });
  };

  const addOrRemoveBalance = (currentData, prevData, _destroy) => {
    const balanceIds = currentData.map(obj => obj.id);
    const balancesFound = prevData.filter(obj => !balanceIds.includes(obj.id));
    if (balancesFound.length === 0) return;

    const balancesFoundIds = balancesFound.map(obj => obj.id);
    const filterBalances = balances.filter(obj => !balancesFoundIds.includes(obj.id));
    const newBalances = _destroy ? filterBalances : [...balances, ...balancesFound];
    removeObject('balanceId', balancesFoundIds, _destroy);
    setBalances(newBalances);
  };

  const handleBalanceSelector = (fieldName, prevData, currentData) => {
    if (currentData.length > prevData.length) addOrRemoveBalance(balances, currentData, false);
    if (currentData.length < prevData.length) addOrRemoveBalance(currentData, prevData, true);
    setFieldValue(fieldName, currentData);
  };

  const removeAnEmployee = employee => {
    const refreshEmployees = employees.filter(obj => obj.id !== employee.id);
    const addToSelector = [...selector, employee];
    removeObject('employeeId', [employee.id], true);
    setEmployees(refreshEmployees);
    setSelector(addToSelector);
  };

  const addAnEmployee = vSelector => {
    const addEmployee = selector.find(selected => selected.value === employeeSelector);
    removeObject('employeeId', [employeeSelector], false);
    setEmployees([...employees, addEmployee]);
    setSelector(vSelector);
  };

  const addAllEmployees = () => {
    const addEmployees = selector.filter(selected => selected.id !== undefined);
    setEmployees(addEmployees);
    setSelector([]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== employeeSelector);
    if (vSelector.length === selector.length) return;
    if (employeeSelector === 'all_employees') addAllEmployees();
    else addAnEmployee(vSelector);
  };

  const handleSearch = selectedEmployees => {
    setModalShow(false);
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    if (vSelector.length === selector.length) return;
    const addEmployees = selector.filter(selected => selectedEmployees.includes(selected.value));
    setEmployees([...employees, ...addEmployees]);
    setSelector(vSelector);
  };

  const clearEndDate = () => {
    if (vScheduledType !== 'simple') setFieldValue('scheduledGroup[endDate]', '');
  };

  const setModal = (title, body) => {
    setModalTitle(title);
    setModalBody(body);
    setModalShow(true);
  };

  useEffect(fetchEmployees, [scheduledBalancesAttributes]);
  useEffect(fetchBalances, [scheduledGroup]);
  useEffect(clearEndDate, [vScheduledType]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute" style={{ top: '205px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Form>
        <Row className="my-4">
          <Col xs={2} md={1} className="mt-3">
            <LinkBtn block variant="circle-dark" to="/scheduled_groups">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <Col xs={10} md={9} className="mt-3">
            <h2 className="text-uppercase my-1">{formTitle}</h2>
          </Col>
          <Col md={2} className="mt-3">
            <Button type="submit" variant={submitVariant} block disabled={isSubmitting} onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field name="scheduledGroup[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre de Grupo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="scheduledGroup[scheduledType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Programación"
                  placeholder="Seleccionar Tipo"
                  options={scheduledTypes}
                  defaultValue={scheduledType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={5}>
            <Row>
              <Col>
                <Field name="scheduledGroup[startDate]">
                  {({ field }) => (
                    <FormikTimePicker
                      {...field}
                      abbr
                      setLocale
                      minDate={new Date(today.getFullYear(), today.getMonth(), 1)}
                      label="Mes de Inicio"
                      placeholder="mm/aaaa"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      selected={selectedStartDate}
                      onChange={date =>
                        setFieldValue(field.name, moment.isMoment(date) ? date.format('MM/YYYY') : date)
                      }
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              {vScheduledType === 'simple' && (
                <Col>
                  <Field name="scheduledGroup[endDate]">
                    {({ field }) => (
                      <FormikTimePicker
                        {...field}
                        abbr
                        setLocale
                        minDate={new Date(today.getFullYear(), today.getMonth(), 1)}
                        label="Mes de Término"
                        placeholder="mm/aaaa"
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        selected={selectedEndDate}
                        onChange={date =>
                          setFieldValue(field.name, moment.isMoment(date) ? date.format('MM/YYYY') : date)
                        }
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Field name="assetSelector">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  isMulti
                  label="Agregar Haberes"
                  placeholder="Seleccionar Haber"
                  options={assets}
                  defaultMultiValues={assetSelector}
                  onChange={data => handleBalanceSelector(field.name, assetSelector, data || [])}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="discountSelector">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  isMulti
                  label="Agregar Descuentos"
                  placeholder="Seleccionar Descuento"
                  options={discounts}
                  defaultMultiValues={discountSelector}
                  onChange={data => handleBalanceSelector(field.name, discountSelector, data || [])}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col md={4}>
            <Field name="employeeSelector">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Agregar Trabajadores"
                  placeholder="Seleccionar Trabajador"
                  options={sortByAttribute(selector, 'fullName')}
                  defaultValue="all_employees"
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={7} md={2} xl={2}>
            <Button block variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
              Agregar
            </Button>
          </Col>
          <Col xs={5} md={1}>
            <ButtonTooltip
              variant="circle-primary"
              className="advance-search"
              text="Búsqueda Avanzada"
              onClick={() =>
                setModal(
                  'Buscar Empleados',
                  <EmployeeSearchModal
                    customParams={{ active: true }}
                    handleClose={() => setModalShow(false)}
                    formRequest={handleSearch}
                  />
                )
              }
            >
              <Icon className="w-100 h-100" icon="people-circle" />
            </ButtonTooltip>
          </Col>
        </Row>

        <Row className={`table-custom-background mb-5 mx-1 ${balancesIsNotEmpty && 'table-dblock'}`}>
          <ScheduledGroupTable
            balances={balances}
            employees={employees}
            removeAnEmployee={removeAnEmployee}
            scheduledBalances={vScheduledBalancesAttributes}
            scheduledType={vScheduledType}
            setFieldValue={setFieldValue}
          />
        </Row>

        <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
      </Form>
    </>
  );
};

const tranformDate = date => {
  if (!date) return '';
  const dateArray = date.split('/');
  return new Date(dateArray[1], dateArray[0] - 1, 1);
};

const setInitialValues = ({ scheduledGroup }) => {
  const { endDate, startDate } = scheduledGroup;
  return {
    assetSelector: [],
    discountSelector: [],
    employeeSelector: 'all_employees',
    scheduledGroup: {
      ...scheduledGroup,
      endDate: tranformDate(endDate),
      startDate: tranformDate(startDate)
    }
  };
};

const validationSchema = Yup.object().shape({
  scheduledGroup: Yup.object().shape(
    {
      endDate: Yup.date().when('scheduledType', {
        is: val => val === 'simple',
        then: Yup.date()
          .required('Debes seleccionar un mes de término')
          .when(
            'startDate',
            (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual al mes de inicio')
          ),
        otherwise: Yup.date()
      }),
      name: Yup.string().required('Debes ingresar un nombre de grupo'),
      scheduledType: Yup.string().required('Debes seleccionar un tipo'),
      startDate: Yup.date()
        .required('Debes seleccionar un mes de inicio')
        .when(
          'endDate',
          (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
        )
    },
    [['endDate', 'startDate']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ScheduledGroupForm);
