const typeAccounts = [
  { label: 'Patrimonio - Activo', value: 'patrimony_active' },
  { label: 'Patrimonio - Pasivo', value: 'patrimony_passive' },
  { label: 'Resultado - Perdida', value: 'result_loss' },
  { label: 'Resultado - Ganancia', value: 'result_profit' }
];

export const auxiliaries = [
  { label: 'Trabajador', value: 'worker' },
  { label: 'AFP', value: 'afp' },
  { label: 'MUTUAL', value: 'mutual' },
  { label: 'APV', value: 'apv' },
  { label: 'Sin Auxiliar', value: 'without_auxiliary' }
];

export default typeAccounts;
