import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { ButtonTooltip, ComponentDataTable, Icon, SimpleCenteredModal } from '../../../components';
import { indexTicketsAdminRequest } from '../../../requests/adminTickets';
import ReportModal from './ReportModal';
import { downloadFile } from '../../../services/utils';

const TicketDataTable = props => {
  const [tickets, setTickets] = useState();
  const [onRequest, setOnRequest] = useState(true);
  const [showModal, setModalShow] = useState(false);
  const defaultDate = useMemo(moment, []);
  const history = useHistory();
  const dispatch = useDispatch();

  const { columns, moreData, indexUpdateTicket } = props;

  const ensureCallback = () => {
    setOnRequest(false);
    setModalShow(false);
  };

  const handleRequest = async (params, format, responseType = 'json') => {
    setOnRequest(true);
    indexTicketsAdminRequest({
      dispatch,
      params,
      format,
      successCallback: response => {
        if (format === '.xlsx') downloadFile(response);
        else setTickets(response.data.data);
      },
      responseType,
      callback: ensureCallback
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/admin/tickets/${item.id}`);
        break;
      case 'assign_responsible':
        indexUpdateTicket(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'business_name':
        return { sort_companies: 'business_name' };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        pointerOnHover
        columns={columns(handleButtonClick)}
        data={tickets}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        onRequest={onRequest}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        rangePicker
        withStartDate
        withEndDate
        defaultStartDate={defaultDate}
        defaultEndDate={defaultDate}
        withMassActions
        massActions={
          <ButtonTooltip className="mb-2" variant="circle-primary" text="Descargar" onClick={() => setModalShow(true)}>
            <Icon icon="download-2" />
          </ButtonTooltip>
        }
      />
      <SimpleCenteredModal
        title="Generar Reporte"
        body={<ReportModal cancelAction={() => setModalShow(false)} formRequest={handleRequest} />}
        show={showModal}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default TicketDataTable;
