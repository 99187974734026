import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const SelectionProcessInfo = ({ selectionProcess }) => {
  const {
    desiredKnowledge,
    jobDescription,
    jobManagement,
    jobTitle,
    selectionProcessStagesAttributes,
    startDate,
    translatedExpertiseLevel,
    translatedProcessType,
    translatedRemote,
    translatedStatus,
    translatedWorkSchedule,
    vacancies
  } = selectionProcess;

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Card.Title>Información</Card.Title>
            <Row>
              <Col md={12}>
                <Row>
                  <Col>
                    <h5 className="text-uppercase text-dark">Estado:</h5>
                    <p>{translatedStatus}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Tipo de Proceso:</h5>
                    <p>{translatedProcessType}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Fecha de Inicio:</h5>
                    <p>{startDate}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Vacantes:</h5>
                    <p>{vacancies}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">N° de Etapas:</h5>
                    <p>{selectionProcessStagesAttributes.length}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col>
                    <h5 className="text-uppercase text-dark">Tipo de Jornada:</h5>
                    <p>{translatedWorkSchedule}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Modalidad Remota:</h5>
                    <p>{translatedRemote}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Nivel de Experiencia:</h5>
                    <p>{translatedExpertiseLevel}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Área:</h5>
                    <p>{jobManagement.label}</p>
                  </Col>
                  <Col>
                    <h5 className="text-uppercase text-dark">Cargo:</h5>
                    <p>{jobTitle.label}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col md={12}>
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card.Title>Funcionalidades del Cargo</Card.Title>
                <pre>
                  <p className="text-justify">{jobDescription}</p>
                </pre>
              </Col>
              <Col md={6}>
                <Card.Title>Conocimientos Deseables</Card.Title>
                <pre>
                  <p className="text-justify">{desiredKnowledge}</p>
                </pre>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SelectionProcessInfo;
