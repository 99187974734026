import React from 'react';
import { Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Icon } from '../../components';
import './style.scss';

const EvaluationStepFields = ({ evaluationStep, index }) => {
  const { active } = evaluationStep;
  const { setFieldValue } = useFormikContext();

  const mandatory = ['results', 'evaluation', 'competence'].includes(evaluationStep.stepType);

  const handleClick = () => {
    if (!mandatory) {
      setFieldValue(`performanceEvaluation[evaluationStepsAttributes][${index}][active]`, !active);
    }
  };

  return (
    <Col md={3} onClick={handleClick}>
      <div className={`mb-3 step-card-container ${active ? '' : 'disabled'}`}>
        <div className="d-flex pl-2 py-4">
          <p className="text-uppercase mb-0 font-weight-bold">
            {evaluationStep.name} {mandatory ? '*' : ''}
          </p>
          {active && <Icon className="text-primary ml-auto mr-2" width={18} icon="checkmark-circle" />}
        </div>
      </div>
    </Col>
  );
};

export default EvaluationStepFields;
