import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { SimpleCenteredModal } from '../../components';
import { updateBookkeeperDocumentTypeRequest } from '../../requests/bookkeepersDocumentTypes';
import { sendAlert } from '../../actions/utils';
import BookkeeperDocumentTypeDataTable from './BookkeeperDocumentTypeDataTable';
import BookkeeperDocumentTypeForm from './BookkeeperDocumentTypeForm';
import BookkeeperSimpleShow from './BookkeeperSimpleShow';

const BookkeeperDocumentTypeIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo tipo de documento');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = bookkeeperDocumentTypeValue => {
    const {
      bookkeeperDocumentType: { id }
    } = bookkeeperDocumentTypeValue;
    updateBookkeeperDocumentTypeRequest(id, {
      dispatch,
      params: snakeCaseKeys(bookkeeperDocumentTypeValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Tipo de Documento actualizada con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editBookkeeperDocumentType = bookkeeperDocumentTypeRow => {
    setModalShow(true);
    setModalBody(
      <BookkeeperDocumentTypeForm
        bookkeeperDocumentType={camelCaseRecursive(bookkeeperDocumentTypeRow)}
        bookkeeperId={bookkeeperDocumentTypeRow.id}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => setModalShow(false)}
      />
    );
    setModalTitle('Editar Tipo de Documento');
  };

  const showBookkeeperDocumentType = bookkeeperDocumentTypeRow => {
    setModalShow(true);
    setModalBody(<BookkeeperSimpleShow item={bookkeeperDocumentTypeRow} handleClose={() => setModalShow(false)} />);
    setModalTitle('Tipo de Documento');
  };

  return (
    <>
      <BookkeeperDocumentTypeDataTable
        editBookkeeperDocumentType={editBookkeeperDocumentType}
        showBookkeeperDocumentType={showBookkeeperDocumentType}
        moreData={moreData}
        setMoreData={setMoreData}
      />
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default BookkeeperDocumentTypeIndex;
