/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

const DatatableHourMinute = ({ item, dangerPath, hoursPath, minutesPath, onClick }) => {
  const vHours = item?.[hoursPath];
  const vMinutes = item?.[minutesPath];
  const danger = item?.[dangerPath];
  const hours = vHours ? `${vHours} ${vHours === 1 ? 'hora' : 'horas'}` : '';
  return (
    <>
      <p data-tag="allowRowEvents" className={`mb-0 ${danger ? 'text-danger' : ''}`} onClick={onClick}>
        {hours}
        {vHours > 0 && vMinutes > 0 && ' y '}
        {vMinutes > 0 && `${vMinutes} min`}
        {danger && '*'}
      </p>
    </>
  );
};

DatatableHourMinute.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  dangerPath: PropTypes.string,
  hoursPath: PropTypes.string,
  minutesPath: PropTypes.string,
  onClick: PropTypes.func
};

DatatableHourMinute.defaultProps = {
  dangerPath: 'exceed_legal',
  hoursPath: 'hours',
  minutesPath: 'minutes',
  onClick: () => null
};

export default DatatableHourMinute;
