import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon, DatatableEmployeeName } from '../../components';

const firstAction = (item, ability, clickHandler, outOfDate) => {
  switch (item.status) {
    case 'answered':
      return (
        ability.can('show', 'EmployeeSurvey') && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver resultados"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )
      );
    case 'sent':
      return (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'reminder')}
          variant="circle-warning"
          className="btn-circle"
          disabled={outOfDate}
          size="sm"
          text="Recordatorio"
        >
          <Icon icon="notifications" />
        </ButtonTooltip>
      );
    case 'not_sent':
      return (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'sent')}
          variant="circle-primary"
          className="btn-circle"
          disabled={outOfDate}
          size="sm"
          text="Enviar"
        >
          <Icon icon="paper-plane" />
        </ButtonTooltip>
      );
    default:
      return '';
  }
};

const columns = memoize((clickHandler, ability, outOfDate) => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    cell: item => item.employee?.job_management,
    sortable: true,
    grow: '1'
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    cell: item => item.employee?.job_title,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      switch (item.status) {
        case 'not_sent':
          itemColor = 'danger';
          break;
        case 'sent':
          itemColor = 'warning';
          break;
        case 'answered':
          itemColor = 'primary';
          break;
        default:
          itemColor = 'dark';
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {firstAction(item, ability, clickHandler, outOfDate)}
        <ButtonTooltip
          onClick={() => clickHandler(item, 'reset')}
          variant="circle-dark"
          className="btn-circle"
          size="sm"
          disabled={item.status !== 'answered' || ability.cannot('create', 'EmployeeSurvey')}
          text="Eliminar Respuestas"
        >
          <Icon icon="refresh" />
        </ButtonTooltip>
        {ability.can('destroy', 'EmployeeSurvey') && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            disabled={item.enrolled}
            text="Eliminar"
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    grow: '1.5',
    minWidth: '185px',
    right: true
  }
]);

export default columns;
