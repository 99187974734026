import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import CompanyInfo from '../../Shared/Company/CompanyInfo';
import { CompanyTopView } from '../../../components';
import { showCompanyRequest } from '../../../requests/companies';

const basicCompany = {
  id: undefined,
  bankAccountsAttributes: [],
  compensationFund: {},
  economicActivity: {},
  safetyOrganization: {},
  businessLogo: {
    fileUrl: ''
  }
};

const CompanyShow = ({ match }) => {
  const [company, setCompany] = useState(basicCompany);
  const { currentCompany, signedIn } = useSelector(state => state.auth);
  const currentCompanyId = currentCompany.id;
  const dispatch = useDispatch();
  let companyId = match.params.id;

  const handleSuccessShow = response => {
    setCompany(camelCaseRecursive(response.data));
  };

  const fetchCompany = () => {
    if (currentCompany !== '') {
      if (!companyId && currentCompany.id) {
        companyId = currentCompany.id;
      }
      if (companyId && signedIn) {
        showCompanyRequest(companyId, {
          dispatch,
          successCallback: handleSuccessShow
        });
      }
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchCompany, [currentCompanyId, match]);

  return (
    <>
      <CompanyTopView company={company} />
      <CompanyInfo company={company} isProfile />
    </>
  );
};

export default CompanyShow;
