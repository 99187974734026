import ApiService from '../services/apiService';

export const showEmployeeEvaluationRequest = (evaluationId, id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/performance_evaluations/${evaluationId}/employee_evaluations/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const updateEmployeeEvaluationRequest = (evaluationId, id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/performance_evaluations/${evaluationId}/employee_evaluations/${id}`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const destroyEmployeeEvaluationRequest = (evaluationId, id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/performance_evaluations/${evaluationId}/employee_evaluations/${id}`, {
    dispatch,
    successCallback,
    callback
  });
