import React from 'react';
import { Modal } from 'react-bootstrap';

const OvertimeInfo = ({ overtime }) => {
  const renderStatus = status => {
    switch (status) {
      case 'pending':
        return <span className="text-warning">Pendiente</span>;
      case 'approved':
        return <span className="text-primary">Aprobado</span>;
      case 'rejected':
        return <span className="text-danger">Rechazado</span>;
      default:
        return <span className="text-info">-</span>;
    }
  };

  return (
    <Modal.Body>
      <div className="info-box">
        <p className="info">
          <span>Fecha de Solicitud:</span> {overtime.date}
        </p>
        <p className="info">
          <span>Horas Extra:</span>{' '}
          <span className={overtime.exceedLegal ? 'text-danger' : 'text-dark'}>
            {overtime.hours} hrs.{overtime.minutes} mins.
          </span>
        </p>
        <p className="info">
          <span>Valor Hora</span> {overtime.translatedHourValue}
        </p>
        <p className="info">
          <span>Estado:</span> {renderStatus(overtime.status)}
        </p>
        {overtime.observation && (
          <p className="info">
            <span>Motivo de Rechazo:</span> {overtime.observation}
          </p>
        )}
      </div>
    </Modal.Body>
  );
};

export default OvertimeInfo;
