import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  BasicTextArea,
  FormikCheckBox,
  FormikInput,
  FormikNumber,
  FormikSelect,
  FormikTimePicker,
  InputSelect
} from '../../../components';
import paymentTypes from './FormOptions';
import { indexSelectEmployeesRequest, showEmployeeRequest } from '../../../requests/employees';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { useAuthorization } from '../../../services/hooks';

const AdvancePaymentForm = ({
  errors,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  employee,
  ...props
}) => {
  const [advanceStartMonth, setAdvanceStartMonth] = useState('');
  const { advanceSettings } = useSelector(state => state.utils);
  const [advanceEndMonth, setAdvanceEndMonth] = useState('');
  const dispatch = useDispatch();
  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, filter_name: inputValue, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  const { action, advancePayment, cancelAction, onHide, onlyShow, reviewerSignature, rrhh, employeeId } = props;
  const { amount, payday, paymentType } = values.advancePayment;
  const message = action === 'items' ? 'Asignar' : 'Confirmar';
  const btnMessage = action === 'new' ? 'Solicitar' : message;
  const isDisabled = action === 'edit' && !payday;
  const today = new Date();
  const [currentSelectedEmployee, setCurrentSelectedEmployee] = useState({});
  const canShowEmployee = useAuthorization('record_card', 'Employee');
  const canShowContract = useAuthorization('contract_card', 'Contract');

  const getEmployee = selectedEmployeeId => {
    if (selectedEmployeeId !== 'undefined' && canShowEmployee && canShowContract) {
      showEmployeeRequest(selectedEmployeeId, {
        dispatch,
        successCallback: response => setCurrentSelectedEmployee(camelCaseEmptyStringRecursive(response.data))
      });
    }
  };

  const apAmountLimitCalculation = () => {
    // Si no hay nadie seleccionado significa que se debe buscar la info del employee que se le pase al formulario
    if (Object.keys(currentSelectedEmployee).length === 0 && typeof employeeId !== 'undefined') {
      getEmployee(employeeId);
    }

    const advancePaymentLimitPercentage = advanceSettings.find(
      advanceSetting => advanceSetting.code === 'advance_payment_percentage_limit'
    );
    const advancePaymentAmountLimit = advanceSettings.find(
      advanceSetting => advanceSetting.code === 'advance_payment_amount_limit'
    );
    let amountLimit = 0;
    if (
      advancePaymentLimitPercentage.textValue === 'true' &&
      advancePaymentAmountLimit.textValue === 'false' &&
      typeof currentSelectedEmployee.contract !== 'undefined'
    ) {
      if ('contract' in currentSelectedEmployee && Object.keys(currentSelectedEmployee.contract).length > 1) {
        const percentage = advancePaymentLimitPercentage.integerValue;
        const advancePaymentAmountPercentageLimit = (currentSelectedEmployee.contract.salary * percentage) / 100;
        amountLimit = advancePaymentAmountPercentageLimit;
      }
    } else {
      amountLimit = advancePaymentAmountLimit.integerValue;
    }
    setFieldValue('advancePayment[apAmountLimit]', amountLimit);
  };

  const handleEmployeeSelection = selectedEmployeeId => {
    getEmployee(selectedEmployeeId);
  };

  useEffect(apAmountLimitCalculation, [currentSelectedEmployee.id]);

  return (
    <Form className="mx-3">
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      {(action === 'new' || action === 'items') && (
        <>
          <Row>
            {rrhh && (
              <Col md={12}>
                <Field name="advancePayment[employeeId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Trabajador"
                      placeholder="Seleccionar Trabajador"
                      values={values}
                      model={[advancePayment, 'employee']}
                      request={fetchEmployees}
                      onChange={data => {
                        handleEmployeeSelection(data ? data.value : '');
                        return setFieldValue(field.name, data ? data.value : '');
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
            <Col md={6}>
              <Field name="advancePayment[parsedAmount]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    fieldName="advancePayment[amount]"
                    value={amount}
                    label="Monto"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    leftAddon="$"
                    disabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="advancePayment[paymentType]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Opción de Pago"
                    placeholder="Seleccionar Opción de Pago"
                    options={paymentTypes}
                    defaultValue={paymentType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="advancePayment[startDate]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    abbr
                    setLocale
                    minDate={rrhh ? true : new Date(today.getFullYear(), today.getMonth(), 1)}
                    label="Mes de Inicio"
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={advanceStartMonth}
                    onChange={date => {
                      setAdvanceStartMonth(date);
                      setFieldTouched(field.name);
                      setFieldValue(field.name, date);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="advancePayment[endDate]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    setLocale
                    minDate={rrhh ? true : new Date(today.getFullYear(), today.getMonth(), 1)}
                    label="Mes de Término"
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={advanceEndMonth}
                    onChange={date => {
                      setAdvanceEndMonth(date);
                      setFieldValue(field.name, date);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={onlyShow || values.advancePayment.permanent}
                  />
                )}
              </Field>
            </Col>
            {action === 'new' && (
              <>
                <Col md={12}>
                  <Field name="advancePayment[reason]">
                    {({ field }) => (
                      <BasicTextArea
                        {...field}
                        label="Motivo"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={onlyShow}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Field name="advancePayment[permanent]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        field={field}
                        onClick={e => {
                          if (e.target.checked) {
                            setFieldValue('advancePayment[endDate]', '');
                          }
                        }}
                        label="Anticipo permanente"
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
      {(action === 'edit' || action === 'items') && (
        <>
          {!employee && (
            <Row>
              <Col md={7}>
                <Field name="advancePayment[payday]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Día de Pago"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={5} className="mt-md-4 pt-md-2">
                <Field name="advancePayment[permanent]">
                  {({ field }) => <FormikCheckBox {...field} field={field} label="Anticipo permanente" margin="mb-0" />}
                </Field>
              </Col>
            </Row>
          )}
          {payday && reviewerSignature && (
            <>
              <Row>
                <Col md={12}>
                  <Field name="advancePayment[signatureKey]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Pin de Firma"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Row className="d-flex justify-content-end mt-3 mb-2">
        {action === 'edit' && (
          <Col xs={6} sm={4}>
            <Button block variant="outline-info" onClick={cancelAction} disabled={isSubmitting}>
              Cancelar
            </Button>
          </Col>
        )}
        {!onlyShow && (
          <Col xs={6} sm={4}>
            <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting || isDisabled}>
              {btnMessage}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { advancePayment, action, reviewerSignature, apAmountLimit, nextMonth, employeeId } = props;
  const {
    id,
    amount,
    directApprove,
    endDate,
    name,
    observation,
    payday,
    paymentType,
    permanent,
    reason,
    signatureKey,
    startDate
  } = advancePayment;
  let newObservation = observation;
  if (action === 'items') {
    newObservation = 'Solicitado desde RRHH';
  }
  return {
    advancePayment: {
      id,
      amount,
      apAmountLimit,
      directApprove,
      employeeId,
      endDate,
      name,
      nextMonth,
      observation: newObservation,
      payday,
      paymentType,
      permanent,
      reason,
      reviewerSignature,
      signatureKey,
      startDate
    }
  };
};

const today = new Date();
const lastMonth = new Date(today.getFullYear(), today.getMonth(), -1);

const validationSchema = Yup.object().shape({
  advancePayment: Yup.object().shape(
    {
      amount: Yup.number()
        .required('Debes ingresar un monto')
        .positive('Debe ser mayor a 0')
        .when('apAmountLimit', (apAmountLimit, schema) =>
          apAmountLimit !== 0 && apAmountLimit !== undefined && apAmountLimit != null
            ? schema.max(
                apAmountLimit,
                `Monto máximo: ${apAmountLimit.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}`
              )
            : schema.nullable()
        ),
      employeeId: Yup.string().required('Debes seleccionar un trabajador'),
      endDate: Yup.date().when(
        'startDate',
        (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual al mes de inicio')
      ),
      name: Yup.string().nullable(),
      payday: Yup.number().when('id', {
        is: val => val,
        then: Yup.number()
          .typeError('Debes ingresar un día de pago')
          .required('Debes ingresar un día de pago')
          .positive('Debe ser mayor a 0')
          .max(31, 'Debe ser menor o igual a 31'),
        otherwise: Yup.number().nullable()
      }),
      paymentType: Yup.string().required('Debes seleccionar una opción de pago'),
      permanent: Yup.boolean(),
      reason: Yup.string().nullable(),
      signatureKey: Yup.string().when('reviewerSignature', {
        is: val => val,
        then: Yup.string().required('Debes ingresar el pin'),
        otherwise: Yup.string().nullable()
      }),
      startDate: Yup.date()
        .required('Debes seleccionar un mes de inicio')
        .min(lastMonth, 'Debes seleccionar una fecha mayor o igual a la fecha actual.')
        .when(
          'endDate',
          (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
        )
    },
    [['endDate', 'startDate']]
  )
});

const editSchema = Yup.object().shape({
  advancePayment: Yup.object().shape({
    payday: Yup.number()
      .typeError('Debes ingresar un día de pago')
      .required('Debes ingresar un día de pago')
      .positive('Debe ser mayor a 0')
      .max(31, 'Debe ser menor o igual a 31'),
    permanent: Yup.boolean(),
    signatureKey: Yup.string().when('reviewerSignature', {
      is: val => val,
      then: Yup.string().required('Debes ingresar el código'),
      otherwise: Yup.string().nullable()
    })
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema: props => (props.action === 'edit' ? editSchema : validationSchema),
  handleSubmit,
  enableReinitialize: true
})(AdvancePaymentForm);
