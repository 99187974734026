import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DE PROGRAMA',
    selector: 'name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'PERÍODO',
    selector: 'period',
    cell: item => item.translated_period,
    sortable: true,
    grow: '1'
  },
  // {
  //   name: 'DEPARTAMENTO',
  //   selector: '',
  //   sortable: true,
  //   grow: '1'
  // },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      switch (item.status) {
        case 'pending':
          itemColor = 'warning';
          break;
        case 'finished':
          itemColor = 'primary';
          break;
        default:
          itemColor = 'warning';
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'edit')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Dnc"
        clickHandler={clickHandler}
        withShow={false}
        withEdit
        withDestroy={false}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
