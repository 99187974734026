import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Accordion } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { LinkBtn, SimpleCenteredModal, Icon } from '../../components';
import { showContractVersionRequest } from '../../requests/contractVersions';
import basicContractVersion from './contractVersion';
import ContractVersionDiffModal from './ContractVersionDiffModal';
import ContractInfoContent from '../../components/Employee/EmployeeShow/ContractInfoContent';

const ContractVersionShow = ({ match, location }) => {
  const [contractVersion, setContractVersion] = useState(basicContractVersion);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const versionId = match.params.id;
  const contractId = new URLSearchParams(location.search).get('resource');
  const employeeId = new URLSearchParams(location.search).get('employee');

  const fetchVersion = () => {
    showContractVersionRequest(versionId, {
      dispatch,
      contractId,
      successCallback: response => setContractVersion(camelCaseRecursive(response.data))
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchVersion, [versionId]);

  const { employee } = contractVersion;

  return (
    <>
      <Row className="mt-4">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to={`/employees/${employeeId}?tab=contract`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">{employee.fullName}</h2>
          <div className="d-flex subtitle-simple-modal">
            <p className="text-capitalize mb-0 mt-0">{employee.jobTitle}</p>
            {employee.jobTitle && employee.email && <p className="ml-1 mr-1">|</p>}
            <p>{employee.email}</p>
          </div>
        </Col>
        <Col md={2}>
          <Button variant="warning" block onClick={() => setModalShow(true)}>
            Ver Cambios
          </Button>
        </Col>
      </Row>
      <Row className="mb-4 info-block info-block-warning">
        <Col>
          <h4 className="text-uppercase mt-0 mb-0">
            Estás viendo una versión antigua del contrato de {employee.fullName}
          </h4>
        </Col>
        <Col md={2}>
          <LinkBtn variant="warning inverse-hover" block to={`/employees/${employeeId}?tab=contract`}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <div className="d-flex align-items-center mt-3">
        <h3 className="text-uppercase mb-0 mr-1">
          Contrato {contractVersion.activeContract ? 'Vigente' : 'Finiquitado'}
        </h3>
        <p className="text-capitalize mb-0 ml-1">Versión {contractVersion.versionNumber}</p>
      </div>
      <Accordion defaultActiveKey="0" className="ml-2">
        <Row className="mb-3">
          <Col>
            <ContractInfoContent contract={contractVersion.oldContract} />
          </Col>
        </Row>
      </Accordion>
      <SimpleCenteredModal
        title={`Contrato ${contractVersion.correlativeIdentification}`}
        body={<ContractVersionDiffModal contractVersion={contractVersion} hideModal={() => setModalShow(false)} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ContractVersionShow;
