import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { showDocumentTemplateRequest, updateDocumentTemplateRequest } from '../../requests/documentTemplates';
import { sendAlert } from '../../actions/utils';
import TemplateForm from './DocumentTemplateForm';

class DocumentTemplateEdit extends Component {
  state = {
    documentTemplate: {
      body: '',
      description: '',
      name: '',
      orientation: '',
      size: '',
      templateType: ''
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { match, dispatch } = this.props;
    const documentTemplateId = match.params.id;
    showDocumentTemplateRequest(documentTemplateId, {
      dispatch,
      successCallback: this.handleSuccessShow
    });
  }

  handleSuccessShow = response => {
    const documentTemplate = camelCaseRecursive(response.data);
    this.setState({ documentTemplate });
  };

  handleUpdateRequest = documentTemplate => {
    const { dispatch } = this.props;
    const documentTemplateId = documentTemplate.documentTemplate.id;
    const activeTab = documentTemplate.documentTemplate.templateType;

    updateDocumentTemplateRequest(documentTemplateId, {
      dispatch,
      params: snakeCaseKeys(documentTemplate),
      formData: true,
      successCallback: this.handleSuccessUpdate(activeTab)
    });
  };

  handleSuccessUpdate = activeTab => {
    const { history, dispatch } = this.props;
    dispatch(sendAlert({ kind: 'success', message: 'Plantilla actualizada con éxito' }));
    history.push(`/document_templates`, { activeTab });
  };

  render() {
    const { documentTemplate } = this.state;
    return (
      <>
        <Row className="mt-3 mb-3">
          <Col>
            <Row className="mt-2">
              <Col>
                <h2 className="text-uppercase mb-0">Plantillas</h2>
              </Col>
            </Row>
            <TemplateForm
              documentTemplate={documentTemplate}
              action="edit"
              submitVariant="primary"
              formRequest={this.handleUpdateRequest}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { company } = state.auth;
  return { company };
};

export default connect(mapStateToProps)(DocumentTemplateEdit);
