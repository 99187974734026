import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, ApprovementStatus, Icon, ShiftChangeActions } from '../../../components';

const approved = clickHandler => [
  {
    name: 'APROBAR',
    cell: item => (
      <ShiftChangeActions resource="ShiftChangeRequest" item={item} clickHandler={clickHandler} approveText="Aprobar" />
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false
  }
];
const shiftChangeColumns = memoize((clickHandler, ability, rrhh) => [
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'SOLICITADO POR',
    selector: 'employee',
    cell: item => item.employee.full_name,
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'TURNO ACTUAL',
    selector: 'shift',
    cell: item => item.shift.name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'REEMPLAZAR CON',
    selector: 'employee_replacement',
    cell: item => item.employee_replacement.full_name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'TURNO A REEMPLAZAR',
    selector: 'shift',
    cell: item => item.shift_replacement.name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA REEMPLAZO',
    selector: 'replacement_date',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    sortable: true,
    grow: '1'
  },
  ...(ability.can('shift_change_action', 'ShiftChangeRequest') && !rrhh ? approved(clickHandler) : []),
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {ability.can('show', 'ShiftChangeRequest') && (
          <ButtonTooltip
            variant="circle-info"
            className="btn-circle"
            onClick={() => clickHandler(item, 'show')}
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
        {ability.can('destroy', 'ShiftChangeRequest') && (
          <ButtonTooltip
            ButtonTooltip
            variant="circle-danger"
            className="btn-circle"
            onClick={() => clickHandler(item, 'destroy')}
            disabled={item.status !== 'pending'}
            text="Eliminar"
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '100px'
  }
]);

export default shiftChangeColumns;
