const civilStates = [
  { label: 'Soltero', value: 'single' },
  { label: 'Casado', value: 'married' },
  { label: 'Viudo', value: 'widower' },
  { label: 'Union Civil', value: 'civil_union_agreement' },
  { label: 'Divorciado', value: 'divorced' }
];

const educationalLevels = [
  { label: 'Sin Colegio', value: 'no_school' },
  { label: 'Básica Incompleta', value: 'basic_incomplete' },
  { label: 'Básica Completa', value: 'basic_complete' },
  { label: 'Media Incompleta', value: 'media_incomplete' },
  { label: 'Media Completa', value: 'media_complete' },
  { label: 'Técnico Incompleto', value: 'technical_incomplete' },
  { label: 'Técnico Completo', value: 'technical_complete' },
  { label: 'Universitaria Incompleta', value: 'university_incomplete' },
  { label: 'Universitaria Completa', value: 'university_complete' }
];

const candidateTypes = [
  { label: 'Interno', value: 'internal' },
  { label: 'Externo', value: 'external' }
];

const identificationTypes = [
  { label: 'RUT', value: 'rut' },
  { label: 'Pasaporte', value: 'passport' },
  { label: 'Otra Identificación', value: 'other_id' }
];

export { candidateTypes, civilStates, educationalLevels, identificationTypes };
