import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormikMaterialUiTimePicker } from '../../components';
import { delayMethod } from '../../services/utils';

const DateForm = ({
  cancelRequest,
  confirmRequest,
  date,
  defEndTime,
  defStartTime,
  destroy,
  destroyRequest,
  id,
  oldTitle
}) => {
  const [startTime, setStartTime] = useState(defStartTime);
  const [endTime, setEndTime] = useState(defEndTime);
  const disabled = startTime === endTime;
  const params = { date, startTime, endTime, id, oldTitle, title: `${startTime} - ${endTime}` };
  const [error, setError] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  const checkStartTime = currentTime => {
    if (currentTime === endTime) {
      setError('Hora de inicio no puede ser igual que la de termino');
      return setStartTime(currentTime);
    }
    setError('');
    return setStartTime(currentTime);
  };
  const checkEndtime = currentTime => {
    if (currentTime === startTime) {
      setError('Hora de termino no puede ser igual que la de inicio');
      return setEndTime(currentTime);
    }
    setError('');
    return setEndTime(currentTime);
  };

  return (
    <>
      <Modal.Body>
        <Row>
          <Col xs={12} md={6}>
            <FormikMaterialUiTimePicker
              abbr
              label="Desde"
              timeSelector
              defaultValue={startTime}
              onChange={checkStartTime}
            />
            <p className="text-danger">{error}</p>
          </Col>
          <Col xs={12} md={6}>
            <FormikMaterialUiTimePicker
              abbr
              label="Hasta"
              timeSelector
              defaultValue={endTime}
              onChange={checkEndtime}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(cancelRequest)}>
          Cancelar
        </Button>
        {destroy && (
          <Button className="text-center" variant="danger" onClick={() => destroyRequest(params)}>
            Eliminar
          </Button>
        )}
        <Button type="submit" disabled={disabled} variant="primary" onClick={() => confirmRequest(params)}>
          Agregar
        </Button>
      </Modal.Footer>
    </>
  );
};

DateForm.propTypes = {
  confirmRequest: PropTypes.func.isRequired,
  cancelRequest: PropTypes.func.isRequired,
  defStartTime: PropTypes.string,
  defEndTime: PropTypes.string,
  destroy: PropTypes.bool,
  oldTitle: PropTypes.string
};

DateForm.defaultProps = {
  defStartTime: '09:00',
  defEndTime: '18:00',
  destroy: false,
  oldTitle: ''
};

export default DateForm;
