import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createAdminCompanyRequest } from '../../../requests/adminCompanies';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import CompanyForm from '../../Shared/Company/CompanyForm';
import basicCompany from './company';

const CompanyNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSuccessCreate = response => {
    const { data } = response;
    dispatch(sendAlert({ kind: 'success', message: 'Empresa creada con éxito' }));
    history.push(`/admin/companies/${data.id}`);
  };

  const handleCreateRequest = (companyValues, setSubmitting) => {
    const { logo } = companyValues.company;
    const myParams = snakeCaseKeys(companyValues);
    myParams.company.logo = logo;

    companyValues.company.representantsAttributes.forEach(
      (value, index) => {
        myParams.company.representants_attributes[index].image = value.image !== "" ? value.image : null;
      }
    );

    createAdminCompanyRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/admin/companies">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Nueva Empresa</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <CompanyForm
            company={basicCompany}
            action="new"
            submitVariant="success"
            formRequest={handleCreateRequest}
            admin
          />
        </Col>
      </Row>
    </>
  );
};

export default CompanyNew;
