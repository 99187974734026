import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { legalreportOffdaysReport } from '../../requests/legalreports';
import { ComponentDataTable } from '../index';

const OffdaysFilterDataTable = ({ customParams, moreData, columns }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [offdays, setOffdays] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    setOffdays(response.data);
    setOnRequest(false);
  };

  const handleOffdayReportRequest = params => {
    if (offdays.length > 0) {
      return;
    }
    setOnRequest(true);
    const request = async () =>
      legalreportOffdaysReport({
        dispatch,
        params: { ...params, ...customParams },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={offdays}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleOffdayReportRequest}
        pointerOnHover
        preName="offdays"
        noDataComponent="la jornada de este trabajador no incluye domingos y festivos"
        withMassActions
        withSearch={false}
        pagination
        paginationServer={false}
      />
    </>
  );
};

export default OffdaysFilterDataTable;
