import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Form, getIn, Field } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import {
  Calendar,
  DefaultModal,
  FormikCheckBox,
  FormikInput,
  FormikMaterialUiTimePicker,
  FormikNumber,
  SimpleCenteredModal
} from '../../components';
import DateForm from './DateForm';
import ShiftContractsForm from './ShiftContractsForm';

const ShiftMonth = ({ values, errors, touched, setFieldValue, isSubmitting, onHide, shift }) => {
  const [modalShow, setModalShow] = useState(false);
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [events, setEvents] = useState([]);

  const [dragEvents, setDragEvents] = useState([]);

  const settleDragEvents = (newEvents, newEvent) => {
    if (newEvents.some(item => item.title === newEvent.title)) return;
    let newDragEvents = newEvents;
    if (newDragEvents.length >= 3) {
      newDragEvents = [dragEvents[1], dragEvents[2], newEvent];
    } else {
      newDragEvents.push(newEvent);
    }
    setDragEvents(newDragEvents);
  };

  const validEvent = newEvent => !events.some(item => item.title === newEvent.title && item.date === newEvent.date);

  const handleNewDate = event => {
    setModalShow(false);
    const newEvent = { ...event, send: true };
    if (!newEvent.startTime || !newEvent.endTime) return;
    const currentDays = values.shift.shiftDaysAttributes;
    if (validEvent(newEvent)) {
      setEvents([...events, { title: newEvent.title, date: newEvent.date }]);
      setFieldValue('shift[shiftDaysAttributes]', [...currentDays, newEvent]);
      settleDragEvents(dragEvents, newEvent);
    } else {
      setDefaultModalShow(true);
    }
  };

  const setValidEvents = eventArray => {
    if (!eventArray) return [];
    return eventArray.filter(item => item.send).map(item => ({ title: item.title, date: item.date, id: item.id }));
  };

  const handleUpdateDate = event => {
    let updateIndex;
    const newEvent = { ...event, send: true };
    setModalShow(false);
    const currentDays = values.shift.shiftDaysAttributes;
    if (newEvent.id && newEvent.id !== 'null' && newEvent.id !== 'undefined') {
      updateIndex = currentDays.findIndex(item => item.id === parseInt(newEvent.id, 10));
      newEvent.id = parseInt(newEvent.id, 10);
    } else {
      updateIndex = currentDays.findIndex(item => item.date === newEvent.date && item.title === newEvent.oldTitle);
      delete newEvent.id;
    }
    if (validEvent(newEvent)) {
      currentDays[updateIndex] = newEvent;
      setEvents(setValidEvents(currentDays));
      setFieldValue('shift[shiftDaysAttributes]', currentDays);
      settleDragEvents(dragEvents, newEvent);
    } else {
      setDefaultModalShow(true);
    }
  };

  const destroyEvent = ({ id, date, title }) => {
    let currentDays;
    const { shiftDaysAttributes } = values.shift;
    setModalShow(false);
    if (id && id !== 'null' && id !== 'undefined') {
      currentDays = shiftDaysAttributes;
      const destroyIndex = currentDays.findIndex(item => item.id === parseInt(id, 10));
      currentDays[destroyIndex] = { ...currentDays[destroyIndex], send: false };
    } else {
      currentDays = shiftDaysAttributes.filter(item => !(item.date === date && item.title === title));
    }
    setEvents(setValidEvents(currentDays));
    setFieldValue('shift[shiftDaysAttributes]', currentDays);
  };

  const handleDateClick = dateParams => {
    setModalBody(
      <DateForm date={dateParams.dateStr} confirmRequest={handleNewDate} cancelRequest={() => setModalShow(false)} />
    );
    setModalShow(true);
  };

  const onDropEvent = ({ dateStr, draggedEl }) => {
    const { firstChild } = draggedEl;
    const [startTime, endTime] = firstChild.data.replace(/\s/g, '').split('-');
    const { shiftDaysAttributes } = values.shift;
    const newEvent = { title: firstChild.data, startTime, endTime, date: dateStr, send: true };
    if (validEvent(newEvent)) {
      setEvents(setValidEvents([...shiftDaysAttributes, newEvent]));
      setFieldValue('shift[shiftDaysAttributes]', [...shiftDaysAttributes, newEvent]);
    } else {
      setDefaultModalShow(true);
    }
  };

  const eventClick = ({ event }) => {
    const {
      _def: eventDrop,
      _instance: { range }
    } = event;
    const [startTime, endTime] = eventDrop.title.replace(/\s/g, '').split('-');
    setModalBody(
      <DateForm
        id={eventDrop.publicId}
        date={range.start.toISOString().slice(0, 10)}
        confirmRequest={handleUpdateDate}
        defStartTime={startTime}
        defEndTime={endTime}
        cancelRequest={() => setModalShow(false)}
        destroyRequest={destroyEvent}
        oldTitle={eventDrop.title}
        destroy
      />
    );
    setModalShow(true);
  };

  useEffect(() => {
    setEvents(shift.shiftDaysAttributes.map(item => ({ id: item.id, title: item.title, date: item.date })));
  }, [shift]);

  return (
    <>
      <Form>
        <Row>
          <Col md={6}>
            <Field name="shift[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  placeholder="NOMBRE DE TURNO"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[startLunch]">
              {({ field }) => (
                <FormikMaterialUiTimePicker
                  {...field}
                  abbr
                  timeSelector
                  label="Inicio de Colación"
                  onChange={time => setFieldValue(field.name, time)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[endLunch]">
              {({ field }) => (
                <FormikMaterialUiTimePicker
                  {...field}
                  abbr
                  timeSelector
                  label="Termino de Colación"
                  onChange={time => setFieldValue(field.name, time)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[parsedTolerance]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  label="Tolerancia"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="shift[tolerance]"
                  value={values.shift.tolerance}
                  tooltipSize="21"
                  tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={6} md={3} className="d-flex align-items-center">
            <Field name="shift[workHolidays]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="TRABAJA FESTIVOS"
                  tooltipSize="21"
                  tooltipText="Turno con días festivos incluídos"
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={3} className="d-flex align-items-center">
            <Field name="shift[lunchCheck]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="MARCAR COLACIÓN"
                  tooltipSize="21"
                  tooltipText="Indica si deben marcar el ingreso y salida de colación"
                />
              )}
            </Field>
          </Col>
        </Row>
        <hr />
        <Calendar
          dateClick={handleDateClick}
          events={events}
          drop={onDropEvent}
          eventClick={eventClick}
          dragEvents={dragEvents}
        />
        <hr />
        <ShiftContractsForm shift={shift} />
        <Row className="d-flex justify-content-end my-3">
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting} variant="primary" block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <SimpleCenteredModal
        title="Agregar Horario"
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <DefaultModal
        title="Turno Duplicado"
        body="El turno que agregaste ya existe para este día."
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        variantBtnClose="outline-info"
        titleBtnClose="Aceptar"
        withConfirm={false}
      />
    </>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = [];
  if (shiftDaysAttributes.length) {
    shiftDays = shiftDaysAttributes.map(item => ({ ...item, send: true }));
  }

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'monthly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape({
    lunchCheck: Yup.boolean(),
    workHolidays: Yup.boolean(),
    tolerance: Yup.number()
      .required('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0'),
    startLunch: Yup.string().required('Debes ingresar una hora de inicio'),
    endLunch: Yup.mixed()
      .required('Debes ingresar una hora de término')
      .test('from-less-than-to', 'Hora de término no puede ser inferior al horario de inicio', function(value) {
        const dayStr = moment().format('DD/MM/YYYY');
        return moment(`${dayStr} ${value}`, 'DD/MM/YYYY HH:mm').isAfter(
          moment(`${dayStr} ${this.parent.startLunch}`, 'DD/MM/YYYY HH:mm')
        );
      }),
    name: Yup.string().required('Debes ingresar un nombre'),
    shiftDaysAttributes: Yup.array().of(
      Yup.object().shape({
        endTime: Yup.string().required('Debes ingresar hora de término'),
        startTime: Yup.string().required('Debes ingresar hora de inicio')
        // date: Yup.date()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftMonth);
