import React, { useState } from 'react';
import { useAbility } from '@casl/react';
import { SimpleCenteredModal } from '../../../components';
import BenefitModalShow from './BenefitModalShow';
import BenefitEdit from '../../Benefit/BenefitEdit';
import { AbilityContext } from '../../../config/abilityContext';
import CardImage from '../../../components/Utils/Cards/CardImage';
import BenefitReminder from '../../Benefit/BenefitReminder';

const BenefitCard = ({ benefits, rrhh, moreData, setMoreData, location }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const ability = useAbility(AbilityContext);
  const canEditBenefit = ability.can('update', 'Benefit');
  const image = benefits.benefitsImage.fileUrl;

  const modalShowInfo = benefit => {
    setModalTitle('');
    setModalShow(true);
    setModalBody(<BenefitModalShow benefit={benefit} />);
  };

  const modalShowEdit = benefit => {
    setModalTitle('Editar Beneficio');
    setModalShow(true);
    setModalBody(
      <BenefitEdit
        id={benefit.id}
        moreData={moreData}
        setMoreData={setMoreData}
        hideModal={() => setModalShow(false)}
      />
    );
  };

  const modalReminder = benefit => {
    setModalTitle('Nofiticación de Beneficio');
    setModalShow(true);
    setModalBody(<BenefitReminder benefit={benefit} hideModal={() => setModalShow(false)} />);
  };

  return (
    <>
      <CardImage
        classNameDescription="b-description-dash"
        item={benefits}
        image={image}
        location={location}
        rrhh={rrhh}
        canEdit={canEditBenefit}
        showInfo={modalShowInfo}
        showEdit={modalShowEdit}
        resend={modalReminder}
      />
      <SimpleCenteredModal
        size="xl"
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default BenefitCard;
