import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';

import { showProfileRequest, updateProfileRequest } from '../../requests/profiles';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import RoleForm from './RoleForm';
import basicProfile from './role';
import { AbilityContext } from '../../config/abilityContext';
import { updateAbility } from '../../config/ability';

const RoleEdit = ({ match }) => {
  const [profile, setProfile] = useState(basicProfile);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const profileId = match.params.id;
  const ability = useAbility(AbilityContext);

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const requestRole = () => {
    const request = async () => {
      showProfileRequest(profileId, {
        dispatch,
        successCallback: response => {
          setProfile(camelCaseRecursive(response.data));
          setOnRequest(false);
        },
        failureCallback: error => {
          handleFailureRequest(error);
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const handleUpdate = values => {
    updateProfileRequest(profileId, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: `Perfil actualizado con éxito` }));
        updateAbility(ability);
        history.push(`/roles`);
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(requestRole, []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/roles">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Editar Perfil</h2>
        </Col>
      </Row>
      <RoleForm
        profile={profile}
        onRequest={onRequest}
        action="edit"
        submitVariant="success"
        formRequest={handleUpdate}
      />
    </>
  );
};

export default RoleEdit;
