/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { DatatableEmployeeName } from '../../components/index';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee_full_name',
    cell: item => (
      <DatatableEmployeeName
        item={item}
        fileName="employee_file_info"
        name="employee_full_name"
        onClick={() => clickHandler(item, 'show')}
      />
    ),
    sortable: true,
    grow: '3'
  },
  {
    name: 'RUT',
    selector: 'employee_rut',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'HORA',
    selector: 'time',
    cell: item => item.complete_parsed_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'time_type',
    cell: item => item.translated_time_type,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'MODALIDAD',
    selector: 'translated_device_type',
    cell: item => (item.translated_device_type ? item.translated_device_type : item.translated_marking_type),
    sortable: false,
    grow: '2',
    center: true
  }
]);

export default columns;
