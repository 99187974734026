export default {
  numLicense: '',
  companyName: '',
  companyRut: '',
  companyPhone: {
    phone: ''
  },
  companyAddress: {
    street: '',
    number: '',
    commune: '',
    country: ''
  },
  companyFullAddress: '',
  codeRegionCompinId: '',
  codeCommuneCompin: '',
  codeWorkActivity: '',
  codeOcupation: '',
  otherOcupation: '',
  previsionDateReceptionCcaf: '',
  codeTypePensionScheme: '',
  codePensionScheme: '',
  codeLetterBox: '',
  previsionName: '',
  codeWorkerQuality: '',
  codeSafeAfc: '',
  codeSafeUndefined: '',
  affiliationDate: '',
  dateContract: '',
  codePayingEntity: '',
  previsionPayerName: '',
  remuneration: [],
  evictionPercentage: 0,
  taxableAmountPreviousMonth: 0,
  havePreviousLicense: false,
  previousLicense: [],
  processingCodeCCAF: ''
};
