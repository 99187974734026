import React, { useRef } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { useFormikContext, getIn, Field } from 'formik';
import { ButtonTooltip, Icon, FormikSelect } from '../../components';
import QuestionChoicesFields from '../Survey/QuestionChoicesFields';

const LikertFields = ({ valuePath, skillAttribute, handleClose }) => {
  const scrollView = useRef();
  const { setFieldValue, values, errors, touched, setFieldTouched } = useFormikContext();
  const { questionChoicesAttributes } = getIn(values, valuePath);
  let destroyed = 0;

  const deleteChoice = () => {
    const arrayValuesLength = questionChoicesAttributes.filter(item => !item._destroy).length - 1;
    const deleteValue = questionChoicesAttributes[arrayValuesLength];
    const newValues = [...questionChoicesAttributes.slice(0, arrayValuesLength)];

    if (deleteValue.id && deleteValue !== '') {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }
    if (deleteValue.position === skillAttribute.requireAmount) {
      setFieldValue(`${valuePath}[requireAmount]`, '');
    }

    setFieldValue(`${valuePath}[questionChoicesAttributes]`, newValues);
  };

  const addChoice = () => {
    const newValues = [...skillAttribute.questionChoicesAttributes, { label: '', position: '' }];
    setFieldValue(`${valuePath}[questionChoicesAttributes]`, newValues);
  };

  const validOptions = questionChoicesAttributes
    .filter(item => item.label)
    .map(item => ({ label: item.label, value: item.position }));

  const likertOptions = questionChoicesAttributes.map((body, choiceIndex) => {
    if (body._destroy) {
      destroyed += 1;
      return '';
    }

    return (
      <QuestionChoicesFields
        key={`question-choice-${choiceIndex.toString()}`}
        questionType="likert"
        position={choiceIndex - destroyed + 1}
        valuePath={`${valuePath}[questionChoicesAttributes][${choiceIndex}]`}
      />
    );
  });

  return (
    <Modal.Body>
      <Row className="mt-3 mb-5 w-100">
        <Col xs={1}>
          <ButtonTooltip
            variant="circle-danger"
            className="btn-circle mt-4"
            size="sm"
            text="Eliminar"
            onClick={deleteChoice}
            disabled={questionChoicesAttributes.filter(item => !item._destroy).length <= 2}
          >
            <Icon icon="remove" />
          </ButtonTooltip>
        </Col>
        <Col xs={10} className="scroll-x" ref={scrollView}>
          {likertOptions}
        </Col>
        <Col xs={1}>
          <ButtonTooltip
            variant="circle-primary"
            className="btn-circle mt-4"
            size="sm"
            text="Nuevo"
            onClick={() => {
              addChoice();
              scrollView.current.scroll({ behavior: 'smooth', left: 10000 });
            }}
          >
            <Icon icon="add" />
          </ButtonTooltip>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name={`${valuePath}[requireAmount]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Cumplimiento Requerido"
                placeholder="Seleccionar"
                options={validOptions}
                defaultValue={skillAttribute.requireAmount}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end my-3 mr-4">
        <Col md={2}>
          <Button variant="outline-info" block onClick={handleClose}>
            Cerrar
          </Button>
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default LikertFields;
