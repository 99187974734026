/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';


const DatatableEmployeeNameXdata = ({
  item,
  onClick,
  errorColor
}) => {

  return (
    <>

      <div className="d-flex flex-column" onClick={onClick} role="presentation">
        <span className={`${errorColor || 'text-muted'}`} data-tag="allowRowEvents">
          {item.fun_rut_afi_nn}-{item.fun_dv_afi_cr}
        </span>
        <span data-tag="allowRowEvents">{item.fun_nom_afi_cr}  {item.fun_ape_pat_cr} {item.fun_ape_mat_cr || ''}</span>
      </div>
    </>
  );
};

DatatableEmployeeNameXdata.propTypes = {
  item: PropTypes.shape({
  }).isRequired,

  onClick: PropTypes.func
};

DatatableEmployeeNameXdata.defaultProps = {

  onClick: () => null
};

export default DatatableEmployeeNameXdata;
