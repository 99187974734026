import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { indexDocumentTemplateRequest, deleteDocumentTemplateRequest } from '../../requests/documentTemplates';
import { ComponentDataTable, DefaultModal, BasicActionBtns } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { sendAlert } from '../../actions/utils';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    cell: item => item.label,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'template_type',
    cell: item => item.translated_template_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns item={item} resource="DocumentTemplate" path="document_templates" clickHandler={clickHandler} />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

class DocumentTemplateDataTable extends Component {
  state = {
    onRequest: true,
    documentTemplates: [],
    amount: 0,
    moreData: false,
    modalShow: false,
    modalItem: null,
    modalBody: ''
  };

  handleRequest = async params => {
    const { dispatch, templateType } = this.props;
    this.setState({
      onRequest: true
    });
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        ...params,
        template_type: templateType,
        display_length: 100
      },
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const documentTemplates = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      documentTemplates,
      amount,
      onRequest: false
    });
  };

  removeDocumentTemplate = documentTemplateId => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    deleteDocumentTemplateRequest(documentTemplateId, {
      dispatch,
      successCallback: this.handleSuccessRemove('Plantilla Eliminada Correctamente')
    });
  };

  handleButtonClick = (item, action) => {
    const { history } = this.props;
    const { context } = this;
    switch (action) {
      case 'show':
        if (context.can('read', 'DocumentTemplate')) history.push(`/document_templates/${item.value}`);
        break;
      case 'edit':
        if (context.can('update', 'DocumentTemplate')) history.push(`/document_templates/${item.value}/edit`);
        break;
      case 'destroy':
        this.setState({
          modalShow: true,
          modalItem: item,
          modalBody: '¿Estás seguro que deseas eliminar esta plantilla?'
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeDocumentTemplate(modalItem.value);
  };

  handleSuccessRemove = message => {
    this.handleModalClose();
    const { moreData } = this.state;
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'success', message }));
    this.setState({ moreData: !moreData });
  };

  render() {
    const { documentTemplates, amount, onRequest, moreData, modalShow, modalBody } = this.state;
    const { context } = this;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          data={documentTemplates}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
          onRowClicked={item => this.handleButtonClick(item, 'show')}
          pointerOnHover={context.can('update', 'DocumentTemplate')}
        />
        <DefaultModal
          title="Eliminar Plantilla"
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
          disabled={onRequest}
        />
      </>
    );
  }
}

DocumentTemplateDataTable.contextType = AbilityContext;

export default withRouter(connect()(DocumentTemplateDataTable));
