import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { delayMethod } from '../../services/utils';

const ContractVersionDiffModal = ({ contractVersion, hideModal }) => {
  const { objectChanges } = contractVersion;

  return (
    <>
      <Modal.Body>
        <h2 className="text-uppercase mb-0">Versión {contractVersion.versionNumber}</h2>
        <h4 className="text-uppercase mb-0">Cambios realizados</h4>
        <Row className="ml-2 mr-2 mb-4 mt-2">
          <Col>
            <Row className="mb-4">
              <Col md={4}>
                <h5 className="text-uppercase">Contrato</h5>
              </Col>
              <Col md={4}>Antes</Col>
              <Col md={4}>Después</Col>
            </Row>
            {objectChanges.map((body, index) => (
              <Row key={`change-${index.toString()}`}>
                <Col md={4}>{body[0]}</Col>
                <Col md={4} style={{ borderRight: '1px solid #ccc' }}>
                  {body[1]}
                </Col>
                <Col md={4}>{body[2]}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => delayMethod(hideModal)}>Cerrar</Button>
      </Modal.Footer>
    </>
  );
};

export default ContractVersionDiffModal;
