import React from 'react';
import { FormCheck } from 'react-bootstrap';

const CheckBoxButton = ({ label, id, ...props }) => (
  <>
    <FormCheck.Input {...props} id={id} />
    <FormCheck.Label className="button" htmlFor={id}>
      {label}
    </FormCheck.Label>
  </>
);

export default CheckBoxButton;
