const es = {
  availableFilterHeader: 'Filtro Disponible',
  availableHeader: 'CONCEPTOS DISPONIBLES',
  moveLeft: 'Mover a la izquierda',
  moveAllLeft: 'Mover todos a la izquierda',
  moveRight: 'Mover a la derecha',
  moveAllRight: 'Mover todos a la derecha',
  moveUp: 'Mover ítem hacia arriba',
  moveTop: 'Mover ítem al principio',
  moveDown: 'Mover ítem hacia abajo',
  moveBottom: 'Mover ítem al final',
  noAvailableOptions: 'No hay opciones disponibles',
  noSelectedOptions: 'No hay opciones seleccionadas',
  selectedFilterHeader: 'Filtro seleccionado',
  selectedHeader: 'CONCEPTOS SELECCIONADOS',
  search: 'Buscar'
};

export default es;
