import React, { useState, useEffect } from 'react';
import { Row, Col, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useSetTab } from '../../services/hooks';
import { LongCard } from '../../components';
import { humanResourcesRequest } from '../../requests/dashboards';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import AdvancePaymentColumns from '../Shared/AdvancePayment/approveColumns';
import AdvancePaymentDataTable from '../Shared/AdvancePayment/AdvancePaymentDataTable';
import EmployeeDataTable from '../Shared/Employee/EmployeeDataTable';
import ExpireContractColumns from './ExpireContractColumns';
import LoanColumns from '../Shared/Loan/approveColumns';
import LoanDataTable from '../Shared/Loan/LoanDataTable';
import PendingColumns from './PendingContractsColumns';
import PermissionColumns from '../Shared/Permission/approveColumns';
import PermissionDataTable from '../Shared/Permission/PermissionDataTable';
import VacationColumns from '../Shared/Vacation/approveColumns';
import VacationDataTable from '../Shared/Vacation/VacationDataTable';
import WorkflowColumns from './WorkflowColumns';
import WorkflowRequestDataTable from '../WorkflowRequest/WorkflowRequestDataTable';

const HRDashboard = () => {
  const location = useLocation();
  const [cards, setCards] = useState({});
  const [key, setKey] = useSetTab('contracts', location);
  const { advanceSettings } = useSelector(state => state.utils);
  const [moreData, setMoreData] = useState(false);
  const legalHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'legal_holiday')[0]?.textValue === 'true';
  const progressiveHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'progressive_holiday')[0]?.textValue === 'true';
  const additionalDaysEnabled =
    advanceSettings.filter(asItem => asItem.code === 'additional_days')[0]?.textValue === 'true';
  const loanCard = advanceSettings?.filter(asItem => asItem.code === 'loan_request')[0]?.textValue === 'true';
  const apCard = advanceSettings?.filter(asItem => asItem.code === 'advance_payment_request')[0]?.textValue === 'true';
  const vacationVisible = legalHolidayEnabled || progressiveHolidayEnabled || additionalDaysEnabled;
  const companyLeave = advanceSettings?.filter(
    asItem => asItem.code === 'paid_leave' || asItem.code === 'without_paid_leave'
  );
  const PermissionCard = companyLeave.some(x => x.textValue === 'true');
  const dispatch = useDispatch();
  const history = useHistory();
  const [tempExpireDate, setTempExpireDate] = useState(0);
  const [numberRenders, setNumberReders] = useState(0);
  let expireDate = 0;

  const dataTableHead = () => {
    switch (key) {
      case 'employees':
        return 'Trabajadores';
      case 'contracts':
        return 'Contratos por Vencer';
      case 'pending_employees':
        return 'Fichas por Completar';
      case 'workflows':
        return 'Documentos pendientes de Firma';
      case 'vacations':
        return 'Solicitudes de Vacaciones Pendientes';
      case 'loans':
        return 'Solicitudes de Préstamos Pendientes';
      case 'advance_payments':
        return 'Solicitudes de Anticipos Pendientes';
      case 'permissions':
        return 'Solicitudes de Permisos Pendientes';
      default:
        return '';
    }
  };

  const fetchCardData = () => {
    const request = async () => {
      humanResourcesRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  useEffect(fetchCardData, [moreData]);
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const localExpireDate = advanceSettings?.filter(asItem => asItem.code === 'expire_days')[0]?.integerValue || 0;
    setTempExpireDate(localExpireDate);
    setNumberReders(old => old + 1);
  }, [advanceSettings]);

  // esto es por si no existe ningun settins tome por defecto 5 y renderize el componente
  if (
    (numberRenders >= 2 && tempExpireDate === 0) ||
    (numberRenders === 1 && tempExpireDate === 0 && advanceSettings.length > 0)
  ) {
    expireDate = 5;
  } else {
    expireDate = tempExpireDate;
  }

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Dashboard Gestión de Personas</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <p onClick={() => history.push('/employees')} className="mt-0 mb-4 cursor-pointer workers">
            Trabajadores Activos: {cards.fullStaff}
          </p>
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.expireContracts}
            label="Contratos vencidos y por vencer"
            subtitle={`En los próximos ${expireDate} días`}
            active={key === 'contracts'}
            onClick={() => setKey('contracts')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pendingEmployees}
            label="Fichas por completar"
            active={key === 'pending_employees'}
            onClick={() => setKey('pending_employees')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pendingSignatures}
            label="Documentos pendientes de firma"
            active={key === 'workflows'}
            onClick={() => setKey('workflows')}
          />
        </Col>
        {vacationVisible && (
          <Col md={4} xs={12}>
            <LongCard
              variant="warning"
              title={cards.vacations}
              label="Solicitudes de vacaciones pendientes"
              active={key === 'vacations'}
              onClick={() => setKey('vacations')}
            />
          </Col>
        )}
        {loanCard && (
          <Col md={4} xs={12}>
            <LongCard
              variant="warning"
              title={cards.loans}
              label="Solicitudes de Préstamos pendientes"
              active={key === 'loans'}
              onClick={() => setKey('loans')}
            />
          </Col>
        )}
        {apCard && (
          <Col md={4} xs={12}>
            <LongCard
              variant="warning"
              title={cards.advancePayments}
              label="Solicitudes de Anticipos pendientes"
              active={key === 'advance_payments'}
              onClick={() => setKey('advance_payments')}
            />
          </Col>
        )}
        {PermissionCard && (
          <Col md={4} xs={12}>
            <LongCard
              variant="warning"
              title={cards.permissions}
              label="Solicitudes de Permisos pendientes"
              active={key === 'permissions'}
              onClick={() => setKey('permissions')}
            />
          </Col>
        )}
        <Col xs={9}>
          <h4 className="mb-0">{dataTableHead()}</h4>
        </Col>
      </Row>
      <Tab.Container id="dashboard-tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Tab.Content>
          <Tab.Pane eventKey="contracts">
            {expireDate > 0 && (
              <EmployeeDataTable
                columns={ExpireContractColumns}
                withMassActions
                clickRoute="/employees"
                customParams={{ to_expire: expireDate, active_staffing: true, active: true }}
                refreshDashboard={() => setMoreData(!moreData)}
                removeExpireFilter
                importContract
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="pending_employees">
            <EmployeeDataTable
              columns={PendingColumns}
              customParams={{ pending_contracts: true, active_staffing: true, active: true }}
              clickRoute="/employees"
              refreshDashboard={() => setMoreData(!moreData)}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="workflows">
            <WorkflowRequestDataTable
              customFilter={{ status: 'pending' }}
              columns={WorkflowColumns}
              refreshDashboard={() => setMoreData(!moreData)}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="vacations">
            {vacationVisible && (
              <VacationDataTable
                columns={VacationColumns}
                customParams={{
                  status: 'pending',
                  filter_vacation_exception:
                    'administrative_days,legal_openning_balance,progressive_openning_balance,additional_days_openning_balance,administrative_days_openning_balance'
                }}
                moreData={moreData}
                setMoreData={setMoreData}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="loans">
            {loanCard && (
              <LoanDataTable
                columns={LoanColumns}
                customParams={{ status: 'pending' }}
                moreData={moreData}
                setMoreData={setMoreData}
                withImport
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="advance_payments">
            {apCard && (
              <AdvancePaymentDataTable
                columns={AdvancePaymentColumns}
                customParams={{ status: 'pending' }}
                moreData={moreData}
                setMoreData={setMoreData}
                withImport
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="permissions">
            {PermissionCard && (
              <PermissionDataTable
                columns={PermissionColumns}
                customParams={{ status: 'pending' }}
                moreData={moreData}
                setMoreData={setMoreData}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default HRDashboard;
