import React from 'react';

const syndicateInfo = props => {
  const { syndicate } = props;
  const { name, code, fullAddress, parsedPhone, employeeSyndicatesAttributes } = syndicate;
  const members = employeeSyndicatesAttributes.map((employee, index) => {
    return (
      <p className="info" key={`employee-${index.toString()}`}>
        <span>Nombre:</span> {employee.fullName}
        <br />
        <span>Cargo:</span> {employee.jobTitle}
      </p>
    );
  });

  return (
    <>
      <div className="info-box">
        <h4 className="text-uppercase">Información Sindicato</h4>
        <p className="info">
          <span>Nombre:</span> {name}
        </p>
        <p className="info">
          <span>Código:</span> {code}
        </p>
        <hr />
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Contacto</h4>
        <p className="info">
          <span>Dirección:</span> {fullAddress}
        </p>
        {parsedPhone && (
          <p className="info">
            <span>Teléfono:</span> {parsedPhone}
          </p>
        )}
        <hr />
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Miembros</h4>
        {members}
      </div>
    </>
  );
};

export default syndicateInfo;
