import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icons';
import { ButtonTooltip } from '../Utils/Tooltips';
import { useAuthorization } from '../../services/hooks';

const BasicActionBtns = ({
  archiveColor,
  archiveText,
  clickHandler,
  destroyColor,
  destroyIcon,
  destroyText,
  disableDestroy,
  disableEdit,
  editColor,
  editIcon,
  extraIcon,
  extraIconAction,
  extraIconColor,
  extraIconText,
  item,
  resource,
  withArchive,
  withDestroy,
  withEdit,
  withShow
}) => {
  const canShow = useAuthorization('show', resource);
  const canRead = useAuthorization('read', resource);
  return (
    <>
      {extraIcon && (
        <ButtonTooltip
          onClick={() => clickHandler(item, extraIconAction)}
          variant={extraIconColor}
          className="btn-circle"
          size="sm"
          text={extraIconText}
        >
          <Icon icon={extraIcon} />
        </ButtonTooltip>
      )}
      {(canShow || canRead) && withShow && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      )}
      {useAuthorization('update', resource) && withEdit && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
          disabled={disableEdit}
        >
          <Icon style={editColor && { color: editColor }} icon={editIcon} />
        </ButtonTooltip>
      )}
      {useAuthorization('deactivate', resource) && withArchive && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'archive')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text={archiveText}
        >
          <Icon style={archiveColor && { color: archiveColor }} icon="archive" />
        </ButtonTooltip>
      )}
      {useAuthorization('destroy', resource) && withDestroy && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text={destroyText}
          disabled={disableDestroy}
        >
          <Icon style={destroyColor && { color: destroyColor }} icon={destroyIcon} />
        </ButtonTooltip>
      )}
    </>
  );
};

BasicActionBtns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  destroyIcon: PropTypes.string,
  destroyText: PropTypes.string,
  archiveColor: PropTypes.string,
  archiveText: PropTypes.string,
  withShow: PropTypes.bool,
  withEdit: PropTypes.bool,
  withArchive: PropTypes.bool,
  withDestroy: PropTypes.bool,
  disableDestroy: PropTypes.bool,
  disableEdit: PropTypes.bool,
  editIcon: PropTypes.string,
  editColor: PropTypes.string,
  destroyColor: PropTypes.string,
  resource: PropTypes.string.isRequired
};

BasicActionBtns.defaultProps = {
  destroyIcon: 'trash',
  destroyText: 'Eliminar',
  archiveText: 'Desactivar',
  withShow: true,
  withArchive: false,
  withEdit: true,
  withDestroy: true,
  disableDestroy: false,
  disableEdit: false,
  editIcon: 'pencil',
  editColor: '',
  archiveColor: '',
  destroyColor: ''
};

export default BasicActionBtns;
