import ApiService from '../services/apiService';

export const exportFamilyGroupsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/family_groups/export.xlsx`, {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const preImportFamilyGroupRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/family_groups/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importFamilyGroupRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/family_groups/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateFamilyGroupRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/family_groups/import_template', {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};
