import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navbar, Image, Nav } from 'react-bootstrap';
import whiteLogo from '../../../assets/images/logo-white.png';
import Icon from '../../Icons/Icon';
import './style.scss';

const FooterLanding = () => {
  const {
    settings: { phoneNumber, facebook, instagram, twitter, linkedin, address, mainContactEmail }
  } = useSelector(state => state.utils);

  const mapRedirect = url => {
    window.open(url, 'noopener', 'noreferrer');
  };

  return (
    <Navbar className="landing-footer" bg="primary" variant="primary" expand="lg" sticky="bottom">
      <Navbar.Brand as={Link} to="/">
        <Image src={whiteLogo} className="footer-logo" alt="PeopleWork" />
      </Navbar.Brand>
      <Navbar.Collapse className="custom-list mt-1">
        {address && (
          <Nav.Item>
            <p>{address}</p>
          </Nav.Item>
        )}
        {mainContactEmail && (
          <Nav.Item>
            <p>{mainContactEmail}</p>
          </Nav.Item>
        )}
        {phoneNumber && (
          <Nav.Item>
            <p>{phoneNumber}</p>
          </Nav.Item>
        )}
      </Navbar.Collapse>
      <Navbar.Collapse className="custom-list mb-md-5">
        <Nav.Item>
          <h3 className="ml-auto mt-0 footer-title">Las personas, el corazón de tu organización</h3>
        </Nav.Item>
        <Nav className="social-media-icon">
          {facebook && (
            <Nav.Item className="cursor-pointer" onClick={() => mapRedirect(facebook)}>
              <Icon icon="logo-facebook" width={20} />
            </Nav.Item>
          )}
          {instagram && (
            <Nav.Item className="cursor-pointer" onClick={() => mapRedirect(instagram)}>
              <Icon icon="logo-instagram" width={20} />
            </Nav.Item>
          )}
          {twitter && (
            <Nav.Item className="cursor-pointer" onClick={() => mapRedirect(twitter)}>
              <Icon icon="logo-twitter" width={20} />
            </Nav.Item>
          )}
          {linkedin && (
            <Nav.Item className="cursor-pointer" onClick={() => mapRedirect(linkedin)}>
              <Icon icon="logo-linkedin" width={20} />
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default FooterLanding;
