import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexWorkflowRequestsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/workflow_requests', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const getWorkflowRequestsRequest = (id, { dispatch, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/workflow_requests/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback
  });

export const createWorkflowRequestRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('post', '/workflow_requests', {
    dispatch,
    params,
    formData: true,
    callback,
    successCallback,
    showLeafArrayIndexes: true
  });

export const createMassiveWorkflowRequestRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/workflow_requests/massive_create', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const deleteWorkflowRequestRequest = (id, { dispatch, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', `/workflow_requests/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback
  });

export const approveWorkflowRequestRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/workflow_requests/${id}/approve`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
export const signWorkflowRequestRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback }
) =>
  ApiService.request('post', `/workflow_requests/${id}/sign`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const rejectWorkflowRequestRequest = (id, { dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', `/workflow_requests/${id}/reject`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const resendNotificationRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('post', `/workflow_requests/${id}/resend_notification`, {
    dispatch,
    successCallback,
    callback
  });

export const massiveSingWorkflowRequestRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', `/workflow_requests/massive_sign`, {
    callback,
    dispatch,
    failureCallback,
    formData,
    params,
    successCallback
  });

export const massiveRejectWorkflowRequestRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', `/workflow_requests/massive_reject`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const debounceIndexWorkflowRequestsRequest = AwesomeDebouncePromise(indexWorkflowRequestsRequest, 500);
