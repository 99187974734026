import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexEmployeeSurveyRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/employee_surveys', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showEmployeeSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/employee_surveys/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createEmployeeSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/surveys/${id}/employee_surveys`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const massCreateEmployeeSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/surveys/${id}/employee_surveys/massive_create`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const sentEmployeeSurveyRequest = (surveyId, id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/surveys/${surveyId}/employee_surveys/${id}/sent`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const resetEmployeeSurveyRequest = (surveyId, id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/surveys/${surveyId}/employee_surveys/${id}/reset`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const updateEmployeeSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/employee_surveys/${id}`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const deleteEmployeeSurveyRequest = (surveyId, id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/surveys/${surveyId}/employee_surveys/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexEmployeeSurveyRequest = AwesomeDebouncePromise(indexEmployeeSurveyRequest, 300);
