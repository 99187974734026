export default {
  name: '',
  code: '',
  account: '',
  analyticsAccount: '',
  auxiliary: '',
  bookkeeperDocumentTypeId: '',
  costCenter: '',
  typeAccount: '',
  active: true
};
