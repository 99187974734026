import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { showPayrollGroupRequest } from '../../requests/payrollGroups';
import { updateMassivePayrollBalanceRequest } from '../../requests/payrollBalances';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import PayrollBalanceForm from './PayrollBalanceForm';

const basicPayrollGroup = {
  annualGratification: false,
  earningsOfPeriod: '',
  grossAmount: '',
  includeSettled: false,
  liquidAmount: '',
  name: '',
  payrollsAttributes: []
};

const PayrollBalanceEdit = ({ match }) => {
  const { payrollGroupId, payrollProcessId } = match.params;
  const [payrollGroup, setPayrollGroup] = useState(basicPayrollGroup);
  const dispatch = useDispatch();
  const history = useHistory();

  const successCallback = useCallback(response => setPayrollGroup(camelCaseEmptyStringRecursive(response.data)), []);

  const fetchPayrollGroup = () => {
    showPayrollGroupRequest(payrollProcessId, payrollGroupId, { dispatch, successCallback });
  };

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Prenómina actualizada con éxito' }));
    history.push(`/payroll_processes/${payrollProcessId}/payroll_groups/${payrollGroupId}`);
  };

  const handleParams = params => {
    const sendParams = params;
    if (sendParams.payrolls === undefined) return sendParams;
    sendParams.payrollBalances = sendParams.payrolls
      .filter(payroll => payroll !== undefined)
      .map(payroll => {
        const { payrollBalances } = payroll;
        return payrollBalances.filter(pb => pb !== undefined && (pb.id !== undefined || pb.denominationAmount !== ''));
      })
      .flat();
    return sendParams;
  };

  const handleUpdateRequest = (params, setSubmitting) => {
    const sendParams = handleParams(params);
    return updateMassivePayrollBalanceRequest(payrollProcessId, payrollGroupId, {
      dispatch,
      params: snakeCaseKeys(sendParams),
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchPayrollGroup, []);

  return <PayrollBalanceForm action="edit" payrollGroup={payrollGroup} formRequest={handleUpdateRequest} />;
};

export default PayrollBalanceEdit;
