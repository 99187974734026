import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import moment from 'moment';
import { useSetTab } from '../../services/hooks';

import { LongCard } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { HRPermissionsRequest } from '../../requests/dashboards';
import PermissionDataTable from '../Shared/Permission/PermissionDataTable';
import VacationDataTable from '../Shared/Vacation/VacationDataTable';
import columns from '../Shared/Permission/approveColumns';

const PermissionIndex = ({ moreData, setMoreData }) => {
  const location = useLocation();
  const { advanceSettings } = useSelector(state => state.utils);
  const [key, setKey] = useSetTab('permissions', location);
  const [cards, setCards] = useState({});
  const [customParams, setCustomParams] = useState({});
  const [activeCard, setActiveCard] = useState('all');
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const dispatch = useDispatch();

  const administrativeDaysCard = advanceSettings
    .filter(asItem => ['administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const fetchDashboardCards = () => {
    const request = async () => {
      HRPermissionsRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleClick = card => {
    if (card !== 'administrative') {
      setKey('permissions');
    }
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setActiveCard('pending');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      case 'approved':
        setCustomParams({ status: 'approved' });
        setActiveCard('approved');
        setDefaultStartDate(moment().startOf('year'));
        setDefaultEndDate(moment().endOf('year'));
        break;
      case 'rejected':
        setCustomParams({ status: 'rejected' });
        setActiveCard('rejected');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      case 'administrative':
        setCustomParams({});
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setKey('administrative');
        setActiveCard('administrative');
        break;
      default:
        setCustomParams({});
        setActiveCard('all');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
    }
    setMoreData(!moreData);
  };

  useEffect(fetchDashboardCards, [moreData]);

  return (
    <>
      <Row className="mt-4 mb-3">
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.all}
            label="Permisos solicitados"
            active={activeCard === 'all'}
            onClick={() => handleClick('all')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pending}
            label="Permisos por autorizar"
            active={activeCard === 'pending'}
            onClick={() => handleClick('pending')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.rejected}
            label="Rechazados"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="primary"
            title={cards.approvedThisYear}
            label="Permisos aprobados este año"
            active={activeCard === 'approved'}
            onClick={() => handleClick('approved')}
          />
        </Col>
        {administrativeDaysCard && (
          <Col md={4} xs={12}>
            <LongCard
              variant="info"
              title={cards.admDays}
              label="Días Administrativos"
              active={activeCard === 'administrative'}
              onClick={() => handleClick('administrative')}
            />
          </Col>
        )}
      </Row>
      <Tab.Container id="permissions-datatable.tabs" activeKey={key} mountOnEnter onSelect={k => setKey(k)}>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="permissions">Permisos</Nav.Link>
          </Nav.Item>
          {administrativeDaysCard && (
            <Nav.Item>
              <Nav.Link eventKey="administrative">Días Administrativos</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="permissions">
            <PermissionDataTable
              moreData={moreData}
              setMoreData={setMoreData}
              columns={columns}
              customParams={customParams}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
            />
          </Tab.Pane>
          {administrativeDaysCard && (
            <Tab.Pane eventKey="administrative">
              <VacationDataTable
                columns={columns}
                moreData={moreData}
                setMoreData={setMoreData}
                withImport
                preName="administrative_days"
                customParams={{ filter_vacation_type: 'administrative_days, administrative_days_openning_balance' }}
                defaultStartDate={defaultStartDate}
                defaultEndDate={defaultEndDate}
              />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PermissionIndex;
