import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../../components';

const adminColumns = memoize(clickHandler => [
  {
    name: 'TICKET',
    selector: 'code',
    sortable: true,
    center: true,
    grow: '0.1'
  },
  {
    name: 'FECHA',
    selector: 'created_at',
    cell: item => item.parsed_date,
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'HORA',
    selector: 'created_at',
    cell: item => item.parsed_time,
    sortable: false,
    center: true,
    grow: '0.5'
  },
  {
    name: 'EMPRESA',
    selector: 'business_name',
    cell: item => item.company.label,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TITULO',
    selector: 'title',
    cell: item => item.title,
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'CRITICIDAD',
    selector: 'criticality',
    cell: item => item.translated_criticality,
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      const closed = item.translated_status === 'Cerrado' || item.translated_status === 'Cancelado';
      if (!closed) {
        const itemColorNormal = item.translated_status === 'Ingresado' ? 'primary' : '';
        itemColor = item.translated_status === 'Pendiente' ? 'warning' : itemColorNormal;
      }
      return (
        <p className={`mt-3 text-${itemColor}`} data-tag="allowRowEvents">
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {item.translated_status !== 'Cerrado' && item.translated_status !== 'Cancelado' && (
          <>
            {(!item.user || !item.translated_criticality) && (
              <ButtonTooltip
                onClick={() => clickHandler(item, 'assign_responsible')}
                variant="circle-info"
                className="btn-circle"
                size="sm"
                text="Actualizar Ticket"
              >
                <Icon icon="person-add-outline" />
              </ButtonTooltip>
            )}
            <ButtonTooltip
              onClick={() => clickHandler(item, 'show')}
              variant="circle-warning"
              className="btn-circle"
              size="sm"
              text="Editar"
            >
              <Icon icon="pencil" />
            </ButtonTooltip>
          </>
        )}
        {(item.translated_status === 'Cerrado' || item.translated_status === 'Cancelado') && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    right: true,
    minWidth: '123px'
  }
]);

export default adminColumns;
