import React from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Modal } from 'react-bootstrap';

const VacationInfo = props => {
  const { item } = props;
  const {
    days,
    employeeTotalVacationByType,
    endDate,
    middayEnd,
    middayStart,
    reincorporationDate,
    createdAt,
    startDate,
    translatedVacationType,
    observation
  } = camelCaseRecursive(item);

  const admVacation = translatedVacationType !== 'Administrativos';

  return (
    <Modal.Body>
      <div className="info-box">
        <p className="info">
          <span>Fecha de Solicitud:</span> {createdAt}
        </p>
        <p className="info">
          <span>Tipo de {admVacation ? 'Vacaciones' : 'Permiso'}:</span> {translatedVacationType}
        </p>
        <p className="info">
          <span>Fecha de Inicio:</span> {startDate} {middayStart && '- Medio día'}
        </p>
        <p className="info">
          <span>Fecha de Término:</span> {endDate} {middayEnd && '- Medio día'}
        </p>
        <p className="info">
          <span>Fecha de Retorno:</span> {reincorporationDate}
        </p>
        <p className="info">
          <span>Total Días Solicitados:</span> {days}
        </p>
        {admVacation && (
          <p className="info">
            <span>Días Acumulados:</span> {employeeTotalVacationByType}
          </p>
        )}
        {observation && (
          <p className="info">
            <span>Motivo de Rechazo:</span> {observation}
          </p>
        )}
      </div>
    </Modal.Body>
  );
};

export default VacationInfo;
