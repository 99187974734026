import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { debounceIndexAbsenceRequest } from '../../../requests/absences';
import Icon from '../../Icons';

const TeamAbsences = ({ employees, handleMoreInfoButton }) => {
  const dispatch = useDispatch();
  const [absences, setAbsences] = useState([]);
  const [showSpinner, setShowSpinner] = useState(1);
  const today = moment().format('YYYY-MM-DD');

  const handleIndexRequest = () => {
    if (employees.length > 0) {
      debounceIndexAbsenceRequest({
        dispatch,
        params: {
          filter_by_employee: employees,
          date_range: today
        },
        successCallback: response => {
          setAbsences(response.data.data);
          setShowSpinner(0);
        }
      });
    } else {
      setShowSpinner(0);
    }
  };

  useEffect(handleIndexRequest, [employees]);

  return (
    <Card className="mb-1">
      <Col className="top-header-green">
        <Col className="card-header-title">AUSENCIAS DE MI EQUIPO</Col>
      </Col>
      {showSpinner ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            {absences.length > 0 ? (
              absences.map(item => (
                <Col key={item.id} className="col-sm-4 text-center">
                  <div className="avatar employee-dash mt-1">
                    <div className={item.employee.file_info.file_url ? 'content' : 'empty content'}>
                      {item.employee.file_info.file_url ? (
                        <Image src={item.employee.file_info.file_url} />
                      ) : (
                        <Icon icon="camera" />
                      )}
                    </div>
                  </div>
                  <p className="mb-1">{item.employee.short_name}</p>
                  <p className="mb-1">{item.translated_absence_type}</p>
                </Col>
              ))
            ) : (
              <Col className="div-content">
                <Row>
                  <Col className="mb-2 mt-2">
                    <div style={{ height: 130, textAlign: 'center' }} className="div-center">
                      Sin ausencias...
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          {absences.length > 0 && (
            <Row>
              <Col sm={8}>&nbsp;</Col>
              <Col sm={4}>
                <Button variant="light-gray" className="mb-2 mt-3" block onClick={handleMoreInfoButton} size="sm">
                  Ver más
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Card>
  );
};

export default TeamAbsences;
