import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'scrollpos-styler';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import LinkBtn from '../Utils/Button/LinkBtn';
import Icon from '../Icons/Icon';
import TopView from './TopView';
import { AbilityContext } from '../../config/abilityContext';
import { ButtonTooltip } from '../Utils/Tooltips';

const CompanyTopView = ({ company, returnTo, editTo }) => {
  const {
    businessLogo: businessLogoShow,
    businessName: businessNameShow,
    nationalIdentification: nationalIdentificationShow,
    id
  } = company;

  let representants = '';
  if (company.representantsAttributes) {
    representants = company.representantsAttributes
      .map(obj => `${obj.name} ${obj.firstLastName} ${obj.nationalIdentification}`)
      .join(' | ');
  }
  const ability = useAbility(AbilityContext);
  const history = useHistory();
  return (
    <>
      <div className="top-box sps sps--abv company" />
      <div className="title-box sps sps--abv company margin-company company-box">
        <Row>
          {returnTo && (
            <LinkBtn variant="circle-dark" className="btn-circle mr-n2 m-top" block to={returnTo}>
              <Icon icon="chevron-back" />
            </LinkBtn>
          )}
          <Col>
            <TopView
              businessLogo={businessLogoShow}
              businessName={businessNameShow}
              nationalIdentification={nationalIdentificationShow}
              representants={representants}
              companyId={id}
            />
          </Col>
          {ability.can('update', 'Company') && editTo && (
            <Col md={3} xl={2} className="margin-icon-edit">
              <>
                <LinkBtn
                  variant="warning button-edit-company"
                  className="d-none d-md-block"
                  block
                  to={`${editTo}/${company.id}/edit`}
                >
                  Editar Empresa
                </LinkBtn>
                <div className="d-md-none d-flex justify-content-end icon-edit-company">
                  <ButtonTooltip
                    onClick={() => history.push(`${editTo}/${company.id}/edit`)}
                    variant="circle-warning"
                    className="btn-circle"
                    size="sm"
                    text="Editar"
                  >
                    <Icon icon="pencil" />
                  </ButtonTooltip>
                </div>
              </>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

CompanyTopView.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  returnTo: PropTypes.string,
  editTo: PropTypes.string
};

CompanyTopView.defaultProps = {
  returnTo: '',
  editTo: ''
};

export default CompanyTopView;
