import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'TÍTULO',
    selector: 'title',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'GRUPO ENVIADO',
    selector: 'group',
    cell: item =>
      item.employee_id ? `Jefatura - ${item.employee.full_name}` : item.group?.label || 'Trabajadores Activos',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'announcement_type',
    cell: item => item.translated_announcement_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <p
        data-tag="allowRowEvents"
        onClick={() => clickHandler(item, 'show')}
        className={`mt-3 text-${item.status === 'visible' ? 'primary' : 'warning'}`}
      >
        {item.status === 'visible' ? 'Visible' : 'Oculto'}
      </p>
    ),
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="Announcement" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    grow: '1.5',
    minWidth: '185px',
    right: true
  }
]);

export default columns;
