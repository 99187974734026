import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { sendAlert } from '../../actions/utils';
import { downloadReportRequest, indexReportRequest, handleCustomReportRequest } from '../../requests/reports';
import { indexQueryOptionsRequest } from '../../requests/queryOptions';
import ReportBasicForm from './ReportBasicForm';
import { downloadFile } from '../../services/utils';
import './style.scss';

const ReportBasicIndex = () => {
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = useState([]);
  const [reports, setReports] = useState([]);
  const [onRequest, setOnRequest] = useState(false);

  const fetchQueryOptions = params => {
    indexQueryOptionsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        setQueryOptions(data.length > 0 && data);
      }
    });
  };

  const fetchReports = params => {
    indexReportRequest({
      dispatch,
      params,
      successCallback: response => {
        setReports(camelCaseRecursive(response.data.data));
      }
    });
  };

  const loadReports = (isRequest = false, message) => {
    setQueryOptions([]);
    setReports([]);
    fetchQueryOptions();
    fetchReports();
    if (isRequest) dispatch(sendAlert({ kind: 'success', message: `Reporte ${message} con éxito` }));
  };

  const handleCustomReports = params => {
    const myParams = snakeCaseKeys({ ...params, will_save: true });
    handleCustomReportRequest({
      dispatch,
      formData: true,
      params: myParams,
      successCallback: () => loadReports(true, myParams.report_id ? 'actualizado' : 'creado'),
      callback: () => setOnRequest(false)
    });
  };

  const handleDownload = params => {
    const myParams = snakeCaseKeys(params);
    if (myParams.report.report_type === 'endowment') {
      if (myParams.report.report_filter_attributes.with_filter) {
        myParams.report.report_filter_attributes.q_contracts_status__in = [0, 1];
      } else {
        myParams.report.report_filter_attributes.q_contracts_status__in = 0;
      }
    }
    downloadReportRequest({
      dispatch,
      params: myParams,
      successCallback: response => downloadFile(response),
      callback: () => setOnRequest(false)
    });
  };

  const handleFormRequest = params => {
    setOnRequest(true);
    if (params.report.reportType === 'custom') {
      handleCustomReports(params);
    } else {
      handleDownload(params);
    }
  };

  const reportBody = () => {
    let body;
    if (queryOptions.length > 0 && reports.length > 0) {
      const endowmentId = reports.filter(e => e.reportType === 'endowment')[0]?.id;
      body = (
        <ReportBasicForm
          setOnRequest={setOnRequest}
          onRequest={onRequest}
          formRequest={handleFormRequest}
          queryOptions={queryOptions}
          reports={reports}
          endowmentId={endowmentId || ''}
          loadReports={loadReports}
        />
      );
    } else if (queryOptions.length === 0 && reports.length === 0) {
      body = (
        <div className="containerSpinnerLoad">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }
    return body;
  };

  useEffect(loadReports, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3">Reportes Básicos</h2>
        </Col>
      </Row>
      {onRequest && (
        <div className="containerSpinnerLoad responsive-spinner center-spinner mt-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <div className={`${onRequest && 'bg-opacity'}`}>{reportBody()}</div>
    </>
  );
};

export default ReportBasicIndex;
