import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'EVALUADOR/ES',
    selector: 'evaluators',
    cell: item =>
      item.evaluator_employees.length === 1
        ? item.evaluator_employees[0]?.label
        : `${item.evaluator_employees.length} evaluadores`,
    sortable: true,
    grow: '1'
  },
  {
    name: 'CANTIDAD DE TRABAJADORES',
    selector: 'employee_count',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      switch (item.status) {
        case 'pending':
          itemColor = 'warning';
          break;
        case 'finished':
          itemColor = 'primary';
          break;
        default:
          itemColor = 'dark';
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="PerformanceEvaluation" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);
