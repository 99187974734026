import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';
import PropTypes from 'prop-types';

import {
  debounceIndexEmployeesRequest,
  deactivateEmployeeRequest,
  destroyEmployeeRequest,
  importEmployeeRequest,
  preImportEmployeeRequest,
  exportEmployeesRequest,
  importTemplateEmployeeRequest,
  sendActivationEmailsRequest,
  sendActivationEmailRequest
} from '../../../requests/employees';
import {
  preImportContractRequest,
  importContractRequest,
  importTemplateContractRequest
} from '../../../requests/contracts';
import createTemplatePdfRequest from '../../../requests/templatePdfs';
import { sendAlert } from '../../../actions/utils';
import { downloadFile } from '../../../services/utils';
import AnnexContractForm from '../../../components/TemplatePdfs/AnnexContractForm';
import OvertimeAgreementsForm from '../../../components/OvertimeAgreements/OvertimeAgreementsForm';
import { AbilityContext } from '../../../config/abilityContext';
import {
  ComponentDataTable,
  DefaultModal,
  SimpleCenteredModal,
  ImportModal,
  ImportActions,
  EmployeeActions
} from '../../../components';
import EmployeeFilter from '../../Employee/EmployeeFilter';
import './Style.scss';

const EmployeeDataTable = ({
  customParams,
  clickRoute,
  columns,
  refreshDashboard,
  withMassActions,
  removeExpireFilter,
  importContract,
  showActions,
  showFilters
}) => {
  const [onRequest, setOnRequest] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [overtimeAgreementsShow, setOvertimeAgreementsShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const [filters, setFilters] = useState({ active: true });
  const [query, setQuery] = useState({});
  const [importTitle, setImportTitle] = useState('');
  const [importBody, setImportBody] = useState({});
  const [importShow, setImportShow] = useState(false);
  const [advanceParams, setAdvanceParams] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const refreshTable = () => {
    setMoreData(!moreData);
    if (refreshDashboard) refreshDashboard();
  };

  const handleFilterUpdate = filterKey => {
    const isInactive = filterKey === 'inactive';

    if (filters[filterKey]) {
      const newFilters = { ...filters };
      if (!isInactive) delete newFilters[filterKey];
      if (filters.inactive === true && isInactive) {
        delete newFilters.active;
        newFilters.inactive = false;
      }
      setFilters(newFilters);
    } else {
      const newFilters = { ...filters, [isInactive ? 'active' : filterKey]: !isInactive };
      if (isInactive) newFilters.inactive = true;
      if (filterKey === 'active') newFilters.inactive = false;
      setFilters(newFilters);
    }
    refreshTable();
  };

  const manageMountFilters = () => {
    const newFilters = new URLSearchParams(location.search).get('filters');
    if (newFilters) handleFilterUpdate(newFilters);
  };

  useEffect(manageMountFilters, []);

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
  };

  const handleFilterAdvance = params => {
    setAdvanceParams(params);
    refreshTable();
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setQuery({ ...params, ...filters, ...advanceParams });
    debounceIndexEmployeesRequest({
      dispatch,
      params: { ...customParams, ...params, ...filters, ...advanceParams, is_dt: false },
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const handleFailureRequest = error => {
    const { response } = error;
    if (!response) {
      dispatch(sendAlert({ kind: 'error', message: error.message }));
    } else {
      let errorMessage = response.statusText;
      if (response.data && response.data.message) {
        errorMessage = response.data.message;
      }
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    }
    setOnRequest(false);
    setSimpleModalShow(false);
    setModalShow(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setOnRequest(false);
  };

  const handleMassAnnex = () => {
    setEmployeeIds(selectedRows.map(employee => employee.id));
    setSimpleModalShow(true);
  };

  const handleMassOvertimeAgreement = () => {
    setEmployeeIds(selectedRows.map(employee => employee.id));
    setOvertimeAgreementsShow(true);
  };

  const handleMassArchive = (selectedArray = []) => {
    let employeesSelected = [...selectedArray, ...selectedRows];
    const myParams = { employee_ids: employeesSelected.map(employee => employee.id) };
    deactivateEmployeeRequest({
      dispatch,
      params: myParams,
      successCallback: () => {
        refreshTable();
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        setClearSelectedRows(!clearSelectedRows);
        employeesSelected = [];
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleModalConfirm = item => {
    handleMassArchive([item]);
    setModalShow(false);
    setOnRequest(true);
  };

  const handleDestroy = item => {
    setOnRequest(true);
    destroyEmployeeRequest(item.id, {
      dispatch,
      successCallback: () => {
        refreshTable();
        dispatch(sendAlert({ kind: 'success', message: 'Empleado eliminado con éxito' }));
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleSendActivationEmail = item => {
    sendActivationEmailRequest(item.id, {
      dispatch,
      successCallback: response => {
        dispatch(sendAlert({ kind: 'success', message: response?.data.message }));
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`${clickRoute || '/employees'}/${item.id}`);
        break;
      case 'edit':
        history.push(`${clickRoute || '/employees'}/${item.id}/edit`);
        break;
      case 'archive':
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas ${item.active ? 'desactivar' : 'activar'} a ${item.full_name}?`);
        setModalTitle(`${item.active ? 'Desactivar' : 'Activar'} Empleado`);
        setModalAction(() => handleModalConfirm);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody(
          `¿Estás seguro que deseas eliminar a ${item.full_name}? Esta acción no es reversible y se eliminará toda su información.`
        );
        setModalTitle('Eliminar Empleado');
        setModalAction(() => handleDestroy);
        break;
      case 'send_activation_email':
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas enviar el correo de primer acceso a ${item.full_name}?`);
        setModalTitle('Enviar correo de primer acceso');
        setModalAction(() => handleSendActivationEmail);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const onRowClicked = item => {
    history.push(`${clickRoute}/${item.id}`);
  };

  const handleSuccessPdf = (modal, templateType) => {
    let message = 'Contratos agregados a la carpeta del colaborador';
    setOnRequest(false);
    if (modal) {
      const documentNames = { contract_annex: 'Anexo de Contrato', overtime_agreements: 'Pactos de horas extras' };
      message = `${documentNames[templateType]} agregados a la carpeta del colaborador`;

      setSimpleModalShow(false);
      setOvertimeAgreementsShow(false);
    }
    setClearSelectedRows(!clearSelectedRows);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const handleFailurePdf = error => {
    const { response } = error;
    if (!response) {
      dispatch(sendAlert({ kind: 'error', message: error.message }));
    } else {
      let errorMessage = response.statusText;
      if (response.data && response.data.message) {
        errorMessage = response.data.message;
      }
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));
      setOnRequest(false);
      setSimpleModalShow(false);
      setOvertimeAgreementsShow(false);
    }
  };

  const createPdfRequest = (params, modal) => {
    const documentType = params?.template_pdf?.document_type;
    createTemplatePdfRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => handleSuccessPdf(modal, documentType),
      failureCallback: handleFailurePdf
    });
  };

  const handleAnnexCreateRequest = templatePdf => {
    setOnRequest(true);
    createPdfRequest(snakeCaseKeys(templatePdf), true);
  };

  const handleMassContract = () => {
    const ids = selectedRows.map(employee => employee.id);
    const myParams = { template_pdf: { employee_ids: ids, document_type: 'work_contract' } };
    setOnRequest(true);
    createPdfRequest(myParams);
  };

  const handleMassSendActivationEmail = () => {
    const myParams = {
      employee_attributes: selectedRows.reduce((acc, current) => {
        acc.push({
          id: current.id,
          company_id: current.company.value
        });
        return acc;
      }, [])
    };
    setOnRequest(true);

    sendActivationEmailsRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: response => {
        let message = '';
        const { errors, success } = response.data;
        if (success.length >= 1) {
          message = (
            <h4 className="text-success mb-3 enrrolment mt-md-n1">
              Correo(s) de primer acceso al sistema enviado(s) con éxito
            </h4>
          );
        }
        if (success.length === 0) {
          message = (
            <h4 className="text-danger mb-3 enrrolment mt-md-n1">
              No se enviaron correo(s) de primer acceso al sistema
            </h4>
          );
        }
        refreshTable();
        setImportTitle('Envío de correo de primer acceso');
        if (errors.length > 1) {
          setImportBody(
            <>
              {message}
              <p>
                Los correos de primer acceso no fueron enviados a los siguientes colaboradores:&nbsp;
                <strong>{errors.join(', ')}</strong>.&nbsp; Ya tienen una contraseña creada en el sistema.
              </p>
            </>
          );
        }
        if (errors.length === 1) {
          setImportBody(
            <>
              {message}
              <p>
                El correo de primer acceso no fue enviado al colaborador&nbsp;
                <strong>{errors[0].trim()}</strong>.&nbsp; Ya tiene una contraseña creada en el sistema.
              </p>
            </>
          );
        }
        if (errors.length === 0) {
          setImportBody(
            <>
              <center>{message}</center>
            </>
          );
        }
        setImportShow(true);
        setOnRequest(false);
      },
      failureCallback: error => {
        const { data } = error.response;
        setOnRequest(false);
        dispatch(sendAlert({ kind: 'error', message: data.message }));
      }
    });
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    exportEmployeesRequest({
      dispatch,
      params: query,
      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
        setModalShow(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'contract':
        handleMassContract();
        break;
      case 'annex':
        handleMassAnnex();
        break;
      case 'overtime_agreements':
        handleMassOvertimeAgreement();
        break;
      case 'archive':
        handleMassArchive();
        break;
      case 'send_activation_email':
        handleMassSendActivationEmail();
        break;
      case 'import':
        setImportShow(true);
        if (importContract) {
          setImportTitle('Importar Contratos');
          setImportBody(
            <ImportModal
              onDropUploaded={preImportContractRequest}
              handleTemplate={importTemplateContractRequest}
              hideModal={() => setImportShow(false)}
              onHide={importContractRequest}
              updateData={() => refreshTable()}
              handleExceptions={handleImportModalExceptions}
            />
          );
        } else {
          setImportTitle('Importar Colaboradores');
          setImportBody(
            <ImportModal
              onDropUploaded={preImportEmployeeRequest}
              handleTemplate={importTemplateEmployeeRequest}
              hideModal={() => setImportShow(false)}
              onHide={importEmployeeRequest}
              updateData={() => refreshTable()}
              handleExceptions={handleImportModalExceptions}
            />
          );
        }
        break;
      case 'export':
        setModalTitle('Exportar empleados');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar empleados?');
        setModalAction(() => handleExportRequest);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleFilters = action => {
    switch (action) {
      case 'toExpire':
        handleFilterUpdate('toExpire');
        break;
      case 'activeStaffing':
        handleFilterUpdate('activeStaffing');
        break;
      case 'toEnroll':
        handleFilterUpdate('toEnroll');
        break;
      case 'active':
        handleFilterUpdate('active');
        break;
      case 'inactive':
        handleFilterUpdate('inactive');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'company':
        return { sort_companies: name };
      case 'correlative_identification':
        return { sort_correlatives: name };
      case 'job_title':
        return { sort_job_titles: name };
      case 'job_management':
        return { sort_job_managements: name };
      case 'start_date':
        return { sort_contracts: name };
      case 'end_date':
        return { sort_end_date: name };
      default:
        return { sort_column: name };
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '55px'
      }
    },
    headCells: {
      style: {
        textAlign: 'left'
      }
    },
    cells: {
      style: {
        overflowY: 'initial !important'
      }
    }
  };

  const employeeMenu = [
    ability.can('force_enrollment', 'Employee'),
    ability.can('send_activation_email', 'Employee'),
    ability.can('show', 'Employee'),
    ability.can('update', 'Employee'),
    ability.can('destroy', 'Employee'),
    ability.can('deactivate', 'Employee')
  ];

  return (
    <>
      {showFilters && <EmployeeFilter formRequest={handleFilterAdvance} />}
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, employeeMenu)}
        customStyles={customStyles}
        data={employees}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        selectableRows={withMassActions}
        clearSelectedRows={clearSelectedRows}
        onRowClicked={onRowClicked}
        resourceRequest={handleRequest}
        pointerOnHover
        massActions={
          <>
            <ImportActions handleClick={handleMassAction} model="Employee" />
            <EmployeeActions
              handleClick={handleMassAction}
              handleFilters={handleFilters}
              disabled={!selectedCount || onRequest}
              removeExpireFilter={removeExpireFilter}
              filters={filters}
              showActions={showActions}
            />
          </>
        }
        withMassActions={withMassActions}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title="Anexo Contrato"
        body={<AnnexContractForm formRequest={handleAnnexCreateRequest} employeeIds={employeeIds} />}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
      <SimpleCenteredModal
        title="Pactos de horas extras"
        body={<OvertimeAgreementsForm formRequest={handleAnnexCreateRequest} employeeIds={employeeIds} />}
        show={overtimeAgreementsShow}
        onHide={() => setOvertimeAgreementsShow(false)}
      />
      <SimpleCenteredModal
        title={importTitle}
        body={importBody}
        show={importShow}
        onHide={() => setImportShow(false)}
      />
    </>
  );
};

EmployeeDataTable.propTypes = {
  showFilters: PropTypes.bool
};

EmployeeDataTable.defaultProps = {
  showFilters: false
};

export default EmployeeDataTable;
