import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSyndicatesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/syndicates', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showSyndicateRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/syndicates/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createSyndicateRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('post', '/syndicates', {
    dispatch,
    params,
    formData: true,
    failureCallback,
    successCallback
  });

export const updateSyndicateRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('put', `/syndicates/${id}`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    failureCallback
  });

export const deleteSyndicateRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/syndicates/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexSyndicatesRequest = AwesomeDebouncePromise(indexSyndicatesRequest, 300);
