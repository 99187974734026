const additionalQueryOptions = [
  {
    label: 'endowment',
    options: [
      { name: 'contract_job_management_name', values: ['contract_job_management_name'] },
      { name: 'contract_branch_office_name', values: ['contract_branch_office_name'] },
      { name: 'contract_cost_center_name', values: ['contract_cost_center_name'] },
      { name: 'contract_termination_reason', values: ['contract_termination_reason', 'contract_end_date'] },
      { name: 'contract_contract_type', values: ['contract_contract_type'] },
      { name: 'contract_check_assistance', values: ['contract_check_assistance'] },
      { name: 'contract_work_schedule', values: ['contract_work_schedule'] },
      { name: 'employee_region', values: ['employee_region'] },
      { name: 'employee_gender', values: ['employee_gender'] },
      { name: 'employee_country', values: ['employee_country'] },
      { name: 'contract_administrative_days', values: ['contract_administrative_days'] },
      { name: 'Formación' },
      { name: 'Vacaciones Pendientes' },
      { name: 'Otros' }
    ]
  }
];

const accumulatedQueryOptions = [
  {
    name: 'Vacaciones Pendientes',
    values: ['employee_legal_vacation', 'employee_progressive_vacation', 'employee_total_vacation']
  },
  {
    name: 'Formación',
    values: [
      'employee_language_name',
      'employee_certification_name',
      'employee_certification_code',
      'employee_career_name',
      'employee_education_level'
    ]
  },
  {
    name: 'Otros',
    values: [
      'contract_pants_size',
      'contract_shirt_size',
      'contract_t_shirt_size',
      'contract_jacket_size',
      'contract_shoes_size',
      'employee_emergency_contact_name',
      'employee_emergency_contact_relation',
      'employee_emergency_contact_country_code',
      'employee_emergency_contact_phone'
    ]
  }
];

const additionalHeaders = [
  {
    name: 'permission',
    options: ['Horas', 'Minutos']
  },
  {
    name: 'endowment',
    options: ['Años de Permanencia', 'Días Totales de Permanencia']
  }
];

const es = {
  availableFilterHeader: 'Filtro Disponible',
  availableHeader: 'COLUMNAS DISPONIBLES',
  moveLeft: 'Mover a la izquierda',
  moveAllLeft: 'Mover todos a la izquierda',
  moveRight: 'Mover a la derecha',
  moveAllRight: 'Mover todos a la derecha',
  moveUp: 'Mover ítem hacia arriba',
  moveTop: 'Mover ítem al principio',
  moveDown: 'Mover ítem hacia abajo',
  moveBottom: 'Mover ítem al final',
  noAvailableOptions: 'No hay opciones disponibles',
  noSelectedOptions: 'No hay opciones seleccionadas',
  selectedFilterHeader: 'Filtro seleccionado',
  selectedHeader: 'COLUMNAS SELECCIONADAS',
  search: 'Buscar'
};

export { additionalQueryOptions, accumulatedQueryOptions, additionalHeaders, es };
