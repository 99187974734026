import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Field, Form, withFormik } from 'formik';
import moment from 'moment';
import { FormikTimePicker } from '../Utils/Input';

const IndicatorsForm = ({ isSubmitting, setFieldValue }) => {
  return (
    <Form>
      <Row>
        <Col xs={12} md={8} xl={8}>
          <Field name="indicatorsDateFilter">
            {({ field }) => (
              <FormikTimePicker
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                onChange={date => {
                  setFieldValue(field.name, date);
                }}
                selected={field.value}
              />
            )}
          </Field>
        </Col>
        <Col xs={12} md={4} xl={4}>
          <Button type="submit" block variant="primary" disabled={isSubmitting}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    indicatorsDateFilter: null
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { indicatorsDateFilter } = values;
  const { formRequest } = props;
  if (indicatorsDateFilter) {
    const newDate = new Date(indicatorsDateFilter);
    const momentWrapperNewDate = moment(newDate);

    formRequest(momentWrapperNewDate.month(), momentWrapperNewDate.year(), setSubmitting);
    return null;
  }
  formRequest(null, null, setSubmitting);
  return null;
};

export default withFormik({
  mapPropsToValues: () => setInitialValues(),
  handleSubmit,
  enableReinitialize: false
})(IndicatorsForm);
