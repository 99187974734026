import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import { Button, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Icon } from '../..';
import { useSetTab } from '../../../services/hooks';
import { AbilityContext } from '../../../config/abilityContext';
import MyProfileDashboardIndex from '../../../screens/Profile/Dashboard/MyProfileDashboardIndex';
import TeamDashboardIndex from '../../../screens/Profile/Dashboard/TeamDashboardIndex';
import { getMeRequest } from '../../../requests/auth';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { validateToken } from '../../../actions/auth';

const basicUser = {
  avatar: [],
  email: '',
  id: '',
  name: '',
  first_name: '',
  nationalIdentification: '',
  role: '',
  employeeCard: {
    fileInfo: {}
  }
};

const Greeting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [key, setKey] = useSetTab('profile', location, 'board');
  const ability = useAbility(AbilityContext);
  const hasTeam = ability.can('boss', 'Employee');
  const [user, setUser] = useState(basicUser);
  const { currentCompany } = useSelector(state => state.auth);
  const employeeName = user.name.trim().split(' ')[0];
  const companyName = currentCompany.businessName;

  const dispatchGetMe = () => {
    getMeRequest({
      dispatch,
      successCallback: response => {
        if (response.data.logged_in) {
          setUser(camelCaseEmptyStringRecursive(response.data.user_info));
        } else {
          dispatch(validateToken());
        }
      }
    });
  };
  useEffect(dispatchGetMe, []);

  return (
    <>
      <Row>
        <Col sm={6} md={6} className="mt-4">
          {employeeName && (
            <p className="title">
              Hola <b>{employeeName}</b>, bienvenido a <b>{companyName}</b>
            </p>
          )}
        </Col>
        <Col sm={6} md={6} className="mt-4">
          <Row className="mt-2">
            {hasTeam && (
              <>
                <Col sm={6} md={6}>
                  <Button
                    variant={`${key === 'profile' ? 'primary' : 'light'}`}
                    className={`toogle ${key === 'profile' ? 'active' : ''} mb-1`}
                    block
                    onClick={() => setKey('profile')}
                    size="sm"
                  >
                    <Icon className="icon-15" icon="person" /> Trabajador
                  </Button>
                </Col>
                <Col sm={6} md={6} className="ml-0">
                  <Button
                    variant={`${key === 'team' ? 'primary' : 'light'}`}
                    className={`toogle ${key === 'team' ? 'active' : ''} mb-1`}
                    block
                    onClick={() => setKey('team')}
                    size="sm"
                  >
                    <Icon className="icon-15" icon="people" /> Jefatura
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      {key === 'profile' && <MyProfileDashboardIndex user={user} />}
      {hasTeam && key === 'team' && <TeamDashboardIndex user={user} />}
    </>
  );
};

export default Greeting;
