import React, { useState } from 'react';
import { Field, getIn } from 'formik';
import { Table } from 'react-bootstrap';
import { FormikNumber, FormikTimePicker } from '../../../components';
import FormikSelectMOptions from '../../../components/Utils/Select/FormikSelectMOptions';
import { socialSecurityCodeRemuneration } from '../FormOptions';

const RemunerationInput = ({ errors, index, setFieldValue, setFieldTouched, touched, remunerationsAttribute }) => {
  const [startMonth, setStartMonth] = useState();

  const handleCalDayLaboralInhability = e => {
    let value = '';
    if (e.target.value !== '') {
      value = Math.max(Number(0), Math.min(Number(30), Number(e.target.value)));
    }
    setFieldValue(`transact[remuneration][${index}][ndaysDisabilityPrevRem]`, value);
  };

  const handleCalDayRemunerations = e => {
    let value = '';
    let result = '';
    if (e.target.value !== '') {
      value = Math.max(Number(0), Math.min(Number(30), Number(e.target.value)));
      result = value - 30;
    }
    setFieldValue(`transact[remuneration][${index}][nDaysPrevRem]`, value);
    setFieldValue(`transact[remuneration][${index}][ndaysDisabilityPrevRem]`, result);
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <Field name={`transact[remuneration][${index}][PreviousIncomeForecastCode]`}>
          {({ field }) => (
            <FormikSelectMOptions
              {...field}
              abbr
              placeholder="Seleccione Régimen Prov."
              options={socialSecurityCodeRemuneration}
              defaultValue={remunerationsAttribute.PreviousIncomeForecastCode}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[remuneration][${index}][yearMonthPrevRent]`}>
          {({ field }) => (
            <FormikTimePicker
              {...field}
              setLocale
              placeholder="mm/aaaa"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              selected={startMonth}
              onChange={date => {
                setStartMonth(date);
                setFieldValue(field.name, date);
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[remuneration][${index}][parsednDaysPrevRem]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              value={remunerationsAttribute.nDaysPrevRem}
              thousandSeparator={false}
              decimalScale={2}
              fieldName={`transact[remuneration][${index}][nDaysPrevRem]`}
              setFieldValue={setFieldValue}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              onChange={handleCalDayRemunerations}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[remuneration][${index}][parsedAmountDisabilityPrevRem]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              leftAddon="$"
              decimalScale={2}
              value={remunerationsAttribute.amountDisabilityPrevRem}
              fieldName={`transact[remuneration][${index}][amountDisabilityPrevRem]`}
              setFieldValue={setFieldValue}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[remuneration][${index}][parsedNdaysDisabilityPrevRem]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              decimalScale={2}
              abbr
              value={remunerationsAttribute.ndaysDisabilityPrevRem}
              thousandSeparator={false}
              fieldName={`transact[remuneration][${index}][ndaysDisabilityPrevRem]`}
              setFieldValue={setFieldValue}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              onChange={handleCalDayLaboralInhability}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[remuneration][${index}][parsedTaxableAmountPrevRent]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              leftAddon="$"
              decimalScale={2}
              value={remunerationsAttribute.taxableAmountPrevRent}
              fieldName={`transact[remuneration][${index}][taxableAmountPrevRent]`}
              setFieldValue={setFieldValue}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[remuneration][${index}][parsedTotalAmountPrevRent]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              leftAddon="$"
              value={remunerationsAttribute.totalAmountPrevRent}
              fieldName={`transact[remuneration][${index}][totalAmountPrevRent]`}
              setFieldValue={setFieldValue}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
    </tr>
  );
};

const Remunerations = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  listTypePensionScheme,
  listPensionInstitution
}) => {
  const { remuneration } = values.transact;

  const remunerationsInputs = () => {
    const mapResults = remuneration.map((body, index) => {
      return (
        <>
          <RemunerationInput
            key={`family-group-${index.toString()}`}
            remunerationsAttribute={body}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errors={errors}
            touched={touched}
            index={index}
            listTypePensionScheme={listTypePensionScheme}
            listPensionInstitution={listPensionInstitution}
          />
        </>
      );
    });
    return mapResults;
  };

  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th rowSpan={2} style={{ width: '8px' }}>
            P
          </th>
          <th className="text-center" rowSpan={2} style={{ width: '200px' }}>
            Código Institución Previsional
          </th>
          <th className="text-center" colSpan={2}>
            Mes al cual corresponden las remuneraciones
          </th>
          <th className="text-center" colSpan={2}>
            Subsidio por incapacidad laboral
          </th>
          <th className="text-center" colSpan={2}>
            Remuneraciones imponibles
          </th>
        </tr>
        <tr>
          <th style={{ width: '105px' }}>Mes/Año </th>
          <th style={{ width: '85px' }}>N° días </th>
          <th> Monto</th>
          <th style={{ width: '85px' }}>N° días </th>
          <th> Remuneración Imponible </th>
          <th>Remuneración Total </th>
        </tr>
      </thead>
      <tbody>{remunerationsInputs()}</tbody>
    </Table>
  );
};

export default Remunerations;
