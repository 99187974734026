import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col, Button } from 'react-bootstrap';
import { sendAlert } from '../../../actions/utils';
import { camelCaseEmptyStringRecursive, delayMethod } from '../../../services/utils';
import TicketDataTable from './TicketDataTable';
import { SimpleCenteredModal } from '../../../components';
import { showEmployeeRequest } from '../../../requests/employees';
import { createTicketAdminRequest, assignUserTicketAdminRequest } from '../../../requests/adminTickets';
import adminColumns from './Columns';
import TicketForm from './TicketForm';
import TicketEdit from './TicketEdit';

const TicketIndex = () => {
  const { currentEmployee } = useSelector(state => state.auth);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [employee, setEmployee] = useState({});
  const dispatch = useDispatch();

  const handleSuccessRequest = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    setModalShow(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreate = ticket => {
    createTicketAdminRequest({
      dispatch,
      params: snakeCaseKeys(ticket),
      successCallback: () => handleSuccessRequest('Ticket creado con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleAssignUser = ticket => {
    assignUserTicketAdminRequest(ticket.ticket.id, {
      dispatch,
      params: snakeCaseKeys(ticket),
      successCallback: () => handleSuccessRequest('Ticket actualizado con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const fetchEmployee = () => {
    if (currentEmployee) {
      showEmployeeRequest(currentEmployee, {
        dispatch,
        params: { active: true },
        successCallback: response => {
          setEmployee(camelCaseEmptyStringRecursive(response.data));
        }
      });
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchEmployee, [currentEmployee]);

  const newTicket = () => {
    const currentTicket = {
      phoneCountryCode: '+56',
      phone: '',
      email: '',
      viaEmail: false,
      viaPhone: false,
      description: ''
    };
    setModalTitle('Nuevo Ticket');
    setModalShow(true);
    setModalBody(
      <TicketForm
        handleModalClose={() => delayMethod(() => setModalShow(false))}
        employee={employee}
        currentEmployee={currentEmployee}
        ticket={currentTicket}
        formRequest={handleCreate}
      />
    );
  };

  const indexUpdateTicket = ticket => {
    setModalTitle('Actualizar Ticket');
    setModalBody(
      <TicketEdit
        handleModalClose={() => delayMethod(() => setModalShow(false))}
        ticket={camelCaseEmptyStringRecursive(ticket)}
        formRequest={handleAssignUser}
      />
    );
    setModalShow(true);
  };

  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">{currentEmployee ? 'Mis ' : ''}Tickets</h2>
        </Col>
        <Col md={3} xl={2}>
          <Button variant="primary" className="mt-3" block onClick={() => newTicket()}>
            Nuevo Ticket
          </Button>
        </Col>
      </Row>
      <TicketDataTable columns={adminColumns} moreData={moreData} indexUpdateTicket={indexUpdateTicket} />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default TicketIndex;
