import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAttendanceRequest = ({
  dispatch,
  params = {},
  format = '',
  responseType = 'json',
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', `/attendances${format}`, {
    dispatch,
    params,
    responseType,
    failureCallback,
    successCallback
  });
};

export const importTemplateAttendanceRequest = ({ dispatch, params = {}, callback, successCallback }) => {
  ApiService.request('get', '/attendances/import_template', {
    dispatch,
    params,
    responseType: 'text',
    callback,
    successCallback
  });
};

export const createAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/attendances', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const createWebAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/attendances/create_web', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const massiveCreateAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/attendances/massive_create', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const exportAttendanceRequest = ({ dispatch, params, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/attendances/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const importAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/attendances/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const preImportAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/attendances/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const updateAttendanceRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/attendances/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const replaceAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/attendances/replace`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const disableAttendanceRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('post', `/attendances/disable`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const massDisableAttendanceRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('post', `/attendances/massive_disable`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const deleteAttendanceRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/attendances/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAttendanceRequest = AwesomeDebouncePromise(indexAttendanceRequest, 300);
