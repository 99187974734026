import React from 'react';
import { Modal, Row, Col, InputGroup, Button, Form as BSForm } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikDatePicker, FormikInput, NestedAttributes } from '../../components';

const EmployeeTracingForm = ({
  employeeEvaluation,
  setFieldValue,
  errors,
  touched,
  isSubmitting,
  evaluatorId,
  values,
  handleModalClose
}) => {
  const { employeeTracingsAttributes } = values.employeeEvaluation;

  const addTracing = () => {
    const mapResults = employeeTracingsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      if (body.newTrace) {
        return (
          <Col md={11} className="mb-3">
            <Row>
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label className="d-flex align-items-center">Trabajador</BSForm.Label>
                  <InputGroup>{employeeEvaluation.employee?.fullName}</InputGroup>
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <Field name={`employeeEvaluation[employeeTracingsAttributes][${index}][date]`}>
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      abbr
                      label="Fecha"
                      isOutsideRange={() => false}
                      placeholder="dd/mm/aaaa"
                      onDateChange={date => {
                        const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                        setFieldValue(field.name, vDate);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      margin="mb-0"
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name={`employeeEvaluation[employeeTracingsAttributes][${index}][observation]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      as="textarea"
                      label="Observación"
                      minInputHeight="120"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Col>
        );
      }
      return (
        <Col md={11} className="mb-3">
          <Row className="my-2">
            <Col md={8}>
              <h4 className="text-uppercase mb-1">Observación</h4>
              {body.observation}
            </Col>
            <Col md={4}>
              <h4 className="text-uppercase mb-1">Fecha y evaluador</h4>
              <p className="text-muted mb-1">{body.date}</p>
              <p>{body.reviewerEmployee?.fullName}</p>
            </Col>
          </Row>
        </Col>
      );
    });
    if (evaluatorId > 0) {
      mapResults.push(
        <Col md={11} className="mb-3">
          <Row className="my-2">
            <Col md={6} className="sample-row">
              <FormikInput label="Nombre" disabled />
            </Col>
            <Col md={6} className="sample-row">
              <FormikDatePicker label="Fecha" placeholder="dd/mm/aaaa" margin="mb-0" disabled />
            </Col>
          </Row>
        </Col>
      );
    } else if (employeeTracingsAttributes.length === 0) {
      mapResults.push(
        <Col md={11}>
          <p>No hay seguimientos para este trabajador</p>
        </Col>
      );
    }

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={employeeTracingsAttributes}
          removeFirstItem
          canCreate={evaluatorId > 0}
          setFieldValue={setFieldValue}
          valuePath="employeeEvaluation[employeeTracingsAttributes]"
          newAttributes={{
            reviewerId: evaluatorId,
            date: '',
            observation: '',
            newTrace: true
          }}
        />
      </>
    );
  };

  return (
    <Form>
      <Modal.Body>
        {addTracing()}
        <Row className="d-flex justify-content-end my-3 mr-4">
          <Col md={2}>
            {evaluatorId > 0 ? (
              <Button type="submit" block disabled={isSubmitting}>
                Guardar
              </Button>
            ) : (
              <Button variant="outline-info" block onClick={handleModalClose}>
                Cerrar
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Form>
  );
};

const setInitialValues = props => {
  const { employeeEvaluation, evaluatorId } = props;
  if (employeeEvaluation.employeeTracingsAttributes.length === 0 && evaluatorId > 0) {
    return {
      employeeEvaluation: {
        ...employeeEvaluation,
        employeeTracingsAttributes: [
          {
            date: '',
            observation: '',
            reviewerId: evaluatorId,
            newTrace: true
          }
        ]
      }
    };
  }
  return { employeeEvaluation: props.employeeEvaluation };
};

const validationSchema = Yup.object().shape({
  employeeEvaluation: Yup.object().shape({
    employeeTracingsAttributes: Yup.array().of(
      Yup.object().shape({
        date: Yup.string().required('Debes escoger una fecha'),
        observation: Yup.string().required('Debes ingresar una observación')
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(EmployeeTracingForm);
