import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusErrorProcessLicense = ({ setTab }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.entries(errors).length) {
      const errorsCollection = [...document.getElementsByClassName('is-invalid')];
      errorsCollection.forEach((_value, key) => {
        errorsCollection[key].tabIndex = `error-${key}`;
      });
      if (errorsCollection.length === 0) {
        return;
      }
      if (setTab) {
        const errorKey = Object.keys(errors.transact)[0];

        if (errorKey.includes('companyPhone', 'companyName', 'companyRut')) {
          setTab('company');
        }
        if (
          [
            'codeTypePensionScheme',
            'codePensionScheme',
            'codeWorkerQuality',
            'affiliationDate',
            'dateContract',
            'codePayingEntity'
          ].includes(errorKey)
        ) {
          setTab('employee');
        }
        if (['remuneration'].includes(errorKey)) {
          setTab('remuneration');
        }
      }
      setTimeout(() => errorsCollection[0].focus(), 100);
    }
  });

  return null;
};

export default FocusErrorProcessLicense;
