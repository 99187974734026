import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import OvertimeReportDataTable from './OvertimeReport';

const OvertimeReportIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="m-top mb-3 mt-3">Reportes Legales</h2>
        </Col>
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix mb-0">Reporte de Exceso de Jornada</h4>
        </Col>
      </Row>
      <OvertimeReportDataTable />
    </>
  );
};

export default OvertimeReportIndex;
