import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';

import {
  FormikInput,
  FormikSelect,
  NestedAttributes,
  FormikCheckBox,
  FormikNumber,
  FormikDatePicker
} from '../../../components';
import {
  familyAllowanceSections,
  heavyLaborAdditionals,
  ineCodeTypes,
  ipsTypes,
  pensionSchemes,
  pensionerTypes,
  unitOfAccountTypes,
  unitOfAccountVoluntaryTypes,
  voluntaryPensionFundTypes,
  voluntaryPrevisionTypes,
  noPensionPensionerTypes,
  unemploymentInsuranceReasons
} from './FormOptions';
import indexHealthPrevisionsRequest from '../../../requests/healthPrevisions';
import indexPensionFundsRequest from '../../../requests/pensionFunds';
import indexVoluntaryPensionFundsRequest from '../../../requests/voluntaryPensionFunds';

const VoluntaryPrevisionInput = ({
  setFieldValue,
  setFieldTouched,
  voluntaryPensionFunds,
  attributes,
  errors,
  touched,
  index
}) => {
  const { voluntaryPrevisionType, unitOfAccount, voluntaryPensionFundId, voluntaryPensionFundType } = attributes;
  const previsionCollectiveApv = voluntaryPrevisionType === 'collective_apv';
  const previsionApv = voluntaryPrevisionType === 'apv';
  const withTaxReduction = voluntaryPrevisionType === 'deposit_agreed' || voluntaryPrevisionType === 'collective_apv';
  const labelAgreedQuantity = previsionCollectiveApv ? 'Aporte Trabajador' : 'Cantidad Acordada';
  const name = useMemo(
    () => `employee[contract][employeePrevisionAttributes][voluntaryPrevisionsAttributes][${index}]`,
    [index]
  );
  let leftAddon = '%';
  if (unitOfAccount === 'uf') {
    leftAddon = 'UF';
  } else if (unitOfAccount === 'pesos') {
    leftAddon = '$';
  }

  return (
    <Col>
      <Row>
        <Col md={withTaxReduction ? 6 : 12}>
          <Field name={`${name}[voluntaryPrevisionType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Previsión Voluntaria"
                placeholder="Seleccionar Tipo de Previsión Voluntaria"
                options={voluntaryPrevisionTypes}
                defaultValue={voluntaryPrevisionType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {withTaxReduction && (
          <Field name={`${name}[taxReduction]`}>
            {({ field }) => (
              <FormikCheckBox {...field} margin="mt-4" field={field} label="Rebaja de la base tributaria" />
            )}
          </Field>
        )}
        <Col md={6}>
          <Field name={`${name}[unitOfAccount]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Unidad de Pago"
                placeholder="Seleccionar Unidad de Cuenta"
                options={unitOfAccountVoluntaryTypes}
                defaultValue={unitOfAccount}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${name}[parsedAgreedQuantity]`}>
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 2}
                leftAddon={leftAddon}
                fieldName={`${name}[agreedQuantity]`}
                label={labelAgreedQuantity}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            )}
          </Field>
        </Col>
        {previsionCollectiveApv && (
          <Col md={6}>
            <Field name={`${name}[parsedEmployerContribution]`}>
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  decimalScale={leftAddon === '$' ? 0 : 2}
                  label="Aporte Empleador"
                  fieldName={`${name}[employerContribution]`}
                  leftAddon={leftAddon}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
        )}
        <Col md={6}>
          <Field name={`${name}[voluntaryPensionFundId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Institución"
                placeholder="Seleccionar Institución"
                options={voluntaryPensionFunds}
                defaultValue={voluntaryPensionFundId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {previsionApv && (
          <Col md={6}>
            <Field name={`${name}[voluntaryPensionFundType]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de APV"
                  placeholder="Seleccionar Tipo de APV"
                  options={voluntaryPensionFundTypes}
                  defaultValue={voluntaryPensionFundType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
    </Col>
  );
};

class EmployeePrevisionTab extends Component {
  state = {
    healthPrevisions: [],
    pensionFunds: [],
    voluntaryPensionFunds: [],
    fonasaReadOnly: false,
    withHealthPrevision: true
  };

  componentDidMount() {
    this.fetchHealthPrevisions();
    this.fetchPensionFunds();
    this.fetchVoluntaryPensionFunds();
    this.checkFonasa();
  }

  componentDidUpdate(prevProps) {
    const {
      employeePrevision: { healthPrevision }
    } = this.props;
    const { healthPrevision: prevPrevision } = prevProps.employeePrevision;
    if (healthPrevision !== prevPrevision) {
      this.checkFonasa();
    }
  }

  checkFonasa = () => {
    const {
      employeePrevision: { healthPrevision }
    } = this.props;
    if (healthPrevision?.name?.toLowerCase() === 'fonasa') {
      this.setState({ fonasaReadOnly: true });
    } else if (healthPrevision?.name?.toLowerCase() === 'no cotiza' || !healthPrevision) {
      this.setState({ withHealthPrevision: false });
    }
  };

  voluntaryPrevisionsInputs = () => {
    const { voluntaryPensionFunds } = this.state;
    const { errors, touched, values, setFieldValue, setFieldTouched } = this.props;
    const { employeePrevisionAttributes = {} } = values.employee?.contract;
    const { voluntaryPrevisionsAttributes } = employeePrevisionAttributes;
    const mapResults = voluntaryPrevisionsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <VoluntaryPrevisionInput
          key={`voluntary-prevision-${index.toString()}`}
          voluntaryPensionFunds={voluntaryPensionFunds}
          attributes={body}
          errors={errors}
          touched={touched}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    });

    mapResults.push(
      <Col>
        <Row>
          <Col className="sample-row">
            <FormikSelect
              label="Tipo de Previsión Voluntaria"
              placeholder="Seleccionar Tipo de Previsión Voluntaria"
              isDisabled
            />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={voluntaryPrevisionsAttributes}
          setFieldValue={setFieldValue}
          valuePath="employee[contract][employeePrevisionAttributes][voluntaryPrevisionsAttributes]"
          newAttributes={{
            voluntaryPrevisionType: '',
            unitOfAccount: '',
            agreedQuantity: '',
            employerContribution: '',
            voluntaryPensionFundId: '',
            voluntaryPensionFundType: '',
            taxReduction: true
          }}
        />
      </>
    );
  };

  // Fetch HealthPrevisions
  fetchHealthPrevisions = () => {
    const { dispatch } = this.props;
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexHealthPrevisionsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        this.setState({ healthPrevisions: data });
      }
    });
  };

  // Fetch PensionFunds
  fetchPensionFunds = () => {
    const { dispatch } = this.props;
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexPensionFundsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        this.setState({ pensionFunds: data });
      }
    });
  };

  // Fetch VoluntaryPensionFunds
  fetchVoluntaryPensionFunds = () => {
    const { dispatch } = this.props;
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexVoluntaryPensionFundsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        this.setState({ voluntaryPensionFunds: data });
      }
    });
  };

  healthPrevisionChange = (field, data) => {
    const { setFieldValue } = this.props;
    this.setState({ fonasaReadOnly: false, withHealthPrevision: true });
    if (data) {
      setFieldValue(field, data.value);
      if (data.label.toLowerCase() === 'fonasa') {
        setFieldValue('employee[contract][employeePrevisionAttributes][unitOfAccount]', 'percentage');
        setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', 7);
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', 7);
        this.setState({ fonasaReadOnly: true });
      } else if (data.label.toLowerCase() === 'no cotiza') {
        this.setState({ withHealthPrevision: false });
        setFieldValue('employee[contract][employeePrevisionAttributes][fun]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][unitOfAccount]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', '');
      }
    } else {
      this.setState({ withHealthPrevision: false });
      setFieldValue(field, '');
    }
  };

  unitOfAccountChange = (field, data) => {
    const { setFieldValue } = this.props;

    setFieldValue(field, data ? data.value : '');

    setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][firstAgreedAmount]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][parsedFirstAgreedAmount]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][secondAgreedAmount]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]', 0);

    if (data) {
      if (
        data.value === 'seven_percent_uf_pesos' ||
        data.value === 'percentage' ||
        data.value === 'seven_percent_uf' ||
        data.value === 'seven_percent_pesos'
      ) {
        setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', 7);
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', 7);
      }
    } else {
      setFieldValue(field, 'percentage');
    }
  };

  agreedAmounts = () => {
    const { fonasaReadOnly } = this.state;
    const { errors, touched, setFieldValue, values } = this.props;
    const {
      employeePrevisionAttributes: { secondAgreedAmount, unitOfAccount }
    } = values.employee?.contract;
    if (unitOfAccount === 'uf_pesos' || unitOfAccount === 'seven_percent_uf_pesos') {
      return (
        <>
          <Col md={3}>
            <Field name="employee[contract][employeePrevisionAttributes][parsedFirstAgreedAmount]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  decimalScale={10}
                  leftAddon="UF"
                  fieldName="employee[contract][employeePrevisionAttributes][firstAgreedAmount]"
                  label="Cantidad acordada UF"
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  maxLength="20"
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  leftAddon="$"
                  fieldName="employee[contract][employeePrevisionAttributes][secondAgreedAmount]"
                  value={secondAgreedAmount}
                  label="Cantidad acordada $"
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  maxLength="20"
                />
              )}
            </Field>
          </Col>
        </>
      );
    }
    let leftAddon = '%';
    if (!unitOfAccount) {
      leftAddon = '$';
      return (
        <Col md={6}>
          <Field name="employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 2}
                leftAddon={leftAddon}
                fieldName="employee[contract][employeePrevisionAttributes][secondAgreedAmount]"
                label="Cantidad acordada $"
                value={secondAgreedAmount}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                maxLength="20"
              />
            )}
          </Field>
        </Col>
      );
    }
    if (unitOfAccount.includes('uf')) {
      leftAddon = 'UF';
      return (
        <Col md={6}>
          <Field name="employee[contract][employeePrevisionAttributes][parsedFirstAgreedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 10}
                leftAddon={leftAddon}
                fieldName="employee[contract][employeePrevisionAttributes][firstAgreedAmount]"
                label="Cantidad acordada UF"
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                maxLength="20"
              />
            )}
          </Field>
        </Col>
      );
    }
    if (unitOfAccount.includes('pesos')) {
      leftAddon = '$';
      return (
        <Col md={6}>
          <Field name="employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 2}
                leftAddon={leftAddon}
                fieldName="employee[contract][employeePrevisionAttributes][secondAgreedAmount]"
                label="Cantidad acordada $"
                value={secondAgreedAmount}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                maxLength="20"
              />
            )}
          </Field>
        </Col>
      );
    }
    return (
      <Col md={6}>
        <Field name="employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]">
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              decimalScale={leftAddon === '$' ? 0 : 2}
              leftAddon={leftAddon}
              fieldName="employee[contract][employeePrevisionAttributes][agreedQuantity]"
              readOnly={fonasaReadOnly}
              label="Cantidad acordada"
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              maxLength="20"
            />
          )}
        </Field>
      </Col>
    );
  };

  render() {
    const { employeePrevision, errors, touched, values, setFieldValue, setFieldTouched } = this.props;
    const { healthPrevisions, pensionFunds, fonasaReadOnly, withHealthPrevision } = this.state;
    const { employeePrevisionAttributes = {} } = values.employee?.contract;
    const {
      familyAllowanceSection,
      healthPrevisionId,
      heavyLaborAdditional,
      ineCode,
      ipsType,
      pensionFundId,
      pensionScheme,
      pensioner,
      unemploymentInsuranceReason
    } = employeePrevision;

    return (
      <>
        <Row>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][pensionScheme]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  isClearable
                  label="Régimen previsional"
                  placeholder="Seleccionar régimen previsional"
                  options={pensionSchemes}
                  defaultValue={pensionScheme}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {employeePrevisionAttributes.pensionScheme === 'afp' && (
            <>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][pensionAffiliationDate]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      isOutsideRange={() => false}
                      label="Fecha de Afiliación AFP"
                      tooltipText="Indica la fecha en la que se afilió por primera vez al sistema de AFP"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][pensionFundId]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="AFP"
                      placeholder="Seleccionar AFP"
                      options={pensionFunds}
                      defaultValue={pensionFundId}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          {employeePrevisionAttributes.pensionScheme === 'ips' && (
            <>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][ipsType]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="Fondo de Cotización"
                      placeholder="Seleccionar fondo de cotización"
                      options={ipsTypes}
                      defaultValue={ipsType}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][pensionSchemeRate]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Tasa del régimen general"
                      inputType="number"
                      leftAddon="%"
                      min={0}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][pensioner]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr={employeePrevisionAttributes.pensionScheme === 'afp'}
                  label="¿Es pensionado?"
                  placeholder="Seleccionar opción de pensión"
                  options={
                    employeePrevisionAttributes.pensionScheme === 'not_listed'
                      ? noPensionPensionerTypes
                      : pensionerTypes
                  }
                  defaultValue={pensioner}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][healthPrevisionId]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Salud"
                  placeholder="Seleccionar institución"
                  options={healthPrevisions}
                  defaultValue={healthPrevisionId}
                  onChange={data => this.healthPrevisionChange(field.name, data)}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  isClearable
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {!fonasaReadOnly && employeePrevisionAttributes.healthPrevisionId && withHealthPrevision && (
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][fun]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="FUN"
                    tooltipText="El Formulario Único de Notificación (FUN) es un documento que forma parte del contrato 
                                 de salud, donde se detalla toda la información requerida del afiliado y sus
                                 beneficiarios, el cual está normado por la Superintendencia de Salud. En este campo
                                 debe incorporar el número de FUN."
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
        </Row>
        {withHealthPrevision && (
          <Row>
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][unitOfAccount]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Unidad de pago"
                    placeholder="Seleccione unidad de pago"
                    options={fonasaReadOnly ? [{ label: '%', value: 'percentage' }] : unitOfAccountTypes}
                    defaultValue={employeePrevisionAttributes.unitOfAccount}
                    onChange={data => this.unitOfAccountChange(field.name, data)}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {this.agreedAmounts()}
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Field name="employee[contract][employeePrevisionAttributes][ineCode]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Código INE"
                  placeholder="Seleccionar Código INE"
                  options={ineCodeTypes}
                  defaultValue={ineCode}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][unemploymentInsurance]">
              {({ field }) => (
                <FormikCheckBox {...field} margin="mt-4" field={field} label="¿Adscribe al seguro de cesantía?" />
              )}
            </Field>
          </Col>
          {!employeePrevisionAttributes.unemploymentInsurance && (
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][unemploymentInsuranceReason]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Razón"
                    placeholder="Seleccionar una razón"
                    options={unemploymentInsuranceReasons}
                    defaultValue={unemploymentInsuranceReason}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={12}>
            <Field name="employee[contract][employeePrevisionAttributes][youthAllowance]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Se encuentra bajo subsidio al empleo joven"
                  tooltipText="Para jóvenes entre 18 y 24 años. Que pertenezcan al 40% más vulnerable de la población
                  según el Registro Social de Hogares. (Si no está registrado se puede realizar en
                  registrosocial.gob.cl o en tu municipio). Calendario El pago del #SEJ es anual y se
                  calcula de acuerdo a las rentas brutas recibidas el año anterior. Se paga una vez al 
                  año en el mes de agosto, pero puedes acceder a anticipos mensuales.
                  (Fuente: http://www.sence.cl/portal/Oportunidad-al-Empleo-Joven/)"
                  tooltipVariant="modal"
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][familyAllowanceSection]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Tramo asignación familiar"
                  placeholder="Seleccionar tramo asignación familiar"
                  options={familyAllowanceSections}
                  defaultValue={familyAllowanceSection}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][heavyLaborAdditional]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Trabajo Pesado - Porcentaje de Cotización Adicional"
                  placeholder="Seleccionar porcentaje de cotización adicional"
                  options={heavyLaborAdditionals}
                  defaultValue={heavyLaborAdditional}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <h4 className="text-uppercase">APV y Depósito Convenido</h4>
        {this.voluntaryPrevisionsInputs()}
      </>
    );
  }
}

export const yupPrevision = Yup.object().shape({
  agreedQuantity: Yup.number()
    .max(10000000000, 'Deben ser menos de 10 números')
    .nullable(),
  healthPrevisionId: Yup.string().nullable(),
  heavyLaborAdditional: Yup.string().nullable(),
  ineCode: Yup.string().nullable(),
  ipsType: Yup.string().when('pensionScheme', {
    is: val => val === 'ips',
    then: Yup.string()
      .required('Debes seleccionar un fondo de cotización')
      .typeError('Debes seleccionar un fondo de cotización'),
    otherwise: Yup.string().nullable()
  }),
  familyAllowanceSection: Yup.string().nullable(),
  fun: Yup.string()
    .nullable()
    .alphanumeric('Deben ser caracteres alfanuméricos')
    .max(60, 'Deben ser máximo 60 caracteres'),
  pensionFundId: Yup.string().when('pensionScheme', {
    is: val => val === 'afp',
    then: Yup.string()
      .required('Debes seleccionar AFP')
      .typeError('Debes seleccionar AFP'),
    otherwise: Yup.string().nullable()
  }),
  pensionScheme: Yup.string()
    .nullable()
    .required('Debes ingresar un regimen previsional'),
  pensionSchemeRate: Yup.number().when('pensionScheme', {
    is: val => val === 'ips',
    then: Yup.number()
      .required('Debes seleccionar un régimen previsional')
      .typeError('Debes seleccionar un régimen previsional')
      .min(0, 'Debe ser mayor o igual a 0')
      .max(100, 'No puede ser mayor al 100'),
    otherwise: Yup.number().nullable()
  }),
  pensioner: Yup.string().when('pensionScheme', {
    is: val => val === 'afp',
    then: Yup.string().required('Debes seleccionar una opción de pensión'),
    otherwise: Yup.string().nullable()
  }),
  firstAgreedAmount: Yup.number().when('unitOfAccount', {
    is: val => val === 'uf_pesos',
    then: Yup.number()
      .required('Debes ingresar una cantidad acordada en UF')
      .max(10000000000, 'Deben ser menos de 10 números'),
    otherwise: Yup.number().nullable()
  }),
  secondAgreedAmount: Yup.number().when('unitOfAccount', {
    is: val => val === 'uf_pesos',
    then: Yup.number()
      .required('Debes ingresar una cantidad acordada en $')
      .max(10000000000, 'Deben ser menos de 10 números'),
    otherwise: Yup.number().nullable()
  }),
  unemploymentInsurance: Yup.boolean(),
  unemploymentInsuranceReason: Yup.string().when('unemploymentInsurance', {
    is: val => val === false,
    then: Yup.string().required('Debes seleccionar una razón'),
    otherwise: Yup.string().nullable()
  }),
  unitOfAccount: Yup.string().nullable(),
  voluntaryPrevisionsAttributes: Yup.array().of(
    Yup.object().shape({
      agreedQuantity: Yup.number().when('voluntaryPrevisionType', {
        is: val => val !== undefined,
        then: Yup.number()
          .required('Debes ingresar una cantidad')
          .min(0, 'Debe ser mayor o igual a 0')
          .max(100000000000000000000, 'Deben ser menos de 20 números'),
        otherwise: Yup.number().nullable()
      }),
      employerContribution: Yup.number().when('voluntaryPrevisionType', {
        is: val => val === 'collective_apv',
        then: Yup.number()
          .required('Debes ingresar un aporte del empleador')
          .min(0, 'Debe ser mayor o igual a 0')
          .max(100000000000000000000, 'Deben ser menos de 20 números'),
        otherwise: Yup.number().nullable()
      }),
      taxReduction: Yup.boolean(),
      unitOfAccount: Yup.string().when('voluntaryPrevisionType', {
        is: val => val !== undefined,
        then: Yup.string().required('Debes seleccionar una unidad de cuenta'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryPensionFundId: Yup.string().when('voluntaryPrevisionType', {
        is: val => val !== undefined,
        then: Yup.string().required('Debes seleccionar una institución'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryPensionFundType: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'apv',
        then: Yup.string().required('Debes seleccionar un tipo de APV'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryPrevisionType: Yup.string().required('Debes seleccionar un tipo de previsión voluntaria')
    })
  ),
  youthAllowance: Yup.bool()
});

export default connect()(EmployeePrevisionTab);
