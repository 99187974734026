import React, { Component } from 'react';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { showJointCommitteeRequest, updateJointCommitteeRequest } from '../../requests/jointCommittees';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import JointCommitteeForm from './JointCommitteeForm';

class jointCommitteeEdit extends Component {
  state = {
    jointCommittee: {
      address: '',
      addressFloor: '',
      addressNumber: '',
      addressOffice: '',
      code: '',
      communeId: '',
      communeName: '',
      employeeJointCommitteesAttributes: [],
      name: '',
      phone: '',
      phoneCountryCode: '+56',
      regionId: ''
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { match, dispatch } = this.props;
    const jointCommitteeId = match.params.id;
    showJointCommitteeRequest(jointCommitteeId, {
      dispatch,
      successCallback: this.handleSuccessShow
    });
  }

  handleSuccessShow = response => {
    const jointCommittee = camelCaseEmptyStringRecursive(response.data);
    this.setState({ jointCommittee });
  };

  handleUpdateRequest = (jointCommittee, setSubmitting) => {
    const { dispatch } = this.props;
    const jointCommitteeId = jointCommittee.jointCommittee.id;
    const myParams = snakeCaseKeys(jointCommittee, { exclude: ['_destroy'] });

    updateJointCommitteeRequest(jointCommitteeId, {
      dispatch,
      params: myParams,
      successCallback: this.handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  handleSuccessUpdate = () => {
    const { history, dispatch, match } = this.props;
    const jointCommitteeId = match.params.id;
    dispatch(sendAlert({ kind: 'success', message: 'Comité actualizado con éxito' }));
    history.push(`/joint_committees/${jointCommitteeId}`);
  };

  render() {
    const { jointCommittee } = this.state;
    return (
      <>
        <h2 className="text-uppercase mb-3 mt-5">Editar Comité</h2>
        <JointCommitteeForm jointCommittee={jointCommittee} action="edit" formRequest={this.handleUpdateRequest} />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { company } = state.auth;
  return { company };
};

export default connect(mapStateToProps)(jointCommitteeEdit);
