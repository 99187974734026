import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormikInput } from '../../../components';
import AttendanceHashDataTable from './AttendanceHashDataTable';

const AttendanceHashIndex = () => {
  const [hash, setHash] = useState('');
  const [moreData, setMoreData] = useState(false);
  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-1 mb-1">Busqueda de Asistencia por Hash</h2>
        </Col>
        <Col md={3}>
          <FormikInput
            label="Hash"
            name="rut"
            placeholder="Ingresa un Hash"
            onChange={data => {
              setHash(data ? data.target.value : '');
              setMoreData(!moreData);
            }}
            value={hash}
          />
        </Col>
      </Row>
      <AttendanceHashDataTable moreData={moreData} customParams={{ filter_by_hash: hash }} />
    </>
  );
};

export default AttendanceHashIndex;
