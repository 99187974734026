const clasificationTypes = [
  { label: 'Error en cálculo', value: 'calculation_error' },
  { label: 'Acceso empresa', value: 'company_access' },
  { label: 'Acceso trabajador', value: 'employee_access' },
  { label: 'Caída de la plataforma empresa', value: 'company_drop' },
  { label: 'Caída portal trabajador', value: 'employee_drop' },
  { label: 'Caída de funcionalidad', value: 'functionality_drop' },
  { label: 'Mensaje de error', value: 'message_error' },
  { label: 'Consulta funcionalidad', value: 'functionality_query' },
  { label: 'Consulta legislación laboral', value: 'legal_query' },
  { label: 'Reclamo', value: 'claim' },
  { label: 'Felicitación', value: 'congratulation' }
];

const criticalityTypes = [
  { label: 'Baja', value: 'low' },
  { label: 'Media', value: 'medium' },
  { label: 'Alta', value: 'high' }
];

const statuses = [
  { label: 'Cancelado', value: 'cancelled' },
  { label: 'Cerrado', value: 'closed' },
  { label: 'Ingresado', value: 'registered' },
  { label: 'Pendiente', value: 'pending' }
];

export { clasificationTypes, criticalityTypes, statuses };
