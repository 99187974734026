import React from 'react';
import { Tab, Nav } from 'react-bootstrap';

import { EmployeeInfo, Icon } from '../../../components';
import { useScrollPosition } from '../../../services/hooks';

const EmployeeTabs = ({ employee }) => {
  const [scrollPos] = useScrollPosition('y');

  return (
    <Tab.Container id="employee-form-tabs" defaultActiveKey="Profile" mountOnEnter>
      <Nav variant="pills" fill className={`employee  ${scrollPos >= 11 && 'shadow-bottom'}`}>
        <Nav.Item>
          <Nav.Link eventKey="Profile">
            <Icon icon="person" />
            Ficha
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="Profile">
          <EmployeeInfo employee={employee} admin />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default EmployeeTabs;
