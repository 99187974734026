import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import { employeeShiftRequest, updateShiftRequest, showShiftRequest } from '../../../requests/shifts';

import { sendAlert } from '../../../actions/utils';
// import { LinkBtn, Icon } from '../../../components';
import ShiftForm from './ShiftForm';
import ShiftMonth from './ShiftMonth';
import ShiftRotative from './ShiftRotative';
import basicShift from './shift';

const ShiftInfo = () => {
  const [shift, setShift] = useState(basicShift);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchShift = matchId => {
    const shiftId = matchId;
    setOnRequest(true);
    showShiftRequest(shiftId, {
      dispatch,
      successCallback: response => setShift(camelCaseRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };

  const EmployeeShift = () => {
    employeeShiftRequest({
      dispatch,
      params: { emp_id: '0' },
      successCallback: response => {
        if (response.data) {
          fetchShift(response.data.id);
        } else {
          setShift(null);
          setOnRequest(false);
        }
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(EmployeeShift, []);

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Turno actualizado con éxito' }));
    history.push('/shifts');
  };

  const handleUpdateRequest = (shiftValues, setSubmitting) => {
    const { id } = shiftValues.shift;
    const daysAttributes = shiftValues.shift.shiftDaysAttributes.filter(item => item && item.send !== '');
    const myParams = {
      shift: {
        ...shiftValues.shift,
        shiftDaysAttributes: daysAttributes.map(shiftDay => {
          if (!shiftDay.send) return { ...shiftDay, _destroy: 'true' };
          return shiftDay;
        })
      }
    };

    updateShiftRequest(id, {
      dispatch,
      params: snakeCaseKeys(myParams, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: error => {
        handleFailureRequest(error);
        setSubmitting(false);
      }
    });
  };

  const body = () => {
    if (shift) {
      switch (shift.shiftType) {
        case 'monthly':
          return <ShiftMonth shift={shift} action="edit" formRequest={handleUpdateRequest} />;
        case 'rotative':
          return <ShiftRotative shift={shift} action="edit" formRequest={handleUpdateRequest} />;
        default:
          return <ShiftForm shift={shift} action="edit" formRequest={handleUpdateRequest} />;
      }
    } else {
      return <h3>No tienes turno asignado</h3>;
    }
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        {/* Se deja comentado este botón mientras se decide qué hacer con respecto a los turnos */}
        {/* <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/shifts">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col> */}
        <Col>
          <h2 className="mb-3 mt-3">Mi Turno</h2>
        </Col>
      </Row>
      {!onRequest && body()}
    </>
  );
};

export default ShiftInfo;
