import React, { useState, useMemo } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Form as BSForm } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  FormikSelect,
  CheckBoxBtn,
  FormikInput,
  FormikRangePicker,
  EmployeeSearchDataTable
} from '../../../components';
import { statuses, priorities } from './FormOptions';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const CompanyTaskForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  companyTask,
  isEmployee,
  rrhh
}) => {
  const { startDate, endDate, sendEmail, sendNotification, status, priority } = values.companyTask;
  const [selector, setSelector] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const { currentEmployee } = useSelector(state => state.auth);
  const customParams = useMemo(() => (rrhh ? {} : { boss_and_employees: currentEmployee }), [currentEmployee, rrhh]);

  const handleSuccessEmployees = response => {
    const vEmployees = camelCaseEmptyStringRecursive(response?.data?.data) || [];
    const { employees } = companyTask;
    const employeeIds = employees.map(item => item.id);

    const employeesToAdd = vEmployees.filter(selected => !employeeIds.includes(selected.id));
    const vSelector = [...selectorTypes, ...employeesToAdd];
    setSelector(vSelector);
  };

  const removeFromTable = (employee, action) => {
    if (action === 'destroy') {
      const { employees: companyTaskEmployees } = values.companyTask;
      const notRemovedEmployees = companyTaskEmployees.filter(selected => selected.id !== employee.id);
      const addToSelector = [...selector, employee];
      setSelector(addToSelector);
      setFieldValue('companyTask[employees]', notRemovedEmployees);
      if (notRemovedEmployees.length === 0) setFieldValue('companyTask[employeeIds]', ['']);
      else
        setFieldValue(
          'companyTask[employeeIds]',
          notRemovedEmployees.map(item => item.id)
        );
    }
  };

  const removeAllFromTable = () => {
    const selectedRowsIds = selectedRows.map(item => item.id);
    const notRemovedEmployees = values.companyTask.employees.filter(selected => !selectedRowsIds.includes(selected.id));
    setSelector([...selectorTypes, ...selector, ...selectedRows].filter(item => item.label));
    setFieldValue('companyTask[employees]', notRemovedEmployees);
    setFieldValue(
      'companyTask[employeeIds]',
      notRemovedEmployees.map(item => item.id)
    );
    setSelectedCount(0);
    setSelectedRows([]);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const addAllEmployees = () => {
    const { companyTask: companyTaskValues } = values;
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const selectedEmployeeIds = vSelector.map(employee => employee.value);
    const selectedEmployees = vSelector.map(employee => ({ ...employee }));
    setSelector([selectorTypes]);
    setFieldValue('employeeSelector', '');
    setFieldValue('companyTask[employees]', [...selectedEmployees, ...companyTaskValues.employees]);
    setFieldValue('companyTask[employeeIds]', [...selectedEmployeeIds, ...companyTaskValues.employeeIds]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== values.employeeSelector);
    if (vSelector.length === selector.length) {
      return;
    }
    if (values.employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === values.employeeSelector);
      setFieldValue(`companyTask[employeeIds]`, [...values.companyTask.employeeIds, employeeToAdd.value]);
      setFieldValue('companyTask[employees]', [...values.companyTask.employees, employeeToAdd]);
      setSelector(vSelector);
    }
  };

  const handleSearch = selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    if (vSelector.length === selector.length) return;
    const employeesToAdd = selector.filter(selected => selectedEmployees.includes(selected.value));
    setFieldValue(`companyTask[employeeIds]`, [
      ...values.companyTask.employeeIds,
      ...employeesToAdd.map(item => item.value)
    ]);
    setFieldValue('companyTask[employees]', [...values.companyTask.employees, ...employeesToAdd]);
    setSelector(vSelector);
  };

  return (
    <Form>
      <Row>
        <Col md={isEmployee ? 12 : 6}>
          <Field name="companyTask[title]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Titulo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={isEmployee ? 12 : 6}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                abbr
                startDateName="companyTask[startDate]"
                endDateName="companyTask[endDate]"
                startDate={startDate}
                endDate={endDate}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={isEmployee ? 12 : 6}>
          <Field name="companyTask[description]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                label="Descripción"
                minInputHeight="120"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {!isEmployee && (
          <Col md={6}>
            <Row>
              <Col md={6}>
                <BSForm.Group className="button-group max-width-group">
                  <BSForm.Label>Enviar Email</BSForm.Label>
                  <Row>
                    <Field name="companyTask[sendEmail]">
                      {({ field }) => <CheckBoxBtn {...field} id="sendEmail" label="Si" checked={sendEmail} />}
                    </Field>
                    <Field name="companyTask[sendEmail]">
                      {({ field }) => <CheckBoxBtn {...field} id="sendEmail" label="No" checked={!sendEmail} />}
                    </Field>
                  </Row>
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <BSForm.Group className="button-group max-width-group">
                  <BSForm.Label>Enviar Notificación</BSForm.Label>
                  <Row>
                    <Field name="companyTask[sendNotification]">
                      {({ field }) => (
                        <CheckBoxBtn {...field} id="sendNotification" label="Si" checked={sendNotification} />
                      )}
                    </Field>
                    <Field name="companyTask[sendNotification]">
                      {({ field }) => (
                        <CheckBoxBtn {...field} id="sendNotification" label="No" checked={!sendNotification} />
                      )}
                    </Field>
                  </Row>
                </BSForm.Group>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={isEmployee ? 12 : 6}>
          <Field name="companyTask[priority]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Prioridad"
                placeholder="Seleccionar prioridad"
                defaultValue={priority}
                options={priorities}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={isEmployee ? 12 : 6}>
          <Field name="companyTask[status]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Estado"
                placeholder="Seleccionar estado"
                options={statuses}
                defaultValue={status}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {!isEmployee && (
        <>
          <hr className="mb-4" />
          <div className="info-box ml-0 mt-5">
            <h4 className="text-uppercase">Selecciona Trabajadores</h4>
            <p className="info">
              <span className="full-width">Seleccione trabajadores a los que se le asignará esta tarea</span>
            </p>
          </div>
          <EmployeeSearchDataTable
            baseModel={companyTask}
            employees={values.companyTask.employees}
            handleActions={removeFromTable}
            handleMassAction={removeAllFromTable}
            handleSearch={handleSearch}
            handleSelectedRows={handleSelectedRows}
            handleSelector={handleSelector}
            handleSuccessEmployees={handleSuccessEmployees}
            options={selector}
            selectedCount={selectedCount}
            advanceSearch={rrhh}
            customParams={customParams}
          />
        </>
      )}
      <Row className={`${!isEmployee && 'd-flex justify-content-end my-5'}`}>
        <Col md={isEmployee ? 12 : 3}>
          <Button type="submit" disabled={isSubmitting} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { employeeIds, endDate, startDate, sendEmail, sendNotification } = props.companyTask;
  const { selectedDate, notSendNotification, currentEmployee } = props;

  return {
    companyTask: {
      ...props.companyTask,
      employeeIds: selectedDate ? [currentEmployee] : employeeIds,
      endDate: selectedDate || endDate,
      startDate: selectedDate || startDate,
      sendEmail: notSendNotification ? false : sendEmail,
      sendNotification: notSendNotification ? false : sendNotification
    },
    employeeSelector: 'all_employees',
    rangeDate: [startDate || selectedDate, endDate || selectedDate]
  };
};

const validationSchema = Yup.object().shape({
  companyTask: Yup.object().shape({
    description: Yup.string(),
    priority: Yup.string().required('Debes seleccionar una prioridad'),
    status: Yup.string().required('Debes seleccionar un estado'),
    title: Yup.string()
      .max(50, 'Deben ser menos que 50 caracteres')
      .required('Debes ingresar un título')
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CompanyTaskForm);
