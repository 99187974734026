import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { Icon, LinkBtn } from '../../components';
import { createSettlementGroupRequest } from '../../requests/settlementGroups';
import SettlementGroupForm from './Form/SettlementGroupForm';
import basicSettlementGroup from './basicSettlementGroup';

const SettlementGroupNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessRequest = response => {
    const { id } = response.data;
    history.push(`/settlement_groups/${id}`);
  };

  const handleCreateRequest = (params, setSubmitting) => {
    createSettlementGroupRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to="/settlements">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={9} className="mt-3">
          <h2 className="text-uppercase mt-1">Simulador Finiquito</h2>
        </Col>
      </Row>
      <SettlementGroupForm action="new" settlementGroup={basicSettlementGroup} formRequest={handleCreateRequest} />
    </>
  );
};

export default SettlementGroupNew;
