import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showSlideRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/slides/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexSlidesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/slides${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createSlideRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/slides', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateSlideRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/slides/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteSlideRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/slides/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexSlidesRequest = AwesomeDebouncePromise(
  indexSlidesRequest,
  300
);
