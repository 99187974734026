const announcementTypes = [
  { label: 'Anuncio', value: 'post' },
  { label: 'Calendario', value: 'event' }
];

const statuses = [
  { label: 'Visible', value: 'visible' },
  { label: 'Oculto', value: 'hidden' }
];

export { announcementTypes, statuses };
