export default {
  announcementType: '',
  description: '',
  endDate: '',
  fileInfo: {},
  groupId: '',
  startDate: '',
  status: '',
  title: '',
  withEmail: false,
  withNotification: false
};
