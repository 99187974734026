import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../config/abilityContext';
import { ButtonTooltip, Icon } from '../../components';

const ContractVersionTable = ({ contractVersions, contractId, employeeId }) => {
  const ability = useAbility(AbilityContext);
  const history = useHistory();

  const showContractVersion = version => {
    history.push(`/contract_versions/${version.id}?resource=${contractId}&employee=${employeeId}`);
  };

  const renewContract = version => {
    history.push(`/employees/${employeeId}/edit`, { contract: version.old_contract });
  };

  return (
    <div className="default-scroll-box negative-margin">
      {contractVersions.map((body, index) => (
        <Row className="version-index" key={`version-index-${index.toString()}`}>
          <Col xs={8} sm={9} lg={10}>
            <Row>
              <Col xs={1} className="text-center">
                <span className="dot dot-info" />
              </Col>
              <Col xs={9} md={7} className="p-0">
                <p className="text-uppercase mb-3 mb-lg-2">
                  <strong>Contrato Versión {body.version_number}</strong>
                </p>
                <div className="d-flex flex-column flex-lg-row flex-lg-wrap">
                  <p className="mb-1 mr-2">
                    Tipo de Contrato: <strong>{body.old_contract?.translated_contract_type}</strong>
                  </p>
                  <p className="mb-1 mr-2">
                    Fecha de Inicio: <strong>{body.old_contract?.start_date}</strong>
                  </p>
                  {body.old_contract?.end_date && (
                    <p className="mb-1 mr-2">
                      Fecha de Término: <strong>{body.old_contract?.end_date}</strong>
                    </p>
                  )}
                  <p className="mb-1 mr-2">
                    Cargo:
                    <strong className="ml-1">{body.old_contract?.job_title?.label}</strong>
                  </p>
                  {body.old_contract?.job_management && (
                    <p className="mb-1 mr-2">
                      Área:
                      <strong className="ml-1">{body.old_contract?.job_management?.label}</strong>
                    </p>
                  )}
                  {ability.can('private_role', 'Employee') && (
                    <p className="mb-1 mr-2">
                      Sueldo:
                      <strong className="ml-1">{body.old_contract?.parsed_salary}</strong>
                    </p>
                  )}
                </div>
              </Col>
              <Col className="ml-3 ml-md-0 mt-3 mt-md-0 d-flex align-items-center" xs={12} md={4}>
                <p>{body.created_at}</p>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex" xs={1} sm={3} md={2}>
            <ButtonTooltip
              onClick={() => showContractVersion(body)}
              variant="circle-info"
              className="btn-circle mx-md-3"
              size="sm"
              text="Ver"
            >
              <Icon icon="eye" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => renewContract(body)}
              variant="circle-warning"
              className="btn-circle ml-2 ml-md-0"
              size="sm"
              text="Renovar Contrato"
            >
              <Icon icon="pencil" />
            </ButtonTooltip>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ContractVersionTable;
