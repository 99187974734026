import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { debounceIndexCompanyTaskRequest } from '../../../requests/companyTasks';

const TeamTasks = ({ handleMoreInfoButton }) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [showSpinner, setShowSpinner] = useState(1);

  const handleIndexRequest = () => {
    const request = async () =>
      debounceIndexCompanyTaskRequest({
        dispatch,
        params: {
          filter_status: 'pending',
          sort_column: 'end_date'
        },
        successCallback: res => {
          setTasks(res.data.data.slice(res.data.data.length - 2));
          setShowSpinner(0);
        }
      });
    request();
  };
  useEffect(handleIndexRequest, []);

  return (
    <Card className="mb-1">
      <Col className="top-header-green">
        <Col className="card-header-title">TAREAS DE MI EQUIPO</Col>
      </Col>
      {showSpinner ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            {tasks.length > 0 ? (
              tasks.map(item => (
                <Col key={item.id} className="col-sm-6 text-center">
                  <p className="mb-1 mt-1">
                    <b>Nombre: </b>
                    {`${item.title}`}
                  </p>
                  <p className="mb-1 mt-1">
                    <b>Vencimiento: </b>
                    {`${item.end_date}`}
                  </p>
                  <p className="mb-1 mt-1">
                    <b>Prioridad: </b>
                    {`${item.translated_priority}`}
                  </p>
                  <p className="mb-1 mt-1">
                    <b>Estatus: </b>
                    {item.translated_status}
                  </p>
                </Col>
              ))
            ) : (
              <Col className="div-content">
                <Row>
                  <Col className="mb-2 mt-2">
                    <div style={{ height: 130, textAlign: 'center' }} className="div-center">
                      Sin tareas...
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          {tasks.length > 0 && (
            <Row>
              <Col sm={8}>&nbsp;</Col>
              <Col sm={4}>
                <Button variant="light-gray" className="mb-1 mt-3" block onClick={handleMoreInfoButton} size="sm">
                  Ver más
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Card>
  );
};

export default TeamTasks;
