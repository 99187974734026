const statuses = [
  { label: 'Pendiente', value: 'pending' },
  { label: 'Terminada', value: 'finished' }
];

const priorities = [
  { label: 'Baja', value: 'low' },
  { label: 'Media', value: 'medium' },
  { label: 'Alta', value: 'high' }
];

export { statuses, priorities };
