import React from 'react';
import { useLocation } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import { Button, Col, Nav, Row, Tab } from 'react-bootstrap';
import * as Yup from 'yup';

import { LinkBtn } from '../../../components';
import { useSetTab } from '../../../services/hooks';
import SettlementInfo from '../SettlementInfo';
import SettlementAssetForm from './SettlementAssetForm';
import SettlementDiscountForm from './SettlementDiscountForm';
import SettlementGeneralForm from './SettlementGeneralForm';
import SettlementPayForm from './SettlementPayForm';
import SettlementStepMap from './SettlementStepMap';

const SettlementForm = ({ isSubmitting, setStep, settlement, step, toggleStep }) => {
  const location = useLocation();
  const [key, setKey] = useSetTab('assets', location);

  return (
    <Form>
      <SettlementStepMap setStep={setStep} step={step} steps={['General', 'Indicadores', 'Total a Pagar', 'Resumen']} />

      {step === 1 && (
        <>
          <h4 className="text-uppercase text-dark mt-2 mb-4">Información General</h4>
          <SettlementGeneralForm settlement={settlement} />
        </>
      )}

      {step === 2 && (
        <>
          <h4 className="text-uppercase text-dark mt-2 mb-4">Indicadores</h4>
          <Tab.Container
            id="settlement-indicators-datatables.tabs"
            activeKey={key}
            onSelect={k => setKey(k)}
            mountOnEnter
          >
            <Nav variant="pills" className="ab-pills">
              <Nav.Item>
                <Nav.Link eventKey="assets">Haberes</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="discounts">Descuentos</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="assets">
                <SettlementAssetForm settlement={settlement} />
              </Tab.Pane>
              <Tab.Pane eventKey="discounts">
                <SettlementDiscountForm settlement={settlement} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </>
      )}

      {step === 3 && (
        <>
          <h4 className="text-uppercase text-dark mt-2 mb-4">Total a Pagar</h4>
          <SettlementPayForm settlement={settlement} />
        </>
      )}

      {step === 4 && (
        <>
          <h4 className="text-uppercase text-dark mt-2 mb-4">Resumen Total por Etapas</h4>
          <SettlementInfo settlement={settlement} />
        </>
      )}

      <Row className="my-4">
        <Col md={2} className="mt-3">
          <LinkBtn block variant="outline-info" to="/settlements">
            Cancelar
          </LinkBtn>
        </Col>
        <Col md={2} className="mt-3">
          <Button block variant="outline-info" onClick={() => toggleStep({ back: true })}>
            Volver
          </Button>
        </Col>
        <Col md={{ span: 4, offset: 4 }}>
          <Row className="d-flex justify-content-end">
            {step !== 4 && (
              <Col md={6} className="mt-3">
                <Button block variant="info" onClick={toggleStep}>
                  Siguiente
                </Button>
              </Col>
            )}
            <Col md={6} className="mt-3">
              <Button block type="submit" variant="primary" disabled={isSubmitting}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ settlement }) => ({ settlement });

const validationSchema = Yup.object().shape({
  settlement: Yup.object().shape({
    justification: Yup.string().required('Debes ingresar una justificación de la causal'),
    name: Yup.string().required('Debes ingresar un nombre de grupo'),
    paymentDate: Yup.date().required('Debes ingresar una fecha de pago'),
    terminationDate: Yup.date().required('Debes ingresar una fecha de término'),
    terminationReason: Yup.string().required('Debes seleccionar una causal de término')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SettlementForm);
