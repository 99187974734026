import React from 'react';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const ItemsInfo = props => {
  const { type, item } = camelCaseEmptyStringRecursive(props);

  return (
    <div className="info-box">
      {type !== 'loan' ? (
        <p className="info">
          <span>Nombre:</span> {item.name}
        </p>
      ) : (
        <p className="info">
          <span>Solicita:</span> {item.employee.shortName}
        </p>
      )}
      <p className="info">
        <span>Fecha de Solicitud:</span> {item.createdAt}
      </p>
      <p className="info">
        <span>Monto:</span> {item.parsedAmount}
      </p>

      {type === 'advancePayment' ? (
        <>
          <p className="info">
            <span>Opción de Pago:</span> {item.translatedPaymentType}
          </p>
          <p className="info">
            <span>Mes de Inicio:</span> {item.startDate}
          </p>
          <p className="info">
            <span>Mes de Término:</span> {item.permanent ? 'Permanente' : item.endDate}
          </p>
          {item.reason && (
            <p className="info">
              <span>Motivo del Anticipo:</span> {item.reason}
            </p>
          )}
          {item.observation && (
            <p className="info">
              <span>Motivo de Rechazo:</span> {item.observation}
            </p>
          )}
          {item.documents.length > 0 && (
            <>
              <h4>Documentos asociados</h4>
              {item.documents.map(document => (
                <div className="info" key={`advance-payment-document-${document.id}`}>
                  <a
                    target="_blank"
                    className="font-weight-bold underline text-primary"
                    rel="noopener noreferrer"
                    href={`/document_preview/${document.id}?employee=${item.employee?.id}`}
                  >
                    {document.name}
                  </a>
                </div>
              ))}
            </>
          )}
        </>
      ) : (
        type === 'loan' && (
          <>
            <p className="info">
              <span>Fecha Otorgamiento Crédito:</span> {item.grantDate}
            </p>
            <p className="info">
              <span>Fecha Primer Descuento:</span> {item.startDate}
            </p>
            <p className="info">
              <span>Estado de la Solicitud:</span> {item.translatedStatus}
            </p>
            {item.resolvedDate && (
              <p className="info">
                <span>Fecha de Respuesta:</span> {item.resolvedDate}
              </p>
            )}
            {item.reason && (
              <p className="info">
                <span>Motivo de Préstamo:</span> {item.reason}
              </p>
            )}
            {item.pendingFees && (
              <p className="info">
                <span>Cuotas Pendientes:</span> {item.pendingFees}
              </p>
            )}
            {item.rejectionReason && (
              <p className="info">
                <span>Motivo de Rechazo:</span> {item.rejectionReason}
              </p>
            )}
          </>
        )
      )}
    </div>
  );
};

export default ItemsInfo;
