import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Icon from '../../Icons';
import LinkBtn from '../../Utils/Button/LinkBtn';

const MyDocuments = () => {
  return (
    <Card className="mb-1">
      <Col className="top-header-light-gray">
        <Col className="card-header-title">MIS DOCUMENTOS</Col>
      </Col>
      <Col className="div-content">
        <Row>
          <Col sm={6} xs={4} md={4} className="mb-3 mt-3 text-center">
            <LinkBtn to="documents" block>
              <Icon icon="document" className="icon-30" />
            </LinkBtn>
            Contrato
          </Col>
          <Col sm={6} xs={4} md={4} className="mb-3 mt-3 text-center">
            <LinkBtn to="documents" block>
              <Icon icon="document-attach" className="icon-30" />
            </LinkBtn>
            Anexo Contrato
          </Col>
          <Col sm={6} xs={4} md={4} className="mb-3 mt-3 text-center">
            <LinkBtn to="documents" block>
              <Icon icon="school" className="icon-30" />
            </LinkBtn>
            Certificados
          </Col>
        </Row>
      </Col>
    </Card>
  );
};

export default MyDocuments;
