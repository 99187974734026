import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col, Accordion } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { terminateContractRequest } from '../../../requests/contracts';
import createTemplatePdfRequest from '../../../requests/templatePdfs';
import { AbilityContext } from '../../../config/abilityContext';
import { sendAlert } from '../../../actions/utils';
import AnnexContractForm from '../../TemplatePdfs/AnnexContractForm';
import { downloadFile } from '../../../services/utils';
import { BasicDropdown, Icon, SimpleCenteredModal, DefaultModal } from '../..';
import ContractTerminationModal from '../../../screens/Contract/ContractTerminationModal';
import ContractTerminationNotice from '../../../screens/Contract/ContractTerminationNotice';
import ContractCertificate from '../../../screens/Contract/ContractCertificate';
import ContractVersionIndex from '../../../screens/ContractVersion/ContractVersionIndex';
import TerminatedContractIndex from '../../../screens/Contract/TerminatedContractIndex';
import ContractInfoContent from './ContractInfoContent';
import DocumentPreview from '../../../screens/DocumentPreview/DocumentPreview';

const defaultMessage =
  'Existe al menos un contrato activo para este empleado. ¿Está seguro que desea generar otro contrato?';

const ContractInfo = ({ contract, employeeId, employee, getEmployee }) => {
  const ability = useAbility(AbilityContext);
  const [activeKey, setKey] = useState('info');
  const [onRequest, setOnRequest] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('lg');
  const { user } = useSelector(state => state.auth);
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalBody, setDefaultModalBody] = useState(defaultMessage);
  const dispatch = useDispatch();
  const { correlativeIdentification, documentTemplateId, document, haveContracts, haveVersions, status } = contract;

  const hideModal = () => {
    setModalBody('');
    setModalTitle('');
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalBody(defaultMessage);
    setOnRequest(false);
  };

  const handleSuccess = modal => {
    let message = 'Contrato creado exitosamente';
    if (modal) {
      message = 'Anexo de contrato creado exitosamente';
      hideModal();
    }
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const createPdfRequest = (params, modal) => {
    setOnRequest(true);
    createTemplatePdfRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        handleSuccess(modal);
        getEmployee(employeeId);
      },
      callback: hideModal
    });
  };

  const terminateContract = (params, contractId) => {
    terminateContractRequest(contractId, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: response => {
        const message = 'Término de contrato guardado exitosamente';
        getEmployee(employeeId);
        downloadFile(response);
        dispatch(sendAlert({ kind: 'success', message }));
      },
      callback: hideModal
    });
  };

  const noticeEmployee = (params, setSubmitting) => {
    const myParams = snakeCaseKeys({ ...params, templatePdf: { ...params.templatePdf, employeeIds: [employeeId] } });
    createTemplatePdfRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        const message = 'Carta enviada con éxito';
        dispatch(sendAlert({ kind: 'success', message }));
      },
      callback: () => {
        hideModal();
        setSubmitting(false);
      }
    });
  };

  const handleCreateRequest = templatePdf => {
    createPdfRequest(snakeCaseKeys(templatePdf), true);
  };

  const showContractPdf = () => {
    setModalBody(<DocumentPreview documentId={document.id} employeeId={employeeId} />);
    setModalTitle('');
    setModalSize('xl');
    setModalShow(true);
  };

  const handleDocumentRequest = () => {
    showContractPdf();
  };

  const handleContractPdf = () => {
    const myParams = {
      template_pdf: {
        employee_ids: [employeeId],
        document_type: 'work_contract'
      }
    };
    createPdfRequest(myParams);
  };

  const handleGenerateContract = () => {
    if (employee.haveActiveContracts || document.status === 'approved') {
      setDefaultModalShow(true);
    } else if (document.status === 'pending' || document.status === 'not_signed') {
      setDefaultModalBody(
        'Ya existe al menos un documento generado para este contrato. ¿Está seguro que desea generar otro documento?'
      );
      setDefaultModalShow(true);
    } else {
      handleContractPdf();
    }
  };

  const handleGenerateContractAnnex = () => {
    setModalBody(<AnnexContractForm formRequest={handleCreateRequest} employeeIds={[employeeId]} />);
    setModalTitle('Anexo Contrato');
    setModalSize('lg');
    setModalShow(true);
  };

  const showTerminationModal = () => {
    setModalBody(
      <ContractTerminationModal
        hideModal={hideModal}
        formRequest={values => terminateContract(values, contract.id)}
        employeeIds={[employeeId]}
      />
    );
    setModalTitle(`Finiquitar Contrato N°${correlativeIdentification}`);
    setModalSize('lg');
    setModalShow(true);
  };

  const showContractsIndex = () => {
    setModalBody(<TerminatedContractIndex hideModal={hideModal} employeeId={employeeId} />);
    setModalTitle(`Contratos Finiquitados`);
    setModalSize('lg');
    setModalShow(true);
  };

  const showContractTerminationNotice = () => {
    setModalBody(<ContractTerminationNotice hideModal={hideModal} formRequest={noticeEmployee} />);
    setModalTitle('Emitir Carta de Aviso de Término de Contrato');
    setModalSize('lg');
    setModalShow(true);
  };

  const showContractVersionsIndex = () => {
    setModalBody(<ContractVersionIndex hideModal={hideModal} contractId={contract.id} employeeId={employeeId} />);
    setModalTitle(`Historial Contrato N°${correlativeIdentification}`);
    setModalSize('lg');
    setModalShow(true);
  };

  const generateCertificate = (title, customParams) => {
    setModalBody(
      <ContractCertificate customParams={customParams} hideModal={hideModal} formRequest={noticeEmployee} />
    );
    setModalTitle(title);
    setModalSize('lg');
    setModalShow(true);
  };

  const dropdownItems = [];
  if (ability.can('create_document', 'WorkflowRequest')) {
    dropdownItems.push({
      key: 'document-request',
      title: 'Enviar A Firmar',
      props: {
        onClick: handleDocumentRequest,
        disabled: !document?.fileInfo?.fileUrl || contract.status === 'finished'
      }
    });
  }
  if (ability.can('generate_contract', 'Document')) {
    dropdownItems.push({
      key: 'generate-contract',
      title: 'Generar Contrato',
      props: {
        onClick: handleGenerateContract,
        disabled: !documentTemplateId || contract.status === 'finished' || !employee.active
      }
    });
  }
  if (ability.can('show_contract', 'Document')) {
    dropdownItems.push({
      key: 'see-contract',
      title: 'Ver Contrato',
      props: {
        onClick: showContractPdf,
        disabled: !document?.fileInfo?.fileUrl
      }
    });
  }
  if (ability.can('generate_annex', 'Document')) {
    dropdownItems.push({
      key: 'generate-annex',
      title: 'Generar Anexo de Contrato',
      props: {
        onClick: handleGenerateContractAnnex,
        disabled: contract.status === 'finished' || !employee.active
      }
    });
  }

  if (ability.can('update', 'Contract')) {
    dropdownItems.push({
      key: 'contract-termination',
      title: 'Terminar Contrato',
      props: {
        onClick: showTerminationModal,
        disabled: contract.status === 'finished'
      }
    });

    dropdownItems.push({
      key: 'termination-card-notice',
      title: ' Emitir Carta de Aviso de Término de Contrato',
      props: {
        onClick: showContractTerminationNotice
      }
    });
  }

  if (ability.can('read', 'Contract') && haveContracts) {
    dropdownItems.push({
      key: 'terminated-contracts',
      title: 'Ver Contratos Finiquitados',
      props: {
        onClick: showContractsIndex
      }
    });
  }

  if (ability.can('read', 'ContractVersion') && haveVersions) {
    dropdownItems.push({
      key: 'contract-versions',
      title: 'Ver Historial',
      props: {
        onClick: showContractVersionsIndex
      }
    });
  }
  if (contract.status === 'active') {
    dropdownItems.push({
      key: 'felicitation',
      title: 'Emitir Carta de Felicitación',
      props: {
        onClick: () => generateCertificate('Carta de Felicitación', { template_type: 'congratulation_letter' })
      }
    });
    dropdownItems.push({
      key: 'reprimand',
      title: 'Emitir Carta de Amonestación',
      props: {
        onClick: () => generateCertificate('Carta de Amonestación', { template_type: 'reprimand_document' })
      }
    });
    dropdownItems.push({
      key: 'certificate',
      title: 'Emitir Certificado',
      props: {
        onClick: () => generateCertificate('Certificado', { template_type: ['certificate', 'other_documents'] })
      }
    });
  }

  return (
    <>
      <Row className="mt-3">
        <Col md={9}>
          <h3 className="text-uppercase ml-2">
            Contrato {status === 'active' ? 'Vigente' : 'Finiquitado'} N°{correlativeIdentification}
          </h3>
        </Col>
        {user.role !== 'dt' && dropdownItems.length > 0 && (
          <Col md={2} className="ml-4 mr-1">
            <BasicDropdown
              variant="outline-primary"
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
                </span>
              }
              items={dropdownItems}
            />
          </Col>
        )}
      </Row>
      <Accordion defaultActiveKey="0" className="ml-2 mr-2">
        <Row>
          <Col className="mt-1">
            <ContractInfoContent
              contract={contract}
              activeKey={activeKey}
              activeAccordion={() => setKey('info')}
              employee={employee}
            />
          </Col>
        </Row>
      </Accordion>
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} size={modalSize} onHide={hideModal} />
      <DefaultModal
        title="Generar Contrato"
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        handleConfirm={handleContractPdf}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default ContractInfo;
