import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComponentDataTable } from '../../components';
import { debounceIndexAttendanceRequest } from '../../requests/attendances';
import columns from './Columns';

const AttendanceHashDataTable = ({ moreData, customParams }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data } = response.data;
    setAttendances(data);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    if (customParams.filter_by_hash) {
      setOnRequest(true);
      const request = async () =>
        debounceIndexAttendanceRequest({
          dispatch,
          params: { ...params, ...customParams },
          successCallback: handleSuccessIndex
        });
      request();
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        pointerOnHover
        preName="attendances"
        resourceRequest={handleRequest}
        columns={columns()}
        data={attendances}
        totalRows={0}
        handleSortCase={sortColumnCase}
        onRequest={onRequest}
        moreData={moreData}
        withSearch={false}
      />
    </>
  );
};

export default AttendanceHashDataTable;
