import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import * as Yup from 'yup';
import { Button, Row, Col, Table } from 'react-bootstrap';
import '../../services/yupCustomMethods';

import { indexEmployeesRequest } from '../../requests/employees';
import { workdaysBetweenHolidayRequest, reincorporationDateHolidayRequest } from '../../requests/holidays';
import { handleDaysShow, sortByAttribute } from '../../services/utils';
import {
  ButtonTooltip,
  DatatableEmployeeName,
  FormikDatePicker,
  FormikSelect,
  Icon,
  SimpleCenteredModal,
  EmployeeSearchModal
} from '../../components';
import { absenceTypes, absenceTypeFormats, selectorTypes } from './FormOptions';

const basicAbsence = () => ({
  absenceType: '',
  absenceTypeFormat: '',
  startDate: '',
  endDate: '',
  days: '',
  reincorporation_date: '',
  observation: '',
  employeeId: ''
});

const licenseTypeValues = () => {
  const licenseRegex = new RegExp(/Licencia/);
  return absenceTypes.filter(type => licenseRegex.test(type.label)).map(type => type.value);
};

const AbsencesForm = props => {
  const { setFieldValue, values, isSubmitting } = props;
  const [selector, setSelector] = useState(selectorTypes);
  const [modalShow, setModalShow] = useState(false);
  const [typeFormatsDisabled, setTypeFormatsDisabled] = useState([]);
  const dispatch = useDispatch();
  const { absences } = values;

  const handleSuccessIndex = response => {
    const vEmployees = camelCaseRecursive(response.data.data);
    const vSelector = [...selectorTypes, ...vEmployees];
    setSelector(vSelector);
  };

  const fetchEmployees = () => {
    indexEmployeesRequest({
      dispatch,
      params: { active: true, is_dt: false, paginate: false },
      successCallback: handleSuccessIndex
    });
  };

  useEffect(fetchEmployees, []);

  const addAllEmployees = () => {
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const employeesToAdd = vSelector.map(employee => ({ ...basicAbsence(), employeeId: employee.value, employee }));
    const vAbsences = [...absences, ...employeesToAdd];
    setSelector([]);
    setFieldValue('absences', vAbsences);
  };

  const handleSelector = () => {
    const { employeeSelector } = props.values;
    const vSelector = selector.filter(selected => selected.value !== employeeSelector);
    if (vSelector.length === selector.length) {
      return;
    }
    if (employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === employeeSelector);
      const absencesLength = absences.length;
      setFieldValue(`absences[${absencesLength}]`, {
        ...basicAbsence(),
        employeeId: employeeToAdd.value,
        employee: employeeToAdd
      });
      setSelector(vSelector);
    }
  };

  const removeFromTable = (absence, action) => {
    if (action === 'destroy') {
      const notRemovedEmployees = absences.filter(selected => selected.employee.id !== absence.employee.id);
      const addToSelector = [...selector, absence.employee];
      setSelector(addToSelector);
      setFieldValue('absences', notRemovedEmployees);
    }
  };

  const calculateDays = ({ absence, index, startDate, endDate }) => {
    let newStartDate = '';
    let newEndDate = '';
    if (startDate) {
      newStartDate = startDate;
      newEndDate = absence.endDate;
    }
    if (endDate) {
      newStartDate = absence.startDate;
      newEndDate = endDate;
    }
    workdaysBetweenHolidayRequest({
      dispatch,
      params: {
        start_date: newStartDate,
        end_date: newEndDate,
        include_all_days: true
      },
      successCallback: response => setFieldValue(`absences[${index}][days]`, response.data)
    });
  };

  const calculateReincorporationDate = ({ index, endDate }) => {
    reincorporationDateHolidayRequest({
      dispatch,
      params: {
        end_date: endDate,
        employee_id: absences[index].employee.id
      },
      successCallback: response => setFieldValue(`absences[${index}][reincorporationDate]`, response.data.date)
    });
  };

  const updateReincorporationDate = ({ absence, index, endDate }) => {
    calculateDays({ absence, index, endDate });
    calculateReincorporationDate({ index, endDate });
  };

  const handleSearch = selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    setModalShow(false);
    if (vSelector.length === selector.length) return;
    const employeesToAdd = selector
      .filter(selected => selectedEmployees.includes(selected.value))
      .map(employee => ({ ...basicAbsence(), employeeId: employee.value, employee }));
    setFieldValue('absences', [...values.absences, ...employeesToAdd]);
    setSelector(vSelector);
  };

  const handleAbsenceTypeFormat = (absenceType, index) => {
    const newFormatsDisabled = [...typeFormatsDisabled];

    if (!licenseTypeValues().includes(absenceType)) {
      newFormatsDisabled[index] = true;
      setTypeFormatsDisabled(newFormatsDisabled);
      setFieldValue(`absences[${index}][absenceTypeFormat]`, '');
    } else {
      newFormatsDisabled[index] = false;
      setTypeFormatsDisabled(newFormatsDisabled);
    }
  };

  const { onHide, submitVariant, errors, touched, action, setFieldTouched } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  return (
    <Form>
      <div className="info-box ml-0">
        <h4 className="text-uppercase">Agregar Receptores</h4>
        <p className="info">
          <span className="full-width">Seleccione trabajadores para incluir en el proceso</span>
        </p>
      </div>
      <Row className="align-items-center mb-3 mb-md-0">
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Generar Para"
                placeholder="Seleccionar Trabajador"
                options={sortByAttribute(selector, 'fullName')}
                defaultValue="all_employees"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} xl={2}>
          <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
            Agregar
          </Button>
        </Col>
        <Col>
          <ButtonTooltip
            variant="circle-primary"
            className="advance-search"
            text="Búsqueda Avanzada"
            onClick={() => setModalShow(true)}
          >
            <Icon className="w-100 h-100" icon="people-circle" />
          </ButtonTooltip>
        </Col>
      </Row>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th className="text-center" style={{ minWidth: '200px' }}>
                MOTIVO
              </th>
              <th className="text-center" style={{ minWidth: '150px' }}>
                FORMATO
              </th>
              <th className="text-center" style={{ maxWidth: '170px' }}>
                INICIO
              </th>
              <th className="text-center" style={{ maxWidth: '170px' }}>
                TÉRMINO
              </th>
              <th className="text-center" style={{ maxWidth: '100px' }}>
                RETORNO
              </th>
              <th className="text-center" style={{ maxWidth: '70px' }}>
                DÍAS
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {absences.map((absence, index) => (
              <tr key={`absence-${index.toString()}`}>
                <td className="employee-name">
                  <DatatableEmployeeName
                    item={absence.employee}
                    fileName="fileInfo"
                    name="fullName"
                    fileUrl="fileUrl"
                  />
                </td>
                <td>
                  <Field name={`absences[${index}][absenceType]`}>
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        placeholder="Seleccionar"
                        options={absenceTypes}
                        defaultValue={absence.absenceType}
                        onChange={data => {
                          handleAbsenceTypeFormat(data.value, index);
                          setFieldValue(field.name, data ? data.value : '');
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`absences[${index}][absenceTypeFormat]`}>
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        placeholder="Seleccionar"
                        options={absenceTypeFormats}
                        defaultValue={absence.absenceTypeFormat}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                        isDisabled={typeFormatsDisabled[index]}
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`absences[${index}][startDate]`}>
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={day => day.isAfter(values.absences[index].filterEndDate || '')}
                        placeholder="dd/mm/aaaa"
                        onDateChange={date => {
                          const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                          calculateDays({ absence, index, startDate: vDate });
                          setFieldValue(field.name, vDate);
                          setFieldValue(`absences[${index}][filterStartDate]`, date !== null ? date : '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`absences[${index}][endDate]`}>
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={day => day.isBefore(values.absences[index].filterStartDate || '')}
                        placeholder="dd/mm/aaaa"
                        onDateChange={date => {
                          const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                          updateReincorporationDate({ absence, index, endDate: vDate });
                          setFieldValue(field.name, vDate);
                          setFieldValue(`absences[${index}][filterEndDate]`, date !== null ? date : '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td className="text-center align-middle">{absence.reincorporationDate}</td>
                <td className="text-center align-middle">{absence.days && handleDaysShow(absence, 'days')}</td>
                <td>
                  <ButtonTooltip
                    onClick={() => removeFromTable(absence, 'destroy')}
                    variant="circle-danger"
                    className="btn-circle"
                    size="sm"
                    text="Eliminar"
                  >
                    <Icon icon="trash" />
                  </ButtonTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="d-flex justify-content-end m-t-10 m-b-30">
        <Col md={3} xl={2}>
          <Button type="submit" className="mb-4" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Form>
  );
};

const setInitialValues = () => {
  return {
    absences: [],
    employeeSelector: 'all_employees'
  };
};

const validationSchema = Yup.object().shape({
  absences: Yup.array().of(
    Yup.object().shape(
      {
        endDate: Yup.date()
          .required('Debes seleccionar una fecha de término')
          .formatdate()
          .when(
            'startDate',
            (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual a la fecha de inicio')
          ),
        observation: Yup.string().nullable(),
        startDate: Yup.date()
          .required('Debes seleccionar una fecha de inicio')
          .formatdate()
          .when(
            'endDate',
            (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
          ),
        absenceType: Yup.string().required('Debes seleccionar un tipo de ausencia'),
        absenceTypeFormat: Yup.string().when('absenceType', {
          is: val => licenseTypeValues().includes(val),
          then: Yup.string().required('Debes escoger el formato de la licencia'),
          otherwise: Yup.string().nullable()
        })
      },
      [['endDate', 'startDate']]
    )
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AbsencesForm);
