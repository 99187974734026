import React, { useState, useEffect } from 'react';
import memoize from 'memoize-one';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import * as Yup from 'yup';
import { Button, Row, Col, FormControl, Form as BSForm } from 'react-bootstrap';

import { indexEmployeesRequest } from '../../requests/employees';
import { indexRolesRequest } from '../../requests/roles';
import { filterArray, sortByAttribute } from '../../services/utils';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import {
  ButtonTooltip,
  DatatableEmployeeName,
  DualList,
  FormikCheckBox,
  FormikInput,
  FormikSelect,
  Icon,
  InputSelect,
  SimpleCenteredModal,
  EmployeeSearchModal,
  ComponentDataTable
} from '../../components';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const columns = memoize(handleClick => [
  {
    name: 'NOMBRE',
    selector: 'fullName',
    cell: item => <DatatableEmployeeName item={item} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    grow: '3',
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => handleClick(item, 'destroy')}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar"
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    ),
    sortable: false,
    grow: '1',
    right: true
  }
]);

const RoleForm = ({
  errors,
  isSubmitting,
  onRequest,
  profile,
  setFieldTouched,
  setFieldValue,
  submitVariant,
  touched,
  values
}) => {
  const {
    profile: { roleIds }
  } = values;
  const [selector, setSelector] = useState(selectorTypes);
  const [modalShow, setModalShow] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);

  const handleSuccessIndex = response => {
    const { employees: profileEmployees } = profile;
    const employeeIds = profileEmployees.map(item => item.id);
    const vEmployees = camelCaseRecursive(response.data.data);

    const employeesToAdd = vEmployees.filter(selected => !employeeIds.includes(selected.id));
    const vSelector = [...selectorTypes, ...employeesToAdd];
    setSelector(vSelector);
  };

  const fetchRoles = () => {
    const request = async () => {
      indexRolesRequest({
        dispatch,
        params: {
          paginate: false
        },
        successCallback: response => setRoles(response.data)
      });
    };
    request();
  };

  const fetchEmployees = () => {
    indexEmployeesRequest({
      dispatch,
      params: { is_dt: false, paginate: false },
      successCallback: handleSuccessIndex
    });
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const addAllEmployees = () => {
    const { profile: profileValues } = values;
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const selectedEmployeeIds = vSelector.map(employee => employee.value);
    const selectedEmployees = vSelector.map(employee => ({ ...employee }));
    setSelector([selectorTypes]);
    setFieldValue('employeeSelector', '');
    setFieldValue('profile[employees]', [...selectedEmployees, ...profileValues.employees]);
    setFilterEmployees([...selectedEmployees, ...profileValues.employees]);
    setFieldValue('profile[employeeIds]', [...selectedEmployeeIds, ...profileValues.employeeIds]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== values.employeeSelector);
    if (vSelector.length === selector.length) {
      return;
    }
    if (values.employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === values.employeeSelector);
      setFieldValue(`profile[employeeIds]`, [...values.profile.employeeIds, employeeToAdd.value]);
      setFieldValue('profile[employees]', [...values.profile.employees, employeeToAdd]);
      setFilterEmployees([...values.profile.employees, employeeToAdd]);
      setSelector(vSelector);
    }
  };

  const removeFromTable = (employee, action) => {
    if (action === 'destroy') {
      const {
        profile: { employees: profileEmployees }
      } = values;
      const notRemovedEmployees = profileEmployees.filter(selected => selected.id !== employee.id);
      const addToSelector = [...selector, employee];
      setSelector(addToSelector);
      setFieldValue('profile[employees]', notRemovedEmployees);
      if (notRemovedEmployees.length === 0) setFieldValue('profile[employeeIds]', ['']);
      else
        setFieldValue(
          'profile[employeeIds]',
          notRemovedEmployees.map(item => item.id)
        );
    }
  };

  const RemoveAllFromTable = () => {
    const selectedRowsIds = selectedRows.map(item => item.id);
    const notRemovedEmployees = values.profile.employees.filter(selected => !selectedRowsIds.includes(selected.id));
    setSelector([...selectorTypes, ...selector, ...selectedRows].filter(item => item.label));
    setFieldValue('profile[employees]', notRemovedEmployees);
    setFieldValue(
      'profile[employeesIds]',
      notRemovedEmployees.map(item => item.id)
    );
    setFilterEmployees(notRemovedEmployees);
    setSelectedCount(0);
    setSelectedRows([]);
  };

  const resultFetchData = response => {
    const { data } = response.data;
    return data.map(element => ({
      label: element.name,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    const request = async () => {
      debounceIndexJobManagementsRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const handleNewJobManagement = (data, field) => {
    const newData = data.map(element => element.value);
    if (!newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const initialMultiDefaultValue = attribute => {
    const { [`${attribute}Ids`]: vAttributeId } = values.profile;
    const { [`${attribute}Ids`]: attributeId } = profile;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      return getIn(profile, `${attribute}s`);
    }
    return undefined;
  };

  const handleSearch = selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    setModalShow(false);
    if (vSelector.length === selector.length) return;
    const employeesToAdd = selector.filter(selected => selectedEmployees.includes(selected.value));
    setFieldValue(`profile[employeeIds]`, [...values.profile.employeeIds, ...employeesToAdd.map(item => item.value)]);
    setFieldValue('profile[employees]', [...values.profile.employees, ...employeesToAdd]);
    setSelector(vSelector);
  };

  const subHeaderComponent = () => (
    <>
      <ButtonTooltip
        onClick={RemoveAllFromTable}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar Seleccionados"
        disabled={selectedCount === 0}
      >
        <Icon icon="trash" />
      </ButtonTooltip>
      <BSForm.Group className="ml-auto w-25">
        <FormControl
          placeholder="Buscar"
          name="textInput"
          value={textInput}
          onChange={e => setTextInput(e.target.value)}
        />
      </BSForm.Group>
    </>
  );

  const filteredArray = () => {
    setFilterEmployees(filterArray(values.profile.employees, textInput, ['fullName', 'nationalIdentification']));
  };

  useEffect(fetchRoles, []);
  useEffect(fetchEmployees, [profile]);
  useEffect(() => setFilterEmployees(profile.employees), [profile]);
  useEffect(filteredArray, [textInput, values.profile.employees]);
  const defaultJobManagements = initialMultiDefaultValue('jobManagement');

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix">Información</h4>
        </Col>
        <Col md={5} xs={12}>
          <Field name="profile[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={5} xs={8}>
          <Field name="profile[jobManagementIds]">
            {({ field }) => (
              <InputSelect
                {...field}
                isMulti
                tooltipText="Seleccionar las áreas por las que se filtrarán los permisos. Por defecto se incluirán todas las áreas"
                placeholder="Todas las áreas"
                label="Áreas"
                request={fetchJobManagments}
                value={defaultJobManagements}
                onChange={data => handleNewJobManagement(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={4} md={2} className="mt-4">
          <Field name="profile[exclude]">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                tooltipText="Al seleccionar esta opción, todas las áreas escogidas 
                se excluirán de los permisos seleccionados"
                field={field}
                label="Excluir"
                custom
              />
            )}
          </Field>
        </Col>
        <Col className="mt-4" xs={12}>
          <DualList
            options={roles}
            selected={roleIds}
            name="profile[roleIds]"
            onChange={selected => setFieldValue('profile[roleIds]', selected)}
          />
        </Col>
      </Row>
      <div className="info-box ml-0">
        <h4 className="text-uppercase">Selecciona Trabajadores</h4>
        <p className="info">
          <span className="full-width">Seleccione trabajadores a los que se le asignará este perfil</span>
        </p>
      </div>
      <Row className="align-items-center">
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Asignar a"
                placeholder="Seleccionar Trabajador"
                options={sortByAttribute(selector, 'fullName')}
                defaultValue="all_employees"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} xl={2}>
          <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
            Agregar
          </Button>
        </Col>
        <Col className="ml-2">
          <ButtonTooltip
            variant="circle-primary"
            className="advance-search"
            text="Búsqueda avanzada de empleados mediante filtros de cargos, Lugares de Prestación de Servicios, otros"
            onClick={() => setModalShow(true)}
          >
            <Icon className="w-100 h-100" icon="people-circle" />
          </ButtonTooltip>
        </Col>
      </Row>
      <ComponentDataTable
        columns={columns(removeFromTable)}
        data={filterEmployees}
        onRequest={onRequest}
        totalRows={filterEmployees.length}
        defaultSortField="fullName"
        pointerOnHover
        sortServer={false}
        subHeaderComponent={subHeaderComponent()}
        onSelectedRowsChange={handleSelectedRows}
        selectableRows
        paginationServer={false}
        pagination
      />
      <Row className="d-flex justify-content-end my-3">
        <Col md={3} xl={2}>
          <Button type="submit" disabled={isSubmitting} variant={submitVariant} block>
            Guardar
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={<EmployeeSearchModal handleClose={() => setModalShow(false)} formRequest={handleSearch} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Form>
  );
};

const setInitialValues = props => {
  const { profile } = props;
  return {
    profile: {
      name: profile.name,
      employees: profile.employees,
      employeeIds: profile.employees.map(item => item.id),
      jobManagementIds: profile.jobManagementIds,
      roleIds: profile.roleIds,
      exclude: profile.exclude
    },
    employeeSelector: 'all_employees'
  };
};

const validationSchema = Yup.object().shape({
  profile: Yup.object().shape({
    exclude: Yup.bool(),
    name: Yup.string().required('Debes ingresar un nombre')
    // roleIds: Yup.array(Yup.number()).min(1, 'Debes ingresar al menos un permiso') FALTA AGREGAR ESTILO AL COMPONENTE
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(RoleForm);
