export default {
  name: '',
  shiftType: 'weekly',
  workHolidays: false,
  lunchCheck: false,
  lunchTime: '',
  tolerance: '',
  shiftDaysAttributes: [],
  startDate: '',
  contractIds: [],
  contracts: []
};
