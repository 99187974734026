import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal } from '../../components';
import { deleteDncRequest } from '../../requests/dncs';
import { delayMethod } from '../../services/utils';

const DncDatatable = ({
  dncs,
  amount,
  onRequest,
  handleIndexRequest,
  customParams,
  moreData,
  setMoreData,
  columnDnc,
  boss
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [modalItem, setModalItem] = useState(null);
  const [request, setRequest] = useState(onRequest);
  const [disabled, setIsDisable] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDeleteRequest = id => {
    setIsDisable(true);
    setRequest(onRequest);
    deleteDncRequest(id, {
      dispatch,
      successCallback: () => {
        setRequest(!onRequest);
        setIsDisable(false);
        dispatch(sendAlert({ kind: 'success', message: 'DNC eliminado con éxito' }));
        setMoreData(!moreData);
      },
      callback: () =>
        delayMethod(() => {
          setModalShow(false);
          setIsDisable(false);
        })
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'edit':
        if (boss) {
          history.push(`/profile/dnc/${item.id}/edit`);
        } else {
          history.push(`/dnc/${item.id}/edit`);
        }
        break;
      case 'destroy':
        setModalItem(item);
        setModalShow(true);
        setModalBody(<div className="ml-2">¿Estás seguro que deseas eliminar {item.name}?</div>);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_management_name':
        return { sort_job_managements: name };
      case 'leadership':
        return { sort_leadership: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columnDnc(handleActions)}
        handleSortCase={sortColumnCase}
        data={dncs}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        onRowClicked={row => handleActions(row, 'edit')}
        customParams={customParams}
        moreData={moreData}
        pointerOnHover
      />
      <DefaultModal
        title="Eliminar DNC"
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => handleDeleteRequest(modalItem.id)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={!request || disabled}
      />
    </>
  );
};

export default DncDatatable;
