import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { showCourseRequest } from '../../../requests/courses';
import { createEmployeeCourseRequest, deleteEmployeeCourseRequest } from '../../../requests/employeeCourses';
import { LinkBtn, Icon, SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import defaultBanner from '../../../assets/images/bg-default.png';

const CourseProfileShow = ({ match }) => {
  const [course, setCourse] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const employeeId = user.employeeCard?.id;
  const outDate = moment().format('DD/MM/YYYY') > course.limitDate;
  const isAdmitted = course?.currentEmployeeCourse?.admission === 'approved';
  const isPending = course?.currentEmployeeCourse?.admission === 'pending';

  const handleRequestCourse = () => {
    setOnRequest(true);
    showCourseRequest(match.params.id, {
      dispatch,
      successCallback: res => setCourse(camelCaseRecursive(res.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleRequestInscription = () => {
    setModalShow(false);
    setOnRequest(true);
    createEmployeeCourseRequest(course.id, {
      dispatch,
      params: { employee_course: { employee_id: employeeId } },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Inscrito con éxito' }));
      },
      callback: () => {
        setMoreData(!moreData);
        setOnRequest(false);
      }
    });
  };

  const handleRequestCancelInscription = () => {
    setOnRequest(true);
    deleteEmployeeCourseRequest(course.id, course?.currentEmployeeCourse.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Inscripción cancelada' }));
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
        setMoreData(!moreData);
      }
    });
  };

  const confirmInscription = () => {
    setModalTitle('Confirmar inscripción');
    setModalShow(true);
    setModalBody(
      <Row className="mx-2 justify-content-end">
        <Col md={12}>
          <pre>
            <p className="mb-4 text-justify pr-3">{course.description || 'Sin descripción de curso'}</p>
          </pre>
        </Col>
        <Col md={6}>
          <Button variant="primary" onClick={handleRequestInscription} className="my-3" block>
            Confirmar inscripción
          </Button>
        </Col>
      </Row>
    );
  };

  const cancelInscription = () => {
    setModalTitle('Cancelar inscripción');
    setModalShow(true);
    setModalBody(
      <Row className="mx-2 justify-content-end">
        <Col md={12}>
          <p className="text-center">¿Estás seguro que deseas cancelar la inscripción?</p>
        </Col>
        <Col md={6}>
          <Button variant="dark" onClick={handleRequestCancelInscription} className="my-3" block>
            Cancelar inscripción
          </Button>
        </Col>
      </Row>
    );
  };

  useEffect(handleRequestCourse, [moreData]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4 align-items-center">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3 mb-1" to="/profile/courses">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-3">{course.name}</h2>
        </Col>
      </Row>
      <div className={`${onRequest && 'bg-opacity'} profile-show`}>
        <Row>
          <Col>
            {course.courseImage?.fileUrl ? (
              <img src={course.courseImage?.fileUrl} alt="banner-course" className="banner-course" />
            ) : (
              <img src={defaultBanner} alt="default-banner" className="banner-course" />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <h4 className="text-uppercase mb-3 color-title">Descripción</h4>
            <pre>
              <p className="mb-4 text-justify pr-3">{course.description || 'Curso sin descripción'}</p>
            </pre>
            <h4 className="text-uppercase mb-2 color-title">Relatores</h4>
            {course.instructorsAttributes?.length > 0 ? (
              <>
                {course.instructorsAttributes.map(item => (
                  <p key={item.id} className="mb-2 ml-1 text-uppercase">
                    {item.name}
                  </p>
                ))}
              </>
            ) : (
              <p className="mb-2 ml-1 text-uppercase">No hay relatores confirmados</p>
            )}
          </Col>
          <Col md={6}>
            <h4 className="text-uppercase title-info mb-3">Información</h4>
            <Row>
              <Col md={6}>
                <p className="text-uppercase font-weight-bold mb-1">Dirección del Curso:</p>
                <p className="font-weight-light">{course.address}</p>
                <p className="text-uppercase font-weight-bold mb-1">Fecha de inicio: </p>
                <p className="font-weight-light">{course.startDate}</p>
                <p className="text-uppercase font-weight-bold mb-1">Fecha de término: </p>
                <p className="font-weight-light">{course.endDate}</p>
                <p className="text-uppercase font-weight-bold mb-1">Fecha limite de inscripción: </p>
                <p className="font-weight-light">{course.limitDate}</p>
                <p className="text-uppercase font-weight-bold mb-1">Costo del Curso: </p>
                <p className="font-weight-light">{course.parsedTotalValue}</p>
              </Col>
              <Col md={6}>
                <p className="text-uppercase font-weight-bold mb-1">Tema:</p>
                <p className="font-weight-light">{course.theme}</p>
                <p className="text-uppercase font-weight-bold mb-1">Modalidad del Curso:</p>
                <p className="font-weight-light">{course.translatedCourseModality}</p>
                <p className="text-uppercase font-weight-bold mb-1">Días de clases:</p>
                <p className="font-weight-light">{course.journeyDistribution}</p>
                <p className="text-uppercase font-weight-bold mb-1">Hora de inicio: </p>
                <p className="font-weight-light">{course.startTime}</p>
                <p className="text-uppercase font-weight-bold mb-1">Hora de término: </p>
                <p className="font-weight-light">{course.endTime}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end mx-1 mt-4">
          <Col md={6} className="bg-color-course p-3 mb-4">
            <Row className="align-items-center">
              <Col md={6} className="d-flex justify-content-center">
                {course.discountAvailability > 0 ? (
                  <h4 className="text-uppercase color-title my-0 ml-md-3 ml-xl-0">
                    <span className="mr-3">{course.discountAvailability}</span>
                    Cupos disponibles
                  </h4>
                ) : (
                  <h4 className="text-uppercase color-title my-0">No hay cupos disponibles</h4>
                )}
              </Col>
              <Col md={6}>
                {course.signUp !== 'not_sign_up' ? (
                  <Button
                    variant="dark"
                    onClick={cancelInscription}
                    disabled={course.status !== 'published' || course.maxAvailability === 0 || !isPending}
                    className="mt-3 mt-md-0"
                    block
                  >
                    {isAdmitted
                      ? 'Ya estás inscrito'
                      : (isPending && 'Cancelar inscripción') || 'Inscripción rechazada'}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={confirmInscription}
                    disabled={
                      course.status !== 'published' ||
                      course.maxAvailability === 0 ||
                      course.discountAvailability <= 0 ||
                      outDate
                    }
                    className="mt-3 mt-md-0"
                    block
                  >
                    {outDate ? 'Fuera de plazo' : 'Inscribirme'}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <SimpleCenteredModal
        title={modalTitle}
        show={modalShow}
        body={modalBody}
        size="md"
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default CourseProfileShow;
