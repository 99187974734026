export default {
  active: true,
  address: '',
  addressFloor: '',
  addressNumber: '',
  addressOffice: '',
  code: '',
  communeId: '',
  fullAddress: '',
  email: '',
  lat: '',
  lng: '',
  name: '',
  phone: '',
  phoneCountryCode: '+56',
  regionId: ''
};
