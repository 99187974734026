import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ImportDocumentsForm from './ImportDocumentsForm';
import { massiveImportDirectoryNodeRequest } from '../../requests/directoryNodes';
import { sendAlert } from '../../actions/utils';

const ImportDocuments = () => {
  const [onRequest, setOnRequest] = useState(false);
  useEffect(() => window.scrollTo(0, 0), []);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Realizando importación. Serás notificado por email una vez terminado el proceso'
      })
    );
    history.push(`/import_logs`);
  };

  const handleSubmit = (values, setSubmitting) => {
    setOnRequest(true);
    massiveImportDirectoryNodeRequest({
      dispatch,
      params: {
        file_keys: values.fileKeys.map(f => f.code),
        match_file: [values.matchFile.code]
      },
      successCallback: handleSuccessCreate,
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h2 className="m-top mb-3 mt-3">Importar Documentos</h2>
        </Col>
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix">Importación de Documentos y Carpetas</h4>
        </Col>
      </Row>
      <ImportDocumentsForm onRequest={onRequest} setOnRequest={setOnRequest} formRequest={handleSubmit} />
    </>
  );
};

export default ImportDocuments;
