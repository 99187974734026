import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexShiftChangeRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/shift_change_requests', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const deleteShiftChangeRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/shift_change_requests/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const approvedShiftChangeRequest = (id, { dispatch, successCallback, callback, params = {} }) =>
  ApiService.request('post', `/shift_change_requests/${id}/approve`, {
    dispatch,
    successCallback,
    callback,
    params
  });

export const rejectShiftChangeRequest = (id, { dispatch, successCallback, callback, params = {} }) =>
  ApiService.request('post', `/shift_change_requests/${id}/reject`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createShiftChangeRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/shift_change_requests', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });
export const debounceIndexShiftChangeRequest = AwesomeDebouncePromise(indexShiftChangeRequest, 300);
