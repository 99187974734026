import ApiService from '../services/apiService';

export const exportEmployeePrevisionsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/employee_previsions/export.xlsx`, {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const preImportEmployeePrevisionRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/employee_previsions/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importEmployeePrevisionRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/employee_previsions/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateEmployeePrevisionRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/employee_previsions/import_template', {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};
