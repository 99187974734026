import React, { useState, useMemo, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Icon,
  ButtonTooltip,
  FormikSelect,
  FormikInput,
  SimpleCenteredModal,
  FormikTimePicker,
  CreatableSelect
} from '../../components';

import { delayMethod } from '../../services/utils';
import { statusEmployeeDnc, levelsEmployeeDnc } from './FormOptions';
import { createTopicRequest } from '../../requests/topics';
import { createNecessityAreaRequest, indexNecessityAreaRequest } from '../../requests/necessityAreas';

const EmployeeDncDatatableRow = ({ employee, index, topics, setTopics, boss, showCurrentEmployeeDnc }) => {
  const { setFieldValue, setFieldTouched, touched, values, errors } = useFormikContext();
  const { employeeDncsAttributes } = values.dnc;
  const [onRequest, setOnRequest] = useState(false);
  const [modalEmployee, setModalEmployee] = useState({});
  const [necessityAreas, setNecessityAreas] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { topicId, topic, suggestedDate, necessityArea, active } = employeeDncsAttributes[index];
  const dispatch = useDispatch();

  const showStatus = (status, className = 'mt-3') => {
    switch (status) {
      case 'approved':
        return <p className={`${className} text-primary`}>Aceptado</p>;
      case 'rejected':
        return <p className={`${className} text-danger`}>Rechazado</p>;
      default:
        return <p className={`${className} text-warning`}>Pendiente</p>;
    }
  };

  const resultFetchData = response => {
    return response.map(element => ({
      label: element.name,
      value: element.id,
      necessityAreas: element.necessity_areas
    }));
  };

  const selectedDate = useMemo(() => {
    if (suggestedDate && !(suggestedDate instanceof Date)) {
      const dateArray = suggestedDate.split('/');
      return new Date(dateArray[1], dateArray[0], 1);
    }
    return suggestedDate;
  }, [suggestedDate]);

  const fetchInitialNecessityAreas = () => {
    if (topicId) {
      indexNecessityAreaRequest({
        dispatch,
        params: {
          topic: topicId,
          sort_column: 'name',
          paginate: false
        },
        successCallback: response => setNecessityAreas(resultFetchData(response.data.data))
      });
    }
  };

  const handleNewNecessityArea = name => {
    setOnRequest(true);
    if (topicId) {
      createNecessityAreaRequest({
        dispatch,
        params: { necessity_area: { name, topic_id: topicId } },
        formData: true,
        successCallback: response => {
          const addNecessityArea = { value: response.data.id, label: name };
          const newTopics = topics.map(item => {
            if (item.id === topicId) return { ...item, necessityAreas: [...item.necessityAreas, addNecessityArea] };
            return item;
          });
          setTopics(newTopics);
          setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityAreaId]`, response.data.id);
          setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityArea]`, addNecessityArea);
          setNecessityAreas([...necessityAreas, addNecessityArea]);
        },
        callback: () => setOnRequest(false)
      });
    }
  };

  const handleNewTopic = name => {
    setOnRequest(true);
    createTopicRequest({
      dispatch,
      params: { topic: { name } },
      formData: true,
      successCallback: response => {
        const addTopic = { value: response.data.id, label: name, necessityAreas: [] };
        setFieldValue(`dnc[employeeDncsAttributes][${index}][topicId]`, response.data.id);
        setFieldValue(`dnc[employeeDncsAttributes][${index}][topic]`, addTopic);
        setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityAreaId]`, '');
        setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityArea]`, '');
        setTopics([...topics, addTopic]);
        setNecessityAreas([]);
      },
      callback: () => setOnRequest(false)
    });
  };

  const deactivateEmployee = () => {
    setFieldValue(`dnc[employeeDncsAttributes][${index}][active]`, !employee.active);
    setFieldValue(`dnc[employeeDncsAttributes][${index}][status]`, employee.active ? 'rejected' : 'pending');
    setFieldValue(`dnc[employeeDncsAttributes][${index}][cost]`, 0);
  };

  useEffect(fetchInitialNecessityAreas, [topics]);
  return (
    <>
      <tr className={`${!employee.active && 'deactivate-color'}`} key={`employee-${index}`}>
        <td>{employee.employee?.fullName}</td>
        <td>{employee.employee?.jobTitle}</td>
        {employee.status === 'pending' || !boss ? (
          <>
            <td className="p-row-input">
              <Field name={`dnc[employeeDncsAttributes][${index}][topicId]`}>
                {({ field }) => (
                  <CreatableSelect
                    {...field}
                    abbr
                    isClearable
                    placeholder="Seleccionar"
                    onCreateOption={handleNewTopic}
                    options={topics}
                    onChange={data => {
                      setFieldValue(`dnc[employeeDncsAttributes][${index}][topic]`, data);
                      setFieldValue(field.name, data ? data.value : '');
                      setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityAreaId]`, '');
                      setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityArea]`, '');
                      setNecessityAreas(data?.necessityAreas || []);
                    }}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled={onRequest}
                    isLoading={onRequest}
                    value={topic}
                  />
                )}
              </Field>
            </td>
            <td>
              <Field name={`dnc[employeeDncsAttributes][${index}][necessityAreaId]`}>
                {({ field }) => (
                  <CreatableSelect
                    {...field}
                    abbr
                    isClearable
                    placeholder="Seleccionar"
                    onCreateOption={handleNewNecessityArea}
                    options={necessityAreas}
                    onChange={data => {
                      setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityArea]`, data);
                      setFieldValue(`dnc[employeeDncsAttributes][${index}][necessityAreaId]`, data?.value || '');
                    }}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled={onRequest || !topicId}
                    isLoading={onRequest}
                    value={necessityArea}
                  />
                )}
              </Field>
            </td>
            <td>
              <Field name={`dnc[employeeDncsAttributes][${index}][depthLevel]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    placeholder="Seleccionar"
                    options={levelsEmployeeDnc}
                    defaultValue={employeeDncsAttributes[index].depthLevel}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  />
                )}
              </Field>
            </td>
            <td>
              <Field name={`dnc[employeeDncsAttributes][${index}][urgencyLevel]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    placeholder="Seleccionar"
                    options={levelsEmployeeDnc}
                    defaultValue={employeeDncsAttributes[index].urgencyLevel}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  />
                )}
              </Field>
            </td>
            <td>
              <Field name={`dnc[employeeDncsAttributes][${index}][suggestedDate]`}>
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    setLocale
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={suggestedDate ? selectedDate : undefined}
                    onChange={date => {
                      setFieldValue(field.name, moment.isMoment(date) ? date.format('MM/YYYY') : date);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </td>
          </>
        ) : (
          <>
            <td>{employee.topic?.label}</td>
            <td>{employee.necessityArea.label}</td>
            <td>{levelsEmployeeDnc.find(item => item.value === employee.depthLevel)?.label}</td>
            <td>{levelsEmployeeDnc.find(item => item.value === employee.urgencyLevel)?.label}</td>
            <td>{employee.printableDate}</td>
          </>
        )}
        <td>{showStatus(boss ? employee.status : employeeDncsAttributes[index].status)}</td>
        <td>
          <div className="d-flex justify-content-center ml-n2">
            {boss ? (
              <ButtonTooltip
                onClick={() =>
                  showCurrentEmployeeDnc(boss ? employee : employeeDncsAttributes[index], true, showStatus)
                }
                variant="circle-primary"
                className="btn-circle"
                size="sm"
                text="Ver"
              >
                <Icon icon="eye" />
              </ButtonTooltip>
            ) : (
              <>
                {active && (
                  <ButtonTooltip
                    onClick={() => {
                      setModalEmployee(employeeDncsAttributes[index]);
                      setModalShow(true);
                    }}
                    variant="circle-warning"
                    className="btn-circle"
                    size="sm"
                    text="Editar"
                  >
                    <Icon icon="pencil" />
                  </ButtonTooltip>
                )}
                <ButtonTooltip
                  onClick={deactivateEmployee}
                  variant="circle-danger"
                  className="btn-circle"
                  size="sm"
                  text={`${active ? 'Desactivar' : 'Activar'}`}
                >
                  <Icon style={{ color: active ? '' : '#87ce03' }} icon="archive" />
                </ButtonTooltip>
              </>
            )}
          </div>
        </td>
      </tr>
      <SimpleCenteredModal
        title="Observación"
        show={modalShow}
        body={
          <div className="mx-2">
            <Row>
              <Col md={6}>
                <Field name={`dnc[employeeDncsAttributes][${index}][status]`}>
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Estado"
                      placeholder="Seleccionar"
                      options={statusEmployeeDnc}
                      defaultValue={modalEmployee.status}
                      onChange={data => setModalEmployee({ ...modalEmployee, status: data.value })}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name={`dnc[employeeDncsAttributes][${index}][cost]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Costo"
                      leftAddon="$"
                      inputType="number"
                      value={modalEmployee.cost}
                      onChange={data => setModalEmployee({ ...modalEmployee, cost: data.target.value })}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name={`dnc[employeeDncsAttributes][${index}][observation]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      as="textarea"
                      label="Escribir observaciones"
                      minInputHeight="120"
                      value={modalEmployee.observation}
                      onChange={data => setModalEmployee({ ...modalEmployee, observation: data.target.value })}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col md={4}>
                <Button
                  variant="outline-info"
                  onClick={() => delayMethod(() => setModalShow(false))}
                  className="my-2 my-md-3"
                  block
                >
                  Cancelar
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setFieldValue(`dnc[employeeDncsAttributes][${index}]`, modalEmployee);
                    delayMethod(() => setModalShow(false));
                  }}
                  className="mb-3 my-md-3"
                  block
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
        }
        size="lg"
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default EmployeeDncDatatableRow;
