import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexLicenseImedRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('get', '/imed/integration_imeds/lme_even_lcc', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const pdfLicenseImedRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('get', '/imed/integration_imeds/lme_dett_lcc_pdf', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const lmeEstLccRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('post', '/imed/integration_imeds/lme_est_lcc', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const lmeDetLccZaRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('post', '/imed/integration_imeds/lme_det_lcc_za', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const lmeInfSeccCNotReciveRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('post', '/imed/integration_imeds/lme_inf_secc_c_not_recive', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const lmeInfSeccCRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('post', '/imed/integration_imeds/lme_inf_secc_c', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const uploadDocumentImedRequest = ({
  dispatch,
  params = {},
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', '/imed/integration_imeds/lme_inf_adjunto', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const uploadDocumentImedMassiveRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('post', '/imed/integration_imeds/lme_inf_adjunto_massive', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
export const lmeInfSeccCAutoRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('post', '/imed/integration_imeds/process_license_auto', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });
export const debounceIndexLicenseImedRequest = AwesomeDebouncePromise(indexLicenseImedRequest, 300);
