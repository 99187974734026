/* eslint-disable camelcase */
import memoize from 'memoize-one';

const columns = memoize(() => [
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'HORA',
    selector: 'time',
    cell: item => item.complete_parsed_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'time_type',
    cell: item => item.translated_time_type,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'MODALIDAD',
    selector: 'translated_device_type',
    cell: item => (item.translated_device_type ? item.translated_device_type : item.translated_marking_type),
    sortable: false,
    grow: '2',
    center: true
  }
]);

export default columns;
