import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import JobTitleInfo from './JobTitleInfo';
import { delayMethod } from '../../services/utils';

const JobTitleShow = ({ jobTitle, hideModal }) => {
  return (
    <>
      <Modal.Body>
        <JobTitleInfo jobTitle={jobTitle} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={() => delayMethod(hideModal)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </>
  );
};
export default JobTitleShow;
