import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

import BalanceInfo from './BalanceInfo';
import { Icon, LinkBtn } from '../../components';
import { showBalanceRequest } from '../../requests/balances';
import { showBalance } from './balance';
import ContractBalanceDataTable from './ContractBalanceDataTable';

const BalanceShow = ({ match, asset }) => {
  const [balance, setBalance] = useState(showBalance);
  const path = asset ? 'asset' : 'discount';
  const dispatch = useDispatch();

  const fetchBalance = () => {
    const request = async () => {
      const balanceId = match.params.id;
      showBalanceRequest(balanceId, {
        dispatch,
        successCallback: response => setBalance(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchBalance, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to={`/balances?tab=${path}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">{balance.name}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="warning" block to={`/balances/${path}s/${balance.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col>
          <BalanceInfo balance={balance} asset={asset} />
        </Col>
      </Row>
      {balance.defaultToShow === 'work_contract' && (
        <Row className="mt-3 mb-3">
          <Col xs={12}>
            <div className="info-box ml-0">
              <h3 className="text-uppercase">Contratos asociados</h3>
              <p className="info">
                <span className="full-width">
                  Trabajadores con este {balance.translatedBalanceType} asociado en su contrato
                </span>
              </p>
            </div>
          </Col>
          <Col>
            <ContractBalanceDataTable balanceId={balance.id} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BalanceShow;
