import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import ImportLogDataTable from './ImportLogDataTable';

const ImportLogIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3">Historial</h2>
        </Col>
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix mb-0">Historial de Acciones</h4>
        </Col>
      </Row>
      <ImportLogDataTable />
    </>
  );
};

export default ImportLogIndex;
