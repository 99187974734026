import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexRolesRequest = ({ dispatch, params = {}, successCallback, failureCallback }) =>
  ApiService.request('get', '/roles', {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const debounceIndexRolesRequest = AwesomeDebouncePromise(indexRolesRequest, 300);
