import React from 'react';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';

const ItemsInfo = props => {
  const { item, rrhh } = camelCaseEmptyStringRecursive(props);
  const renderStatus = status => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'approved':
        return 'Aprobado';
      case 'rejected':
        return 'Rechazado';
      default:
        return '-';
    }
  };
  return (
    <div className="info-box">
      {rrhh && (
        <p className="info">
          <span style={{ width: '150px' }}>Solicitante:</span> {item.employee.fullName}
        </p>
      )}
      <p className="info">
        <span style={{ width: '150px' }}>Horario:</span> {`${item.shift.name} ${item.shift.scheduleType}`}
      </p>
      <p className="info">
        <span style={{ width: '150px' }}>Fecha de Solicitud:</span> {item.createdAt}
      </p>
      <p className="info">
        <span style={{ width: '150px' }}>Fecha de Remmplazo:</span> {item.replacementDate}
      </p>
      <p className="info">
        <span style={{ width: '150px' }}>Reemplazar con:</span> {item.employeeReplacement.fullName}
      </p>
      <p className="info">
        <span style={{ width: '150px' }}>Horario:</span>{' '}
        {`${item.shiftReplacement.name} ${item.shiftReplacement.scheduleType}`}
      </p>
      <p className="info">
        <span style={{ width: '150px' }}>Motivo:</span> {item.reason}
      </p>
      <p className="info">
        <span style={{ width: '150px' }}>Estado:</span> {renderStatus(item.status)}
      </p>
      {item.status === 'rejected' && (
        <p className="info">
          <span style={{ width: '150px' }}>Motivo del Rechazo:</span> {item.observation}
        </p>
      )}
    </div>
  );
};

export default ItemsInfo;
