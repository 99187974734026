import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tab, Row, Col } from 'react-bootstrap';
import { useSetTab } from '../../../services/hooks';
import AnnouncementCalendar from './AnnouncementModalShow';

const AnnouncementIndex = () => {
  const location = useLocation();
  const [key, setKey] = useSetTab('posts', location);
  const { currentEmployee } = useSelector(state => state.auth);
  const [moreData, setMoreData] = useState(false);


  return (
    <>
      <Row className="m-top mb-3">
        <Col>
          <h2>Fechas Destacadas</h2>
        </Col>
      </Row>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter unmountOnExit>
        <AnnouncementCalendar moreData={moreData} setMoreData={setMoreData} currentEmployee={currentEmployee} />
      </Tab.Container>
    </>
  );
};

export default AnnouncementIndex;
