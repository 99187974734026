import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner } from 'react-bootstrap';

import { showByTypeWorkflowTemplatesRequest } from '../../requests/workflowTemplates';
import { createWorkflowRequestRequest } from '../../requests/workflowRequests';
import WorkflowRequestForm from './WorkflowRequestForm';

// Setear el basico
const basicDocumentRequest = (recordType, recordId) => ({
  workflowType: '',
  recordId,
  recordType,
  requestReviewersAttributes: [
    {
      approval: false,
      signature: false,
      position: 1,
      employeeReviewerIds: []
    }
  ]
});

const parseTemplateToRequest = template => {
  const requestReviewers = template.reviewerTemplatesAttributes.map(reviewerTemplate => {
    const requestReviewer = {
      approval: reviewerTemplate.approval,
      signature: reviewerTemplate.signature,
      position: reviewerTemplate.position
    };
    const employeeReviewerIds = [];
    const employeeReviewersAttributes = [];
    reviewerTemplate.reviewerTemplateIds.forEach(reviewerIds => {
      employeeReviewerIds.push({
        employeeType: reviewerIds.employeeType,
        label: reviewerIds.label,
        value: reviewerIds.value
      });

      employeeReviewersAttributes.push({
        employeeType: reviewerIds.employeeType,
        employeeId: reviewerIds.value
      });
    });
    requestReviewer.employeeReviewerIds = employeeReviewerIds;
    requestReviewer.employeeReviewersAttributes = employeeReviewersAttributes;
    return requestReviewer;
  });
  return requestReviewers;
};

const setWorkflowType = (document, customWorkflowType) => {
  if (document) {
    let workflowType = '';
    switch (document.documentType) {
      case 'work_contract':
        workflowType = 'contract';
        break;
      case 'contract_annex':
      case 'overtime_agreements':
      case 'certificate':
      case 'reprimand_document':
      case 'congratulation_letter':
      case 'personal_protection':
      case 'report_occupational_hazards':
      case 'acceptance_riohs':
      case 'induction_risk_prevention':
        workflowType = document.documentType;
        break;
      default:
        workflowType = 'other_document';
    }
    return [workflowType, document.id];
  }
  return [customWorkflowType, ''];
};

const WorkflowRequestNew = ({ ...props }) => {
  const { handleModalClose, handleSuccessWorkflowRequest, recordId, recordType, document, workflowType } = props;
  const [onRequest, setOnRequest] = useState(false);
  const [workflowRequest, setWorkflowRequest] = useState(basicDocumentRequest(recordType, recordId));
  const dispatch = useDispatch();

  useEffect(() => {
    setWorkflowRequest(basicDocumentRequest(recordType, recordId));
  }, [recordType, recordId]);

  const handleSuccessLoadTemplate = response => {
    const workflowTemplates = camelCaseRecursive(response.data);
    const workflowCopy = { ...workflowRequest };
    if (workflowTemplates) {
      workflowCopy.requestReviewersAttributes = parseTemplateToRequest(workflowTemplates);
    }
    const [newWorkflowType, documentId] = setWorkflowType(document, workflowType);
    workflowCopy.workflowType = newWorkflowType;
    workflowCopy.recordId = documentId;
    workflowCopy.recordType = 'Document';
    setWorkflowRequest(workflowCopy);
    setOnRequest(false);
  };

  const loadTemplateRequest = () => {
    setOnRequest(true);
    const newWorkflowType = setWorkflowType(document, workflowType)[0];
    showByTypeWorkflowTemplatesRequest({
      dispatch,
      params: { workflow_type: newWorkflowType },
      successCallback: response => handleSuccessLoadTemplate(response)
    });
  };

  const handleCreate = workflowRequestValue => {
    setOnRequest(true);
    const params = snakeCaseKeys(workflowRequestValue);

    createWorkflowRequestRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        const message = 'Enviado a firmar con éxito';
        setOnRequest(false);
        handleSuccessWorkflowRequest(message);
      },
      callback: () => {
        setOnRequest(false);
        handleModalClose();
      }
    });
  };

  useEffect(loadTemplateRequest, [document]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <WorkflowRequestForm
        workflowRequest={workflowRequest}
        action="new"
        formRequest={handleCreate}
        cancelAction={handleModalClose}
      />
    </>
  );
};

export default WorkflowRequestNew;
