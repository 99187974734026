import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { basicDnc } from './dnc';
import { sendAlert } from '../../actions/utils';
import { createDncRequest } from '../../requests/dncs';
import DncForm from './DncForm';

const DncNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (dncValues, setSubmitting) => {
    const params = snakeCaseKeys(dncValues);
    createDncRequest({
      dispatch,
      params,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'DNC creado con éxito' }));
        history.push('/dnc');
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to="/dnc">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Nueva Detección de Necesidades de Capacitación</h2>
        </Col>
      </Row>
      <DncForm dnc={basicDnc} action="new" formRequest={handleCreateRequest} />
    </>
  );
};

export default DncNew;
