import { utilsTypes } from '../actions/utils';
import { camelCaseEmptyStringRecursive } from '../services/utils';

const initialState = {
  advanceSettings: [],
  sidebarOpen: false,
  settings: {
    mainContactEmail: ''
  },
  slides: [],
  page: {
    body: null
  },
  updateRoles: false,
  unreadNotifications: 0,
  isPretending: false,
  userCompanies: 0,
  alert: {
    show: false,
    title: '',
    kind: '',
    timeout: process.env.NODE_ENV === 'development' ? 3000 : 10000,
    message: ''
  },
  ongoingRequest: {
    getAdvanceSettings: false,
    getSettings: false,
    getSlides: false,
    getPage: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case utilsTypes.SET_ADVANCE_SETTINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getAdvanceSettings: true }
      };
    case utilsTypes.SET_ADVANCE_SETTINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getAdvanceSettings: false }
      };
    case utilsTypes.SET_ADVANCE_SETTINGS_SUCCESS:
      return {
        ...state,
        advanceSettings: camelCaseEmptyStringRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, getAdvanceSettings: false }
      };
    case utilsTypes.TOOGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    case utilsTypes.SET_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.params.show
      };
    case utilsTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: true }
      };
    case utilsTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: camelCaseEmptyStringRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SLIDES_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: true }
      };
    case utilsTypes.GET_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.result,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_SLIDES_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: true },
        url: action.url
      };
    case utilsTypes.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.result,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.UPDATE_ROLES:
      return {
        ...state,
        updateRoles: action.params.updateRoles,
        ongoingRequest: { ...state.ongoingRequest }
      };
    case utilsTypes.IS_PRETENDING:
      return {
        ...state,
        isPretending: action.params.isPretending,
        ongoingRequest: { ...state.ongoingRequest }
      };
    case utilsTypes.NOTIFICATION:
      return {
        ...state,
        unreadNotifications: action.params.unreadNotifications,
        ongoingRequest: { ...state.ongoingRequest }
      };
    case utilsTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          kind: action.kind,
          timeout: action.timeout || initialState.alert.timeout,
          message: action.message,
          title: action.title
        }
      };
    case utilsTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    case utilsTypes.UPDATE_COMPANIES:
      return {
        ...state,
        userCompanies: action.params.hasManyCompanies,
        ongoingRequest: { ...state.ongoingRequest }
      };
    default:
      return state;
  }
};

export default reducer;
