import React from 'react';
import { Image } from 'react-bootstrap';
import defaultBg from '../../assets/images/bg-default.png';

const AnnouncementModalShow = ({ announcement }) => {
  const image = announcement?.fileInfo?.fileUrl;
  return (
    <section className="pb-3">
      <div className="d-flex justify-content-center">
        <Image className="max-height-300" src={image || defaultBg} alt={announcement.image} fluid />
      </div>
      <h2 className="my-3 text-uppercase">{announcement.title}</h2>
      <h4 className="my-3 mb-3">
        {announcement.startDate} - {announcement.endDate}
      </h4>
      <pre>
        <p className="text-justify">{announcement.description}</p>
      </pre>
    </section>
  );
};

export default AnnouncementModalShow;
