import React from 'react';
import OrganizationChartChart from './OrganizationChartChart';

const OrganizationChartIndex = () => {
  return (
    <>
      <OrganizationChartChart />
    </>
  );
};

export default OrganizationChartIndex;
