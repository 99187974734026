import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { Row, Col, Button } from 'react-bootstrap';
import {
  ComponentDataTable,
  DefaultModal,
  FormikSelect,
  ButtonTooltip,
  Icon,
  SimpleCenteredModal,
  EmployeeSearchModal
} from '../../components';
import {
  debounceIndexEmployeeSurveyRequest,
  deleteEmployeeSurveyRequest,
  createEmployeeSurveyRequest,
  massCreateEmployeeSurveyRequest,
  sentEmployeeSurveyRequest,
  resetEmployeeSurveyRequest
} from '../../requests/employeeSurveys';
import { delayMethod, sortByAttribute } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import { AbilityContext } from '../../config/abilityContext';
import { indexEmployeesRequest } from '../../requests/employees';
import { sentSurveyRequest } from '../../requests/surveys';

const EmployeeSurveyDataTable = ({ survey, surveyId, rrhh, columns, customParams }) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [employeeSurveys, setEmployeeSurveys] = useState([]);
  const [employee, setEmployee] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [searchModalShow, setSearchModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalAction, setModalAction] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [selector, setSelector] = useState([]);
  const outOfDate = !survey?.sendSurvey;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelector = () => {
    setOnRequest(true);
    createEmployeeSurveyRequest(surveyId, {
      dispatch,
      params: { employee_survey: { employee_id: employee } },
      successCallback: () => {
        setMoreData(!moreData);
        setEmployee('');
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexEmployeeSurveyRequest({
        dispatch,
        params: { ...params, ...customParams },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setEmployeeSurveys(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleModalConfirm = async item => {
    setOnRequest(true);
    deleteEmployeeSurveyRequest(item.survey?.value, item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Participante eliminado con éxito.' }));
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleRemindEmployee = async item => {
    setOnRequest(true);
    sentEmployeeSurveyRequest(surveyId, item.id, {
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        const message = modalTitle === 'Recordatorio' ? 'Recordatorio enviado' : 'Encuesta enviada';
        dispatch(sendAlert({ kind: 'success', message: `${message} con éxito.` }));
        setModalShow(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleResetEmployee = async item => {
    setOnRequest(true);
    resetEmployeeSurveyRequest(surveyId, item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Respuestas eliminadas con éxito.' }));
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/surveys/employee_surveys/${item.id}`);
        break;
      case 'answer':
        history.push(`/profile/surveys/${item.id}`);
        break;
      case 'reset':
        setModalTitle('Eliminar Respuestas');
        setModalBody(
          `¿Estás seguro que quieres eliminar las respuestas del participante ${item.employee?.full_name}?
           Esta acción es irreversible.`
        );
        setModalShow(true);
        setModalItem(item);
        setModalAction(() => handleResetEmployee);
        break;
      case 'sent':
        setModalTitle('Enviar Encuesta');
        setModalBody(`¿Deseas enviarle la encuesta al participante ${item.employee?.full_name}?`);
        setModalShow(true);
        setModalItem(item);
        setModalAction(() => handleRemindEmployee);
        break;
      case 'reminder':
        setModalTitle('Recordatorio');
        setModalBody(`¿Deseas enviarle un recordatorio al participante ${item.employee?.full_name}?`);
        setModalShow(true);
        setModalItem(item);
        setModalAction(() => handleRemindEmployee);
        break;
      case 'destroy':
        setModalTitle('Eliminar Participante');
        setModalBody(`¿Estás seguro que deseas eliminar al participante ${item.employee?.full_name}?`);
        setModalShow(true);
        setModalItem(item);
        setModalAction(() => handleModalConfirm);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const filterSelector = () => {
    const employeeIds = employeeSurveys.map(employeeSurvey => employeeSurvey.employee?.id);
    setSelector(selector.filter(item => !employeeIds.includes(item.id)));
  };

  const handleSuccessIndex = response => {
    const employeeIds = employeeSurveys.map(employeeSurvey => employeeSurvey.employee?.id);
    const fetchedEmployees = response.data.data;
    setSelector(fetchedEmployees.filter(item => !employeeIds.includes(item.id)));
  };

  const fetchEmployees = () => {
    if (rrhh) {
      indexEmployeesRequest({
        dispatch,
        params: { is_dt: false },
        successCallback: handleSuccessIndex
      });
    }
  };

  const handleSearch = selectedEmployees => {
    setOnRequest(true);
    massCreateEmployeeSurveyRequest(surveyId, {
      dispatch,
      params: { employee_surveys: selectedEmployees },
      successCallback: () => {
        setMoreData(!moreData);
        setEmployee('');
        setSearchModalShow(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_title':
        return { sort_job_titles: name };
      case 'job_management':
        return { sort_job_managements: name };
      case 'employee':
        return { sort_employees: name };
      case 'survey':
        return { sort_surveys: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSendSurvey = () => {
    setOnRequest(true);
    sentSurveyRequest(surveyId, {
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta enviada con éxito.' }));
        setModalShow(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  const sendSurvey = () => {
    const count = employeeSurveys.filter(item => item.status === 'not_sent').length;
    setModalTitle('Enviar Encuesta');
    setModalBody(`Deseas enviar esta encuesta a ${count} trabajador(es)?`);
    setModalShow(true);
    setModalAction(() => handleSendSurvey);
  };

  const defaultClickAction = item => {
    if (!rrhh) {
      return 'answer';
    }
    switch (item.status) {
      case 'not_sent':
        return 'sent';
      case 'sent':
        return 'reminder';
      case 'answered':
        return 'show';
      default:
        return 'show';
    }
  };

  useEffect(fetchEmployees, []);
  useEffect(filterSelector, [employeeSurveys]);

  return (
    <>
      {rrhh && (
        <>
          <div className="info-box ml-0">
            <h4 className="text-uppercase">Agregar Participantes</h4>
          </div>

          <Row className="align-items-center">
            <Col md={5}>
              <FormikSelect
                label="Agregar a"
                placeholder="Seleccionar Trabajador"
                options={sortByAttribute(selector, 'full_name')}
                onChange={data => setEmployee(data.id || '')}
              />
            </Col>
            <Col xs={6} md={2} xl={2}>
              <Button
                variant="primary"
                onClick={handleSelector}
                style={{ marginTop: '5px' }}
                disabled={!employee || onRequest}
              >
                Agregar
              </Button>
            </Col>
            <Col className="ml-2">
              <ButtonTooltip
                variant="circle-primary"
                className="advance-search"
                text="Búsqueda avanzada de trabajadores mediante filtros de cargos, Lugares de Prestación de Servicios, otros"
                onClick={() => setSearchModalShow(true)}
              >
                <Icon className="w-100 h-100" icon="people-circle" />
              </ButtonTooltip>
            </Col>
            <Col className="d-flex align-items-center flex-column">
              {outOfDate && <p className="text-danger font-weight-bold">Encuesta fuera de plazo</p>}
              <Button
                variant="info"
                block
                onClick={sendSurvey}
                disabled={employeeSurveys.filter(item => item.status === 'not_sent').length === 0 || outOfDate}
              >
                Enviar Encuesta
              </Button>
            </Col>
          </Row>
        </>
      )}
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability, outOfDate)}
        data={employeeSurveys}
        handleSortCase={sortColumnCase}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        onRowClicked={row => handleActions(row, defaultClickAction(row))}
        pointerOnHover
        moreData={moreData}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setSearchModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={searchModalShow}
        onHide={() => setSearchModalShow(false)}
      />
    </>
  );
};

export default EmployeeSurveyDataTable;
