import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button, Carousel } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import {
  Icon,
  CustomGraph,
  FormikCheckBox,
  FormikRangePicker,
  FormikSelect,
  DefaultModal,
  ButtonTooltip
} from '../../components';
import { showReportRequest, downloadReportRequest } from '../../requests/reports';
import { additionalQueryOptions, accumulatedQueryOptions, additionalHeaders } from './FormOptions';
import CustomReport from './CustomReport';

const ReportForm = props => {
  const {
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
    reports,
    queryOptions,
    endowmentId,
    setOnRequest,
    loadReports
  } = props;
  const { reportType, reportQueryOptionsAttributes, reportFilterAttributes } = values.report;
  const [extraQueryOptions, setExtraQueryOptions] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [reportSelected, setReportSelected] = useState(false);
  const [report, setReport] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const mappedReports = reports.map(element => ({ label: element.name, value: element.id, type: element.reportType }));
  const customReports = mappedReports.filter(element => element.type === 'custom');
  const reportOptions = mappedReports
    .filter(element => element.type !== 'custom')
    .concat({
      label: `Reportes Personalizados (${customReports.length})`,
      options: [{ label: 'Nuevo Personalizado', type: 'custom' }].concat(customReports)
    });
  const dispatch = useDispatch();

  const handleChartData = chartsData => {
    let charts = [];
    if (chartsData) {
      charts = Object.entries(chartsData).map(([, val]) => ({
        data: Object.entries(val[0]).map(([key, value]) => ({ name: key, value })),
        chartName: val[3],
        chartType: val[4]
      }));
    }
    const differenceChart = charts.filter(item => !chartData.some(other => item.chartName === other.chartName))[0];
    setChartData(charts);
    setActiveIndex(charts.indexOf(differenceChart) > -1 ? charts.indexOf(differenceChart) : 0);
  };

  const handleColumnsData = selectedReport => {
    const colsData = selectedReport.reportQueryOptionsAttributes.map(colData => colData.label);
    const extraHeaders = additionalHeaders.filter(item => item.name === selectedReport.reportType)[0]?.options;
    setColumnsData(extraHeaders ? colsData.concat(extraHeaders) : colsData);
    const qoa = selectedReport.reportQueryOptionsAttributes.map(item => ({
      queryOptionId: item.value,
      optionType: 'q_attribute'
    }));
    setFieldValue('report[reportQueryOptionsAttributes]', qoa);
    const additionalQueries = additionalQueryOptions.filter(e => e.label === selectedReport.reportType)[0];
    const newOptions = [];
    if (additionalQueries && queryOptions) {
      additionalQueries.options.map(item => {
        const directAttribute = queryOptions.filter(e => e.name === item.name)[0];
        if (directAttribute) {
          const pushData =
            item.values?.length === 1 ? directAttribute : { ...directAttribute, multiple: true, direct: true };
          newOptions.push(pushData);
        } else {
          const aqo = accumulatedQueryOptions.filter(e => e.name === item.name)[0].values;
          newOptions.push({
            label: item.name,
            value: aqo.map(qo => queryOptions.filter(e => e.name === qo)[0])[0].value,
            multiple: true
          });
        }
        return newOptions;
      });
    }
    setExtraQueryOptions(newOptions);
  };

  const fetchReport = (id, params) => {
    setOnRequest(true);
    showReportRequest(id, {
      dispatch,
      params,
      successCallback: response => {
        const data = camelCaseRecursive(response.data);
        handleColumnsData(data.report);
        handleChartData(data.chartData !== null ? data.chartData : []);
        setReportSelected(false);
        setReport(data);
        setFieldValue('report[name]', data.report.name);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateCharts = () => {
    if (reportType) {
      const params = snakeCaseKeys({ ...values, updateChart: true });
      if (params.report.report_filter_attributes.with_filter) {
        params.report.report_filter_attributes.q_contracts_status__in = [0, 1];
      } else {
        params.report.report_filter_attributes.q_contracts_status__in = [0];
      }
      downloadReportRequest({
        dispatch,
        params,
        successCallback: response => {
          handleChartData(camelCaseRecursive(response.data.chart));
        },
        callback: () => setOnRequest(false)
      });
    }
  };

  const updateColumnsAndCheckboxes = (field, label, qAttributeValue, multiple, name, directAttr) => {
    setOnRequest(true);
    let myLabels = [];
    let addedOptions;
    setFieldValue(field.name, !field.value);
    const qoa = reportQueryOptionsAttributes;
    if (!multiple) {
      addedOptions = [{ queryOptionId: qAttributeValue, optionType: 'q_attribute' }];
      myLabels = [label];
    } else {
      const dataToModify = additionalQueryOptions.filter(e => e.label === reportType)[0].options;
      const addQueries = directAttr
        ? dataToModify.filter(e => e.name === name)[0].values
        : accumulatedQueryOptions.filter(e => e.name === label)[0].values;
      addedOptions = addQueries.map(item => {
        const additionalQuery = queryOptions.filter(e => e.name === item)[0];
        myLabels.push(additionalQuery.label);
        return { queryOptionId: additionalQuery.value, optionType: 'q_attribute' };
      });
    }
    if (field.value !== true) {
      values.report.reportQueryOptionsAttributes = [...qoa, ...addedOptions];
      setColumnsData(columnsData.concat(myLabels));
      if (label === 'Razón de Término del contrato') {
        reportFilterAttributes.withFilter = 1;
      }
    } else {
      if (label === 'Razón de Término del contrato') {
        reportFilterAttributes.withFilter = undefined;
      }
      const dataColumns = columnsData;
      const compareToFindIndex = (element, compare) => element === compare;
      addedOptions.map(qaAdded =>
        qoa.splice(
          qoa.findIndex(element => compareToFindIndex(element, qaAdded)),
          1
        )
      );
      myLabels.map(myLabel =>
        dataColumns.splice(
          dataColumns.findIndex(element => compareToFindIndex(element, myLabel)),
          1
        )
      );
      setColumnsData(dataColumns);
    }
    handleUpdateCharts();
  };

  const modalBody = () => {
    const body = (
      <Row>
        {extraQueryOptions.map(item => (
          <Col key={`filter-${item.label}`} md={4} xs={6} className="d-flex align-items-center">
            <Field name={`optionQueryCheckboxes[${item.value}]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label={item.label}
                  onClick={() =>
                    updateColumnsAndCheckboxes(field, item.label, item.value, item.multiple, item.name, item.direct)
                  }
                />
              )}
            </Field>
          </Col>
        ))}
      </Row>
    );
    return body;
  };

  const chartBody = () => (
    <>
      <h4 className="reports-titles text-uppercase ml-0 mt-3">Gráficos del Reporte</h4>
      <div className="reports-card">
        {chartData.length > 0 ? (
          <Carousel
            indicators={false}
            interval={null}
            onSelect={selectedIndex => setActiveIndex(selectedIndex || 0)}
            activeIndex={activeIndex}
            fade
            prevIcon={chartData.length > 1 && <Icon width={20} style={{ color: 'black' }} icon="chevron-back" />}
            nextIcon={chartData.length > 1 && <Icon width={20} style={{ color: 'black' }} icon="chevron-forward" />}
          >
            {chartData.map(element => (
              <Carousel.Item key={`slide-${element.chartName}`}>
                <CustomGraph
                  chartType={element.chartType}
                  data={element.data}
                  title={element.chartName}
                  xLegend={`${activeIndex + 1} de ${chartData.length}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <p className="reports-no-charts">No hay gráficos en este reporte.</p>
        )}
      </div>
    </>
  );

  const handleSelectedReport = reportId => {
    setFieldValue('report[reportFilterAttributes]', {});
    setFieldValue('report[reportQueryOptionsAttributes]', []);
    setFieldValue('optionQueryCheckboxes', []);
    setFieldValue('report[name]', '');
    if (reportId) {
      fetchReport(reportId);
    } else {
      setReport({});
      setChartData([]);
    }
  };

  const onChangeUpdateChart = () => {
    if (!reportSelected) handleUpdateCharts();
  };

  const initialEndowment = () => {
    setFieldValue('report[reportType]', 'endowment');
    fetchReport(endowmentId);
  };

  useEffect(onChangeUpdateChart, [reportFilterAttributes.startDate, reportFilterAttributes.endDate]);
  useEffect(initialEndowment, [endowmentId]);

  return (
    <>
      <>
        <Form>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Field name="report[reportType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Tipo de Reporte"
                        options={reportOptions}
                        defaultValue="endowment"
                        placeholder="Dotación"
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        onChange={data => {
                          setFieldValue(field.name, data ? data.type : '');
                          handleSelectedReport(data.value);
                          if (data.type !== 'custom') {
                            setReportSelected(true);
                          }
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              {reportType && reportType !== 'custom' && (
                <Row>
                  <Col md={12}>
                    <Field name="rangeDate">
                      {({ field }) => (
                        <FormikRangePicker
                          {...field}
                          startDateName="report[reportFilterAttributes][startDate]"
                          endDateName="report[reportFilterAttributes][endDate]"
                          startDate={reportFilterAttributes.startDate}
                          endDate={reportFilterAttributes.endDate}
                          showClearDates
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={6} className="mt-4">
              {extraQueryOptions.length > 0 && reportType !== 'custom' && (
                <>
                  <Button onClick={() => setModalShow(true)}>Opciones adicionales</Button>
                  <Row style={{ marginTop: '30px' }}>
                    <Col md={12}>
                      {extraQueryOptions.map(item => (
                        <>
                          {values.optionQueryCheckboxes[item.value] && (
                            <div key={`column-badge-${item.label}`} className="badge badge-light pr-2 pl-2 mr-2 mt-2">
                              <p className="d-inline mr-2">{item.label}</p>
                              <Field name={`optionQueryCheckboxes[${item.value}]`}>
                                {({ field }) => (
                                  <ButtonTooltip
                                    onClick={() =>
                                      updateColumnsAndCheckboxes(
                                        field,
                                        item.label,
                                        item.value,
                                        item.multiple,
                                        item.name,
                                        item.direct
                                      )
                                    }
                                    variant="circle-light"
                                    className="btn-circle d-inline"
                                    toolbarVariant="d-inline"
                                    style={{ width: '25px', height: '25px' }}
                                    size="sm"
                                    text="Eliminar"
                                  >
                                    x
                                  </ButtonTooltip>
                                )}
                              </Field>
                            </div>
                          )}
                        </>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {reportType !== 'custom' ? (
            <>
              <hr />
              <Row key={`chart-${chartData.length}`} className="justify-content-around">
                {columnsData.length > 0 && (
                  <>
                    <Col md={3}>
                      <h4 className="reports-titles text-uppercase mt-3">Columnas del Reporte</h4>
                      <div className="reports-card report-scroll">
                        {columnsData.map((item, index) => (
                          <div key={`column-${item}`}>
                            <p className="ml-3 ml-md-0 ml-xl-3 mr-2">{`${index + 1}. ${item}`}</p>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col md={8}>{chartBody()}</Col>
                  </>
                )}
              </Row>
              <Row className="my-3 mr-md-2 mr-xl-3">
                <Col md={{ span: 3, offset: 9 }}>
                  <Button type="submit" className="mb-5" variant="primary" block>
                    DESCARGAR REPORTE
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <CustomReport queryOptions={queryOptions} report={report} loadReports={loadReports} />
          )}
        </Form>
        <DefaultModal
          title="Filtro"
          body={modalBody()}
          modalSize="lg"
          show={modalShow}
          handleClose={() => setModalShow(false)}
          handleConfirm={() => setModalShow(false)}
          withClose={false}
          titleBtnSave="Confirmar"
        />
      </>
    </>
  );
};

const setInitialValues = () => {
  return { optionQueryCheckboxes: [], report: { reportFilterAttributes: {}, reportQueryOptionsAttributes: [] } };
};

const validationSchema = Yup.object().shape({
  report: Yup.object().shape({
    name: Yup.string().when('reportType', {
      is: val => val === 'custom',
      then: Yup.string().required('Debes ingresar el nombre de reporte'),
      otherwise: Yup.string().nullable()
    }),
    reportQueryOptionsAttributes: Yup.array().min(1, 'Debe escoger al menos una columna')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit
})(ReportForm);
