import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, BasicActionBtns, DatatableEmployeeName } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1.5'
  },
  {
    name: 'NOMBRE DE GRUPO',
    selector: 'settlement_group',
    cell: item => item.settlement_group_name,
    sortable: true,
    grow: '1.25'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1.25'
  },
  {
    name: 'TOTAL LÍQUIDO',
    selector: 'liquid_amount',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL BRUTO',
    selector: 'gross_amount',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => (
      <ApprovementStatus
        status={item.status}
        translatedStatus={item.translated_status}
        onClick={() => clickHandler(item, 'show')}
      />
    ),
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Settlement"
        clickHandler={clickHandler}
        withDestroy={false}
        withEdit={false}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    center: true,
    grow: '0.5',
    minWidth: '100px'
  }
]);
