import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { indexBenefitsRequest } from '../../../requests/benefits';
import { InfiniteScroll } from '../../../components';
import BenefitCard from '../../Shared/Benefit/BenefitCard';

const BenefitProfileIndex = () => {
  const [benefits, setBenefits] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    if (response.data.data.length + benefits.length === response.data.metadata.amount) {
      setHasMore(false);
    }
    setPage(page + 1);
    setBenefits([...benefits, ...camelCaseRecursive(response.data.data)]);
  };

  const handleRequest = () => {
    setOnRequest(true);
    indexBenefitsRequest({
      dispatch,
      params: {
        active: moment().format('DD/MM/YYYY'),
        my_job_management: true,
        display_start: page * 15,
        display_length: 15,
        sort_column: 'position',
        sort_direction: 'asc'
      },
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  useEffect(handleRequest, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="m-top mb-3 mt-3">Beneficios</h2>
        </Col>
      </Row>
      <InfiniteScroll dataLength={benefits.length} next={handleRequest} hasMore={hasMore} label="beneficios">
        <Row className={`${onRequest && 'bg-opacity'}`}>
          {benefits.map(item => (
            <Col key={item.id} xs={12} md={6}>
              <BenefitCard benefits={item} />
            </Col>
          ))}
        </Row>
      </InfiniteScroll>
    </>
  );
};

export default BenefitProfileIndex;
