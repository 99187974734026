import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusError = ({ setEmployeeTab, setAccordion, errorKeys }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.entries(errors).length) {
      const errorsCollection = [...document.getElementsByClassName('is-invalid')];
      errorsCollection.forEach((_value, key) => {
        errorsCollection[key].tabIndex = `error-${key}`;
      });
      if (errorsCollection.length === 0) {
        return;
      }
      const errorKey = errors?.employee ? Object.keys(errors.employee)[0] : null;
      if (setEmployeeTab) {
        const employeeTab =
          errorKey === 'contract' || errorKey === 'employeePrevisionAttributes' ? 'contract' : 'profile';
        setEmployeeTab(employeeTab);
      }

      if (setAccordion) {
        const personalData = ['email', 'firstLastName', 'identificationType', 'name', 'nationalIdentification'];
        let accordionKey = '';
        personalData.some(element => {
          if (element === errorKey) {
            accordionKey = 'profile';
          }
          return setAccordion(accordionKey);
        });

        if (errorKeys.includes('email')) {
          setAccordion('contact');
        }

        if (errorKeys.includes('familyGroupsAttributes')) {
          setAccordion('family');
        }

        if (errors.employee.contract !== undefined) {
          const contractErrors = Object.keys(errors.employee.contract);
          if (errorKeys.includes('contract')) {
            setAccordion('contract');
          }

          if (contractErrors.includes('salary')) {
            setAccordion('salary');
          }

          if (contractErrors.includes('contractBalancesAttributes')) {
            setAccordion('discounts');
          }

          if (contractErrors.includes('employeePrevisionAttributes')) {
            setAccordion('prevision');
          }
        }
      }
      setTimeout(() => errorsCollection[0].focus(), 100);
    }
  });

  return null;
};

export default FocusError;
