import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { Icon, LinkBtn } from '../../components';
import { showSettlementRequest } from '../../requests/settlements';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicSettlement from './basicSettlement';
import SettlementInfo from './SettlementInfo';

const SettlementShow = ({ match }) => {
  const { id: settlementId } = match.params;
  const [settlement, setSettlementGroup] = useState(basicSettlement);
  const dispatch = useDispatch();

  const fetchSettlementGroup = () => {
    showSettlementRequest(settlementId, {
      dispatch,
      successCallback: response => setSettlementGroup(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchSettlementGroup, []);

  return (
    <>
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to="/settlements">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={9} className="mt-3">
          <h2 className="text-uppercase mt-1">Detalle Finiquito Individual</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="d-flex align-items-center mb-sm-4 mb-lg-0 mb-3">
          <h4 className="text-uppercase my-0">{settlement.employee?.fullName}</h4>
        </Col>
        <Col md={5} lg={3} className="mb-3">
          <Card className="mb-0">
            <Card.Body className="text-uppercase py-2">
              <h5 className="mb-0">
                Estado Finiquito: <span className="text-success ml-3">Aprobado</span>
              </h5>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={5} md={2} className="mb-3">
          <Button block disabled variant="warning">
            Suspender
          </Button>
        </Col>
        <Col sm={6} md={3} lg={2} className="mb-3">
          <Button block disabled variant="primary">
            Pasos del Proceso
          </Button>
        </Col>
      </Row>
      <SettlementInfo />
    </>
  );
};

export default SettlementShow;
