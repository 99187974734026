import React from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from '../Utils/Tooltips/BasicTooltip';

const ActiveDot = ({ item, type }) => {
  const text = item.active || item.is_active ? 'Activo' : 'Inactivo';
  const currentType = type ? 'text' : 'dot dot';
  const currentClass = item.active || item.is_active ? `${currentType}-success` : `${currentType}-danger`;
  const textSpan = type && text.toUpperCase()

  return <>
    <BasicTooltip text={text}>
      <span className={currentClass} data-tag="allowRowEvents">
        {textSpan}
      </span>
    </BasicTooltip>
  </>
}

ActiveDot.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

ActiveDot.defaultProps = {};

export default ActiveDot;
