import React, { Component } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPage } from '../../actions/utils';
import Page404 from './Page404';

class Page extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    dispatch(getPage(match.params.page));
  };

  loading = () => (
    <Row className="mt-100 justify-content-center mb-5">
      <Col md={6}>
        <Spinner animation="border" variant="primary" />;
      </Col>
    </Row>
  );

  content = () => {
    const {
      utils: {
        page: { body }
      }
    } = this.props;

    if (body) {
      return (
        <Row className="mt-100 justify-content-center mb-5">
          <Col md={6}>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </Col>
        </Row>
      );
    }
    return <Page404 />;
  };

  render() {
    const {
      utils: { ongoingRequest }
    } = this.props;
    return ongoingRequest.getPage ? this.loading() : this.content();
  }
}

const mapStateToProps = state => {
  const { utils } = state;
  return {
    utils
  };
};

export default withRouter(connect(mapStateToProps)(Page));
