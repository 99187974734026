import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner, Row, Col } from 'react-bootstrap';

import { createMassiveAbsenceRequest } from '../../requests/absences';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import AbsencesForm from './AbsencesForm';

const AbsencesNew = () => {
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const returnTo = new URLSearchParams(location.search).get('returnTo') || 'absences';

  const handleSuccessCreate = () => {
    const message = 'Ausencias creadas con éxito';
    setOnRequest(false);
    history.push(`/assistance?tab=assistance&assistance-tab=absent-datatable`);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const validateLength = absenceValues => {
    if (!absenceValues.absences.length) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar al menos un trabajador' }));
      return true;
    }
    return false;
  };

  const handleCreate = (absenceValues, setSubmitting) => {
    if (validateLength(absenceValues)) {
      setSubmitting(false);
      return;
    }
    setOnRequest(true);
    const params = snakeCaseKeys(absenceValues);

    createMassiveAbsenceRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to={`/${returnTo}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Agregar Ausentismo</h2>
        </Col>
      </Row>
      <AbsencesForm action="new" formRequest={handleCreate} />
    </>
  );
};

export default AbsencesNew;
