import React from 'react';
import { Modal } from 'react-bootstrap';

const AbsenceInfo = ({ absence }) => (
  <Modal.Body>
    <div className="info-box">
      <p className="info">
        <span>Motivo:</span> {absence.translatedAbsenceType}
      </p>
      {absence.absenceTypeFormat && (
        <p className="info">
          <span>Formato:</span> {absence.absenceTypeFormat}
        </p>
      )}
      <p className="info">
        <span>Fecha de Inicio</span> {absence.startDate}
      </p>
      <p className="info">
        <span>Fecha de Término</span> {absence.endDate}
      </p>
      <p className="info">
        <span>Cantidad de días</span> {absence.days}
      </p>
      {absence.observation && (
        <p className="info">
          <span>Observaciones:</span> {absence.observation}
        </p>
      )}
    </div>
  </Modal.Body>
);

export default AbsenceInfo;
