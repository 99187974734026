import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn } from '../../../components';
import EmployeeDataTable from './EmployeeDataTable';

const EmployeeIndex = () => {
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">Colaboradores</h2>
        </Col>
        <Col md={3} xl={2}>
          <LinkBtn variant="primary" className="mt-3" block to="/admin/employees/new">
            Nuevo
          </LinkBtn>
        </Col>
      </Row>
      <EmployeeDataTable withMassActions clickRoute="/admin/employees" />
    </>
  );
};

export default EmployeeIndex;
