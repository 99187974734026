import ApiService from '../services/apiService';

const organizationChartRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/companies/organization_chart', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export default organizationChartRequest;
