import React from 'react';
import { Modal } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

const CompanyTaskInfo = props => {
  const { item } = props;

  const { title, startDate, endDate, description } = camelCaseRecursive(item);

  return (
    <Modal.Body>
      <div className="info-box">
        <p className="info">
          <span>Título: </span>
          {title}
        </p>
        <p className="info">
          <span>Inicio: </span>
          {startDate}
        </p>
        <p className="info">
          <span>Fin: </span>
          {endDate}
        </p>
        <p className="info">
          <span>Descripción: </span>
          {description}
        </p>
      </div>
    </Modal.Body>
  );
};
export default CompanyTaskInfo;
