import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../components';
import {
  debounceIndexAnnouncementRequest,
  deleteAnnouncementRequest,
  updateAnnouncementRequest
} from '../../requests/announcements';
import AnnouncementForm from './AnnouncementForm';
import AnnouncementModalShow from './AnnouncementModalShow';
import { delayMethod, camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const AnnouncementDataTable = ({ columns, moreData, setMoreData }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexAnnouncementRequest({
        dispatch,
        params: { ...params },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setAnnouncements(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleModalConfirm = item => {
    setOnRequest(true);
    deleteAnnouncementRequest(item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Anuncio eliminado con éxito.'
          })
        );
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateRequest = (announcementValues, setSubmitting) => {
    const { image, id } = announcementValues.announcement;
    const myParams = snakeCaseKeys(announcementValues, { exclude: ['_destroy'] });
    myParams.announcement.image = image;
    updateAnnouncementRequest(id, {
      dispatch,
      params: myParams,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Anuncio actualizado con éxito' }));
        setMoreData(!moreData);
        setSimpleModalShow(false);
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalTitle(item.translated_announcement_type);
        setModalBody(<AnnouncementModalShow announcement={camelCaseEmptyStringRecursive(item)} />);
        setSimpleModalShow(true);
        break;
      case 'edit':
        setModalTitle('Nueva Fecha Destacada');
        setModalBody(
          <AnnouncementForm
            announcement={camelCaseEmptyStringRecursive(item)}
            action="edit"
            formRequest={handleUpdateRequest}
          />
        );
        setSimpleModalShow(true);
        break;
      case 'destroy':
        setModalBody(`¿Estás seguro que deseas eliminar el anuncio ${item.title}?`);
        setModalShow(true);
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'group':
        return { sort_groups: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={announcements}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        resourceRequest={handleIndexRequest}
        onRowClicked={row => handleActions(row, 'show')}
        pointerOnHover
        moreData={moreData}
      />
      <DefaultModal
        title="Eliminar Anuncio"
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => handleModalConfirm(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default AnnouncementDataTable;
