export const utilsTypes = {
  // ADVANCE SETTINGS
  SET_ADVANCE_SETTINGS_REQUEST: 'SET_ADVANCE_SETTINGS_REQUEST',
  SET_ADVANCE_SETTINGS_FAILURE: 'SET_ADVANCE_SETTINGS_FAILURE',
  SET_ADVANCE_SETTINGS_SUCCESS: 'SET_ADVANCE_SETTINGS_SUCCESS',
  // SIDEBAR
  TOOGLE_SIDEBAR: 'TOOGLE_SIDEBAR',
  SET_SIDEBAR: 'SET_SIDEBAR',
  // SETTINGS
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  // SLIDES
  GET_SLIDES_REQUEST: 'GET_SLIDES_REQUEST',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
  GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
  // PAGE
  GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'GET_PAGE_FAILURE',
  // Roles
  UPDATE_ROLES: 'UPDATE_ROLES',
  // PRETENDER
  IS_PRETENDING: 'IS_PRETENDING',
  // NOTIFICATION
  NOTIFICATION: 'NOTIFICATION',
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',
  // UPDATE_COMPANIES
  UPDATE_COMPANIES: 'UPDATE_COMPANIES',
};

// ADVANCE SETTINGS
export const setAdvanceSettings = params => ({ type: utilsTypes.SET_ADVANCE_SETTINGS_REQUEST, params });

// SIDEBAR
export const toogleSidebar = () => ({ type: utilsTypes.TOOGLE_SIDEBAR });
export const setSidebar = params => ({ type: utilsTypes.SET_SIDEBAR, params });

// SETTINGS
export const getSettings = () => ({ type: utilsTypes.GET_SETTINGS_REQUEST });

// SLIDE
export const getSlides = () => ({ type: utilsTypes.GET_SLIDES_REQUEST });

// PAGE
export const getPage = url => ({
  type: utilsTypes.GET_PAGE_REQUEST,
  url
});

// ALERT
export const closeAlert = params => ({ type: utilsTypes.CLOSE_ALERT, params });
export const sendAlert = params => ({ type: utilsTypes.SET_ALERT, ...params });
export const setHeaders = params => ({ type: utilsTypes.SET_HEADERS, ...params });
