import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { updateBranchOfficeRequest } from '../../requests/branchOffices';
import { sendAlert } from '../../actions/utils';
import BranchOfficeForm from './BranchOfficeForm';
import BranchOfficeDataTable from './BranchOfficeDataTable';
import BranchOfficeShow from './BranchOfficeShow';
import columns from './columns';

const BranchOfficeIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Lugar de Prestación de Servicios');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = branchOfficeValue => {
    const {
      branchOffice: { id: branchOfficeId }
    } = branchOfficeValue;
    updateBranchOfficeRequest(branchOfficeId, {
      dispatch,
      params: snakeCaseKeys(branchOfficeValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Lugar de Prestación de Servicios actualizado con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editBranchOffice = branchOfficeRow => {
    setModalShow(true);
    setModalTitle('Editar Lugar de Prestación de Servicios');
    setModalBody(
      <BranchOfficeForm
        branchOffice={camelCaseRecursive(branchOfficeRow)}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const showBranchOffice = branchOfficeRow => {
    setModalShow(true);
    setModalBody(
      <BranchOfficeShow branchOffice={camelCaseRecursive(branchOfficeRow)} hideModal={() => setModalShow(false)} />
    );
    setModalTitle(branchOfficeRow.name);
  };

  return (
    <>
      <BranchOfficeDataTable
        columns={columns}
        editBranchOffice={editBranchOffice}
        moreData={moreData}
        setMoreData={setMoreData}
        showBranchOffice={showBranchOffice}
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default BranchOfficeIndex;
