import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { DocumentEditor, InputSelect } from '../../components';
import { indexEmployeesRequest } from '../../requests/employees';
import { previewTemplatePdfRequest } from '../../requests/templatePdfs';

const DocumentPreview = ({ body }) => {
  const dispatch = useDispatch();
  const [previewBody, setPreviewBody] = useState(body);
  const [employeeId, setEmployeeId] = useState('');
  const [onRequest, setOnRequest] = useState(false);

  const fetchEmployees = (inputValue, callback) => {
    indexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 10
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchDocumentPreview = () => {
    if (employeeId) {
      setOnRequest(true);
      previewTemplatePdfRequest({
        dispatch,
        params: {
          template_body: body,
          employee_ids: [employeeId]
        },
        successCallback: response => setPreviewBody(response.data.body),
        callback: () => setOnRequest(false)
      });
    } else {
      setPreviewBody(body);
    }
  };

  useEffect(fetchDocumentPreview, [body, employeeId]);

  return (
    <>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <h3 className="text-uppercase">Previsualización</h3>
            <Row>
              <Col md={5}>
                <InputSelect
                  isClearable
                  label="Colaboradores"
                  placeholder="Seleccione colaboradores"
                  values={employeeId}
                  request={fetchEmployees}
                  onChange={data => setEmployeeId(data ? data.value : '')}
                />
              </Col>
            </Row>
            {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
            <DocumentEditor data={previewBody} disabled isReadOnly />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default DocumentPreview;
