import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { indexContractVersionRequest } from '../../requests/contractVersions';
import { sendAlert } from '../../actions/utils';
import ContractVersionTable from './ContractVersionTable';
import { delayMethod } from '../../services/utils';

const ContractVersionIndex = ({ contractId, employeeId, hideModal }) => {
  const [contractVersions, setContractVersions] = useState([]);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    hideModal();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const fetchVersions = () => {
    indexContractVersionRequest({
      dispatch,
      contractId,
      params: {
        sort_column: 'created_at',
        sort_direction: 'desc',
        paginate: false
      },
      successCallback: response => {
        const { data } = response.data;
        setContractVersions(data);
      },
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchVersions, [contractId]);

  return (
    <>
      <ContractVersionTable contractVersions={contractVersions} contractId={contractId} employeeId={employeeId} />
      <Modal.Footer>
        <Button onClick={() => delayMethod(hideModal)}>Cerrar</Button>
      </Modal.Footer>
    </>
  );
};

export default ContractVersionIndex;
