import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSurveyRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/surveys', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/surveys/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createSurveyRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', '/surveys', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const sentSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/surveys/${id}/sent`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const updateSurveyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/surveys/${id}/`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const deleteSurveyRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/surveys/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const duplicateSurveyRequest = (id, { dispatch, params, successCallback }) => {
  ApiService.request('post', `/surveys/${id}/duplicate`, {
    dispatch,
    params,
    formData: true,
    successCallback
  });
};

export const exportSurveyRequest = (id, { dispatch, params, successCallback, callback }) => {
  ApiService.request('get', `/surveys/${id}/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });
};

export const debounceIndexSurveyRequest = AwesomeDebouncePromise(indexSurveyRequest, 300);
