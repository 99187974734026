import React, { Component } from 'react';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createJointCommitteeRequest } from '../../requests/jointCommittees';
import { sendAlert } from '../../actions/utils';
import JointCommitteeForm from './JointCommitteeForm';

class JointCommitteeNew extends Component {
  state = {
    jointCommittee: {
      address: '',
      addressFloor: '',
      addressNumber: '',
      addressOffice: '',
      code: '',
      communeId: '',
      communeName: '',
      employeeJointCommitteesAttributes: [],
      name: '',
      phone: '',
      phoneCountryCode: '+56',
      regionId: ''
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCreateRequest = (jointCommittee, setSubmitting) => {
    const { dispatch } = this.props;

    createJointCommitteeRequest({
      dispatch,
      params: snakeCaseKeys(jointCommittee),
      successCallback: this.handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  handleSuccessCreate = response => {
    const { history, dispatch } = this.props;
    const { data: jointCommittee } = response;
    dispatch(sendAlert({ kind: 'success', message: 'Comité creado con éxito' }));
    history.push(`/joint_committees/${jointCommittee.id}`);
  };

  render() {
    const { jointCommittee } = this.state;
    return (
      <>
        <h2 className="text-uppercase mb-3 mt-5">Nuevo Comité</h2>
        <JointCommitteeForm jointCommittee={jointCommittee} action="new" formRequest={this.handleCreateRequest} />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { company } = state.auth;
  return { company };
};

export default connect(mapStateToProps)(JointCommitteeNew);
