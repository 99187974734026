import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { updateSubsidiaryRequest } from '../../requests/subsidiaries';
import { sendAlert } from '../../actions/utils';
import SubsidiaryForm from './SubsidiaryForm';
import SubsidiaryDataTable from './SubsidiaryDataTable';
import SubsidiaryShow from './SubsidiaryShow';
import columns from './columns';

const SubsidiaryIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Sucursal');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = subsidiaryValue => {
    const {
      subsidiary: { id: subsidiaryId }
    } = subsidiaryValue;
    updateSubsidiaryRequest(subsidiaryId, {
      dispatch,
      params: snakeCaseKeys(subsidiaryValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Sucursal actualizada con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editSubsidiary = subsidiaryRow => {
    setModalShow(true);
    setModalTitle('Editar Sucursal');
    setModalBody(
      <SubsidiaryForm
        subsidiary={camelCaseRecursive(subsidiaryRow)}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const showSubsidiary = subsidiaryRow => {
    setModalShow(true);
    setModalBody(
      <SubsidiaryShow subsidiary={camelCaseRecursive(subsidiaryRow)} hideModal={() => setModalShow(false)} />
    );
    setModalTitle(subsidiaryRow.name);
  };

  return (
    <>
      <SubsidiaryDataTable
        columns={columns}
        editSubsidiary={editSubsidiary}
        moreData={moreData}
        setMoreData={setMoreData}
        showSubsidiary={showSubsidiary}
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default SubsidiaryIndex;
