import ApiService from '../services/apiService';

export const indexWorkflowTemplatesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/workflow_templates', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createWorkflowTemplateRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/workflow_templates', {
    dispatch,
    params,
    formData,
    callback,
    successCallback,
    showLeafArrayIndexes: true
  });

export const updateWorkflowTemplateRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/workflow_templates/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const deleteWorkflowTemplateRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/workflow_templates/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const showByTypeWorkflowTemplatesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/workflow_templates/show_by_type', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
