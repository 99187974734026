import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';

const EmergencyContact = ({ employee, activeAccordion, activeKey }) => {
  const { emergencyContactName, parsedEmergencyContactPhone, translatedEmergencyContactRelation } = employee;

  return (
    <Card>
      <Col className={`${activeKey === 'emergency-contact' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle
          as={Col}
          eventKey="3"
          className="card-header-title"
          onClick={activeAccordion}
          style={{ textAlign: 'right' }}
        >
          CONTACTO DE EMERGENCIA
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="3">
        <Card.Body className="div-content">
          <Row>
            <Col>
              <div className="info">
                <span className="type">Nombre completo:</span>
                <span className="align-right" />
                <span className="answer"> {emergencyContactName}</span>
              </div>
              <div className="info">
                <span className="type">Relación:</span>
                <span className="align-right" />
                <span className="answer"> {translatedEmergencyContactRelation}</span>
              </div>
              <div className="info">
                <span className="type">Teléfono:</span>
                <span className="align-right" />
                <span className="answer"> {parsedEmergencyContactPhone}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default EmergencyContact;
