import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { withFormik, Form, Field } from 'formik';
import { debounceIndexAdminCompaniesRequest } from '../../../requests/adminCompanies';
import { InputSelect, FormikSelect, FormikRangePicker, FormikCheckBox } from '../../../components';
import { clasificationTypes, criticalityTypes, statuses } from './FormOptions';
import { delayMethod } from '../../../services/utils';

const ReportFilterModal = ({ cancelAction, values, setFieldValue, setFieldTouched }) => {
  const dispatch = useDispatch();

  const resultCompanyFetchData = response => {
    const { data } = response.data;
    const tempArray = data.map(element => {
      return {
        label: element.business_name,
        value: element.id
      };
    });
    return tempArray;
  };

  const fetchCompanies = (inputValue, callback) => {
    debounceIndexAdminCompaniesRequest({
      dispatch,
      params: { business_name: inputValue, order_by_attribute: 'business_name', paginate: false },
      successCallback: data => callback(resultCompanyFetchData(data))
    });
  };

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Field name="rangeDate">
                {({ field }) => (
                  <FormikRangePicker
                    {...field}
                    startDateName="filterDateFrom"
                    endDateName="filterDateTo"
                    startDate={values.filterDateFrom}
                    endDate={values.filterDateTo}
                    showClearDates
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={6}>
              <Field name="companyFilter">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Empresa"
                    placeholder="Todas"
                    request={fetchCompanies}
                    value={undefined}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    isClearable
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={4}>
              <Field name="clasificationFilter">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Clasificación"
                    placeholder="Todas"
                    options={clasificationTypes}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    isClearable
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={4}>
              <Field name="criticalityFilter">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Criticidad"
                    placeholder="Todas"
                    options={criticalityTypes}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    isClearable
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={4}>
              <Field name="statusFilter">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Estado"
                    placeholder="Todos"
                    options={statuses}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    isClearable
                  />
                )}
              </Field>
            </Col>
            <Col md={4} className="d-flex align-items-center z-index-0">
              <Field name="viaEmailFilter">
                {({ field }) => <FormikCheckBox {...field} custom field={field} label="Canal por Correo" />}
              </Field>
            </Col>
            <Col md={4} className="d-flex align-items-center z-index-0">
              <Field name="viaPhoneFilter">
                {({ field }) => <FormikCheckBox {...field} custom field={field} label="Canal Telefónico" />}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn" variant="info" onClick={() => delayMethod(cancelAction)}>
            Cancelar
          </Button>
          <Button type="submit" className="btn" variant="primary">
            Generar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = () => ({
  rangeDate: '',
  companyFilter: '',
  clasificationFilter: '',
  criticalityFilter: ''
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values, '.xlsx', 'text');
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  handleSubmit
})(ReportFilterModal);
