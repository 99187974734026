import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../../actions/utils';
import { DefaultModal } from '../../../components';
import { destroyDocumentImedRequest } from '../../../requests/integrationDocumentImeds';
import { uploadDocumentImedMassiveRequest, uploadDocumentImedRequest } from '../../../requests/licenseImed';
import FileTransactDataTable from './FileTransactDataTable';
import UploadFileImedForm from './UploadFileImedForm';

const FileTransact = props => {
  const { rutEmployee, numLicense, employee, payrolls } = props;
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [modalAction, setModalAction] = useState('');
  const [modalItem, setModalItem] = useState('');
  const [modalDestroyShow, setModalDestroyShow] = useState(false);
  const [body, setBody] = useState('');
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalDestroyShow(false);
    setModalShow(false);
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };
  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleAttendanceImportRequest = async (documentImed, setSubmitting) => {
    uploadDocumentImedRequest({
      dispatch,
      params: documentImed,
      formData: true,
      successCallback: response => handleSuccessAction(response?.data?.message),
      failureCallback: handleFailureRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleDownloadDocument = item => {
    const link = document.createElement('a');
    link.href = item.link;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const removeAbsence = item => {
    destroyDocumentImedRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Documento eliminado con éxito'),
      failureCallback: handleFailureRequest
    });
  };
  const handleDestroyDocument = item => {
    setModalDestroyShow(true);
    setTitleModal('Eliminar Documento');
    setBody('¿Estás seguro que deseas eliminar este documento?');
    setModalItem(item);
    setModalAction(() => removeAbsence);
  };

  const fetchFile = (codLicense, filename, fileStorage) => {
    uploadDocumentImedRequest({
      dispatch,
      params: {
        lme_inf_adjunto: {
          num_license: codLicense[0],
          dv_license: codLicense[1],
          file_type: 3,
          file_name: filename,
          file: fileStorage
        }
      },
      formData: true,
      successCallback: () => setMoreData(!moreData)
    });
  };

  const fetchFileContract = () => {
    const codLicense = numLicense.split('-');
    if (employee.have_active_contracts === true) {
      const { contract } = employee;
      if (Object.keys(contract.document).length > 0) {
        const filename = contract?.document?.file_info?.filename;
        const fileStorage = contract?.document?.file_info?.file_storage;
        fetchFile(codLicense, filename, fileStorage);
      }
    }
  };

  const initRequest = () => {
    fetchFileContract();
  };

  const uploadFileLiquidation = fileInfo => {
    uploadDocumentImedMassiveRequest({
      dispatch,
      params: fileInfo,
      successCallback: () => setMoreData(!moreData)
    });
  };
  const fetchFileLiquidation = () => {
    const codLicense = numLicense.split('-');

    let fileInfo = [];

    if (Object.keys(payrolls).length > 0) {
      fileInfo = {
        lme_inf_adjunto: payrolls.map(e => {
          return {
            num_license: codLicense[0],
            dv_license: codLicense[1],
            file_type: 1,
            file_name: e.file_info.filename,
            file: e.file_info.file_storage
          };
        })
      };
      uploadFileLiquidation(fileInfo);
    }
  };

  useEffect(initRequest, []);
  useEffect(fetchFileLiquidation, [payrolls]);

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'download':
        handleDownloadDocument(item);
        break;
      case 'destroy':
        handleDestroyDocument(item);
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleModalUploadFile = () => {
    setModalShow(true);
    setTitleModal('Adjuntar Documentos');
    setBody(
      <>
        <UploadFileImedForm
          formRequest={handleAttendanceImportRequest}
          handleModalClose={handleModalClose}
          numLicense={numLicense}
          rutEmployee={rutEmployee}
        />
      </>
    );
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Row className="mt-3 mb-3">
            <Col>
              <h4 className="text-uppercase mb-0">Adjuntar Documentos</h4>
            </Col>
            <Col md={3}>
              <Button size="sm" variant="primary" className="mt-2 mt-1" block onClick={handleModalUploadFile}>
                Nuevo
              </Button>
            </Col>
          </Row>
          <FileTransactDataTable
            rutEmployee={rutEmployee}
            numLicense={numLicense}
            body={body}
            moreData={moreData}
            setMoreData={setMoreData}
            modalShow={modalShow}
            setModalShow={setModalShow}
            titleModal={titleModal}
            handleButtonClick={handleButtonClick}
          />
          <DefaultModal
            title={titleModal}
            body={body}
            show={modalDestroyShow}
            handleClose={handleModalClose}
            handleConfirm={() => modalAction(modalItem)}
            titleBtnClose="Cancelar"
            titleBtnSave="Confirmar"
          />
        </Col>
      </Row>
    </>
  );
};

export default FileTransact;
