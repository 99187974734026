/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import translateStatus from './formOptions';

const Status = ({ margin, status, onClick }) => {
  const renderBody = () => {
    switch (status) {
      case 'incomplete':
        return (
          <p className={`text-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translateStatus[status]}
          </p>
        );
      case 'corrected':
      case 'corrected_rejected':
      case 'incorrect':
        return (
          <p className={`text-warning ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translateStatus[status]}
          </p>
        );
      case 'ok':
      case 'corrected_approved':
      case 'justified':
        return (
          <p className={`text-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translateStatus[status]}
          </p>
        );
      default:
        return (
          <p className={`text-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            -
          </p>
        );
    }
  };
  return renderBody();
};

Status.propTypes = {
  margin: PropTypes.string,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

Status.defaultProps = {
  margin: 'my-0',
  onClick: () => null
};

export default Status;
