import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col, Tab, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../config/abilityContext';
import { LinkBtn, Icon } from '../../components';
import { showSurveyRequest } from '../../requests/surveys';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import SurveyPreview from './SurveyPreview';
import basicSurvey from './survey';
import { useSetTab } from '../../services/hooks';
import EmployeeSurveyDataTable from './EmployeeSurveyDataTable';
import SurveyResults from './SurveyResults';
import columns from './employeeSurveyColumns';

const SurveyShow = ({ match, location }) => {
  const ability = useAbility(AbilityContext);
  const { id } = match.params;
  const [onRequest, setOnRequest] = useState(true);
  const [survey, setSurvey] = useState(basicSurvey);
  const [key, setKey] = useSetTab('survey', location);
  const dispatch = useDispatch();

  const successCallback = response => {
    setSurvey(camelCaseEmptyStringRecursive(response.data));
  };

  const handleShowRequest = () => {
    setOnRequest(true);
    showSurveyRequest(id, {
      dispatch,
      successCallback,
      callback: () => setOnRequest(false)
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <div className={`${onRequest && 'bg-opacity'}`}>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="m-top mb-3">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" to="/surveys">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col md={6} xl={8}>
          <h2 className="text-uppercase">{survey.name}</h2>
        </Col>
        <Col className="ml-auto" md={4} xl={3}>
          <LinkBtn variant="warning" block to={`/surveys/${survey.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
      </Row>
      <Tab.Container id="surveys-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="survey">
              Encuesta
            </Nav.Link>
          </Nav.Item>
          {ability.can('index', 'EmployeeSurvey') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="participants">
                Participantes
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="results">
              Resultados
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="survey">
            <SurveyPreview survey={survey} employeeSurvey={{ employeeQuestionChoicesAttributes: [] }} />
          </Tab.Pane>
          {ability.can('index', 'EmployeeSurvey') && (
            <Tab.Pane eventKey="participants">
              <EmployeeSurveyDataTable
                survey={survey}
                surveyId={id}
                rrhh
                columns={columns}
                customParams={{ survey_id: id }}
              />
            </Tab.Pane>
          )}
          <Tab.Pane eventKey="results">
            <SurveyResults survey={survey} setOnRequest={setOnRequest} onRequest={onRequest} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default SurveyShow;
