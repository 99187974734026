import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal } from '../../components';
import { indexSettlementGroupsRequest, deleteSettlementGroupRequest } from '../../requests/settlementGroups';
import columns from './Columns';

const SettlementGroupDataTable = () => {
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [settlementGroups, setSettlementGroups] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRequest = async params => {
    setOnRequest(true);
    indexSettlementGroupsRequest({
      dispatch,
      params,
      successCallback: response => setSettlementGroups(response.data.data),
      callback: () => setOnRequest(false)
    });
  };

  const handleDeleteRequest = settlementGroupId => {
    setOnRequest(true);
    deleteSettlementGroupRequest(settlementGroupId, {
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Grupo de finiquitos eliminado con éxito' }));
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleModalConfirm = () => handleDeleteRequest(modalItem.id);

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/settlement_groups/${item.id}`);
        break;
      case 'edit':
        history.push(`/settlement_groups/${item.id}/edit`);
        break;
      case 'destroy':
        setModalItem(item);
        setModalShow(true);
        setModalBody(`¿Estás seguro que deseas eliminar el grupo de finiquitos '${item.name}'?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <ComponentDataTable
        withEndDate
        withStartDate
        pointerOnHover
        preName="settlement_groups"
        resourceRequest={handleRequest}
        columns={columns(handleActions)}
        onRowClicked={item => handleActions(item, 'show')}
        data={settlementGroups}
        moreData={moreData}
        onRequest={onRequest}
      />
      <DefaultModal
        title="Eliminar Grupo de Finiquitos"
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default SettlementGroupDataTable;
