import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { Icon, LinkBtn, LongCard } from '../../components';
import { showPayrollGroupRequest } from '../../requests/payrollGroups';
import PayrollDataTable from '../Payroll/PayrollDataTable';

const PayrollGroupShow = ({ match }) => {
  const { id, payrollProcessId } = match.params;
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [payrollGroup, setPayrollGroup] = useState({});
  const dispatch = useDispatch();

  const successCallback = useCallback(response => setPayrollGroup(camelCaseRecursive(response.data)), []);

  const fetchPayrollGroup = () => {
    setOnRequest(true);
    showPayrollGroupRequest(payrollProcessId, id, {
      dispatch,
      successCallback,
      callback: () => setOnRequest(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchPayrollGroup, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute h-100" style={{ bottom: '35px', left: '130px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to={`/payroll_processes/${payrollProcessId}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={9} className="mt-3">
          <h2 className="text-uppercase mt-1">{payrollGroup.name}</h2>
        </Col>
        {Object.keys(payrollGroup).length > 0 && payrollGroup.status !== 'closed' && (
          <Col md={2} className="mt-3">
            <LinkBtn block variant="warning" to={`/payroll_processes/${payrollProcessId}/payroll_groups/${id}/edit`}>
              Editar
            </LinkBtn>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={3} sm={4} xs={12}>
          <LongCard
            variant="primary"
            label="Líquido a pagar"
            title={payrollGroup.liquidTotal}
            paddingContent="p-0"
            customSize="font-24"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <LongCard
            variant="danger"
            label="Sobregiros"
            title={payrollGroup.overdraftTotal}
            paddingContent="p-0"
            customSize="font-24"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <LongCard
            variant="info"
            label="Total haberes"
            title={payrollGroup.assetTotal}
            paddingContent="p-0"
            customSize="font-24"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <LongCard
            variant="info"
            label="Total descuentos"
            title={payrollGroup.discountTotal}
            paddingContent="p-0"
            customSize="font-24"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <LongCard
            variant="warning"
            label="Total impuestos"
            title={payrollGroup.taxTotal}
            paddingContent="p-0"
            customSize="font-24"
          />
        </Col>
      </Row>
      <PayrollDataTable
        payrolls={payrollGroup.payrollsAttributes}
        payrollProcessId={payrollProcessId}
        moreData={moreData}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default PayrollGroupShow;
