import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { LongCard } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { HRLoansRequest } from '../../requests/dashboards';
import LoanDataTable from '../Shared/Loan/LoanDataTable';
import columns from '../Shared/Loan/approveColumns';

const LoanIndex = ({ moreData, setMoreData }) => {
  const [activeCard, setActiveCard] = useState('all');
  const [cards, setCards] = useState({});
  const [customParams, setCustomParams] = useState({});
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [nameRangePicker, setNameRangePicker] = useState('');
  const dispatch = useDispatch();

  const fetchDashboardCards = () => {
    const request = async () => {
      HRLoansRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleClick = card => {
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setNameRangePicker('date_range');
        setActiveCard('pending');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(!moreData);
        break;
      case 'year':
        setCustomParams({ status: 'approved' });
        setNameRangePicker('grant_date');
        setActiveCard('year');
        setDefaultStartDate(moment().startOf('year'));
        setDefaultEndDate(moment().endOf('year'));
        break;
      case 'rejected':
        setCustomParams({ status: 'rejected' });
        setNameRangePicker('date_range');
        setActiveCard('rejected');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(!moreData);
        break;
      case 'month':
        setCustomParams({ status: 'approved' });
        setNameRangePicker('grant_date');
        setActiveCard('month');
        setDefaultStartDate(moment().startOf('month'));
        setDefaultEndDate(moment().endOf('month'));
        break;
      default:
        setCustomParams({});
        setNameRangePicker('date_range');
        setActiveCard('all');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(!moreData);
        break;
    }
  };

  useEffect(fetchDashboardCards, [moreData]);

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.all}
            label="Préstamos solicitados"
            active={activeCard === 'all'}
            onClick={() => handleClick('all')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pending}
            label="Préstamos por autorizar"
            active={activeCard === 'pending'}
            onClick={() => handleClick('pending')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.rejected}
            label="Rechazados"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.amountThisYear}
            label="Monto de préstamos aprobados en el año"
            active={activeCard === 'year'}
            onClick={() => handleClick('year')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.amountThisMonth}
            label="Monto de préstamos aprobados en el mes"
            active={activeCard === 'month'}
            onClick={() => handleClick('month')}
          />
        </Col>
      </Row>
      <LoanDataTable
        columns={columns}
        customParams={customParams}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        moreData={moreData}
        setMoreData={setMoreData}
        nameRangePicker={nameRangePicker}
        withImport
      />
    </>
  );
};

export default LoanIndex;
