import React, { useState } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';
import AbsencesForm from '../Absence/AbsencesForm';
import AbsencesIndex from '../Absence/AbsencesIndex';
import AttendancesIndex from '../Attendance/AttendancesIndex';
import AssistanceRecordIndex from './AssistanceRecordIndex';
import OvertimeReportIndex from '../Legalreports/OvertimeReportIndex';
import basicAbsence from '../Absence/absence';
import { Icon, SimpleCenteredModal } from '../../components';
import AttendanceImportForm from '../../components/Attendance/AttendanceImportForm';
import { importAttendanceRequest, importTemplateAttendanceRequest } from '../../requests/attendances';
import { createMassiveAbsenceRequest } from '../../requests/absences';
import { sendAlert } from '../../actions/utils';
import { useSetTab } from '../../services/hooks';
import { downloadFile } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';

const CompanyRecords = ({ location }) => {
  const [modalTitle, setModalTitle] = useState('Nueva Lugar de Prestación de Servicios');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [moreData, setMoreData] = useState(false);
  const [key, setKey] = useSetTab('absence', location);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleSuccess = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleCreateAbsences = absencesValue => {
    createMassiveAbsenceRequest({
      dispatch,
      params: snakeCaseKeys(absencesValue),
      formData: true,
      successCallback: () => handleSuccess('Ausencias creadas con éxito')
    });
  };

  const handleAttendanceImportRequest = attendance => {
    importAttendanceRequest({
      dispatch,
      params: snakeCaseKeys(attendance),
      formData: true,
      successCallback: () => handleSuccess('Ausencias creadas con éxito')
    });
  };

  const handleImportTemplateAttendanceRequest = () => {
    importTemplateAttendanceRequest({
      dispatch,
      successCallback: response => {
        downloadFile(response);
      },
      callback: () => setModalShow(false)
    });
  };

  const newAbsence = () => {
    setModalTitle('Nueva Ausencia');
    setModalShow(true);
    setModalBody(
      <AbsencesForm
        absences={basicAbsence}
        action="new"
        formRequest={handleCreateAbsences}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const newAttendance = () => {
    setModalTitle('Importar Asistencias');
    setModalShow(true);
    setModalBody(
      <>
        <Button className="ml-3" variant="warning" onClick={handleImportTemplateAttendanceRequest}>
          Descargar ejemplo
        </Button>
        <AttendanceImportForm
          formRequest={handleAttendanceImportRequest}
          handleModalClose={() => setModalShow(false)}
        />
      </>
    );
  };

  const handleClick = () => {
    switch (key) {
      case 'absence':
        return newAbsence();
      case 'attendances':
        return newAttendance();
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        return '';
    }
  };

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Informes y registros</h2>
        </Col>
        <Col md={3}>
          <Button variant="primary" block onClick={handleClick}>
            Nuevo
          </Button>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('index', 'Absence') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="absence">
                <Icon icon="location" />
                Ausencias
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('read', 'Attendance') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="attendances">
                <Icon icon="cube" />
                Registro de asistencia
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="assistance-record">
              <Icon icon="cube" />
              Informe de asistencia
            </Nav.Link>
          </Nav.Item>
          {ability.can('index', 'OvertimeReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="overtime-report">
                <Icon icon="location" />
                Reporte de Horas Extra
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="absence">
            <AbsencesIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="attendances">
            <AttendancesIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="assistance-record">
            <AssistanceRecordIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="overtime-report">
            <OvertimeReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        size="xl"
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default CompanyRecords;
