import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexAnnouncementRequest } from '../../../requests/announcements';
import { CardImage, SimpleCenteredModal, InfiniteScroll } from '../../../components';
import { groupBy } from '../../../services/utils';
import AnnouncementModalShow from '../../Announcement/AnnouncementModalShow';

const AnnouncementFeed = ({ moreData, currentEmployee }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [page, setPage] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const today = useMemo(() => moment().format('DD/MM/YYYY'), []);
  const dispatch = useDispatch();

  const handleRequest = () => {
    if (currentEmployee) {
      setOnRequest(true);
      debounceIndexAnnouncementRequest({
        dispatch,
        params: {
          display_start: page * 15,
          display_length: 15,
          announcement_types: 'post',
          status: 'visible',
          filter_employee: currentEmployee,
          sort_column: 'created_at',
          sort_direction: 'desc'
        },
        successCallback: response => {
          if (response.data.data.length + announcements.length === response.data.metadata.amount) {
            setHasMore(false);
          }
          setAnnouncements([...announcements, ...camelCaseRecursive(response.data.data)]);
          setPage(page + 1);
        },
        callback: () => setOnRequest(false)
      });
    }
  };

  const handleShow = item => {
    setModalShow(true);
    setModalItem(item);
  };

  const handleRefresh = () => {
    setHasMore(true);
    setPage(0);
    setAnnouncements([]);
    setRefresh(!refresh);
  };

  useEffect(handleRequest, [currentEmployee, refresh]);
  useEffect(handleRefresh, [moreData]);

  return (
    <>
      <InfiniteScroll dataLength={announcements.length} next={handleRequest} hasMore={hasMore} label="anuncios">
        {groupBy(announcements, 'startDate').map(dateArray => {
          const date = Object.keys(dateArray)[0];
          return (
            <div key={date}>
              <hr />
              <h4 className="mb-3 text-dark text-uppercase">{date === today ? `Hoy - ${date}` : date}</h4>
              <Row className={`${onRequest && 'bg-opacity'}`}>
                {dateArray[date].map(item => (
                  <Col key={item.id} xs={12} md={6}>
                    <CardImage item={item} image={item.fileInfo.fileUrl} nameKey="title" showInfo={handleShow} />
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
      </InfiniteScroll>
      <SimpleCenteredModal
        title={modalItem.translatedAnnouncementType}
        body={<AnnouncementModalShow announcement={modalItem} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AnnouncementFeed;