import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Button, Row, Col, Spinner, Card, Table } from 'react-bootstrap';

import { publicShowDocumentPreviewRequest } from '../../requests/documents';
import { parseUrlParams, handleWorkflowStatus } from '../../services/utils';
import { WorkflowRequestActionBtns } from '../../components';

class DocumentPreview extends Component {
  state = {
    document: {
      documentType: '',
      name: '',
      file: {
        fileUrl: '',
        filename: ''
      },
      workflowRequest: {
        status: '',
        employeeRequestReviewer: {}
      },
      pendingToApprove: ''
    },
    file: {
      fileUrl: '',
      filename: ''
    },
    participant: []
  };

  componentDidMount() {
    this.getDocument();
  }

  getDocument = () => {
    const {
      dispatch,
      location: { search },
      match: { params }
    } = this.props;
    const options = parseUrlParams(search);

    this.setState({ onRequest: true });

    publicShowDocumentPreviewRequest(params.id, {
      employeeId: options.employee,
      dispatch,
      successCallback: data => this.handleSuccessShow(data)
    });
  };

  handleSuccessShow = response => {
    const { data } = response;
    const document = camelCaseRecursive(data);
    const file = document.fileInfo;
    this.setState({
      participant: document.participatingEmployees.map(p => {
        return p.employeeReviewerIds.map(e => {
          return { ...e, status: p.translatedStatus };
        });
      })
    });
    this.setState({
      document,
      file,
      onRequest: false
    });
  };

  render() {
    const { onRequest, document, file, participant } = this.state;
    const { pendingToApprove } = document;

    return (
      <>
        {onRequest && (
          <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <Row className="mb-4 mt-100">
          <Col md={7} lg={8}>
            <h2 className="text-uppercase">{file?.cleanFilename}</h2>
            {handleWorkflowStatus(document?.workflowRequest?.status)}
          </Col>
          <WorkflowRequestActionBtns document={document} file={file} workflowRequest={document.workflowRequest} />
        </Row>
        <Row className="mb-4 mt-4">
          <Col>
            <div className="info-box mb-4">
              <p className="info">
                <span>Nombre completo:</span> {document?.employee?.fullName}
              </p>
              <p className="info">
                <span>Identificación:</span> {document?.employee?.nationalIdentification}
              </p>
              <p className="info">
                <span>Fecha de Creación:</span> {file?.createdAt}
              </p>
              {pendingToApprove && (
                <p className="info">
                  <span>Pendiente por:</span> {pendingToApprove}
                </p>
              )}
            </div>
          </Col>
          {document?.workflowRequest?.observation && (
            <Col md={6}>
              <div className="info-box mb-4">
                <p className="info">
                  <span>Observaciones:</span>
                  <br />
                  {document?.workflowRequest?.observation}
                </p>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={8}>
            <iframe src={file?.fileUrl} style={{ width: '100%', height: '800px' }} title={`${file?.filename}`}>
              <p>
                This browser does not support PDFs. Please download the PDF to view it:
                <Button variant="secondary" block href={file?.fileUrl} download>
                  Download
                </Button>
              </p>
            </iframe>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Body className="table-responsive">
                <h3 className="mb-4">Evidencias</h3>
                {participant.map(element => {
                  return (
                    <div key={element.id}>
                      <Table className="table table-sm" style={{ borderCollapse: 'inherit' }}>
                        <tbody>
                          <tr>
                            <td>{element[0].status} por:</td>
                            <td>{element[0].label}</td>
                          </tr>
                          <tr>
                            <td>{element[0].identificationType}:</td>
                            <td>{element[0].nationalIdentification}</td>
                          </tr>
                          <tr>
                            <td>Fecha/Hora:</td>
                            <td>{element[0].signatureDate}</td>
                          </tr>
                          <tr>
                            <td>Dirección IP:</td>
                            <td>{element[0].signatureIp}</td>
                          </tr>
                          <tr>
                            <td>Token:</td>
                            <td>{element[0].jti}</td>
                          </tr>
                          {element[0].signatureKey && (
                            <tr>
                              <td>Firma:</td>
                              <td>{element[0].signatureKey}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <br />
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(connect()(DocumentPreview));
