import React, { useState } from 'react';
import { SimpleCenteredModal } from '../../components';
import ConceptsDataTable from './ConceptsDataTable';
import columns from './columns';

const ConceptsIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Lugar de Prestación de Servicios');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});

  const showBranchOffice = branchOfficeRow => {
    setModalShow(true);
    setModalBody();

    setModalTitle(branchOfficeRow.name);
  };

  return (
    <>
      <ConceptsDataTable
        columns={columns}
        moreData={moreData}
        setMoreData={setMoreData}
        showBranchOffice={showBranchOffice}
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ConceptsIndex;
