import React from 'react';
import { Table } from 'react-bootstrap';
import { Field } from 'formik';

import { ButtonTooltip, DatatableEmployeeName, FormikInput, FormikNumber, Icon } from '../../components';

const ScheduledGroupTable = ({ setFieldValue, show = false, ...props }) => {
  const { balances, employees, removeAnEmployee, scheduledBalances, scheduledType } = props;
  const isInstallments = scheduledType === 'installments';

  const dataNotFound = () => {
    return (
      <tbody>
        <tr className="d-flex justify-content-center text-uppercase">
          <td style={{ color: '#959595' }}>No se encontraron datos.</td>
        </tr>
      </tbody>
    );
  };

  const handleFieldValues = (amount, balanceId, contractId, employeeId, fieldValue) => {
    setFieldValue(`${fieldValue}[amount]`, amount);
    setFieldValue(`${fieldValue}[balanceId]`, balanceId);
    setFieldValue(`${fieldValue}[contractId]`, contractId);
    setFieldValue(`${fieldValue}[employeeId]`, employeeId);
  };

  const handleHeadItems = () => {
    const settings = isInstallments ? { totalCol: 3, size: 277 } : { totalCol: 5, size: 140 };
    const minWidth = balances.length < settings.totalCol ? (settings.totalCol - balances.length) * settings.size : 0;
    return (
      <tr>
        {!show && <th style={{ maxWidth: '50px' }} />}
        <th className="align-middle custom-employee-width">Nombre del Trabajador</th>
        {balances.map((balance, index) => (
          <React.Fragment key={`sb-head-${index.toString()}`}>
            <th className="text-center align-middle custom-balance-width">{balance.name}</th>
            {isInstallments && (
              <th className="text-center align-middle custom-installments-width special-division">Cuotas</th>
            )}
          </React.Fragment>
        ))}
        <th style={{ minWidth: `${minWidth}px` }} />
      </tr>
    );
  };

  const handleBodyItems = employee => {
    return balances.map((balance, balanceIndex) => {
      const addon = { pesos: '$', uf: 'UF', utm: 'UTM' };
      const leftAddon = addon[balance.unitOfAccount] || '%';
      const contractId = employee.simpleContractInfo.id;
      const index = scheduledBalances.findIndex(sb => sb.balanceId === balance.id && sb.contractId === contractId);
      const scheduledBalanceIndex = index !== -1 ? index : scheduledBalances.length;
      const fieldValue = `scheduledGroup[scheduledBalancesAttributes][${scheduledBalanceIndex}]`;
      return (
        <React.Fragment key={`sb-body-${balanceIndex.toString()}`}>
          <td className="text-center align-middle">
            {show ? (
              scheduledBalances[index].parsedAmount
            ) : (
              <Field name={`${fieldValue}[parsedAmount]`}>
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    leftAddon={leftAddon}
                    decimalScale={leftAddon === '$' ? 0 : 2}
                    onValueChange={e => handleFieldValues(e?.value, balance.id, contractId, employee.id, fieldValue)}
                    fieldName={field.name}
                    margin="mb-0"
                  />
                )}
              </Field>
            )}
          </td>
          {isInstallments && (
            <td className="text-center align-middle special-division">
              {show ? (
                scheduledBalances[index].installments
              ) : (
                <Field name={`${fieldValue}[installments]`}>
                  {({ field }) => <FormikInput {...field} inputType="number" margin="mb-0" />}
                </Field>
              )}
            </td>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Table responsive>
      {employees.length === 0 && balances.length === 0 ? (
        dataNotFound()
      ) : (
        <>
          <thead className="text-uppercase">{handleHeadItems()}</thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr key={`scheduled-balance-${index.toString()}`}>
                {!show && (
                  <td className="text-center align-middle" style={{ maxWidth: '50px' }}>
                    <ButtonTooltip
                      onClick={() => removeAnEmployee(employee)}
                      variant="circle-danger"
                      className="btn-circle"
                      size="sm"
                      text="Eliminar"
                    >
                      <Icon icon="trash" />
                    </ButtonTooltip>
                  </td>
                )}
                <td className="employee-name">
                  <DatatableEmployeeName item={employee} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />
                </td>
                {handleBodyItems(employee)}
                <td />
              </tr>
            ))}
          </tbody>
        </>
      )}
    </Table>
  );
};

export default ScheduledGroupTable;
