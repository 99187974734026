import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexRiskPreventionDocumentRequest } from '../../../requests/riskPreventionDocuments';
import { BasicCard } from '../../../components';

const RiskPreventionProfileIndex = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [riskPreventionDocument, setRiskPreventionDocument] = useState([]);
  const dispatch = useDispatch();

  const handleIndexRequest = (format, responseType = 'json') => {
    setOnRequest(true);
    debounceIndexRiskPreventionDocumentRequest({
      dispatch,
      params: { is_public: true },
      format,
      responseType,
      successCallback: response => setRiskPreventionDocument(camelCaseRecursive(response.data.data)),
      callback: () => setOnRequest(false)
    });
  };

  useEffect(handleIndexRequest, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mb-3 mt-3">Documentación Legal</h2>
        </Col>
      </Row>
      <Row className={`${onRequest && 'bg-opacity'}`}>
        {riskPreventionDocument.map(document => (
          <Col key={document.id} xs={12} md={6}>
            <BasicCard
              size="100%"
              classNameCard="z-index-auto"
              bodyMarginBottom="-10px"
              colorCard="white"
              divBody
              text={
                <Row>
                  <Col md={12}>
                    <h3 className="mb-4 futura text-uppercase font-size-title">{document.translatedDocumentType}</h3>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="outline-info"
                      disabled={Object.keys(document.documentInfo).length === 0}
                      className="mb-2"
                      onClick={() => window.open(document.documentInfo?.fileUrl, '_blank', 'noopener', 'noreferrer')}
                    >
                      Ver Archivo
                    </Button>
                  </Col>
                </Row>
              }
            />
          </Col>
        ))}
      </Row>
      {!onRequest && riskPreventionDocument.length === 0 && <p className="msg-empty">No se encontraron documentos.</p>}
    </>
  );
};

export default RiskPreventionProfileIndex;
