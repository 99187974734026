import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Tab } from 'react-bootstrap';

import { useSetTab } from '../../../services/hooks';
import AbsenceDataTable from '../../Absence/AbsenceDataTable';
import PermissionIndex from '../Permission/PermissionIndex';
import VacationIndex from '../Vacation/VacationIndex';
import AttendanceRegisterIndex from './AttendanceRegisterIndex';
import absenceColumns from './AbsenceColumns';

const AttendanceProfileIndex = ({ location }) => {
  const [key, setKey] = useSetTab('attendance', location);
  const { currentEmployee } = useSelector(state => state.auth);

  return (
    <Row className="mt-4 mb-4">
      <Col md={12}>
        <h2 className="mt-3 mb-3">Asistencia</h2>
      </Col>
      <Col>
        <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
          <Tab.Content>
            <Tab.Pane eventKey="attendance">
              {currentEmployee && <AttendanceRegisterIndex currentEmployee={currentEmployee} />}
            </Tab.Pane>
            <Tab.Pane eventKey="vacations">
              {currentEmployee && <VacationIndex currentEmployee={currentEmployee} />}
            </Tab.Pane>
            <Tab.Pane eventKey="permissions">
              {currentEmployee && <PermissionIndex currentEmployee={currentEmployee} />}
            </Tab.Pane>
            <Tab.Pane eventKey="absences">
              {currentEmployee && (
                <AbsenceDataTable
                  columns={absenceColumns}
                  customParams={{
                    filterByEmployee: currentEmployee,
                    filterType: [
                      'sickness_or_accident',
                      'pregnacy_pathologies',
                      'preventive_medicine',
                      'pre_postnatal',
                      'underage_sickness',
                      'work_accident',
                      'profesional_sickness'
                    ]
                  }}
                />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default AttendanceProfileIndex;
