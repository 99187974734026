import ApiService from '../services/apiService';

export const createMassivePayrollBalanceRequest = (
  payrollProcessId,
  payrollGroupId,
  { dispatch, params, formData, callback, successCallback }
) => {
  ApiService.request(
    'post',
    `/payroll_processes/${payrollProcessId}/payroll_groups/${payrollGroupId}/payroll_balances/massive_create`,
    {
      dispatch,
      params,
      formData,
      callback,
      successCallback
    }
  );
};

export const updateMassivePayrollBalanceRequest = (
  payrollProcessId,
  payrollGroupId,
  { dispatch, params, formData, callback, successCallback }
) => {
  ApiService.request(
    'put',
    `/payroll_processes/${payrollProcessId}/payroll_groups/${payrollGroupId}/payroll_balances/massive_update`,
    {
      dispatch,
      params,
      formData,
      callback,
      successCallback
    }
  );
};
