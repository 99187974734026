import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { showSelectionProcessPublicRequest } from '../../requests/selectionProcesses';
import { createPublicCandidateRequest } from '../../requests/candidates';
import { showEmployeeRequest } from '../../requests/employees';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import './style.scss';
import { sendAlert } from '../../actions/utils';
import SelectionProcessCandidateFormPublic from './SelectionProcessCandidateFormPublic';
import SelectionProcessSuccessPublic from './SelectionProcessSuccessPublic';
import SelectionProcessInfoPublic from './SelectionProcessInfoPublic';
import basicEmployee from '../Employee/employee';

const basicCandidate = {
  address: '',
  addressApt: '',
  addressNumber: '',
  birthdate: '',
  candidateType: '',
  careerIds: [],
  careers: [],
  civilState: '',
  communeId: '',
  countryId: '',
  countryName: '',
  educationLevel: '',
  email: '',
  firstLastName: '',
  genderId: '',
  identificationType: '',
  languageIds: [],
  name: '',
  nationalIdentification: '',
  otherCareer: '',
  phone: '',
  phoneCountryCode: '+56',
  regionId: '',
  resume: '',
  secondLastName: ''
};

const SelectionProcessShowPublic = ({ match }) => {
  const selectionProcessToken = match.params.token;
  const history = useHistory();
  const [selectionProcess, setSelectionProcess] = useState({ company: {} });
  const [employee, setEmployee] = useState(basicEmployee);
  const { signedIn, currentEmployee } = useSelector(state => state.auth);
  const { company } = selectionProcess;
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const fetchSelectionProcess = () => {
    showSelectionProcessPublicRequest({
      params: { token: selectionProcessToken },
      dispatch,
      successCallback: response => {
        setSelectionProcess(camelCaseEmptyStringRecursive(response.data));
      }
    });
  };

  const handleSuccessCreate = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setStep(2);
  };

  const handleCreate = candidate => {
    const { resume } = candidate;
    const params = snakeCaseKeys(candidate);
    createPublicCandidateRequest(selectionProcess.id, {
      dispatch,
      params: { candidate: { ...params, resume } },
      successCallback: () => handleSuccessCreate('Postulación creada con éxito')
    });
  };

  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <SelectionProcessCandidateFormPublic
            candidate={basicCandidate}
            selectionProcess={selectionProcess}
            formRequest={handleCreate}
            employee={employee}
          />
        );
      case 2:
        return <SelectionProcessSuccessPublic company={company} />;
      default:
        return <SelectionProcessInfoPublic company={company} selectionProcess={selectionProcess} setStep={setStep} />;
    }
  };

  const fetchEmployee = () => {
    if (currentEmployee && selectionProcess.processType !== 'external') {
      showEmployeeRequest(currentEmployee, {
        dispatch,
        successCallback: response => setEmployee(camelCaseEmptyStringRecursive(response.data))
      });
    }
  };

  const redirectProcessType = () => {
    if (selectionProcess.processType === 'internal' && !signedIn) {
      dispatch(sendAlert({ kind: 'error', message: 'Necesitas iniciar sesión para acceder a este proceso.' }));
      history.push('/login', { from: history.location });
    }
  };

  useEffect(fetchSelectionProcess, []);
  useEffect(fetchEmployee, [currentEmployee, selectionProcess]);
  useEffect(redirectProcessType, [selectionProcess, signedIn]);
  useEffect(() => window.scrollTo(0, 0), []);

  return <Container className="mt-100">{renderBody()}</Container>;
};

export default SelectionProcessShowPublic;
