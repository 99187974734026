import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexJobTitleRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/job_titles', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showJobTitleRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/job_titles/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createJobTitleRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/job_titles', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });

export const updateJobTitleRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/job_titles/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteJobTitleRequest = (id, { dispatch, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', `/job_titles/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback
  });

export const massiveDestroyJobTitleRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/job_titles/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportJobTitleRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/job_titles/export.xlsx', {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });

export const preImportJobTitleRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/job_titles/pre_import', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const importJobTitleRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/job_titles/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const importTemplateJobTitleRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/job_titles/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });

export const activeJobTitlesRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/job_titles/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateJobTitlesRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/job_titles/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexJobTitleRequest = AwesomeDebouncePromise(indexJobTitleRequest, 300);
