import React from 'react';
import memoize from 'memoize-one';
import { ActiveDot, BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'CODIGO',
    selector: 'code',
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CUENTA',
    selector: 'account',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TIPO DE CUENTA',
    selector: 'type_account',
    cell: item => item.translated_type_account,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '0.5',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="Bookkeeper" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
