import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import CertificationDataTable from './CertificationDataTable';
import { SimpleCenteredModal } from '../../components';
import { updateCertificationRequest } from '../../requests/certifications';
import { sendAlert } from '../../actions/utils';
import CertificationForm from './CertificationForm';

const CertificationIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nueva Certificación');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = certificationValue => {
    const {
      certification: { id }
    } = certificationValue;
    updateCertificationRequest(id, {
      dispatch,
      params: snakeCaseKeys(certificationValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Certificación actualizada con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editCertification = certificationRow => {
    setModalShow(true);
    setModalBody(
      <CertificationForm
        certification={camelCaseRecursive(certificationRow)}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => setModalShow(false)}
      />
    );
    setModalTitle('Editar certificación');
  };

  return (
    <>
      <CertificationDataTable editCertification={editCertification} moreData={moreData} setMoreData={setMoreData} />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default CertificationIndex;
