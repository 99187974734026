import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';

import { requestSignIn } from '../../actions/auth';
import { FormikInput, MainLogo } from '../../components';
import { updateAbility } from '../../config/ability';
import { AbilityContext } from '../../config/abilityContext';
import '../../services/yupCustomMethods';

const Login = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    signedIn,
    location: { locationState },
    history,
    user
  } = props;
  const ability = useAbility(AbilityContext);
  const { userCompanies, currentCompany } = useSelector(state => state.utils);

  const redirect = () => {
    if (signedIn) {
      updateAbility(ability);
      if (locationState?.from?.pathname) {
        const { from } = locationState;
        history.replace(from);
      } else if (user.role === 'admin' && !Object.keys(user.employeeCard).length) {
        history.replace('admin/companies');
      } else if (userCompanies > 1 || user.role === 'dt') {
        history.replace('/profile/companies');
      } else {
        history.replace('/profile/dashboard');
      }
    }
  };

  useEffect(redirect, [signedIn, user, currentCompany]);

  return (
    <Row className="justify-content-center align-items-center h-100 img-login">
      <Col sm={6} md={4}>
        <MainLogo />
        <Form className="d-flex flex-column justify-content-end mt-1">
          <Field name="login">
            {({ field }) => (
              <FormikInput
                {...field}
                size="lg"
                placeholder="Email personal o RUT"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                size="lg"
                inputType="password"
                placeholder="Contraseña"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Button variant={submitVariant} className="mr-1 btn-login" size="lg" type="submit" onClick={onHide}>
            INICIAR SESIÓN
          </Button>
          <Link className="mt-2 lnk-login" to="/recover_password">
            Olvidé mi contraseña
          </Link>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn, user, currentEmployee } = state.auth;
  return {
    ongoingRequest,
    signedIn,
    user,
    currentEmployee
  };
};

const initialValues = {
  login: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  login: Yup.string()
    .required('Este campo es obligatorio')
    .min(4, 'Un minimo de 4 caracteres es requerido')
    .emailValidation('Debes ingresar un email válido'),
  password: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  let { login } = values;
  if (login.indexOf('@') === -1) {
    login = login
      .replace(/\s/g, '')
      .replace(/\./g, '')
      .replace(/-/g, '');
  }
  dispatch(
    requestSignIn({
      user: {
        login,
        password: values.password
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
