import React from 'react';
import DirectoryNodeIndex from '../../../screens/Shared/DirectoryNode/DirectoryNodeIndex';

const DirectoryInfo = ({ employeeId }) => (
  <>
    <br />
    <DirectoryNodeIndex employeeId={employeeId} rrhh />
  </>
);

export default DirectoryInfo;
