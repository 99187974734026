import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    cell: item => item.job_managements.map(jm => jm.label).join(', ') || 'Todas',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÚLTIMA PUBLICACIÓN',
    selector: 'last_message',
    cell: item => item.last_message || '-',
    sortable: true,
    grow: '1'
  },
  // {
  //   name: 'PARTICIPANTES',
  //   selector: 'employee_count',
  //   sortable: true,
  //   center: true,
  //   grow: '1'
  // },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="Group" withShow={false} clickHandler={clickHandler} />,
    ignoreRowClick: true,
    grow: '1.5',
    minWidth: '185px',
    right: true
  }
]);

export default columns;
