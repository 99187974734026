import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../components';
import useConceptHook from './useConceptHook';
import { useAuthorization } from '../../services/hooks';
import massiveActions from './massiveActions';

const ConceptsDataTable = ({ columns, moreData, setMoreData }) => {
  const [amount, setAmount] = useState(0);
  const [paramsModalAction, setParamsModalAction] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [concepts, setConcepts] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const history = useHistory();

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setConfirmModalShow(false);
    setOnRequest(false);
  };

  const {
    handleRemoveConcept,
    handleIndexConcept,
    handleMassiveActivateConcepts,
    handleMassiveDeactivateConcepts,
    handleMassiveDeleteConcepts
  } = useConceptHook();

  const handleConfirm = (action, item) => {
    switch (action) {
      case 'destroy':
        setParamsModalAction([item.id, handleModalClose, setMoreData]);
        setModalAction(() => handleRemoveConcept);

        break;
      case 'massive_active':
        setModalAction(() => handleMassiveActivateConcepts);
        setParamsModalAction([selectedRows, setOnRequest, handleModalClose, setMoreData, setClearSelectedRows]);
        break;
      case 'massive_deactivate':
        setModalAction(() => handleMassiveDeactivateConcepts);
        setParamsModalAction([selectedRows, setOnRequest, handleModalClose, setMoreData, setClearSelectedRows]);
        break;
      case 'massive_destroy':
        setModalAction(() => handleMassiveDeleteConcepts);
        setParamsModalAction([selectedRows, setOnRequest, handleModalClose, setMoreData, setClearSelectedRows]);
        break;
      default:
        break;
    }
  };

  const permissions = {
    read: useAuthorization('read_concept', 'Balance'),
    update: useAuthorization('update_concept', 'Balance'),
    destroy: useAuthorization('destroy_concept', 'Balance')
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/remunerations_settings/${item.id}/show`);
        break;
      case 'edit':
        if (permissions.update) {
          history.push(`/remunerations_settings/${item.id}/edit`);
        }
        break;
      case 'destroy':
        if (permissions.destroy) {
          setConfirmModalShow(true);
          setModalTitle(`Eliminar este concepto`);
          setModalBody(`¿Estás seguro que deseas eliminar este concepto?`);
          setModalAction(() => handleConfirm('destroy', item));
        }
        break;

      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'massive_destroy':
        setModalTitle('Eliminar Conceptos');
        setConfirmModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los conceptos seleccionados?');
        setModalAction(() => handleConfirm('massive_destroy'));
        break;
      case 'massive_active':
        setModalTitle('Activar Conceptos');
        setConfirmModalShow(true);
        setModalBody('¿Estás seguro que deseas activar los conceptos seleccionados?');
        setModalAction(() => handleConfirm('massive_active'));
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Conceptos');
        setConfirmModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar los conceptos seleccionados?');
        setModalAction(() => handleConfirm('massive_deactivate'));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, permissions)}
        data={concepts}
        totalRows={amount}
        selectableRows
        withMassActions
        massActions={massiveActions(handleMassAction, permissions, selectedRows.length === 0)}
        moreData={moreData}
        resourceRequest={params => handleIndexConcept(params, setConcepts, setAmount, setOnRequest)}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        preName="balance"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={confirmModalShow}
        handleClose={() => setConfirmModalShow(false)}
        handleConfirm={() => modalAction(...paramsModalAction)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />

      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};
export default ConceptsDataTable;
