import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icon from '../../Icons/Icon';
import ButtonTooltip from '../Tooltips/ButtonTooltip';

const SubHeader = ({ setTextInput, textInput, selectedCount, handleMassAction, massAction }) => (
  <>
    {massAction && (
      <ButtonTooltip
        onClick={handleMassAction}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar Seleccionados"
        disabled={selectedCount === 0}
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    )}
    <Form.Group className="ml-auto w-25">
      <FormControl
        placeholder="Buscar"
        name="textInput"
        value={textInput}
        onChange={e => setTextInput(e.target.value)}
      />
    </Form.Group>
  </>
);

SubHeader.propTypes = {
  setTextInput: PropTypes.func.isRequired,
  textInput: PropTypes.string.isRequired,
  selectedCount: PropTypes.number,
  handleMassAction: PropTypes.func,
  massAction: PropTypes.bool
};

SubHeader.defaultProps = {
  selectedCount: 0,
  handleMassAction: () => false,
  massAction: true
};

export default SubHeader;
