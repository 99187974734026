import React, { useMemo, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';

import { useSetTab } from '../../services/hooks';
import { sendAlert } from '../../actions/utils';
import { FormikSelect, SimpleCenteredModal } from '../../components';
import { createPayrollProcessRequest } from '../../requests/payrollProcesses';
import PayrollProcessDataTable from './PayrollProcessDataTable';
import { AbilityContext } from '../../config/abilityContext';
import PayrollProcessForm from './PayrollProcessForm';

const PayrollProcessIndex = ({ location }) => {
  const ability = useAbility(AbilityContext);
  const [year, setYear] = useSetTab(moment().year(), location, 'year', e => parseInt(e, 10));
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const years = useMemo(() => Array.from(Array(100), (e, i) => ({ label: i + 2020, value: i + 2020 })), []);
  const dispatch = useDispatch();

  const handleCreate = (values, setSubmitting) => {
    createPayrollProcessRequest({
      dispatch,
      params: snakeCaseKeys(values),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Proceso creado con éxito' }));
        setMoreData(!moreData);
        setModalShow(false);
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-1 mb-1">Historial de Liquidaciones</h2>
        </Col>
        <Col md={3}>
          <FormikSelect
            options={years}
            label=""
            defaultValue={year}
            value={year}
            onChange={data => {
              setYear(data ? data.value : '');
              setMoreData(!moreData);
            }}
            placeholder="Selecciona un año"
          />
        </Col>
        {ability.can('create', 'PayrollProcess') && (
          <Col md={3}>
            <Button variant="primary" block onClick={() => setModalShow(true)}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <PayrollProcessDataTable moreData={moreData} customParams={{ year }} />
      <SimpleCenteredModal
        title="Nuevo Proceso"
        body={<PayrollProcessForm formRequest={handleCreate} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default PayrollProcessIndex;
