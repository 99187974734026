import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { authTypes, requestSignIn } from '../actions/auth';
import { utilsTypes } from '../actions/utils';
import { camelCaseEmptyStringRecursive } from '../services/utils';
import API from '../services/api';
import runDefaultSaga, { updateJWT } from './default';

// SIGN IN
const signInRequest = params => API.post('/login', params);

function* signInSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers.get('Authorization').split('Bearer ')[1];
    yield updateJWT(authorization);
    const formattedResult = camelCaseEmptyStringRecursive(result);
    yield put({
      type: authTypes.SIGN_IN_SUCCESS,
      result: formattedResult,
      response
    });
    yield put({ type: authTypes.VALIDATE_TOKEN_REQUEST });
  }
}
function* signInFailureCallback() {
  yield put({
    type: authTypes.SIGN_IN_FAILURE
  });
}
export function* signIn(action) {
  yield runDefaultSaga({ request: signInRequest, params: action.params }, signInSuccessCallback, signInFailureCallback);
}


const signInDtRequest = params => API.post('/auth', params);

function* signInDtSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers.get('Authorization').split('Bearer ')[1];
    yield updateJWT(authorization);
    const formattedResult = camelCaseEmptyStringRecursive(result);
    yield put({
      type: authTypes.SIGN_IN_SUCCESS,
      result: formattedResult,
      response
    });
    yield put({ type: authTypes.VALIDATE_TOKEN_REQUEST });
  }
}
function* signInDtFailureCallback() {
  yield put({
    type: authTypes.SIGN_IN_FAILURE
  });
}

export function* signInDt(action) {
  yield runDefaultSaga({ request: signInDtRequest, params: action.params }, signInDtSuccessCallback, signInDtFailureCallback);
}


// CREATE ACCOUNT
const signUpRequest = params => API.post('/signup', params);

function* signUpSuccessCallback(result, response, params) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: authTypes.SIGN_UP_SUCCESS, result, response });
    const user = params;
    delete user.password_confirmation;
    yield put(requestSignIn(user));
  }
}
function* signUpFailureCallback() {
  yield put({
    type: authTypes.SIGN_UP_FAILURE
  });
}
export function* signUp(action) {
  yield runDefaultSaga({ request: signUpRequest, params: action.params }, signUpSuccessCallback, signUpFailureCallback);
}

// SIGN OUT
const signOutRequest = () => API.delete('/logout');

function* signOutSuccessCallback(result) {
  if (result.success) {
    yield put({ type: authTypes.SIGN_OUT_SUCCESS });
    yield localStorage.removeItem('jwt');
    yield localStorage.removeItem('currentCompany');
    yield put(push('/login'));
  } else {
    throw new Error(result);
  }
}
function* signOutFailureCallback() {
  yield put({ type: authTypes.SIGN_OUT_FAILURE });
}
export function* signOut() {
  yield runDefaultSaga({ request: signOutRequest }, signOutSuccessCallback, signOutFailureCallback);
}

// VALIDATE TOKENS
const validateTokenRequest = () => API.get('/me');
function* validateTokensSuccessCallback(result, response) {
  if (result.logged_in) {
    localStorage.setItem('currentRoles', result.current_roles);
    yield put({ type: utilsTypes.UPDATE_ROLES, params: { updateRoles: false } });
    yield put({
      type: authTypes.VALIDATE_TOKEN_SUCCESS,
      result,
      response,
      user: camelCaseEmptyStringRecursive(result.user_info),
      roles: result.current_roles,
      enrollmentSent: {
        ...result.enrollment_sent,
        enrolled: result.user_info?.employee_card?.enrolled
      }
    });
    const params = {
      company: camelCaseEmptyStringRecursive(result.current_company),
      employee: result.current_employee
    };
    yield put({ type: authTypes.SELECT_COMPANY, params });
  } else {
    yield put({ type: authTypes.CLEAR_AUTH_INFO });
    yield put(push('/login'));
  }
}
function* validateTokensFailureCallback() {
  yield put({ type: authTypes.CLEAR_AUTH_INFO });
  yield put(push('/login'));
}
function* validateToken() {
  yield runDefaultSaga({ request: validateTokenRequest }, validateTokensSuccessCallback, validateTokensFailureCallback);
}

// COMPANY
const currentCompanyRequest = () => API.get(`/companies/my_company`);

function* currentCompanyRequestSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: authTypes.CURRENT_COMPANY_SUCCESS, result });
  }
}
function* currentCompanyRequestFailureCallback() {
  yield put({ type: authTypes.CURRENT_COMPANY_FAILURE });
}
export function* currentCompany(action) {
  yield runDefaultSaga(
    { request: currentCompanyRequest, params: action.params },
    currentCompanyRequestSuccessCallback,
    currentCompanyRequestFailureCallback
  );
}

// DEFINE authSagas
export default function* authSagas() {
  yield takeEvery(authTypes.SIGN_IN_REQUEST, signIn);
  yield takeEvery(authTypes.SIGN_IN_REQUEST_DT, signInDt);
  yield takeEvery(authTypes.SIGN_UP_REQUEST, signUp);
  yield takeEvery(authTypes.SIGN_OUT_REQUEST, signOut);
  yield takeEvery(authTypes.VALIDATE_TOKEN_REQUEST, validateToken);
  yield takeEvery(authTypes.CURRENT_COMPANY_REQUEST, currentCompany);
}
