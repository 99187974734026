import ApiService from '../services/apiService';

const indexPensionFundsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/pension_funds', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export default indexPensionFundsRequest;
