import React from 'react';
import WorkflowRequestDataTable from '../../../screens/WorkflowRequest/WorkflowRequestDataTable';
import columns from '../../../screens/WorkflowRequest/Columns';

const WorkflowInfo = ({ employee }) => (
  <>
    <br />
    <WorkflowRequestDataTable customFilter={{ document_employee: employee?.id }} columns={columns} />
  </>
);

export default WorkflowInfo;
