import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { InputSelect } from '../../components';
import { delayMethod } from '../../services/utils';
import { indexSelectEmployeesRequest } from '../../requests/employees';

const ReportSelectEmployee = ({ handleModalClose, formRequest, onRequest, employeeIds }) => {
  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState('');

  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, filter_name: inputValue, include_ids: employeeIds, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <>
      <Modal.Body>
        <InputSelect
          label="Trabajador"
          onChange={data => setEmployeeId(data ? data.value : '')}
          placeholder="Seleccionar Trabajador"
          request={fetchEmployees}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={!employeeId || onRequest}
          onClick={() => formRequest({ employeeId })}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ReportSelectEmployee;
