import { takeEvery, put } from 'redux-saga/effects';
import { utilsTypes } from '../actions/utils';
import API from '../services/api';
import runDefaultSaga, { setUserHeaders } from './default';

// AdvanceSettings
const getAdvanceSettingsRequest = () => API.get('/advance_settings');

function* getAdvanceSettingsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.SET_ADVANCE_SETTINGS_SUCCESS, result, response });
  }
}

function* getAdvanceSettingsFailureCallback() {
  yield put({
    type: utilsTypes.SET_ADVANCE_SETTINGS_FAILURE
  });
}

function* setAdvanceSettings() {
  yield runDefaultSaga(
    { request: getAdvanceSettingsRequest },
    getAdvanceSettingsSuccessCallback,
    getAdvanceSettingsFailureCallback
  );
}

// Settings
const getSettingsRequest = () => API.get('/settings');

function* getSettingsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_SETTINGS_SUCCESS, result, response });
  }
}

function* getSettingsFailureCallback() {
  yield put({
    type: utilsTypes.GET_SETTINGS_FAILURE
  });
}

function* getSettings() {
  yield runDefaultSaga({ request: getSettingsRequest }, getSettingsSuccessCallback, getSettingsFailureCallback);
}

// Slides
const getSlidesRequest = () => API.get('/pages');

function* getSlidesSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_SLIDES_SUCCESS, result, response });
  }
}

function* getSlidesFailureCallback() {
  yield put({
    type: utilsTypes.GET_SLIDES_FAILURE
  });
}

function* getSlides() {
  yield runDefaultSaga({ request: getSlidesRequest }, getSlidesSuccessCallback, getSlidesFailureCallback);
}

// Page
const getPageRequest = url => API.get(`/pages/${url}`);

function* getPageSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_PAGE_SUCCESS, result });
  }
}

function* getPageFailureCallback() {
  yield put({
    type: utilsTypes.GET_PAGE_FAILURE
  });
}

function* getPage(action) {
  yield runDefaultSaga({ request: getPageRequest, params: action.url }, getPageSuccessCallback, getPageFailureCallback);
}

// Headers
function* setHeaders(headers) {
  yield setUserHeaders(headers);
}

// DEFINE utilsSagas
export default function* utilsSagas() {
  yield takeEvery(utilsTypes.GET_SETTINGS_REQUEST, getSettings);
  yield takeEvery(utilsTypes.GET_SLIDES_REQUEST, getSlides);
  yield takeEvery(utilsTypes.GET_PAGE_REQUEST, getPage);
  yield takeEvery(utilsTypes.SET_HEADERS, setHeaders);
  yield takeEvery(utilsTypes.SET_ADVANCE_SETTINGS_REQUEST, setAdvanceSettings);
}
