import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';

import { sendAlert } from '../../actions/utils';
import { debounceIndexCommunesRequest, indexCommunesRequest } from '../../requests/communes';
import { debounceIndexRegionsRequest } from '../../requests/regions';
import { InputSelect } from '../Utils/Select';

const RegionCommune = ({
  allDisabled,
  communeAbbr,
  modelKey,
  regionAbbr,
  nameFieldCommune = 'communeId',
  nameFieldRegion = 'regionId'
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [communeDisabled, setCommuneDisabled] = useState(true);
  const [communes, setCommunes] = useState([]);
  const [regions, setRegions] = useState([]);
  const dispatch = useDispatch();

  const currentValues = getIn(values, modelKey);
  const regionId = currentValues[nameFieldRegion];
  const communeId = currentValues[nameFieldCommune];

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleRegionData = response => {
    const { data } = response.data;
    setRegions(data);
    return data;
  };

  const fetchRegions = (inputValue, callback) => {
    debounceIndexRegionsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(handleRegionData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleCommuneData = response => {
    const { data } = response.data;
    setCommunes(data);
    return data;
  };

  const fetchCommunes = (inputValue, callback) => {
    debounceIndexCommunesRequest({
      dispatch,
      params: {
        name: inputValue,
        region: regionId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: response => callback(handleCommuneData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchInitialCommunes = (params = {}) => {
    indexCommunesRequest({
      dispatch,
      params: {
        ...params,
        sort_column: 'name',
        sort_direction: 'asc',
        paginate: false
      },
      successCallback: response => handleCommuneData(response),
      failureCallback: handleFailureRequest
    });
    setCommuneDisabled(false);
  };

  const handleRegionId = () => {
    if (regionId) fetchInitialCommunes({ region: regionId });
  };

  useEffect(handleRegionId, [regionId]);

  const initialDefaultValues = (array, attributeId) => {
    const data = array.find(obj => obj.value === attributeId);
    if (data === undefined) return false;
    return data;
  };

  const handleRegionChange = value => {
    setFieldValue(`${modelKey}[${nameFieldRegion}]`, value);
    setFieldValue(`${modelKey}[${nameFieldCommune}]`, '');
    if (value !== '') fetchInitialCommunes({ region: value });
    else setCommuneDisabled(true);
  };

  return (
    <>
      <Col md={6}>
        <Field name={`${modelKey}[${nameFieldRegion}]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={regionAbbr}
              isClearable={!regionAbbr}
              label="Región"
              placeholder="Seleccionar Región"
              disabled={allDisabled}
              value={initialDefaultValues(regions, regionId)}
              onChange={data => handleRegionChange(data ? data.value : '')}
              request={fetchRegions}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name={`${modelKey}[${nameFieldCommune}]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={communeAbbr}
              isClearable={!communeAbbr}
              label="Comuna"
              placeholder="Seleccionar Comuna"
              disabled={allDisabled || communeDisabled}
              value={initialDefaultValues(communes, communeId)}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              defaultOptions={communes}
              request={fetchCommunes}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default RegionCommune;
