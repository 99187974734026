import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';

import { FormikSelect } from '../../components';
import { months, years } from './FormOptions';

const PayrollProcessForm = ({ onHide, submitVariant, errors, touched, setFieldValue, isSubmitting }) => {
  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Field name="payrollProcess[year]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  placeholder="Seleccionar Año"
                  label="Año"
                  options={years}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="payrollProcess[month]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  placeholder="Seleccionar Mes"
                  label="Mes"
                  options={months}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} onClick={onHide} disabled={isSubmitting}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    payrollProcess: {
      year: moment().year(),
      month: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  payrollProcess: Yup.object().shape({
    year: Yup.number().required('Debes ingresar un año'),
    month: Yup.string().required('Debes ingresar un mes')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PayrollProcessForm);
