import React, { useEffect } from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import LinkBtn from '../../components/Utils/Button/LinkBtn';
import './style.scss';

const SelectionProcessSuccessPublic = props => {
  const company = props;

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Container className="mt-100">
      <>
        <Row>
          <Col md={3} />
          <Col md={6}>
            <div className="selection-header text-center">
              <Image rounded className="img" src={company.company?.businessLogo?.fileUrl} />
              <h4 className="text-uppercase text-dark mt-3">{company.company?.businessName}</h4>
              <h2 className="text-uppercase mt-3">Postulacion Registrada</h2>
              <p>Felicitaciones, te informaremos por correo los resultados.</p>
              <LinkBtn variant="primary" className="mt-3" to="/">
                Conoce más sobre PeopleWork
              </LinkBtn>
            </div>
          </Col>
          <Col md={3} />
        </Row>
      </>
    </Container>
  );
};

export default SelectionProcessSuccessPublic;
