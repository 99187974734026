import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'TICKET',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TITULO',
    selector: 'title',
    cell: item => item.title,
    sortable: true,
    grow: '3'
  },
  {
    name: 'FECHA',
    selector: 'created_at',
    cell: item => item.parsed_date,
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORA',
    selector: 'created_at',
    cell: item => item.parsed_time,
    sortable: false,
    grow: '1'
  },
  {
    name: 'SOLICITADO POR',
    selector: 'full_name',
    cell: item => item.employee.full_name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      const closed = item.translated_status === 'Cerrado' || item.translated_status === 'Cancelado';
      if (!closed) {
        const itemColorNormal = item.translated_status === 'Ingresado' ? 'primary' : '';
        itemColor = item.translated_status === 'Pendiente' ? 'warning' : itemColorNormal;
      }
      return (
        <p data-tag="allowRowEvents" className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Ticket"
        clickHandler={clickHandler}
        withShow
        withEdit={false}
        withDestroy={false}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
