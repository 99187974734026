import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showJobManagementRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/job_managements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexJobManagementsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/job_managements${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createJobManagementRequest = ({
  dispatch,
  params,
  formData,
  callback,
  failureCallback,
  successCallback
}) => {
  ApiService.request('post', '/job_managements', {
    dispatch,
    params,
    formData,
    callback,
    failureCallback,
    successCallback
  });
};

export const updateJobManagementRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/job_managements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const changeStatusJobManagementRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('put', `/job_managements/${id}/status_change`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const deleteJobManagementRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/job_managements/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportJobManagementsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/job_managements/export.xlsx`, {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const preImportJobManagementRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/job_managements/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importJobManagementRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/job_managements/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateJobManagementRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/job_managements/import_template', {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const activeJobManagementRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/job_managements/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateJobManagementRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/job_managements/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexJobManagementsRequest = AwesomeDebouncePromise(indexJobManagementsRequest, 300);
