import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikCheckBox, FormikInput, RegionCommune, PhoneWithCode } from '../../components';

const SubsidiaryForm = props => {
  const { onHide, submitVariant, errors, touched, action, setFieldValue, values, isSubmitting } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const {
    subsidiary: { phoneCountryCode, address }
  } = values;

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={10}>
            <Field name="subsidiary[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="subsidiary[code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Código"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <Field name="subsidiary[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección Completa"
                  defaultValue={address}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="subsidiary[addressFloor]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="number"
                  label="Piso"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="subsidiary[addressOffice]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Oficina"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row>
          <RegionCommune regionAbbr communeAbbr modelKey="subsidiary" />
        </Row>

        <Row>
          <Col md={6}>
            <PhoneWithCode
              label="Teléfono"
              countryCodeField="subsidiary[phoneCountryCode]"
              phoneField="subsidiary[phone]"
              workPhoneCountryCode={phoneCountryCode}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col md={6}>
            <Field name="subsidiary[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Field name="subsidiary[active]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" />}
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { subsidiary } = props;
  return { subsidiary };
};

const validationSchema = Yup.object().shape({
  subsidiary: Yup.object().shape(
    {
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      code: Yup.string()
        .required('Debes ingresar un código')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      address: Yup.string()
        .required('Debes ingresar una dirección')
        .max(120, 'Deben ser menos que 200 caracteres'),
      addressOffice: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(true),
      communeId: Yup.string().required('Debes seleccionar una comuna'),
      regionId: Yup.string().required('Debes seleccionar una región'),
      phoneCountryCode: Yup.string().when('phone', {
        is: val => val,
        then: Yup.string().required('Debes seleccionar un código'),
        otherwise: Yup.string().nullable()
      }),
      phone: Yup.string()
        .when('phoneCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .nullable(true),
      email: Yup.string()
        .email('Debes ingresar un e-mail válido')
        .nullable(),
      active: Yup.boolean()
    },
    [['phone', 'phoneCountryCode']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SubsidiaryForm);
