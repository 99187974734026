import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import { createShiftRequest } from '../../requests/shifts';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import ShiftForm from './ShiftForm';
import ShiftMonth from './ShiftMonth';
import ShiftRotative from './ShiftRotative';

import basicShift from './shift';

const ShiftNew = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shiftType = new URLSearchParams(location.search).get('shiftType') || 'weekly';

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Turno creado con éxito' }));
    history.push('/shifts');
  };

  const handleCreateRequest = (shift, setSubmitting) => {
    const daysAttributes = shift.shift.shiftDaysAttributes;
    const myParams = snakeCaseKeys({
      shift: {
        ...shift.shift,
        shiftDaysAttributes: daysAttributes.filter(item => item && item.send)
      }
    });

    createShiftRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  const title = () => {
    switch (shiftType) {
      case 'monthly':
        return 'Configuración Turno Mensual';
      case 'rotative':
        return 'Configuración Turno Rotativo';
      default:
        return 'Nuevo Turno Semanal';
    }
  };

  const body = () => {
    switch (shiftType) {
      case 'monthly':
        return <ShiftMonth shift={basicShift} action="new" formRequest={handleCreateRequest} />;
      case 'rotative':
        return <ShiftRotative shift={basicShift} action="new" formRequest={handleCreateRequest} />;
      default:
        return <ShiftForm shift={basicShift} action="new" formRequest={handleCreateRequest} />;
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/shifts">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">{title()}</h2>
        </Col>
      </Row>
      {body()}
    </>
  );
};

export default ShiftNew;
