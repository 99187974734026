export default {
  name: '',
  jobManagementIds: [],
  jobManagements: [],
  employees: [],
  employeeIds: [],
  roleIds: [],
  roles: [],
  exclude: false
};
