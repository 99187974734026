import React from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';
import { InputSelect } from '../../components';

const SelectionProcessNoticeModal = props => {
  const { onHide, submitVariant, errors, touched, setFieldValue, isSubmitting } = props;
  const dispatch = useDispatch();

  const fetchDocumentTemplates = (inputValue, callback) => {
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: ['selection_process_acceptance_notice', 'selection_process_rejection_notice'],
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <Form>
      <Modal.Body>
        <h4 className="mt-0 mb-4">Seleccione la plantilla que será enviada al correo del candidato</h4>
        <Row>
          <Col>
            <Field name="templatePdf[templateId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Plantilla"
                  placeholder="Seleccionar Plantilla"
                  value={undefined}
                  request={fetchDocumentTemplates}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant={submitVariant} onClick={onHide} disabled={isSubmitting}>
          Enviar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    templatePdf: {
      documentType: 'selection_process_acceptance_notice',
      templateId: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  templatePdf: Yup.object().shape({
    templateId: Yup.string().required('Debes seleccionar una plantilla')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(SelectionProcessNoticeModal);
