import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexRiskPreventionDocumentRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/risk_prevention_documents', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const updateRiskPreventionDocumentRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/risk_prevention_documents/${id}/`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const downloadRiskPreventionDocumentRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/risk_prevention_documents/${id}/download_document`, {
    dispatch,
    params,
    formData,
    responseType: 'blob',
    successCallback,
    callback
  });

export const debounceIndexRiskPreventionDocumentRequest = AwesomeDebouncePromise(
  indexRiskPreventionDocumentRequest,
  300
);
