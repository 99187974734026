import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import columns from './columns';
import { SimpleCenteredModal } from '../../components';
import { updateBookkeeperRequest } from '../../requests/bookkeepers';
import { sendAlert } from '../../actions/utils';
import BookkeeperForm from './BookkeeperForm';
import BookkeeperDataTable from './BookkeeperDataTable';

const BookkeeperIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nueva cuenta contable');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [modalSize, setModalSize] = useState('lg');

  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdate = bookkeeperValue => {
    const {
      bookkeeper: { id }
    } = bookkeeperValue;
    updateBookkeeperRequest(id, {
      dispatch,
      params: snakeCaseKeys(bookkeeperValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cuenta contable actualizada con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      failureCallback: handleFailureRequest
    });
  };

  const editBookkeeper = bookkeeperRow => {
    setModalShow(true);
    setModalSize('lg');
    setModalBody(
      <BookkeeperForm
        bookkeeper={camelCaseRecursive(bookkeeperRow)}
        bookkeeperId={bookkeeperRow.id}
        action="edit"
        formRequest={handleUpdate}
        cancelAction={() => setModalShow(false)}
      />
    );
    setModalTitle('Editar cuenta contable');
  };

  return (
    <>
      <BookkeeperDataTable
        columns={columns}
        editBookkeeper={editBookkeeper}
        moreData={moreData}
        setMoreData={setMoreData}
      />
      <SimpleCenteredModal
        title={modalTitle}
        size={modalSize}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default BookkeeperIndex;
