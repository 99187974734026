import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, ApprovementStatus } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'MES',
    selector: 'month',
    grow: '1',
    sortable: true
  },
  {
    name: 'HABERES',
    selector: 'asset_total',
    grow: '1'
  },
  {
    name: 'DESCUENTOS',
    selector: 'discount_total',
    grow: '1'
  },
  {
    name: 'TOTAL LÍQUIDO',
    selector: 'liquid_total',
    grow: '1'
  },
  {
    name: 'TOTAL BRUTO',
    selector: 'gross_total',
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    grow: '1',
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    right: true,
    grow: '1',
    minWidth: '123px'
  }
]);
