import React from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import Icon from '../../Icons';
import './style.scss';

const businessNode = nodeData => (
  <>
    <div className="position">
      <span className="avatar">
        <span className={nodeData.businessLogo.fileUrl ? 'content' : 'empty content'}>
          {nodeData.businessLogo?.fileUrl ? (
            <Image id={nodeData.id} src={nodeData.businessLogo.fileUrl} />
          ) : (
            <Icon icon="camera" />
          )}
        </span>
      </span>
    </div>
    <div className="fullname mb-35 mt-3">{nodeData.businessName}</div>
  </>
);

const ChartNodeEmployee = ({ nodeData, fetchRequest, onFetch }) => {
  if (nodeData.businessName) {
    return businessNode(nodeData);
  }
  const fetch = nodeData.hasChildren && (!nodeData.children || nodeData.children.length === 0);

  return (
    <>
      <div className="position">
        <span className="avatar">
          <span className={nodeData.fileInfo?.fileUrl ? 'content' : 'empty content'}>
            {nodeData.fileInfo?.fileUrl ? (
              <Image id={nodeData.id} src={nodeData.fileInfo.fileUrl} />
            ) : (
              <Icon icon="camera" />
            )}
          </span>
        </span>
      </div>
      <div className="dataTitle">{nodeData.title}</div>
      <div className="fullname mb-35">
        {nodeData.name}
        {fetch && (
          <>
            <Button variant="circle-light" className="btn-circle" onClick={() => fetchRequest(nodeData)}>
              <Icon icon="add" />
            </Button>
            {onFetch === nodeData.id && <Spinner className="mt-2" animation="border" variant="primary" />}
          </>
        )}
      </div>
    </>
  );
};

export default ChartNodeEmployee;
