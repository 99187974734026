import React from 'react';
import { Table } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import {
  BasicTooltip,
  ButtonTooltip,
  DatatableContractType,
  DatatableEmployeeName,
  FormikNumber,
  Icon
} from '../../components';

const PayrollGroupTable = ({ payrolls, removeAnEmployee, setModal, validateIfHasPayroll, validatePayroll }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const payrollsToShow = payrolls.filter(payroll => payroll._destroy !== true);

  const modalErrorMessage = payrollErrors => {
    const { missingFields = [] } = payrollErrors;
    if (missingFields.length === 0) return '';
    return (
      <>
        <p className="mb-4 mx-3 font-weight-normal">
          El trabajador no cuenta con los siguientes campos mínimos del contrato para realizar esta liquidación:
        </p>
        <ul className="list-two-columns">
          {missingFields.map((field, index) => (
            <li key={`missing-field-${index.toString()}`} type="disc" className="font-weight-light text-danger">
              <span className="text-dark">{field}</span>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const dataNotFound = () => {
    return (
      <tbody>
        <tr className="d-flex justify-content-center text-uppercase">
          <td style={{ color: '#959595' }}>No se encontraron datos.</td>
        </tr>
      </tbody>
    );
  };

  const handleHeadItems = () => {
    return (
      <tr>
        <th style={{ minWidth: '35px' }} />
        <th className="align-middle" style={{ minWidth: '175px' }}>
          Nombre del Trabajador
        </th>
        <th className="text-center align-middle" style={{ maxWidth: '150px' }}>
          Área
        </th>
        <th className="text-center align-middle" style={{ maxWidth: '140px' }}>
          Días Trabajados
        </th>
        <th className="text-center align-middle" style={{ maxWidth: '120px' }}>
          Días Licencias
        </th>
        <th className="text-center align-middle" style={{ maxWidth: '120px' }}>
          Ausentismo
        </th>
        <th className="align-middle" style={{ minWidth: '150px' }}>
          Contrato
        </th>
        <th className="align-middle" style={{ minWidth: '150px' }}>
          Sobregiros Pendientes
        </th>
        <th />
      </tr>
    );
  };

  return (
    <Table responsive>
      {payrollsToShow.length === 0 ? (
        dataNotFound()
      ) : (
        <>
          <thead className="text-uppercase">{handleHeadItems()}</thead>
          <tbody>
            {payrollsToShow.map((payroll, index) => {
              const { employee } = payroll;
              const payrollErrors = validatePayroll(employee);
              const anyPayrollError = payrollErrors.length > 0;
              const noContract = payrollErrors.includes('noContract');
              const noRut = payrollErrors.includes('noRut');
              const hasPayroll = validateIfHasPayroll(employee);
              const errorColor = (hasPayroll && 'text-warning') || (anyPayrollError && 'text-danger');
              const hasPayrollOrNoContract = hasPayroll
                ? { icon: 'warning-outline', text: 'El trabajador ya tiene una liquidación para este proceso' }
                : (noContract && { icon: 'close-circle-outline', text: 'Trabajador sin contrato' }) ||
                  (noRut && { icon: 'close-circle-outline', text: 'Trabajador sin RUT' });
              const hasOverdrafts = employee.pendingOverdraftsTotal !== '0.0';
              const overdraftHelpText = hasOverdrafts
                ? `Máximo disponible ${employee.parsedPendingOverdraftsTotal}`
                : 'No cuenta con sobregiros';
              return (
                <tr key={`payroll-process-${index.toString()}`} className={`${errorColor}`}>
                  <td className="align-middle text-center pr-0">
                    {hasPayrollOrNoContract ? (
                      <BasicTooltip toolbarVariant="d-inline-block" text={hasPayrollOrNoContract.text}>
                        <Icon icon={hasPayrollOrNoContract.icon} width="21px" />
                      </BasicTooltip>
                    ) : (
                      anyPayrollError && (
                        <ButtonTooltip
                          size="sm"
                          variant="circle-dark"
                          toolbarVariant="d-inline-block"
                          className="text-danger"
                          text="Más detalles"
                          onClick={() =>
                            !noContract && setModal('Campos Faltantes', modalErrorMessage(employee.payrollErrors), 'md')
                          }
                        >
                          <Icon icon="close-circle-outline" />
                        </ButtonTooltip>
                      )
                    )}
                  </td>
                  <td className="employee-name">
                    <DatatableEmployeeName
                      item={employee}
                      fileName="fileInfo"
                      name="fullName"
                      fileUrl="fileUrl"
                      errorColor={errorColor}
                    />
                  </td>
                  <td className="text-center align-middle" style={{ maxWidth: '150px' }}>
                    {employee.jobManagement}
                  </td>
                  <td className="text-center align-middle">{employee.workedDays}</td>
                  <td className="text-center align-middle">{employee.licenseDays}</td>
                  <td className="text-center align-middle">{employee.absenceDays}</td>
                  <td className="align-middle" style={{ minWidth: '150px' }}>
                    <DatatableContractType item={employee.simpleContractInfo} errorColor={errorColor} />
                  </td>
                  <td className="text-center align-middle" style={{ maxWidth: '150px' }}>
                    <Field name={`payrollGroup[payrollsAttributes][${index}][parsedOverdraftsLimit]`}>
                      {({ field }) => (
                        <FormikNumber
                          {...field}
                          margin="mb-0"
                          leftAddon="$"
                          helpText={overdraftHelpText}
                          fieldName={`payrollGroup[payrollsAttributes][${index}][overdraftsLimit]`}
                          value={getIn(values, `payrollGroup[payrollsAttributes][${index}][overdraftsLimit]`)}
                          isAllowed={data => data.floatValue <= employee.pendingOverdraftsTotal}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          disabled={!hasOverdrafts}
                        />
                      )}
                    </Field>
                  </td>
                  <td className="text-center align-middle">
                    <ButtonTooltip
                      onClick={() => removeAnEmployee(employee)}
                      variant="circle-danger"
                      className="btn-circle"
                      size="sm"
                      text="Eliminar"
                    >
                      <Icon icon="trash" />
                    </ButtonTooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      )}
    </Table>
  );
};

export default PayrollGroupTable;
