/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';

import { ComponentDataTable, SimpleCenteredModal } from '../../components';
import { filterArray } from '../../services/utils';
import columns from './Columns';

const PayrollDataTable = ({ moreData, payrolls = [] }) => {
  const [filterPayrolls, setFilterPayrolls] = useState(payrolls);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitleBtn, setModalTitleBtn] = useState('');
  const [textInput, setTextInput] = useState('');

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        const file = item.fileInfo;
        const downloadBtn = (
          <Button variant="primary" href={file?.fileUrl} target="_blank" rel="noopener noreferrer" className="ml-4">
            Descargar PDF
          </Button>
        );
        const body = (
          <iframe src={file?.fileUrl} title={`${file?.filename}`} style={{ height: '500px' }} className="w-100">
            <p>Este navegador no soporta PDFs. Por favor descarga el PDF para verlo: {downloadBtn}</p>
          </iframe>
        );
        setModalTitleBtn(downloadBtn);
        setModalBody(body);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const subHeaderComponent = () => (
    <>
      <Form.Group className="ml-auto w-25">
        <FormControl
          placeholder="Buscar"
          name="textInput"
          value={textInput}
          onChange={e => setTextInput(e.target.value)}
        />
      </Form.Group>
    </>
  );

  const filteredArray = () => {
    setFilterPayrolls(
      filterArray(payrolls, textInput, ['fullName', 'rut', 'liquid', 'discountsTotal', 'assetsTotal', 'status'])
    );
  };

  useEffect(filteredArray, [textInput, payrolls]);

  return (
    <>
      <ComponentDataTable
        pointerOnHover
        preName="payroll_processes"
        columns={columns(handleButtonClick)}
        onRowClicked={item => handleButtonClick(item, 'show')}
        data={filterPayrolls}
        onRequest={false}
        moreData={moreData}
        subHeaderComponent={subHeaderComponent()}
        defaultSortField="id"
        sortServer={false}
        paginationServer={false}
        pagination
      />
      <SimpleCenteredModal
        title="Detalles de Liquidación"
        titleBtn={modalTitleBtn}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        bodyClass="mx-3 mb-3"
      />
    </>
  );
};

export default PayrollDataTable;
