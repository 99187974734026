import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { delayMethod } from '../../../services/utils';

const ModalTowActions = ({
  body,
  closeButton,
  handleClose,
  handleConfirmActionOne,
  handleConfirmActionTwo,
  modalSize,
  show,
  title,
  titleBtnClose,
  titleBtnActionTwo,
  titleBtnActionOne,
  variantBtnClose,
  variantBtnActionTwo,
  variantBtnActionOne,
  withClose,
  withActionTow,
  withActionOne,
  disabled,
  ...props
}) => {
  return (
    <Modal {...props} size={modalSize} show={show} onHide={handleClose}>
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {(withClose || withActionTow || withActionOne) && (
        <Modal.Footer>
          {withClose && (
            <Button
              className="btn"
              variant={variantBtnClose}
              onClick={() => delayMethod(handleClose)}
              disabled={disabled}
            >
              {titleBtnClose}
            </Button>
          )}

          {withActionTow && (
            <Button className="btn" variant={variantBtnActionTwo} onClick={handleConfirmActionTwo} disabled={disabled}>
              {titleBtnActionTwo}
            </Button>
          )}
          {withActionOne && (
            <Button className="btn" variant={variantBtnActionOne} onClick={handleConfirmActionOne} disabled={disabled}>
              {titleBtnActionOne}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalTowActions.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
  variantBtnClose: PropTypes.string,
  titleBtnClose: PropTypes.string,
  titleBtnActionOne: PropTypes.string,
  variantBtnActionOne: PropTypes.string,
  variantBtnActionTwo: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
  withActionTow: PropTypes.bool,
  withActionOne: PropTypes.bool,
  withClose: PropTypes.bool,
  modalSize: PropTypes.string,
  disabled: PropTypes.bool
};

ModalTowActions.defaultProps = {
  closeButton: true,
  handleConfirm: () => null,
  modalSize: '',
  titleBtnClose: 'Cancelar',
  titleBtnActionOne: 'Confirmar',
  variantBtnClose: 'outline-info',
  variantBtnActionOne: 'primary',
  variantBtnActionTwo: 'primary',
  withClose: true,
  withActionTow: false,
  withActionOne: true,
  disabled: false
};

export default ModalTowActions;
