import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import memoize from 'memoize-one';
import { BasicActionBtns, ComponentDataTable, SimpleCenteredModal } from '../../../components';
import { indexDocumentImedRequest } from '../../../requests/integrationDocumentImeds';

const columns = memoize(clickHandler => [
  {
    name: 'Nombre',
    selector: 'name_file',
    cell: item => item.nameFile,
    sortable: true,
    grow: '1'
  },
  {
    name: 'Tipo de Archivo',
    selector: 'type_document',
    cell: item => item.translatedTypeDocument,
    sortable: true,
    grow: '1.5'
  },

  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <BasicActionBtns
          resource="IntegrationDocumentImed"
          item={item}
          withShow={false}
          withDestroy={false}
          withEdit={false}
          clickHandler={clickHandler}
          extraIcon="cloud-download"
          extraIconAction="download"
          extraIconColor="circle-info"
          extraIconText="Descargar"
        />
        <BasicActionBtns
          resource="IntegrationDocumentImed"
          item={item}
          clickHandler={clickHandler}
          withShow={false}
          withEdit={false}
        />
      </>
    ),

    ignoreRowClick: true,
    allowOverflow: false,
    button: false,
    grow: '1',
    maxWidth: '175px',
    minWidth: '175px'
  }
]);

const FileTransactDataTable = props => {
  const { moreData, body, modalShow, setModalShow, titleModal, handleButtonClick, numLicense } = props;
  const [listDocumentImeds, setListDocumentImeds] = useState();
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState();

  const documentImedRequest = params => {
    setOnRequest(true);
    indexDocumentImedRequest(numLicense, {
      dispatch,
      params,
      successCallback: response => {
        setListDocumentImeds(camelCaseRecursive(response.data.data));
        setAmount(response.data.metadata.amount);
        setOnRequest(false);
      }
    });
  };
  return (
    <>
      <ComponentDataTable
        columns={columns(handleButtonClick)}
        data={listDocumentImeds}
        moreData={moreData}
        onRequest={onRequest}
        totalRows={amount}
        resourceRequest={documentImedRequest}
        pointerOnHover
        onRowClicked={item => handleButtonClick(item, 'show')}
      />

      <SimpleCenteredModal
        show={modalShow}
        title={titleModal}
        body={body}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </>
  );
};

export default FileTransactDataTable;
