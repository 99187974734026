import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Button, Card, Spinner, Badge } from 'react-bootstrap';
import moment from 'moment';
import { FormikInput, FormikSelect, FormikTimePicker } from '../../components';
import { rutFormat, validRutInput } from '../../services/utils';
import { debounceindexNotificationPeriodRequest, showNumberFunesPendingRequest } from '../../requests/xdatas';
import NotificationPeriodDataTable from './NotificationPeriodDataTable';
import { healthPrevision, funType, estadoFun } from './TableOptions';
import { statusFun } from '../../components/SharedFields/FormOptions';

const NotificationPeriodIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const startDate = new Date();
  const [periodo, setPeriodo] = useState(startDate);
  const [folioFun, setFolioFun] = useState('');
  const [estado, setEstado] = useState('');
  const [rut, setRut] = useState('');
  const [amountFunes, setAmountFunes] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [funesList, setfunesList] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const customPeriod = moment(periodo).format('YYYYMM');
  const dispatch = useDispatch();

  const handleSuccessFunesPending = response => {
    const { data } = response;
    setAmountFunes(data.cantidad_funes);
    setLastDate(data.fecha_ultimo_aviso ? moment(data).format('DD/MM/YYYY') : '');
    setOnRequest(false);
  };

  const requestFunesPending = () => {
    setOnRequest(true);
    showNumberFunesPendingRequest({
      params: { periodo_proc: customPeriod },
      dispatch,
      successCallback: handleSuccessFunesPending
    });
  };

  const handleSuccessIndex = response => {
    const { data } = response;
    setfunesList([]);
    const dataFilter = data
      .map(row => {
        return {
          ...row,
          fun_est_not_re_desc: estadoFun[row.fun_est_not_re],
          fun_ori_fun_cr_desc: funType[row.fun_ori_fun_cr],
          cis_cod_prd_cr_desc: healthPrevision[row.cis_cod_prd_cr]
        };
      })
      .sort()
      .reverse();
    const rutEmployee = rut.replace(/[^a-zA-Z0-9 -]/g, '').split('-');
    const _rut = rutEmployee[0] !== undefined ? rutEmployee[0] : '';
    const _dv = rutEmployee[1] !== undefined ? rutEmployee[1] : '';

    setfunesList(
      dataFilter.filter(
        list =>
          list.fun_fol_fun_nn.includes(folioFun) &&
          list.fun_rut_afi_nn.toLowerCase().includes(_rut.toLowerCase()) &&
          list.fun_dv_afi_cr.toLowerCase().includes(_dv.toLowerCase()) &&
          list.fun_est_not_re_desc.toLowerCase().includes(estado.toLowerCase())
      )
    );
    setOnRequest(false);
    requestFunesPending();
  };

  const requestDatatable = () => {
    setOnRequest(true);

    debounceindexNotificationPeriodRequest({
      params: { periodo_proc: customPeriod },
      dispatch,
      successCallback: handleSuccessIndex,
      failureCallback: () => setOnRequest(false)
    });
  };
  const handleInit = () => {
    requestDatatable();
    requestFunesPending();
  };
  useEffect(handleInit, []);

  const handleFilter = e => {
    setOnRequest(true);
    e.preventDefault();
    debounceindexNotificationPeriodRequest({
      params: { periodo_proc: customPeriod },
      dispatch,
      successCallback: handleSuccessIndex,
      failureCallback: () => setOnRequest(false)
    });
  };

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setRut(formattedValue);
    }
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Notificación de F.U.N</h2>
        </Col>
        <Col>
          <h3 className="mt-4">
            <Badge variant="primary text-center">Fecha de último aviso {lastDate}</Badge>
          </h3>
        </Col>
      </Row>
      <Form onSubmit={handleFilter}>
        <Row className="justify-content-center">
          <Col xs={12} md={3} xl={4}>
            <FormikTimePicker
              abbr
              label="Periodo"
              name="periodo"
              setLocale
              placeholder="mm/aaaa"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              selected={periodo}
              onChange={date => {
                setPeriodo(moment.isMoment(date) ? date.format('YYYYMM') : date);
              }}
            />
          </Col>
          <Col xs={12} md={3} xl={4}>
            <FormikInput
              label="F.U.N"
              name="folio"
              placeholder="1234"
              onChange={e => setFolioFun(e.target.value)}
              value={folioFun}
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="text-center">
            <FormikInput
              label="RUT Trabajador"
              name="rut"
              placeholder="12.345.678-9"
              onChange={e => handleNationalIdentificationFormat(e)}
              value={rut}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={3} xl={4}>
            <FormikSelect
              label="Estado"
              name="estado"
              placeholder="Seleccionar"
              options={statusFun}
              onChange={e => setEstado(e.value)}
              value={estado}
            />
          </Col>
          <Col xs={12} md={3} xl={4}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title>{amountFunes}</Card.Title>
                <Card.Subtitle className="text-muted">Pendientes</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} xl={4} className="text-center mt-3">
            <Button variant="primary" type="submit" className="btn-block">
              Filtrar
            </Button>
          </Col>
        </Row>
        {onRequest && (
          <div className="containerSpinnerLoad responsive-spinner center-spinner mt-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <NotificationPeriodDataTable
          data={funesList}
          withMassActions
          requestDatatable={requestDatatable}
          requestFunesPending={requestFunesPending}
          customPeriod={customPeriod}
        />
      </Form>
    </>
  );
};

export default NotificationPeriodIndex;
