import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTicketsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/tickets', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showTicketRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/tickets/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createTicketRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/tickets', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const cancelTicketRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/tickets/${id}/cancel`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const updateTicketRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/tickets/${id}/`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const denyAccountAccessRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/tickets/${id}/deny_access`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const debounceIndexTicketsRequest = AwesomeDebouncePromise(indexTicketsRequest, 300);
