import React from 'react';
import memoize from 'memoize-one';
import { DatatableEmployeeName, ButtonTooltip, Icon } from '../../components';

const columns = memoize(handleClick => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'fullName',
    cell: item => <DatatableEmployeeName item={item} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'jobManagement',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'CARGO',
    selector: 'jobTitle',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => handleClick(item, 'destroy')}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar"
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    ),
    sortable: false,
    grow: '1',
    right: true
  }
]);

export default columns;
