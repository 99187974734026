import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showEmployeeRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/employees/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const stunnerEmployeeRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/employees/${id}/stunner_simulation`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const indexEmployeesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback,
  callback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/employees${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });
};

export const indexSelectEmployeesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/employees/index_select', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexSelectByLoadEmployeesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/employees/index_select_by_load', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createEmployeeRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/employees', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const deactivateEmployeeRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('post', '/employees/status_change', {
    dispatch,
    params,
    formData: true,
    callback,
    successCallback
  });

export const destroyEmployeeRequest = (id, { dispatch, callback, successCallback }) =>
  ApiService.request('delete', `/employees/${id}`, {
    dispatch,
    callback,
    successCallback
  });

export const forceEnrollmentEmployeeRequest = (id, { dispatch, callback, successCallback }) =>
  ApiService.request('put', `/employees/${id}/force_enrollment`, {
    dispatch,
    callback,
    successCallback
  });

export const updateEmployeeRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/employees/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const shiftEmployeeRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/employees/${id}/shifts`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const searchEmployeeRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/employees/search`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const searchEmployeeByRutRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/employees/search_by_rut/`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const searchEmployeesWithAvailableVacationsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('get', `/employees/vacations_available`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const sendActivationEmailsRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/employees/send_activation_emails/', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const sendActivationEmailRequest = (id, { dispatch, params, callback, successCallback }) =>
  ApiService.request('get', `/employees/${id}/send_activation_email`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const exportEmployeesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/employees/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportEmployeeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/employees/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importEmployeeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/employees/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateEmployeeRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/employees/import_template', {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const payrollsRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/employees/payrolls', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexEmployeesRequest = AwesomeDebouncePromise(indexEmployeesRequest, 300);
export const debounceIndexSelectEmployeesRequest = AwesomeDebouncePromise(indexSelectEmployeesRequest, 300);
