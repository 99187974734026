import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn } from '../../components';
import { useAuthorization } from '../../services/hooks';
import EmployeeDataTable from '../Shared/Employee/EmployeeDataTable';
import columns from './Columns';

const EmployeeIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Colaboradores</h2>
        </Col>
        {useAuthorization('create', 'Employee') && (
          <Col md={2}>
            <LinkBtn variant="primary" className="mt-2" block to="/employees/new">
              Nuevo
            </LinkBtn>
          </Col>
        )}
      </Row>
      <EmployeeDataTable columns={columns} withMassActions clickRoute="/employees" showActions showFilters />
    </>
  );
};

export default EmployeeIndex;
