import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { showPayrollProcessRequest } from '../../requests/payrollProcesses';
import { createPayrollGroupRequest } from '../../requests/payrollGroups';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import PayrollGroupForm from './PayrollGroupForm';

const basicPayrollGroup = {
  annualGratification: false,
  earningsOfPeriod: '',
  limitFifteenPercentage: true,
  month: '',
  name: '',
  payrollProcessId: '',
  payrollsAttributes: [],
  year: ''
};

const PayrollGroupNew = ({ match }) => {
  const { payrollProcessId } = match.params;
  const [cancelSubmit, setCancelSubmit] = useState(false);
  const [payrollGroup, setPayrollGroup] = useState(basicPayrollGroup);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchPayrollProcess = () => {
    const request = async () => {
      showPayrollProcessRequest(payrollProcessId, {
        dispatch,
        params: { simple_serializer: true },
        successCallback: response => {
          const { id, month, year } = camelCaseEmptyStringRecursive(response.data);
          setPayrollGroup({ ...payrollGroup, payrollProcessId: id, month, year });
        }
      });
    };
    request();
  };

  const handleSuccessRequest = response => {
    const { id } = response.data;
    history.push(`/payroll_processes/${payrollProcessId}/payroll_groups/${id}/payroll_balances/new`);
  };

  const handleCreateRequest = (params, setSubmitting) => {
    if (cancelSubmit) return;
    createPayrollGroupRequest(payrollProcessId, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchPayrollProcess, []);

  return (
    <PayrollGroupForm
      action="new"
      payrollGroup={payrollGroup}
      formRequest={handleCreateRequest}
      setCancelSubmit={setCancelSubmit}
    />
  );
};

export default PayrollGroupNew;
