import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';

import { duplicateProfileRequest, indexProfilesRequest, deleteProfileRequest } from '../../requests/profiles';
import { ComponentDataTable, DefaultModal, BasicActionBtns } from '../../components';
import { sendAlert } from '../../actions/utils';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'EMPLEADOS',
    selector: 'employees_count',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Profile"
        clickHandler={clickHandler}
        extraIcon="copy"
        extraIconAction="copy"
        extraIconColor="circle-primary"
        extraIconText="Duplicar"
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '175px'
  }
]);

const RoleDataTable = () => {
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [roles, setRoles] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setRoles(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    indexProfilesRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleSuccessRemove = message => {
    setOnRequest(false);
    setModalShow(false);
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    setModalShow(false);
    setOnRequest(false);
  };

  const removeRole = roleId => {
    setOnRequest(true);
    deleteProfileRequest(roleId, {
      dispatch,
      successCallback: handleSuccessRemove('Perfil Eliminado Correctamente'),
      failureCallback: handleFailureRequest
    });
  };

  const dupRole = roleId => {
    setOnRequest(true);
    duplicateProfileRequest(roleId, {
      dispatch,
      successCallback: handleSuccessRemove('Perfil Duplicado Correctamente'),
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/roles/${item.id}`);
        break;
      case 'copy':
        setModalShow(true);
        setModalTitle('Duplicar Perfil');
        setModalItem(item);
        setModalAction(() => dupRole);
        setModalBody(`¿Estás seguro que deseas duplicar el perfil '${item.name}'?`);
        break;
      case 'edit':
        history.push(`/roles/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalTitle('Eliminar Perfil');
        setModalItem(item);
        setModalAction(() => removeRole);
        setModalBody(`¿Estás seguro que deseas eliminar el perfil '${item.name}'?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={roles}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem.id)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default RoleDataTable;
