import React, { useState } from 'react';
import { useFormikContext, getIn, Field } from 'formik';
import { Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import {
  FormikInput,
  BasicDropdown,
  Icon,
  FormikNumber,
  FormikSelect,
  SimpleCenteredModal,
  FormikDatePicker
} from '../../components';
import './style.scss';

import { binaryRequirements, competenceTypes, defaultQuestionChoices, requireAmountTypes } from './FormOptions';
import LikertFields from './LikertFields';

const SkillFields = ({ valuePath, skillAttribute }) => {
  const { errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const [modalShow, setModalShow] = useState(false);

  const dropdownClick = amountType => {
    setFieldValue(`${valuePath}[requireAmountType]`, amountType);
    setFieldValue(`${valuePath}[requireAmount]`, '');
    if (amountType === 'likert') {
      const defChoices =
        skillAttribute.questionChoicesAttributes.length > 0
          ? skillAttribute.questionChoicesAttributes
          : defaultQuestionChoices;
      setFieldValue(`${valuePath}[questionChoicesAttributes]`, defChoices);
    } else {
      setFieldValue(`${valuePath}[questionChoicesAttributes]`, []);
    }
  };

  const dropdownItems = requireAmountTypes.map(item => ({
    key: `${valuePath}-${item.value}`,
    title: item.label,
    props: {
      onClick: () => dropdownClick(item.value),
      active: skillAttribute.requireAmountType === item.value
    }
  }));

  const requireAmountField = amountType => {
    const hasError = getIn(errors, `${valuePath}[requireAmount]`) && getIn(touched, `${valuePath}[requireAmount]`);
    switch (amountType) {
      case 'likert':
        return (
          <Form.Group>
            <Form.Label className="d-flex align-items-center">Medición Likert</Form.Label>
            <InputGroup>
              <Button block onClick={() => setModalShow(true)} variant="primary">
                Configurar Likert
              </Button>
            </InputGroup>
            {hasError && <Form.Text className="text-danger">{getIn(errors, `${valuePath}[requireAmount]`)}</Form.Text>}
          </Form.Group>
        );
      case 'binary':
        return (
          <Field name={`${valuePath}[requireAmount]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Cumplimiento Requerido"
                labelClass="text-truncate"
                placeholder="Seleccionar"
                options={binaryRequirements}
                defaultValue={skillAttribute.requireAmount}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        );
      default:
        return (
          <Field name={`${valuePath}[parsedRequireAmount]`}>
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                label="Cumplimiento Requerido"
                labelClass="text-truncate"
                fieldName={`${valuePath}[requireAmount]`}
                setFieldValue={setFieldValue}
                value={skillAttribute.requireAmount}
                leftAddon={skillAttribute.requireAmountType === 'percentage' ? '%' : false}
                errors={errors}
                touched={touched}
                isAllowed={data => {
                  if (skillAttribute.requireAmountType === 'percentage') {
                    return data.value <= 100;
                  }
                  return true;
                }}
              />
            )}
          </Field>
        );
    }
  };

  return (
    <>
      <Row className="skill-fields">
        <Col md={2} xl={3} className="pl-0 pl-xl-3">
          <Field name={`${valuePath}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} className="pl-0 pl-xl-3">
          <Field name={`${valuePath}[description]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label={skillAttribute.skillType === 'objective' ? 'Descripción' : 'Definición'}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} className="pl-0 pl-xl-3">
          {skillAttribute.skillType === 'objective' ? (
            <Field name={`${valuePath}[endDate]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  isOutsideRange={() => false}
                  label="Plazo"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          ) : (
            <Field name={`${valuePath}[competenceType]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Tipo"
                  placeholder="Seleccionar"
                  options={competenceTypes}
                  defaultValue={skillAttribute.competenceType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          )}
        </Col>
        <Col md={3} xl={2} className="pl-0 pl-xl-3">
          {requireAmountField(skillAttribute.requireAmountType)}
        </Col>
        <Col md={1} className="pl-0 pl-xl-3">
          <BasicDropdown
            variant="no-border mr-auto ml-4 mt-4"
            noArrow
            block
            right
            titleDrop={
              <span className="align-middle">
                <Icon icon="chevron-down" height="20px" width="20px" />
              </span>
            }
            items={dropdownItems}
          />
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Configurar"
        body={
          <LikertFields valuePath={valuePath} skillAttribute={skillAttribute} handleClose={() => setModalShow(false)} />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
      />
    </>
  );
};

export default SkillFields;
