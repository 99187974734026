import React from 'react';
import { useDispatch } from 'react-redux';

import { sendAlert } from '../../../actions/utils';
import { updateFolderRequest } from '../../../requests/directoryNodes';
import DirectoryNodeFormFolder from './DirectoryNodeFormFolder';

const DirectoryNodeEdit = ({ onSuccess, folderId, employeeId, folderName, onHide, setOnRequest }) => {
  const dispatch = useDispatch();

  const handleSuccessRequest = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    onSuccess();
  };

  const handleChangeNameFolder = nameFolder => {
    setOnRequest(true);

    const {
      directoryNode: { name }
    } = nameFolder;

    updateFolderRequest(folderId, {
      employeeId,
      dispatch,
      params: {
        name
      },
      successCallback: () => handleSuccessRequest('Nombre cambiado con éxito'),
      callback: () => setOnRequest(false)
    });
  };

  return (
    <DirectoryNodeFormFolder
      formRequest={handleChangeNameFolder}
      subtitle={
        <p>
          Estás cambiando el nombre de la carpeta <span className="font-weight-bold">{folderName}</span>
        </p>
      }
      onHide={onHide}
    />
  );
};

export default DirectoryNodeEdit;
