import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import SelectionProcessForm from './SelectionProcessForm';
import { createSelectionProcessRequest } from '../../requests/selectionProcesses';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';

const SelectionProcessNew = () => {
  const { currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const history = useHistory();

  const currentSelectionProcess = {
    id: '',
    name: '',
    companyId: currentCompany || '',
    desiredKnowledge: '',
    expertiseLevel: '',
    jobDescription: '',
    startDate: '',
    jobTitleId: '',
    jobManagementId: '',
    vacancies: '',
    processType: '',
    remote: '',
    status: '',
    workSchedule: '',
    selectionProcessStagesAttributes: [
      { name: 'Candidatos del proceso', position: 1 },
      { name: 'Entrevista telefónica', position: 2 },
      { name: 'Entrevista personal', position: 3 },
      { name: 'Evaluación psicolaboral', position: 4 },
      { name: 'Entrevista con jefatura', position: 5 },
      { name: 'Candidatos finales', position: 6 },
      { name: 'Candidatos descartados', position: 7 }
    ]
  };

  const handleSuccessCreate = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreate = selectionProcess => {
    createSelectionProcessRequest({
      dispatch,
      params: snakeCaseKeys(selectionProcess),
      successCallback: res => {
        handleSuccessCreate('Proceso de selección creado con éxito');
        history.push(`/selection_processes/${res.data.id}`);
      },
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row className="mt-4 mb-1">
      <Col xs={2}>
        <LinkBtn variant="circle-dark" className="btn-circle mt-3 ml-n2 ml-xl-5" block to="/selection_processes">
          <Icon icon="chevron-back" />
        </LinkBtn>
      </Col>
      <Col xs={10}>
        <h2 className="text-uppercase mt-3 mb-4 ml-n3 ml-md-n5">Nuevo proceso de selección</h2>
      </Col>
      <Col>
        <SelectionProcessForm action="new" selectionProcess={currentSelectionProcess} formRequest={handleCreate} />
      </Col>
    </Row>
  );
};

export default SelectionProcessNew;
