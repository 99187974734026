import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import {
  ButtonTooltip,
  ComponentDataTable,
  DefaultModal,
  DocumentActions,
  Icon,
  ImportModal,
  Reminder,
  SimpleCenteredModal
} from '../../../components';
import {
  approvePermissionRequest,
  debounceIndexPermissionsRequest,
  deletePermissionRequest,
  exportPermissionsRequest,
  importPermissionRequest,
  importTemplatePermissionRequest,
  preImportPermissionRequest,
  rejectPermissionRequest
} from '../../../requests/permissions';
import {
  massiveRejectWorkflowRequestRequest,
  massiveSingWorkflowRequestRequest,
  signWorkflowRequestRequest
} from '../../../requests/workflowRequests';

import PermissionInfo from '../../Profile/Permission/PermissionInfo';
import WorkflowRequestReject from '../../WorkflowRequest/WorkflowRequestReject';
import SignatureKeyForm from '../../DocumentPreview/SignatureKeyForm';
import { downloadFile } from '../../../services/utils';
import WorkflowRequestRejectMasiveForm from '../../WorkflowRequest/WorkflowRequestRejectMasiveForm';

const PermissionDataTable = ({
  customParams,
  moreDataCenterModal,
  columns,
  preName,
  moreData,
  setMoreData,
  defaultStartDate = null,
  defaultEndDate = null,
  withMassActions
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  const isInitialMount = useRef(true);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalButtons, setModalButtons] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const [query, setQuery] = useState(false);
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const [isDisabled, setIsDisable] = useState(false);

  const dispatch = useDispatch();

  const handleRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams
    };
    setQuery(sendParams);
    const request = async () => {
      debounceIndexPermissionsRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data, metadata } = response.data;
          setWorkflowRequests(data);
          setAmount(metadata.amount);
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
    setOnRequest(false);
  };

  const cleanSelectedData = () => {
    setSelectedCount(0);
    setSelectedRows([]);
    setClearSelectedRows(!clearSelectedRows);
  };

  const handleSuccessAction = message => {
    handleModalClose();
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message }));
    cleanSelectedData();
  };

  const handleUpdateRequest = item => {
    setIsDisable(false);
    approvePermissionRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Solicitud aprobada con éxito')
    });
  };

  const rejectDocument = (values, setSubmitting) => {
    setOnRequest(true);
    const {
      workflowRequest: { record }
    } = values;

    rejectPermissionRequest(record.id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessAction('Solicitud rechazada con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const removePermission = item => {
    setOnRequest(true);
    deletePermissionRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => handleModalClose()
    });
  };

  const aproveWorfklow = workflowId => {
    const signDocument = signatureKey => {
      signWorkflowRequestRequest(workflowId, {
        dispatch,
        params: snakeCaseKeys(signatureKey),
        successCallback: () => handleSuccessAction('Solicitud aprobada con éxito')
      });
    };

    setModalButtons(false);
    setModalTitle('Firmar Documento');
    setModalBody(<SignatureKeyForm formRequest={signDocument} signatureKey="" />);
  };

  const handleActions = (item, action) => {
    setModalItem(item);
    switch (action) {
      case 'show':
        setModalTitle('Solicitud de Permiso');
        setModalBody(<PermissionInfo item={item} />);
        setCenterModalShow(true);
        break;
      case 'approved':
        if (item.request_reviewer_signature) {
          aproveWorfklow(item.workflow_request_id);
        } else {
          setModalButtons(true);
          setModalTitle('Aprobar Solicitud');
          setModalBody('¿Estás seguro que deseas aprobar esta solicitud?');
          setModalAction(() => handleUpdateRequest);
        }
        setModalShow(true);
        break;
      case 'destroy':
        setModalTitle('Eliminar Solicitud');
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
        setModalShow(true);
        setModalAction(() => removePermission);
        break;
      case 'rejected':
        setModalTitle('Rechazar Solicitud');
        setModalBody(
          <WorkflowRequestReject
            record={item}
            workflowRequest={item}
            cancelAction={handleModalClose}
            formRequest={rejectDocument}
          />
        );
        setCenterModalShow(true);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  const handleDownload = () => {
    setOnRequest(true);

    exportPermissionsRequest({
      dispatch,
      params: query,
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
      },
      callback: () => setOnRequest(false)
    });
  };

  const downloadModal = () => {
    setModalTitle('Exportar Permisos');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas descargar permisos?');
    setModalAction(() => handleDownload);
  };

  const massiveSingWorkflow = () => {
    const elementsSummaryWorkflowIds = selectedRows
      .filter(element => element.status === 'pending' && element.review_turn === true)
      .map(element => element.workflow_request_id);

    const massiveSignWorkflowRequest = ({ signatureKey }, setSubmitting) => {
      setOnRequest(true);
      massiveSingWorkflowRequestRequest({
        dispatch,
        params: {
          workflowsIds: elementsSummaryWorkflowIds,
          signatureKey
        },
        successCallback: () => {
          handleSuccessAction('Solicitud aprobada con éxito');
        },
        callback: () => {
          setSubmitting(false);
          setOnRequest(false);
        }
      });
    };

    setModalButtons(false);
    setModalTitle('Firmar/Aprobar Permisos');
    setModalBody(
      <SignatureKeyForm formRequest={massiveSignWorkflowRequest} onHide={handleModalClose} signatureKey="" />
    );
  };

  const handleMassiveRejectWorkflow = () => {
    const massiveRejectWorkflowRequest = ({ rejectObservation }) => {
      const elementsSummaryWorkflowIds = selectedRows
        .filter(element => element.status === 'pending' && element.review_turn === true)
        .map(element => element.workflow_request_id);

      massiveRejectWorkflowRequestRequest({
        dispatch,
        params: {
          workflows_ids: elementsSummaryWorkflowIds,
          observation: rejectObservation
        },
        successCallback: () => {
          handleSuccessAction('Solicitud Rechazada con éxito');
        }
      });
    };

    setModalButtons(false);
    setModalTitle('Rechazo Masivo');
    setModalBody(
      <WorkflowRequestRejectMasiveForm formRequest={massiveRejectWorkflowRequest} handleModalClose={handleModalClose} />
    );
  };

  const massiveApproveWorkflow = () => {
    const massiveApproveWorkflowRequest = () => {
      setIsDisable(true);
      const elementsSummaryWorkflowIds = selectedRows
        .filter(element => element.status === 'pending' && element.review_turn === true)
        .map(element => element.workflow_request_id);

      massiveSingWorkflowRequestRequest({
        dispatch,
        params: {
          workflowsIds: elementsSummaryWorkflowIds
        },
        successCallback: () => {
          handleSuccessAction('Solicitud aprobada con éxito');
        }
      });
    };

    setModalButtons(true);
    setModalTitle('Aprobar Permisos');
    setModalBody('¿Estás seguro que deseas aprobar estas solicitudes?');
    setModalAction(() => massiveApproveWorkflowRequest);
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    const elementsSummary = selectedRows.filter(
      element => element.status === 'pending' && element.review_turn === true
    );

    switch (action) {
      case 'massAprove':
        if (elementsSummary.length > 0) {
          const elementsSummarySignNeeded = elementsSummary.some(
            element => element.request_reviewer_signature === true
          );

          if (elementsSummarySignNeeded) {
            massiveSingWorkflow();
          } else {
            massiveApproveWorkflow();
          }

          setModalShow(true);
        }

        break;
      case 'import':
        setModalTitle('Importar Permisos');
        setModalBody(
          <ImportModal
            onDropUploaded={preImportPermissionRequest}
            handleTemplate={importTemplatePermissionRequest}
            onHide={importPermissionRequest}
            hideModal={() => setCenterModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setCenterModalShow(true);
        break;
      case 'export':
        downloadModal();
        break;
      case 'massReject':
        if (elementsSummary.length > 0) {
          handleMassiveRejectWorkflow();
          setModalShow(true);
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleSelectedRows = item => {
    setSelectedCount(item.selectedCount);
    setSelectedRows(item.selectedRows);
  };

  const handleOnUpdate = () => {
    if (isInitialMount.current) isInitialMount.current = false;
    else handleRequest();
  };

  useEffect(handleOnUpdate, [moreData, moreDataCenterModal]);

  return (
    <>
      <ComponentDataTable
        clearSelectedRows={clearSelectedRows}
        columns={columns(handleActions, ability)}
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        data={workflowRequests}
        handleSortCase={sortColumnCase}
        massActions={
          <>
            {ability.can('export', 'Permission') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Exportar"
                onClick={() => handleMassAction('export')}
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )}
            {ability.can('import', 'Permission') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Importar"
                onClick={() => handleMassAction('import')}
              >
                <Icon icon="cloud-upload-outline" />
              </ButtonTooltip>
            )}
            {ability.can('workflow_action', 'Permission') && (
              <DocumentActions handleClick={handleMassAction} disabled={!selectedCount || onRequest} />
            )}
          </>
        }
        moreData={moreData}
        onRequest={onRequest}
        onRowClicked={item => handleActions(item, 'show')}
        onSelectedRowsChange={handleSelectedRows}
        pointerOnHover
        preName={preName || 'permission'}
        rangePicker
        resourceRequest={handleRequest}
        selectableRows={withMassActions}
        totalRows={amount}
        withStartDate
        withEndDate
        withMassActions={withMassActions}
      />
      <SimpleCenteredModal
        title={modalTitle}
        size="md"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        withConfirm={modalButtons}
        withClose={modalButtons}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest || isDisabled}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default PermissionDataTable;
