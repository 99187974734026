import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, Nav, Row, Tab, Table } from 'react-bootstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';

import { Icon, LinkBtn } from '../../components';
import { indexBalancesRequest } from '../../requests/balances';
import { indexScheduledGroupsRequest } from '../../requests/scheduledGroups';
import { useSetTab } from '../../services/hooks';
import { camelCaseEmptyStringRecursive, groupBy } from '../../services/utils';
import PayrollBalanceTable from './PayrollBalanceTable';

const PayrollBalanceForm = ({ action, payrollGroup }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [balances, setBalances] = useState([]);
  const [scheduledBalances, setScheduledBalances] = useState([]);
  const [key, setKey] = useSetTab('asset', location.pathname);

  const fetchBalances = () => {
    indexBalancesRequest({
      dispatch,
      params: { filter_default_to_show: 'prenomine' },
      successCallback: response => {
        const { data } = camelCaseEmptyStringRecursive(response.data);
        const balanceGroups = groupBy(data, 'balanceType');
        setBalances(balanceGroups);
      }
    });
  };

  const fetchScheduledGroups = () => {
    if (action !== 'new' || !payrollGroup.groupDate) return;
    indexScheduledGroupsRequest({
      dispatch,
      params: { scheduled_balances_date: payrollGroup.groupDate },
      successCallback: response => {
        const scheduledGroups = camelCaseEmptyStringRecursive(response.data.data);
        const allScheduledBalances = scheduledGroups.map(obj => obj.scheduledBalancesAttributes);
        setScheduledBalances(allScheduledBalances.flat());
      }
    });
  };

  const dataNotFound = () => {
    return (
      <tbody>
        <tr className="d-flex justify-content-center text-uppercase">
          <td style={{ color: '#959595' }}>No se encontraron datos.</td>
        </tr>
      </tbody>
    );
  };

  const balancesTable = type => {
    const allBalances = balances.find(pb => pb[type]?.length);
    if (!allBalances) return <Table responsive>{dataNotFound()}</Table>;

    const balancesByType = allBalances[type];
    const assets = balances.find(pb => pb.asset?.length);

    return (
      <PayrollBalanceTable
        action={action}
        balances={balancesByType}
        dataNotFound={dataNotFound}
        payrolls={payrollGroup.payrollsAttributes}
        scheduledBalances={scheduledBalances}
        startIndex={type === 'asset' ? 0 : assets?.asset?.length || 0}
      />
    );
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchBalances, []);
  useEffect(fetchScheduledGroups, [payrollGroup]);

  return (
    <Form>
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn
            block
            variant="circle-dark"
            to={`/payroll_processes/${payrollGroup.payrollProcessId}/payroll_groups/${payrollGroup.id}/edit`}
          >
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={7} className="mt-3">
          <h2 className="text-uppercase my-1">Nueva Prenómina</h2>
          <h4 className="text-uppercase my-0">
            {payrollGroup.month} {payrollGroup.year}
          </h4>
        </Col>
        <Col md={2} className="mt-3">
          <LinkBtn
            block
            variant="outline-info"
            to={`/payroll_processes/${payrollGroup.payrollProcessId}/payroll_groups/${payrollGroup.id}/edit`}
          >
            Volver
          </LinkBtn>
        </Col>
        <Col md={2} className="mt-3">
          <Button block type="submit" variant="primary">
            Guardar
          </Button>
        </Col>
      </Row>

      <Tab.Container id="payroll-datatables.tabs" activeKey={key} onSelect={k => setKey(k)}>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="asset">Haberes</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="discount">Descuentos</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="table-custom-background">
          <Tab.Pane key="pb-body-asset" eventKey="asset" className="mt-2 mb-5">
            {balancesTable('asset')}
          </Tab.Pane>
          <Tab.Pane key="pb-body-discount" eventKey="discount" className="mt-2 mb-5">
            {balancesTable('discount')}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Form>
  );
};

const setInitialValues = () => {
  return { payrollBalances: [] };
};

const validationSchema = Yup.object().shape({
  payrollBalances: Yup.array().of(Yup.object().shape({}))
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PayrollBalanceForm);
