export default {
  name: '',
  description: '',
  endDate: '',
  endText: '',
  introText: '',
  startDate: '',
  employeeSurveys: [],
  questionsAttributes: [
    {
      questionType: 'binary',
      label: '',
      position: 0,
      results: [],
      questionChoicesAttributes: [
        { label: 'Sí', position: 0 },
        { label: 'No', position: 1 }
      ]
    }
  ]
};
