import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { createPerformanceEvaluationRequest } from '../../requests/performanceEvaluations';
import { sendAlert } from '../../actions/utils';
import PerformanceEvaluationForm from './PerformanceEvaluationForm';
import performanceEvaluation from './performanceEvaluation';

const PerformanceEvaluationNew = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (performanceEvaluationValues, setSubmitting) => {
    createPerformanceEvaluationRequest({
      dispatch,
      params: snakeCaseKeys(performanceEvaluationValues),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Evaluación creada con éxito' }));
        history.push(`/performance_evaluations`);
      },
      callback: () => setSubmitting(false)
    });
  };

  const toggleStep = () => {
    setStep(step === 1 ? 2 : 1);
  };

  return (
    <>
      <Row className="m-top mb-4">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" to="/performance_evaluations">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase">Nueva Evaluación</h2>
        </Col>
        <Col md={2}>
          <Button variant="info" block onClick={toggleStep}>
            {step === 1 ? 'Siguiente' : 'Volver'}
          </Button>
        </Col>
      </Row>
      <PerformanceEvaluationForm
        performanceEvaluation={performanceEvaluation}
        action="new"
        step={step}
        setStep={setStep}
        formRequest={handleCreateRequest}
      />
    </>
  );
};

export default PerformanceEvaluationNew;
