import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, DatatableEmployeeName } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1.5'
  },
  {
    name: 'CURSO',
    selector: 'course',
    cell: item => item.course_name,
    sortable: true,
    grow: '1.5'
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ADMISIÓN',
    selector: 'admission',
    cell: item => <ApprovementStatus status={item.admission} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    sortable: true,
    grow: '1'
  }
]);

export default columns;
