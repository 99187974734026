import React from 'react';
import { Row, Col } from 'react-bootstrap';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, ActiveDot, LinkBtn } from '../../../components';
import CompanyDataTable from '../../Shared/Company/CompanyDataTable';

const columns = memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RAZÓN SOCIAL',
    selector: 'business_name',
    sortable: true,
    grow: '3'
  },
  {
    name: 'IDENTIFICACIÓN',
    selector: 'national_identification',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      const colors = { pending: 'warning', accepted: 'primary', rejected: 'danger' };
      return (
        <p data-tag="allowRowEvents" className={`mt-3 text-${colors[item.status]}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
        >
          <Icon icon="pencil" />
        </ButtonTooltip>

        <ButtonTooltip
          onClick={() => clickHandler(item, 'archive')}
          variant={`circle-${item.active ? 'danger' : 'success'}`}
          className="btn-circle"
          size="sm"
          text={`${item.active ? 'Desactivar' : 'Activar'}`}
        >
          <Icon icon="archive" />
        </ButtonTooltip>
      </>
      //   <ButtonTooltip
      //     onClick={() => clickHandler(item, 'destroy')}
      //     variant="circle-danger"
      //     className="btn-circle"
      //     size="sm"
      //     text={destroyText}
      //   >
      //     <Icon style={destroyColor && { color: destroyColor }} icon={destroyIcon} />
      //   </ButtonTooltip>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const CompanyIndex = () => {
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">Empresas</h2>
        </Col>
        <Col md={3} xl={2}>
          <LinkBtn variant="primary" className="mt-3" block to="/admin/companies/new">
            Nuevo
          </LinkBtn>
        </Col>
      </Row>
      <CompanyDataTable admin columns={columns} />
    </>
  );
};

export default CompanyIndex;
