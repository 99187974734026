import React from 'react';
import { Card, Accordion, Col, Row } from 'react-bootstrap';

const AcademicInformation = ({ employee, activeAccordion, activeKey }) => {
  const { careers, languages, translatedEducationLevel, translatedSenceLevel } = employee;
  const careerNames = careers.map(career => career.label);
  const languagesNames = languages.map(language => language.label);

  return (
    <Card className="mr-2">
      <Col className={`${activeKey === 'academic-information' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle as={Col} eventKey="2" onClick={activeAccordion} className="card-header-title">
          INFORMACIÓN ACADÉMICA
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="2">
        <Card.Body className="div-content">
          <Row>
            <Col>
              <div className="info">
                <span className="type">Nivel educacional:</span>
                <span className="align-right" />
                <span className="answer"> {translatedEducationLevel}</span>
              </div>
              <div className="info">
                <span className="type">Profesión:</span>
                <span className="align-right" />
                <span className="answer"> {careerNames.join(', ')}</span>
              </div>
              {languagesNames.length > 0 && (
                <div className="info">
                  <span className="type">Idiomas:</span>
                  <span className="align-right" />
                  <span className="answer"> {languagesNames.join(', ')}</span>
                </div>
              )}
              <div className="info">
                <span className="type">Nivel Sence:</span>
                <span className="align-right" />
                <span className="answer"> {translatedSenceLevel}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AcademicInformation;
