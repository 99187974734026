import React from 'react';
import memoize from 'memoize-one';
import { DatatableEmployeeName } from '../../components';

const columns = memoize(() => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'fullName',
    cell: item => <DatatableEmployeeName item={item} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'jobManagement',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'CARGO',
    selector: 'jobTitle',
    sortable: true,
    grow: '1',
    center: true
  }
]);

export default columns;
