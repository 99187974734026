import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const createDataLoadRequest = ({ dispatch, params, formData, successCallback, callback }) => {
  ApiService.request('post', '/data_loads/create_export.xlsx', {
    dispatch,
    params,
    responseType: 'text',
    formData,
    successCallback,
    callback
  });
};

export const lastDataLoadRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/data_loads/last_data_loads', {
    dispatch,
    params,

    failureCallback,
    successCallback
  });
};

export const debounceLastDataLoadRequest = AwesomeDebouncePromise(lastDataLoadRequest, 300);
export default createDataLoadRequest;
