import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import MarkingCorrectionDatatable from './MarkingCorrectionDatatable';

import columns from './columns';

const MarkingCorrectionIndex = () => {
  const [moreData, setMoreData] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-4 mt-4">Corrección de Marcación</h2>
        </Col>
      </Row>
      <MarkingCorrectionDatatable
        columns={columns}
        moreData={moreData}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default MarkingCorrectionIndex;
