/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikSelect, FormikTimePicker, FormikDatePicker } from '../index';
import timeTypes from './formOptions';
import { delayMethod } from '../../services/utils';

const AttendanceReplaceForm = props => {
  const { errors, touched, setFieldValue, setFieldTouched, values, handleModalClose, onHide } = props;
  const { attendance: vAttendance } = values;
  const { timeType: vTimeType } = vAttendance;
  const attRecord = JSON.parse(JSON.stringify(props.attendance))

  return (
    <Form>
      <Modal.Body>
        <Row>
          <h5>Asistencia a Reemplazar</h5>
        </Row>
        <Row>
          <Col md={4}>
            Fecha:
            {attRecord.date}
          </Col>
          <Col md={4}>
            Hora:
            {attRecord.completeParsedTime}
          </Col>
          <Col md={4}>
            Tipo de Hora:
            {attRecord.translatedTimeType}
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          <h5>Nueva Asistencia</h5>
        </Row>
        <Row>
          <Col md={4}>
            <Field name="attendance[date]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={() => false}
                  label="Fecha"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[completeParsedTime]">
              {({ field }) => (
                <FormikTimePicker
                  {...field}
                  abbr
                  label="Hora"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeFormat="h:mm:ss aa"
                  placeholder="9:00:00 AM"
                  onChange={date => {
                    setFieldValue(
                      `attendance[time]`,
                      date.toLocaleTimeString('en-ES')
                    );
                    setFieldValue(
                      field.name,
                      date.toLocaleTimeString('en-ES')
                    );
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[timeType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Hora"
                  placeholder="Seleccionar Tipo de Hora"
                  options={timeTypes}
                  defaultValue={vTimeType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" onClick={onHide}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { attendance } = props;

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    date: Yup.string().required('Debes escoger una fecha'),
    time: Yup.string().required('Debes escoger la hora'),
    timeType: Yup.string().required('Debes escoger el tipo de hora'),
    employeeId: Yup.string().required('Debe haber un empleado asociado')
  })
});

const handleSubmit = (values, { props }) => {
  // console.log(values);
  // console.log(props);
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(AttendanceReplaceForm);
