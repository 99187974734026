import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  BasicTextArea,
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  InputSelect,
  NestedAttributes,
  Genders
} from '../../../components';

import { relationTypes, identificationTypes } from './FormOptions';
import { debounceIndexCountriesRequest, indexCountriesRequest } from '../../../requests/countries';
import { rutFormat, validRutInput } from '../../../services/utils';
import { useAuthorization } from '../../../services/hooks';

const FamilyGroupInput = ({
  errors,
  index,
  setFieldValue,
  setFieldTouched,
  touched,
  defaultValue,
  defaultOptions,
  familyGroupsAttribute,
  fetchCountries,
  handleNationalIdentificationFormat
}) => {
  const countryFieldName = `employee[familyGroupsAttributes][${index}][country][label]`;
  const { relationType, charge, identificationType } = familyGroupsAttribute;
  return (
    <Col>
      <Row>
        <Col md={12}>
          <Field name={`employee[familyGroupsAttributes][${index}][relationType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Relación"
                placeholder="Seleccionar Relación"
                options={relationTypes}
                defaultValue={relationType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombres"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][firstLastName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Primer Apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][secondLastName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Segundo Apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][identificationType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                defaultValue={identificationType}
                label="Tipo de Identificación"
                options={identificationTypes}
                placeholder="Seleccionar Tipo de Identificación"
                tooltipText=""
                onChange={data => {
                  setFieldValue(`employee[familyGroupsAttributes][${index}][nationalIdentification]`, '');
                  setFieldValue(field.name, data ? data.value : 'RUT');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][nationalIdentification]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={identificationType === 'rut' ? 'RUT' : 'Nº de Identificación'}
                onChange={e => handleNationalIdentificationFormat(e, identificationType === 'rut')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][countryId]`}>
            {({ field }) => (
              <InputSelect
                {...field}
                abbr
                label="Nacionalidad"
                placeholder="Seleccionar Nacionalidad"
                defaultOptions={defaultOptions}
                value={defaultValue}
                request={fetchCountries}
                onChange={data => {
                  setFieldValue(field.name, data.value);
                  setFieldValue(countryFieldName, data.label);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[familyGroupsAttributes][${index}][birthdate]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={day => moment().diff(day) < 0}
                label="Fecha de Nacimiento"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Genders modelKey={`employee[familyGroupsAttributes][${index}]`} md={4} />
        <Col md={4} className="mt-md-4 pt-md-2">
          <Field name={`employee[familyGroupsAttributes][${index}][charge]`}>
            {({ field }) => <FormikCheckBox {...field} field={field} label="¿Es Carga?" />}
          </Field>
        </Col>
        {charge && (
          <>
            <Col md={4}>
              <Field name={`employee[familyGroupsAttributes][${index}][chargeStartDate]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    isOutsideRange={() => false}
                    label="Fecha de Inicio"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name={`employee[familyGroupsAttributes][${index}][chargeEndDate]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    isOutsideRange={() => false}
                    label="Fecha de Término"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col md={12}>
          <Field name={`employee[familyGroupsAttributes][${index}][observation]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Observación"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

const FamilyGroupTab = ({ errors, touched, values, setFieldValue, setFieldTouched }) => {
  const [initialCountries, setInitialCountries] = useState([]);
  const { familyGroupsAttributes } = values.employee;
  const dispatch = useDispatch();
  const createFamilyGroup = useAuthorization('create', 'FamilyGroup');
  const destroyFamilyGroup = useAuthorization('destroy', 'FamilyGroup');

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: element.label,
      value: element.value
    }));
  };

  const fetchInitialCountries = () => {
    indexCountriesRequest({
      dispatch,
      params: {
        sort_column: 'position',
        display_length: 100
      },
      successCallback: response => setInitialCountries(resultFetchData(response))
    });
  };

  useEffect(fetchInitialCountries, []);

  const fetchCountries = (inputValue, callback) => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'position',
        display_length: 250
      },
      successCallback: response => {
        callback(resultFetchData(response));
      }
    });
  };

  const initialCountryValue = value => {
    const { familyGroupsAttributes: attributes } = values.employee;

    if (value && value.id) {
      const vAttribute = attributes.filter(e => {
        return value.countryId === e.countryId;
      });
      if (vAttribute.length) {
        return { label: vAttribute[0].country.label, value: value.countryId };
      }
    }

    return undefined;
  };

  const handleNationalIdentificationFormat = (e, isRut) => {
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const setNewfamilyGroup = () => {
    if (!values.newFamilyGroup) setFieldValue('employee[newFamilyGroup]', true);
  };

  const familyGroupsInputs = () => {
    const mapResults = familyGroupsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <FamilyGroupInput
          key={`family-group-${index.toString()}`}
          defaultOptions={initialCountries}
          defaultValue={initialCountryValue(body)}
          familyGroupsAttribute={body}
          fetchCountries={fetchCountries}
          handleNationalIdentificationFormat={handleNationalIdentificationFormat}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          index={index}
        />
      );
    });

    mapResults.push(
      <Col>
        <Row>
          <Col className="sample-row">
            <FormikSelect label="Relación" placeholder="Seleccionar Relación" isDisabled />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={familyGroupsAttributes}
          setFieldValue={setFieldValue}
          actionOnAdd={setNewfamilyGroup}
          valuePath="employee[familyGroupsAttributes]"
          newAttributes={{
            birthdate: '',
            charge: false,
            countryId: '',
            firstLastName: '',
            genderId: '',
            name: '',
            nationalIdentification: '',
            observation: '',
            relationType: '',
            secondLastName: '',
            identificationType: ''
          }}
          canCreate={createFamilyGroup}
          canDestroy={destroyFamilyGroup}
        />
      </>
    );
  };

  return familyGroupsInputs();
};

export const yupFamilyGroup = Yup.object().shape({
  birthdate: Yup.string()
    .required('Debes seleccionar una fecha de nacimiento')
    .typeError('Debes ingresar una fecha de nacimiento válida'),
  charge: Yup.boolean(),
  chargeEndDate: Yup.string().when('charge', {
    is: val => val === true,
    then: Yup.string()
      .required('Debes ingresar una fecha de término')
      .typeError('Debes ingresar una fecha de término válida'),
    otherwise: Yup.string().nullable()
  }),
  chargeStartDate: Yup.string().when('charge', {
    is: val => val === true,
    then: Yup.string()
      .required('Debes ingresar una fecha de inicio')
      .typeError('Debes ingresar una fecha de inicio válida'),
    otherwise: Yup.string().nullable()
  }),
  countryId: Yup.string().required('Debes seleccionar una nacionalidad'),
  firstLastName: Yup.string()
    .required('Debes ingresar un apellido')
    .max(120, 'Deben ser menos que 120 caracteres')
    .alphanumeric('Deben ser caracteres alfanuméricos'),
  identificationType: Yup.string().required('Debes seleccionar un tipo de documento'),
  name: Yup.string()
    .required('Debes ingresar un nombre')
    .max(120, 'Deben ser menos que 120 caracteres')
    .alphanumeric('Deben ser caracteres alfanuméricos'),
  nationalIdentification: Yup.string()
    .required('Debes ingresar un número de identificación')
    .when('identificationType', (identificationType, schema) =>
      identificationType === 'rut'
        ? schema.rut('Debes ingresar un RUT válido')
        : schema.alphanumeric('Deben ser caracteres alfanuméricos')
    ),
  observation: Yup.string()
    .alphanumeric('Deben ser caracteres alfanuméricos')
    .nullable(),
  relationType: Yup.string().required('Debes seleccionar una relación'),
  secondLastName: Yup.string()
    .max(120, 'Deben ser menos que 120 caracteres')
    .alphanumeric('Deben ser caracteres alfanuméricos')
    .nullable()
});

export default FamilyGroupTab;
