import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createDocumentTemplateRequest } from '../../requests/documentTemplates';
import { sendAlert } from '../../actions/utils';
import DocumentTemplateForm from './DocumentTemplateForm';

class DocumentTemplateNew extends Component {
  state = {
    documentTemplate: {
      body: '',
      description: '',
      name: '',
      orientation: '',
      size: '',
      templateType: ''
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCreateRequest = documentTemplate => {
    const { dispatch } = this.props;
    const activeTab = documentTemplate.documentTemplate.templateType;
    createDocumentTemplateRequest({
      dispatch,
      params: snakeCaseKeys(documentTemplate),
      formData: true,
      successCallback: () => this.handleSuccessCreate(activeTab)
    });
  };

  handleSuccessCreate = activeTab => {
    const { history, dispatch } = this.props;
    dispatch(sendAlert({ kind: 'success', message: 'Plantilla creada con éxito' }));
    history.push(`/document_templates/`, { activeTab });
  };

  render() {
    const { documentTemplate } = this.state;
    return (
      <>
        <Row className="mt-3 mb-3">
          <Col>
            <Row className="mt-2">
              <Col>
                <h2 className="mb-0 mt-2">Plantillas</h2>
              </Col>
            </Row>
            <DocumentTemplateForm
              documentTemplate={documentTemplate}
              action="new"
              submitVariant="primary"
              formRequest={this.handleCreateRequest}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { company } = state.auth;
  return { company };
};

export default connect(mapStateToProps)(DocumentTemplateNew);
