import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikCheckBox, FormikInput, FormikSelect, InputSelect } from '../../components';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import ineGroups from './FormOptions';

class JobTitleForm extends Component {
  resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  fetchJobManagements = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  // custom handle inputs
  handleNewJobManagement = (data, field) => {
    const { setFieldValue } = this.props;
    const newData = data.map(element => element.value);
    if (!newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  initialMultiDefaultValue = attribute => {
    const { jobTitle, values } = this.props;
    const { [`${attribute}Ids`]: vAttributeId } = values.jobTitle;
    const { [`${attribute}Ids`]: attributeId } = jobTitle;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      return getIn(jobTitle, `${attribute}s`);
    }
    return undefined;
  };

  render() {
    const {
      onHide,
      submitVariant,
      errors,
      touched,
      action,
      setFieldValue,
      setFieldTouched,
      jobTitle,
      isSubmitting
    } = this.props;
    const defaultJobManagements = this.initialMultiDefaultValue('jobManagement');
    const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
    const { ineGroup } = jobTitle;

    return (
      <Form>
        <Modal.Body>
          <Row>
            <Col md={10}>
              <Field name="jobTitle[name]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombre"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={2}>
              <Field name="jobTitle[code]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Código"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="jobTitle[description]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Descripción"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="jobTitle[jobManagementIds]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Áreas"
                    isMulti
                    value={defaultJobManagements}
                    placeholder="Seleccionar Áreas"
                    request={this.fetchJobManagements}
                    onChange={data => this.handleNewJobManagement(data || [], field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="jobTitle[ineGroup]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Agrupación INE"
                    placeholder="Seleccionar Agrupación INE"
                    options={ineGroups}
                    defaultValue={ineGroup}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isClearable
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="jobTitle[active]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" />}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn" type="submit" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
            {btnMessage}
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { id, active, name, code, description, ineGroup, jobManagementIds } = props.jobTitle;

  return {
    jobTitle: {
      id,
      active,
      code,
      description,
      ineGroup,
      name,
      jobManagementIds
    }
  };
};

const validationSchema = Yup.object().shape({
  jobTitle: Yup.object().shape({
    active: Yup.boolean(),
    code: Yup.string()
      .required('Debes ingresar un código')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    description: Yup.string()
      .alphanumeric('Deben ser caracteres alfanuméricos')
      .nullable(),
    jobManagementIds: Yup.array().nullable(),
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(connect()(JobTitleForm));
