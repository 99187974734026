import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { showAdminEmployeeRequest, updateAdminEmployeeRequest } from '../../../requests/adminEmployees';
import { sendAlert } from '../../../actions/utils';
import EmployeeTopView from '../../../components/Employee/EmployeeShow/EmployeeTopView';
import EmployeeForm from '../../Employee/EmployeeForm/EmployeeForm';
import basicEmployee from './employee';

const EmployeeEdit = ({ match }) => {
  const [employee, setEmployee] = useState(basicEmployee);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: employeeId } = match.params;

  const fetchEmployee = () => {
    showAdminEmployeeRequest(employeeId, {
      dispatch,
      successCallback: response => setEmployee(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchEmployee, []);

  const handleUpdateRequest = employeeValues => {
    const {
      employee: { image }
    } = employeeValues;

    updateAdminEmployeeRequest(employeeId, {
      dispatch,
      params: {
        employee: {
          ...snakeCaseKeys(employeeValues.employee, { exclude: ['_destroy'] }),
          image
        }
      },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Trabajador actualizado con éxito' }));
        history.push(`/admin/employees/${employeeId}`);
      }
    });
  };

  return (
    <>
      <EmployeeTopView employee={employee.employeeCard} returnTo="/admin/employees" />
      <EmployeeForm
        employeeId={employeeId}
        employee={employee}
        currentCompany={employee?.company?.id}
        action="edit"
        submitVariant="success"
        formRequest={handleUpdateRequest}
        admin
      />
    </>
  );
};

export default EmployeeEdit;
