import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPayrollGroupsRequest = (payrollProcessId, { dispatch, params = {}, callback, successCallback }) =>
  ApiService.request('get', `/payroll_processes/${payrollProcessId}/payroll_groups`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const showPayrollGroupRequest = (payrollProcessId, id, { dispatch, params = {}, callback, successCallback }) =>
  ApiService.request('get', `/payroll_processes/${payrollProcessId}/payroll_groups/${id}`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const createPayrollGroupRequest = (
  payrollProcessId,
  { dispatch, params, formData, callback, successCallback }
) => {
  ApiService.request('post', `/payroll_processes/${payrollProcessId}/payroll_groups`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });
};

export const updatePayrollGroupRequest = (
  payrollProcessId,
  id,
  { dispatch, params, formData, callback, successCallback }
) =>
  ApiService.request('put', `/payroll_processes/${payrollProcessId}/payroll_groups/${id}`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback,
    showLeafArrayIndexes: true
  });

export const deletePayrollGroupRequest = (payrollProcessId, id, { dispatch, callback, successCallback }) =>
  ApiService.request('delete', `/payroll_processes/${payrollProcessId}/payroll_groups/${id}`, {
    dispatch,
    callback,
    successCallback
  });

export const debounceIndexPayrollGroupsRequest = AwesomeDebouncePromise(indexPayrollGroupsRequest, 300);
