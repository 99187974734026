import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikInput, FormikRangePicker, FormikSelect, InputSelect } from '../../../components';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';

const LegalReportFilter = props => {
  const {
    errors,
    touched,
    setFieldValue,
    values,
    onRequest,
    isShiftType = false,
    filterRRHH = true,
    filterOffday = true
  } = props;
  const dispatch = useDispatch();

  const fetchEmployees = (inputValue, callback) => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchJobManagements = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchShifts = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => callback(response.data.data)
    });
  };
  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
  };

  const workSchedules = [
    { label: 'Jornada Completa (45 horas semanales)', value: 'full_time' },
    { label: 'Jornada Parcial', value: 'part_time' },
    { label: 'Articulo 22', value: 'article_22' }
  ];

  return (
    <Form>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner">
          <Spinner className="mb-4" animation="border" variant="primary" />
        </div>
      )}
      <div className={`${onRequest && 'bg-opacity'}`}>
        <Row>
          {filterOffday && (
            <Col xs={4} md={4}>
              <Field name="range_date">
                {({ field }) => (
                  <FormikRangePicker
                    {...field}
                    abbr
                    startDateName="date_from"
                    endDateName="date_to"
                    startDate={values.date_from}
                    endDate={values.date_to}
                    showClearDates
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          {filterRRHH && (
            <>
              <Col md={8}>
                <Field name="employees">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Colaboradores"
                      isMulti
                      placeholder="Seleccione colaboradores"
                      values={values.filter_ids}
                      request={fetchEmployees}
                      onChange={data => {
                        handleNewMulti(data || [], 'filter_ids', true);
                        setFieldValue(field.name, data || []);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>

              <Col xs={12} sm={8} md={6} xl={4}>
                <Field name="filter_national_id">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="RUT"
                      placeholder="Sin puntos (ej: 11111111-1)"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>

              <Col xs={12} sm={8} md={6} xl={4}>
                <Field name="job_managements">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Área"
                      isMulti
                      placeholder="Seleccione área"
                      values={values.filter_active_job_managements}
                      request={fetchJobManagements}
                      onChange={data => {
                        handleNewMulti(data || [], 'filter_active_job_managements', true);
                        setFieldValue(field.name, data || []);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>

              <Col xs={12} sm={8} md={6} xl={4}>
                <Field name="job_titles">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Cargo"
                      isMulti
                      placeholder="Seleccione cargo"
                      values={values.filter_active_job_titles}
                      request={fetchJobTitles}
                      onChange={data => {
                        handleNewMulti(data || [], 'filter_active_job_titles', true);
                        setFieldValue(field.name, data || []);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} sm={8} md={6} xl={4}>
                <Field name="branch_offices">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Lugar de Prestación de Servicios"
                      isMulti
                      placeholder="Seleccione Lugar de Prestación de Servicios"
                      values={values.filter_active_branch_offices}
                      request={fetchBranchOffices}
                      onChange={data => {
                        handleNewMulti(data || [], 'filter_active_branch_offices', true);
                        setFieldValue(field.name, data || []);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              {isShiftType && (
                <Col md={4}>
                  <Field name="filter_work_schedule">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Tipo de Jornada"
                        placeholder="Seleccionar Jornada"
                        options={workSchedules}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              )}
              <Col xs={12} sm={8} md={6} xl={4}>
                <Field name="shifts">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Turnos"
                      isMulti
                      placeholder="Seleccione Turno"
                      values={values.filter_active_shifts}
                      request={fetchShifts}
                      onChange={data => {
                        handleNewMulti(data || [], 'filter_active_shifts', true);
                        setFieldValue(field.name, data || []);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        <Row className="d-flex justify-content-end mt-4">
          <Col md={2}>
            <Button className="btn-block" type="submit" disabled={onRequest}>
              Filtrar
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  range_date: Yup.array().when('filter_offday', {
    is: true,
    then: Yup.array().rangedate(true)
  })
});

const setInitialValues = props => {
  const { filterOffday = true } = props;
  return {
    date_from: '',
    date_to: '',
    range_date: [],
    filter_ids: [],
    filter_national_id: '',
    filter_active_job_managements: [],
    filter_active_job_titles: [],
    filter_active_branch_offices: [],
    filter_active_shifts: [],
    filter_work_schedule: '',
    filter_offday: filterOffday
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(LegalReportFilter);
