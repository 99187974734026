import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';

import { FormikNumber } from '../../../components';

const SettlementPayForm = ({ settlement }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <h4 className="my-4">General</h4>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedPendingVacation]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                rightAddon="días"
                label="Feriados Pendientes"
                fieldName="settlement[pendingVacation]"
                value={settlement.pendingVacation}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedNonWorkingDays]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                rightAddon="días"
                label="Días Inhábiles"
                fieldName="settlement[nonWorkingDays]"
                value={settlement.nonWorkingDays}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedVacationToPay]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                rightAddon="días"
                label="Feriados a Pagar"
                fieldName="settlement[vacationToPay]"
                value={settlement.vacationToPay}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedYearsOfService]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                rightAddon="años"
                label="Años de Servicio"
                fieldName="settlement[yearsOfServices]"
                value={settlement.yearsOfServices}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>

      <h4 className="my-4">Base de Cálculos</h4>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedBaseYearsOfServices]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Años de Servicio y Mes de Aviso"
                fieldName="settlement[baseYearsOfServices]"
                value={settlement.baseYearsOfServices}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedBaseVacations]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Vacaciones"
                fieldName="settlement[baseVacations]"
                value={settlement.baseVacations}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedBaseDailyCompensation]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Remuneración Diaria"
                fieldName="settlement[baseDailyCompensation]"
                value={settlement.baseDailyCompensation}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>

      <h4 className="my-4">Cálculos</h4>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedPendingAnnualVacation]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Feriado Anual Pendiente"
                fieldName="settlement[pendingAnnualVacation]"
                value={settlement.pendingAnnualVacation}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedProportionalVacation]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Feriado Proporcional"
                fieldName="settlement[proportionalVacation]"
                value={settlement.proportionalVacation}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedCompensationYearsWorked]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Indemnización Años de Servicio"
                fieldName="settlement[compensationYearsWorked]"
                value={settlement.compensationYearsWorked}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedCompensationSubstitutePiorNotice]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label="Indemnización Sustitutiva de Aviso Previo"
                fieldName="settlement[compensationSubstitutePiorNotice]"
                value={settlement.compensationSubstitutePiorNotice}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name="settlement[parsedCompensationVoluntary]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                leftAddon="$"
                label="Indemnización Voluntaria"
                fieldName="settlement[compensationVoluntary]"
                value={settlement.compensationVoluntary}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={{ span: 3, offset: 6 }} lg={{ span: 2, offset: 7 }}>
          <p className="font-weight-normal mt-2">Subtotal</p>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedSubtotal]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[subtotal]"
                value={settlement.subtotal}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={{ span: 3, offset: 6 }} lg={{ span: 2, offset: 7 }}>
          <p className="font-weight-normal mt-2">Total Descuentos</p>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedTotalDiscount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[totalDiscount]"
                value={settlement.totalDiscount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={{ span: 3, offset: 6 }} lg={{ span: 2, offset: 7 }}>
          <h4 className="text-uppercase text-dark my-2">Total a pagar</h4>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedTotalToPay]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[totalToPay]"
                value={settlement.totalToPay}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default SettlementPayForm;
