import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './Style.scss';
import { recordsReport } from '../../requests/records';
import { indexEmployeesRequest } from '../../requests/employees';
import { downloadFile } from '../../services/utils';
import LegalReportFilter from './Filters/LegalReportFilter';

const AssistanceRecordDataTable = () => {
  const [filters, setFilters] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const handleRecordsReportRequest = async (params, format, type = 'json') => {
    const responseType = format === '.docx' ? 'blob' : type;
    recordsReport({
      dispatch,
      params: { date_range: params.range_date, ...params, paginate: false },
      format,
      responseType,
      successCallback: response => {
        if (format === '.xlsx' || format === '.docx') {
          downloadFile(response);
        }
        setOnRequest(false);
      }
    });
  };

  const handleRequest = async (params, format, responseType = 'json') => {
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date;
    indexEmployeesRequest({
      dispatch,
      params: { ...filters, ...empParams, active: true, is_dt: false },
      format,
      responseType,
      successCallback: () => handleRecordsReportRequest(params, '.xlsx', 'text')
    });
  };

  const handleFilters = params => {
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date;
    setFilters(empParams);
    setOnRequest(true);
    handleRequest(params);
  };

  return (
    <>
      <LegalReportFilter formRequest={handleFilters} isShiftType onRequest={onRequest} />
    </>
  );
};

export default AssistanceRecordDataTable;
