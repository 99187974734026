import React from 'react';
import PropTypes from 'prop-types';
import timeLineElements from './ExampleTimelineElements';
import './style.scss';

const BasicTimeline = ({ items }) => {
  return (
    <div className="timeline">
      {items.map(item => {
        const isEven = 'right';
        const isPending = item.status === 'pending' || item.status === 'waiting_another';

        return (
          <div
            key={item.key}
            className={`timelineElementCard ${isEven}   ${isPending ? '' : `timeline${item.status}Status`} `}
          >
            <div className="timeLineContent">
              <span> {item.title} </span>
              <p> {item.body} </p>
              <p className="text-right">
                {' '}
                <strong> {item.type} </strong> {isPending ? 'Pendiente' : item.dateTime}{' '}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

BasicTimeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      dateTime: PropTypes.string,
      key: PropTypes.number.isRequired
    })
  )
};

BasicTimeline.defaultProps = {
  items: timeLineElements
};

export default BasicTimeline;
