import ApiService from '../services/apiService';

const indexVoluntaryPensionFundsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/voluntary_pension_funds', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export default indexVoluntaryPensionFundsRequest;
