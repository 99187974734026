import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBookkeepersRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/bookkeepers${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createBookkeeperRequest = ({ dispatch, params, formData, callback, successCallback }) => {
  ApiService.request('post', '/bookkeepers', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });
};

export const updateBookkeeperRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/bookkeepers/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteBookkeeperRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/bookkeepers/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportBookkeepersRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/bookkeepers/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const preImportBookkeeperRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/bookkeepers/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importBookkeeperRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/bookkeepers/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateBookkeeperRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/bookkeepers/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const activeBookkeeperRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/bookkeepers/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateBookkeeperRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/bookkeepers/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexBookkeepersRequest = AwesomeDebouncePromise(indexBookkeepersRequest, 300);
