import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import moment from 'moment';

import { BasicTextArea, FormikNumber, FormikTimePicker, FormikSelect } from '../../../components';
import { delayMethod } from '../../../services/utils';

const LoanRequestForm = ({ errors, setFieldValue, setFieldTouched, touched, values, isSubmitting, ...props }) => {
  const { handleModalClose, newLoan, onHide } = props;
  const [grantDate, setGrantDate] = useState('');

  const { loan: vLoan } = values;
  const { amount, parsedAmount, installments } = vLoan;
  const btnMessage = newLoan ? 'Solicitar' : 'Confirmar';
  const today = new Date();
  const quota = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 }
  ];

  return (
    <Form className="mx-3">
      <Row>
        <Col md={4}>
          <Field name="loan[parsedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                leftAddon="$"
                fieldName="loan[amount]"
                value={amount}
                setFieldValue={setFieldValue}
                label="Monto Préstamo"
                errors={errors}
                touched={touched}
                disabled={!newLoan}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="loan[installments]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Número de Cuotas"
                placeholder="Seleccionar Número de Cuotas"
                options={quota}
                defaultValue={installments}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="loan[installmentAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                fieldName="loan[installmentAmount]"
                value={Math.round(parseFloat(parsedAmount.replace(/[.$]/g, '')) / installments)}
                setFieldValue={setFieldValue}
                label="Monto Cuota"
                errors={errors}
                touched={touched}
                disabled={newLoan}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="loan[grantDate]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                setLocale
                minDate={new Date(today.getFullYear(), today.getMonth(), 1)}
                maxDate={new Date(today.getFullYear(), 12, 0)}
                label="Fecha Otorgamiento Crédito"
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                selected={values.loan.grantDate}
                onChange={date => {
                  setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
                  setFieldValue('loan[startDate]', '');
                  setGrantDate(
                    date !== null
                      ? moment(date)
                          .add(1, 'months')
                          .startOf('month')
                      : ''
                  );
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={!newLoan}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="loan[startDate]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                setLocale
                label="Fecha Primer Descuento"
                minDate={new Date(moment(grantDate, 'D-MM-YYYY'))}
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                selected={values.loan.startDate}
                onChange={date => setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={!newLoan}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="loan[reason]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Motivo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={!newLoan}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mt-3 mb-2">
        {!newLoan && (
          <Col xs={6} sm={4}>
            <Button block variant="outline-info" onClick={() => delayMethod(handleModalClose)} disabled={isSubmitting}>
              Cancelar
            </Button>
          </Col>
        )}
        <Col xs={6} sm={4}>
          <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { loan, reqAmountLimit } = props;
  return {
    loan: {
      ...loan,
      reqAmountLimit,
      startDate: loan.parsedStartDate,
      installments: loan.installments || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  loan: Yup.object().shape({
    amount: Yup.number()
      .required('Debes ingresar un monto')
      .positive('Debe ser mayor a 0')
      .when('reqAmountLimit', (reqAmountLimit, schema) =>
        // Revisar si funciona
        reqAmountLimit !== 0 ? schema.max(reqAmountLimit, `Monto máximo: ${reqAmountLimit}`) : schema.nullable()
      ),
    grantDate: Yup.date().required('Debes ingresar la fecha del otorgamiento del crédito'),
    installments: Yup.number().required('Debes ingresar cantidad de cuotas'),
    startDate: Yup.date().required('Debes ingresar la fecha del primer descuento'),
    reason: Yup.string()
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(LoanRequestForm);
