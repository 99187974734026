export const typeDocumentImed = [
  { label: 'Liquidación de Sueldo', value: '1' },
  { label: 'Cotización', value: '2' },
  { label: 'Contrato', value: '3' },
  { label: 'Otro', value: '4' }
];

export const processingCodList = [
  { label: 'No se tramita en CCAF', value: 10100 },
  { label: 'Se tramita en CCAF 18 de septiembre', value: 10101 },
  { label: 'Se tramita en CCAF Los Andes', value: 10102 },
  { label: 'Se tramita en CCAF Gabriela Mistral', value: 10103 },
  { label: 'Se tramita en CCAF La Araucana', value: 10105 },
  { label: 'Se tramita en CCAF Los Heroes', value: 10106 },
  { label: 'Se tramita en Asociación Chilena de Seguridad', value: 11 },
  { label: 'Se tramita en Instituto de Seguridad del Trabajo', value: 12 },
  { label: 'Se tramita en Mutual de Seguridad de la CChC', value: 13 },
  { label: 'Se tramita en Instituto de Seguridad Laboral', value: 21 },
  { label: 'Se tramita en LCO División Andina', value: 31 },
  { label: 'Se tramita en LCO División Norte', value: 32 },
  { label: 'Se tramita en División el Teniente', value: 33 },
  { label: 'Se tramita en División el Salvador', value: 34 },
  { label: 'Se tramita en Pontificia Universidad de Chile', value: 41 }
];

export const typeLicense = [
  { label: 'Enfermedad o Accidente Común', value: 1 },
  { label: 'Prórroga Medicina Preventiva', value: 2 },
  { label: 'Licencia Maternal PRE y POST Natal', value: 3 },
  { label: 'Enfermedad Grave de Hijo Menor de 1 Año', value: 4 },
  { label: 'Accidente del Trabajo o de Trayecto', value: 5 },
  { label: 'Enfermedad Profesional', value: 6 },
  { label: 'Patología del Embarazo', value: 7 }
];

export const statusLicence = [
  { label: 'Otorgada Profesional', value: 1 },
  { label: 'Anulada', value: 2 },
  { label: 'No Tramitada', value: 3 },
  { label: 'No Recepcionada', value: 4 },
  { label: 'Tramitada', value: 5 },
  { label: 'Devuelta', value: 6 },
  { label: 'Pronunciada', value: 7 },
  { label: 'Liquidada', value: 8 },
  { label: 'Copia Impresa Recepcionada en OA', value: 10 },
  { label: 'Copia Impresa Recepcionada en CCAF', value: 11 },
  { label: 'Copia Impresa Recepcionada en COMPIN', value: 12 },
  { label: 'No Tramitada para AO', value: 30 },
  { label: 'No Tramitada para CCAF', value: 31 },
  { label: 'No Recepcionada para AO', value: 40 },
  { label: 'No Recepcionada para CCAF', value: 41 },
  { label: 'Tramitada para AO', value: 50 },
  { label: 'Tramitada para CCAF', value: 51 },
  { label: 'Validada por CCAF', value: 52 },
  { label: 'Validada por CCAF sin Todos los Antecedentes', value: 53 },
  { label: 'Validada por CCAF - Plazo de CCAF Vencido', value: 54 },
  { label: 'Zona C de Copia Impresa Consolidad', value: 55 },
  { label: 'Devuleta por AO', value: 60 },
  { label: 'Devuelta por CCAF', value: 62 },
  { label: 'Devuelta por Entidad Pagadora', value: 63 },
  { label: 'Derivada a otro AO', value: 65 },
  { label: 'Calificada Origen Laboral', value: 70 },
  { label: 'Pendiente de Clasificación', value: 71 },
  { label: 'Pronunciada para Pago por CCAF', value: 72 },
  { label: 'Calificada Origen Común', value: 77 }
];

export const occupation = [
  { label: 'Ejecutivo o Directivo', value: 11 },
  { label: 'Profesor', value: 12 },
  { label: 'Otro Profesional', value: 13 },
  { label: 'Tecnico', value: 14 },
  { label: 'Vendedor', value: 15 },
  { label: 'Administrativo', value: 16 },
  { label: 'Operario, Trabajador Manual', value: 17 },
  { label: 'Trabajador de Casa Particular', value: 18 },
  { label: 'Otro', value: 19 }
];

export const workerQuality = [
  { label: 'Trabajador Sector Público Afecto a la Ley 18.834', value: 1 },
  { label: 'Trabajador Sector Público No Afecto a la Ley 18.834', value: 2 },
  { label: 'Trabajador Dependiente Sector Privado', value: 3 }
];

export const reasonNotToReceive = [
  { label: 'Inexistencia de Relación Laboral', value: 1 },
  { label: 'Relación Laboral Terminada', value: 2 },
  { label: 'Trabajador con Permiso sin Goce de Sueldo', value: 3 },
  { label: 'Trabajador Sector Público con Feriado Legal', value: 4 },
  { label: 'Otras Razones', value: 5 }
];

export const typePensionScheme = [
  { label: 'D.L. 3501 INP', value: 1 },
  { label: 'D.L. 3500 A.F.P.', value: 2 }
];

export const workActivity = [
  { label: 'Agricultura, Servicios Agrícolas, Silvicultura y Pesca', value: 0 },
  { label: 'Minas, Petróleos y Canteras', value: 1 },
  { label: 'Industrias Manufactureras', value: 2 },
  { label: 'Construcción', value: 3 },
  { label: 'Electricidad, gas y agua', value: 4 },
  { label: 'Comercio', value: 5 },
  { label: 'Transporte, Almacenamiento y Comunicaciones', value: 6 },
  { label: 'Finanzas, Seguros Bienes Muebles y Servicios Técnicos, Profesionales y Otros', value: 7 },
  { label: 'Servicios Estatales, Sociales, Personales e Internacionales', value: 8 },
  { label: 'Actividad No Especificada', value: 9 }
];

export const socialSecurityCode = [
  { label: 'No cotiza A.F.P.', value: -1, type_p_s: 2 },
  { label: '03-CUPRUM', value: 1003, type_p_s: 2 },
  { label: '05-HABITAT', value: 1005, type_p_s: 2 },
  { label: '08-PROVIDA', value: 1008, type_p_s: 2 },
  { label: '32-PLANVITAL', value: 1032, type_p_s: 2 },
  { label: '33-CAPITAL', value: 1033, type_p_s: 2 },
  { label: '34-MODELO', value: 1034, type_p_s: 2 },
  { label: '35-UNO', value: 1035, type_p_s: 2 },
  { label: '1-C.PREV.EMP.PART. Y ORG. AUX.', value: 1, type_p_s: 1 },
  { label: '2-CAJA BANCARIA DE PENSIONES', value: 2, type_p_s: 1 },
  { label: '3-CAJA PREV. Y ESTIMULO EMP. BANCO CHILE', value: 3, type_p_s: 1 },
  { label: '4-SESSION DE PREVISION BANCO CENTRAL CHILE', value: 4, type_p_s: 1 },
  { label: '5-CAJA PREV. ESTIMULO BANCO ESTADO CHILE', value: 5, type_p_s: 1 },
  { label: '6-C.PREV. MAR.MERC.', value: 6, type_p_s: 1 },
  { label: '7-C.PREV. MAR.MERC.', value: 7, type_p_s: 1 },
  { label: '8-C.PREV. HIPICA', value: 8, type_p_s: 1 },
  { label: '9-SERV. SEG. SOC.', value: 9, type_p_s: 1 },
  { label: '10-C. PREV. EMP. METROP. OBRAS SANIT. DEP. EM.', value: 10, type_p_s: 1 },
  { label: '11-C. PREV. EMP. METROP. OBRAS SANIT. DEP. OB.', value: 11, type_p_s: 1 },
  { label: '12-C. PREV. FERROCARRILES.', value: 12, type_p_s: 1 },
  { label: '13-CANAEMPU', value: 13, type_p_s: 1 },
  { label: '14-CANAEMPU Y PER.', value: 14, type_p_s: 1 },
  { label: '15-CANAEMPU STGO.', value: 15, type_p_s: 1 },
  { label: '16-CAMUVAL', value: 16, type_p_s: 1 },
  { label: '17-CAPREMUR', value: 17, type_p_s: 1 },
  { label: '18-CAPRESOMO', value: 18, type_p_s: 1 }
];

export const socialSecurityCodeRemuneration = [
  {
    label: 'D.L. 3501 INP',
    options: [
      { label: '1-C.PREV.EMP.PART. Y ORG. AUX.', value: 1 },
      { label: '2-CAJA BANCARIA DE PENSIONES', value: 2 },
      { label: '3-CAJA PREV. Y ESTIMULO EMP. BANCO CHILE', value: 3 },
      { label: '4-SESSION DE PREVISION BANCO CENTRAL CHILE', value: 4 },
      { label: '5-CAJA PREV. ESTIMULO BANCO ESTADO CHILE', value: 5 },
      { label: '6-C.PREV. MAR.MERC.', value: 6 },
      { label: '7-C.PREV. MAR.MERC.', value: 7 },
      { label: '8-C.PREV. HIPICA', value: 8 },
      { label: '9-SERV. SEG. SOC.', value: 9 },
      { label: '10-C. PREV. EMP. METROP. OBRAS SANIT. DEP. EM.', value: 10 },
      { label: '11-C. PREV. EMP. METROP. OBRAS SANIT. DEP. OB.', value: 11 },
      { label: '12-C. PREV. FERROCARRILES.', value: 12 },
      { label: '13-CANAEMPU', value: 13 },
      { label: '14-CANAEMPU Y PER.', value: 14 },
      { label: '15-CANAEMPU STGO.', value: 15 },
      { label: '16-CAMUVAL', value: 16 },
      { label: '17-CAPREMUR', value: 17 },
      { label: '18-CAPRESOMO', value: 18 }
    ]
  },
  {
    label: 'D.L. 3500 A.F.P.',
    options: [
      { label: 'No cotiza A.F.P.', value: -1 },
      { label: '03-CUPRUM', value: 1003 },
      { label: '05-HABITAT', value: 1005 },
      { label: '08-PROVIDA', value: 1008 },
      { label: '32-PLANVITAL', value: 1032 },
      { label: '33-CAPITAL', value: 1033 },
      { label: '34-MODELO', value: 1034 },
      { label: '35-UNO', value: 1035 }
    ]
  }
];

export const letterBox = [
  { label: 'A - EMPL. PARTICULARES REGIMEN GENERAL', value: 'A', s_s_code: 1 },
  { label: 'B - EMPL.PART. AFECTOS ART. 14 FINAL LEY 1047', value: 'B', s_s_code: 1 },
  { label: 'C - CAJA PREV. E.P. S.S.S. INDM. OBR MOL PANI.', value: 'C', s_s_code: 1 },
  { label: 'D - CAJA P.E.P. S.S.S. INDM.OBR.MOL.P. 1047', value: 'D', s_s_code: 1 },
  { label: 'E - FUNCIONARIOS EX. SERV. NACIONAL DE SALUD', value: 'E', s_s_code: 1 },
  { label: 'F - FUNC. EX. SNS LIBERADOS IMPOSIC 10475', value: 'F', s_s_code: 1 },
  { label: 'A - REGIMEN GENERAL', value: 'A', s_s_code: 5 },
  { label: 'B - EX. FUNC. EX.CAJA ACCIDENTE DEL TRABAJO', value: 'B', s_s_code: 5 },
  { label: 'C - FUNC. SUPERINT. BANCOS INST. FINANCIERA', value: 'C', s_s_code: 5 },
  { label: 'A - NAC.OFIC.EMP. R. GENERAL', value: 'A', s_s_code: 6 },
  { label: 'B - EMP.BAHIA EVENT.DISCON', value: 'B', s_s_code: 6 },
  { label: 'C - EMP.PORTUARIA DESAHUCIO', value: 'C', s_s_code: 6 },
  { label: 'D - EMP.PORTUA. DESAH DC.33', value: 'D', s_s_code: 6 },
  { label: 'A - NACIONAL EMPLEADOS', value: 'A', s_s_code: 8 },
  { label: 'B - NACIONAL CUIDADORES CAB.', value: 'B', s_s_code: 8 },
  { label: 'A - REGIMEN GENERAL', value: 'A', s_s_code: 9 },
  { label: 'B - OBREROS MIN. OBRAS PUBLICAS', value: 'B', s_s_code: 9 },
  { label: 'A - R. GENERAL MENOS 10 AÑOS', value: 'A', s_s_code: 12 },
  { label: 'B - R. GENERAL 10 Y 20 AÑOS', value: 'B', s_s_code: 12 },
  { label: 'C - R. GENERAL MAS DE 20 AÑOS', value: 'C', s_s_code: 12 },
  { label: 'D - EMPL. CAJA MENOS 10 AÑOS', value: 'D', s_s_code: 12 },
  { label: 'E - EMPL. CAJA 10 Y 20 AÑOS', value: 'E', s_s_code: 12 },
  { label: 'F - EMPL. CAJA MAS 20 AÑOS', value: 'F', s_s_code: 12 },
  { label: 'G - EMPL. CAJA INGRESA+31-10-70', value: 'G', s_s_code: 12 },
  { label: 'A - REGIMEN GENERAL EMPLEADOS PUBLICOS', value: 'A', s_s_code: 13 },
  { label: 'B - EMPLEADOS SECTOR PRIVADO', value: 'B', s_s_code: 13 },
  { label: 'C - FUNC. SEMIFISC. Y DE CAJA A 31-10-70', value: 'C', s_s_code: 13 },
  { label: 'D - FUNC. EX SNS.', value: 'D', s_s_code: 13 },
  { label: 'E - FUNC. INST. SEGURO DEL ESTADO', value: 'E', s_s_code: 13 },
  { label: 'F - FUNC. UNIVERSIDAD CONCEPCION', value: 'F', s_s_code: 13 },
  { label: 'G - FUNC. E.T.C. DEL ESTADO', value: 'G', s_s_code: 13 },
  { label: 'H - FUNC. EMP. PORTUARIA DE CHILE', value: 'H', s_s_code: 13 },
  { label: 'I - FUNC. ENAP', value: 'I', s_s_code: 13 },
  { label: 'J - FUNC. ENAMI', value: 'J', s_s_code: 13 },
  { label: 'K - FUNC. NOTARIAS CONSU. ARCHIV.', value: 'K', s_s_code: 13 },
  { label: 'A - DEPT. PERIODISTAS R. GENERAL', value: 'A', s_s_code: 14 },
  { label: 'B - DEPT. PERIOD. GENERAL TOX. NOCT.', value: 'B', s_s_code: 14 },
  { label: 'C - PERIODISTAS', value: 'C', s_s_code: 14 },
  { label: 'D - PERIODISTAS TOXICO NOCTURNO', value: 'D', s_s_code: 14 },
  { label: 'E - TRAB. IMPRENTAS DE OBRAS', value: 'E', s_s_code: 14 },
  { label: 'F - TRAB. IMPRENTAS OBRAS TOX. NOCT.', value: 'F', s_s_code: 14 },
  { label: 'G - TRAB. IMP. OBR. AFC A. 33 L17322', value: 'G', s_s_code: 14 },
  { label: 'H - TRAB. IMP-OBR.33 L17322 NOC-TO', value: 'H', s_s_code: 14 },
  { label: 'A - R. GENERAL FUNC. PAVIM.', value: 'A', s_s_code: 15 },
  { label: 'B - EMPLEADOS DE LA CAJA', value: 'B', s_s_code: 15 },
  { label: 'C - EMP. CAJA OB. MUNIC. RE', value: 'C', s_s_code: 15 },
  { label: 'A - R. GENERAL', value: 'A', s_s_code: 16 },
  { label: 'B - EMPLEADOS DE LA CAJA', value: 'B', s_s_code: 16 },
  { label: 'A - REGIMEN GENERAL', value: 'A', s_s_code: 17 },
  { label: 'B - EMPLEADOS CAJA al 31-10-70', value: 'B', s_s_code: 17 },
  { label: 'C - EMPLEADOS CAJA  CON POSTERIORIDAD al 31-10-70', value: 'C', s_s_code: 17 },
  { label: 'A - REGIMEN GENERAL ', value: 'A', s_s_code: 18 },
  { label: 'B - IMP EX CAJA OOMM STGO.', value: 'B', s_s_code: 18 }
];

export const payingEntityCode = [
  { label: 'E - Servicio de Salud', value: 'E', type: 1 },
  { label: 'F - MUTUAL', value: 'F', type: 1 },
  { label: 'G - INP', value: 'G', type: 1 },
  { label: 'H - Empleador', value: 'H', type: 1 },
  { label: 'A - Servicio de Salud', value: 'A', type: 2 },
  { label: 'B - ISAPRE', value: 'B', type: 2 },
  { label: 'C - CCAF', value: 'C', type: 2 },
  { label: 'D - Empleador', value: 'D', type: 2 }
];
