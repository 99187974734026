import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexUserAdminRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/admin/users', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexUserRequest = AwesomeDebouncePromise(indexUserAdminRequest, 300);
