/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Icon from '../../Icons';
import { ButtonTooltip } from '../Tooltips';
import './style.scss';

const handleDelete = (arrayValues, setFieldValue, valuePath, index) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  const newValues = [...arrayValues.slice(0, index), ...arrayValues.slice(index + 1, arrayValuesLength)];

  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    newValues.push(deleteValue);
  }

  setFieldValue(`${valuePath}`, newValues);
};

const addRow = (arrayValues, setFieldValue, valuePath, newAttributes, actionOnAdd = undefined) => {
  const newValues = [...arrayValues, newAttributes];
  setFieldValue(`${valuePath}`, newValues);
  if (actionOnAdd) actionOnAdd();
};

const buttonRemoveItem = (arrayValues, setFieldValue, valuePath, index) => {
  return (
    <ButtonTooltip
      toolbarVariant="center-button"
      variant="circle-danger"
      className="btn-circle"
      size="sm"
      text="Eliminar"
      onClick={() => handleDelete(arrayValues, setFieldValue, valuePath, index)}
    >
      <Icon icon="trash" />
    </ButtonTooltip>
  );
};

const buttonAddItem = (arrayValues, setFieldValue, valuePath, newAttributes, actionOnAdd) => {
  return (
    <ButtonTooltip
      toolbarVariant="center-button"
      variant="circle-primary"
      className="btn-circle"
      size="sm"
      text="Nuevo"
      onClick={() => addRow(arrayValues, setFieldValue, valuePath, newAttributes, actionOnAdd)}
    >
      <Icon icon="add" />
    </ButtonTooltip>
  );
};

const NestedBody = ({
  arrayValues,
  body,
  deleteLastOne,
  index,
  inputLength,
  newAttributes,
  setFieldValue,
  shownLength,
  actionOnAdd,
  valuePath,
  removeFirstItem,
  canDestroy,
  canCreate,
  // rowClass,
  tdClass
}) => (
  <>
    {body && (
      <>
        <tr
        // className={rowClass}
        // onClick={
        //   index === inputLength
        //     ? () => addRow(arrayValues, setFieldValue, valuePath, newAttributes, actionOnAdd)
        //     : undefined
        // }
        >
          {body}
          <td className={tdClass}>
            {deleteLastOne
              ? canDestroy &&
                index === inputLength - 1 &&
                inputLength !== 0 &&
                shownLength !== 0 &&
                buttonRemoveItem(arrayValues, setFieldValue, valuePath, index)
              : canDestroy &&
                index !== inputLength &&
                (removeFirstItem || (inputLength !== 0 && shownLength > 1)) &&
                buttonRemoveItem(arrayValues, setFieldValue, valuePath, index)}
            {canCreate &&
              index === inputLength &&
              buttonAddItem(arrayValues, setFieldValue, valuePath, newAttributes, actionOnAdd)}
          </td>
        </tr>
      </>
    )}
  </>
);

const NestedAttributesTable = ({
  arrayValues,
  deleteLastOne,
  mapInputs,
  newAttributes,
  removeFirstItem,
  setFieldValue,
  actionOnAdd,
  valuePath,
  canDestroy = true,
  canCreate = true,
  tdClass,
  maxRow
}) => {
  const inputLength = mapInputs.length - 1;
  const shownLength =
    mapInputs.filter(e => {
      return e !== undefined;
    }).length - 1;
  const nestedBody = mapInputs.map((body, index) => (
    <>
      {maxRow ? (
        index < maxRow && (
          <NestedBody
            key={`nested-${index.toString()}`}
            arrayValues={arrayValues}
            body={body}
            deleteLastOne={deleteLastOne}
            removeFirstItem={removeFirstItem}
            index={index}
            inputLength={inputLength}
            setFieldValue={setFieldValue}
            shownLength={shownLength}
            actionOnAdd={actionOnAdd}
            valuePath={valuePath}
            newAttributes={newAttributes}
            canDestroy={canDestroy}
            canCreate={canCreate}
            tdClass={tdClass}
          />
        )
      ) : (
        <NestedBody
          key={`nested-${index.toString()}`}
          arrayValues={arrayValues}
          body={body}
          deleteLastOne={deleteLastOne}
          removeFirstItem={removeFirstItem}
          index={index}
          inputLength={inputLength}
          setFieldValue={setFieldValue}
          shownLength={shownLength}
          actionOnAdd={actionOnAdd}
          valuePath={valuePath}
          newAttributes={newAttributes}
          canDestroy={canDestroy}
          canCreate={canCreate}
          tdClass={tdClass}
        />
      )}
    </>
  ));

  return nestedBody;
};

export default NestedAttributesTable;
