import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import { Form, InputGroup, ButtonToolbar, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Icon from '../../Icons';
import './style.scss';

const FormikInput = ({
  abbr,
  addon,
  delayHide,
  delayShow,
  defaultValue,
  direction,
  disabled,
  error,
  formInline,
  formType,
  helpText,
  inputType,
  label,
  leftAddon,
  margin,
  minInputHeight,
  placeholder,
  rightBtn,
  rightBtnClickHandler,
  selectedPlace,
  size,
  toolbarVariant,
  tooltipIcon,
  tooltipRight,
  tooltipSize,
  tooltipText,
  touched,
  widthInput,
  ...props
}) => (
  <Form.Group
    className={`${formInline ? 'form-inline' : ''} ${disabled ? 'disabled' : ''} ${margin} ${
      error && touched ? 'is-invalid' : ''
    }`}
  >
    {label && (
      <>
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1 mr-1">*</abbr>}
          {tooltipText && !tooltipRight && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon={tooltipIcon} width={tooltipSize} />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      </>
    )}
    <InputGroup>
      {leftAddon && (
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
        </InputGroup.Prepend>
      )}
      {formType === 'addressAutocomplete' ? (
        <Autocomplete
          onPlaceSelected={place => {
            selectedPlace(place);
          }}
          defaultValue={defaultValue}
          placeholder=""
          types={['geocode']}
          disabled={disabled}
          className={`form-control ${disabled && 'input-disabled'}`}
          componentRestrictions={{ country: 'cl' }}
        />
      ) : (
        <Form.Control
          style={{ width: `${widthInput}px`, minHeight: `${minInputHeight}px` }}
          disabled={disabled}
          size={size}
          placeholder={placeholder}
          className={disabled && 'input-disabled'}
          type={inputType}
          {...props}
        />
      )}
      {addon && (
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">{addon}</InputGroup.Text>
        </InputGroup.Append>
      )}
      {rightBtn && (
        <InputGroup.Append>
          <Button id="basic-btn2" variant="outline-info" onClick={rightBtnClickHandler}>
            {rightBtn}
          </Button>
        </InputGroup.Append>
      )}
    </InputGroup>
    {tooltipText && tooltipRight && (
      <ButtonToolbar className="ml-2 mt-1">
        <OverlayTrigger
          key={direction}
          placement={direction}
          delay={{ show: delayShow, hide: delayHide }}
          overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
          <Icon icon={tooltipIcon} width={tooltipSize} />
        </OverlayTrigger>
      </ButtonToolbar>
    )}
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);
FormikInput.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  formInline: PropTypes.bool,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipSize: PropTypes.string,
  tooltipRight: PropTypes.bool
};

FormikInput.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  formInline: false,
  margin: '',
  toolbarVariant: '',
  tooltipText: '',
  tooltipIcon: 'help-circle',
  tooltipSize: '15',
  tooltipRight: false
};

export default FormikInput;
