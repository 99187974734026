import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import SlideForm from './SlideForm';
import { showSlideRequest, updateSlideRequest } from '../../requests/slides';

class SlideEdit extends Component {
  state = {
    slide: {
      id: '',
      fileInfo: {},
      position: '',
      caption: '',
      active: true
    }
  };

  componentDidMount() {
    const { match, dispatch } = this.props;
    const slideId = match.params.id;
    showSlideRequest(slideId, {
      dispatch,
      successCallback: response => this.handleSuccessShow(response)
    });
  }

  handleSuccessShow = response => {
    const slide = camelCaseRecursive(response.data);
    this.setState({ slide });
  };

  handleUpdateRequest = slide => {
    const { dispatch } = this.props;
    const slideId = slide.slide.id;
    updateSlideRequest(slideId, {
      dispatch,
      params: slide,
      formData: true,
      successCallback: response => this.handleSuccessUpdate(response)
    });
  };

  handleSuccessUpdate = response => {
    const { history } = this.props;
    const slide = response.data;
    history.push(`/slides/${slide.id}`);
  };

  render() {
    const { slide } = this.state;
    return (
      <>
        <Row className="mt-3">
          <Col>
            <h1>Editar Slide</h1>
          </Col>
          <Col md={2}>
            <LinkBtn variant="light" block to="/slides">
              Volver
            </LinkBtn>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <SlideForm
              slide={slide}
              action="edit"
              formRequest={this.handleUpdateRequest}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default connect()(SlideEdit);
