import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import EmployeeDncDatatableRow from './EmployeeDncDatatableRow';
import { debounceIndexTopicsRequest } from '../../requests/topics';

const EmployeeDncDatatable = ({ employees, boss, showCurrentEmployeeDnc }) => {
  const [topics, setTopics] = useState([]);
  const isEmpty = employees.length === 0;
  const dispatch = useDispatch();

  const resultFetchData = response => {
    return response.map(element => ({
      label: element.name,
      value: element.id,
      necessityAreas: element.necessity_areas
    }));
  };

  const fetchInitialTopics = () => {
    debounceIndexTopicsRequest({
      dispatch,
      params: {
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => setTopics(resultFetchData(response.data.data))
    });
  };

  useEffect(fetchInitialTopics, []);

  return (
    <>
      <div className="container-table-dnc">
        <div className={`scroll-table-dnc ${isEmpty && 'empty-data'}`}>
          <table className="table-dnc">
            <thead>
              <tr>
                <th>TRABAJADOR</th>
                <th>CARGO</th>
                <th>TEMA</th>
                <th>ÁREA DE NECESIDAD</th>
                <th>NIVEL PROFUNDIDAD</th>
                <th>NIVEL URGENCIA</th>
                <th>FECHA SUGERIDA</th>
                <th>ESTADO</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              {employees.length > 0 &&
                employees.map((employee, index) => {
                  if (employee && !employee._destroy) {
                    return (
                      <EmployeeDncDatatableRow
                        key={`employee-dnc-${index.toString()}`}
                        employee={employee}
                        index={index}
                        topics={topics}
                        setTopics={setTopics}
                        boss={boss}
                        showCurrentEmployeeDnc={showCurrentEmployeeDnc}
                      />
                    );
                  }
                  return undefined;
                })}
            </tbody>
          </table>
        </div>
        {isEmpty && <p className="text-center empty-msg">No se encontraron trabajadores para esta jefatura</p>}
      </div>
    </>
  );
};

export default EmployeeDncDatatable;
