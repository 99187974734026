import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner } from 'react-bootstrap';

import { updateOvertimeRequest } from '../../requests/overtimes';
import OvertimeForm from './OvertimeForm';

const OvertimeEdit = props => {
  const { overtime, handleSuccessAction } = props;
  const { id: overtimeId } = overtime;
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessUpdate = () => {
    const message = 'Hora Extra actualizada con éxito';
    setOnRequest(false);
    handleSuccessAction(message);
  };

  const handleFailureRequest = error => {
    const { handleFailureAction } = props;
    setOnRequest(false);
    handleFailureAction(error);
  };

  const handleUpdate = (overtimeValues, setSubmitting) => {
    setOnRequest(true);
    const params = snakeCaseKeys(overtimeValues);

    updateOvertimeRequest(overtimeId, {
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: error => {
        handleFailureRequest(error);
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <OvertimeForm overtime={overtime} action="edit" formRequest={handleUpdate} />
    </>
  );
};

export default OvertimeEdit;
