import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Container, Dropdown } from 'react-bootstrap';
import { NavbarLogged } from '../../components';
import { requestSignOut } from '../../actions/auth';

const LoggedSimpleLayout = ({ ...props }) => {
  const dispatch = useDispatch();
  const { component: Screen, ...otherProps } = props;
  const { signedIn } = useSelector(state => state.auth);
  return (
    <Route
      {...otherProps}
      render={rest =>
        signedIn ? (
          <>
            <NavbarLogged module="document_management">
              <Dropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</Dropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Screen {...rest} />
            </Container>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
        )
      }
    />
  );
};

export default LoggedSimpleLayout;
