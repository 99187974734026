import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Container, Dropdown } from 'react-bootstrap';
import { NavbarTop, NavbarLogged } from '../../components';
import { toogleSidebar } from '../../actions/utils';
import { requestSignOut } from '../../actions/auth';

class PublicLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const width = window.innerWidth;
    const isMobile = width <= 991;
    const { sidebarOpen, dispatch } = this.props;
    if (isMobile && sidebarOpen) {
      dispatch(toogleSidebar());
    } else if (!isMobile && !sidebarOpen) {
      dispatch(toogleSidebar());
    }
  }

  render() {
    const { dispatch, component: Screen, ...props } = this.props;
    return (
      <Route
        {...props}
        render={rest =>
          localStorage.getItem('jwt') ? (
            <>
              <NavbarLogged>
                <Dropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</Dropdown.Item>
              </NavbarLogged>
              <Container fluid>
                <Screen {...rest} />
              </Container>
            </>
          ) : (
            <>
              <NavbarTop />
              <Container fluid>
                <Screen {...rest} />
              </Container>
            </>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => {
  const { sidebarOpen } = state.utils;
  return {
    sidebarOpen
  };
};
export default connect(mapStateToProps)(PublicLayout);
