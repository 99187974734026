import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { sendAlert } from '../../actions/utils';
import CompanyTaskForm from '../Shared/CompanyTask/CompanyTaskForm';
import { createCompanyTaskRequest } from '../../requests/companyTasks';
import task from '../Shared/CompanyTask/task';

const CompanyTaskNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (taskValues, setSubmitting) => {
    createCompanyTaskRequest({
      dispatch,
      params: snakeCaseKeys(taskValues),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Tarea creada con éxito' }));
        history.push('/tasks');
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to="/tasks">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Crear Tarea</h2>
        </Col>
      </Row>
      <CompanyTaskForm companyTask={task} action="new" formRequest={handleCreateRequest} rrhh />
    </>
  );
};

export default CompanyTaskNew;
