import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl, Form, DropdownButton, Dropdown } from 'react-bootstrap';

const FormikInputDropdown = ({
  label,
  items,
  variant,
  dropDownClick,
  abbr,
  title,
  size,
  margin,
  error,
  touched,
  ...props
}) => (
  <Form.Group className={`${margin} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label>
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
    )}
    <InputGroup className="mb-3" size={size}>
      <DropdownButton as={InputGroup.Prepend} variant={variant} title={title} id="input-group-dropdown-1">
        {items.map(item => (
          <Dropdown.Item onClick={() => dropDownClick(item)} key={item.label}>
            {item.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <FormControl {...props} />
    </InputGroup>
  </Form.Group>
);

FormikInputDropdown.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

FormikInputDropdown.defaultProps = {
  variant: 'info',
  size: 'md'
};

export default FormikInputDropdown;
