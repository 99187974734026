import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const BookkeeperShow = ({ item, handleClose }) => (
  <>
    <Modal.Body>
      <div className="info-box">
        {item.code && (
          <p className="info">
            <span>Código:</span> {item.code}
          </p>
        )}
        {item.name && (
          <p className="info">
            <span>Nombre:</span> {item.name}
          </p>
        )}
        {item.type_account && (
          <p className="info">
            <span>Tipo de Cuenta:</span> {item.translated_type_account}
          </p>
        )}
        {item.account && (
          <p className="info">
            <span>Cuenta:</span> {item.account}
          </p>
        )}

        {item.bookkeeper_document_type_id && (
          <p className="info">
            <span>Tipo de Documento:</span> {item?.bookkeeper_document_type?.label}
          </p>
        )}
        <p className="info">
          <span>Centro de Costo:</span> {item?.cost_center ? 'Sí' : 'No'}
        </p>
        <p className="info">
          <span>Cuenta Análisis:</span> {item?.analytics_account ? 'Sí' : 'No'}
        </p>
        {item.auxiliary && (
          <p className="info">
            <span>Auxiliar:</span> {item?.translated_auxiliary}
          </p>
        )}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-info" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </>
);

export default BookkeeperShow;
