import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';

const ButtonTooltip = ({ toolbarVariant, direction, text, variant, children, delayShow, delayHide, ...props }) => {
  const { disabled } = props;

  return (
    <ButtonToolbar className={toolbarVariant}>
      <OverlayTrigger
        key={direction}
        placement={direction}
        delay={{ show: delayShow, hide: delayHide }}
        overlay={disabled ? <span /> : <Tooltip>{text}</Tooltip>}
      >
        <Button {...props} variant={variant}>
          {children}
        </Button>
      </OverlayTrigger>
    </ButtonToolbar>
  );
};

ButtonTooltip.propTypes = {
  direction: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

ButtonTooltip.defaultProps = {
  direction: 'bottom',
  variant: 'info',
  delayShow: 250,
  delayHide: 0,
  toolbarVariant: ''
};

export default ButtonTooltip;
