import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { profileTeamDashboardRequest, profileDashboardRequest } from '../../../requests/dashboards';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn } from '../../Utils/Button';

const PendingDocuments = ({ handleMoreInfoButton, topClass, tab, moreData, employees }) => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState({});
  const [showSpinner, setShowSpinner] = useState(1);
  const pendingCard = tab === 'profile' ? cards.signature : cards?.totalRequestsCount;

  const handleFailureRequest = error => {
    setShowSpinner(0);
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const fetchCardData = () => {
    if (tab === 'profile') {
      profileDashboardRequest({
        dispatch,
        successCallback: response => {
          setCards(camelCaseEmptyStringRecursive(response.data));
          setShowSpinner(0);
        },
        failureCallback: error => handleFailureRequest(error)
      });
    } else {
      profileTeamDashboardRequest({
        dispatch,
        successCallback: response => {
          setCards(camelCaseEmptyStringRecursive(response.data).allRequestsCount);
          setShowSpinner(0);
        }
      });
    }
  };
  useEffect(fetchCardData, [moreData]);

  return (
    <Card className="mb-1">
      <Col className={topClass}>
        <Col className="card-header-title">DOCUMENTOS POR FIRMAR</Col>
      </Col>
      {showSpinner ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash">
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            <Col sm={12} md={4} xs={12} className="mb-2 mt-2 circular-progress">
              <div style={{ width: 100, height: 100 }}>
                <CircularProgressbar
                  value={pendingCard}
                  text={`${pendingCard}`}
                  styles={buildStyles({
                    textSize: '26px',
                    pathColor: '#29B48F',
                    textColor: '#29B48F'
                  })}
                />
              </div>
            </Col>
            <Col sm={12} md={8} xs={12} className="mb-2 mt-4">
              <p className="text-dark-bold text-center">Tienes {pendingCard} documento(s) por Aprobar/Firmar</p>
              {tab === 'team' ? (
                <Button
                  variant="light-gray"
                  className="mb-4 mt-2"
                  block
                  onClick={handleMoreInfoButton}
                  disabled={employees.length === 0}
                  size="sm"
                >
                  Ver más
                </Button>
              ) : (
                <LinkBtn variant="light-gray" block size="sm" to="/profile?tab=signature">
                  Ver más
                </LinkBtn>
              )}
            </Col>
          </Row>
        </Col>
      )}
    </Card>
  );
};

export default PendingDocuments;
