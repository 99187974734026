import React from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';

const PermissionInfo = props => {
  const { item } = props;
  const {
    endDate,
    endTime,
    middayEnd,
    middayStart,
    reason,
    startDate,
    startTime,
    translatedPermissionType,
    workflowObservation
  } = camelCaseRecursive(item);

  return (
    <>
      <div className="info-box">
        {translatedPermissionType && (
          <p className="info">
            <span>Tipo de Permiso:</span> {translatedPermissionType}
          </p>
        )}
        <p className="info">
          <span>Fecha de Inicio:</span> {startDate} {middayStart && '- Medio día'}
        </p>
        {endDate ? (
          <p className="info">
            <span>Fecha de Término:</span> {endDate} {middayEnd && '- Medio día'}
          </p>
        ) : (
          <>
            <p className="info">
              <span>Hora de Inicio:</span> {startTime}
            </p>
            <p className="info">
              <span>Hora de Término:</span> {endTime}
            </p>
          </>
        )}
        {reason && (
          <p className="info">
            <span>Motivo de Solicitud:</span> {reason}
          </p>
        )}
        {workflowObservation && (
          <p className="info">
            <span>Motivo Rechazo:</span> {workflowObservation}
          </p>
        )}
      </div>
    </>
  );
};

export default PermissionInfo;
