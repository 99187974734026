import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';

const PersonalData = ({ employee, activeAccordion, activeKey }) => {
  const {
    country,
    firstLastName,
    name,
    nationalIdentification,
    birthdate,
    nationalIdExpirationDate,
    profiles,
    secondLastName,
    translatedCivilState,
    gender,
    translatedIdentificationType
  } = employee;
  const profileNames = profiles.map(profile => profile.label);
  const collaborator = employee.collaborator ? 'Sí' : 'No';

  return (
    <Card>
      <Col className={`${activeKey === 'personal-data' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle as={Col} eventKey="0" onClick={activeAccordion} className="card-header-title">
          INFORMACIÓN PERSONAL
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="div-content">
          <Row>
            <Col>
              <div className="info">
                <span className="type">Nombres:</span>
                <span className="align-right" />
                <span className="answer"> {name}</span>
              </div>
              <div className="info">
                <span className="type">Primer Apellido:</span>
                <span className="align-right" />
                <span className="answer"> {firstLastName}</span>
              </div>
              <div className="info">
                <span className="type">Segundo Apellido:</span>
                <span className="align-right" />
                <span className="answer"> {secondLastName}</span>
              </div>
              <div className="info">
                <span className="type">Fecha de nacimiento:</span>
                <span className="align-right" />
                <span className="answer"> {birthdate}</span>
              </div>
              <div className="info">
                <span className="type">Género:</span>
                <span className="align-right" />
                <span className="answer"> {gender?.label}</span>
              </div>
              <div className="info">
                <span className="type">Identificación ({translatedIdentificationType}):</span>
                <span className="align-right" />
                <span className="answer"> {nationalIdentification}</span>
              </div>
              <div className="info">
                <span className="type">Fecha de vencimiento Cédula de Identidad:</span>
                <span className="align-right" />
                <span className="answer"> {nationalIdExpirationDate}</span>
              </div>
              <div className="info">
                <span className="type">Nacionalidad:</span>
                <span className="align-right" />
                <span className="answer"> {country?.label}</span>
              </div>
              <div className="info">
                <span className="type">Estado civil:</span>
                <span className="align-right" />
                <span className="answer"> {translatedCivilState}</span>
              </div>
              <div className="info">
                <span className="type">Permisos:</span>
                <span className="align-right" />
                <span className="answer"> {profileNames.join(', ')}</span>
              </div>
              <div className="info">
                <span className="type">Es colaborador:</span>
                <span className="align-right" />
                <span className="answer"> {collaborator}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default PersonalData;
