import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const yesNo = value => {
  return value ? 'Sí' : 'No';
};

const balanceInfo = props => {
  const { balance, asset } = props;
  const {
    active,
    advanceGratification,
    amount,
    benefit,
    bankAccount,
    bank,
    bookkeeper,
    calculationOrder,
    code,
    compensationFund,
    name,
    overdraft,
    parsedAmount,
    paymentReceptor,
    receptorEmail,
    mobilizationAssignment,
    settlementConsideration,
    taxable,
    tributable,
    translatedAmountType,
    translatedBalanceType,
    translatedBankAccountType,
    translatedCalculationType,
    translatedDefaultToShow,
    translatedDiscountType,
    translatedIneCode,
    translatedPaymentType,
    translatedRemunerationType,
    translatedUnitOfAccount
  } = balance;

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>{`Información ${translatedBalanceType}`}</Card.Title>
          <Row>
            <Col md={6} xs={12}>
              <div className="info">
                <span className="type">Nombre:</span>
                <span className="line" />
                <span className="answer"> {name}</span>
              </div>
              <div className="info">
                <span className="type">Código:</span>
                <span className="line" />
                <span className="answer"> {code}</span>
              </div>
              {!asset && (
                <>
                  <div className="info">
                    <span className="type">Tipo de Descuento:</span>
                    <span className="line" />
                    <span className="answer"> {translatedDiscountType}</span>
                  </div>
                </>
              )}
              <div className="info">
                <span className="type">Forma de Cálculo:</span>
                <span className="line" />
                <span className="answer"> {translatedCalculationType}</span>
              </div>
              <div className="info">
                <span className="type">Cuenta Contable:</span>
                <span className="line" />
                <span className="answer"> {bookkeeper.label}</span>
              </div>
              <div className="info">
                <span className="type">Activo:</span>
                <span className="line" />
                <span className="answer"> {yesNo(active)}</span>
              </div>
              <div className="info">
                <span className="type">Beneficios:</span>
                <span className="line" />
                <span className="answer"> {yesNo(benefit)}</span>
              </div>
              {translatedDefaultToShow && (
                <div className="info">
                  <span className="type">Mostrar de Forma Predeterminada:</span>
                  <span className="line" />
                  <span className="answer"> {translatedDefaultToShow}</span>
                </div>
              )}
            </Col>
            <Col>
              {asset ? (
                <>
                  <div className="info">
                    <span className="type">Tipo de Remuneración:</span>
                    <span className="line" />
                    <span className="answer"> {translatedRemunerationType}</span>
                  </div>
                  <div className="info">
                    <span className="type">Agrupación INE:</span>
                    <span className="line" />
                    <span className="answer"> {translatedIneCode}</span>
                  </div>
                  <div className="info">
                    <span className="type">Imponible:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(taxable)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Tributable:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(tributable)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Sobregiro:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(overdraft)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Asignación de Movilización:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(mobilizationAssignment)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Anticipo de Gratificación:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(advanceGratification)}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="info">
                    <span className="type">Receptor de Pago:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(paymentReceptor)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Considerar para el cálculo de finiquito:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(settlementConsideration)}</span>
                  </div>
                </>
              )}
              {calculationOrder && (
                <div className="info">
                  <span className="type">Orden en la liquidación:</span>
                  <span className="line" />
                  <span className="answer"> {calculationOrder}</span>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {paymentReceptor && (
        <Card>
          <Card.Body>
            <Card.Title>Receptor de pago</Card.Title>
            {translatedPaymentType && (
              <div className="info">
                <span className="type">Forma de Pago:</span>
                <span className="line" />
                <span className="answer">{translatedPaymentType}</span>
              </div>
            )}
            {bank && (
              <div className="info">
                <span className="type">Banco para Pago:</span>
                <span className="line" />
                <span className="answer">{bank?.label}</span>
              </div>
            )}
            {translatedBankAccountType && (
              <div className="info">
                <span className="type">Tipo de Cuenta para depósito:</span>
                <span className="line" />
                <span className="answer">{translatedBankAccountType}</span>
              </div>
            )}
            {bankAccount && (
              <div className="info">
                <span className="type">Cuenta para depósito:</span>
                <span className="line" />
                <span className="answer">{bankAccount}</span>
              </div>
            )}
            {compensationFund && (
              <div className="info">
                <span className="type">Banco para Pago:</span>
                <span className="line" />
                <span className="answer">{compensationFund?.label}</span>
              </div>
            )}
            {receptorEmail && (
              <div className="info">
                <span className="type">Email:</span>
                <span className="line" />
                <span className="answer">{receptorEmail}</span>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Body>
          <Card.Title>Monto</Card.Title>
          <div className="info">
            <span className="type">Monto a Asignar:</span>
            <span className="line" />
            <span className="answer"> {translatedAmountType}</span>
          </div>
          <div className="info">
            <span className="type">Moneda:</span>
            <span className="line" />
            <span className="answer"> {translatedUnitOfAccount}</span>
          </div>
          {amount && (
            <div className="info">
              <span className="type">Monto:</span>
              <span className="line" />
              <span className="answer"> {parsedAmount}</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default balanceInfo;
