import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { debounceIndexDncRequest } from '../../../requests/dncs';
import DncDatatable from '../../DNC/DncDatatable';
import columns from './columns';

const DncProfileIndex = () => {
  const [dncs, setDncs] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      filter_my_dncs: true,
      allow_access: true,
      ...params
    };
    const request = async () =>
      debounceIndexDncRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { amount: responseAmount } = response.data.metadata;
          setDncs(response.data.data);
          setAmount(responseAmount);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={8}>
          <h2 className="mb-3 mt-3">Programa de Capacitación</h2>
        </Col>
      </Row>
      <DncDatatable
        dncs={dncs}
        amount={amount}
        onRequest={onRequest}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        setMoreData={setMoreData}
        columnDnc={columns}
        boss
      />
    </>
  );
};

export default DncProfileIndex;
