import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { withRouter } from 'react-router-dom';

import { validateTokenRequest, updateStatusEnrollmentRequest } from '../../requests/enrollments';
import { validateToken } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import EnrollmentForm from './EnrollmentForm';

class EnrollmentEdit extends Component {
  state = {
    enrollment: {
      serialNumber: '',
      photo: '',
      idBack: '',
      idFront: ''
    },
    renderView: ''
  };

  componentDidMount() {
    const {
      dispatch,
      myEnrollment,
      handleModalClose,
      match: { params }
    } = this.props;
    const myToken = params.token ? params.token : myEnrollment.token;
    const myParams = { token: myToken };

    if (params.token && handleModalClose) {
      handleModalClose();
    }

    validateTokenRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => this.setState({ token: params.token, renderView: 'enrollment_form' }),
      failureCallback: () => this.setState({ renderView: 'invalid_token' })
    });
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  handleCreateRequest = enrollment => {
    const { dispatch, myEnrollment } = this.props;

    const myParams = snakeCaseKeys(enrollment);
    myParams.token = enrollment.token ? enrollment.token : myEnrollment.token;
    myParams.enrollment.id = enrollment.enrollment.id;
    myParams.enrollment.photo = enrollment.enrollment.photo;
    myParams.enrollment.id_front = enrollment.enrollment.idFront;
    myParams.enrollment.id_back = enrollment.enrollment.idBack;

    updateStatusEnrollmentRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: this.handleSuccessCreate
    });
  };

  handleSuccessCreate = () => {
    const { dispatch, handleModalClose } = this.props;
    this.setState({ renderView: 'success' });
    dispatch(sendAlert({ kind: 'success', message: 'Enrolamiento creado con éxito' }));
    dispatch(validateToken());
    if (handleModalClose) {
      handleModalClose();
    }
  };

  getRenderedView = () => {
    const { enrollment, token, renderView } = this.state;

    switch (renderView) {
      case 'enrollment_form':
        return <EnrollmentForm enrollment={enrollment} token={token} formRequest={this.handleCreateRequest} />;
      case 'success':
        return <h2 className="text-uppercase mb-3 text-center">¡Listo! Tu enrolamiento ha sido enviado</h2>;
      case 'invalid_token':
        return <h2 className="text-uppercase mb-3 text-center">Este link expiró o es inválido</h2>;
      default:
        return '';
    }
  };

  render() {
    const viewToRender = this.getRenderedView();
    const {
      currentCompany,
      match: { params }
    } = this.props;

    return (
      <Row className={`px-md-4 px-lg-5 ${params.token ? 'mt-100 mb-3' : 'mt-2 mb-3'}`}>
        <Col>
          {params.token && currentCompany.businessName ? (
            <h2 className="text-uppercase mb-3 text-center">Bienvenido a {currentCompany.businessName}</h2>
          ) : (
            ''
          )}
          <Container>{viewToRender}</Container>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { currentCompany } = state.auth;
  return {
    currentCompany
  };
};

export default withRouter(connect(mapStateToProps)(EnrollmentEdit));
