import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../Icons';
import { useAuthorization } from '../../services/hooks';
import { ButtonTooltip } from '../Utils/Tooltips';

const BasicActionBtns = ({ item, clickHandler, destroyText, destroyIcon, destroyColor, path }) => (
  <>
    {useAuthorization('show', 'Employee') && (
      <ButtonTooltip
        to={`/${path}/${item.id}`}
        variant="circle-info"
        className="btn-circle"
        size="sm"
        text="Ver"
        as={Link}
      >
        <Icon icon="eye" />
      </ButtonTooltip>
    )}
    {useAuthorization('deactivate', 'Employee') && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'archive')}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text={destroyText}
      >
        <Icon style={destroyColor && { color: destroyColor }} icon={destroyIcon} />
      </ButtonTooltip>
    )}
  </>
);

BasicActionBtns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  destroyText: PropTypes.string,
  destroyIcon: PropTypes.string,
  destroyColor: PropTypes.string
};

BasicActionBtns.defaultProps = {
  destroyText: 'Desactivar',
  destroyIcon: 'archive',
  destroyColor: ''
};

export default BasicActionBtns;
