const ineGroups = [
  { label: 'Directivos y Gerentes', value: 'managers' },
  { label: 'Operadores y Montadores de Máquinas e Instalaciones', value: 'machine_operators' },
  { label: 'Operarios Manuales y Artesanos', value: 'manual_operators' },
  { label: 'Profesionales', value: 'professionals' },
  { label: 'Técnicos', value: 'technicians' },
  { label: 'Trabajadores de Apoyo Administrativo', value: 'administrative_support' },
  { label: 'Trabajadores No Especializados', value: 'unspecialized' },
  { label: 'Trab. de Serv. Personales y Seguridad', value: 'personal_and_security' },
  { label: 'Vendedores', value: 'sellers' }
];

export default ineGroups;
