import React, { Component } from 'react';
import { Map, Circle, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapContainer extends Component {
  state = {
    currentLocation: { lat: 0, lng: 0 }
  };

  handleClickedMap = (mapProps, map) => {
    const { coordenates } = this.props;
    if (coordenates) {
      const pos = coordenates;
      this.setState({ currentLocation: pos });
      const bounds = new mapProps.google.maps.LatLngBounds();
      bounds.extend(pos);
      map.fitBounds(bounds);
      map.setCenter(pos);
      map.setZoom(14);
      const listener = mapProps.google.maps.event.addListener(map, 'idle', () => {
        if (map.getZoom() > 14) map.setZoom(14);
        mapProps.google.maps.event.removeListener(listener);
      });
    }
  };

  render() {
    const { google } = this.props;
    const { currentLocation } = this.state;
    return (
      <>
        <Map
          google={google}
          // style={{ width: '100%', height: '100%', position: 'absolute' }}
          initialCenter={currentLocation}
          onReady={this.handleClickedMap}
          zoom={14}
        >
          <Circle
            radius={700}
            center={currentLocation}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillColor="#FF0000"
            fillOpacity={0.2}
          />
          <Marker title="Ubicacion Actual" name="Ubicacion" position={currentLocation} />
        </Map>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCwndRpOtXKHlX079dUfDE0fO7e4vWWgeE'
})(MapContainer);
