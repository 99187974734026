const basicSettlement = {
  id: '',
  assetsTotal: 0,
  businessDays: 0,
  continueWeekAmount: 0,
  contractId: '',
  daysAmount: 0,
  discountsTotal: 0,
  dtNotified: false,
  emailSent: false,
  employee: {},
  gratificationAmount: 0,
  grossAmount: 0,
  justification: '',
  liquidAmount: 0,
  notarySign: false,
  noticeDate: '',
  paymentDate: '',
  pendingHolidays: 0,
  salaryAmount: 0,
  settlementDocumentSent: false,
  settlementGroupId: '',
  status: '',
  terminationDate: '',
  terminationDocumentSent: false,
  terminationReason: '',
  uf: '',
  workedDays: 0
};

export default basicSettlement;
