import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';

const ContactData = ({ employee, activeAccordion, activeKey }) => {
  const { email, fullAddress, parsedPhone, parsedWorkPhone, workEmail } = employee;

  return (
    <Card className="mr-2">
      <Col className={`${activeKey === 'contact-data' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle as={Col} eventKey="1" onClick={activeAccordion} className="card-header-title">
          DATOS DE CONTACTO
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="div-content">
          <Row>
            <Col>
              <div className="info">
                <span className="type">Teléfono laboral:</span>
                <span className="align-right" />
                <span className="answer"> {parsedWorkPhone}</span>
              </div>
              <div className="info">
                <span className="type">Email laboral:</span>
                <span className="align-right" />
                <span className="answer"> {workEmail}</span>
              </div>
              <div className="info">
                <span className="type">Teléfono personal:</span>
                <span className="align-right" />
                <span className="answer"> {parsedPhone}</span>
              </div>
              <div className="info">
                <span className="type">Email personal:</span>
                <span className="align-right" />
                <span className="answer"> {email}</span>
              </div>
              <div className="info">
                <span className="type">Dirección:</span>
                <span className="align-right" />
                <span className="answer"> {fullAddress}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ContactData;
