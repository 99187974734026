import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const SimpleCenteredModal = ({ title, titleBtn, subtitle, body, bodyClass, size, closeButton, ...props }) => (
  <Modal {...props} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header closeButton={closeButton}>
      <Modal.Title>
        {title} {titleBtn && titleBtn}
        {subtitle && <h6 className="subtitle-simple-modal mb-0">{subtitle}</h6>}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className={bodyClass || ''}>{body}</Modal.Body>
  </Modal>
);

SimpleCenteredModal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  closeButton: PropTypes.bool
};

SimpleCenteredModal.defaultProps = {
  title: null,
  size: 'lg',
  closeButton: true
};

export default SimpleCenteredModal;
