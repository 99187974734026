import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';

const abbrMonthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
const days = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];

const locale = {
  localize: {
    month: n => abbrMonthNames[n],
    day: n => days[n]
  },
  formatLong: {}
};

const FormikTimePicker = ({
  abbr,
  date,
  delayHide,
  delayShow,
  direction,
  disabled,
  disableOutsideRange,
  error,
  errorText,
  focused,
  helpText,
  id,
  inputType,
  label,
  margin,
  onDateChange,
  onFocusChange,
  placeholder,
  readOnlyInput,
  setLocale,
  size,
  tooltipText,
  touched,
  minDate,
  ...props
}) => (
  <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        {tooltipText && (
          <ButtonToolbar className="ml-2">
            <OverlayTrigger
              key={direction}
              placement={direction}
              delay={{ show: delayShow, hide: delayHide }}
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <Icon icon="help-circle" height="15px" width="15px" />
            </OverlayTrigger>
          </ButtonToolbar>
        )}
      </Form.Label>
    )}
    <DatePicker
      className="form-control"
      locale={setLocale && locale}
      minDate={minDate}
      date={date}
      autoComplete="off"
      onDateChange={selectedDate => onDateChange(selectedDate)}
      placeholderText={placeholder}
      disabled={disabled}
      {...props}
    />
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

FormikTimePicker.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string
};

FormikTimePicker.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: ''
};

export default FormikTimePicker;
