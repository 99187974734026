import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { legalreportWorkdayReport } from '../../requests/legalreports';
import { ComponentDataTable } from '../index';

const WorkdaysFilterDataTable = ({ customParams, moreData, columns }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [workdays, setWorkdays] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    setWorkdays(response.data);
    setOnRequest(false);
  };

  const handleWorkdayReportRequest = params => {
    if (workdays.length > 0) {
      return;
    }
    setOnRequest(true);
    const request = async () =>
      legalreportWorkdayReport({
        dispatch,
        params: { ...customParams, ...params },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={workdays}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleWorkdayReportRequest}
        pointerOnHover
        preName="workdays"
        withMassActions
        withSearch={false}
        pagination
        paginationServer={false}
      />
    </>
  );
};

export default WorkdaysFilterDataTable;
