import { Field, getIn } from 'formik';
import React from 'react';
import { Col, Table } from 'react-bootstrap';

import { FormikCheckBox, FormikDatePicker, FormikInput, NestedAttributesTable } from '../../../../components';

const PreviousLicensesInput = ({ errors, index, touched }) => {
  return (
    <>
      <td>{index + 1}</td>
      <td>
        <Field name={`transact[previousLicense][${index}][lmaDateFrom]`}>
          {({ field }) => (
            <FormikDatePicker
              {...field}
              abbr
              isOutsideRange={() => false}
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[previousLicense][${index}][lmaDateTo]`}>
          {({ field }) => (
            <FormikDatePicker
              {...field}
              abbr
              isOutsideRange={() => false}
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`transact[previousLicense][${index}][lmaNdays]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
    </>
  );
};
const PreviousLicensesTab = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  setLicenseBack,
  licenseBack
}) => {
  const { previousLicense } = values.transact;

  const previousLicensesInputs = () => {
    const mapResults = previousLicense.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <PreviousLicensesInput
          key={`previous-licenses-group-${index.toString()}`}
          previousLicense={body}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          index={index}
        />
      );
    });
    mapResults.push(
      <>
        <td />
        <td>
          <FormikDatePicker placeholder="dd/mm/aaaa" margin="mb-1" disabled readOnly />
        </td>
        <td>
          <FormikDatePicker placeholder="dd/mm/aaaa" margin="mb-1" disabled readOnly />
        </td>
        <td>
          <FormikInput margin="mb-1" disabled />
        </td>
      </>
    );
    return (
      <>
        <NestedAttributesTable
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={previousLicense}
          setFieldValue={setFieldValue}
          valuePath="transact[previousLicense]"
          tdClass="text-center"
          maxRow={6}
          newAttributes={{
            lmaDateFrom: '',
            lmaDateTo: '',
            lmaNdays: ''
          }}
        />
      </>
    );
  };

  return (
    <>
      <Col md={12}>
        <Field name="transact[havePreviousLicense]">
          {({ field }) => (
            <FormikCheckBox
              {...field}
              field={field}
              label="Informar licencias anteriores"
              custom
              onClick={() => {
                setLicenseBack(!licenseBack);
              }}
              type="switch"
            />
          )}
        </Field>
      </Col>
      {licenseBack && (
        <Table responsive bordered>
          <thead>
            <tr>
              <th style={{ width: '8px' }}>#</th>
              <th className="text-center">Desde</th>
              <th className="text-center">Hasta</th>
              <th className="text-center">Total días</th>
              <th className="text-center">Acción</th>
            </tr>
          </thead>
          <tbody>{previousLicensesInputs()}</tbody>
        </Table>
      )}
    </>
  );
};

export default PreviousLicensesTab;
