import React, { useEffect, useRef } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { FormikSelect, FormikInput, ButtonTooltip, Icon } from '../../components';
import QuestionChoicesFields from './QuestionChoicesFields';
import questionTypes from './FormOptions';

const QuestionFields = ({ index, questionAttribute, valuePath }) => {
  const { questionChoicesAttributes } = questionAttribute;
  const { errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const scrollView = useRef();

  const addChoice = () => {
    const newValues = [...questionAttribute.questionChoicesAttributes, { label: '', position: '' }];
    setFieldValue(`${valuePath}[questionChoicesAttributes]`, newValues);
  };

  const deleteMultipleChoice = choiceIndex => {
    const arrayValuesLength = questionChoicesAttributes.length;
    const deleteValue = questionChoicesAttributes[choiceIndex];
    const newValues = [
      ...questionChoicesAttributes.slice(0, choiceIndex),
      ...questionChoicesAttributes.slice(choiceIndex + 1, arrayValuesLength)
    ];

    if (deleteValue.id && deleteValue !== '') {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }

    setFieldValue(`${valuePath}[questionChoicesAttributes]`, newValues);
  };

  const deleteChoice = () => {
    const arrayValuesLength = questionChoicesAttributes.filter(item => !item._destroy).length - 1;
    const deleteValue = questionChoicesAttributes[arrayValuesLength];
    const newValues = [...questionChoicesAttributes.slice(0, arrayValuesLength)];

    if (deleteValue.id && deleteValue !== '') {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }

    setFieldValue(`${valuePath}[questionChoicesAttributes]`, newValues);
  };

  const addQuestionChoices = () => {
    let mapResults = [];
    let destroyed = 0;
    let counter = 0;
    switch (questionAttribute.questionType) {
      case 'choices':
        mapResults = questionChoicesAttributes.map((body, choiceIndex) => {
          if (body._destroy) {
            destroyed += 1;
            return undefined;
          }

          return (
            <Row>
              <Col md={3}>
                <QuestionChoicesFields
                  key={`question-choice-${choiceIndex.toString()}`}
                  valuePath={`${valuePath}[questionChoicesAttributes][${choiceIndex}]`}
                  questionType={questionAttribute.questionType}
                  position={choiceIndex - destroyed + 1}
                />
              </Col>
              <Col md={1}>
                <ButtonTooltip
                  variant="circle-danger"
                  className="btn-circle mt-4"
                  size="sm"
                  text="Eliminar"
                  onClick={() => deleteMultipleChoice(choiceIndex)}
                  disabled={questionChoicesAttributes.length <= 2}
                >
                  <Icon icon="trash" />
                </ButtonTooltip>
              </Col>
            </Row>
          );
        });
        mapResults.push(
          <Row>
            <Col md={3}>
              <Button onClick={addChoice}>Agregar Respuesta</Button>
            </Col>
          </Row>
        );
        return <Container>{mapResults}</Container>;
      case 'likert':
        return (
          <Row className="mt-3 w-100">
            <Col xs={1}>
              <ButtonTooltip
                variant="circle-danger"
                className="btn-circle mt-4"
                size="sm"
                text="Eliminar"
                onClick={deleteChoice}
                disabled={questionChoicesAttributes.filter(item => !item._destroy).length <= 2}
              >
                <Icon icon="remove" />
              </ButtonTooltip>
            </Col>
            <Col xs={10} className="scroll-x" ref={scrollView}>
              {questionChoicesAttributes.map((body, choiceIndex) => {
                if (body._destroy) {
                  destroyed += 1;
                  return '';
                }

                return (
                  <QuestionChoicesFields
                    key={`question-choice-${choiceIndex.toString()}`}
                    questionType={questionAttribute.questionType}
                    position={choiceIndex - destroyed + 1}
                    valuePath={`${valuePath}[questionChoicesAttributes][${choiceIndex}]`}
                  />
                );
              })}
            </Col>
            <Col xs={1}>
              <ButtonTooltip
                variant="circle-primary"
                className="btn-circle mt-4"
                size="sm"
                text="Nuevo"
                onClick={() => {
                  addChoice();
                  scrollView.current.scroll({ behavior: 'smooth', left: 10000 });
                }}
              >
                <Icon icon="add" />
              </ButtonTooltip>
            </Col>
          </Row>
        );
      default:
        return questionChoicesAttributes.map((body, choiceIndex) => {
          if (body._destroy || counter === 2) {
            destroyed += 1;
            return undefined;
          }

          counter += 1;
          return (
            <Container>
              <QuestionChoicesFields
                key={`question-choice-${choiceIndex.toString()}`}
                position={choiceIndex - destroyed + 1}
                questionType={questionAttribute.questionType}
                valuePath={`${valuePath}[questionChoicesAttributes][${choiceIndex}]`}
              />
            </Container>
          );
        });
    }
  };

  const updatePosition = () => {
    setFieldValue(`${valuePath}[position]`, index + 1);
  };

  useEffect(updatePosition, []);

  return (
    <Row className="p-3 pb-4">
      <Col md={3}>
        <Field name={`${valuePath}[questionType]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              defaultValue={questionAttribute.questionType}
              label="Tipo de Pregunta"
              options={questionTypes}
              placeholder="Seleccionar Tipo de Pregunta"
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={{ span: 6, offset: 1 }}>
        <Field name={`${valuePath}[label]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              label="Pregunta"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <hr />
      {addQuestionChoices()}
    </Row>
  );
};

export default QuestionFields;
