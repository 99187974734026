import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { indexUserAbsenceRequest } from '../../../requests/absences';
import { indexUserVacationsRequest } from '../../../requests/vacations';
import { indexUserPermissionsRequest } from '../../../requests/permissions';
import CalendarAssistance from "../../CalendarAssistance/CalendarAssistance";
import './style.scss';

const CalendarAssistanceIndex = () => {
  const [events, setEvents] = useState('');
  const [eventColor, setEventColor] = useState('#29b48f');
  const [calendarTitle, setCalendarTitle] = useState('Licencias Médicas');
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const updateCalendar = (titleCalendar = 'Licencias Médicas', color = '#29b48f') => {
    setCalendarTitle(titleCalendar);
    setEventColor(color);
  };

  const saveEvents = data => {
    const array = [];
    data.map(item => array.push(item.calendarFormat));
    setEvents(array);
  };

  const handleAbsenceRequest = (
    params = {
      filter_absence_exception: 'unjustified_absence',
      date_from: moment()
        .clone()
        .startOf('months')
        .format('DD/MM/YYYY')
    },
    title,
    color
  ) => {
    setOnRequest(true);
    const request = async () =>
      indexUserAbsenceRequest({
        dispatch,
        params,
        successCallback: res => {
          saveEvents(camelCaseRecursive(res.data.data));
          setOnRequest(false);
          updateCalendar(title, color);
        }
      });
    request();
  };

  const handlePermissionRequest = (params, title, color) => {
    setOnRequest(true);
    const request = async () =>
      indexUserPermissionsRequest({
        dispatch,
        params,
        successCallback: res => {
          saveEvents(camelCaseRecursive(res.data.data));
          setOnRequest(false);
          updateCalendar(title, color);
        }
      });
    request();
  };

  const handleVacationRequest = (params, title, color) => {
    setOnRequest(true);
    const request = async () =>
      indexUserVacationsRequest({
        dispatch,
        params,
        successCallback: res => {
          saveEvents(camelCaseRecursive(res.data.data));
          setOnRequest(false);
          updateCalendar(title, color);
        }
      });
    request();
  };

  useEffect(handleAbsenceRequest, []);
  useEffect(updateCalendar, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner mt-5 fix-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4">
        <Col md={12}>
          <h2 className="m-top mt-3 mb-md-4">Calendario de {calendarTitle}</h2>
        </Col>
      </Row>
      <Row className={`${onRequest && 'bg-opacity'} calendar-assistance`}>
        <Col>
          <CalendarAssistance
            events={events}
            eventColor={eventColor}
            moreLinkContent={args => `Ver más (${args.num})`}
            dayMaxEvents={3}
            handleAbsenceRequest={handleAbsenceRequest}
            handlePermissionRequest={handlePermissionRequest}
            handleVacationRequest={handleVacationRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default CalendarAssistanceIndex;
