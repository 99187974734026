import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAdvancePaymentRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/advance_payments`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showAdvancePaymentRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/advance_payments/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createAdvancePaymentRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/advance_payments', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const updateAdvancePaymentRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/advance_payments/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const approveAdvancePaymentRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', `/advance_payments/${id}/approve`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const deleteAdvancePaymentRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/advance_payments/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const exportAdvancePaymentsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/advance_payments/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const preImportAdvancePaymentRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/advance_payments/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importAdvancePaymentRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/advance_payments/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateAdvancePaymentRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/advance_payments/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const generatePaymentAdvancePaymentRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/advance_payments/${id}/generate_payment`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const generateMassPaymentAdvancePaymentRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('put', '/advance_payments/mass_generate_payment', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const generateMassSignAdvancePaymentRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('put', '/advance_payments/mass_sign_document', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const debounceIndexAdvancePaymentRequest = AwesomeDebouncePromise(indexAdvancePaymentRequest, 300);
