import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, ApproveRejectActions, ButtonTooltip, Icon } from '../../../components';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'SOLICITADO POR',
    selector: 'employee',
    cell: item => item.employee.short_name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MONTO',
    selector: 'amount',
    cell: item => item.parsed_amount,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE OTORGAMIENTO',
    selector: 'grant_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'PRIMER DESCUENTO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'APROBAR',
    cell: item => (
      <ApproveRejectActions
        resource="Vacation"
        item={item}
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    minWidth: '110px',
    grow: '0.5'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          variant="circle-info"
          className="btn-circle"
          onClick={() => clickHandler(item, 'show')}
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        {ability.can('destroy', 'Loan') && (
          <ButtonTooltip
            variant="circle-danger"
            className="btn-circle"
            onClick={() => clickHandler(item, 'destroy')}
            disabled={item.status !== 'pending'}
            text="Eliminar"
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '100px'
  }
]);

export default columns;
