const contractTypes = [
  { label: 'Indefinido', value: 'undefined_term' },
  { label: 'Plazo fijo', value: 'fixed_term' }
  // { label: 'Por obra o faena', value: 'work_or_task' },
  // { label: 'Part time (jornada parcial)', value: 'contract_part_time' }
];

const paymentSchedules = [
  { label: 'Mensual', value: 'monthly' }
  // { label: 'Quincenal', value: 'biweekly' },
  // { label: 'Semanal', value: 'weekly' },
  // { label: 'Diario', value: 'daily' }
];

const gratificationTypes = [
  { label: 'Sí', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Editar', value: 'edit' }
];

export { contractTypes, gratificationTypes, paymentSchedules };
