import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Form as BSForm, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { FormikSelect, CheckBoxBtn, FormikInput, FormikDatePicker, InputSelect, LinkBtn } from '../../components';
import { periods, statuses } from './FormOptions';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { indexEmployeesRequest } from '../../requests/employees';

import EmployeeDncDatatable from './EmployeeDncDatatable';

const DncForm = ({ errors, touched, values, setFieldValue, setFieldTouched, dnc, isSubmitting, action }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [jobManagementId, setJobManagementId] = useState('');
  const [bosses, setBosses] = useState('');
  const [bossesLoading, setBossesLoading] = useState(false);
  const dispatch = useDispatch();
  const { period, allowAccess, status, employeeId, employeeDncsAttributes } = values.dnc;
  const vAllowAccess = !allowAccess;

  const resultFetchJobManagements = response => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: element.name,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    setOnRequest(true);
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: res => {
        callback(resultFetchJobManagements(res));
        setOnRequest(false);
      }
    });
  };

  const resultFetchBosses = response => {
    const result = response.data;
    setBosses(
      result.map(element => ({
        ...element,
        label: element.full_name,
        value: element.id
      }))
    );
    setBossesLoading(false);
  };

  const fetchBosses = () => {
    setBossesLoading(true);
    indexEmployeesRequest({
      dispatch,
      params: {
        filter_job_management: jobManagementId,
        bosses: true,
        is_dt: false,
        paginate: false
      },
      successCallback: res => resultFetchBosses(res.data)
    });
  };

  const defineEmployee = data => {
    const employeeIds = data.map(item => item.id);
    const currentIds = employeeDncsAttributes.map(item => item.employeeId);
    employeeDncsAttributes.forEach((item, index) => {
      if (employeeIds.includes(item.employeeId)) {
        setFieldValue(`dnc[employeeDncsAttributes][${index}][_destroy]`, false);
      } else {
        setFieldValue(`dnc[employeeDncsAttributes][${index}][_destroy]`, true);
      }
    });
    const base = employeeDncsAttributes.length;
    data.forEach((item, index) => {
      if (!currentIds.includes(item.id)) {
        setFieldValue(`dnc[employeeDncsAttributes][${base + index}][employeeId]`, item.id);
        setFieldValue(`dnc[employeeDncsAttributes][${base + index}][employee]`, item);
        setFieldValue(`dnc[employeeDncsAttributes][${base + index}][active]`, true);
      }
    });
  };

  const fetchEmployees = () => {
    if (employeeId) {
      setOnRequest(true);
      indexEmployeesRequest({
        dispatch,
        params: {
          employees_belong_boss: employeeId,
          is_dt: false
        },
        successCallback: res => defineEmployee(camelCaseRecursive(res.data.data)),
        callback: () => setOnRequest(false)
      });
    }
  };

  useEffect(fetchBosses, [jobManagementId]);
  useEffect(fetchEmployees, [employeeId]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad mt-5 center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Form className={`${onRequest && 'bg-opacity'} mx-1 mx-md-3`}>
        <Row>
          <Col md={6}>
            <Field name="dnc[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre de DNC"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="dnc[period]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Periodo"
                  placeholder="Seleccionar periodo"
                  options={periods}
                  defaultValue={period}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="dnc[jobManagementId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Seleccionar Área"
                  placeholder="Seleccionar área"
                  values={values.dnc}
                  model={[dnc, 'jobManagement']}
                  request={fetchJobManagments}
                  onChange={data => {
                    setFieldValue(field.name, data ? data.value : '');
                    setFieldValue('dnc[employeeId]', '');
                    setJobManagementId(data.value);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            {bossesLoading && (
              <Spinner className="width-spinner-dnc position-absolute" animation="border" variant="primary" />
            )}
            <Field name="dnc[employeeId]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  isDisabled={(action !== 'edit' && jobManagementId === '') || bossesLoading}
                  label="Seleccionar Jefatura"
                  options={bosses}
                  defaultValue={bosses.length > 0 && employeeId}
                  placeholder="Seleccionar jefatura"
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  margin="mb-0"
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mt-4 mt-md-0">
            <Field name="dnc[limitDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={() => false}
                  label="Fecha límite"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <BSForm.Group className="button-group max-width-group">
              <BSForm.Label>Acceso a la Jefatura</BSForm.Label>
              <Row>
                <Field name="dnc[allowAccess]">
                  {({ field }) => <CheckBoxBtn {...field} id="allowAccess" label="Si" checked={allowAccess} />}
                </Field>
                <Field name="dnc[allowAccess]">
                  {({ field }) => <CheckBoxBtn {...field} id="allowAccess" label="No" checked={vAllowAccess} />}
                </Field>
              </Row>
            </BSForm.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {action === 'edit' && (
              <Field name="dnc[status]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Estado del DNC"
                    placeholder="Seleccionar estado"
                    options={statuses}
                    defaultValue={status}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
        <hr className="mb-5" />
        <EmployeeDncDatatable employees={employeeDncsAttributes} boss={false} />
        <Row className="d-flex justify-content-end my-5">
          <Col md={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
            <LinkBtn block variant="outline-info" className="btn-circle" to="/dnc">
              Cancelar
            </LinkBtn>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting} block>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { dnc } = props;

  const setDate = date => {
    if (date === '') {
      return '';
    }
    const [month, year] = date.split('/');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, 1);
  };

  return {
    dnc: {
      ...dnc,
      employeeDncsAttributes: dnc.employeeDncsAttributes.map(item => ({
        ...item,
        printableDate: item.suggestedDate,
        suggestedDate: setDate(item.suggestedDate)
      }))
    }
  };
};

const validationSchema = Yup.object().shape({
  dnc: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    period: Yup.string().required('Debes seleccionar un periodo'),
    status: Yup.string().nullable(),
    employeeId: Yup.string().required('Debes seleccionar una jefatura'),
    jobManagementId: Yup.string().required('Debes seleccionar un área'),
    limitDate: Yup.string()
      .required('Debes seleccionar una fecha límite')
      .typeError('Debes seleccionar una fecha límite'),
    employeeDncsAttributes: Yup.array().of(
      Yup.object().shape({
        topicId: Yup.string(),
        necessityAreaId: Yup.string(),
        suggestedDate: Yup.string()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(DncForm);
