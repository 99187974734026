import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ComponentDataTable } from '../../components';
import { debounceIndexEmployeeCourseRequest } from '../../requests/employeeCourses';
import columns from './columns';

const CourseDatatable = () => {
  const [employeeCourses, setEmployeeCourses] = useState('');
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexEmployeeCourseRequest({
        dispatch,
        params: { ...params },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setEmployeeCourses(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_title':
        return { sort_job_titles: name };
      case 'job_management':
        return { sort_job_managements: name };
      case 'employee':
        return { sort_employees: name };
      case 'course':
        return { sort_courses: name };
      case 'admission':
        return { sort_admission: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  const handleButtonClick = item => history.push(`/courses/${item.course_id}`);

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={employeeCourses}
        handleSortCase={sortColumnCase}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        onRowClicked={handleButtonClick}
        pointerOnHover
      />
    </>
  );
};

export default CourseDatatable;
