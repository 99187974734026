import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { FormikInput, InputSelect, FormikDatePicker, FormikSelect, NestedAttributes } from '../../components';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { debounceIndexJobTitleRequest } from '../../requests/jobTitles';
import { processTypes, statuses, workSchedules, expertiseLevels, remotes } from './FormOptions';
import SelectionProcessStageForm from './SelectionProcessStageForm';

const SelectionProcessForm = props => {
  const { errors, setFieldValue, touched, selectionProcess, setFieldTouched, values } = props;
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    setOnRequest(true);
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(resultFetchData(data));
        setOnRequest(false);
      }
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    let type = false;
    setOnRequest(true);
    debounceIndexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40,
        type
      },
      successCallback: data => {
        callback(resultFetchData(data, (type = true)));
        setOnRequest(false);
      }
    });
  };

  const addSelectionProcessStage = () => {
    const { selectionProcessStagesAttributes } = values.selectionProcess;
    const mapResults = selectionProcessStagesAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }
      return (
        <SelectionProcessStageForm
          key={`stage-${index.toString()}`}
          errors={errors}
          touched={touched}
          index={index}
          setFieldValue={setFieldValue}
        />
      );
    });

    mapResults.push(
      <>
        <Col md={11}>
          <FormikInput label="Nombre Etapa" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={selectionProcessStagesAttributes}
          setFieldValue={setFieldValue}
          valuePath="selectionProcess[selectionProcessStagesAttributes]"
          newAttributes={{ name: '', position: '' }}
        />
      </>
    );
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Form className={`container ${onRequest && 'bg-opacity'}`}>
        <Row>
          <Col md={12}>
            <Field name="selectionProcess[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="selectionProcess[startDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={() => false}
                  label="Fecha de Inicio"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="selectionProcess[vacancies]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Vacantes"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="selectionProcess[jobTitleId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Cargo"
                  placeholder="Seleccionar Cargo"
                  values={values.selectionProcess}
                  model={[selectionProcess, 'jobTitle']}
                  request={fetchJobTitles}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="selectionProcess[jobManagementId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Área"
                  placeholder="Seleccionar Área"
                  values={values.selectionProcess}
                  model={[selectionProcess, 'jobManagement']}
                  request={fetchJobManagments}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="selectionProcess[processType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo"
                  placeholder="Seleccionar Tipo de Proceso"
                  options={processTypes}
                  defaultValue={selectionProcess.processType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="selectionProcess[status]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Estado"
                  placeholder="Seleccionar Estado"
                  options={statuses}
                  defaultValue={selectionProcess.status}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="selectionProcess[description]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  as="textarea"
                  label="Descripción"
                  minInputHeight="150"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="selectionProcess[workSchedule]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Jornada"
                  placeholder="Seleccionar Tipo de Jornada"
                  options={workSchedules}
                  defaultValue={selectionProcess.workSchedule}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="selectionProcess[expertiseLevel]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Nivel de Experiencia"
                  placeholder="Seleccionar Nivel de Experiencia"
                  options={expertiseLevels}
                  defaultValue={selectionProcess.expertiseLevel}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="selectionProcess[remote]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Modalidad Remota"
                  placeholder="Seleccionar Tipo de Modalidad Remota"
                  options={remotes}
                  defaultValue={selectionProcess.remote}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="selectionProcess[desiredKnowledge]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  as="textarea"
                  abbr
                  label="Conocimientos Deseables"
                  minInputHeight="150"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="selectionProcess[jobDescription]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  as="textarea"
                  abbr
                  label="Funcionalidades del Cargo"
                  minInputHeight="150"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <p className="text-muted">*Las etapas se verán en el mismo orden en que se agregan al formulario.</p>
        {addSelectionProcessStage()}
        <Row className="justify-content-end mt-3">
          <Col md={3}>
            <Button type="submit" block>
              CONFIRMAR
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const {
    id,
    name,
    desiredKnowledge,
    expertiseLevel,
    jobDescription,
    startDate,
    description,
    vacancies,
    processType,
    remote,
    status,
    workSchedule,
    jobManagementId,
    jobTitleId,
    companyId,
    translatedProcessType,
    selectionProcessStagesAttributes
  } = props.selectionProcess;

  return {
    selectionProcess: {
      id,
      name,
      desiredKnowledge,
      expertiseLevel,
      jobDescription,
      startDate,
      vacancies,
      description,
      processType,
      remote,
      status,
      workSchedule,
      companyId,
      jobManagementId,
      jobTitleId,
      translatedProcessType,
      selectionProcessStagesAttributes
    }
  };
};

const validationSchema = Yup.object().shape({
  selectionProcess: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(60, 'Máximo 60 caracteres permitidos'),
    desiredKnowledge: Yup.string().required('Debes ingresar los conocimientos deseables'),
    expertiseLevel: Yup.string().required('Debes ingresar el nivel de experiencia'),
    jobDescription: Yup.string().required('Debes ingresar las funcionalidades del cargo'),
    startDate: Yup.date()
      .formatdate()
      .required('Debes escoger una fecha'),
    vacancies: Yup.number()
      .required('Debes ingresar la cantidad de vacantes disponibles')
      .typeError('Debes ingresar un número')
      .positive('Debe ser mayor a 0'),
    processType: Yup.string().required('Debes ingresar un tipo de proceso de selección'),
    remote: Yup.string().required('Debes ingresar una modalidad remota'),
    status: Yup.string().required('Debes ingresar un estado'),
    workSchedule: Yup.string().required('Debes ingresar un tipo de jornada'),
    jobTitleId: Yup.string().required('Debes ingresar un cargo'),
    jobManagementId: Yup.string().required('Debes ingresar un Área'),
    selectionProcessStagesAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required('Debes ingresar un nombre')
          .max(60, 'Máximo 60 caracteres permitidos')
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SelectionProcessForm);
