import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikRangePicker, InputSelect } from '../../../components';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';

const Filters = props => {
  const { errors, touched, setFieldValue, values } = props;
  const dispatch = useDispatch();

  const fetchEmployees = (inputValue, callback) => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
  };

  return (
    <Form>
      <Row>
        <Col xs={4} md={4}>
          <Field name="range_date">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                abbr
                startDateName="date_from"
                endDateName="date_to"
                startDate={values.date_from}
                endDate={values.date_to}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={8}>
          <Field name="employees">
            {({ field }) => (
              <InputSelect
                {...field}
                label="Colaboradores"
                isMulti
                placeholder="Seleccione colaboradores"
                values={values.filter_ids}
                request={fetchEmployees}
                onChange={data => {
                  handleNewMulti(data || [], 'filter_ids', true);
                  setFieldValue(field.name, data || []);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-4">
        <Col md={2}>
          <Button className="btn-block" type="submit">
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  range_date: Yup.array().when('filter_offday', {
    is: true,
    then: Yup.array().rangedate(true)
  })
});

const setInitialValues = () => {
  return {
    range_date: [],
    filter_ids: [],
    filter_offday: true
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(Filters);
