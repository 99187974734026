import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { updateWorkflowTemplateRequest } from '../../requests/workflowTemplates';
import { sendAlert } from '../../actions/utils';
import WorkflowTemplateDataTable from './WorkflowTemplateDataTable';
import WorkflowTemplateForm from './WorkflowTemplateForm';

const handleEmployeeReviewers = (values, employeeReviewers) => {
  employeeReviewers.forEach(employeeReviewer => {
    if (!values.some(value => value.id === employeeReviewer.id)) {
      values.push({ id: employeeReviewer.id, _destroy: 'true' });
    }
  });
};

const handleReviewerTemplates = (values, reviewerTemplates) => {
  reviewerTemplates.forEach(reviewerTemplate => {
    values.forEach(reviewerTemplateValue => {
      if (reviewerTemplate.id === reviewerTemplateValue.id && !reviewerTemplateValue._destroy) {
        handleEmployeeReviewers(
          reviewerTemplateValue.employeeReviewerTemplatesAttributes || reviewerTemplateValue.reviewerTemplateIds,
          reviewerTemplate.reviewerTemplateIds
        );
      }
    });
  });
  values
    .filter(RTValue => !RTValue._destroy)
    .forEach((RTValue, i) => {
      // eslint-disable-next-line no-param-reassign
      RTValue.position = i + 1;
    });
};

const WorkflowTemplateIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Flujo');
  const [modalShow, setModalShow] = useState(false);
  const [workflowTemplate, setWorkflowTemplate] = useState({});
  const dispatch = useDispatch();

  const handleSuccess = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleFailure = error => {
    const {
      response: { data }
    } = error;
    dispatch(sendAlert({ kind: 'error', message: data.message }));
  };

  const handleUpdate = workflowTemplateValue => {
    const {
      workflowTemplate: { id: workflowTemplateId, reviewerTemplatesAttributes }
    } = workflowTemplateValue;
    handleReviewerTemplates(reviewerTemplatesAttributes, workflowTemplate.reviewerTemplatesAttributes);
    const test = snakeCaseKeys(workflowTemplateValue, { exclude: ['_destroy'] });
    updateWorkflowTemplateRequest(workflowTemplateId, {
      dispatch,
      params: test,
      formData: true,
      successCallback: () => handleSuccess('Flujo actualizado con éxito'),
      failureCallback: error => handleFailure(error)
    });
  };

  const editWorkflowTemplate = workflowTemplateRow => {
    setWorkflowTemplate(camelCaseRecursive(workflowTemplateRow));
    setModalShow(true);
    setModalTitle('Editar flujo');
  };

  return (
    <>
      <WorkflowTemplateDataTable
        editWorkflowTemplate={editWorkflowTemplate}
        moreData={moreData}
        setMoreData={setMoreData}
      />
      <SimpleCenteredModal
        title={modalTitle}
        backdrop="static"
        closeButton={false}
        body={
          <WorkflowTemplateForm
            workflowTemplate={workflowTemplate}
            action="edit"
            formRequest={handleUpdate}
            cancelAction={() => setModalShow(false)}
          />
        }
        show={modalShow}
      />
    </>
  );
};

export default WorkflowTemplateIndex;
