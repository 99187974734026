import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { ComponentDataTable, ButtonTooltip, Icon, DefaultModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import columns from './columns';

const TrainingProgramDatatable = ({ onRequest, employeeDncs, amount, handleIndexRequest, query }) => {
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const ability = useAbility(AbilityContext);

  const handleActions = (item, action) => {
    switch (action) {
      case 'edit':
        history.push(`/dnc/${item.dnc.value}/edit`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee_name':
        return { sort_employees: name };
      case 'employee_job_title':
        return { sort_job_titles: name };
      case 'topic':
        return { sort_topic: name };
      case 'necessity_area':
        return { sort_necessity_areas: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        handleSortCase={sortColumnCase}
        data={employeeDncs}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        onRowClicked={row => handleActions(row, 'edit')}
        withMassActions={ability.can('index', 'EmployeeDnc')}
        massActions={
          <ButtonTooltip
            onClick={() => setModalShow(true)}
            variant="circle-primary"
            toolbarVariant="ml-auto mr-3"
            className="btn-circle"
            size="sm"
            text="Exportar"
          >
            <Icon icon="download-2" />
          </ButtonTooltip>
        }
        withStartDate
        withEndDate
        pointerOnHover
      />
      <DefaultModal
        title="Exportar programa de capacitación"
        body={<div className="ml-3">¿Estás seguro que deseas exportar el programa de capacitación?</div>}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => {
          handleIndexRequest(query, '.xlsx', 'text');
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default TrainingProgramDatatable;
