import memoize from 'memoize-one';
import { handleDaysShow } from '../../../services/utils';

const columns = memoize(() => [
  {
    name: 'MOTIVO',
    selector: 'absence_type',
    cell: item => item.translated_absence_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FORMATO',
    selector: 'absence_type_format',
    cell: item => item.translated_absence_type_format,
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RETORNO',
    selector: 'reincorporation_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1'
  }
]);

export default columns;
