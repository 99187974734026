import React from 'react';
import PropTypes from 'prop-types';

const GetStatus = ({ code, listStatus, showCode = true }) => {
  const renderBody = () => {
    if (listStatus.length > 0 && code !== '') {
      const filterStatus = listStatus.filter(element => {
        return element.value.toString() === code.toString();
      });

      return (
        <>
          {showCode ? `${code} - ` : ''} {filterStatus[0].label}
        </>
      );
    }
    return null;
  };
  return renderBody();
};

GetStatus.propTypes = {
  code: PropTypes.string.isRequired
};

export default GetStatus;
