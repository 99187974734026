import React from 'react';
import { useAbility } from '@casl/react';
import Icon from '../Icons';
import { AbilityContext } from '../../config/abilityContext';
import { BasicDropdown } from '..';

const BookkeeperDocumentTypeActions = ({ handleClick, disabled }) => {
  const ability = useAbility(AbilityContext);

  const actions = () => {
    const authorizedActions = [];
    if (ability.can('destroy_file', 'DirectoryNode')) {
      authorizedActions.push({
        icon: <Icon icon="checkmark-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'activate',
        props: {
          onClick: () => handleClick('activate'),
          disabled
        },
        title: 'Activar'
      });
    }
    if (ability.can('destroy_file', 'DirectoryNode')) {
      authorizedActions.push({
        icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'deactivate',
        props: {
          onClick: () => handleClick('deactivate'),
          disabled
        },
        title: 'Desactivar'
      });
    }
    if (ability.can('destroy_file', 'DirectoryNode')) {
      authorizedActions.push({
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'destroy',
        props: {
          onClick: () => handleClick('destroy'),
          disabled
        },
        title: 'Eliminar'
      });
    }
    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default BookkeeperDocumentTypeActions;
