import * as Yup from 'yup';
import '../../../services/yupCustomMethods';

const validationSchema = Yup.object().shape({
  balance: Yup.object().shape({
    code: Yup.string()
      .required('Debes ingresar un código')
      .max(10, 'Deben ser como máximo 10 caracteres')
      .matches(/^[aA-zZ0-9\s]+$/, 'Solo se permiten letras y numeros '),
    name: Yup.string()
      .required('Debes ingresar una Descripción')
      .max(50, 'Deben ser como máximo 50 caracteres'),
    bookkeeperId: Yup.string().when('balanceType', {
      is: val => ['asset', 'discount'].includes(val),
      then: Yup.string().required('Debes ingresar una Cuenta Contable'),
      otherwise: Yup.string().nullable()
    }),
    ineCode: Yup.string().when('balanceType', {
      is: val => ['asset'].includes(val),
      then: Yup.string().required('Debes seleccionar la categoria INE'),
      otherwise: Yup.string().nullable()
    }),
    classificationType: Yup.string().when('balanceType', {
      is: val => ['asset', 'discount'].includes(val),
      then: Yup.string().required('Debes seleccionar la Clasificación'),
      otherwise: Yup.string().nullable()
    }),
    observation: Yup.string()
      .max(100, 'Deben ser como máximo 100 caracteres')
      .nullable(true),
    calculationOrder: Yup.number().when('balanceType', {
      is: val => ['asset', 'discount'].includes(val),
      then: Yup.number()
        .min(1, 'Debe ser mayor o igual a 1')
        .max(999, 'Deben ser menor o igual a 999'),
      otherwise: Yup.number().nullable()
    }),
    maxInput: Yup.number().when('balanceType', {
      is: val => val === 'input_parameters',
      then: Yup.number()
        .min(1, 'Debe ser mayor o igual a 1')
        .max(999, 'Deben ser menor o igual a 999 '),
      otherwise: Yup.number().nullable()
    })
  })
});

export default validationSchema;
