import React from 'react';
import { Form } from 'react-bootstrap';

const BasicTextArea = ({
  abbr,
  inputType,
  placeholder,
  helpText,
  label,
  size,
  row,
  error,
  touched,
  minInputHeight,
  maxLength,
  ...props
}) => (
  <Form.Group className={error && touched ? 'is-invalid' : ''}>
    {label && (
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
      </Form.Label>
    )}
    <Form.Control
      style={{ minHeight: `${minInputHeight}px` }}
      as="textarea"
      rows={row}
      size={size}
      maxLength={maxLength}
      type={inputType}
      placeholder={placeholder}
      {...props}
    />
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

export default BasicTextArea;
