import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ilustration3 from '../../assets/images/ilustration-3.png';

const HomeHow = () => {
  return (
    <Row className="how-section my-5 d-flex justify-content-center text-justify">
      <Col xs={12}>
        <p>
          Nuestro software 100% digital, cuenta con gestión integral 360°, trazando todos los procesos internos del área
          de RRHH. Desde gestión documentaria;donde podrás firmar digitalmente, marcar asistencia, importar y exportar
          documentos, reportes y control de gestión, etc, como también gestión de remuneraciones; generación de
          contratos, asistencia, solicitud de vacaciones, etc.
        </p>
      </Col>
      <Col md={7} xs={12}>
        <p>
          Gestión del talento; donde podrás realizar procesos de reclutamiento y selección, capacitaciones, evaluación
          de desempeño, todo en un solo lugar.
        </p>
        <p>
          Ya no tendrás que tener la documentación física en todas partes, sufrir porque no encuentras la información de
          tus colaboradores, o no tener tiempo para liderar temas estratégicos, ya que lo operativo te consume el día.
        </p>
      </Col>
      <Col md={5} xs={12}>
        <Image src={ilustration3} fluid alt="Mockups 2" />
      </Col>
    </Row>
  );
};

export default HomeHow;
