import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import BranchOfficeInfo from './BranchOfficeInfo';
import { delayMethod } from '../../services/utils';

const BranchOfficeShow = ({ branchOffice, hideModal }) => {
  return (
    <>
      <Modal.Body>
        <BranchOfficeInfo branchOffice={branchOffice} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={() => delayMethod(hideModal, 180)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </>
  );
};

export default BranchOfficeShow;
