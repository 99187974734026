import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexEmployeeReviewerTemplatesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/employee_reviewer_templates', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexEmployeeReviewerTemplatesRequest = AwesomeDebouncePromise(
  indexEmployeeReviewerTemplatesRequest,
  300
);
