import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ComponentDataTable, SimpleCenteredModal, ButtonTooltip, Icon } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { showImageFunRequest, updateSendNotificationEmployeerRequest } from '../../requests/xdatas';
import BasicEmployeeDataModalShow from './BasicEmployeeDataModalShow';
import columns from './Columns';
import ProcessNotificationForm from './ProcessNotificationForm';
import { sendAlert } from '../../actions/utils';
import MasiveProcessNotificationForm from './MasiveProcessNotificationForm';

const NotificationPeriodDataTable = ({
  data,
  withMassActions,
  requestDatatable,
  requestFunesPending,
  customPeriod
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledAction, setDisableAction] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalMasiveBodyForm, setModalMasiveBodyForm] = useState('');
  const [modalMasiveShowForm, setModalMasiveShowForm] = useState(false);
  const [modalBodyForm, setModalBodyForm] = useState('');
  const [modalShowForm, setModalShowForm] = useState(false);
  const [modalTitleBtn, setModalTitleBtn] = useState('');
  const [size, setSize] = useState('');
  const dispatch = useDispatch();

  const downloadPDFFromURL = link => {
    const downloadUrl = document.createElement('a');
    const fileName = `Imagen_Fun.pdf`;
    downloadUrl.href = link;
    downloadUrl.download = fileName;
    downloadUrl.dispatchEvent(new MouseEvent('click'));
  };

  const downloadPDF = base64 => {
    const linkSource = `data:application/pdf;base64,${base64}`;

    const downloadLink = document.createElement('a');
    const fileName = `Imagen_Fun.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleSuccessShow = (response, params) => {
    setModalBody(false);
    const fileObject = response.data;
    const file = fileObject.archivo;
    setSize('xl');
    setModalTitle('Documento Fun ');
    let documentShow;

    if (fileObject.archivo === 'No Encontrado') {
      setModalBody(<BasicEmployeeDataModalShow item={camelCaseEmptyStringRecursive(params.item)} />);
    }
    if (fileObject.tipo === 'AR') {
      documentShow = `data:application/pdf;base64,${file}`;
    }
    if (fileObject.tipo === 'LK' || fileObject.tipo === 'UR') {
      documentShow = file;
    }

    const downloadBtn = (
      <Button
        variant="primary"
        onClick={() => downloadPDF(file)}
        target="_blank"
        rel="noopener noreferrer"
        className="ml-4"
      >
        Descargar PDF
      </Button>
    );
    const downloadBtnLink = (
      <Button
        variant="primary"
        onClick={() => downloadPDFFromURL(file)}
        target="_blank"
        rel="noopener noreferrer"
        className="ml-4"
      >
        Descargar PDF
      </Button>
    );
    const body = (
      <>
        <iframe src={documentShow} title="Documento" style={{ height: '600px' }} className="w-100">
          <p>Este navegador no soporta PDFs.Por favor descarga el PDF para verlo: {downloadBtn}</p>
        </iframe>
      </>
    );

    if (fileObject.archivo === 'No Encontrado') {
      setModalTitle('Documento no encontrado');
      setModalTitleBtn();
    } else if (fileObject.tipo === 'AR') {
      setModalTitleBtn(downloadBtn);
      setModalBody(body);
    } else if (fileObject.tipo === 'LK' || fileObject.tipo === 'UR') {
      setModalTitleBtn(downloadBtnLink);
      setModalBody(body);
    }
    setModalShow(true);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    if (!item.selectedCount) {
      setDisableAction(true);
    } else {
      setDisableAction(false);
    }
  };

  const handleRequestImageFun = async params => {
    showImageFunRequest({
      dispatch,
      params,
      successCallback: response => handleSuccessShow(response, params)
    });
  };

  const handleModalClose = () => {
    setModalShowForm(false);
    setModalMasiveShowForm(false);
  };

  const handleSuccessUpdateNotification = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Trabajador/es Procesados Correctamente' }));
    handleModalClose();
    requestDatatable();
    requestFunesPending();
  };

  const handleMasiveSendNotificationEmployeer = (dataForm, setSubmitting) => {
    const myParams = selectedRows.reduce((acc, current) => {
      if (current.fun_not_aut_re !== 'ON') {
        acc.push({
          rut_employee: current.fun_rut_afi_nn,
          dv_employee: current.fun_dv_afi_cr,
          folio_fun: current.fun_fol_fun_nn,
          status_notification: dataForm.employees.status_notification,
          reason_rejection: dataForm.employees.reason_rejection
        });
      }
      return acc;
    }, []);

    updateSendNotificationEmployeerRequest({
      dispatch,
      params: {
        periodo_proc: customPeriod,
        employees: myParams
      },
      successCallback: handleSuccessUpdateNotification,
      callback: () => {
        setSubmitting(false);
        handleModalClose(false);
      }
    });
  };

  const handleSendNotificationEmployeer = (dataform, setSubmitting) => {
    updateSendNotificationEmployeerRequest({
      dispatch,
      params: {
        periodo_proc: customPeriod,
        employees: dataform.employees
      },
      successCallback: handleSuccessUpdateNotification,
      callback: () => {
        setSubmitting(false);
        handleModalClose(false);
      }
    });
  };

  const handleButtonClick = (item, action) => {
    const folioFun = item.fun_fol_fun_nn;
    const Periodo = item.period;
    switch (action) {
      case 'document':
        handleRequestImageFun({
          folio_fun: folioFun,
          process_period: Periodo,
          item
        });
        break;
      case 'process':
        setModalBodyForm(
          <ProcessNotificationForm
            employees={[
              {
                rut_employee: item.fun_rut_afi_nn,
                dv_employee: item.fun_dv_afi_cr,
                folio_fun: item.fun_fol_fun_nn,
                status_notification: '',
                reason_rejection: ''
              }
            ]}
            formRequest={handleSendNotificationEmployeer}
            handleModalClose={handleModalClose}
            item={item}
          />
        );
        setModalShowForm(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleMassProccessNotification = () => {
    setModalMasiveBodyForm(
      <MasiveProcessNotificationForm
        employees={{
          status_notification: '',
          reason_rejection: ''
        }}
        formRequest={handleMasiveSendNotificationEmployeer}
        handleModalClose={handleModalClose}
      />
    );
    setModalMasiveShowForm(true);
  };
  return (
    <>
      <ComponentDataTable
        onRequest={false}
        columns={columns(handleButtonClick)}
        data={data}
        paginationServer={false}
        sortServer={false}
        selectableRows={withMassActions}
        pagination
        withSearch={false}
        onSelectedRowsChange={handleSelectedRows}
        withMassActions
        massActions={
          <>
            <ButtonTooltip
              variant="circle-warning"
              className="btn-circle"
              onClick={() => handleMassProccessNotification()}
              disabled={disabledAction}
              text="Procesar Fun"
            >
              <Icon icon="settings-outline" />
            </ButtonTooltip>
          </>
        }
      />
      <SimpleCenteredModal
        title={modalTitle}
        titleBtn={modalTitleBtn}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size={size}
      />
      <SimpleCenteredModal
        title="Procesamiento de FUN"
        body={modalBodyForm}
        show={modalShowForm}
        onHide={handleModalClose}
      />
      <SimpleCenteredModal
        title="Procesamiento Masivo de FUN"
        body={modalMasiveBodyForm}
        show={modalMasiveShowForm}
        onHide={handleModalClose}
      />
    </>
  );
};

export default NotificationPeriodDataTable;
