const workflowTypes = [
  { label: 'Anexo de Contrato', value: 'contract_annex' },
  { label: 'Certificados', value: 'certificate' },
  { label: 'Contratos', value: 'contract' },
  { label: 'Solicitud de Anticipos', value: 'advance_payment' },
  { label: 'Solicitud de Permisos', value: 'permission' },
  { label: 'Solicitud de Préstamos', value: 'loan' },
  { label: 'Solicitud de Vacaciones', value: 'vacation' },
  { label: 'Horas Extra', value: 'overtime' },
  { label: 'Amonestaciones', value: 'reprimand_document' },
  { label: 'Felicitaciones', value: 'congratulation_letter' },
  { label: 'Otros Documentos', value: 'other_document' },
  { label: 'Entrega de elementos de protección personal', value: 'personal_protection' },
  { label: 'Obligación de informar los riesgos laborales', value: 'report_occupational_hazards' },
  { label: 'Registro de aceptación del RIOHS', value: 'acceptance_riohs' },
  { label: 'Inducción en prevención de riesgos', value: 'induction_risk_prevention' },
  { label: 'Pactos de horas extras', value: 'overtime_agreements' }
];

export default workflowTypes;
