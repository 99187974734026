import React from 'react';
import Icon from '../../components/Icons';
import { BasicDropdown } from '../../components';

const ReportActions = ({ handleClick, disabled }) => {
  const actions = () => {
    const authorizedActions = [];
    authorizedActions.push({
      icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download_excel',
      props: {
        onClick: () => handleClick('download_excel'),
        disabled
      },
      title: 'Descargar Excel'
    });

    authorizedActions.push({
      icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download_docx',
      props: {
        onClick: () => handleClick('download_docx'),
        disabled
      },
      title: 'Descargar Word'
    });

    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Descargar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default ReportActions;
