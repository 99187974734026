import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../../components';

import RoleDataTable from './RoleDataTable';

const RoleIndex = () => {
  const [moreData, setMoreData] = useState(false);
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">Perfiles</h2>
        </Col>
        <Col md={3} xl={2}>
          <LinkBtn variant="primary" className="mt-3" block to="/admin/roles/new">
            Nuevo Perfil
          </LinkBtn>
        </Col>
      </Row>
      <RoleDataTable moreData={moreData} setMoreData={setMoreData} />
    </>
  );
};

export default RoleIndex;
