import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Card, Col, Image, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { indexEmployeesRequest } from '../../../requests/employees';
import Icon from '../../Icons';

const BirthCalendar = () => {
  const dispatch = useDispatch();
  const [todayBirths, setTodayBirths] = useState('');
  const [nextBirths, setNextBirths] = useState('');
  const [showSpinner, setShowSpinner] = useState(1);

  const fetchEmployees = () => {
    const today = moment().format('YYYY-MM-DD');
    const endDate = moment()
      .add(30, 'days')
      .format('YYYY-MM-DD');

    const handleSuccessEmployees = response => {
      const results = response.data.data;
      const dayMonthToday = moment().format('DD-MM');
      const births = results.filter(item => moment(item.birthdate).format('DD-MM') === dayMonthToday);

      const othersBirths = results.filter(item => moment(item.birthdate).format('DD-MM') > dayMonthToday);

      setTodayBirths(births.slice(0, 2));
      setNextBirths(othersBirths.slice(0, 2));
      setShowSpinner(0);
    };

    indexEmployeesRequest({
      dispatch,
      params: {
        paginate: false,
        active: true,
        birthdate_from: today,
        birthdate_to: endDate
      },
      successCallback: handleSuccessEmployees,
      failureCallback: () => setShowSpinner(0)
    });
  };
  useEffect(fetchEmployees, []);

  return (
    <Card className="mb-3">
      <Col className="top-header-light-gray">
        <Col className="card-header-title">CALENDARIO DE CUMPLEAÑOS</Col>
      </Col>
      {showSpinner ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            <Col className="mb-13 mt-2">
              <p className="text-green mb-1 font-size-responsive position-top-box employee-edit-top-box">
                <Icon icon="gift-outline" className="icon-15" /> Cumpleañeros de Hoy
              </p>
            </Col>
          </Row>
          <Row>
            {todayBirths.length > 0 ? (
              todayBirths.map(item => (
                <Col key={item.id} className="col-sm-6 text-center">
                  <div className="avatar employee-dash">
                    <div className={item.file_info.file_url ? 'content' : 'empty content'}>
                      {item.file_info.file_url ? <Image src={item.file_info.file_url} /> : <Icon icon="camera" />}
                    </div>
                  </div>
                  <p className="mb-1">{item.short_name}</p>
                </Col>
              ))
            ) : (
              <p className="ml-4 mb-8">Sin cumpleañeros hoy...</p>
            )}
          </Row>
          <Row>
            <Col className="mb-13 mt-2">
              <p className="text-green mb-1 font-size-responsive position-top-box employee-edit-top-box">
                <Icon icon="gift-outline" className="icon-15" /> Próximos Cumpleaños
              </p>
            </Col>
          </Row>
          <Row>
            {nextBirths.length > 0 ? (
              nextBirths.map(item => (
                <Col key={`${item.id}_n`} className="col-sm-6 text-center">
                  <div className="avatar employee-dash" title={moment(item.birthdate).format('DD/MM')}>
                    <div className={item.file_info?.file_url ? 'content' : 'empty content'}>
                      {item.file_info?.file_url ? <Image src={item.file_info?.file_url} /> : <Icon icon="camera" />}
                    </div>
                  </div>
                  <p className="mb-4">{item.short_name}</p>
                </Col>
              ))
            ) : (
              <p className="ml-4 mb-8">Sin registros...</p>
            )}
          </Row>
        </Col>
      )}
    </Card>
  );
};

export default BirthCalendar;
