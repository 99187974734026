import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';

const useDocumentsAbility = () => {
  const ability = useAbility(AbilityContext);
  return (
    ability.can('read', 'DirectoryNode') ||
    ability.can('read_contract', 'DirectoryNode') ||
    ability.can('read_certificate', 'DirectoryNode') ||
    ability.can('read_requests', 'DirectoryNode') ||
    ability.can('read_annex', 'DirectoryNode') ||
    ability.can('read_settlement', 'DirectoryNode') ||
    ability.can('read_salary', 'DirectoryNode') ||
    ability.can('read_requests', 'DirectoryNode') ||
    ability.can('read_others', 'DirectoryNode') ||
    ability.can('read_prevention', 'DirectoryNode')
  );
};

export default useDocumentsAbility;
