import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';

const RemoteSelect = ({ value, request, onChange, disabled, ...moreProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <AsyncSelect
      className={`${isOpen ? 'border-on ' : ''}`}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      placeholder="Buscar..."
      loadingMessage={() => 'Cargando...'}
      noOptionsMessage={() => 'No hay opciones'}
      value={value}
      defaultOptions
      loadOptions={request}
      onChange={onChange}
      isDisabled={disabled}
      {...moreProps}
    />
  );
};

export default RemoteSelect;
