import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { legalreportShiftReport } from '../../../requests/legalreports';
import { ComponentDataTable, DefaultModal } from '../../../components';
import { indexEmployeesRequest } from '../../../requests/employees';
import { AbilityContext } from '../../../config/abilityContext';
import { ShiftFilterDataTable } from '../../../components/LegalReport';
import { downloadFile } from '../../../services/utils';
import ReportActions from '../../Legalreports/ReportActions';
import LegalReportFilter from '../../Legalreports/Filters/LegalReportFilter';

const columns = memoize(() => [
  {
    name: 'RUT',
    selector: 'national_identification',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: true,
    grow: '2'
  },
  {
    name: 'LUGAR DE PRESTACION DE SERVICIOS',
    selector: 'branch_office',
    sortable: false,
    grow: '3'
  }
]);

const recordsColumns = memoize(() => [
  {
    name: 'FECHA TURNO ORIGINAL',
    selector: 'original_shift_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'TURNO ORIGINAL',
    selector: 'original_turn_name',
    sortable: true,
    grow: '3'
  },
  {
    name: 'FECHA TURNO NUEVO',
    selector: 'new_shift_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'TURNO NUEVO',
    selector: 'new_turn_name',
    sortable: true,
    grow: '3'
  },
  {
    name: 'SOLICITADOR',
    selector: 'solicitor',
    sortable: true,
    grow: '3'
  }
]);

export const MyExpander = props => {
  const { data, dateRange } = props;
  const { id } = data;

  return (
    <ShiftFilterDataTable
      columns={recordsColumns}
      customParams={{ filter_by_employee: id, date_range: dateRange }}
      props
    />
  );
};

export const LoadingTranslation = () => {
  return <h3>Cargando...</h3>;
};

const ShiftReportDataTable = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [wordModalShow, setWordModalShow] = useState(false);
  const [query, setQuery] = useState({});
  const [filters, setFilters] = useState([]);
  const [haveDateRange, setHaveDateRange] = useState(false);
  const [amount, setAmount] = useState(0);
  const { currentEmployee } = useSelector(state => state.auth);
  const [dateRange, setDateRange] = useState([]);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleRequest = async (params, format, responseType = 'json') => {
    setOnRequest(true);
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date;
    setQuery(empParams);
    indexEmployeesRequest({
      dispatch,
      params: { ...filters, ...empParams, active: true, is_dt: false, filter_ids: currentEmployee },
      format,
      responseType,
      successCallback: response => {
        const { data } = response;
        setAmount(data.metadata.amount);
        setFilteredEmployees(data.data);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleShiftReportRequest = async (params, format, type = 'json') => {
    const responseType = format === '.docx' ? 'blob' : type;
    setOnRequest(true);
    legalreportShiftReport({
      dispatch,
      params: { filter_by_employee: currentEmployee, date_range: dateRange, ...params, paginate: false },
      format,
      responseType,
      successCallback: response => {
        if (format === '.xlsx' || format === '.docx') {
          downloadFile(response);
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setModalShow(true);
        break;
      case 'download_docx':
        setWordModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const handleFilters = params => {
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date;
    setFilters(empParams);
    setSearchFirstStart(true);
    setDateRange(params.range_date);
    setHaveDateRange(true);
    handleRequest(params);
  };

  return (
    <>
      <LegalReportFilter formRequest={handleFilters} filterRRHH={false} />

      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={filteredEmployees}
        totalRows={amount}
        withMassActions={ability.can('index', 'ShiftReport')}
        massActions={<ReportActions disabled={!haveDateRange || onRequest} handleClick={handleMassAction} />}
        resourceRequest={handleRequest}
        expandableRows
        progressPending={onRequest}
        progressComponent={<LoadingTranslation />}
        expandableRowsComponent={<MyExpander dateRange={dateRange} />}
        searchFirstStart={searchFirstStart}
        pagination={false}
      />
      <DefaultModal
        title="Exportar Reporte de Asistencias"
        body="¿Estás seguro que quieres exportar el reporte de asistencia en formato Excel?"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => {
          handleShiftReportRequest(query, '.xlsx', 'text');
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <DefaultModal
        title="Exportar Reporte de Asistencias"
        body="¿Estás seguro que quieres exportar el reporte de asistencia en formato Word?"
        show={wordModalShow}
        handleClose={() => setWordModalShow(false)}
        handleConfirm={() => {
          handleShiftReportRequest(query, '.docx', 'text');
          setWordModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ShiftReportDataTable;
