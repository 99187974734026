import React, { useState, useEffect } from 'react';
import { Row, Col, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useSetTab } from '../../../services/hooks';
import { InfiniteScroll } from '../../../components';
import JobLongCard from '../../../components/Utils/Cards/JobLongCard';
import { debounceIndexProfileSelectionProcessesRequest } from '../../../requests/selectionProcesses';
import './style.scss';

const JobOffersIndex = ({ location }) => {
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [key, setKey] = useSetTab('all-course', location, 'course-tab');
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRequest = () => {
    const request = async () =>
      debounceIndexProfileSelectionProcessesRequest({
        dispatch,
        params: {
          display_start: page * 15,
          display_length: 15
        },
        successCallback: response => {
          if (response.data.data.length + courses.length === response.data.metadata.amount) {
            setHasMore(false);
          }
          setPage(page + 1);
          setCourses([...courses, ...camelCaseRecursive(response.data.data)]);
        }
      });
    request();
  };

  useEffect(handleRequest, []);

  const showInfo = item => history.push(`selection_processes/${item.id}`);

  return (
    <>
      <Row className="my-4">
        <Col>
          <h2 className="m-top mb-3 mt-3">Ofertas de Empleo</h2>
        </Col>
      </Row>
      <div className="profile-show">
        <Tab.Container id="course.tabs" activeKey={key} mountOnEnter onSelect={k => setKey(k)}>
          <Tab.Content>
            <Tab.Pane eventKey="all-course">
              <InfiniteScroll dataLength={courses.length} next={handleRequest} hasMore={hasMore} label="cursos">
                <Row className="mt-4">
                  {courses.map(item => (
                    <Col key={item.id} xs={12} md={12}>
                      <JobLongCard
                        item={item}
                        employeeRut={user.nationalIdentification}
                        showInfo={showInfo}
                        nameShow="Ver"
                        className="card-size"
                        classNameNoImage="card-size-noimage"
                        classNameDescription="box-description"
                      />
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default JobOffersIndex;
