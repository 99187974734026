import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { createAdminEmployeeRequest } from '../../../requests/adminEmployees';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import EmployeeForm from '../../Employee/EmployeeForm/EmployeeForm';
import basicEmployee from './employee';

const EmployeeNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleCreateRequest = employeeValues => {
    const {
      employee: { image }
    } = employeeValues;

    createAdminEmployeeRequest({
      dispatch,
      params: {
        employee: snakeCaseKeys(employeeValues.employee),
        image
      },
      formData: true,
      successCallback: response => {
        const { data } = response;
        dispatch(sendAlert({ kind: 'success', message: 'Trabajador creado con éxito' }));
        history.push(`/admin/employees/${data.id}`);
      }
    });
  };

  return (
    <>
      <div className="top-box sps sps--abv employee" />
      <div className="title-box sps sps--abv employee employee-admin">
        <Row className="mt-4 mb-1">
          <Col xs={1}>
            <LinkBtn variant="circle-dark" className="btn-circle" block to="/admin/employees">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <Col>
            <h2 className="text-uppercase mb-0 xs-font-size">Ficha del Trabajador</h2>
          </Col>
        </Row>
      </div>
      <EmployeeForm
        employee={basicEmployee}
        action="new"
        submitVariant="success"
        formRequest={handleCreateRequest}
        admin
        adminNew
      />
    </>
  );
};
export default EmployeeNew;
