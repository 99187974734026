import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  indexBranchOfficesRequest,
  importBranchOfficeRequest,
  preImportBranchOfficeRequest,
  exportBranchOfficesRequest,
  importTemplateBranchOfficeRequest,
  massiveDestroyBranchOficcesRequest,
  activeBranchOfficeRequest,
  deactivateBranchOfficeRequest
} from '../../requests/branchOffices';
import { downloadFile } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import {
  ComponentDataTable,
  DefaultModal,
  SimpleCenteredModal,
  ImportActions,
  ImportModal,
  SettingsMassiveActions
} from '../../components';

const BranchOfficeDataTableComponent = ({ columns, showBranchOffice, moreData, setMoreData, editBranchOffice }) => {
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [query, setQuery] = useState({});
  const [branchOffices, setBranchOffices] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setBranchOffices(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setQuery(params);
    indexBranchOfficesRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSimpleModalShow(false);
    setOnRequest(false);
  };

  const handleSuccessRemove = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
    setOnRequest(false);
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    handleModalClose();
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const removeBranchOffice = item => {
    const branchOfficesSelected = item.id;
    setOnRequest(true);
    massiveDestroyBranchOficcesRequest({
      params: { ids: branchOfficesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Lugar de Prestación de Servicios Eliminada correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    exportBranchOfficesRequest({
      dispatch,
      params: { ...query, paginate: false },
      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
        setModalShow(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        showBranchOffice(item);
        break;
      case 'edit':
        editBranchOffice(item);
        break;
      case 'destroy':
        setModalTitle('Eliminar Lugar de Prestación de Servicios');
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este Lugar de Prestación de Servicios?');
        setModalAction(() => removeBranchOffice);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const massiveDeleteBranchOffice = () => {
    const branchOfficesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyBranchOficcesRequest({
      params: { ids: branchOfficesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Lugares de Prestación de Servicios  Eliminados correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveActiveBranchOffice = () => {
    const branchOfficesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    activeBranchOfficeRequest({
      params: { ids: branchOfficesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Lugares de Prestación de Servicios Activados correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveDeactivateBranchOffice = () => {
    const branchOfficesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deactivateBranchOfficeRequest({
      params: { ids: branchOfficesSelected },
      dispatch,
      successCallback: handleSuccessRemove('Lugares de Prestación de Servicios Desactivados correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setSimpleModalShow(true);
        break;
      case 'export':
        setModalTitle('Exportar Lugares de Prestación de Servicios');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar Lugares de Prestación de Servicios?');
        setModalAction(() => handleExportRequest);
        break;
      case 'massive_destroy':
        setModalTitle('Eliminar Lugar de Prestación de Servicios');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los Lugares de Prestación de Servicios seleccionados?');
        setModalAction(() => massiveDeleteBranchOffice);
        break;
      case 'massive_active':
        setModalTitle('Activar Lugares de Prestación de Servicios');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas activar los Lugares de Prestación de Servicios seleccionados?');
        setModalAction(() => massiveActiveBranchOffice);
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Lugares de Prestación de Servicios');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar las Lugares de Prestación de Servicios seleccionados?');
        setModalAction(() => massiveDeactivateBranchOffice);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={branchOffices}
        totalRows={amount}
        selectableRows
        withMassActions
        massActions={
          <>
            <ImportActions model="BranchOffice" handleClick={handleMassAction} />
            <SettingsMassiveActions model="BranchOffice" handleClick={handleMassAction} disabled={!selectedCount} />
          </>
        }
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        preName="branch_offices"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />

      <SimpleCenteredModal
        title="Importar Lugares de Prestación de Servicios"
        body={
          <ImportModal
            onDropUploaded={preImportBranchOfficeRequest}
            handleTemplate={importTemplateBranchOfficeRequest}
            hideModal={() => setSimpleModalShow(false)}
            onHide={importBranchOfficeRequest}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};
export default BranchOfficeDataTableComponent;
