import ApiService from '../services/apiService';

const createTemplatePdfRequest = ({
  dispatch,
  format = '',
  params,
  responseType,
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', `/template_pdfs${format}`, {
    dispatch,
    params,
    responseType,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const previewTemplatePdfRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('put', `/template_pdfs/preview`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export default createTemplatePdfRequest;
