import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikSelect, FormikTimePicker, FormikDatePicker, NestedAttributes } from '../index';
import timeTypes from './formOptions';
import { delayMethod } from '../../services/utils';

const AttendanceInput = ({ setFieldValue, setFieldTouched, errors, touched, index, timeType }) => {
  return (
    <>
      <Col md={4}>
        <Field name={`attendances[${index}][date]`}>
          {({ field }) => (
            <FormikDatePicker
              {...field}
              abbr
              isOutsideRange={() => false}
              showClearDate={false}
              label="Fecha"
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3}>
        <Field name={`attendances[${index}][parsedTime]`}>
          {({ field }) => (
            <FormikTimePicker
              {...field}
              abbr
              label="Hora"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              timeFormat="h:mm aa"
              placeholder="9:00 AM"
              onChange={date => {
                setFieldValue(
                  `attendances[${index}][time]`,
                  date.toLocaleTimeString('en-ES', {
                    hour: 'numeric',
                    minute: '2-digit'
                  })
                );
                setFieldValue(
                  field.name,
                  date.toLocaleTimeString('en-ES', {
                    hour: 'numeric',
                    minute: '2-digit'
                  })
                );
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name={`attendances[${index}][timeType]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Tipo de Hora"
              placeholder="Seleccionar Tipo de Hora"
              options={timeTypes}
              defaultValue={timeType}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

const AttendanceNestedForm = props => {
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    handleModalClose,
    onHide,
    employeeId,
    isSubmitting
  } = props;

  const generateAttendanceInputs = () => {
    const { attendances: vAttendances } = values;
    const mapResults = vAttendances.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <AttendanceInput
          key={`attendance-${index.toString()}`}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          index={index}
          time={body.time}
          timeType={body.timeType}
        />
      );
    });

    mapResults.push(
      <>
        <Col md={4} className="sample-row">
          <Field name="attendances[date]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={() => false}
                label="Fecha"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled
              />
            )}
          </Field>
        </Col>
        <Col md={3} className="sample-row">
          <Field name="attendances[time]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                label="Hora"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeFormat="h:mm aa"
                placeholder="9:00 AM"
                onChange={() => {}}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="attendances[timeType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Hora"
                placeholder="Seleccionar Tipo de Hora"
                options={timeTypes}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isDisabled
              />
            )}
          </Field>
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={vAttendances}
          setFieldValue={setFieldValue}
          valuePath="attendances"
          newAttributes={{
            date: '',
            time: '',
            timeType: '',
            parsedTime: '',
            employeeId
          }}
        />
      </>
    );
  };

  return (
    <Form>
      <Modal.Body>{generateAttendanceInputs()}</Modal.Body>
      <Modal.Footer>
        <Row className="d-flex justify-content-end mt-3 mb-1">
          <Col md={6}>
            <Button className="text-center" block variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
              Cancelar
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" block variant="primary" disabled={isSubmitting} onClick={onHide}>
              CREAR
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { attendances } = props;
  return {
    attendances
  };
};

const validationSchema = Yup.object().shape({
  attendances: Yup.array().of(
    Yup.object().shape({
      date: Yup.string()
        .required('Debes escoger una fecha')
        .typeError('Debes ingresar una fecha válida'),
      time: Yup.string().required('Debes escoger la hora'),
      timeType: Yup.string().required('Debes escoger el tipo de hora'),
      parsedTime: Yup.string().required('Debes escoger la hora'),
      employeeId: Yup.string().required('Debe haber un empleado asociado')
    })
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(AttendanceNestedForm);
