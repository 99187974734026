import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';

import { FormikInput, FormikRangePicker, NestedAttributes } from '../../components';
import QuestionFields from './QuestionFields';
import SurveyPreview from './SurveyPreview';
import '../../services/yupCustomMethods';

const SurveyForm = ({ errors, isSubmitting, setFieldValue, touched, values }) => {
  const { startDate, endDate, questionsAttributes } = values.survey;
  const [togglePreview, setTogglePreview] = useState(false);

  const addQuestion = () => {
    const mapResults = questionsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <Col md={11} className="bg-light round-10 ml-3 mr-n3 mb-3">
          <QuestionFields
            key={`question-${index.toString()}`}
            index={index}
            valuePath={`survey[questionsAttributes][${index}]`}
            questionAttribute={body}
          />
        </Col>
      );
    });

    mapResults.push(
      <Col md={11} className="bg-light round-10 ml-3 mr-n3 mb-3">
        <Row className="p-3 pb-4">
          <Col md={3} className="sample-row">
            <FormikInput label="Tipo de Pregunta" disabled />
          </Col>
          <Col md={{ span: 6, offset: 1 }} className="sample-row">
            <FormikInput label="Pregunta" disabled />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={questionsAttributes}
          setFieldValue={setFieldValue}
          valuePath="survey[questionsAttributes]"
          newAttributes={{
            questionType: 'binary',
            label: '',
            position: '',
            questionChoicesAttributes: [
              { label: 'Sí', position: 1 },
              { label: 'No', position: 2 }
            ]
          }}
        />
      </>
    );
  };

  return (
    <Form>
      {togglePreview ? (
        <SurveyPreview survey={values.survey} employeeSurvey={{ employeeQuestionChoicesAttributes: [] }} />
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Field name="survey[name]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombre"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="rangeDate">
                {({ field }) => (
                  <FormikRangePicker
                    {...field}
                    abbr
                    minCurrentDate
                    startDateName="survey[startDate]"
                    endDateName="survey[endDate]"
                    startDate={startDate}
                    endDate={endDate}
                    showClearDates
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="survey[introText]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    as="textarea"
                    rows={5}
                    label="Mensaje de Bienvenida"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="survey[endText]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    as="textarea"
                    rows={5}
                    label="Mensaje de Salida"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="survey[description]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    as="textarea"
                    rows={5}
                    label="Descripción"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <hr />
          <h4 className="text-uppercase mb-3">Preguntas</h4>
          {addQuestion()}
        </>
      )}
      <Row className="d-flex justify-content-end my-5">
        <Col md={2}>
          <Button variant="warning" block onClick={() => setTogglePreview(!togglePreview)}>
            {togglePreview ? 'Editar' : 'Previsualizar'}
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" block disabled={isSubmitting}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { survey } = props;
  return { survey, rangeDate: [survey.startDate, survey.endDate] };
};

const validationSchema = Yup.object().shape({
  survey: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    startDate: Yup.date().formatdate(),
    endDate: Yup.date().formatdate(),
    description: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos'),
    introText: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos'),
    endText: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos'),
    questionsAttributes: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Debes ingresar una pregunta'),
        questionType: Yup.string().required('Debes ingresar un tipo de pregunta'),
        questionChoicesAttributes: Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required('Debes ingresar una respuesta')
          })
        )
      })
    )
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SurveyForm);
