import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav, Dropdown } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';
import Icon from '../../Icons';
import useDocumentsAbility from '../../../screens/Shared/DirectoryNode/documentAbility';
import './style.scss';

const SidebarProfile = ({ closeSidebar, pathName, children, showChangePassword, showChangePin }) => {
  const ability = useAbility(AbilityContext);
  const { user, currentEmployee, currentCompany } = useSelector(state => state.auth);
  const [myProfileActive, setMyProfileActive] = useState(false);
  const [companyActive, setCompanyActive] = useState(false);
  const [myAttendanceActive, setMyAttendanceActive] = useState(false);
  const [comunicationActive, setComunicationActive] = useState(false);
  const [capacitationActive, setCapacitationActive] = useState(false);
  // const [cultureActive, setCultureActive] = useState(false);
  const documentReadPermission = useDocumentsAbility();

  useEffect(() => {
    const companyDropdown = ['dashboard', 'company', 'groupings'];
    const myProfileDropdown = ['profile', 'requests', 'organization_charts'];
    const myAsistanceDropdown = ['attendances', 'attendances_hash', 'shifts', 'calendar', 'legal_reports'];
    const comunicationDropdown = ['benefits', 'importantdates', 'announcementstasks'];
    const capacitationDropdown = ['courses', 'dnc'];
    // const cultureDropdown = ['surveys', 'performance_evaluations'];
    setCompanyActive(companyDropdown.includes(pathName));
    setMyAttendanceActive(myAsistanceDropdown.includes(pathName));
    setMyProfileActive(myProfileDropdown.includes(pathName));
    setComunicationActive(comunicationDropdown.includes(pathName));
    setCapacitationActive(capacitationDropdown.includes(pathName));
    // setCultureActive(cultureDropdown.includes(pathName));
  }, [pathName]);

  const legalReportsMenu = [
    ability.can('index', 'AttendanceReport') ||
      ability.can('index', 'WorkdayReport') ||
      ability.can('index', 'HolidayReport') ||
      ability.can('index', 'ShiftReport') ||
      ability.can('index', 'OvertimeReport')
  ];

  const requestsMenu = [
    ability.can('index', 'AdvancePayment'),
    ability.can('index', 'Permission'),
    ability.can('index', 'Vacation'),
    ability.can('index', 'Loan'),
    ability.can('index', 'ShiftChangeRequest')
  ];

  const menuCascadeEffect = spanClass => {
    const menuChildrens = document.querySelectorAll(spanClass);
    menuChildrens.forEach((link, index) => {
      const { style } = link;
      style.animation = `cascade .2s ease-in forwards ${index / 10}s`;
    });
  };

  useEffect(() => {
    menuCascadeEffect('.legalreport-effect span');
    menuCascadeEffect('.company-effect span');
    menuCascadeEffect('.myprofile-effect span');
    menuCascadeEffect('.myattendance-effect span');
    menuCascadeEffect('.comunication-effect span');
    menuCascadeEffect('.capacitation-effect span');
    menuCascadeEffect('.culture-effect span');
  });

  const setDropdownKey = (navLink, currentStatus) => {
    switch (navLink) {
      case 'company':
        setMyProfileActive(false);
        setMyAttendanceActive(false);
        setCapacitationActive(false);
        setCompanyActive(!currentStatus);
        setComunicationActive(false);
        // setCultureActive(false);
        break;
      case 'myprofile':
        setMyProfileActive(!currentStatus);
        setMyAttendanceActive(false);
        setCapacitationActive(false);
        setCompanyActive(false);
        setComunicationActive(false);
        // setCultureActive(false);
        break;
      case 'myattendance':
        setMyAttendanceActive(!currentStatus);
        setMyProfileActive(false);
        setCapacitationActive(false);
        setCompanyActive(false);
        setComunicationActive(false);
        // setCultureActive(false);
        break;
      case 'comunication':
        setMyAttendanceActive(false);
        setMyProfileActive(false);
        setCapacitationActive(false);
        setCompanyActive(false);
        setComunicationActive(!currentStatus);
        // setCultureActive(false);
        break;
      case 'capacitation':
        setMyAttendanceActive(false);
        setMyProfileActive(false);
        setCapacitationActive(!currentStatus);
        setCompanyActive(false);
        setComunicationActive(false);
        // setCultureActive(false);
        break;
      case 'culture':
        setMyAttendanceActive(false);
        setMyProfileActive(false);
        setCapacitationActive(false);
        setCompanyActive(false);
        setComunicationActive(false);
        // setCultureActive(!currentStatus);
        break;
      default:
        setMyProfileActive(false);
        setMyAttendanceActive(false);
        setCapacitationActive(false);
        setCompanyActive(false);
        setComunicationActive(false);
        // setCultureActive(false);
        closeSidebar();
    }
  };

  return (
    <div className="scroll-box">
      <Nav className="flex-column" activeKey={pathName} defaultActiveKey={pathName}>
        <div className="d-lg-none">
          {currentCompany.id && ability.can('rrhh', 'Employee') && (
            <Nav.Link eventKey="rrhh" as={Link} to="/dashboard" onClick={closeSidebar}>
              <Icon icon="people-outline" />
              <span className="link-menu position-absolute">Ir a RRHH</span>
            </Nav.Link>
          )}
        </div>
        {currentEmployee && (
          <>
            <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={companyActive}>
              <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('company', companyActive)}>
                <Icon icon="people-outline" />
                <span className="link-menu position-absolute">Empresa</span>
              </Dropdown.Toggle>
              <Dropdown.Menu show={companyActive}>
                <div className="company-effect">
                  <div className="border-line" />
                  <Dropdown.Item
                    eventKey="dashboard"
                    active={pathName === 'dashboard'}
                    as={Link}
                    to="/profile/dashboard"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Dashboard</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="company"
                    active={pathName === 'company'}
                    as={Link}
                    to="/profile/company"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Ficha Empresa</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="groupings"
                    as={Link}
                    active={pathName === 'groupings'}
                    to="/profile/groupings"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Agrupaciones</span>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={myProfileActive}>
              <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('myprofile', myProfileActive)}>
                <Icon icon="stack" />
                <span className="link-menu position-absolute">Mi Perfil</span>
              </Dropdown.Toggle>
              <Dropdown.Menu show={myProfileActive}>
                <div className="myprofile-effect">
                  <div className="border-line" />
                  <Dropdown.Item
                    eventKey="profile"
                    active={pathName === 'profile'}
                    as={Link}
                    to="/profile"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Mi Perfil</span>
                  </Dropdown.Item>
                  {requestsMenu.some(item => item) && (
                    <Dropdown.Item
                      eventKey="requests"
                      active={pathName === 'requests'}
                      as={Link}
                      to="/profile/requests"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Mis Solicitudes</span>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    eventKey="organization_charts"
                    as={Link}
                    active={pathName === 'organization_charts'}
                    to="/profile/organization_charts"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Organigrama</span>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={myAttendanceActive}>
              <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('myattendance', myAttendanceActive)}>
                <Icon icon="people-outline" />
                <span className="link-menu position-absolute">Mi Asistencia</span>
              </Dropdown.Toggle>
              <Dropdown.Menu show={myAttendanceActive}>
                <div className="company-effect">
                  <div className="border-line" />
                  <Dropdown.Item
                    eventKey="attendances"
                    active={pathName === 'attendances'}
                    as={Link}
                    to="/profile/attendances"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Registro de Asistencia</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="attendances_hash"
                    active={pathName === 'attendances_hash'}
                    as={Link}
                    to="/profile/attendances_hash"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Busqueda por Hash</span>
                  </Dropdown.Item>
                  {user.employeeCard.shift && (
                    <Dropdown.Item
                      eventKey="shifts"
                      active={pathName === 'shifts'}
                      as={Link}
                      to="/profile/shifts"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Mi Turno</span>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    eventKey="calendar"
                    active={pathName === 'calendar'}
                    as={Link}
                    to="/profile/calendar"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Mi Calendario</span>
                  </Dropdown.Item>
                  {legalReportsMenu[0] && (
                    <Dropdown.Item
                      eventKey="legal_reports"
                      active={pathName === 'legal_reports'}
                      as={Link}
                      to="/profile/legal_reports"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Reportes DT</span>
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {documentReadPermission && (
              <Nav.Link eventKey="documents" as={Link} to="/profile/documents" onClick={closeSidebar}>
                <Icon icon="folder-open-outline" />
                <span className="link-menu position-absolute">Mis Documentos</span>
              </Nav.Link>
            )}
            <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={comunicationActive}>
              <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('comunication', comunicationActive)}>
                <Icon icon="people-outline" />
                <span className="link-menu position-absolute">Comunicación</span>
              </Dropdown.Toggle>
              <Dropdown.Menu show={comunicationActive}>
                <div className="company-effect">
                  <div className="border-line" />
                  <Dropdown.Item
                    eventKey="benefits"
                    active={pathName === 'benefits'}
                    as={Link}
                    to="/profile/benefits"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Beneficios</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="importantdates"
                    active={pathName === 'importantdates'}
                    as={Link}
                    to="/profile/importantdates"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Fechas Destacadas</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="announcementstasks"
                    active={pathName === 'announcementstasks'}
                    as={Link}
                    to="/profile/announcementstasks"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Anuncios/Tareas</span>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link eventKey="job_offers" as={Link} to="/profile/job_offers" onClick={closeSidebar}>
              <Icon icon="folder-open-outline" />
              <span className="link-menu position-absolute">Mis Ofertas</span>
            </Nav.Link>
            <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={capacitationActive}>
              <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('capacitation', capacitationActive)}>
                <Icon icon="people-outline" />
                <span className="link-menu position-absolute">Capacitaciones</span>
              </Dropdown.Toggle>
              <Dropdown.Menu show={capacitationActive}>
                <div className="company-effect">
                  <div className="border-line" />
                  <Dropdown.Item
                    eventKey="dnc"
                    active={pathName === 'dnc'}
                    as={Link}
                    to="/profile/dnc"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Programa de Capacitación</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="courses"
                    active={pathName === 'courses'}
                    as={Link}
                    to="/profile/courses"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Cursos</span>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={cultureActive}>
              <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('culture', cultureActive)}>
                <Icon icon="file-tray-full-outline" />
                <span className="link-menu position-absolute">Cultura</span>
              </Dropdown.Toggle>
              <Dropdown.Menu show={cultureActive}>
                <div className="company-effect">
                  <div className="border-line" />
                  <Dropdown.Item
                    eventKey="surveys"
                    active={pathName === 'surveys'}
                    as={Link}
                    to="/profile/surveys"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Encuestas</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="performance_evaluations"
                    active={pathName === 'performance_evaluations'}
                    as={Link}
                    to="/profile/performance_evaluations"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Evaluación laboral</span>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown> */}
            {ability.can('index', 'RiskPreventionDocument') && (
              <Nav.Link eventKey="risk_prevention" as={Link} to="/profile/risk_prevention" onClick={closeSidebar}>
                <Icon icon="book-outline" />
                <span className="link-menu position-absolute">Prevención de Riesgo</span>
              </Nav.Link>
            )}
            <div className="d-lg-none">
              <Nav.Link
                eventKey="change_pin"
                onClick={() => {
                  closeSidebar();
                  showChangePin();
                }}
              >
                <Icon icon="pencil" />
                <div className="position-relative position-link width-fixed">
                  <span className="link-menu position-absolute">Cambiar PIN de Firma</span>
                </div>
              </Nav.Link>
              <Nav.Link
                eventKey="change_password"
                onClick={() => {
                  closeSidebar();
                  showChangePassword();
                }}
              >
                <Icon icon="settings-outline" />
                <div className="position-relative position-link width-fixed">
                  <span className="link-menu position-absolute">Cambiar Contraseña</span>
                </div>
              </Nav.Link>
              {children}
            </div>
          </>
        )}
      </Nav>
    </div>
  );
};

export default SidebarProfile;
