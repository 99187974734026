import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCompanyRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/companies', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const businessNameRequest = AwesomeDebouncePromise(
  ({ dispatch, params = {}, failureCallback, successCallback }) =>
    ApiService.request('get', `/companies/business_name`, {
      dispatch,
      params,
      failureCallback,
      successCallback
    }),
  300
);

export const showCompanyRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/companies/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createCompanyRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/companies', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const exportCompanyRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/companies/${id}/export`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const updateCompanyRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/companies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const changeActiveCompanyRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/companies/${id}/change_active`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const generateSecretKeyCompanyRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/companies/${id}/generate_secret_key`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexCompaniesRequest = AwesomeDebouncePromise(indexCompanyRequest, 300);
