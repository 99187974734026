import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../components';
import InternalDocumentationDataTable from './InternalDocumentationDataTable';

const RiskPreventionInternalDocumentationIndex = () => {
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Documentación Interna</h2>
        </Col>
        <Col md={2}>
          <LinkBtn block variant="primary" className="btn-circle mt-2" to="/internal_documentation/new">
            Nuevo
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <h4 className="text-uppercase mt-1 mb-3 mb-md-0">Documentos generados</h4>
        </Col>

      </Row>
      <InternalDocumentationDataTable />
    </>
  );
};

export default RiskPreventionInternalDocumentationIndex;
