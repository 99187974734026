import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Modal, Row, Col } from 'react-bootstrap';

import { FormikDatePicker, FormikNumber, FormikInput, FormikSelect } from '../index';
import hourValues from './FormOptions';

const OvertimeForm = props => {
  const { onHide, submitVariant, errors, touched, action, setFieldValue, setFieldTouched, values, overtime } = props;
  const { hourValue } = overtime;
  const { hours, minutes } = values.overtime;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Field name="overtime[date]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={() => false}
                  label="Fecha"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="overtime[parsedHours]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  label="Horas"
                  rightAddon="hrs"
                  placeholder="1"
                  fieldName="overtime[hours]"
                  value={hours}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  margin="mb-0"
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="overtime[parsedMinutes]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  label="Minutos"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="overtime[minutes]"
                  value={minutes}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  margin="mb-0"
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="overtime[hourValue]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Valor Hora"
                  placeholder="50%"
                  options={hourValues}
                  defaultValue={hourValue}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="overtime[observation]">
              {({ field }) => <FormikInput {...field} as="textarea" label="Observaciones" />}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { date, hours, minutes, observation, hourValue } = props.overtime;
  return {
    overtime: {
      date,
      hours,
      minutes,
      observation,
      hourValue
    }
  };
};

const validationSchema = Yup.object().shape({
  overtime: Yup.object().shape(
    {
      date: Yup.string().required('Debes seleccionar una fecha'),
      hours: Yup.number().when('minutes', {
        is: val => val > 0,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .required('Debes ingresar la cantidad de horas')
          .positive('Debe ser mayor a 0')
          .max(23, 'Debe ser menor a 24 horas')
      }),
      minutes: Yup.number().when('hours', {
        is: val => val > 0,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .required('Debes ingresar la cantidad de minutos')
          .positive('Debe ser mayor a 0')
          .max(59, 'Debe ser menor a 60 minutos')
      }),
      observation: Yup.string().nullable()
    },
    [['hours', 'minutes']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OvertimeForm);
