import { Button, Col, Row, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Field, Form, getIn, withFormik } from 'formik';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import * as Yup from 'yup';
import es from './FormOptions';
import { DualList, FormikSelect, Icon } from '../../components';
import { indexEmployeesRequest } from '../../requests/employees';
import { debounceIndexConceptRequest } from '../../requests/balances';
import DataLoadTable from './DataLoadTable';
import { lastDataLoadRequest } from '../../requests/dataLoads';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const DataLoadForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  isSubmitting,
  setListEmployees,
  listEmployees,
  activeMonth,
  moreData
}) => {
  const {
    dataLoad: { balanceIds, employees }
  } = values;

  const { dataLoad } = values;

  const [listBalances, setListBalances] = useState([]);
  const [options, setOptions] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(0);

  const handleSuccessIndex = response => {
    const vEmployees = camelCaseRecursive(response.data.data);
    const vSelector = [...selectorTypes, ...vEmployees];
    setAllEmployees(vSelector);
    setListEmployees(vSelector);
  };

  const fetchBalances = () => {
    debounceIndexConceptRequest({
      dispatch,
      successCallback: response => {
        let optionsList = [];
        const { data } = response;
        setListBalances(data);
        data.forEach(item => {
          item.options.forEach(option => {
            optionsList = [...optionsList, { ...option, amount: '0' }];
          });
        });
        setOptions(optionsList);
      }
    });
  };

  const fetchEmployees = () => {
    const request = async () => {
      indexEmployeesRequest({
        dispatch,
        params: {
          active: true,
          is_dt: false,
          paginate: false,
          data_load: true,
          active_contracts: true,
          active_staffing: true
        },
        successCallback: handleSuccessIndex
      });
    };
    request();
  };

  const handleBlances = balancesSelected => {
    const employeesIds = employees.map(item => item?.id);
    setFieldValue('dataLoad[balanceIds]', balancesSelected);
    setShowSpinner(1);
    lastDataLoadRequest({
      dispatch,
      params: {
        filter_by_employee: employeesIds.length > 0 ? employeesIds : null,
        filter_by_month_of_process: activeMonth.id,
        filter_by_balance: balancesSelected.length > 0 ? balancesSelected : null
      },
      successCallback: response => {
        const { data } = response.data;

        const filterBalances = options.filter(element => {
          return balancesSelected.includes(element.value);
        });

        const employeesWithBalances = employees.map(employee => {
          const balanceEmployee =
            data.length > 0 ? data.filter(lastDataload => lastDataload.employee_id === employee.id) : [];
          const balanceValueIds = balanceEmployee.map(item => item?.value);

          const balanceEmpty = filterBalances.filter(element => {
            return !balanceValueIds.includes(element.value);
          });

          return {
            ...employee,
            balances: [...balanceEmployee, ...balanceEmpty]
          };
        });
        setFieldValue('dataLoad[employees]', employeesWithBalances);
        setShowSpinner(0);
      }
    });
  };

  const handleEmployees = (field, data) => {
    const employeeFilter = allEmployees.filter(item => item?.id);
    setFieldValue(field, data);

    setFieldValue('dataLoad[balanceIds]', balanceIds);

    if (data.find(({ value }) => value === 'all_employees') === undefined) {
      setListEmployees(data.length > 0 ? employeeFilter : allEmployees);
      setFieldValue('dataLoad[employees]', data || []);
    }

    if (data.find(({ value }) => value === 'all_employees') !== undefined) {
      setListEmployees([]);
      setFieldValue('dataLoad[employees]', employeeFilter);
    }
  };

  useEffect(fetchEmployees, [moreData]);
  useEffect(fetchBalances, []);
  return (
    <Form>
      {activeMonth ? (
        <>
          <Row className="mt-4">
            <Col md={3}>
              <h2 className="mt-3">Carga de Datos</h2>
            </Col>
            <Col md={{ span: 3, offset: 3 }}>
              <h4 className="text-right">
                Mes de Proceso: {activeMonth?.month} / {activeMonth?.year}
              </h4>
            </Col>
            <Col md={3}>
              <Button className="mt-3" variant="primary" block type="submit" disabled={isSubmitting}>
                Guardar
              </Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Field name="dataLoad[employeeIds]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    isMulti
                    label="Seleccionar Colaboradores"
                    placeholder="Buscar empleado.."
                    defaultMultiValues={dataLoad.employeeIds}
                    onChange={data => handleEmployees(field.name, data || [])}
                    options={listEmployees}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    isClearable
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col className="mt-4" xs={12}>
              <DualList
                es={es}
                disabled={dataLoad.employeeIds.length <= 0}
                filterPlaceholder="Buscar Conceptos..."
                options={listBalances}
                selected={dataLoad.employeeIds.length <= 0 ? [] : balanceIds}
                name="dataLoad[balanceIds]"
                onChange={selected => {
                  handleBlances(selected);
                }}
                error={getIn(errors, 'dataLoad[balanceIds]')}
                touched={getIn(touched, 'dataLoad[balanceIds]')}
              />
            </Col>
            {showSpinner ? (
              <Col className="div-content mt-5 mb-5">
                <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              </Col>
            ) : (
              <Col className="mb-5 mt-5">
                {employees.length <= 0 ? (
                  <div style={{ height: 177, textAlign: 'center' }} className="div-center">
                    No existen registros...
                  </div>
                ) : (
                  <DataLoadTable
                    employees={employees}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />
                )}
              </Col>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-4">
            <Col md={3}>
              <h2 className="mt-3">Carga de Datos</h2>
            </Col>
          </Row>
          <Row>
            <Icon
              icon="close-circle"
              height="20px"
              width="20px"
              style={{ marginRight: 5 }}
              className="mt-3 ml-2 text-danger"
            />
            <h4 className="mt-3">Debes tener un mes de proceso abierto para realizar la carga de datos</h4>
          </Row>
        </>
      )}
    </Form>
  );
};

const setInitialValues = props => {
  const { dataLoad } = props;
  return {
    dataLoad
  };
};
const validationSchema = Yup.object().shape({
  dataLoad: Yup.object().shape({
    employeeIds: Yup.array().min(1, 'Debes escoger al menos un colaborador.'),
    balanceIds: Yup.array().min(1, 'Debes escoger al menos un concepto.'),
    employees: Yup.array().of(
      Yup.object().shape({
        balances: Yup.array().of(
          Yup.object().shape({
            amount: Yup.string().required('Debes ingresar un monto')
          })
        )
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting, resetForm, setFieldValue }) => {
  const { formRequest, activeMonth } = props;
  const data = { dataLoads: [] };
  values.dataLoad.employees.forEach(employee => {
    employee.balances.forEach(balance => {
      data.dataLoads.push({
        employee_id: employee.id,
        balance_id: balance.value,
        amount: balance.amount,
        month_of_process_id: activeMonth.id
      });
    });
  });
  formRequest(data, setSubmitting, resetForm, setFieldValue);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false,
  validateOnChange: false,
  validateOnMount: false,
  validateOnBlur: false
})(DataLoadForm);
