import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSelectionProcessesRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/selection_processes', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexProfileSelectionProcessesRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/selection_processes/profile_index', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showSelectionProcessRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/selection_processes/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showSelectionProcessPublicRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/public/selection_processes`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createSelectionProcessRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/selection_processes', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateSelectionProcessRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/selection_processes/${id}/`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    showLeafArrayIndexes: true
  });

export const deleteSelectionProcessRequest = (id, { dispatch, callback, successCallback }) =>
  ApiService.request('delete', `/selection_processes/${id}`, {
    dispatch,
    callback,
    successCallback
  });

export const selectionProcessCardsRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/selection_processes/selection_process_cards', { dispatch, successCallback });

export const debounceIndexSelectionProcessesRequest = AwesomeDebouncePromise(indexSelectionProcessesRequest, 300);
export const debounceIndexProfileSelectionProcessesRequest = AwesomeDebouncePromise(
  indexProfileSelectionProcessesRequest,
  300
);
