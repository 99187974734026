import React from 'react';
import Greeting from '../../../components/Employee/Dashboard/Greeting';
import './style.scss';

const ProfileDashboardIndex = () => {
  return (
    <>
      <Greeting />
    </>
  );
};

export default ProfileDashboardIndex;
