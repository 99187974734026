import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns, DatatableEmployeeName } from '../../components';

const handleNames = items => {
  const names = items.map(item => item.label);
  return names.join(', ');
};

const columns = memoize(clickHandler => [
  {
    name: 'PROCESO DE SELECCIÓN',
    selector: 'selection_processes',
    cell: item => handleNames(item.selection_processes),
    sortable: true,
    grow: '1'
  },
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item} withPhoto={false} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'EMAIL',
    selector: 'email',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TELÉFONO',
    selector: 'phone',
    cell: item => item.parsed_phone,
    sortable: true,
    grow: '1'
  },
  {
    name: 'PROFESIÓN U OFICIO',
    selector: 'careers',
    cell: item => handleNames(item.careers),
    sortable: true,
    grow: '1'
  },
  {
    name: 'VER FICHA',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="SelectionProcess"
        clickHandler={clickHandler}
        withEdit={false}
        withDestroy={false}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
