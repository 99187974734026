import React from 'react';

const subsidiaryInfo = props => {
  const { subsidiary } = props;
  const { addressFloor, addressOffice, code, companyName, email, fullAddress, name, parsedPhone } = subsidiary;
  const active = subsidiary.active ? 'Sí' : 'No';

  return (
    <>
      <div className="info-box">
        <h4 className="text-uppercase">Información Sucursal</h4>

        <p className="info">
          <span>Empresa:</span> {companyName}
        </p>
        <p className="info">
          <span>Nombre:</span> {name}
        </p>
        <p className="info">
          <span>Código:</span> {code}
        </p>
        <hr />
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Contacto</h4>
        <p className="info">
          <span>Dirección:</span> {fullAddress}
        </p>
        {addressFloor && (
          <p className="info">
            <span>Piso:</span> {addressFloor}
          </p>
        )}
        {addressOffice && (
          <p className="info">
            <span>Oficina:</span> {addressOffice}
          </p>
        )}
        {parsedPhone && (
          <p className="info">
            <span>Teléfono:</span> {parsedPhone}
          </p>
        )}
        {email && (
          <p className="info">
            <span>Email:</span> {email}
          </p>
        )}
        <p className="info">
          <span>Activo:</span> {active}
        </p>
      </div>
    </>
  );
};

export default subsidiaryInfo;
