import React from 'react';
import absenceColumns from './Columns';
import AbsenceDataTable from './AbsenceDataTable';

const AbsenceIndex = ({ moreData, setMoreData }) => {
  return (
    <>
      <AbsenceDataTable columns={absenceColumns} moreData={moreData} setMoreData={setMoreData} />
    </>
  );
};

export default AbsenceIndex;
