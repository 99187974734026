import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';

import {
  indexBalancesRequest,
  deleteBalanceRequest,
  exportBalancesRequest,
  importBalanceRequest,
  importTemplateBalanceRequest,
  preImportBalanceRequest
} from '../../requests/balances';
import { sendAlert } from '../../actions/utils';
import {
  ComponentDataTable,
  DefaultModal,
  ButtonTooltip,
  Icon,
  ImportModal,
  SimpleCenteredModal,
  ActiveDot
} from '../../components';
import { useAuthorization } from '../../services/hooks';
import { downloadFile } from '../../services/utils';

const columns = memoize((clickHandler, permissions) => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '3'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {permissions.read && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
        {permissions.update && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'edit')}
            variant="circle-warning"
            className="btn-circle"
            size="sm"
            text="Editar"
          >
            <Icon icon="pencil" />
          </ButtonTooltip>
        )}
        {permissions.destroy && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
            disabled={!item.destroyable}
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const BalanceDataTable = ({ asset }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [balances, setBalances] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleMoreData = () => {
    setMoreData(!moreData);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    setModalShow(false);
    setOnRequest(false);
  };

  useEffect(handleMoreData, [asset]);

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setBalances(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    const type = asset ? 'asset' : 'discount';
    indexBalancesRequest({
      dispatch,
      params: {
        filter_type: type,
        ...params
      },
      successCallback: handleSuccessIndex,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRemove = () => {
    setModalShow(false);
    handleMoreData();
  };

  const removeBalance = balanceId => {
    deleteBalanceRequest(balanceId, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const handleModalConfirm = item => {
    removeBalance(item.id);
  };

  const handleButtonClick = (item, action) => {
    const body = asset ? 'haber' : 'descuento';
    switch (action) {
      case 'show':
        history.push(`/balances/${asset ? 'assets' : 'discounts'}/${item.id}`);
        break;
      case 'edit':
        history.push(`/balances/${asset ? 'assets' : 'discounts'}/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalTitle(`Eliminar ${asset ? 'Haber' : 'Descuento'}`);
        setModalAction(() => handleModalConfirm);
        setModalBody(`¿Estás seguro que deseas eliminar este ${body}?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleDownload = () => {
    setOnRequest(true);

    exportBalancesRequest({
      dispatch,
      params: { filter_type: asset ? 'asset' : 'discount' },
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const downloadModal = () => {
    setModalTitle(`Exportar ${asset ? 'haberes' : 'descuentos'}`);
    setModalShow(true);
    setModalBody(`¿Estás seguro que deseas descargar ${asset ? 'haberes' : 'descuentos'}?`);
    setModalAction(() => handleDownload);
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setSimpleModalShow(true);
        break;
      case 'export':
        downloadModal();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const permissions = {
    read: useAuthorization(asset ? 'read_asset' : 'read_discount', 'Balance'),
    update: useAuthorization(asset ? 'update_asset' : 'update_discount', 'Balance'),
    destroy: useAuthorization(asset ? 'destroy_asset' : 'destroy_discount', 'Balance')
  };
  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, permissions)}
        data={balances}
        totalRows={amount}
        moreData={moreData}
        withMassActions
        massActions={
          <>
            {useAuthorization(`export_${asset ? 'asset' : 'discount'}`, 'Balance') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Exportar"
                onClick={() => handleMassAction('export')}
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )}
            {useAuthorization(`import_${asset ? 'asset' : 'discount'}`, 'Balance') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Importar"
                onClick={() => handleMassAction('import')}
              >
                <Icon icon="cloud-upload-outline" />
              </ButtonTooltip>
            )}
          </>
        }
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />

      <SimpleCenteredModal
        title={`Importar ${asset ? 'haberes' : 'descuentos'}`}
        body={
          <ImportModal
            onDropUploaded={preImportBalanceRequest}
            handleTemplate={importTemplateBalanceRequest}
            onHide={importBalanceRequest}
            hideModal={() => setSimpleModalShow(false)}
            params={{ filter_type: asset ? 'asset' : 'discount' }}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default BalanceDataTable;
