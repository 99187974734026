import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { BasicTextArea, FormikSelect } from '../../components';
import { statusFun } from '../../components/SharedFields/FormOptions';

const MasiveProcessNotificationForm = props => {
  const { errors, touched, setFieldValue, values, handleModalClose, onHide, isSubmitting } = props;
  const { employees: vEmployees } = values;
  const { statunFun: vStatusFun } = vEmployees;

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Field name="employees[status_notification]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Estado de Notificacion"
                    placeholder="Seleccionar"
                    options={statusFun}
                    defaultValue={vStatusFun}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {values.employees.status_notification === 'RE' && (
              <Col md={6}>
                <Field name="employees[reason_rejection]">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      abbr
                      label="Motivo de Rechazo"
                      maxLength={100}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-center" variant="outline-info" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button type="submit" variant="primary" disabled={isSubmitting} onClick={onHide}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { employees } = props;

  return {
    employees
  };
};

const validationSchema = Yup.object().shape({
  employees: Yup.object().shape({
    status_notification: Yup.string()
      .required('Debes seleccionar un estado')
      .max(100, 'Deben ser menos que 100 caracteres'),
    reason_rejection: Yup.string().when('status_notification', {
      is: val => val !== 'AC',
      then: Yup.string().required('Debes ingresar un motivo'),
      otherwise: Yup.string().nullable()
    })
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(MasiveProcessNotificationForm);
