import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import { updateSelectionProcessRequest, showSelectionProcessRequest } from '../../requests/selectionProcesses';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicSelectionProcess from './basicSelectionProcess';
import SelectionProcessForm from './SelectionProcessForm';

const SelectionProcessEdit = ({ match }) => {
  const selectionProcessId = match.params.id;
  const [selectionProcess, setSelectionProcess] = useState(basicSelectionProcess);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchSelectionProcess = () => {
    showSelectionProcessRequest(selectionProcessId, {
      dispatch,
      successCallback: response => setSelectionProcess(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdateRequest = params => {
    updateSelectionProcessRequest(selectionProcessId, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Proceso de selección actualizado con éxito' }));
        history.push(`/selection_processes/${selectionProcessId}`);
      },
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchSelectionProcess, []);

  return (
    <>
      <Row className="my-3">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/selection_processes">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={11} className="mt-3">
          <h2 className="text-uppercase mt-1">Editar proceso de selección</h2>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <SelectionProcessForm action="edit" selectionProcess={selectionProcess} formRequest={handleUpdateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default SelectionProcessEdit;
