import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ComponentDataTable, DefaultModal } from '../../components';
import { debounceIndexGroupRequest, deleteGroupRequest } from '../../requests/groups';
import { delayMethod } from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const GroupDataTable = ({ columns }) => {
  const [groups, setGroups] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalAction, setModalAction] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexGroupRequest({
        dispatch,
        params: { ...params },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setGroups(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleModalConfirm = item => {
    setOnRequest(true);
    deleteGroupRequest(item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Grupo eliminado con éxito.' }));
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'edit':
        history.push(`/groups/${item.id}/edit`);
        break;
      case 'destroy':
        setModalTitle('Eliminar Grupo');
        setModalBody(`¿Estás seguro que deseas eliminar el grupo '${item.name}'?`);
        setModalShow(true);
        setModalItem(item);
        setModalAction(() => handleModalConfirm);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_management':
        return { sort_job_managements: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={groups}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        resourceRequest={handleIndexRequest}
        onRowClicked={row => handleActions(row, 'edit')}
        pointerOnHover
        moreData={moreData}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default GroupDataTable;
