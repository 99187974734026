import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { LinkBtn, Icon } from '../../components';

import { showContractRequest } from '../../requests/contracts';
import basicContract from './contract';
import ContractInfoContent from '../../components/Employee/EmployeeShow/ContractInfoContent';

const TerminatedContractShow = ({ match }) => {
  const [contract, setContract] = useState(basicContract);
  const dispatch = useDispatch();
  const contractId = match.params.id;

  const fetchContract = () => {
    showContractRequest(contractId, {
      dispatch,
      successCallback: response => setContract(camelCaseRecursive(response.data))
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchContract, [contractId]);

  const { employee } = contract;

  return (
    <>
      <Row className="mt-4">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to={`/employees/${employee.id}?tab=contract`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">{employee.fullName}</h2>
          <div className="d-flex subtitle-simple-modal">
            <p className="text-capitalize mb-0 mt-0">{employee.jobTitle}</p>
            {employee.jobTitle && employee.email && <p className="ml-1 mr-1">|</p>}
            <p>{employee.email}</p>
          </div>
        </Col>
      </Row>
      <Row className="mb-4 info-block info-block-warning">
        <Col>
          <h4 className="text-uppercase mt-0 mb-0">
            Estás viendo una versión de un contrato finiquitado de {employee.fullName}
          </h4>
        </Col>
        <Col md={2}>
          <LinkBtn variant="warning inverse-hover" block to={`/employees/${employee.id}?tab=contract`}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <div className="d-flex align-items-center mt-3">
        <h3 className="text-uppercase mb-0 mr-1">Contrato Finiquitado {contract.correlativeIdentification}</h3>
      </div>
      <Row className="mb-3">
        <Col>
          <ContractInfoContent contract={contract} />
        </Col>
      </Row>
    </>
  );
};

export default TerminatedContractShow;
