import React from 'react';

import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikCheckBox, FormikTimePicker, FormikInput } from '../../../components';
import { delayMethod } from '../../../services/utils';

const AttendanceFormReplace = props => {
  const { errors, touched, setFieldValue, setFieldTouched, values, handleModalClose, onHide, isSubmitting } = props;
  const { attendance } = values;
  const { correctionType } = attendance;

  return (
    <Form>
      <Modal.Body>
        <Row>
          {(correctionType === 'replacing' || attendance.name) && (
            <Col md={12}>
              <h4>{`${attendance.name} - ${attendance.parseAttendanceDate}`}</h4>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={6}>
            <Field name="attendance[shiftDayStartTime]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Hora Entrada Turno"
                  value={attendance.shiftDayStartTime}
                  touched={getIn(touched, field.name)}
                  readOnly
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="attendance[shiftDayEndTime]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Hora Salida Turno"
                  value={attendance.shiftDayEndTime}
                  touched={getIn(touched, field.name)}
                  readOnly
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="attendance[arrivalMark]">
              {({ field }) => (
                <FormikTimePicker
                  {...field}
                  abbr
                  label="Hora Entrada"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeFormat="h:mm aa"
                  placeholder=""
                  onChange={date => {
                    setFieldValue(
                      field.name,
                      date.toLocaleTimeString('en-ES', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })
                    );
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="attendance[departureMark]">
              {({ field }) => (
                <FormikTimePicker
                  {...field}
                  abbr
                  label="Hora Salida"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeFormat="h:mm aa"
                  placeholder=""
                  onChange={date => {
                    setFieldValue(
                      field.name,
                      date.toLocaleTimeString('en-ES', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })
                    );
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="defaultArribalShift">
              {({ field }) => (
                <>
                  <FormikCheckBox
                    {...field}
                    value={field.value === 'true' || field.value === true}
                    checked={field.value === 'true' || field.value === true}
                    field={field}
                    label="Usar hora entrada turno"
                    custom
                    onClick={() => {
                      const valueSet =
                        typeof field.value === 'undefined' || field.value === false ? attendance.shiftDayStartTime : '';
                      setFieldValue('attendance[arrivalMark]', valueSet);
                    }}
                  />
                </>
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="defaultDepartureShift">
              {({ field }) => (
                <>
                  <FormikCheckBox
                    {...field}
                    value={field.value === 'true' || field.value === true}
                    checked={field.value === 'true' || field.value === true}
                    field={field}
                    label="Usar hora salida turno"
                    custom
                    onClick={() => {
                      const valueSet =
                        typeof field.value === 'undefined' || field.value === false ? attendance.shiftDayEndTime : '';
                      setFieldValue('attendance[departureMark]', valueSet);
                    }}
                  />
                </>
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" onClick={onHide} disabled={isSubmitting}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { correctionType } = props;
  const attendance = { ...props.attendance, correctionType };

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    arrivalMark: Yup.string().required('Debes escoger la hora de entrada'),
    departureMark: Yup.string().required('Debes escoger la hora de salida')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(AttendanceFormReplace);
