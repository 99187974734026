import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn } from '../../components';
import SurveyDataTable from './SurveyDataTable';
import columns from './Columns';

const SurveyIndex = () => (
  <>
    <Row className="mt-4 mb-4">
      <Col>
        <h2 className="mb-3 mt-3">Encuestas</h2>
      </Col>
      <Col md={3}>
        <LinkBtn variant="primary" block to="/surveys/new">
          Nuevo
        </LinkBtn>
      </Col>
    </Row>
    <SurveyDataTable columns={columns} />
  </>
);

export default SurveyIndex;
