import React from 'react';
import { useFormikContext } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import {
  BasicCard,
  FormikInput,
  NestedAttributes,
  BasicDropdown,
  Icon,
  FormikNumber,
  FormikSelect
} from '../../components';
import './style.scss';
import SkillFields from './SkillFields';

const FormStep2 = ({ setStep, objective }) => {
  const { isSubmitting, setFieldValue, values } = useFormikContext();
  const { skillsAttributes } = values.performanceEvaluation;

  const prevStep = () => {
    setStep(1);
    window.scrollTo(0, 0);
  };

  const SkillsFields = () => {
    const resultObjectives = [];
    const resultCompetences = [];
    skillsAttributes.forEach((body, index) => {
      if (body._destroy) {
        resultObjectives.push(undefined);
        resultCompetences.push(undefined);
      }
      const response = (
        <Col md={11} className="bg-light round-10 ml-3 mr-n3 mb-3">
          <SkillFields
            key={`skill-${index.toString()}`}
            valuePath={`performanceEvaluation[skillsAttributes][${index}]`}
            skillAttribute={body}
          />
        </Col>
      );
      if (body.skillType === 'objective') {
        resultObjectives.push(response);
        resultCompetences.push(undefined);
      } else {
        resultObjectives.push(undefined);
        resultCompetences.push(response);
      }
    });
    resultObjectives.push(
      <Col md={11} className="bg-light round-10 ml-3 mr-n3 mb-3">
        <Row className="skill-fields">
          <Col md={2} xl={3} className="sample-row pl-0 pl-xl-3">
            <FormikInput label="Nombre" disabled />
          </Col>
          <Col md={3} className="sample-row pl-0 pl-xl-3">
            <FormikInput label="Descripción" disabled />
          </Col>
          <Col md={3} className="sample-row pl-0 pl-xl-3">
            <FormikInput label="Plazo" disabled />
          </Col>
          <Col md={3} xl={2} className="sample-row pl-0 pl-xl-3">
            <FormikNumber
              labelClass="text-truncate"
              fieldName=""
              leftAddon="%"
              label="Cumplimiento Requerido"
              disabled
            />
          </Col>
          <Col md={1} className="sample-row pl-0 pl-xl-3">
            <BasicDropdown
              variant="no-border mr-auto ml-4 mt-4"
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  <Icon icon="chevron-down" height="20px" width="20px" />
                </span>
              }
              items={[]}
            />
          </Col>
        </Row>
      </Col>
    );

    resultCompetences.push(
      <Col md={11} className="bg-light round-10 ml-3 mr-n3 mb-3">
        <Row className="skill-fields">
          <Col md={2} xl={3} className="sample-row pl-0 pl-xl-3">
            <FormikInput label="Nombre" disabled />
          </Col>
          <Col md={3} className="sample-row pl-0 pl-xl-3">
            <FormikInput label="Definición" disabled />
          </Col>
          <Col md={3} className="sample-row pl-0 pl-xl-3">
            <FormikSelect placeholder="Seleccionar" label="Tipo" disabled />
          </Col>
          <Col md={3} xl={2} className="sample-row pl-0 pl-xl-3">
            <FormikNumber
              labelClass="text-truncate"
              fieldName=""
              leftAddon="%"
              label="Cumplimiento Requerido"
              disabled
            />
          </Col>
          <Col md={1} className="sample-row pl-0 pl-xl-3">
            <BasicDropdown
              variant="no-border mr-auto ml-4 mt-4"
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  <Icon icon="chevron-down" height="20px" width="20px" />
                </span>
              }
              items={[]}
            />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <Col xs={12}>
          <BasicCard
            title="Definición de Competencias"
            classNameCard="competence-card"
            text={
              <NestedAttributes
                mapInputs={resultCompetences}
                arrayValues={skillsAttributes}
                setFieldValue={setFieldValue}
                valuePath="performanceEvaluation[skillsAttributes]"
                newAttributes={{
                  name: '',
                  skillType: 'competence',
                  description: '',
                  requiredAmount: '',
                  requireAmountType: 'percentage',
                  questionChoicesAttributes: []
                }}
                rowClass="mr-0"
              />
            }
            size="100%"
            divBody
          />
        </Col>
        {objective && (
          <Col xs={12}>
            <BasicCard
              title="Objetivos para el evaluado"
              classNameCard="competence-card"
              text={
                <NestedAttributes
                  removeFirstItem
                  mapInputs={resultObjectives}
                  arrayValues={skillsAttributes}
                  setFieldValue={setFieldValue}
                  valuePath="performanceEvaluation[skillsAttributes]"
                  newAttributes={{
                    name: '',
                    skillType: 'objective',
                    description: '',
                    requiredValue: '',
                    requireAmountType: 'percentage',
                    questionChoicesAttributes: []
                  }}
                  rowClass="mr-0"
                />
              }
              size="100%"
              divBody
            />
          </Col>
        )}
      </>
    );
  };
  return (
    <Row className="d-flex justify-content-end my-5">
      {SkillsFields()}
      <Col md={2}>
        <Button variant="info" block onClick={prevStep} disabled={isSubmitting}>
          Volver
        </Button>
      </Col>
      <Col md={2}>
        <Button type="submit" block disabled={isSubmitting}>
          Guardar
        </Button>
      </Col>
    </Row>
  );
};

export default FormStep2;
