const collationTypes = [
  { label: 'Paga durante licencia', value: 'with_collation' },
  { label: 'No paga durante licencia', value: 'without_collation' }
];

const gratificationTypes = [
  { label: 'Anual', value: 'annual' },
  { label: 'Mensual', value: 'monthly' },
  { label: 'Sin gratificación', value: 'without_gratification' }
];

const representantIdentificationTypes = [
  { label: 'RUT', value: 'rut' },
  { label: 'Pasaporte', value: 'passport' },
  { label: 'Otra Identificación', value: 'other_id' }
];

const mobilizationTypes = [
  { label: 'Paga durante licencia', value: 'with_mobilization' },
  { label: 'No paga durante licencia', value: 'without_mobilization' }
];

const workActivityOptions = [
  {
    label: 'Agricultura, Servicios Agrícolas, Silvicultura y Pesca',
    value: 'agriculture_agricultural_services_forestry_fisheries'
  },
  { label: 'Minas, Petróleos y Canteras', value: 'mines_oil_quarries' },
  { label: 'Industrias Manufactureras', value: 'industrias_manufactureras' },
  { label: 'Construcción', value: 'construction' },
  { label: 'Electricidad, gas y agua', value: 'electricity_gas_water' },
  { label: 'Comercio', value: 'commerce' },
  { label: 'Transporte, Almacenamiento y Comunicaciones', value: 'transportation_storage_communications' },
  {
    label: 'Finanzas, Seguros Bienes Muebles y Servicios Técnicos, Profesionales y Otros',
    value: 'finance_insurance_movable_professional_other_services'
  },
  {
    label: 'Servicios Estatales, Sociales, Personales e Internacionales',
    value: 'state_social_personal_international_services'
  },
  { label: 'Actividad No Especificada', value: 'unspecified_activity' }
];

export { collationTypes, gratificationTypes, representantIdentificationTypes, mobilizationTypes, workActivityOptions };
