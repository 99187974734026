import React, { useEffect } from 'react';
import { Field, getIn } from 'formik';
import { Col } from 'react-bootstrap';

import { FormikInput } from '../../components';

const SelectionProcessStageForm = ({ errors, touched, index, setFieldValue }) => {
  const updatePosition = () => {
    setFieldValue(`selectionProcess[selectionProcessStagesAttributes][${index}][position]`, index);
  };

  useEffect(updatePosition, [index]);

  return (
    <>
      <Col md={11}>
        <Field name={`selectionProcess[selectionProcessStagesAttributes][${index}][name]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              label="Nombre Etapa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default SelectionProcessStageForm;
