import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTopicRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/topics', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createTopicRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/topics', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const updateTopicRequest = (id, { dispatch, params, formData, successCallback }) =>
  ApiService.request('put', `/topics/${id}`, {
    dispatch,
    params,
    formData,
    successCallback
  });

export const deleteTopicRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/topics/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const exportTopicRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/topics/export.xlsx', {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const preImportTopicRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/topics/pre_import', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const importTopicRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/topics/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const importTemplateTopicRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/topics/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });

export const debounceIndexTopicsRequest = AwesomeDebouncePromise(indexTopicRequest, 300);
