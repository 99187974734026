import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, BasicActionBtns, ButtonTooltip, DatatableEmployeeName, Icon } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'fullName',
    cell: item => (
      <DatatableEmployeeName
        name="fullName"
        fileName="fileInfo"
        fileUrl="fileUrl"
        item={item}
        onClick={() =>
          clickHandler(item, (item.admission === 'approved' && 'edit') || (item.admission === 'rejected' && 'show'))
        }
      />
    ),
    sortable: true,
    grow: '1.5'
  },
  {
    name: 'CARGO',
    selector: 'jobTitle',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÁREA',
    selector: 'jobManagement',
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'ADMISIÓN',
    selector: 'admission',
    cell: item => (
      <ApprovementStatus
        status={item.admission}
        translatedStatus={item.translatedAdmission}
        onClick={() =>
          clickHandler(item, (item.admission === 'approved' && 'edit') || (item.admission === 'rejected' && 'show'))
        }
      />
    ),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => (
      <ApprovementStatus
        status={item.status}
        translatedStatus={item.translatedStatus}
        onClick={() =>
          clickHandler(item, (item.admission === 'approved' && 'edit') || (item.admission === 'rejected' && 'show'))
        }
      />
    ),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {item.admission === 'pending' && (
          <>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'approved')}
              variant="circle-success"
              className="btn-circle"
              size="sm"
              text="Admitir"
            >
              <Icon icon="checkmark-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'rejected')}
              variant="circle-danger"
              className="btn-circle"
              size="sm"
              text="Rechazar"
            >
              <Icon icon="close-circle" />
            </ButtonTooltip>
          </>
        )}
        <BasicActionBtns
          item={item}
          resource="EmployeeCourse"
          clickHandler={clickHandler}
          withEdit={item.admission === 'approved'}
          withShow={false}
        />
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    grow: '1',
    minWidth: '100px'
  }
]);

export default columns;
