const emptyBalance = (balanceType = '') => ({
  id: '',
  active: true,
  advanceGratification: false,
  amount: '',
  amountType: '',
  balanceType,
  bankAccount: '',
  bankAccountType: '',
  bankId: '',
  benefit: false,
  bookkeeperId: '',
  calculationOrder: '',
  calculationType: '',
  code: '',
  compensationFundId: '',
  defaultToShow: '',
  discountType: '',
  ineCode: '',
  mobilizationAssignment: false,
  name: '',
  overdraft: false,
  paymentReceptor: false,
  paymentType: '',
  receptorEmail: '',
  remunerationType: '',
  settlementConsideration: false,
  taxable: false,
  tributable: false,
  unitOfAccount: ''
});

const showBalance = {
  id: '',
  active: true,
  advanceGratification: false,
  amount: '',
  bankAccount: '',
  bank: {},
  benefit: '',
  bookkeeper: {},
  calculationOrder: '',
  code: '',
  compensationFund: {},
  name: '',
  overdraft: false,
  paymentReceptor: false,
  receptorEmail: '',
  mobilizationAssignment: false,
  settlementConsideration: false,
  taxable: false,
  tributable: false,
  translatedAmountType: '',
  translatedBalanceType: '',
  translatedBankAccountType: '',
  translatedDefaultToShow: '',
  translatedDiscountType: '',
  translatedIneCode: '',
  translatedPaymentType: '',
  translatedRemunerationType: '',
  translatedUnitOfAccount: ''
};

export { emptyBalance, showBalance };
