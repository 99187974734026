const months = [
  { label: 'Enero', value: 'january' },
  { label: 'Febrero', value: 'february' },
  { label: 'Marzo', value: 'march' },
  { label: 'Abril', value: 'april' },
  { label: 'Mayo', value: 'may' },
  { label: 'Junio', value: 'june' },
  { label: 'Julio', value: 'july' },
  { label: 'Agosto', value: 'august' },
  { label: 'Septiembre', value: 'september' },
  { label: 'Octubre', value: 'october' },
  { label: 'Noviembre', value: 'november' },
  { label: 'Diciembre', value: 'december' }
];

const years = Array.from(Array(80), (e, i) => ({ label: i + 2000, value: i + 2000 }));

export { years, months };
