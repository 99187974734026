import ApiService from '../services/apiService';

export const recordsReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/records/records_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export default recordsReport;
