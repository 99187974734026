import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import useConceptHook from './useConceptHook';
import { conceptTypeEnum, classificationTypeEnum, ineCodeEnum, remunerationTypeEnum } from './Form/FormOptions';
import { LinkBtn, Icon } from '../../components';

const ConceptShow = ({ match }) => {
  const balanceId = match.params.id;

  const [concept, setBalance] = useState({});
  const { handleFetchConcept } = useConceptHook();

  const fetchConcept = () => {
    handleFetchConcept(balanceId, setBalance);
  };
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchConcept, [balanceId]);

  if (Object.keys(concept).length > 2) {
    const conceptLabel = conceptTypeEnum.find(item => item.value === concept.balanceType).label ?? '';
    const remunerationType =
      concept.remunerationType && remunerationTypeEnum.find(item => item.value === concept.remunerationType).label;
    const conceptType = concept.balanceType;
    const ineCode = concept.ineCode && ineCodeEnum.find(item => item.value === concept.ineCode).label;
    const classificationType =
      concept.classificationType &&
      classificationTypeEnum.find(item => item.value === concept.classificationType).label;

    return (
      <>
        <Row className="mt-4 mb-4 align-items-center">
          <Col xs={1}>
            <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/remunerations_settings">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
        </Row>
        <div className="info-box mt-5">
          <h3 className="text-uppercase mb-5">Información del Concepto</h3>
          <Row>
            <Col md={6}>
              <p className="info">
                <span>Tipo de Concepto:</span>
                {conceptLabel}
              </p>
            </Col>
            <Col md={6}>
              <p className="info">
                <span>Código:</span> {concept.code}
              </p>
            </Col>
          </Row>
          <hr />
        </div>
        <div className="info-box">
          <Row>
            <Col md={6}>
              <p className="info">
                <span>Descripción:</span> {concept.name}
              </p>
            </Col>
            {['asset', 'discount'].includes(conceptType) && (
              <Col md={6}>
                <p className="info">
                  <span>Tipo de Remuneración:</span> {remunerationType}
                </p>
              </Col>
            )}
            {conceptType === 'input_parameters' && (
              <Col md={6}>
                <p className="info">
                  <span>Máximo:</span> {concept.maxInput}
                </p>
              </Col>
            )}
          </Row>
          <hr />
        </div>

        {conceptType === 'asset' && (
          <div className="info-box">
            <Row>
              <>
                <Col md={6}>
                  <p className="info">
                    <span>Imponible:</span> {concept.taxable ? 'Si' : 'No'}
                  </p>
                </Col>

                <Col md={6}>
                  <p className="info">
                    <span>Tributable:</span> {concept.tributable ? 'Si' : 'No'}
                  </p>
                </Col>
              </>
            </Row>
            <hr />
          </div>
        )}

        {['asset', 'discount'].includes(conceptType) && (
          <div className="info-box">
            <Row>
              <Col md={6}>
                <p className="info">
                  <span>Formulación:</span> {concept.formulation ? 'Si' : 'No'}
                </p>
              </Col>

              <Col md={6}>
                <p className="info">
                  <span>Proporcional:</span> {concept.proportional ? 'Si' : 'No'}
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        )}

        {conceptType === 'discount' && (
          <div className="info-box">
            <Row>
              <Col md={6}>
                <p className="info">
                  <span>Legal:</span> {concept.legal ? 'Si' : 'No'}
                </p>
              </Col>
              <Col md={6}>
                <p className="info">
                  <span>Costo Empresa:</span> {concept.companyCost ? 'Si' : 'No'}
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        )}

        <div className="info-box">
          <Row>
            {['asset', 'discount'].includes(conceptType) && (
              <Col md={6}>
                <p className="info">
                  <span>Se imprime en liquidación:</span> {concept.printOnLiquidation ? 'Si' : 'No'}
                </p>
              </Col>
            )}

            <Col md={6}>
              <p className="info">
                <span>Activo:</span> {concept.active ? 'Si' : 'No'}
              </p>
            </Col>
          </Row>
          <hr />
        </div>

        {conceptType === 'asset' && (
          <div className="info-box">
            <Row>
              <>
                <Col md={6}>
                  <p className="info">
                    <span>Base de indemnización:</span> {concept.compensationBase ? 'Si' : 'No'}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="info">
                    <span>Muestra valor cero:</span> {concept.showZeroValue ? 'Si' : 'No'}
                  </p>
                </Col>
              </>
            </Row>
            <hr />
          </div>
        )}

        {['asset', 'discount'].includes(conceptType) && (
          <div className="info-box">
            <Row>
              <Col md={6}>
                <p className="info">
                  <span>Clasificación:</span> {classificationType}
                </p>
              </Col>

              <Col md={6}>
                <p className="info">
                  <span>Orden en la liquidación:</span> {concept.calculationOrder}
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        )}

        {['asset', 'discount'].includes(conceptType) && (
          <div className="info-box">
            <Row>
              {conceptType === 'asset' && (
                <Col md={6}>
                  <p className="info">
                    <span>Agrupación INE:</span> {ineCode}
                  </p>
                </Col>
              )}
              <Col md={6}>
                <p className="info">
                  <span>Cuenta Contable:</span> {concept.bookkeeper.label}
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        )}
        <div className="info-box">
          <Row>
            <Col md={12}>
              <p className="info">
                <span>Observación:</span> {concept.observation}
              </p>
            </Col>
          </Row>
          <hr />
        </div>
      </>
    );
  }

  return <></>;
};

export default ConceptShow;
