import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, DatatableEmployeeName } from '../../components';

const handleNames = items => {
  const names = items?.map(item => item.label);
  return names?.join(', ');
};

const columns = memoize(clickHandler => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'fullName',
    cell: item => (
      <DatatableEmployeeName
        item={item.candidate}
        withPhoto={false}
        name="fullName"
        onClick={() => clickHandler(item, 'show')}
      />
    ),
    sortable: false,
    grow: '1'
  },
  {
    name: 'TIPO DE CANDIDATO',
    selector: 'translatedCandidateType',
    cell: item => item?.candidate?.translatedCandidateType,
    sortable: false,
    grow: '1'
  },
  {
    name: 'PROFESIÓN U OFICIO',
    selector: 'careers',
    cell: item => handleNames(item?.candidate?.careers),
    sortable: false,
    grow: '1'
  },
  {
    name: 'COMUNA',
    selector: 'communeName',
    cell: item => item?.candidate?.communeName,
    sortable: false,
    grow: '1'
  },
  {
    name: 'ETAPA',
    selector: 'selectionProcessStage',
    cell: item => item?.selectionProcessStage?.label,
    sortable: false,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="SelectionProcess" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
