import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikInput, FormikSelect } from '../../../../components';
import { workActivity } from '../../FormOptions';

const CompanyTab = props => {
  const {
    values,
    errors,
    touched,
    codeWorkActivity,
    listRegion,
    codeRegionCompinId,
    setFieldValue,
    setFieldTouched,
    handleRegionChange,
    listCommuneCompin,
    codeCommuneCompin
  } = props;
  return (
    <Row>
      <Col md={6}>
        <Field name="transact[companyRut]">
          {({ field }) => (
            <FormikInput
              {...field}
              readOnly
              label="RUT"
              values={values}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="transact[companyName]">
          {({ field }) => (
            <FormikInput
              {...field}
              readOnly
              values={values}
              label="Razón social"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="transact[companyPhone][phone]">
          {({ field }) => (
            <FormikInput
              {...field}
              readOnly
              label="Teléfono"
              values={values}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={8}>
        <Field name="transact[companyFullAddress]">
          {({ field }) => (
            <FormikInput
              {...field}
              readOnly
              label="Dirección"
              values={values}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="transact[codeWorkActivity]">
          {({ field }) => (
            <FormikSelect
              {...field}
              label="Actividad Laboral de la Empresa"
              placeholder="Seleccione Actividad Laboral de la Empresa"
              options={workActivity}
              defaultValue={codeWorkActivity}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="transact[codeRegionCompinId]">
          {({ field }) => (
            <FormikSelect
              {...field}
              // abbr
              label="Región COMPIN"
              placeholder="Seleccione Región COMPIN"
              options={listRegion}
              defaultValue={codeRegionCompinId}
              onChange={data => handleRegionChange(field, data)}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="transact[codeCommuneCompin]">
          {({ field }) => (
            <FormikSelect
              {...field}
              // abbr
              label="Comuna COMPIN"
              placeholder="Seleccione Comuna COMPIN"
              options={listCommuneCompin}
              defaultValue={codeCommuneCompin}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default CompanyTab;
