import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Navbar, Form, FormControl, Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import {
  deleteCostCenterRequest,
  exportCostCentersRequest,
  importCostCenterRequest,
  importTemplateCostCenterRequest,
  debounceIndexCostCentersRequest,
  preImportCostCenterRequest
} from '../../requests/costCenters';
import {
  AccordionTree,
  DefaultModal,
  ButtonTooltip,
  Icon,
  SimpleCenteredModal,
  ImportModal,
  SimpleShow
} from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { sendAlert } from '../../actions/utils';
import { downloadFile, camelCaseEmptyStringRecursive } from '../../services/utils';

const CostCenterAccordion = ({ editCostCenter, moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [itemShow, setItemShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState('');
  const [textInput, setTextInput] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [costCenters, setCostCenters] = useState([]);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setSimpleModalShow(false);
    setModalShow(false);
    setOnRequest(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setOnRequest(false);
  };

  const addNestedElement = (costCentersValues, id, data) => {
    const costCenters2 = costCentersValues.map(item => {
      if (item.id !== id) {
        if (item.children && item.children.length > 0) {
          const newItem = item;
          newItem.children = addNestedElement(item.children, id, data);
          return newItem;
        }
        return item;
      }
      return data;
    });
    return costCenters2;
  };

  const handleSuccessIndex = (response, successType) => {
    if (successType) {
      setCostCenters(addNestedElement(costCenters, successType, response.data.data[0]));
    } else {
      setCostCenters(response.data.data);
    }
    setOnRequest(false);
  };

  const handleRequest = (params = { tree_data: '3', sort_column: 'name' }, successType) => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexCostCentersRequest({
        dispatch,
        params,
        successCallback: response => handleSuccessIndex(response, successType)
      });
    request();
  };

  useEffect(handleRequest, [moreData]);

  const fetchRequestWithId = item => {
    handleRequest(
      {
        tree_data_from_id: `2 ${item.id}`
      },
      item.id
    );
  };

  const handleSuccessRemove = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setOnRequest(false);
    setModalShow(false);
    handleRequest({ tree_data: '3', sort_column: 'name' });
  };

  const removeCostCenter = costCenterId => {
    setOnRequest(true);
    deleteCostCenterRequest(costCenterId, {
      dispatch,
      successCallback: handleSuccessRemove('Centro de Costo eliminado correctamente'),
      failureCallback: handleFailureRequest
    });
  };

  const handleModalConfirm = item => {
    removeCostCenter(item.id);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setItemShow(true);
        setModalItem(camelCaseEmptyStringRecursive(item));
        break;
      case 'edit':
        editCostCenter(item);
        break;
      case 'remove':
        setModalTitle('Eliminar centro de costo');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar este centro de costos?');
        setModalItem(item);
        setModalAction(() => handleModalConfirm);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleDownload = () => {
    setOnRequest(true);

    exportCostCentersRequest({
      dispatch,
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const downloadModal = () => {
    setModalTitle('Exportar centros de costo');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas descargar centros de costo?');
    setModalAction(() => handleDownload);
  };

  const textInputChange = () => {
    if (textInput === '') return setMoreData(!moreData);
    return handleRequest({ query: textInput, sort_column: 'name' });
  };

  useEffect(textInputChange, [textInput]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="justify-content-end">
        <Col md={4} xl={3}>
          <Form.Group>
            <FormControl
              placeholder="Buscar"
              name="textInput"
              className="mt-4"
              value={textInput}
              onChange={e => setTextInput(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Navbar className="pr-0 mt-n3">
        {ability.can('export', 'CostCenter') && (
          <ButtonTooltip variant="circle-primary" text="Exportar" onClick={downloadModal}>
            <Icon width={21} className="text-primary" icon="download-2" />
          </ButtonTooltip>
        )}
        {ability.can('import', 'CostCenter') && (
          <ButtonTooltip variant="circle-primary" text="Importar" onClick={() => setSimpleModalShow(true)}>
            <Icon width={21} className="text-primary" icon="cloud-upload-outline" />
          </ButtonTooltip>
        )}
      </Navbar>
      <AccordionTree
        items={costCenters}
        handleButtonClick={(item, action) => handleButtonClick(item, action)}
        permitedActions={['show', 'edit', 'remove']}
        fetchRequestWithId={fetchRequestWithId}
        onRequest={onRequest}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title="Importar centros de costo"
        body={
          <ImportModal
            onDropUploaded={preImportCostCenterRequest}
            handleTemplate={importTemplateCostCenterRequest}
            onHide={importCostCenterRequest}
            hideModal={() => setSimpleModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
      <SimpleCenteredModal
        title="Centro de Costo"
        body={<SimpleShow item={modalItem} handleClose={() => setItemShow(false)} withActive />}
        show={itemShow}
        onHide={() => setItemShow(false)}
      />
    </>
  );
};

export default CostCenterAccordion;
