import React from 'react';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';

const ItemsInfo = props => {
  const { item } = camelCaseEmptyStringRecursive(props);
  const renderStatus = status => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'approved':
        return 'Aprobado';
      case 'rejected':
        return 'Rechazado';
      default:
        return '-';
    }
  };
  return (
    <div className="info-box">

      <p className="info">
        <span style={{ width: '150px' }}>Solicitante:</span> {item.employee.fullName}
      </p>

      <p className="info">
        <span>Fecha:</span> {item.attendanceDate}
      </p>

      {item.arrivalMark && (
        <p className="info">
          <span>Hora / Entrada:</span> {item.arrivalMark}
        </p>
      )}

      {item.arrivalMark && (
        <p className="info">
          <span>Hora / Salida:</span> {item.departureMark}
        </p>
      )}

      <p className="info">
        <span>Remplazado Por:</span> {item.replacementBy}
      </p>

      <p className="info">
        <span style={{ width: '150px' }}>Estado:</span> {renderStatus(item.status)}
      </p>
      {item.status === 'rejected' && (
        <p className="info">
          <span style={{ width: '150px' }}>Motivo del Rechazo:</span> {item.observation}
        </p>
      )}
    </div>
  );
};

export default ItemsInfo;
