import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikCheckBox, FormikInput, RegionCommune, PhoneWithCode } from '../../components';

const BranchOfficeForm = props => {
  const { onHide, submitVariant, errors, touched, action, setFieldValue, values, isSubmitting } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const {
    branchOffice: { phoneCountryCode, fullAddress }
  } = values;

  const findInArrays = (array, name) =>
    array?.address_components?.find(element => element.types.find(type => type === name))?.long_name;

  const selectedPlace = place => {
    const route = findInArrays(place, 'route');
    const number = findInArrays(place, 'street_number');
    setFieldValue('branchOffice[address]', route || '');
    setFieldValue('branchOffice[addressNumber]', number || '');
    setFieldValue('branchOffice[fullAddress]', place.formatted_address);
    setFieldValue('branchOffice[lat]', place.geometry?.location.lat());
    setFieldValue('branchOffice[lng]', place.geometry?.location.lng());
  };

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={10}>
            <Field name="branchOffice[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Código"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="branchOffice[fullAddress]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección Completa"
                  selectedPlace={val => selectedPlace(val)}
                  defaultValue={fullAddress}
                  formType="addressAutocomplete"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="branchOffice[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección"
                  disabled
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[addressNumber]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Número"
                  disabled
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[addressFloor]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="number"
                  label="Piso"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[addressOffice]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Oficina"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <RegionCommune regionAbbr communeAbbr modelKey="branchOffice" />
          <Col md={6}>
            <PhoneWithCode
              label="Teléfono"
              countryCodeField="branchOffice[phoneCountryCode]"
              phoneField="branchOffice[phone]"
              workPhoneCountryCode={phoneCountryCode}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col md={6}>
            <Field name="branchOffice[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Field name="branchOffice[active]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" />}
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { branchOffice } = props;
  return { branchOffice };
};

const validationSchema = Yup.object().shape({
  branchOffice: Yup.object().shape(
    {
      active: Yup.boolean(),
      fullAddress: Yup.string()
        .required('Debes ingresar una dirección')
        .max(120, 'Deben ser menos que 200 caracteres'),
      address: Yup.string()
        .required('Debes ingresar una dirección')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      addressFloor: Yup.number().nullable(true),
      addressNumber: Yup.string().required('Debes ingresar el número de la dirección'),
      addressOffice: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(true),
      code: Yup.string()
        .required('Debes ingresar un código')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      communeId: Yup.string().required('Debes seleccionar una comuna'),
      email: Yup.string()
        .email('Debes ingresar un e-mail válido')
        .nullable(),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      phone: Yup.string()
        .when('phoneCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .nullable(true),
      phoneCountryCode: Yup.string().when('phone', {
        is: val => val,
        then: Yup.string().required('Debes seleccionar un código'),
        otherwise: Yup.string().nullable()
      }),
      regionId: Yup.string().required('Debes seleccionar una región')
    },
    [['phone', 'phoneCountryCode']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BranchOfficeForm);
