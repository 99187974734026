import React from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { createGroupRequest } from '../../requests/groups';
import { sendAlert } from '../../actions/utils';
import GroupForm from './GroupForm';
import group from './group';

const GroupNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (groupValues, setSubmitting) => {
    createGroupRequest({
      dispatch,
      params: snakeCaseKeys(groupValues),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Grupo creado con éxito' }));
        history.push(`/groups`);
      },
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <Row className="my-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/groups">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">Nuevo Grupo de Comunicacion</h2>
      </Row>
      <GroupForm group={group} action="new" formRequest={handleCreateRequest} />
    </>
  );
};

export default GroupNew;
