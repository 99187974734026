import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import * as Yup from 'yup';
import { FormikNumber } from '../../../../components';
import Remunerations from '../Remunerations';
import FileTransact from '../FileTransact';

const RemunerationsTab = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  listTypePensionScheme,
  listPensionInstitution,
  rutEmployee,
  numLicense,
  employee,
  payrolls
}) => {
  const { evictionPercentage, taxableAmountPreviousMonth } = values.transact;

  return (
    <>
      <Row>
        <Col md={2}>
          <Field name="transact[parsedevictionPercentage]">
            {({ field }) => (
              <FormikNumber
                {...field}
                rightAddon="%"
                fieldName="transact[evictionPercentage]"
                value={evictionPercentage}
                setFieldValue={setFieldValue}
                label="Porcentaje de Desahucio"
                decimalScale={2}
                thousandSeparator={false}
                decimalSeparator="."
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="transact[parsedTaxableAmountPreviousMonth]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                value={taxableAmountPreviousMonth}
                fieldName="transact[taxableAmountPreviousMonth]"
                setFieldValue={setFieldValue}
                label="Monto del Mes Anterior"
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Remunerations
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        listTypePensionScheme={listTypePensionScheme}
        listPensionInstitution={listPensionInstitution}
      />
      <FileTransact rutEmployee={rutEmployee} numLicense={numLicense} employee={employee} payrolls={payrolls} />
    </>
  );
};
export const remunerationsAttribute = Yup.object().shape({
  PreviousIncomeForecastCode: Yup.string().required('Debes seleccionar un régimen prov.'),
  yearMonthPrevRent: Yup.string().required('Debes seleccionar una mes'),
  nDaysPrevRem: Yup.number().required('Debes ingresar N° de días')
});
export default RemunerationsTab;
