import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { InputSelect } from '../../components';
import { debounceIndexEmployeeDncRequest } from '../../requests/trainingPrograms';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { debounceIndexJobTitleRequest } from '../../requests/jobTitles';
import { debounceIndexCostCentersRequest } from '../../requests/costCenters';
import { debounceIndexBranchOfficesRequest } from '../../requests/branchOffices';
import { downloadFile } from '../../services/utils';
import TrainingProgramDatatable from './TrainingProgramDatatable';

const TrainingProgramIndex = () => {
  const [query, setQuery] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [employeeDncs, setEmployeeDncs] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [jobManagements, setJobManagements] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();

  const handleIndexRequest = async (params, format, responseType = 'json') => {
    setOnRequest(true);
    const sendParams = {
      status: 'approved',
      filter_job_management: jobManagements,
      filter_job_title: jobTitles,
      filter_branch_office: branchOffices,
      filter_cost_center: costCenters,
      ...params
    };
    setQuery({ ...sendParams });
    debounceIndexEmployeeDncRequest({
      dispatch,
      params: sendParams,
      format,
      responseType,
      successCallback: response => {
        if (format === '.xlsx') {
          downloadFile(response);
        } else {
          const { data, metadata } = response.data;
          setEmployeeDncs(data);
          setAmount(metadata.amount);
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    let type = false;
    debounceIndexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 100,
        type
      },
      successCallback: data => {
        callback(resultFetchData(data, (type = true)));
      }
    });
  };

  const fetchCostCenters = (inputValue, callback) => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h2 className="mt-3">Programa de Capacitación</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="text-uppercase font-weight-light mb-3">Filtros</h4>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={3}>
          <InputSelect
            isMulti
            label="Área"
            placeholder="Todas"
            values={resultFetchData}
            request={fetchJobManagments}
            onChange={data => {
              const values = data?.map(item => item.value);
              setJobManagements(values || []);
              handleIndexRequest({ ...query, filter_job_management: values || [] });
            }}
          />
        </Col>
        <Col md={3}>
          <InputSelect
            isMulti
            label="Cargo"
            placeholder="Todas"
            values={resultFetchData}
            request={fetchJobTitles}
            onChange={data => {
              const values = data?.map(item => item.value);
              setJobTitles(values || []);
              handleIndexRequest({ ...query, filter_job_title: values || [] });
            }}
          />
        </Col>
        <Col md={3}>
          <InputSelect
            isMulti
            label="Centro de Costos"
            placeholder="Todas"
            values={resultFetchData}
            request={fetchCostCenters}
            onChange={data => {
              const values = data?.map(item => item.value);
              setCostCenters(values || []);
              handleIndexRequest({ ...query, filter_cost_center: values || [] });
            }}
          />
        </Col>
        <Col md={3}>
          <InputSelect
            isMulti
            label="Lugar de Prestación de Servicios"
            placeholder="Todas"
            values={resultFetchData}
            request={fetchBranchOffices}
            onChange={data => {
              const values = data?.map(item => item.value);
              setBranchOffices(values || []);
              handleIndexRequest({ ...query, filter_branch_office: values || [] });
            }}
          />
        </Col>
      </Row>
      <TrainingProgramDatatable
        onRequest={onRequest}
        handleIndexRequest={handleIndexRequest}
        query={query}
        employeeDncs={employeeDncs}
        amount={amount}
      />
    </>
  );
};

export default TrainingProgramIndex;
