export default {
  name: '',
  customGroup: false,
  genderId: '',
  maxAge: '',
  minAge: '',
  branchOfficeIds: [],
  syndicateIds: [],
  jobManagementIds: [],
  jobTitleIds: [],
  employeeIds: [],
  employees: []
};
