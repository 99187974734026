import React from 'react';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
// https://www.npmjs.com/package/react-infinite-scroll-component

const InfinitScrollComponent = ({ dataLength, label, children, ...props }) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      className="px-1 overflow-hidden"
      loader={
        <div className="containerSpinnerLoad center-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      endMessage={
        <p className="text-muted mt-5" style={{ textAlign: 'center' }}>
          <b>No hay {dataLength > 0 ? `más ${label}` : label} para mostrar</b>
        </p>
      }
      {...props}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfinitScrollComponent;
