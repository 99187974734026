import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';

import {
  ButtonTooltip,
  DefaultModal,
  EmployeeSearchModal,
  FormikCheckBox,
  FormikNumber,
  FormikInput,
  FormikSelect,
  Icon,
  LinkBtn,
  SimpleCenteredModal
} from '../../components';
import { indexEmployeesRequest } from '../../requests/employees';
import { camelCaseEmptyStringRecursive, sortByAttribute } from '../../services/utils';
import PayrollGroupTable from './PayrollGroupTable';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const PayrollGroupForm = ({ errors, isSubmitting, setFieldValue, touched, values, ...props }) => {
  const { action, payrollGroup, setCancelSubmit } = props;
  const { employeeSelector, payrollGroup: vPayrollGroup } = values;
  const { limitFifteenPercentage, payrollsAttributes: vPayrollsAttributes } = vPayrollGroup;
  const { payrollsAttributes } = payrollGroup;

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [selector, setSelector] = useState(selectorTypes);
  const dispatch = useDispatch();

  const handleFieldValues = (employee, index) => {
    const findPayrollIndex = vPayrollsAttributes.findIndex(payroll => payroll.employeeId === employee.id);
    const payrollIndex = findPayrollIndex >= 0 ? findPayrollIndex : index;
    const fieldValue = `payrollGroup[payrollsAttributes][${payrollIndex}]`;
    if (findPayrollIndex !== -1) setFieldValue(`[${fieldValue}][_destroy]`, false);
    setFieldValue(`[${fieldValue}][employee]`, employee);
    setFieldValue(`[${fieldValue}][employeeId]`, employee.id);
    setFieldValue(`[${fieldValue}][makePayroll]`, true);
    setFieldValue(`[${fieldValue}][updatedAt]`, Date.now());
  };

  const findSavedPayrolls = employees => {
    if (action === 'new') return employees;
    const employeeIds = [];
    payrollsAttributes.forEach((payroll, index) => {
      handleFieldValues(payroll.employee, index);
      employeeIds.push(payroll.employeeId);
    });
    const vSelector = employees.filter(employee => !employeeIds.includes(employee.id));
    if (vSelector.length === employees.length) return employees;
    return vSelector;
  };

  const handleSuccessRequest = response => {
    const employees = camelCaseEmptyStringRecursive(response.data.data);
    const addEmployees = findSavedPayrolls(employees);
    setSelector([...selectorTypes, ...addEmployees]);
    setOnRequest(false);
  };

  const fetchEmployees = () => {
    setOnRequest(true);
    indexEmployeesRequest({
      dispatch,
      params: { active: true, is_dt: false, payroll_process: true, paginate: false },
      successCallback: handleSuccessRequest
    });
  };

  const handleRemoveValue = ({ index, makePayroll, _destroy, employee, updateValues = false }) => {
    if (updateValues) {
      const allPayrolls = vPayrollsAttributes.filter(vPayroll => vPayroll.employeeId !== employee.id);
      setFieldValue('payrollGroup[payrollsAttributes]', allPayrolls);
    } else {
      const fieldValue = `payrollGroup[payrollsAttributes][${index}]`;
      setFieldValue(`[${fieldValue}][makePayroll]`, makePayroll);
      setFieldValue(`[${fieldValue}][_destroy]`, _destroy);
    }
  };

  const removeAnEmployee = employee => {
    const addToSelector = [...selector, employee];
    setSelector(addToSelector);
    vPayrollsAttributes.forEach((payroll, index) => {
      if (payroll.employeeId !== employee.id) return;
      if (payroll.id) handleRemoveValue({ index, makePayroll: false, _destroy: true });
      else handleRemoveValue({ employee, updateValues: true });
    });
  };

  const addAnEmployee = vSelector => {
    const addEmployee = selector.find(selected => selected.value === employeeSelector);
    handleFieldValues(addEmployee, vPayrollsAttributes.length);
    setSelector(vSelector);
  };

  const addAllEmployees = () => {
    const addEmployees = selector.filter(selected => selected.id !== undefined);
    addEmployees.forEach((employee, index) => handleFieldValues(employee, vPayrollsAttributes.length + index));
    setSelector([]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== employeeSelector);
    if (vSelector.length === selector.length) return;
    if (employeeSelector === 'all_employees') addAllEmployees();
    else addAnEmployee(vSelector);
  };

  const handleSearch = selectedEmployees => {
    setModalShow(false);
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    if (vSelector.length === selector.length) return;
    const addEmployees = selector.filter(selected => selectedEmployees.includes(selected.value));
    addEmployees.forEach((employee, index) => handleFieldValues(employee, vPayrollsAttributes.length + index));
    setSelector(vSelector);
  };

  const validatePayroll = employee => Object.keys(employee.payrollErrors);

  const validateIfHasPayroll = employee => {
    const { payrollProcessId, payrollGroupId } = employee.lastPayroll;
    return payrollProcessId === payrollGroup.payrollProcessId && payrollGroupId !== payrollGroup.id;
  };

  const validatePayrollGroup = (validate = 'all') => {
    const validations = vPayrollsAttributes.filter(payroll => {
      const payrollErrors = validatePayroll(payroll.employee);
      const anyPayrollError = payrollErrors.length > 0;
      if (validate === 'all') return anyPayrollError === true;
      if (validate === 'errors') {
        return (
          payrollErrors.includes('noContract') ||
          payrollErrors.includes('missingFields') ||
          payrollErrors.includes('noRut')
        );
      }
      if (validate === 'payroll') return validateIfHasPayroll(payroll.employee);
      return '';
    });
    return validations.length > 0 || (validate === 'all' && vPayrollsAttributes.length === 0);
  };

  const setModal = (title, body, size = 'lg') => {
    setModalSize(size);
    setModalTitle(title);
    setModalBody(body);
    setModalShow(true);
  };

  const submitAvailable = () => setCancelSubmit(validatePayrollGroup());

  const limitConfirmationModal = () => {
    if (limitFifteenPercentage) {
      setDefaultModalShow(true);
    } else {
      setFieldValue('payrollGroup[limitFifteenPercentage]', true);
    }
  };

  const unlimitFifteenPercentage = () => {
    setDefaultModalShow(false);
    setFieldValue('payrollGroup[limitFifteenPercentage]', false);
  };

  useEffect(fetchEmployees, [payrollsAttributes]);
  useEffect(submitAvailable, [validatePayrollGroup]);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute h-100" style={{ top: '125px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Form>
        <Row className="my-4">
          <Col xs={2} md={1} className="mt-3">
            <LinkBtn block variant="circle-dark" to={`/payroll_processes/${payrollGroup.payrollProcessId}`}>
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <Col xs={10} md={9} className="mt-3">
            <h2 className="text-uppercase my-1">Nueva Prenómina</h2>
            <h4 className="text-uppercase my-0">
              {payrollGroup.month} {payrollGroup.year}
            </h4>
          </Col>
          <Col md={2} className="mt-3">
            <Button block type="submit" variant="primary" disabled={isSubmitting || validatePayrollGroup()}>
              Siguiente
            </Button>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <h4 className="text-uppercase text-dark mt-2 mb-4">Configuraciones</h4>
            <Row>
              <Col md={6}>
                <Field name="payrollGroup[name]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Nombre de Grupo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={3}>
                <Field name="payrollGroup[limitFifteenPercentage]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      type="switch"
                      margin="my-4"
                      label="Limitación del 15%"
                      tooltipText="Limita los descuentos voluntarios por el 15% del Total Haberes"
                      tooltipClass="ml-2 mt-2 pt-1"
                      checked={vPayrollGroup.limitFifteenPercentage}
                      field={field}
                      onChange={() => limitConfirmationModal()}
                    />
                  )}
                </Field>
              </Col>
              <Col md={3}>
                <Field name="payrollGroup[annualGratification]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      type="switch"
                      margin="my-4"
                      label="Calcular Gratificación Anual"
                      tooltipText="Gratificación anual calculada de Enero a Diciembre del año anterior"
                      tooltipClass="ml-2 mt-2 pt-1"
                      checked={vPayrollGroup.annualGratification}
                      field={field}
                    />
                  )}
                </Field>
              </Col>
              {vPayrollGroup.annualGratification && (
                <Col md={3}>
                  <Field name="payrollGroup[parsedEarningsOfPeriod]">
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        label="Utilidades del Período"
                        tooltipText="Aplica solo para contratos con gratificación no garantizada y considera solo las utilidades positivas"
                        leftAddon="$"
                        value={vPayrollGroup.earningsOfPeriod}
                        fieldName="payrollGroup[earningsOfPeriod]"
                        setFieldValue={setFieldValue}
                        error={errors}
                        touched={touched}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Row className="align-items-center mb-3">
          <Col md={4}>
            <Field name="employeeSelector">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Agregar Trabajadores"
                  placeholder="Seleccionar Trabajador"
                  options={sortByAttribute(selector, 'fullName')}
                  defaultValue="all_employees"
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={7} md={2} xl={2}>
            <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
              Agregar
            </Button>
          </Col>
          <Col xs={5} md={1}>
            <ButtonTooltip
              variant="circle-primary"
              className="advance-search"
              text="Búsqueda Avanzada"
              onClick={() =>
                setModal(
                  'Buscar Empleados',
                  <EmployeeSearchModal
                    customParams={{ active: true }}
                    handleClose={() => setModalShow(false)}
                    formRequest={handleSearch}
                  />
                )
              }
            >
              <Icon className="w-100 h-100" icon="people-circle" />
            </ButtonTooltip>
          </Col>
          <Col className="my-3">
            {validatePayrollGroup('errors') && (
              <p className="text-danger font-weight-bold mb-0">
                Existen trabajadores que presentan problemas para realizar la liquidación
              </p>
            )}
            {validatePayrollGroup('payroll') && (
              <p className="text-warning font-weight-bold mb-0">
                Existen trabajadores que ya tienen una liquidación para este proceso
              </p>
            )}
          </Col>
        </Row>
        <Row className="table-custom-background mb-5 mx-1">
          <PayrollGroupTable
            payrolls={vPayrollsAttributes}
            removeAnEmployee={removeAnEmployee}
            setModal={setModal}
            validateIfHasPayroll={validateIfHasPayroll}
            validatePayroll={validatePayroll}
          />
        </Row>
        <DefaultModal
          title="¿Seguro que quiere desactivar el límite del 15%?"
          body="Legalmente es recomendado no desactivarlo"
          show={defaultModalShow}
          handleClose={() => setDefaultModalShow(false)}
          handleConfirm={() => unlimitFifteenPercentage()}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
        <SimpleCenteredModal
          size={modalSize}
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Form>
    </>
  );
};

const setInitialValues = ({ payrollGroup }) => {
  const { annualGratification, earningsOfPeriod, limitFifteenPercentage, name, payrollsAttributes } = payrollGroup;
  return {
    payrollGroup: {
      annualGratification,
      earningsOfPeriod,
      limitFifteenPercentage,
      name,
      payrollsAttributes
    },
    employeeSelector: 'all_employees'
  };
};

const validationSchema = Yup.object().shape({
  payrollGroup: Yup.object().shape({
    annualGratification: Yup.boolean(),
    earningsOfPeriod: Yup.string().nullable(),
    limitFifteenPercentage: Yup.boolean(),
    name: Yup.string().required('Debes ingresar un nombre de grupo')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PayrollGroupForm);
