import ApiService from '../services/apiService';

export const indexJointCommitteeRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/joint_committees', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showJointCommitteeRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/joint_committees/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createJointCommitteeRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('post', '/joint_committees', {
    dispatch,
    params,
    formData: true,
    callback,
    successCallback
  });

export const updateJointCommitteeRequest = (id, { dispatch, params, callback, successCallback }) =>
  ApiService.request('put', `/joint_committees/${id}`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const deleteJointCommitteeRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/joint_committees/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });
