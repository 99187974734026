import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row, Col, Button, Tab, Nav } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';
import { useSetTab } from '../../../services/hooks';

import { SimpleCenteredModal, VacationCard } from '../../../components';
import { profilePermissions } from '../../../requests/dashboards';
import { AbilityContext } from '../../../config/abilityContext';
import PermissionDataTable from '../../Shared/Permission/PermissionDataTable';
import VacationDataTable from '../../Shared/Vacation/VacationDataTable';

import PermissionCard from './PermissionCard';
import PermissionNew from './PermissionNew';
import columns from './Columns';

const PermissionIndex = ({ currentEmployee }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const [administrativeVacationCard, setAdministrativeVacationCard] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [permissionCard, setPermissionCard] = useState({});
  const [toggle, setToggle] = useState(false);
  const ability = useAbility(AbilityContext);
  const location = useLocation();
  const dispatch = useDispatch();

  const findAdvancedSettings = options => {
    return advanceSettings.filter(item => options.includes(item.code)).some(item => item.textValue === 'true');
  };

  const canAdministrative = findAdvancedSettings(['administrative_days']);
  const canLeave = findAdvancedSettings(['paid_leave', 'without_paid_leave']);
  const showNewBtn = (canLeave && ability.can('create', 'Permission')) || canAdministrative;

  const defaultKey = () => {
    if (canLeave) return 'perms-datatable';
    return 'administrative-datatable';
  };

  const checkTab = () => {
    const tab = new URLSearchParams(location.search).get('tab');
    if (canLeave && tab === 'perms-datatable') return 'perms-datatable';
    return defaultKey();
  };

  const [key, setKey] = useSetTab(defaultKey(), location);

  const updateDefaultKey = () => setKey(checkTab());

  useEffect(updateDefaultKey, [advanceSettings]);

  const fetchEmployeeCard = () => {
    profilePermissions({
      dispatch,
      successCallback: response => {
        setPermissionCard(camelCaseRecursive(response.data.permission_card));
        setAdministrativeVacationCard(camelCaseRecursive(response.data.administrative_vacation_card));
      }
    });
  };

  const handleSuccessCreate = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleOpenModal = () => {
    setModalShow(true);
    setModalBody(<PermissionNew employeeId={currentEmployee} onSuccess={handleSuccessCreate} />);
  };

  const buttonToggle = () => setToggle(!toggle);

  const changeTab = () => {
    if (!showNewBtn) {
      setKey('administrative-datatable');
    }
  };

  useEffect(fetchEmployeeCard, []);
  useEffect(changeTab, [advanceSettings]);

  return (
    <Row className="mt-1 mb-3">
      <Col>
        <Row>
          <Col md={{ span: 3, offset: 9 }}>
            {showNewBtn && (
              <Button variant="primary" className="d-none d-md-block" block onClick={handleOpenModal}>
                Nuevo
              </Button>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-between border-responsive d-md-none">
          <Button variant="outline-primary" onClick={buttonToggle}>
            {toggle ? 'Ver datos' : 'Ver registro'}
          </Button>
          {showNewBtn && (
            <Button variant="primary" onClick={handleOpenModal}>
              Nuevo
            </Button>
          )}
        </div>
        <div className={`${toggle ? '' : 'd-block'} d-none d-md-block`}>
          <Row className="mt-4">
            {canLeave && (
              <Col md={6}>
                <PermissionCard className="flex-fill" permissionCard={permissionCard} />
              </Col>
            )}
            {canAdministrative && (
              <Col md={6}>
                <VacationCard
                  className="flex-fill"
                  title="Días Administrativos"
                  vacationCard={administrativeVacationCard}
                />
              </Col>
            )}
          </Row>
        </div>
        <div className={`${toggle ? 'd-block' : ''} d-none d-md-block`}>
          <Tab.Container id="permissions-datatable.tabs" activeKey={key} mountOnEnter onSelect={k => setKey(k)}>
            <Nav variant="pills" className="ab-pills">
              {canLeave && (
                <Nav.Item>
                  <Nav.Link eventKey="perms-datatable">Permisos</Nav.Link>
                </Nav.Item>
              )}
              {canAdministrative && (
                <Nav.Item>
                  <Nav.Link eventKey="administrative-datatable">Días Administrativos</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              {canLeave && (
                <Tab.Pane eventKey="perms-datatable">
                  <PermissionDataTable
                    columns={columns}
                    moreData={moreData}
                    setMoreData={setMoreData}
                    customParams={{ filter_employee: currentEmployee }}
                  />
                </Tab.Pane>
              )}
              {canAdministrative && (
                <Tab.Pane eventKey="administrative-datatable">
                  <VacationDataTable
                    columns={columns}
                    moreData={moreData}
                    setMoreData={setMoreData}
                    preName="administrative_days"
                    customParams={{
                      filter_employee: currentEmployee,
                      filter_vacation_type: 'administrative_days, administrative_days_openning_balance',
                      sort_status: 'status'
                    }}
                  />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
        <SimpleCenteredModal
          title="Solicitar Permiso"
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Col>
    </Row>
  );
};

export default PermissionIndex;
