import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikInput } from '../../components';

const CertificationForm = props => {
  const { onHide, submitVariant, errors, touched, action, isSubmitting } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={9}>
            <Field name="certification[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="certification[code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Código"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { certification } = props;
  return { certification };
};

const validationSchema = Yup.object().shape({
  certification: Yup.object().shape({
    code: Yup.string()
      .required('Debes ingresar un código')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CertificationForm);
