import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { LongCard, FormikTimePicker } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { HRAdvancePaymentsRequest } from '../../requests/dashboards';
import AdvancePaymentDataTable from '../Shared/AdvancePayment/AdvancePaymentDataTable';
import columns from './HRColumns';

const AdvancePaymentIndex = ({ location }) => {
  const [cards, setCards] = useState({});
  const urlDate = new URLSearchParams(location.search).get('date');
  const initialDate = urlDate !== null ? new Date(urlDate) : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const customParams = { status: 'approved', date: moment(selectedDate).format('MM_YY') };
  const [moreData, setMoreData] = useState(false);
  const [defaultStartDate, setDefaultStartDate] = useState(moment(initialDate).startOf('month'));
  const [defaultEndDate, setDefaultEndDate] = useState(moment(initialDate).endOf('month'));
  const [workflowIds, setWorkflowIds] = useState([]);
  const parsedSelectedDate = moment(selectedDate).format('MMMM');
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchDashboardCards = () => {
    const request = async () => {
      HRAdvancePaymentsRequest({
        params: { date: moment(selectedDate).format('DD/MM/YYYY') },
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  useEffect(fetchDashboardCards, [selectedDate]);

  return (
    <>
      <Row className="m-top mb-2">
        <Col>
          <h2 className="m-top mb-3 mt-0">Anticipos - {parsedSelectedDate}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <LongCard
            variant="primary"
            title={cards.countThisMonth}
            label={`Anticipos solicitados en ${parsedSelectedDate}`}
          />
        </Col>
        <Col md={6} xs={12}>
          <LongCard
            variant="info"
            title={cards.amountThisMonth}
            label={`Monto de anticipos en ${parsedSelectedDate}`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormikTimePicker
            abbr
            setLocale
            label={`ANTICIPOS DE ${moment(selectedDate)
              .format('MMMM')
              .toUpperCase()}:`}
            placeholder="mm/aaaa"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selected={selectedDate}
            onChange={date => {
              setSelectedDate(date);
              setDefaultStartDate(moment(date).startOf('month'));
              setDefaultEndDate(moment(date).endOf('month'));
            }}
          />
        </Col>
        <Col md={3} style={{ marginTop: 26 }}>
          <Button
            variant="primary"
            block
            disabled={workflowIds < 1}
            onClick={() => {
              history.push({
                pathname: '/bank_documents',
                state: {
                  ids: workflowIds,
                  backUrl: `${location.pathname}?date=${selectedDate}`,
                  type: 'advance_payment',
                  date: moment(selectedDate).format('MMMM YYYY')
                }
              });
            }}
          >
            Generar Documentos de Transferencia
          </Button>
        </Col>
      </Row>
      <AdvancePaymentDataTable
        setWorkflowIds={setWorkflowIds}
        columns={columns}
        customParams={customParams}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        moreData={moreData}
        setMoreData={setMoreData}
        withDateSelect={false}
        hhrr
      />
    </>
  );
};

export default AdvancePaymentIndex;
