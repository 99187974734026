import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import {
  approveLoanRequest,
  deleteLoanRequest,
  exportLoansRequest,
  importLoanRequest,
  importTemplateLoanRequest,
  indexLoanRequest,
  preImportLoanRequest,
  rejectLoanRequest
} from '../../../requests/loans';
import {
  ComponentDataTable,
  SimpleCenteredModal,
  DefaultModal,
  Reminder,
  ImportModal,
  Icon,
  ButtonTooltip
} from '../../../components';
import { downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import LoanForm from './LoanForm';
import LoanRejectForm from './LoanRejectForm';
import ItemsInfo from '../../Employee/Items/ItemsInfo';

const LoanDataTable = ({
  columns,
  customParams,
  defaultEndDate = null,
  defaultStartDate = null,
  employee,
  employeeId,
  handleFailureRequest,
  moreData,
  nameRangePicker,
  preName,
  setMoreData,
  withImport
}) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [loans, setLoans] = useState([]);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('lg');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [query, setQuery] = useState({});
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setLoans(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setOnRequest(false);
  };

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const sendParams = { ...customParams, ...params };
    setQuery(sendParams);
    indexLoanRequest({ dispatch, params: sendParams, successCallback: handleSuccessIndex });
  };

  const handleSuccessRequest = status => {
    switch (status) {
      case 'approved':
        dispatch(sendAlert({ kind: 'success', message: 'Préstamo aceptado correctamente' }));
        break;
      case 'rejected':
        dispatch(sendAlert({ kind: 'success', message: 'Préstamo rechazado correctamente' }));
        break;
      default:
        break;
    }
    setMoreData(!moreData);
    setModalShow(false);
  };

  const handleApproveRequest = (loan, setSubmitting) => {
    const { id: loanId } = loan.loan;
    const myParams = snakeCaseKeys(loan);

    approveLoanRequest(loanId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessRequest('approved'),
      failureCallback: error => {
        dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
        setMoreData(!moreData);
        setModalShow(false);
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleRejectRequest = loan => {
    const { id: loanId } = loan.loan;
    const myParams = snakeCaseKeys(loan);

    rejectLoanRequest(loanId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessRequest('rejected')
    });
  };

  const handleApproveModal = loan => {
    const reviewerSignature = loan.requestReviewerSignature;
    const reqAmountLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_amount_limit')[0]?.integerValue;

    setModalTitle(reviewerSignature ? 'Firmar Documento' : 'Aprobar Solicitud');
    setModalBody(
      <LoanForm
        newLoan={false}
        formRequest={handleApproveRequest}
        handleModalClose={() => setModalShow(false)}
        loan={loan}
        reqAmountLimit={reqAmountLimit}
        reviewerSignature={reviewerSignature}
        employee={employee}
      />
    );
    setModalShow(true);
  };

  const handleRejectModal = loan => {
    setModalBody(
      <LoanRejectForm loan={loan} formRequest={handleRejectRequest} handleModalClose={() => setModalShow(false)} />
    );
    setModalTitle('Rechazar solicitud');
    setModalShow(true);
  };

  const handleConfirmModal = item => {
    setDefaultModalShow(true);
    setModalItem(item);
    setDefaultModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
    setDefaultModalTitle('Eliminar solicitud de préstamo');
  };

  const handleDefaultModalClose = () => {
    setDefaultModalShow(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const handleDeleteRequest = item => {
    setOnRequest(true);
    deleteLoanRequest(item?.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    setModalItem(item);
    switch (action) {
      case 'approved':
        handleApproveModal(camelCaseRecursive(item));
        setModalSize('lg');
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        setModalSize('md');
        break;
      case 'destroy':
        setModalAction(() => handleDeleteRequest);
        handleConfirmModal(item);
        break;
      case 'show':
        setModalTitle('Préstamo');
        setModalBody(<ItemsInfo type="loan" item={item} />);
        setModalShow(true);
        setModalSize('md');
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleDownload = () => {
    setOnRequest(true);

    exportLoansRequest({
      dispatch,
      params: query,
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setModalTitle('Importar préstamos');
        setModalBody(
          <ImportModal
            onDropUploaded={preImportLoanRequest}
            handleTemplate={importTemplateLoanRequest}
            onHide={importLoanRequest}
            hideModal={() => setModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setModalShow(true);
        break;
      case 'export':
        setDefaultModalTitle(`Exportar préstamos`);
        setDefaultModalShow(true);
        setDefaultModalBody(`¿Estás seguro que deseas descargar préstamos?`);
        setModalAction(() => handleDownload);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability)}
        data={loans}
        totalRows={amount}
        moreData={moreData}
        handleSortCase={sortColumnCase}
        resourceRequest={handleIndexRequest}
        key={employeeId}
        withStartDate
        withEndDate
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        preName={preName || 'loans'}
        rangePicker
        nameRangePicker={nameRangePicker}
        withMassActions
        massActions={
          <>
            {ability.can('export', 'Loan') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Exportar"
                onClick={() => handleMassAction('export')}
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )}
            {ability.can('import', 'Loan') && withImport && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Importar"
                onClick={() => handleMassAction('import')}
              >
                <Icon icon="cloud-upload-outline" />
              </ButtonTooltip>
            )}
          </>
        }
      />
      <SimpleCenteredModal
        size={modalSize}
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <DefaultModal
        title={defaultModalTitle}
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default LoanDataTable;
