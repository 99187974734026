import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PrincipalNavbar from './PrincipalNavbar';
import './style.scss';

const NavbarTop = () => (
  <PrincipalNavbar>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        <Nav.Link as={Link} to="/login">
          Iniciar Sesión
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </PrincipalNavbar>
);

export default NavbarTop;
