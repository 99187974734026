import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const InputPrependRight = ({
  size,
  placeholder,
  ariaLabel,
  ariaDescribedby,
  text
}) => (
  <InputGroup size={size} className="mb-3">
    <FormControl
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
    />
    <InputGroup.Append>
      <InputGroup.Text id="basic-addon2">{text}</InputGroup.Text>
    </InputGroup.Append>
  </InputGroup>
);

InputPrependRight.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  text: PropTypes.string.isRequired
};

InputPrependRight.defaultProps = {
  size: 'sm',
  placeholder: null,
  ariaLabel: null,
  ariaDescribedby: null
};

export default InputPrependRight;
