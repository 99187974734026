import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexRegionsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/regions', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexRegionsRequest = AwesomeDebouncePromise(indexRegionsRequest, 300);
