import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { delayMethod } from '../../services/utils';
import { BasicTextArea, FormikSelect } from '../../components';
import { statusFun } from '../../components/SharedFields/FormOptions';

const ProcessNotificationForm = props => {
  const { errors, touched, setFieldValue, values, handleModalClose, onHide, item, isSubmitting } = props;
  const { employees: vEmployees } = values;
  const { statusFun: vStatusFun } = vEmployees;

  return (
    <>

      <Form>
        <Modal.Body>
          <Row className="mb-2">
            <Col md={6}>
              <div className="info-box">
                <p className="info">
                  <span>Nombre Completo</span>
                </p>
                <p className="info">
                  <span>Rut</span>
                </p>
                <p className="info">
                  <span>Folio</span>
                </p>
                <p className="info">
                  <span>Periodo</span>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="info-box">
                <p className="info">{item.fun_nom_afi_cr}  {item.fun_ape_pat_cr} {item.fun_ape_mat_cr || ''}
                </p>
                <p className="info"> {item.fun_rut_afi_nn}-{item.fun_dv_afi_cr}</p>
                <p className="info"> {item.fun_fol_fun_nn} </p>
                <p className="info">{item.period}</p>
              </div>
            </Col>

          </Row>
          <Row>
            <Col md={6}>
              <Field name="employees[0][status_notification]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Estado de Notificacion"
                    placeholder="Seleccionar"
                    options={statusFun}
                    defaultValue={vStatusFun}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)} />
                )}
              </Field>
            </Col>
            {values.employees[0].status_notification === 'RE' && (
              <Col md={6}>
                <Field name="employees[0][reason_rejection]">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      abbr
                      label="Motivo de Rechazo"
                      maxLength={100}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)} />
                  )}
                </Field>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
            Cancelar
          </Button>
          <Button type="submit" variant="primary" disabled={isSubmitting} onClick={onHide}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Form></>
  );
};

const setInitialValues = props => {
  const { employees } = props;

  return {
    employees
  };
};

const validationSchema = Yup.object().shape({
  employees: Yup.array().of(
    Yup.object().shape(
      {
        status_notification: Yup.string()
          .required('Debes seleccionar un estado')
          .max(100, 'Deben ser menos que 100 caracteres'),
        reason_rejection: Yup.string().when('status_notification', {
          is: val => val !== 'AC',
          then: Yup.string().required('Debes ingresar un motivo'),
          otherwise: Yup.string().nullable()
        })
      }
    )
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(ProcessNotificationForm);
