import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner, Row, Col } from 'react-bootstrap';

import { createMassiveOvertimeRequest } from '../../requests/overtimes';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import OvertimesForm from './OvertimesForm';

const OvertimeNew = () => {
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    const message = 'Horas Extras creadas con éxito';
    setOnRequest(false);
    history.push(`/assistance?tab=overtime`);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const handleFailureRequest = error => {
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
  };

  const validateLength = overtimeValues => {
    if (!overtimeValues.overtimes.length) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar al menos un trabajador' }));
      return true;
    }
    return false;
  };

  const handleCreate = (overtimeValues, setSubmitting) => {
    if (validateLength(overtimeValues)) {
      return;
    }
    setOnRequest(true);
    const params = snakeCaseKeys(overtimeValues);

    createMassiveOvertimeRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: error => {
        handleFailureRequest(error);
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/assistance?tab=overtime">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Agregar Horas Extra</h2>
        </Col>
      </Row>
      <OvertimesForm action="new" formRequest={handleCreate} />
    </>
  );
};

export default OvertimeNew;
