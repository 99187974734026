import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { SimpleCenteredModal } from '../../components';
import { indexAbsenceRequest } from '../../requests/absences';
import { indexVacationsRequest } from '../../requests/vacations';
import { indexPermissionsRequest } from '../../requests/permissions';
import CalendarAssistance from './CalendarAssistance';
import './style.scss';

const CalendarAssistanceIndex = () => {
  const [events, setEvents] = useState('');
  const [eventColor, setEventColor] = useState('#29b48f');
  const [calendarTitle, setCalendarTitle] = useState('Licencias Médicas');
  const [onRequest, setOnRequest] = useState(true);
  const [modalBody, setModalBody] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [dateRange, setDateRange] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const updateCalendar = (titleCalendar = 'Licencias Médicas', color = '#29b48f') => {
    setCalendarTitle(titleCalendar);
    setEventColor(color);
  };

  const saveEvents = data => {
    const array = [];
    data.map(item => array.push(item.calendarFormat));
    setEvents(array);
  };

  const formatDate = data => {
    return data.map(item => {
      const { id, start, end, title, stime, etime } = item;
      return { id, start: start.split(' ')[0], end: end.split(' ')[0], title, stime, etime };
    });
  };

  const handleAbsenceRequest = (
    params = {
      filter_absence_exception: 'unjustified_absence',
      date_from: moment()
        .clone()
        .startOf('months')
        .format('DD/MM/YYYY')
    },
    title,
    color
  ) => {
    setOnRequest(true);
    const request = async () =>
      indexAbsenceRequest({
        dispatch,
        params,
        successCallback: res => {
          saveEvents(camelCaseRecursive(res.data.data));
          setOnRequest(false);
          updateCalendar(title, color);
        }
      });
    request();
  };

  const handlePermissionRequest = (params, title, color) => {
    setOnRequest(true);
    const request = async () =>
      indexPermissionsRequest({
        dispatch,
        params,
        successCallback: res => {
          saveEvents(camelCaseRecursive(res.data.data));
          setOnRequest(false);
          updateCalendar(title, color);
        }
      });
    request();
  };

  const handleVacationRequest = (params, title, color) => {
    setOnRequest(true);
    const request = async () =>
      indexVacationsRequest({
        dispatch,
        params,
        successCallback: res => {
          saveEvents(camelCaseRecursive(res.data.data));
          setOnRequest(false);
          updateCalendar(title, color);
        }
      });
    request();
  };

  const eventClick = ({ event }) => history.push(`/employees/${event._def.publicId}`);

  const handleDateClick = date => {
    const dateSelected = moment(date.dateStr).format('dddd DD / MMMM YYYY');
    const employees = formatDate(events).filter(item => item.start <= date.dateStr && item.end >= date.dateStr);

    setModalBody(
      <>
        <p className="mt-n3 ml-3 mr-2 text-uppercase text-muted">
          Fecha seleccionada: <span className="ml-1 ml-md-2 font-weight-bold">{dateSelected}</span>
        </p>
        <div className="container-bodymodal">
          {employees.length === 0 ? (
            <p className="msg-calendar-empty mt-4 text-center">No hay trabajadores en este día</p>
          ) : (
            <div className="container-info">
              {employees.map(item => (
                <>
                  <div key={item.id} className="container-employee">
                    <Link to={`/employees/${item.id}`} className="name-employee">
                      {item.title}
                    </Link>
                    {item.stime && item.etime ? (
                      <p>
                        {item.stime} - {item.etime}
                      </p>
                    ) : null}
                  </div>
                  <hr />
                </>
              ))}
            </div>
          )}
        </div>
        {employees.length > 0 && (
          <p className="mt-n2 mr-3 text-right text-uppercase text-muted font-weight-bold">
            Cantidad de trabajadores: <span className="ml-1">{employees.length}</span>
          </p>
        )}
      </>
    );
    setModalShow(true);
  };

  const handleSelectable = date => {
    const end = moment(date.endStr)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    const employees = formatDate(events).filter(
      item =>
        (item.start >= date.startStr && item.end <= end) ||
        (item.start <= date.startStr && item.end >= end) ||
        (item.start <= end && item.end >= date.startStr)
    );
    if (date.startStr !== end) setShowRange(true);

    setDateRange(
      <div className="my-4 mr-md-3 text-uppercase text-right">
        <p className="d-inline">Rango seleccionado: </p>
        <span className="ml-1 ml-md-2 font-weight-bold text-muted">
          {moment(date.startStr).format('DD/MM/YYYY')} - {moment(end).format('DD/MM/YYYY')}
        </span>
        {employees.length > 0 ? (
          <div className="my-2 text-right text-uppercase font-weight-bold text-muted">
            Cantidad de trabajadores: <span className="ml-1">{employees.length}</span>
          </div>
        ) : (
          <p className="msg-calendar-empty text-right mt-2">No hay trabajadores en este rango</p>
        )}
      </div>
    );
  };

  const cancelSelectable = () => setShowRange(false);

  useEffect(handleAbsenceRequest, []);
  useEffect(updateCalendar, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner mt-5 fix-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4">
        <Col md={12}>
          <h2 className="m-top mt-3 mb-md-4">Calendario de {calendarTitle}</h2>
        </Col>
      </Row>
      <Row className={`${onRequest && 'bg-opacity'} calendar-assistance`}>
        <Col>
          <CalendarAssistance
            dateClick={handleDateClick}
            events={events}
            eventClick={eventClick}
            eventColor={eventColor}
            selectable
            select={handleSelectable}
            unselect={cancelSelectable}
            moreLinkContent={args => `Ver más (${args.num})`}
            dayMaxEvents={3}
            handleAbsenceRequest={handleAbsenceRequest}
            handlePermissionRequest={handlePermissionRequest}
            handleVacationRequest={handleVacationRequest}
          />
        </Col>
      </Row>
      {showRange ? (
        dateRange
      ) : (
        <div className="my-4 mr-md-3 text-uppercase text-right text-muted font-weight-bold">
          Resultados encontrados:
          {onRequest ? (
            <Spinner className="width-spinner" animation="border" variant="primary" />
          ) : (
            <>
              <span className="ml-2">{events.length}</span>
            </>
          )}
        </div>
      )}
      <SimpleCenteredModal title="Trabajadores" body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default CalendarAssistanceIndex;
