import { useState, useEffect } from 'react';
import { useAbility } from '@casl/react';
import { sendAlert } from '../actions/utils';
import { AbilityContext } from '../config/abilityContext';

const redirectUnauthorized = (dispatch, history, user, currentEmployee) => {
  if (currentEmployee) {
    dispatch(sendAlert({ kind: 'error', message: 'No tienes permisos para acceder a este sitio' }));
  }
  if (user.role === 'admin' && !currentEmployee) history.push('/admin/companies');
  else if (user.role === 'dt' && !currentEmployee) history.push('/dt/welcome');
  else history.push('/profile/dashboard');
};

const useSetTab = (defaultTab, location, tabName = 'tab', transform = undefined) => {
  const tab = new URLSearchParams(location.search).get(tabName);
  const tabTransform = transform ? transform(tab) : tab;
  const [key, setKey] = useState(tabTransform || defaultTab);
  useEffect(() => {
    window.scrollTo(0, 0);
    const paramTab = new URLSearchParams(location.search).get(tabName);
    if (paramTab) {
      setKey(paramTab);
    }
  }, [location, tabName]);
  return [key, setKey];
};

const useAuthorization = (action, resource) => {
  const ability = useAbility(AbilityContext);
  return ability.can(action, resource);
};

const useCanAccess = (action, resource, exclusive = false) => {
  // action and resource need to be pairs separeted by ','
  const ability = useAbility(AbilityContext);
  const resources = resource.split(',');
  const abilities = action.split(',').map((name, index) => ability.can(name, resources[index]));
  const canAccess = exclusive ? !abilities.includes(false) : abilities.includes(true); // can and can : can or can
  return canAccess;
};

const useScrollPosition = type => {
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    if (type.toLowerCase() === 'x') {
      const positionX = window.pageXOffset;
      setScrollPos(positionX);
    }
    if (type.toLowerCase() === 'y') {
      const positionY = window.pageYOffset;
      setScrollPos(positionY);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, [scrollPos, type]);

  return [scrollPos];
};

export { useAuthorization, useCanAccess, useSetTab, useScrollPosition, redirectUnauthorized };
