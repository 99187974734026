import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, ApprovementStatus, Icon, DatatableEmployeeName } from '../../../components';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    minWidth: '185px',
    grow: '2'
  },
  {
    name: 'TURNO',
    selector: 'shift_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA',
    selector: 'attendance_date',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'HORA / ENTRADA',
    selector: 'arrival_mark',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'HORA / SALIDA',
    selector: 'departure_mark',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'TIPO CAMBIO',
    selector: 'change_type',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'SOLICITADO POR',
    selector: 'replacement_by',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {ability.can('show', 'AttendanceChangeRequest') && (
          <ButtonTooltip
            variant="circle-info"
            className="btn-circle"
            onClick={() => clickHandler(item, 'show')}
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
        {ability.can('attendance_change_action', 'AttendanceChangeRequest') && (
          <>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'approved')}
              variant="circle-success"
              className="btn-circle"
              size="sm"
              text="Aprobar"
            >
              <Icon icon="checkmark-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'rejected')}
              variant="circle-danger"
              className="btn-circle"
              size="sm"
              text="Rechazar"
            >
              <Icon icon="close-circle" />
            </ButtonTooltip>
          </>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '100px'
  }
]);

export default columns;
