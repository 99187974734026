import React, { Component } from 'react';
import { Field, getIn } from 'formik';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  DefaultModal,
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  InputSelect,
  NestedAttributes,
  PhoneWithCode,
  RegionCommune,
  Genders,
  UploadFile,
  UploadImage
} from '../../../components';
import { debounceIndexCareersRequest } from '../../../requests/careers';
import { debounceIndexCertificationsRequest, indexCertificationsRequest } from '../../../requests/certifications';
import { debounceIndexCompaniesRequest } from '../../../requests/companies';
import { debounceIndexAdminCompaniesRequest } from '../../../requests/adminCompanies';
import { debounceIndexCountriesRequest } from '../../../requests/countries';
import { debounceIndexLanguagesRequest } from '../../../requests/languages';
import { debounceIndexProfilesRequest } from '../../../requests/profiles';
import { debounceIndexProfilesRequest as adminProfilesRequest } from '../../../requests/adminProfiles';
import { civilStateTypes, educationLevelTypes, identificationTypes, senceLevels } from './FormOptions';
import { rutFormat, validRutInput } from '../../../services/utils';
import FamilyGroupTab from './FamilyGroupTab';

const EmployeeCertificationInput = ({
  employeeCertificationAttributes,
  certifications,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  index
}) => {
  const { certificationId } = employeeCertificationAttributes;

  return (
    <Col>
      <Row>
        <Col md={5}>
          <Field name={`employee[employeeCertificationsAttributes][${index}][certificationId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Certificación"
                placeholder="Seleccionar Certificación"
                tooltipText="En esta sección podrá incorporar documentos de título y otras certificaciones que posee el
                             empleado"
                options={certifications}
                defaultValue={certificationId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`employee[employeeCertificationsAttributes][${index}][certificate]`}>
            {({ field }) => (
              <UploadFile
                {...field}
                abbr
                label="Archivo"
                tooltipText="Solo formato PDF permitido"
                formats={['.pdf']}
                name={employeeCertificationAttributes?.certificateInfo?.filename || 'Adjuntar Archivo'}
                onChange={file => setFieldValue(field.name, file)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name={`employee[employeeCertificationsAttributes][${index}][endDate]`}>
            {({ field }) => (
              <FormikDatePicker
                name={field.name}
                value={field.value}
                isOutsideRange={() => false}
                label="Fecha de Término"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

class profileTab extends Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    includeModal: this.props.action === 'edit',
    initialCertifications: [],
    modalShow: false,
    modalEventField: '',
    modalEventValue: '',
    profiles: [],
    localSingSubstitutes: []
  };

  componentDidMount() {
    const { currentCompany } = this.props;
    if (currentCompany) {
      this.fetchInitialCertifications();
      this.fetchInitialProfiles(currentCompany);
    }
  }

  componentDidUpdate(prevProps) {
    const { values: prevValues } = prevProps;
    const { values } = this.props;
    const { signSubstitutes } = this.props;
    if (prevValues.employee.companyId !== values.employee.companyId) {
      this.fetchInitialProfiles(values.employee.companyId);
    }

    if (prevProps.signSubstitutes !== signSubstitutes) {
      this.syncSubstitutes(signSubstitutes);
    }
  }

  fetchInitialCertifications = () => {
    const { dispatch } = this.props;

    indexCertificationsRequest({
      dispatch,
      params: {
        sort_column: 'name',
        display_length: 100
      },
      successCallback: response => {
        const initialCertifications = this.resultCertificationFetchData(response);
        this.setState({ initialCertifications });
      }
    });
  };

  // Fetch Certifications
  fetchCertifications = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexCertificationsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: response => {
        callback(this.resultCertificationFetchData(response));
      }
    });
  };

  resultCertificationFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  employeeCertificationsInputs = () => {
    const { initialCertifications } = this.state;
    const { errors, touched, values, setFieldValue, setFieldTouched } = this.props;
    const { employeeCertificationsAttributes } = values.employee;
    const mapResults = employeeCertificationsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <EmployeeCertificationInput
          key={`employee-certification-${index.toString()}`}
          certifications={initialCertifications}
          employeeCertificationAttributes={body}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          index={index}
        />
      );
    });

    mapResults.push(
      <Col>
        <Row>
          <Col className="sample-row">
            <FormikSelect label="Certificación" placeholder="Seleccionar Certificación" isDisabled />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={employeeCertificationsAttributes}
          setFieldValue={setFieldValue}
          valuePath="employee[employeeCertificationsAttributes]"
          newAttributes={{
            certificate: '',
            certificationId: '',
            endDate: ''
          }}
        />
      </>
    );
  };

  fetchCompanyData = (inputValue, callback) => {
    const { dispatch, admin } = this.props;
    const allParams = {
      dispatch,
      params: {
        business_name: inputValue,
        order_by_attribute: 'business_name',
        display_length: 100
      },
      successCallback: data => callback(this.resultCompanyFetchData(data))
    };
    if (admin) debounceIndexAdminCompaniesRequest(allParams);
    else debounceIndexCompaniesRequest(allParams);
  };

  fetchInitialProfiles = companyId => {
    const { dispatch, admin } = this.props;
    if (admin && companyId) {
      adminProfilesRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          company_id: companyId
        },
        successCallback: response => this.setState({ profiles: response.data.data })
      });
    } else if (!admin) {
      debounceIndexProfilesRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false
        },
        successCallback: response => this.setState({ profiles: response.data.data })
      });
    }
  };

  fetchProfiles = (inputValue, callback) => {
    const {
      dispatch,
      values: { employee },
      admin
    } = this.props;
    if (admin && employee.companyId) {
      adminProfilesRequest({
        dispatch,
        params: {
          name: inputValue,
          sort_column: 'name',
          paginate: false,
          company_id: employee.companyId
        },
        successCallback: response => {
          const { data } = response.data;
          callback(data);
        }
      });
    } else if (!admin) {
      debounceIndexProfilesRequest({
        dispatch,
        params: {
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: response => {
          const { data } = response.data;
          callback(data);
        }
      });
    }
  };

  resultCompanyFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        label: element.business_name,
        value: element.id
      };
    });
    return tempArray;
  };

  // Fetch Countries
  fetchCountries = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        nationality: true,
        name: inputValue,
        sort_column: 'position',
        display_length: 250
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  // Fetch Careers
  fetchCareers = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexCareersRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 200
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchLanguages = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexLanguagesRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  resultFetchData = response => {
    const { data } = response.data;
    return data;
  };

  // Sinc Substitutes
  syncSubstitutes = singSubstitutes => {
    this.setState({ localSingSubstitutes: singSubstitutes });
  };

  // custom handle inputs
  handleNewMulti = (data, field, allowEmpty = false) => {
    const { setFieldValue } = this.props;
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  handleNationalIdentificationFormat = (e, isRut) => {
    const { setFieldValue } = this.props;
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  // Initial remote selects values
  initialDefaultValue = (attribute, attrObject) => {
    const { employee, values } = this.props;
    const { [`${attribute}`]: vAttributeId } = values.employee;
    const { [`${attribute}`]: attributeId } = employee;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      return getIn(employee, `${attrObject}`);
    }
    return undefined;
  };

  // Modal methods
  handleModalClose = () => {
    this.setState({ modalShow: false, modalEventField: '', modalEventValue: '' });
  };

  handleModalConfirm = () => {
    const { modalEventField, modalEventValue } = this.state;
    const { setFieldValue } = this.props;
    setFieldValue(modalEventField, modalEventValue);
    this.setState({ modalShow: false, modalEventValue: '', modalEventField: '', includeModal: false });
  };

  personalEmail = e => {
    const { includeModal } = this.state;
    const { setFieldValue } = this.props;
    if (includeModal) {
      this.setState({
        modalShow: true,
        modalEventField: e.target.name,
        modalEventValue: e.target.value
      });
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  render() {
    const {
      currentCompany,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      employee,
      values,
      adminNew,
      accordion,
      setAccordion
    } = this.props;
    const { modalShow, profiles, localSingSubstitutes } = this.state;
    const defaultValue = this.initialDefaultValue('companyId', 'company');
    const defaultCountry = this.initialDefaultValue('countryId', 'country');
    const defaultCareers = this.initialDefaultValue('careerIds', 'careers');
    const defaultLanguages = this.initialDefaultValue('languageIds', 'languages');

    const {
      civilState,
      educationLevel,
      identificationType,
      phoneCountryCode,
      senceLevel,
      signSubstituteId,
      workPhoneCountryCode
    } = employee;

    return (
      <>
        <DefaultModal
          title="¿Está seguro querer editar?"
          body="Recuerde que el e-mail personal está asociado al envío de liquidaciones y otros documentos, además al
                acceso al portal por parte del trabajador"
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
        {!currentCompany && adminNew && (
          <>
            <h4 className="text-uppercase">Empresa</h4>
            <Field name="employee[companyId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Empresa"
                  placeholder="Seleccionar Empresa"
                  value={defaultValue}
                  request={this.fetchCompanyData}
                  onChange={data => {
                    setFieldValue(field.name, data ? data.value : '');
                    this.handleNewMulti([], 'employee[profileIds]');
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </>
        )}
        <Accordion defaultActiveKey="0" className="ml-2 mt-2 mr-2">
          <Col className={`${accordion === 'profile' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
            <Accordion.Toggle
              as={Col}
              eventKey="0"
              onClick={() => setAccordion('profile')}
              className="card-header-title"
            >
              INFORMACIÓN PERSONAL
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="0">
            <Card.Body className="div-content">
              <Row>
                <Col md={4}>
                  <Field name="employee[name]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Nombres"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[firstLastName]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Primer Apellido"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[secondLastName]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Segundo Apellido"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={4}>
                  <Field name="employee[identificationType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        defaultValue={identificationType}
                        label="Tipo de Identificación"
                        options={identificationTypes}
                        placeholder="Seleccionar Tipo de Identificación"
                        tooltipText=""
                        onChange={data => {
                          setFieldValue('employee[nationalIdentification]', '');
                          setFieldValue(field.name, data ? data.value : 'RUT');
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[nationalIdentification]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label={values.employee.identificationType === 'rut' ? 'RUT' : 'Nº de Identificación'}
                        onChange={e =>
                          this.handleNationalIdentificationFormat(e, values.employee.identificationType === 'rut')
                        }
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[nationalIdExpirationDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        name={field.name}
                        value={field.value}
                        isOutsideRange={() => false}
                        label="Fecha de Vencimiento Documento de Identificación"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={4}>
                  <Field name="employee[birthdate]">
                    {({ field }) => (
                      <FormikDatePicker
                        name={field.name}
                        value={field.value}
                        isOutsideRange={day => moment().diff(day) < 0}
                        label="Fecha de Nacimiento"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[countryId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Nacionalidad"
                        placeholder="Seleccionar Nacionalidad"
                        value={defaultCountry}
                        request={this.fetchCountries}
                        onChange={data => {
                          setFieldValue(field.name, data.value);
                          setFieldValue('employee[countryName]', data.label);
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Genders modelKey="employee" md={4} />
              </Row>
              <Row>
                <Col md={4}>
                  <Field name="employee[civilState]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Estado Civil"
                        placeholder="Seleccionar Estado Civil"
                        options={civilStateTypes}
                        defaultValue={civilState}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  {values.employee?.companyId && (
                    <Field name="employee[signSubstituteId]">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Sustituto"
                          placeholder="Seleccionar Sustituto"
                          options={localSingSubstitutes}
                          defaultValue={signSubstituteId}
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isClearable
                        />
                      )}
                    </Field>
                  )}
                </Col>
                <Col md={4}>
                  {values.employee?.companyId && (
                    <Field name="employee[profileIds]">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Asignar Perfil"
                          placeholder="Seleccionar Permisos"
                          tooltipText="Perfil de acceso a información"
                          values={values.employee}
                          model={[employee, 'profile']}
                          defaultOptions={profiles}
                          request={(value, cb) => this.fetchProfiles(value, cb)}
                          onChange={data => this.handleNewMulti(data || [], field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isMulti
                        />
                      )}
                    </Field>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Field name="employee[image]">
                    {({ field }) => (
                      <UploadImage
                        {...field}
                        circleImage
                        name="Adjuntar foto del colaborador."
                        imageUrl={getIn(employee.fileInfo, 'fileUrl')}
                        onChange={_avatar => setFieldValue(field.name, _avatar)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        helpText="Formato sugerido 400x400px de máximo 5mb."
                        fileAccept=".png, .jpg, .gif, .jpeg"
                        s
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3} className="mt-3">
                  <Field name="employee[collaborator]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Es Colaborador" custom />}
                  </Field>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          <Col className={`${accordion === 'contact' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="1"
              onClick={() => setAccordion('contact')}
              className="card-header-title"
            >
              CONTACTO
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="1">
            <Card.Body className="div-content">
              <Row>
                <Col md={6}>
                  <PhoneWithCode
                    label="Teléfono laboral"
                    countryCodeField="employee[workPhoneCountryCode]"
                    phoneField="employee[workPhone]"
                    workPhoneCountryCode={workPhoneCountryCode}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col md={6}>
                  <PhoneWithCode
                    label="Teléfono Personal"
                    countryCodeField="employee[phoneCountryCode]"
                    phoneField="employee[phone]"
                    workPhoneCountryCode={phoneCountryCode}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col md={6}>
                  <Field name="employee[workEmail]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="E-mail Laboral"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[email]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="E-mail Personal"
                        tooltipText="Requerido para el envío de documentación laboral de forma electrónica al trabajador.
                               Para ello, el destinatario debe dar su consentimiento por escrito. Si el trabajador no
                               acuerda esta modalidad, su documentación debe ser entrega en papel. (ORD. 2571 DT)."
                        onChange={e => this.personalEmail(e)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[address]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Dirección"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="employee[addressNumber]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Número"
                        inputType="number"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="employee[addressApt]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Departamento/Casa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <RegionCommune modelKey="employee" />
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          <Col className={`${accordion === 'academic' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="2"
              onClick={() => setAccordion('academic')}
              className="card-header-title"
            >
              INFORMACIÓN ACADÉMICA
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="2">
            <Card.Body className="div-content">
              <Row>
                <Col md={6}>
                  <Field name="employee[educationLevel]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Nivel Educacional Máximo Alcanzado"
                        placeholder="Seleccionar Nivel Educacional"
                        options={educationLevelTypes}
                        defaultValue={educationLevel}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[senceLevel]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Nivel Sence"
                        placeholder="Seleccionar Nivel Sence"
                        options={senceLevels}
                        defaultValue={senceLevel}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field name="employee[careerIds]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Profesión u Oficio"
                        placeholder="Seleccionar Profesión u Oficio"
                        isMulti
                        value={defaultCareers}
                        request={this.fetchCareers}
                        onChange={data => this.handleNewMulti(data || [], field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[languageIds]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Idiomas"
                        isMulti
                        placeholder="Seleccionar Idiomas"
                        value={defaultLanguages}
                        request={this.fetchLanguages}
                        onChange={data => this.handleNewMulti(data || [], field.name, true)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {currentCompany && (
                    <>
                      <h4 className="text-uppercase">Títulos y Certificaciones</h4>
                      {this.employeeCertificationsInputs()}
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          {/* <h4 className="text-uppercase">Contacto de Emergencia</h4>
        <Field name="employee[emergencyContactName]">
          {({ field }) => (
            <FormikInput
              {...field}
              label="Nombre Completo"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
        <Row>
          <Col md={6}>
            <Field name="employee[emergencyContactRelation]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Relación"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <PhoneWithCode
              label="Teléfono"
              countryCodeField="employee[emergencyContactCountryCode]"
              phoneField="employee[emergencyContactPhone]"
              workPhoneCountryCode={emergencyContactCountryCode}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row> */}
          <Col className={`${accordion === 'family' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2 mb-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="3"
              onClick={() => setAccordion('family')}
              className="card-header-title"
            >
              GRUPOS FAMILIARES
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="3">
            <Card.Body className="div-content">
              <FamilyGroupTab
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </>
    );
  }
}

export default connect()(profileTab);
