import React, { Component } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import { FormikMaterialUiTimePicker, FormikCheckBox, FormikInput, FormikNumber } from '../../components/index';
import daysOfWeek from './FormOptions';
import ShiftContractsForm from './ShiftContractsForm';
import Icon from '../../components/Icons';
import './style.scss';

class ShiftForm extends Component {
  dayCheckBoxValue = (day, index, element) => {
    const { setFieldValue, values } = this.props;
    if (element._dispatchListeners[0] === undefined) {
      values.shift.shiftDaysAttributes[index].send = !values.shift.shiftDaysAttributes[index].send;
    }
    setFieldValue(`shift[shiftDaysAttributes][${index}][weekDay]`, day);
  };

  render() {
    const { values, errors, onHide, touched, setFieldValue, isSubmitting, shift } = this.props;
    const { shiftDaysAttributes } = values.shift;
    return (
      <Form>
        <Row>
          <Col md={6}>
            <Field name="shift[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  placeholder="NOMBRE DE TURNO"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={3}>
            <Field name="shift[startLunch]">
              {({ field }) => (
                <FormikMaterialUiTimePicker
                  {...field}
                  abbr
                  timeSelector
                  label="Inicio de Colacion"
                  onChange={time => setFieldValue(field.name, time)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="shift[endLunch]">
              {({ field }) => (
                <FormikMaterialUiTimePicker
                  {...field}
                  abbr
                  timeSelector
                  label="Hora de Término"
                  onChange={time => setFieldValue(field.name, time)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={3}>
            <Field name="shift[parsedTolerance]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  label="Tolerancia"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="shift[tolerance]"
                  value={values.shift.tolerance}
                  tooltipSize="21"
                  tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={6} md={3} className="d-flex align-items-center">
            <Field name="shift[workHolidays]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="TRABAJA FESTIVOS"
                  tooltipSize="23"
                  tooltipText="Turno con días festivos incluídos"
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={3} className="d-flex align-items-center">
            <Field name="shift[lunchCheck]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="MARCAR COLACIÓN"
                  tooltipSize="23"
                  tooltipText="Indica si deben marcar el ingreso y salida de colación"
                />
              )}
            </Field>
          </Col>
        </Row>
        <hr />
        <p>Haz click en un día para activarlo o desactivarlo en este turno semanal.</p>
        <Row className="justify-content-center">
          {daysOfWeek.map((item, index) => (
            <Col
              xs={5}
              sm={2}
              lg
              key={item.value}
              onClick={e => this.dayCheckBoxValue(item.value, index, e)}
              className={`shift-card-container${shiftDaysAttributes[index].send === true ? '' : '-disabled'}`}
            >
              <Row>
                <Col md={12}>
                  {shiftDaysAttributes[index].send && (
                    <Icon
                      className="text-primary"
                      style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                      width={18}
                      icon="checkmark-circle"
                    />
                  )}
                  <p className="text-uppercase shift-card-title">{item.label}</p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 align-items-center" md={12}>
                  <p className="text-uppercase shift-card-label">Desde</p>
                  <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        defaultValue="09:00"
                        onChange={time => setFieldValue(field.name, time)}
                        disabled={!shiftDaysAttributes[index].send}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md={12}>
                  <p className="text-uppercase shift-card-label">Hasta</p>
                  <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        defaultValue="18:00"
                        onChange={time => setFieldValue(field.name, time)}
                        disabled={!shiftDaysAttributes[index].send}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
        <ShiftContractsForm shift={shift} />
        <Row className="d-flex justify-content-end my-3">
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting} variant="primary" block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = daysOfWeek;
  if (shiftDaysAttributes.length) {
    shiftDays = daysOfWeek.map(day => {
      const shiftDay = shiftDaysAttributes.filter(item => item.weekDay === day.value);
      if (shiftDay.length) {
        return { ...shiftDay[0], send: true };
      }
      return { ...day, send: false };
    });
  }

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'weekly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape({
    lunchCheck: Yup.boolean(),
    workHolidays: Yup.boolean(),
    tolerance: Yup.number()
      .required('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0'),
    startLunch: Yup.string().required('Debes ingresar una hora de inicio'),
    endLunch: Yup.mixed()
      .required('Debes ingresar una hora de término')
      .test('from-less-than-to', 'Hora de término no puede ser inferior al horario de inicio', function(value) {
        const dayStr = moment().format('DD/MM/YYYY');
        return moment(`${dayStr} ${value}`, 'DD/MM/YYYY HH:mm').isAfter(
          moment(`${dayStr} ${this.parent.startLunch}`, 'DD/MM/YYYY HH:mm')
        );
      }),
    name: Yup.string().required('Debes ingresar un nombre'),
    shiftDaysAttributes: Yup.array().of(
      Yup.object().shape({
        endTime: Yup.string().when('send', {
          is: val => val,
          then: Yup.string()
            .required('Debes ingresar hora de término')
            .notOneOf([Yup.ref('startTime'), null], 'Las horas de inicio y termino no pueden ser iguales'),
          otherwise: Yup.string().nullable()
        }),
        startTime: Yup.string().when('send', {
          is: val => val,
          then: Yup.string().required('Debes ingresar hora de inicio'),
          otherwise: Yup.string().nullable()
        }),
        send: Yup.boolean()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftForm);
