const healthPrevision = {
  '01': 'Banmédica',
  '02': 'Consalud',
  '03': 'VidaTres',
  '04': 'Colmena',
  '05': 'CruzBlanca',
  '10': 'Nueva MasVida',
  '12': 'Isapre Bco. Estado',
  '25': 'Cruz del Norte',
  '31': 'ISALUD'
};

const funType = {
  '1': 'Suscripción de Contrato',
  '2': 'Termino por parte de la Isapre',
  '3': 'Situaciones Especiales',
  '4': 'Sustitución, adición o eliminación de Empleador Cambio situación laboral o previsional del cotizante Confidencial',
  '5': 'Cambio de fecha para contabilizar periodos anuales',
  '6': 'Variación del precio por cambiar tramo de edad',
  '7': 'Cambio en el número o modificación del tipo de beneficiario',
  '8': 'Modificación de la cotización pactada',
  '9': 'Cambio de plan',
}

const estadoFun = {
  'RE': 'Rechazado',
  'AC': 'Aceptado',
  'PE': 'Pendiente'
}

export { healthPrevision, funType, estadoFun };