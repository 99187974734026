import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { exportSurveyRequest } from '../../requests/surveys';
import { CustomGraph, BasicDropdown, Icon } from '../../components';
import { downloadFile } from '../../services/utils';

const QuestionResult = ({ question, index }) => {
  const [plotType, setPlotType] = useState('pie');
  const dropdownOptions = [
    {
      title: 'Gráfico de Barra',
      props: {
        onClick: () => setPlotType('bar'),
        active: plotType === 'bar'
      }
    },
    {
      title: 'Gráfico de Torta',
      props: {
        onClick: () => setPlotType('pie'),
        active: plotType === 'pie'
      }
    },
    {
      title: 'Gráfico de Línea',
      props: {
        onClick: () => setPlotType('line'),
        active: plotType === 'line'
      }
    },
    {
      title: 'Gráfico de Barra Lateral',
      props: {
        onClick: () => setPlotType('side'),
        active: plotType === 'side'
      }
    }
  ];

  return (
    <Row className="mb-responsive truncate-container">
      <Col xs={12} className="d-flex">
        <h4 className="mb-5">{`${index + 1}. ${question.label}`}</h4>
        <BasicDropdown
          variant="no-border mr-auto ml-4 mt-3"
          noArrow
          block
          right
          titleDrop={
            <span className="align-left">
              <Icon icon="ellipsis-vertical" height="18px" width="18px" />
            </span>
          }
          items={dropdownOptions}
        />
      </Col>
      <Col md={6} xs={12} className="question-plot mb-4">
        <CustomGraph
          customizedLabel
          classResponsiveContainer="responsive-container"
          chartType={plotType}
          title={false}
          data={question.results}
          pieLegend={{ layout: 'vertical', verticalAlign: 'middle', align: 'right' }}
          activeAnimation={false}
        />
      </Col>
    </Row>
  );
};

const SurveyResults = ({ survey, setOnRequest, onRequest }) => {
  const dispatch = useDispatch();

  const exportResults = async () => {
    setOnRequest(true);
    exportSurveyRequest(survey.id, {
      dispatch,
      successCallback: downloadFile,
      callback: () => setOnRequest(false)
    });
  };

  return (
    <>
      <Row className="mt-3">
        <Col md={3}>
          <Button variant="info" block onClick={exportResults} disabled={onRequest}>
            Exportar Resultados
          </Button>
        </Col>
      </Row>
      {survey.questionsAttributes.map((question, index) => (
        <QuestionResult question={question} index={index} key={`question-result-${question.id}`} />
      ))}
    </>
  );
};

export default SurveyResults;
