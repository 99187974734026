import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { indexContractRequest } from '../../requests/contracts';
import { sendAlert } from '../../actions/utils';
import TerminatedContractsTable from './TerminatedContractsTable';
import { delayMethod } from '../../services/utils';

const TerminatedContractIndex = ({ employeeId, hideModal }) => {
  const [contracts, setContracts] = useState([]);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    hideModal();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const fetchContracts = () => {
    indexContractRequest({
      dispatch,
      params: {
        filter_employee: employeeId,
        filter_finished: true,
        sort_column: 'created_at',
        sort_direction: 'desc'
      },
      successCallback: response => {
        const { data } = response.data;
        setContracts(data);
      },
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchContracts, [employeeId]);

  return (
    <>
      <TerminatedContractsTable contracts={contracts} employeeId={employeeId} />
      <Modal.Footer>
        <Button onClick={() => delayMethod(hideModal)}>Cerrar</Button>
      </Modal.Footer>
    </>
  );
};

export default TerminatedContractIndex;
