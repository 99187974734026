import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useSetTab, redirectUnauthorized } from '../../services/hooks';
import { Icon, SimpleCenteredModal } from '../../components';
import VacationIndex from './VacationIndex';
import PermissionIndex from './PermissionIndex';
import PermissionNew from '../Profile/Permission/PermissionNew';
import VacationNew from '../Profile/Vacation/VacationNew';
import { AbilityContext } from '../../config/abilityContext';

const VacationDashboard = () => {
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [moreData, setMoreData] = useState(false);
  const { advanceSettings } = useSelector(state => state.utils);
  const { user, currentEmployee } = useSelector(state => state.auth);
  const companyLeave = advanceSettings?.filter(
    asItem => asItem.code === 'paid_leave' || asItem.code === 'without_paid_leave'
  );
  const legalHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'legal_holiday')[0]?.textValue === 'true';
  const progressiveHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'progressive_holiday')[0]?.textValue === 'true';
  const additionalDaysEnabled =
    advanceSettings.filter(asItem => asItem.code === 'additional_days')[0]?.textValue === 'true';
  const newBtnVisible = legalHolidayEnabled || progressiveHolidayEnabled || additionalDaysEnabled;
  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const [key, setKey] = useSetTab(vacationsTab ? 'vacations' : 'permissions', location);

  const handleSuccessCreate = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const newVacation = () => {
    setModalTitle('Solicitar Vacaciones');
    setModalBody(<VacationNew rrhh onSuccess={handleSuccessCreate} />);
    setModalShow(true);
  };

  const newPermission = () => {
    setModalTitle('Solicitar Permiso');
    setModalShow(true);
    setModalBody(
      <PermissionNew
        rrhh
        employeeId={currentEmployee}
        onSuccess={handleSuccessCreate}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const handleClick = () => {
    switch (key) {
      case 'vacations':
        return newVacation();
      case 'permissions':
        return newPermission();
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        return '';
    }
  };

  const canNew = () => {
    switch (key) {
      case 'vacations':
        return ability.can('create', 'Vacation') && newBtnVisible;
      case 'permissions':
        return ability.can('create', 'Permission') && companyLeave;
      default:
        return false;
    }
  };

  const checkSettings = () => {
    if (advanceSettings.length && !(vacationsTab || permissionsTab)) {
      redirectUnauthorized(dispatch, history, user, currentEmployee);
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(checkSettings, [vacationsTab, permissionsTab]);

  const canVacation = ability.can('index', 'Vacation') && vacationsTab;
  const canPermission = ability.can('index', 'Permission') && permissionsTab;
  const title = `${canVacation ? 'Vacaciones' : ''}${canVacation && canPermission ? ' y ' : ''}${canPermission ? 'Permisos' : ''
    }`;

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">{title}</h2>
        </Col>
        {canNew() && (
          <Col md={3}>
            <Button className="mt-3" variant="primary" block onClick={handleClick}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
            <Nav variant="pills">
              {canVacation && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="vacations">
                    <Icon icon="sunny" />
                    Vacaciones
                  </Nav.Link>
                </Nav.Item>
              )}
              {canPermission && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="permissions">
                    <Icon icon="user-check" />
                    Permisos
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="vacations">
                <VacationIndex moreData={moreData} setMoreData={setMoreData} />
              </Tab.Pane>
              <Tab.Pane eventKey="permissions">
                <PermissionIndex moreData={moreData} setMoreData={setMoreData} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default VacationDashboard;
