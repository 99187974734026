/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';
import Icon from '../../Icons';
import './style.scss';

const SidebarBasic = ({ children, pathName, closeSidebar, showChangePassword, showChangePin }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const ability = useAbility(AbilityContext);
  const [assistanceActive, setAssistanceActive] = useState(false);
  const [selectionActive, setSelectionActive] = useState(false);
  const [trainingActive, setTrainingActive] = useState(false);
  const [companyActive, setCompanyActive] = useState(false);
  const [employeeActive, setEmployeeActive] = useState(false);
  const [commsActive, setCommsActive] = useState(false);
  const [remunerationActive, setRemunerationActive] = useState(false);
  const [preventionActive, setPreventionActive] = useState(false);
  const [reportActive, setReportActive] = useState(false);
  const [activeKey, setActiveKey] = useState(pathName);

  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  useEffect(() => {
    const assistanceDropdown = [
      'assistance',
      'attendances_hash',
      'shifts',
      'attendances',
      'records',
      'calendar',
      'legal_reports',
      'workdays_report',
      'offdays_report',
      'shift_report',
      'overtime_report',
      'marking_correction'
    ];
    const companyDropdown = [
      'company',
      'groupings',
      'job_managements',
      'settings',
      'roles',
      'advance_settings',
      'importers'
    ];
    const employeeDropdown = [
      'employees',
      'advance_payments',
      'dashboard',
      'organization_charts',
      'vacations',
      'document_templates',
      'import_documents',
      'simulators',
      'shift_changes',
      'get_notifications_period',
      'license',
      'requests'
    ];
    const selectionDropdown = ['selection_processes', 'candidates'];
    const remunerationDropdown = [
      'remunerations_settings',
      'payroll_processes',
      'advance_payments_process',
      'settlements',
      'settlement_groups',
      'balances',
      'data_load'
    ];
    const commsDropdown = ['groups', 'announcements', 'tasks', 'benefits'];
    const trainingDropdown = ['courses', 'dnc', 'training_program'];
    const preventionDropdown = ['risk_prevention', 'supporting_documentation', 'internal_documentation'];
    const reportsDropdown = ['basic', 'custom', 'company_logs', 'import_logs'];

    setAssistanceActive(assistanceDropdown.includes(pathName));
    setEmployeeActive(employeeDropdown.includes(pathName));
    setCompanyActive(companyDropdown.includes(pathName));
    setSelectionActive(selectionDropdown.includes(pathName));
    setRemunerationActive(remunerationDropdown.includes(pathName));
    setTrainingActive(trainingDropdown.includes(pathName));
    setCommsActive(commsDropdown.includes(pathName));
    setPreventionActive(preventionDropdown.includes(pathName));
    setReportActive(reportsDropdown.includes(pathName));
    setActiveKey(pathName);
  }, [pathName]);

  const companyMenu = [
    ability.can('show', 'Company'),
    ability.can('organization_chart', 'Company'),
    ability.can('read', 'JointCommittee'),
    ability.can('read', 'Syndicate'),
    ability.can('read', 'JobManagement'),
    ability.can('read', 'DocumentTemplate'),
    ability.can('read_configurations', 'Company'),
    ability.can('index', 'Profile'),
    ability.can('update', 'AdvanceSetting'),
    ability.can('read', 'ImportLog')
  ];
  const employeesMenu = [
    ability.can('index', 'Employee'),
    ability.can('organization_chart', 'Company'),
    ability.can('index', 'Document'),
    ability.can('index', 'AdvancePayment'),
    ability.can('index', 'IntegrationImed'),
    ability.can('read', 'DocumentTemplate'),
    ability.can('read', 'ImportLog'),
    ability.can('salary_stunner', 'Payroll'),
    ability.can('read', 'Xdata')
  ];
  const assistanceMenu = ability.can('manage', 'attendance_management') && [
    ability.can('read_dashboard', 'Attendance'),
    ability.can('read', 'Shift'),
    ability.can('read', 'Attendance') || ability.can('index', 'Absence'),
    (ability.can('index', 'Vacation') || ability.can('index', 'Permission')) && (vacationsTab || permissionsTab),
    ability.can('index', 'AttendanceReport') ||
      ability.can('index', 'WorkdayReport') ||
      ability.can('index', 'HolidayReport') ||
      ability.can('index', 'ShiftReport') ||
      ability.can('index', 'OvertimeReport')
  ];
  const trainingMenu = [
    ability.can('index', 'Course'),
    ability.can('index', 'Dnc'),
    ability.can('index', 'EmployeeDnc')
  ];
  const selectionProcessMenu = [ability.can('index', 'SelectionProcess'), ability.can('index', 'Candidate')];
  const remunerationMenu = [
    ability.can('read_concept', 'Balance'),
    ability.can('read', 'PayrollProcess'),
    ability.can('generate_payment', 'AdvancePayment'),
    ability.can('read', 'SettlementGroup') || ability.can('read', 'Settlements'),
    ability.can('read', 'ScheduledGroup'),
    ability.can('salary_stunner', 'Payroll'),
    ability.can('create', 'DataLoad')
  ];
  const commsMenu = [
    ability.can('read', 'Group'),
    ability.can('read', 'Announcement'),
    ability.can('index', 'CompanyTask'),
    ability.can('read', 'Benefit')
  ];
  const preventionMenu = [ability.can('index', 'RiskPreventionDocument')];
  const reportMenu = [
    ability.can('read', 'Report'),
    ability.can('index', 'CompanyLog'),
    ability.can('read', 'ImportLog')
  ];
  const menuCascadeEffect = spanClass => {
    const menuChildrens = document.querySelectorAll(spanClass);
    menuChildrens.forEach((link, index) => {
      link.style.animation = `cascade .2s ease-in forwards ${index / 10}s`;
    });
  };

  useEffect(() => {
    menuCascadeEffect('.employee-effect span');
    menuCascadeEffect('.importer-effect span');
    menuCascadeEffect('.assistance-effect span');
    menuCascadeEffect('.training-effect span');
    menuCascadeEffect('.selection-effect span');
    menuCascadeEffect('.legalreport-effect span');
    menuCascadeEffect('.remuneration-effect span');
    menuCascadeEffect('.comms-effect span');
    menuCascadeEffect('.prevention-effect span');
    menuCascadeEffect('.company-effect span');
    menuCascadeEffect('.report-effect span');
  });

  const setDropdownKey = (navLink, currentStatus) => {
    setActiveKey(navLink);
    switch (navLink) {
      case 'company':
        setCompanyActive(!currentStatus);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setSelectionActive(false);
        setRemunerationActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'assistance':
        setCompanyActive(false);
        setAssistanceActive(!currentStatus);
        setEmployeeActive(false);
        setSelectionActive(false);
        setRemunerationActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'employee':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(!currentStatus);
        setSelectionActive(false);
        setRemunerationActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'importer':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setSelectionActive(false);
        setRemunerationActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'remuneration':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setSelectionActive(false);
        setRemunerationActive(!currentStatus);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'selection':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setRemunerationActive(false);
        setSelectionActive(!currentStatus);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'legalreport':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setRemunerationActive(false);
        setSelectionActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'training':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setRemunerationActive(false);
        setSelectionActive(false);
        setTrainingActive(!currentStatus);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'communications':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setRemunerationActive(false);
        setSelectionActive(false);
        setTrainingActive(false);
        setCommsActive(!currentStatus);
        setPreventionActive(false);
        setReportActive(false);
        break;
      case 'prevention':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setSelectionActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(!preventionActive);
        setReportActive(false);
        break;
      case 'report':
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setSelectionActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        setPreventionActive(false);
        setReportActive(!currentStatus);
        break;
      default:
        setCompanyActive(false);
        setAssistanceActive(false);
        setEmployeeActive(false);
        setRemunerationActive(false);
        setSelectionActive(false);
        setTrainingActive(false);
        setCommsActive(false);
        closeSidebar();
    }
  };

  return (
    <div className="scroll-box">
      <Nav className="flex-column">
        <div className="d-lg-none">
          <Nav.Link eventKey="profile" as={Link} to="/profile/dashboard" onClick={closeSidebar}>
            <Icon icon="person-outline" />
            <span className="link-menu position-absolute">Mi Perfil</span>
          </Nav.Link>
        </div>
        {companyMenu.some(item => item) && (
          <Dropdown as={Nav.Item} show={companyActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('company', companyActive)}>
              <Icon icon="briefcase-outline" />
              <span className="link-menu position-absolute">Empresa</span>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount show={companyActive}>
              <div className="company-effect">
                <div className="border-line" />
                {companyMenu[0] && (
                  <Dropdown.Item
                    eventKey="company"
                    active={pathName === 'company'}
                    as={Link}
                    to="/company"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Ficha</span>
                  </Dropdown.Item>
                )}
                {(companyMenu[2] || companyMenu[3]) && (
                  <Dropdown.Item
                    eventKey="groupings"
                    as={Link}
                    active={pathName === 'groupings'}
                    to="/groupings"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Agrupaciones</span>
                  </Dropdown.Item>
                )}
                {companyMenu[6] && (
                  <Dropdown.Item
                    eventKey="settings"
                    as={Link}
                    active={pathName === 'settings'}
                    to="/settings"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Configuraciones</span>
                  </Dropdown.Item>
                )}
                {companyMenu[7] && (
                  <Dropdown.Item
                    eventKey="roles"
                    as={Link}
                    active={pathName === 'roles'}
                    to="/roles"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Perfiles</span>
                  </Dropdown.Item>
                )}
                {companyMenu[8] && (
                  <Dropdown.Item
                    eventKey="advance_settings"
                    as={Link}
                    active={pathName === 'advance_settings'}
                    to="/advance_settings"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Ajustes</span>
                  </Dropdown.Item>
                )}
                {companyMenu[9] && (
                  <Dropdown.Item
                    eventKey="importers"
                    as={Link}
                    active={pathName === 'importers'}
                    to="/importers"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Parámetros</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {employeesMenu.some(item => item) && (
          <Dropdown as={Nav.Item} show={employeeActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('employee', employeeActive)}>
              <Icon icon="people-outline" />
              <span className="link-menu position-absolute">Gestión de Personas</span>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount show={employeeActive}>
              <div className="employee-effect">
                <div className="border-line" />
                {employeesMenu[0] && (
                  <Dropdown.Item
                    eventKey="dashboard"
                    active={pathName === 'dashboard'}
                    as={Link}
                    to="/dashboard"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Dashboard</span>
                  </Dropdown.Item>
                )}
                {employeesMenu[1] && (
                  <Dropdown.Item
                    eventKey="organization_charts"
                    as={Link}
                    active={pathName === 'organization_charts'}
                    to="/organization_charts"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Organigrama</span>
                  </Dropdown.Item>
                )}
                {employeesMenu[0] && (
                  <Dropdown.Item
                    eventKey="employees"
                    active={pathName === 'employees'}
                    as={Link}
                    to="/employees"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Colaboradores</span>
                  </Dropdown.Item>
                )}
                {employeesMenu[3] && (
                  <Dropdown.Item
                    eventKey="requests"
                    active={pathName === 'requests'}
                    as={Link}
                    to="/requests"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Solicitudes</span>
                  </Dropdown.Item>
                )}
                {companyMenu[4] && (
                  <Dropdown.Item
                    eventKey="document_templates"
                    as={Link}
                    active={pathName === 'document_templates'}
                    to="/document_templates"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Plantillas</span>
                  </Dropdown.Item>
                )}
                {employeesMenu[8] && (
                  <Dropdown.Item
                    eventKey="get_notifications_period"
                    active={pathName === 'get_notifications_period'}
                    as={Link}
                    to="/get_notifications_period"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Notificacion de FUN</span>
                  </Dropdown.Item>
                )}
                {employeesMenu[5] && (
                  <Dropdown.Item
                    eventKey="license"
                    as={Link}
                    active={pathName === 'license'}
                    to="/license/imed"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Tramitar Licencias</span>
                  </Dropdown.Item>
                )}
                {companyMenu[5] && (
                  <Dropdown.Item
                    eventKey="import_documents"
                    as={Link}
                    active={pathName === 'import_documents'}
                    to="/import_documents"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Importar Documentos</span>
                  </Dropdown.Item>
                )}
                {companyMenu[6] && (
                  <Dropdown.Item
                    eventKey="simulators"
                    active={pathName === 'simulators'}
                    as={Link}
                    to="/simulators"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Embrutecedor de Sueldo</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {ability.can('index', 'Document') && (
          <Nav.Link
            onClick={() => setDropdownKey('signature', false)}
            active={activeKey === 'signature'}
            eventKey="signature"
            as={Link}
            to="/signature"
          >
            <Icon icon="pie-chart-outline" />
            <span className="link-menu position-absolute">Firma Digital</span>
          </Nav.Link>
        )}
        {assistanceMenu && assistanceMenu.some(item => item) && (
          <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={assistanceActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('assistance', assistanceActive)}>
              <Icon icon="menu" />
              <span className="link-menu position-absolute">Control del Tiempo</span>
            </Dropdown.Toggle>
            <Dropdown.Menu show={assistanceActive}>
              <div className="assistance-effect">
                <div className="border-line" />
                {assistanceMenu[0] && (
                  <Dropdown.Item
                    eventKey="assistance"
                    active={pathName === 'assistance'}
                    as={Link}
                    to="/assistance"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Dashboard</span>
                  </Dropdown.Item>
                )}
                {assistanceMenu[0] && (
                  <Dropdown.Item
                    eventKey="attendances_hash"
                    active={pathName === 'attendances_hash'}
                    as={Link}
                    to="/attendances_hash"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Busqueda por Hash</span>
                  </Dropdown.Item>
                )}
                {assistanceMenu[4] && (
                  <Dropdown.Item
                    eventKey="calendar"
                    active={pathName === 'calendar'}
                    as={Link}
                    to="/calendar"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Calendario</span>
                  </Dropdown.Item>
                )}
                {assistanceMenu[1] && (
                  <Dropdown.Item
                    eventKey="shifts"
                    as={Link}
                    active={pathName === 'shifts'}
                    to="/shifts"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Configuración de turnos</span>
                  </Dropdown.Item>
                )}
                {ability.can('index', 'MarkingCorrectionReport') && (
                  <Dropdown.Item
                    eventKey="marking_correction"
                    as={Link}
                    active={pathName === 'marking_correction'}
                    to="/marking_correction"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Corrección de marcación</span>
                  </Dropdown.Item>
                )}

                {assistanceMenu[2] && (
                  <Dropdown.Item
                    eventKey="records"
                    active={pathName === 'records'}
                    as={Link}
                    to="/records"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Informes y registros</span>
                  </Dropdown.Item>
                )}
                {assistanceMenu[4] && (
                  <Dropdown.Item
                    eventKey="legal_reports"
                    active={pathName === 'legal_reports'}
                    as={Link}
                    to="/legal_reports"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Reportes DT</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {remunerationMenu && remunerationMenu.some(item => item) && (
          <Dropdown as={Nav.Item} show={remunerationActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('remuneration', remunerationActive)}>
              <Icon icon="wallet-outline" />
              <span className="link-menu position-absolute">Remuneraciones</span>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount show={remunerationActive}>
              <div className="remuneration-effect">
                <div className="border-line" />
                {remunerationMenu[0] && (
                  <Dropdown.Item
                    eventKey="remunerations_settings"
                    active={pathName === 'remunerations_settings'}
                    as={Link}
                    to="/remunerations_settings"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Configuraciones</span>
                  </Dropdown.Item>
                )}
                {remunerationMenu[2] && (
                  <Dropdown.Item
                    eventKey="advance_payments_process"
                    active={pathName === 'advance_payments_process'}
                    as={Link}
                    to="/advance_payments_process"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Proceso de Anticipos</span>
                  </Dropdown.Item>
                )}
                {remunerationMenu[1] && (
                  <Dropdown.Item
                    eventKey="payroll_processes"
                    active={pathName === 'payroll_processes'}
                    as={Link}
                    to="/payroll_processes"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Proceso de Remuneración</span>
                  </Dropdown.Item>
                )}
                {remunerationMenu[6] && (
                  <Dropdown.Item
                    eventKey="data_load"
                    active={pathName === 'data_load'}
                    as={Link}
                    to="/data_load"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu animation-link position-absolute">Carga de Datos</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {commsMenu.some(item => item) && (
          <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={commsActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('communications', commsActive)}>
              <Icon icon="paper-plane-outline" />
              <span className="link-menu position-absolute">Comunicación/Beneficios</span>
            </Dropdown.Toggle>
            <Dropdown.Menu show={commsActive}>
              <div className="comms-effect">
                <div className="border-line" />
                {commsMenu[1] && (
                  <Dropdown.Item
                    eventKey="announcements"
                    as={Link}
                    active={pathName === 'announcements'}
                    to="/announcements"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Fechas destacadas</span>
                  </Dropdown.Item>
                )}
                {commsMenu[3] && (
                  <Dropdown.Item
                    eventKey="benefits"
                    as={Link}
                    active={pathName === 'benefits'}
                    to="/benefits"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Beneficios</span>
                  </Dropdown.Item>
                )}
                {commsMenu[0] && (
                  <Dropdown.Item
                    eventKey="groups"
                    active={pathName === 'groups'}
                    as={Link}
                    to="/groups"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Grupos de Comunicación</span>
                  </Dropdown.Item>
                )}
                {commsMenu[2] && (
                  <Dropdown.Item
                    eventKey="tasks"
                    as={Link}
                    active={pathName === 'tasks'}
                    to="/tasks"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Anuncios/Tareas</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {selectionProcessMenu && selectionProcessMenu.some(item => item) && (
          <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={selectionActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('selection', selectionActive)}>
              <Icon icon="trello" />
              <span className="link-menu position-absolute">Reclutamiento/Selección</span>
            </Dropdown.Toggle>
            <Dropdown.Menu show={selectionActive}>
              <div className="selection-effect">
                <div className="border-line" />
                {selectionProcessMenu[0] && (
                  <Dropdown.Item
                    eventKey="selection_processes"
                    active={pathName === 'selection_processes'}
                    as={Link}
                    to="/selection_processes"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Dashboard</span>
                  </Dropdown.Item>
                )}
                {selectionProcessMenu[1] && (
                  <Dropdown.Item
                    eventKey="candidates"
                    as={Link}
                    active={pathName === 'candidates'}
                    to="/candidates"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Candidatos</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {trainingMenu && trainingMenu.some(item => item) && (
          <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={trainingActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('training', trainingActive)}>
              <Icon icon="school-outline" />
              <span className="link-menu position-absolute">Capacitación</span>
            </Dropdown.Toggle>
            <Dropdown.Menu show={trainingActive}>
              <div className="training-effect">
                <div className="border-line" />
                {trainingMenu[0] && (
                  <Dropdown.Item
                    eventKey="courses"
                    active={pathName === 'courses'}
                    as={Link}
                    to="/courses"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Planificación de Cursos</span>
                  </Dropdown.Item>
                )}
                {trainingMenu[1] && (
                  <Dropdown.Item eventKey="dnc" active={pathName === 'dnc'} as={Link} to="/dnc" onClick={closeSidebar}>
                    <span className="ml-n2 link-menu position-absolute">Solicitud de Capacitación</span>
                  </Dropdown.Item>
                )}
                {trainingMenu[2] && (
                  <Dropdown.Item
                    eventKey="training_program"
                    active={pathName === 'training_program'}
                    as={Link}
                    to="/training_program"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Programa de Capacitación</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {preventionMenu && preventionMenu.some(item => item) && (
          <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={preventionActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('prevention', preventionActive)}>
              <Icon icon="book-outline" />
              <span className="link-menu position-absolute">Prevención de Riesgo</span>
            </Dropdown.Toggle>
            <Dropdown.Menu show={preventionActive}>
              <div className="prevention-effect">
                <div className="border-line" />
                {preventionMenu[0] && (
                  <>
                    <Dropdown.Item
                      eventKey="risk_prevention"
                      active={pathName === 'risk_prevention'}
                      as={Link}
                      to="/risk_prevention"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Documentación Legal</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="supporting_documentation"
                      active={pathName === 'supporting_documentation'}
                      as={Link}
                      to="/supporting_documentation"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Documentación de Apoyo</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="internal_documentation"
                      active={pathName === 'internal_documentation'}
                      as={Link}
                      to="/internal_documentation"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Documentación Interna</span>
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {reportMenu && reportMenu.some(item => item) && (
          <Dropdown as={Nav.Item} className="no-border-radius-bottom" show={reportActive}>
            <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('report', reportActive)}>
              <Icon icon="folder-open" />
              <span className="link-menu position-absolute">Reportes</span>
            </Dropdown.Toggle>
            <Dropdown.Menu show={reportActive}>
              <div className="report-effect">
                <div className="border-line" />
                {reportMenu[0] && (
                  <>
                    <Dropdown.Item
                      eventKey="basic"
                      active={pathName === 'basic'}
                      as={Link}
                      to="/basic"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Basicos</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="custom"
                      active={pathName === 'custom'}
                      as={Link}
                      to="/custom"
                      onClick={closeSidebar}
                    >
                      <span className="ml-n2 link-menu position-absolute">Personalizados</span>
                    </Dropdown.Item>
                  </>
                )}
                {companyMenu[2] && (
                  <Dropdown.Item
                    eventKey="import_logs"
                    as={Link}
                    active={pathName === 'import_logs'}
                    to="/import_logs"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Historial</span>
                  </Dropdown.Item>
                )}
                {reportMenu[1] && (
                  <Dropdown.Item
                    eventKey="company_logs"
                    active={pathName === 'company_logs'}
                    as={Link}
                    to="/company_logs"
                    onClick={closeSidebar}
                  >
                    <span className="ml-n2 link-menu position-absolute">Transacciones de Sistema</span>
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {ability.can('read', 'Ticket') && (
          <Nav.Link
            onClick={() => setDropdownKey('tickets', false)}
            active={activeKey === 'tickets'}
            eventKey="tickets"
            as={Link}
            to="/tickets"
          >
            <Icon icon="help-circle-outline" />
            <span className="link-menu position-absolute">Soporte</span>
          </Nav.Link>
        )}
        <div className="d-lg-none">
          <Nav.Link
            eventKey="change_pin"
            onClick={() => {
              closeSidebar();
              showChangePin();
            }}
          >
            <Icon icon="pencil" />
            <div className="position-relative position-link width-fixed">
              <span className="link-menu position-absolute">Cambiar PIN de Firma</span>
            </div>
          </Nav.Link>
          <Nav.Link
            eventKey="change_password"
            onClick={() => {
              closeSidebar();
              showChangePassword();
            }}
          >
            <Icon icon="settings-outline" />
            <div className="position-relative position-link width-fixed">
              <span className="link-menu position-absolute">Cambiar Contraseña</span>
            </div>
          </Nav.Link>
          {children}
        </div>
      </Nav>
    </div>
  );
};

export default SidebarBasic;
