import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createAdvancePaymentRequest } from '../../../requests/advancePayments';
import { sendAlert } from '../../../actions/utils';
import AdvancePaymentForm from '../../Shared/AdvancePayment/AdvancePaymentForm';

const basicAdvancePayment = {
  advanceNumber: '',
  amount: '',
  endDate: '',
  name: '',
  payday: '',
  permanent: false,
  reason: '',
  startDate: ''
};

const AdvancePaymentNew = ({
  onSuccess,
  action,
  apAmountLimit,
  apDayLimit,
  nextMonth,
  employeeId,
  rrhh = false,
  directApprove = false
}) => {
  const dispatch = useDispatch();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud creada con éxito' }));
    onSuccess();
  };

  const handleCreateRequest = (params, setSubmitting) => {
    createAdvancePaymentRequest({
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <Row>
      <Col>
        <AdvancePaymentForm
          action={action || 'new'}
          nextMonth={nextMonth}
          advancePayment={{ ...basicAdvancePayment, directApprove: rrhh || directApprove }}
          apAmountLimit={apAmountLimit}
          apDayLimit={apDayLimit}
          employeeId={employeeId}
          formRequest={handleCreateRequest}
          rrhh={rrhh}
        />
      </Col>
    </Row>
  );
};

export default AdvancePaymentNew;
