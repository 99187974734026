import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { profileRegisterRequest } from '../../../requests/dashboards';
import AttendanceDataTable from './AttendanceDataTable';
import columns from './Columns';

const AttendanceRegisterIndex = ({ currentEmployee }) => {
  const [todayRegisterCard, setTodayRegisterCard] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();

  const buttonToggle = () => setToggle(!toggle);

  const handleSuccessShow = response => {
    const { data } = response;
    setTodayRegisterCard(camelCaseEmptyStringRecursive(data.attendances));
  };

  const fetchEmployeeCards = () => {
    if (currentEmployee) {
      profileRegisterRequest({
        dispatch,
        successCallback: handleSuccessShow
      });
    }
  };

  useEffect(fetchEmployeeCards, [currentEmployee]);

  return (
    <>
      <Row className="mt-3 border-responsive">
        <Col className="d-md-none" xs={12}>
          <div className="d-flex justify-content-between">
            <Button variant="outline-primary" onClick={buttonToggle}>
              {toggle ? 'Ver datos' : 'Ver registro'}
            </Button>
          </div>
        </Col>
        <Col md={5} xs={12}>
          <Card className={`register-attendance-margin ${toggle ? 'mt-3' : 'd-none'} d-md-block`}>
            <Card.Body>
              <Card.Header>Registro de hoy</Card.Header>
              <Row className="mt-3">
                <Col md={12}>
                  <Row>
                    <Col>
                      <Card.Title className="text-uppercase">Tipo</Card.Title>
                      {todayRegisterCard.length > 0 ? (
                        todayRegisterCard.map(attendance => (
                          <p key={`attendance-type-${attendance.id}`}>{attendance.translatedTimeType}</p>
                        ))
                      ) : (
                        <p>Sin registros</p>
                      )}
                    </Col>
                    <Col className="attendance-card">
                      <Card.Title className="text-uppercase">Hora</Card.Title>
                      {todayRegisterCard.length > 0 ? (
                        todayRegisterCard.map(attendance => (
                          <p key={`attendance-time-${attendance.id}`}>{attendance.parsedTime}</p>
                        ))
                      ) : (
                        <p>--:--</p>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className={`${toggle ? 'd-none' : ''} d-md-block`} xs={12}>
          <AttendanceDataTable
            customParams={{ filter_by_employee: currentEmployee }}
            columns={columns}
            moreData={moreData}
            setMoreData={setMoreData}
          />
        </Col>
      </Row>
    </>
  );
};

export default AttendanceRegisterIndex;
