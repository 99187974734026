/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { FormikInput } from '../../../components';
import { delayMethod } from '../../../services/utils';

const DirectoryNodeFormFolder = props => {
  const { onHide, errors, touched, subtitle } = props;

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={12}>
            {subtitle}
            <Field name="directoryNode[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre Carpeta"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn" variant="outline-info" onClick={() => delayMethod(onHide)}>
          Cancelar
        </Button>
        <Button type="submit" className="btn" variant="primary">
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = ({ folderName }) => {
  return {
    directoryNode: {
      name: folderName || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  directoryNode: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(DirectoryNodeFormFolder);
