/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createDropZoneFileRequest } from '../../../requests/dropzone';
import { Dropzone } from '../../../components';
import { delayMethod } from '../../../services/utils';

const DirectoryNodeFormFiles = props => {
  const { onHide, submitVariant, setFieldValue } = props;
  const [onRequest, setOnRequest] = useState(false);
  const [msgMaxFiles, setMsgMaxFiles] = useState('');
  const dispatch = useDispatch();

  const handleOnDrop = async (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'import',
        documents
      }
    };
    setOnRequest(true);
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        setOnRequest(false);
        return response;
      }
    });
  };

  const handleOnDropUploaded = uploadedDocuments => {
    setFieldValue(
      'directoryNode[documentKeys]',
      uploadedDocuments.map(file => file.code)
    );
  };

  return (
    <Form>
      {onRequest && (
        <div className="containerSpinnerLoad mt-4 position-absolute h-100" style={{ maxHeight: 200, right: 0 }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Modal.Body>
        <div className={onRequest && 'bg-opacity'}>
          <Dropzone
            fileAccept=".pdf .xls .doc .docx .rtf .xlsx .ppt .pptx .jpg .jpeg .gif .mp3 .mp4 .wmv .avi .png"
            maxSize={50000000}
            multiple
            onDrop={handleOnDrop}
            batchNumber={20}
            maxFiles={1000}
            customClass="long-dropzone"
            onDropUploaded={handleOnDropUploaded}
            msgMaxFiles={msg => setMsgMaxFiles(msg)}
            onFileDialogOpen={() => setOnRequest(true)}
            onFileDialogCancel={() => setOnRequest(false)}
          />
        </div>
        {<p className="font-weight-bold mt-3">{msgMaxFiles}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn" variant="outline-info" onClick={() => delayMethod(onHide)}>
          Cancelar
        </Button>
        <Button type="submit" className="btn" variant={submitVariant} onClick={onHide}>
          Importar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    directoryNode: {
      documentKeys: []
    }
  };
};

const validationSchema = Yup.object().shape({
  directoryNode: Yup.object().shape({
    documentKeys: Yup.array()
      .min(1, 'Debes agregar al menos un archivo')
      .required('Debes agregar al menos un archivo')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(DirectoryNodeFormFiles);
