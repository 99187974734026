import ApiService from '../services/apiService';

export const indexAdvanceSettingsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/advance_settings', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const updateAdvanceSettingRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/advance_settings/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const advanceSettingAdvancePaymentsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/advance_settings/advance_payments', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateAdvanceSettingAdvancePaymentsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/advance_settings/update_advance_payment', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });
