const documentStatus = [
  { label: 'Aprobado', value: 'approved' },
  { label: 'Emitido', value: 'not_signed' },
  { label: 'Pendiente', value: 'pending' },
  { label: 'Rechazado', value: 'rejected' }
];

const documentTypes = [
  { label: 'Amonestación', value: 'reprimand_document' },
  { label: 'Anexo de Contrato', value: 'contract_annex' },
  { label: 'Carta de Felicitación', value: 'congratulation_letter' },
  { label: 'Contrato', value: 'work_contract' },
  { label: 'Certificado', value: 'certificate' },
  { label: 'Finiquito', value: 'work_settlement' },
  { label: 'Inducción en prevención de riesgos', value: 'induction_risk_prevention' },
  { label: 'Liquidación de Sueldo', value: 'salary_settlement' },
  { label: 'Obligación de informar los riesgos laborales', value: 'report_occupational_hazards' },
  { label: 'Otros Documentos', value: 'other_documents' },
  { label: 'Prevención de Riesgos', value: 'risk_prevention' },
  { label: 'Registro de aceptación del RIOHS', value: 'acceptance_riohs' },
  { label: 'Solicitudes', value: 'employee_requests' }
];

export { documentStatus, documentTypes };
