import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import AssistanceRecordDataTable from './AssistanceRecord';

const WorkdayReportIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix mb-0">Informe de asistencia</h4>
        </Col>
      </Row>
      <AssistanceRecordDataTable />
    </>
  );
};

export default WorkdayReportIndex;
