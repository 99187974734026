import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';

import { indexRolesRequest } from '../../../requests/roles';
import { DualList, FormikInput } from '../../../components';

const RoleForm = ({ submitVariant, isSubmitting, setFieldValue, values, errors, touched }) => {
  const {
    profile: { roleIds }
  } = values;
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);

  const fetchRoles = () => {
    const request = async () => {
      indexRolesRequest({
        dispatch,
        params: {
          paginate: false
        },
        successCallback: response => setRoles(response.data)
      });
    };
    request();
  };

  useEffect(fetchRoles, []);

  return (
    <Form>
      <Row>
        <Col>
          <h4 className="text-uppercase margin-fix">Información</h4>
        </Col>
        <Col md={12}>
          <Field name="profile[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col className="mt-4">
          <DualList
            options={roles}
            selected={roleIds}
            name="profile[roleIds]"
            onChange={selected => setFieldValue('profile[roleIds]', selected)}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-5 mt-md-3 mb-3">
        <Col md={3} xl={2}>
          <Button type="submit" disabled={isSubmitting} variant={submitVariant} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { profile } = props;
  return {
    profile: {
      name: profile.name,
      roleIds: profile.roleIds
    }
  };
};

const validationSchema = Yup.object().shape({
  profile: Yup.object().shape({
    exclude: Yup.bool(),
    name: Yup.string().required('Debes ingresar un nombre')
    // roleIds: Yup.array(Yup.number()).min(1, 'Debes ingresar al menos un permiso') FALTA AGREGAR ESTILO AL COMPONENTE
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(RoleForm);
