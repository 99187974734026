import React from 'react';
import { Image, Button } from 'react-bootstrap';

const BenefitModalShow = ({ benefit }) => {
  const image = benefit.benefitsImage.fileUrl;
  const newTab = () => window.open(benefit.link, 'noopener', 'noreferrer');

  return (
    <section className="px-3 mt-n3 pb-3">
      {image && (
        <div className="d-flex justify-content-center">
          <Image className="max-height-300" src={image} alt={benefit.name} fluid />
        </div>
      )}
      <h2 className={`${image ? 'mt-3' : 'mt-n4'} mb-3 text-uppercase`}>{benefit.name}</h2>
      <pre>
        <p className="text-justify">{benefit.description}</p>
      </pre>
      <div className="d-flex flex-row-reverse mt-4">
        {benefit.link && (
          <Button variant="primary" onClick={newTab}>
            Ir a beneficio
          </Button>
        )}
      </div>
    </section>
  );
};

export default BenefitModalShow;
