import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { sendAlert } from '../../actions/utils';
import {
  debounceIndexRiskPreventionDocumentRequest,
  downloadRiskPreventionDocumentRequest
} from '../../requests/riskPreventionDocuments';
import { BasicCard, ButtonTooltip, Icon } from '../../components';
import { downloadFile } from '../../services/utils';

const RiskPreventionSupportingDocumentationIndex = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [riskPreventionDocument, setRiskPreventionDocument] = useState([]);
  const dispatch = useDispatch();
  const { currentEmployee } = useSelector(state => state.auth);
  const documentTypes = [
    'voting_workers',
    'committee_hygiene_safety',
    'work_accidents',
    'election_representatives_hygiene_safety',
    'meeting_hygiene_safety'
  ];

  const handleIndexRequest = () => {
    setOnRequest(true);
    debounceIndexRiskPreventionDocumentRequest({
      dispatch,
      params: {
        document_type: documentTypes
      },
      successCallback: response => setRiskPreventionDocument(camelCaseRecursive(response.data.data)),
      callback: () => setOnRequest(false)
    });
  };

  const downloadRiskPreventionDocument = (id, type) => {
    setOnRequest(true);
    downloadRiskPreventionDocumentRequest(id, {
      dispatch,
      params: {
        template_pdf: {
          document_type: type,
          employee_ids: [currentEmployee]
        }
      },
      formData: true,
      successCallback: response => {
        downloadFile(response);
        dispatch(sendAlert({ kind: 'success', message: 'Documento descargado exitosamente' }));
      },
      callback: () => setOnRequest(false)
    });
  };
  useEffect(handleIndexRequest, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mb-3 mt-3">Documentación de Apoyo</h2>
        </Col>
      </Row>
      <Row className={`${onRequest && 'bg-opacity'}`}>
        {riskPreventionDocument.map(document => (
          <Col key={document.id} xs={12} md={6}>
            <BasicCard
              size="100%"
              classNameCard="z-index-auto"
              bodyMarginBottom="-10px"
              colorCard="white"
              divBody
              text={
                <Row>
                  <Col md={9} xl={10}>
                    <h3 className="mb-4 futura text-uppercase font-size-title">{document.translatedDocumentType}</h3>
                  </Col>
                  <Col className="d-flex justify-content-end align-items-start">
                    <ButtonTooltip
                      onClick={() => downloadRiskPreventionDocument(document.id, document.documentType)}
                      variant="circle-primary"
                      toolbarVariant="ml-auto"
                      className="btn-circle"
                      size="sm"
                      text="Descargar"
                    >
                      <Icon icon="download-2" />
                    </ButtonTooltip>
                  </Col>
                </Row>
              }
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default RiskPreventionSupportingDocumentationIndex;
