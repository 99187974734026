import ApiService from '../services/apiService';

export const createDropZoneFileRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/public/drop_zone_files', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const deleteDropZoneFileRequest = (id, { dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', `/public/drop_zone_files/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });
