import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner } from 'react-bootstrap';
import BasicTimeline from '../Utils/TimeLine';
import { sendAlert } from '../../actions/utils';
import { getWorkflowRequestsRequest } from '../../requests/workflowRequests';

const WorkflowTimeline = ({ workflowId, document, handleClose }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessRequest = ({ data }) => {
    const requestReviewers = data.request_reviewers_attributes.sort(
      (firstElement, secondElement) => firstElement.position - secondElement.position
    );

    const formattedRequestReviewers = requestReviewers.map(item => {
      const itemObject = {
        title: item.employee_reviewer_ids[0].label,
        body: item.status === 'rejected' ? `Observación: ${data.observation}` : '',
        type: item.signature ? 'Firma' : 'Aprobación',
        status: item.status,
        dateTime: item.signature_reviewers.length > 0 ? item.signature_reviewers[0].signature_date : '',
        key: item.id
      };

      return itemObject;
    });

    setItems(camelCaseRecursive(formattedRequestReviewers));
  };

  const handleFailureRequest = () => {
    handleClose();
    dispatch(sendAlert({ kind: 'error', message: 'Flujo no encontrado' }));
  };

  const getWorkflowRequest = () => {
    const request = () => {
      getWorkflowRequestsRequest(workflowId, {
        dispatch,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest,
        callback: () => setOnRequest(false)
      });
    };
    request();
  };

  useEffect(getWorkflowRequest, [workflowId]);

  return (
    <>
      <h3 className="text-center">
        {' '}
        {document.fileInfo.cleanFilename} <br /> <small>{document.employee.label}</small>{' '}
      </h3>
      {onRequest && (
        <div className="containerSpinnerLoad">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <BasicTimeline items={items} />
    </>
  );
};

WorkflowTimeline.propTypes = {
  workflowId: PropTypes.number.isRequired
};

export default WorkflowTimeline;
