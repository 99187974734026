import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FormikInput } from '../../../components';

const InfoTicketComments = ({ ticketComments, displayModal, setComment, isOpen }) => {
  const newComment = () => (
    <FormikInput as="textarea" label="Comentario" onChange={data => setComment(data.target.value)} />
  );

  return (
    <Row>
      <Col xs={11} className="ticket-card mx-auto">
        <Row>
          <Col md={12}>
            <h3 className="mt-4 text-uppercase">Respuestas</h3>
          </Col>
        </Row>
        {ticketComments.length > 0 ? (
          <div className="default-scroll-box negative-margin comment-height">
            {ticketComments.map((ticketComment, index) => (
              <div key={`ticket-comment-${ticketComment.id.toString()}`}>
                <Row className="mt-3 mx-0">
                  <Col md={2}>
                    <p className="show-info-title">
                      {ticketComment.employee ? ticketComment.employee.fullName : 'Respuesta Soporte'}
                    </p>
                    <p className="show-info-title">{ticketComment.parsedCreatedAt}</p>
                  </Col>
                  <Col md={10} className="pl-4">
                    <p>{ticketComment.comment}</p>
                  </Col>
                </Row>
                {index !== ticketComments.length - 1 && <hr className="mt-3" />}
              </div>
            ))}
          </div>
        ) : (
          <>
            <Row className="my-4">
              <Col md={2}>
                <p className="show-info-title">Respuesta Soporte</p>
              </Col>
              <Col md={10} className="pl-4">
                <p>Pendiente</p>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={{ span: 3, offset: 9 }}>
            {isOpen && (
              <Button
                block
                variant="primary"
                className="mt-3"
                onClick={() => displayModal('Agregar Comentario', newComment(), 'Agregar', 'primary')}
              >
                Ingresar Comentario
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InfoTicketComments;
