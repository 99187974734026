import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, DatatableEmployeeName } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'employee_name',
    cell: item => (
      <DatatableEmployeeName item={item.employee} fileName="file_info" name="full_name" fileUrl="file_url" />
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'CARGO',
    selector: 'employee_job_title',
    cell: item => item.employee?.job_title,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TEMA',
    selector: 'topic',
    sortable: true,
    cell: item => item.topic?.label
  },
  {
    name: 'ÁREA DE NECESIDAD',
    selector: 'necessity_area',
    cell: item => item.necessity_area?.label,
    sortable: true,
    grow: '1'
  },
  {
    name: 'NIVEL PROFUNDIDAD',
    selector: 'depth_level',
    cell: item => item.translated_depth_level,
    sortable: true,
    grow: '1'
  },
  {
    name: 'NIVEL URGENCIA',
    selector: 'urgency_level',
    cell: item => item.translated_urgency_level,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA SUGERIDA',
    selector: 'suggested_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'COSTO',
    selector: 'cost',
    cell: item => item.cost_format,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Dnc"
        clickHandler={clickHandler}
        withShow={false}
        withEdit
        withDestroy={false}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
