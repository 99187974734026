import React from 'react';
import ChartContainer from '@jearaneda/react-new-orgchart';

const ChartTree = ({ datasource, node, parentRef, ...props }) => (
  <ChartContainer
    ref={parentRef}
    datasource={datasource}
    chartClass="myChart"
    NodeTemplate={node}
    pan
    minZoom={0.4}
    maxZoom={1.2}
    defaultZoom={0.8}
    toggleableSiblings={false}
    {...props}
  />
);

export default ChartTree;
