import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const BalanceShow = ({ document }) => {
  const { translatedTemplateType, name, description, translatedSize, translatedOrientation } = document;
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>{`Plantilla ${translatedTemplateType}`}</Card.Title>
          <Row>
            <Col md={6} xs={12}>
              <div className="info">
                <span className="type">Nombre:</span>
                <span className="line" />
                <span className="answer"> {name}</span>
              </div>
            </Col>
            <Col>
              <div className="info">
                <span className="type">Tamaño:</span>
                <span className="line" />
                <span className="answer"> {translatedSize}</span>
              </div>
              <div className="info">
                <span className="type">Orientación:</span>
                <span className="line" />
                <span className="answer"> {translatedOrientation}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="mb-2 mt-md-n2 text-uppercase font-weight-bold">Descripción:</p>
              <p className="font-weight-light ml-md-2">{description}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default BalanceShow;
