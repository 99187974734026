import React, { useState } from 'react';
import { useFormikContext, getIn, Field } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import EvaluationStepFields from './EvaluationStepFields';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { indexEmployeesRequest } from '../../requests/employees';
import { FormikInput, FormikRangePicker, EmployeeSearchDataTable, InputSelect, FormikNumber } from '../../components';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const FormStep1 = ({ performanceEvaluation, setStep }) => {
  const { errors, values, touched, setFieldValue, isSubmitting, setFieldTouched } = useFormikContext();
  const { startDate, endDate, evaluationStepsAttributes } = values.performanceEvaluation;
  const [selector, setSelector] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const dispatch = useDispatch();

  const fetchEmployees = (inputValue, callback) => {
    indexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        is_dt: false,
        filter_name: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const handleSuccessEmployees = response => {
    const vEmployees = camelCaseEmptyStringRecursive(response?.data?.data) || [];
    const { evaluatedEmployees } = performanceEvaluation;
    const employeeIds = evaluatedEmployees.map(item => item.id);

    const employeesToAdd = vEmployees.filter(selected => !employeeIds.includes(selected.id));
    const vSelector = [...selectorTypes, ...employeesToAdd];
    setSelector(vSelector);
  };

  const removeFromTable = (employee, action) => {
    if (action === 'destroy') {
      const { evaluatedEmployees: performanceEvaluationEmployees } = values.performanceEvaluation;
      const notRemovedEmployees = performanceEvaluationEmployees.filter(selected => selected.id !== employee.id);
      const addToSelector = [...selector, employee];
      setSelector(addToSelector);
      setFieldValue('performanceEvaluation[evaluatedEmployees]', notRemovedEmployees);
      if (notRemovedEmployees.length === 0) setFieldValue('performanceEvaluation[evaluatedEmployeeIds]', ['']);
      else
        setFieldValue(
          'performanceEvaluation[evaluatedEmployeeIds]',
          notRemovedEmployees.map(item => item.id)
        );
    }
  };

  const removeAllFromTable = () => {
    const selectedRowsIds = selectedRows.map(item => item.id);
    const notRemovedEmployees = values.performanceEvaluation.evaluatedEmployees.filter(
      selected => !selectedRowsIds.includes(selected.id)
    );
    setSelector([...selectorTypes, ...selector, ...selectedRows].filter(item => item.label));
    setFieldValue('performanceEvaluation[evaluatedEmployees]', notRemovedEmployees);
    setFieldValue(
      'performanceEvaluation[evaluatedEmployeeIds]',
      notRemovedEmployees.map(item => item.id)
    );
    setSelectedCount(0);
    setSelectedRows([]);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const addAllEmployees = () => {
    const { performanceEvaluation: performanceEvaluationValues } = values;
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const selectedEmployeeIds = vSelector.map(employee => employee.value);
    const selectedEmployees = vSelector.map(employee => ({ ...employee }));
    setSelector([selectorTypes]);
    setFieldValue('employeeSelector', '');
    setFieldValue('performanceEvaluation[evaluatedEmployees]', [
      ...selectedEmployees,
      ...performanceEvaluationValues.evaluatedEmployees
    ]);
    setFieldValue('performanceEvaluation[evaluatedEmployeeIds]', [
      ...selectedEmployeeIds,
      ...performanceEvaluationValues.evaluatedEmployeeIds
    ]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== values.employeeSelector);
    if (vSelector.length === selector.length) {
      return;
    }
    if (values.employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === values.employeeSelector);
      setFieldValue(`performanceEvaluation[evaluatedEmployeeIds]`, [
        ...values.performanceEvaluation.evaluatedEmployeeIds,
        employeeToAdd.value
      ]);
      setFieldValue('performanceEvaluation[evaluatedEmployees]', [
        ...values.performanceEvaluation.evaluatedEmployees,
        employeeToAdd
      ]);
      setSelector(vSelector);
    }
  };

  const handleSearch = selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    if (vSelector.length === selector.length) return;
    const employeesToAdd = selector.filter(selected => selectedEmployees.includes(selected.value));
    setFieldValue(`performanceEvaluation[evaluatedEmployeeIds]`, [
      ...values.performanceEvaluation.evaluatedEmployeeIds,
      ...employeesToAdd.map(item => item.value)
    ]);
    setFieldValue('performanceEvaluation[evaluatedEmployees]', [
      ...values.performanceEvaluation.evaluatedEmployees,
      ...employeesToAdd
    ]);
    setSelector(vSelector);
  };

  const nextStep = () => {
    setStep(2);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Field name="performanceEvaluation[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                abbr
                startDateName="performanceEvaluation[startDate]"
                endDateName="performanceEvaluation[endDate]"
                startDate={startDate}
                endDate={endDate}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="performanceEvaluation[parsedReminderDays]">
            {({ field }) => (
              <FormikNumber
                {...field}
                fieldName="performanceEvaluation[reminderDays]"
                setFieldValue={setFieldValue}
                value={values.performanceEvaluation.reminderDays}
                label="Días previos para recordatorio"
                tooltipText="Cantidad de días antes en que se enviará un recordatorio a los evaluadores para completar 
                             la evaluación."
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="performanceEvaluation[startText]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                rows={5}
                label="Mensaje de Bienvenida"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="performanceEvaluation[endText]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                rows={5}
                label="Mensaje de Salida"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="performanceEvaluation[description]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                rows={5}
                label="Descripción"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <h4 className="text-uppercase mb-3">Etapas</h4>
      <p className="text-muted">Haz click en una etapa para activarla o desactivarla</p>
      <Row>
        {evaluationStepsAttributes.map((item, index) => (
          <EvaluationStepFields evaluationStep={item} index={index} key={item.name} />
        ))}
      </Row>
      <h4 className="text-uppercase mb-3">Evaluadores</h4>
      <Row>
        <Col md={12}>
          <Field name="performanceEvaluation[evaluatorEmployeeIds]">
            {({ field }) => (
              <InputSelect
                {...field}
                abbr
                isMulti
                label="Selecciona un evaluador. Puedes agregar más de uno."
                placeholder="Seleccionar Evaluadores"
                values={values.performanceEvaluation}
                model={[performanceEvaluation, 'evaluatorEmployee']}
                request={fetchEmployees}
                onChange={data => setFieldValue(field.name, data?.map(element => element.value) || [''])}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <h4 className="text-uppercase mb-3">Trabajadores</h4>
      <EmployeeSearchDataTable
        baseModel={performanceEvaluation}
        employees={values.performanceEvaluation.evaluatedEmployees}
        handleActions={removeFromTable}
        handleMassAction={removeAllFromTable}
        handleSearch={handleSearch}
        handleSelectedRows={handleSelectedRows}
        handleSelector={handleSelector}
        handleSuccessEmployees={handleSuccessEmployees}
        options={selector}
        selectedCount={selectedCount}
      />
      <Row className="d-flex justify-content-end my-5">
        <Col md={2}>
          <Button variant="info" block onClick={nextStep} disabled={isSubmitting}>
            Siguiente
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" block disabled={isSubmitting}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FormStep1;
