import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import moment from 'moment';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import { Row, Col, FormCheck } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import { useAbility } from '@casl/react';
import Icon from '../../components/Icons/Icon';
import RenderMonthElement from '../../components/Utils/Input/RenderMonthElement';
import { legalreportOffdaysReport } from '../../requests/legalreports';
import { ComponentDataTable, DefaultModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { OffdaysFilterDataTable } from '../../components/LegalReport';
import { downloadFile } from '../../services/utils';
import LegalReportFilter from './Filters/LegalReportFilter';
import ReportActions from './ReportActions';
import { indexEmployeesRequest } from '../../requests/employees';

const columns = memoize(() => [
  {
    name: 'RUT',
    selector: 'national_identification',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: true,
    grow: '2'
  },
  {
    name: 'LUGAR DE PRESTACION DE SERVICIOS',
    selector: 'branch_office',
    sortable: false,
    grow: '3'
  },
  {
    name: 'ACTIVIDAD LABORAL',
    selector: 'economic_activity',
    sortable: true,
    grow: '1'
  }
]);

const recordsColumns = memoize(() => [
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '4'
  },
  {
    name: 'HORARIO/EVENTO',
    selector: 'shift_name',
    sortable: true,
    grow: '4',
    center: true
  },
  {
    name: 'ASISTENCIA',
    selector: 'attendance',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'AUSENCIA',
    selector: 'absence',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'OBSERVACIONES',
    selector: 'justification',
    sortable: true,
    grow: '5',
    center: true
  }
]);

export const MyExpander = props => {
  const { data, dateRange } = props;
  const { id } = data;

  return (
    <OffdaysFilterDataTable
      columns={recordsColumns}
      customParams={{ filter_by_employee: id, date_range: dateRange }}
      props
    />
  );
};

export const LoadingTranslation = () => {
  return <h3>Cargando...</h3>;
};

const OffdaysReportDataTable = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [wordModalShow, setWordModalShow] = useState(false);
  const [query, setQuery] = useState({});
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filters, setFilters] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'years'));
  const [endDate, setEndDate] = useState(moment());
  const [focusStartDate, setFocusStartDate] = useState(false);
  const [focusEndDate, setFocusEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [employeeIds, setEmployeeIds] = useState([]);

  const [amount, setAmount] = useState(0);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleRequest = async (params, format, responseType = 'json') => {
    setOnRequest(true);
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date;
    setQuery(empParams);
    indexEmployeesRequest({
      dispatch,
      params: { ...filters, ...empParams, active: true, is_dt: false },
      format,
      responseType,
      successCallback: response => {
        const { data } = response;
        setAmount(data.metadata.amount);
        setFilteredEmployees(data.data);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleOffdayReportRequest = async (params, format, type = 'json') => {
    const responseType = format === '.docx' ? 'blob' : type;
    setOnRequest(true);
    legalreportOffdaysReport({
      dispatch,
      params: { filter_by_employee: employeeIds, date_range: dateRange, ...params, paginate: false },
      format,
      responseType,
      successCallback: response => {
        setClearSelectedRows(true);
        if (format === '.xlsx' || format === '.docx') {
          downloadFile(response);
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassAction = action => {
    setEmployeeIds(selectedRows.map(employee => employee.id));

    switch (action) {
      case 'download_excel':
        setModalShow(true);
        break;
      case 'download_docx':
        setWordModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const handleFilters = params => {
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date;
    empParams.filter_ids.push(empParams.filter_id);
    setFilters(empParams);
    setSearchFirstStart(true);
    const DateRange = [startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY')];
    setDateRange(DateRange);
    handleRequest(params);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };
  return (
    <>
      <Row>
        <Col xs={6} md={3} xl={3}>
          <FormCheck.Label className="d-flex align-items-center" style={{ marginBottom: '-5px' }}>
            {'Fecha Inicio'}
          </FormCheck.Label>
          <SingleDatePicker
            block
            disabled
            id="datatable-start-date-input"
            date={startDate}
            placeholder="dd/mm/aaaa"
            focused={focusStartDate}
            maxDate={endDate}
            onFocusChange={({ focused }) => {
              setFocusEndDate(false);
              setFocusStartDate(focused);
            }}
            numberOfMonths={1}
            onDateChange={date => {
              setStartDate(date);
            }}
            hideKeyboardShortcutsPanel
            inputIconPosition={ICON_AFTER_POSITION}
            firstDayOfWeek={1}
            renderMonthElement={RenderMonthElement}
            isOutsideRange={day => day.isAfter(endDate)}
          />
        </Col>
        <Col xs={6} md={3} xl={3}>
          <FormCheck.Label className="d-flex align-items-center" style={{ marginBottom: '-5px' }}>
            {'Ingrese Fecha de termino'}
            <abbr className="text-danger ml-1">*</abbr>
          </FormCheck.Label>
          <SingleDatePicker
            block
            id="datatable-end-date-input"
            date={endDate}
            placeholder="dd/mm/aaaa"
            focused={focusEndDate}
            onFocusChange={({ focused }) => {
              setFocusStartDate(false);
              setFocusEndDate(focused);
            }}
            numberOfMonths={1}
            onDateChange={date => {
              setEndDate(date);
              setStartDate(moment(date).subtract(1, 'years'));
            }}
            showClearDate
            customCloseIcon={<Icon icon="close" width={18} />}
            customInputIcon={<Icon icon="calendar" width={18} />}
            hideKeyboardShortcutsPanel
            inputIconPosition={ICON_AFTER_POSITION}
            firstDayOfWeek={1}
            renderMonthElement={RenderMonthElement}
            isOutsideRange={day => day.isBefore(startDate)}
          />
        </Col>
      </Row>
      <LegalReportFilter formRequest={handleFilters} filterOffday={false} />

      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={filteredEmployees}
        totalRows={amount}
        withMassActions={ability.can('index', 'HolidayReport')}
        massActions={<ReportActions disabled={!selectedCount || onRequest} handleClick={handleMassAction} />}
        resourceRequest={handleRequest}
        expandableRows
        expandableRowsComponent={<MyExpander dateRange={dateRange} />}
        progressPending={onRequest}
        progressComponent={<LoadingTranslation />}
        searchFirstStart={searchFirstStart}
        selectableRows
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title="Exportar Reporte de Asistencias"
        body="¿Estás seguro que quieres exportar el reporte de asistencia en formato Excel?"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => {
          handleOffdayReportRequest(query, '.xlsx', 'text');
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <DefaultModal
        title="Exportar Reporte de Asistencias"
        body="¿Estás seguro que quieres exportar el reporte de asistencia en formato Word?"
        show={wordModalShow}
        handleClose={() => setWordModalShow(false)}
        handleConfirm={() => {
          handleOffdayReportRequest(query, '.docx', 'text');
          setWordModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default OffdaysReportDataTable;
