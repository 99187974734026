import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPerformanceEvaluationRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('get', '/performance_evaluations', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showPerformanceEvaluationRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/performance_evaluations/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createPerformanceEvaluationRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', '/performance_evaluations', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const updatePerformanceEvaluationRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/performance_evaluations/${id}`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const destroyPerformanceEvaluationRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/performance_evaluations/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexPerformanceEvaluationRequest = AwesomeDebouncePromise(indexPerformanceEvaluationRequest, 300);
