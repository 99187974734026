import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showNumberFunesPendingRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/xdatas/number_funes_pending', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const indexNotificationPeriodRequest = ({ dispatch, params = {}, successCallback, callback, failureCallback }) => {
  ApiService.request('get', '/xdatas/get_notifications_period', {
    dispatch,
    params,
    successCallback,
    callback,
    failureCallback
  });
};

export const showImageFunRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/xdatas/request_image_fun/', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const updateSendNotificationEmployeerRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', '/xdatas/send_notification_employeer/', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const debounceindexNotificationPeriodRequest = AwesomeDebouncePromise(indexNotificationPeriodRequest, 300);
