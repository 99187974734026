import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal, Form as BSForm } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import {
  FormikCheckBox,
  FormikInput,
  FormikRangePicker,
  FormikSelect,
  InputSelect,
  UploadImage
} from '../../components';
import { debounceIndexGroupRequest } from '../../requests/groups';
import { announcementTypes, statuses } from './FormOptions';

import '../../services/yupCustomMethods';

const AnnouncementForm = ({
  announcement,
  errors,
  isSubmitting,
  setFieldValue,
  touched,
  values,
  setFieldTouched,
  boss
}) => {
  const dispatch = useDispatch();
  const { announcementType, startDate, endDate, status } = values.announcement;

  const resultFetchData = response => {
    const { data } = response.data;
    return data.map(element => ({
      label: element.name,
      value: element.id
    }));
  };

  const fetchGroups = (inputValue, callback) => {
    const request = async () => {
      debounceIndexGroupRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const resetValues = () => {
    if (announcementType === 'event') {
      setFieldValue('announcement[withEmail]', false);
      setFieldValue('announcement[withNotification]', false);
    }
  };
  useEffect(resetValues, [announcementType]);
  return (
    <Modal.Body>
      <Form>
        {boss && <p className="text-warning">Esta comunicación estará disponible para toda su jefatura.</p>}
        <Row>
          <Col md={6}>
            <Field name="announcement[announcementType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo"
                  placeholder="Seleccionar tipo"
                  options={announcementTypes}
                  defaultValue={announcementType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="announcement[title]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Título"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        {values.announcement.announcementType === 'post' && (
          <Row>
            <Col md={6}>
              <Field name="announcement[withNotification]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Enviar Notificación"
                    custom
                    type="switch"
                    tooltipClass="ml-2 mt-2"
                    tooltipText="Al marcar esta casilla a los trabajadores les llegará una notificación en la
                                aplicación cuando este anuncio este visible."
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="announcement[withEmail]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Enviar Email"
                    custom
                    type="switch"
                    tooltipClass="ml-2 mt-2"
                    tooltipText="Al marcar esta casilla a los trabajadores les llegará un correo a su email personal
                                cuando este anuncio este visible."
                  />
                )}
              </Field>
            </Col>
          </Row>
        )}
        <Row>
          {!boss && (
            <>
              <Col md={6}>
                <Field name="announcement[groupId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      isClearable
                      placeholder="Trabajadores Activos"
                      label="Grupo"
                      request={fetchGroups}
                      tooltipText="En caso de no asignarse grupo se enviará a todos los trabajadores activos"
                      values={values.announcement}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      model={[announcement, 'group']}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="announcement[status]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="Estado"
                      placeholder="Seleccionar estado"
                      options={statuses}
                      defaultValue={status}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          <Col md={6}>
            <Field name="rangeDate">
              {({ field }) => (
                <FormikRangePicker
                  {...field}
                  abbr
                  startDateName="announcement[startDate]"
                  endDateName="announcement[endDate]"
                  startDate={startDate}
                  endDate={endDate}
                  showClearDates
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} className={`pt-${values.announcement.announcementType === 'event' ? '2' : '5'}`}>
            <Field name="announcement[image]">
              {({ field }) => (
                <>
                  <BSForm.Label className="d-md-block d-none">Imágen</BSForm.Label>
                  <UploadImage
                    {...field}
                    label="Imagen"
                    name="Imagen"
                    imageUrl={getIn(announcement.fileInfo, 'fileUrl')}
                    onChange={_avatar => setFieldValue(field.name, _avatar)}
                    fileAccept=".png, .jpg, .gif, .jpeg"
                    helpText="Formato sugerido 535x175px de máximo 5mb."
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="announcement[description]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  as="textarea"
                  rows={5}
                  label="Descripción"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end my-3 mr-4">
          <Col md={2}>
            <Button type="submit" block disabled={isSubmitting}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  );
};

const setInitialValues = props => {
  const { announcement } = props;
  return { announcement, rangeDate: [announcement.startDate, announcement.endDate] };
};

const validationSchema = Yup.object().shape({
  announcement: Yup.object().shape({
    announcementType: Yup.string().required('Debes ingresar un tipo'),
    description: Yup.string(),
    endDate: Yup.date().formatdate(),
    groupId: Yup.string(),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'La imagen cargada excede el tamaño maximo permitido (5mb).',
        value => !value?.size || (value && value?.size <= 5242880)
      )
      .test(
        'FILE_FORMAT',
        'El archivo cargado tiene un formato no compatible.',
        value =>
          (!value && value?.type !== '') ||
          (value && ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(value?.type))
      ),
    title: Yup.string()
      .required('Debes ingresar un título')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    startDate: Yup.date().formatdate(),
    status: Yup.string().required('Debes ingresar un estado'),
    withEmail: Yup.boolean(),
    withNotification: Yup.boolean()
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AnnouncementForm);
