import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { showAdminEmployeeRequest } from '../../../requests/adminEmployees';
import { EmployeeTopView } from '../../../components';
import EmployeeTabs from './EmployeeTabs';

import basicEmployee from './employee';

const EmployeeShow = ({ match }) => {
  const [employee, setEmployee] = useState(basicEmployee);
  const dispatch = useDispatch();
  const { params } = match;

  const fetchEmployee = () => {
    showAdminEmployeeRequest(params.id, {
      dispatch,
      successCallback: response => setEmployee(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchEmployee, []);

  return (
    <>
      <EmployeeTopView employee={employee.employeeCard} editTo="/admin/employees" returnTo="/admin/employees" canEdit />
      <EmployeeTabs employee={employee} />
    </>
  );
};

export default EmployeeShow;
