import React from 'react';
import JobManagementDataTable from './JobManagementDataTable';

const JobManagementIndex = ({ moreData, setMoreData }) => {
  return (
    <>
      <JobManagementDataTable moreData={moreData} setMoreData={setMoreData} />
    </>
  );
};

export default JobManagementIndex;
