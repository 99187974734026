import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal, Spinner } from 'react-bootstrap';

import { FormikDatePicker } from '../Utils/Input';
import { InputSelect } from '../Utils/Select';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';

const OvertimeAgreementsForm = ({ submitVariant, errors, touched, setFieldValue, isSubmitting }) => {
  const dispatch = useDispatch();
  const fetchDocumentTemplates = (inputValue, callback) => {
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: 'overtime_agreements',
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Field name="templatePdf[templateId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="Plantilla"
                    placeholder="Seleccionar Plantilla"
                    value={undefined}
                    request={fetchDocumentTemplates}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="templatePdf[extraKeys][0][value]">
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    isOutsideRange={() => false}
                    label="Fecha Inicio"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {isSubmitting && (
            <Spinner className="width-spinner-dnc position-absolute" animation="border" variant="primary" />
          )}
          <Button className="btn" type="submit" variant={submitVariant} disabled={isSubmitting}>
            Crear
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { employeeIds } = props;
  return {
    templatePdf: {
      documentType: 'overtime_agreements',
      templateId: '',
      employeeIds,
      extraKeys: [{ code: '[[fecha_inicio_clausula]]', value: '' }]
    }
  };
};

const validationSchema = Yup.object().shape({
  templatePdf: Yup.object().shape({
    templateId: Yup.string().required('Debes seleccionar una plantilla')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(connect()(OvertimeAgreementsForm));
