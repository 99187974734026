import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexImportLogsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', '/import_logs', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const exportImportLogsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/import_logs/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const debounceIndexImportLogsRequest = AwesomeDebouncePromise(indexImportLogsRequest, 300);
