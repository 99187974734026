import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import ShiftChangeDataTable from '../Shared/ShiftChange/ShiftChangeDataTable';
import columns from '../Profile/ShiftChange/Columns';
import { LongCard } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { HRShiftChangeRequest } from '../../requests/dashboards';

const ShiftChangeDashboard = ({ moreData, setMoreData, title }) => {
  const [cards, setCards] = useState({});
  const [activeCard, setActiveCard] = useState('all');
  const [customParams, setCustomParams] = useState({});
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const dispatch = useDispatch();

  const handleClick = card => {
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setActiveCard('pending');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      case 'approved':
        setCustomParams({ status: 'approved' });
        setActiveCard('approved');
        setDefaultStartDate(moment().startOf('year'));
        setDefaultEndDate(moment().endOf('year'));
        break;
      case 'rejected':
        setCustomParams({ status: 'rejected' });
        setActiveCard('rejected');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      default:
        setCustomParams({});
        setActiveCard('all');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
    }
    setMoreData(!moreData);
  };

  const fetchDashboardCards = () => {
    const request = async () => {
      HRShiftChangeRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  useEffect(fetchDashboardCards, [moreData]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="my-3" />
      {!title && (
        <Col className="mb-3">
          <h2 className="m-top mb-3 mt-4">Cambio de Turno</h2>
        </Col>
      )}
      <Row className="mt-4 mb-3">
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.all}
            label="Solicitados"
            active={activeCard === 'all'}
            onClick={() => handleClick('all')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pending}
            label="Por autorizar"
            active={activeCard === 'pending'}
            onClick={() => handleClick('pending')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.rejected}
            label="Rechazados"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="primary"
            title={cards.approvedThisYear}
            label="Aprobados este año"
            active={activeCard === 'approved'}
            onClick={() => handleClick('approved')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ShiftChangeDataTable
            columns={columns}
            moreData={moreData}
            setMoreData={setMoreData}
            rrhh
            customParams={customParams}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
          />
        </Col>
      </Row>
    </>
  );
};

export default ShiftChangeDashboard;
