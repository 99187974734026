import ApiService from '../services/apiService';

export const exportContractBalancesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/contract_balances/export.xlsx`, {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const preImportContractBalanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/contract_balances/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importContractBalanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/contract_balances/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateContractBalanceRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/contract_balances/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};
