import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, ApprovementStatus } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'NOMBRE DE GRUPO',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TRABAJADORES',
    selector: 'employees_count',
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL BRUTO',
    selector: 'gross_amount',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL LÍQUIDO',
    selector: 'liquid_amount',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
          disabled={item.status === 'pending'}
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
          disabled={item.status === 'closed'}
        >
          <Icon icon="pencil" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text="Eliminar"
          disabled={item.status === 'closed'}
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    grow: '1',
    minWidth: '123px'
  }
]);
