import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { FormikNumber } from '../../components';

const SettlementInfo = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Información general</Card.Title>
          <h5 className="text-uppercase font-weight-bold mt-0 mb-4">Proceso Asociado N° 000</h5>

          <Row className="info-box custom-box mx-0">
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Empresa</span> Nnodes
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">RUT Empresa</span> 00.000.000-0
              </p>
            </Col>
          </Row>
          <Row className="info-box custom-box mx-0 mb-3">
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Valor UF</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Tope 90 UF</span> $0.000.000
              </p>
            </Col>
          </Row>

          <Row className="info-box custom-box mx-0">
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Trabajador</span> Nombre Apellido
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Identificación (RUT)</span> 00.000.000-0
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Cargo</span> Desarrollador de Software Full Stack
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Tipo de Contrato</span> Indefinido
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Fecha de Ingreso</span> 31/12/2020
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Tiempo Trabajado</span> 25 años, 10 meses, 31 días
              </p>
            </Col>
          </Row>
          <hr />

          <h4 className="mt-4 mb-3">Definiciones para el Cálculo</h4>
          <Row className="info-box custom-box mx-0">
            <Col sm={6} md={5}>
              <p className="info-settlement">
                <span className="title-xs font-weight-bold">Fecha de Término</span> 30/12/2021
              </p>
            </Col>
            <Col sm={6} md={5}>
              <p className="info-settlement">
                <span className="title-xs font-weight-bold">Fecha de Pago</span> 31/12/2021
              </p>
            </Col>
          </Row>
          <Row className="info-box custom-box mx-0">
            <Col xs={12}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Causal de Término</span> Art. 161 bis - Invalidez, total o
                parcial del trabajador, sujeto a indemnización
              </p>
            </Col>
            <Col xs={12}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Justificación de la Causal</span> Lorem ipsum dolor sit amet
                consectetur adipiscing, elit parturient tristique condimentum suspendisse cras sodales, ridiculus
                vivamus morbi cum nisi. Parturient netus congue quam nullam aenean nunc sociis semper tortor, cras
                ligula quis purus porttitor sociosqu turpis a, fermentum nulla cum phasellus dignissim euismod sed id.
                Arcu tortor convallis porta hac enim ut pellentesque sociosqu aliquam curabitur, nec rhoncus a in
                lacinia donec luctus habitasse odio nisl vitae, fermentum gravida nisi iaculis netus eget integer
                sodales etiam.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Indicadores</Card.Title>

          <h4 className="mt-4 mb-3">Haberes</h4>
          <Row className="info-box custom-box mx-0">
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Sueldo Base</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Gratificación</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Bono Fijo Imponible</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Semana Corrida</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Asignación Fija Imponible</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Otras Remuneraciones Fijas</span> $0.000.000
              </p>
            </Col>
          </Row>
          <Row className="mt-4 d-flex justify-content-end">
            <Col md={6} lg={5}>
              <Row className="align-items-center justify-content-end">
                <Col xs={6} md={5} lg={4}>
                  <h5 className="font-weight-bold text-uppercase my-2">Total Haberes</h5>
                </Col>
                <Col xs={6} md={5} className="pl-0">
                  <FormikNumber disabled leftAddon="$" margin="mb-0" value={9999999} />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />

          <h4 className="mt-4 mb-3">Descuentos</h4>
          <Row className="info-box custom-box mx-0">
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Préstamos</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Anticipos</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Remuneraciones</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Descuento Seleccionado</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Descuento Seleccionado</span> $0.000.000
              </p>
            </Col>
            <Col sm={6} lg={4}>
              <p className="info-settlement">
                <span className="title-md font-weight-bold">Descuento Seleccionado</span> $0.000.000
              </p>
            </Col>
          </Row>
          <Row className="mt-4 d-flex justify-content-end">
            <Col md={6} lg={5}>
              <Row className="align-items-center justify-content-end">
                <Col xs={6} md={5} lg={4}>
                  <h5 className="font-weight-bold text-uppercase my-2">Total Descuentos</h5>
                </Col>
                <Col xs={6} md={5} className="pl-0">
                  <FormikNumber disabled leftAddon="$" margin="mb-0" value={9999999} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Total a pagar</Card.Title>

          <h4 className="mt-4 mb-3">General</h4>
          <Row className="info-box custom-box mx-0">
            <Col sm={6} lg={3}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Feriados Pendientes</span> 00 días
              </p>
            </Col>
            <Col sm={6} lg={3}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Días Inhábiles</span> 00
              </p>
            </Col>
            <Col sm={6} lg={3}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Feriados a Pagar</span> 00 días
              </p>
            </Col>
            <Col sm={6} lg={3}>
              <p className="info-settlement">
                <span className="title-sm font-weight-bold">Años de Servicio</span> 00
              </p>
            </Col>
          </Row>
          <hr />

          <h4 className="mt-4 mb-3">Base de Cálculos</h4>
          <Row className="mt-4 info-box custom-box mx-0 d-flex align-items-center">
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Años de Servicio y Mes de Aviso</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Vacaciones</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Remuneración Diaria</span> $0.000.000
              </p>
            </Col>
          </Row>
          <hr />

          <h4 className="mt-4 mb-3">Cálculos</h4>
          <Row className="mt-4 info-box custom-box mx-0 d-flex align-items-center">
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Feriados Anual Pendiente</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Feriados Proporcional</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Indemnización Años de Servicio</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Indemnización Sustitutiva de Aviso Previo</span> $0.000.000
              </p>
            </Col>
            <Col md={6} lg={4}>
              <p className="info-settlement">
                <span className="title-lg font-weight-bold">Indemnización Voluntaria</span> $0.000.000
              </p>
            </Col>
          </Row>

          <Row className="mt-4 d-flex justify-content-end">
            <Col md={7} lg={6}>
              <Row className="align-items-center justify-content-end">
                <Col xs={5} md={5} lg={3}>
                  <span>Subtotal</span>
                </Col>
                <Col xs={7} md={5}>
                  <FormikNumber disabled leftAddon="$" margin="mb-0" value={0} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4 d-flex justify-content-end">
            <Col md={7} lg={6}>
              <Row className="align-items-center justify-content-end">
                <Col xs={5} md={5} lg={3}>
                  <span>Total descuentos</span>
                </Col>
                <Col xs={7} md={5}>
                  <FormikNumber disabled leftAddon="$" margin="mb-0" value={9999999} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4 d-flex justify-content-end">
            <Col md={7} lg={6}>
              <Row className="align-items-center justify-content-end">
                <Col xs={5} md={5} lg={3} className="pr-0">
                  <Card.Title className="mb-0">Total a pagar</Card.Title>
                </Col>
                <Col xs={7} md={5}>
                  <FormikNumber disabled leftAddon="$" margin="mb-0" value={0} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SettlementInfo;
