import React from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FormikInput, FormikCheckBox } from '../../components';

const BookkeeperDocumentTypeForm = ({ onHide, submitVariant, errors, touched, action, isSubmitting }) => {
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Field name="bookkeeperDocumentType[acronym]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Sigla"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="bookkeeperDocumentType[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12} className="d-flex">
            <Field name="bookkeeperDocumentType[isActive]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  custom
                  label="Activo"
                  type="switch"
                  disabled={action === 'new'}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};
const setInitialValues = props => {
  const { id, name, acronym, isActive } = props.bookkeeperDocumentType;
  return {
    bookkeeperDocumentType: {
      id,
      name,
      acronym,
      isActive
    }
  };
};

const validationSchema = Yup.object().shape({
  bookkeeperDocumentType: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(60, 'Debe tener máximo 60 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    acronym: Yup.string()
      .required('Debes ingresar una sigla')
      .max(10, 'Debe tener máximo 10 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(connect()(BookkeeperDocumentTypeForm));
