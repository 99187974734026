import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import moment from 'moment';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Field, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  ComponentDataTable,
  BasicActionBtns,
  SimpleCenteredModal,
  FormikDatePicker,
  FormikInput,
  ModalTowActions,
  ButtonTooltip,
  Icon
} from '../../components';
import {
  pdfLicenseImedRequest,
  lmeEstLccRequest,
  lmeInfSeccCNotReciveRequest,
  lmeInfSeccCAutoRequest
} from '../../requests/licenseImed';
import GetStatus from '../../components/DatatableColumns/GetStatus';
import StatusLicenseInfo from './StatusLicenseInfo';
import { sendAlert } from '../../actions/utils';
import { typeLicense, statusLicence, reasonNotToReceive } from './FormOptions';
import { searchEmployeeByRutRequest } from '../../requests/employees';

const employeeFullName = (employeeName = null, employeeSurname = null, employeeSecondSurname = null) => {
  return `${employeeName !== null ? employeeName : ''}
          ${employeeSurname !== null ? employeeSurname : ''}
          ${employeeSecondSurname !== null ? employeeSecondSurname : ''}`;
};

const columns = memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'nombre_trabajador',
    cell: item => (
      <div className="d-flex flex-column">
        <span className="text-truncate" data-tag="allowRowEvents">
          {employeeFullName(item.nombre_trabajador, item.paterno_trabajador, item.materno_trabajador)}
        </span>
        <span style={{ marginTop: 3 }} className="text-muted" data-tag="allowRowEvents">
          {`${item.rut_trabajador}-${item.dig_trabajador}`}
        </span>
      </div>
    ),
    sortable: true,
    grow: '1'
  },
  {
    name: 'LICENCIA',
    selector: 'num_licencia',
    cell: item => (
      <div className="d-flex flex-column">
        <span className="text-truncate" data-tag="allowRowEvents">
          Folio: {item.num_licencia}-{item.dig_licencia}
        </span>
        <span style={{ fontSize: 11, marginTop: 3 }} className="text-muted" data-tag="allowRowEvents">
          Reposo Total: {item.dias_licencia} días Fecha de emisión: {moment(item.fecha_emision).format('DD/MM/YYYY')}
        </span>
        <span style={{ fontSize: 11 }} className="text-muted" data-tag="allowRowEvents">
          Tipo: <GetStatus code={item.codigo_tipo_licencia} listStatus={typeLicense} />
        </span>
      </div>
    ),
    sortable: true,
    grow: '1.5'
  },
  {
    name: 'ESTADO',
    selector: 'estado',
    cell: item => <GetStatus code={item.estado} listStatus={statusLicence} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <BasicActionBtns
          item={item}
          withShow={false}
          withDestroy={false}
          withEdit={false}
          clickHandler={clickHandler}
          extraIcon="eye"
          extraIconAction="status-license"
          extraIconColor="circle-info"
          extraIconText="Estados de Licencia"
        />
        <BasicActionBtns
          item={item}
          withShow={false}
          withDestroy={false}
          withEdit={false}
          clickHandler={clickHandler}
          extraIcon="document"
          extraIconAction="document"
          extraIconColor="circle-primary"
          extraIconText="Ver Licencia"
        />
        <ButtonTooltip
          variant="circle-warning"
          className="btn-circle"
          onClick={() => clickHandler(item, 'transact')}
          disabled={item.estado !== '1'}
          text="Tramitar Licencia"
        >
          <Icon icon="settings-outline" />
        </ButtonTooltip>
        <ButtonTooltip
          variant="circle-danger"
          className="btn-circle"
          onClick={() => clickHandler(item, 'not_receive')}
          disabled={item.estado !== '1'}
          text="Rechazar Licencia"
        >
          <Icon icon="close" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '175px'
  }
]);

const LicenseImedDataTable = ({ license, handleReFilter, onRequest }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShowD, setModalShowD] = useState('');
  const [modalTitleBtn, setModalTitleBtn] = useState('');
  const [endContract, setEndContract] = useState('');
  const [size, setSize] = useState();
  const [titleModal, setTitleModal] = useState();
  const dispatch = useDispatch();
  const [numLicense, setNumLicense] = useState('');
  const [dvLicense, setDvLicense] = useState('');
  const [vDateEndContract, setVDateEndContract] = useState();
  const [modalItem, setModalItem] = useState();
  const [modalActionOne, setModalActionOne] = useState();
  const [modalActionTwo, setModalActionTwo] = useState();
  const [withActionTow, setWithActionTow] = useState(true);
  const [withActionOne, setWithActionOne] = useState(true);
  const [withClose, setwithClose] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();

  const downloadPDF = base64 => {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement('a');
    const fileName = `Licencia_Medica.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleSuccessLicense = response => {
    const { data } = response;
    const base64Pdf = data.lme_det_lcc_pdf_response.dcto_lme;
    setSize('xl');
    setTitleModal('Licencia Médica');

    const downloadBtn = (
      <Button
        onClick={() => downloadPDF(base64Pdf)}
        variant="primary"
        target="_blank"
        rel="noopener noreferrer"
        className="ml-4"
      >
        Descargar PDF
      </Button>
    );
    const body = (
      <iframe
        src={`data:application/pdf;base64,${base64Pdf}`}
        style={{ height: '600px' }}
        title="LME"
        className="w-100"
      >
        <p>Este navegador no soporta PDFs. Por favor descarga el PDF para verlo: {downloadBtn}</p>
      </iframe>
    );
    setModalTitleBtn(downloadBtn);
    setModalBody(body);
    setModalShow(true);
  };

  const handleSuccessStatusLicense = response => {
    const { data } = response;
    const { item } = data.lme_est_lcc_response.lista_estados;
    setSize('lg');
    setTitleModal('Historico de Estado');
    const body = <StatusLicenseInfo statusLicence={item} />;
    setModalTitleBtn(false);
    setModalBody(body);
    setModalShow(true);
  };
  const handleRequestLicensePdf = async params => {
    pdfLicenseImedRequest({
      dispatch,
      params,
      successCallback: handleSuccessLicense
    });
  };

  const handleRequestStatusLicense = async params => {
    lmeEstLccRequest({
      dispatch,
      params,
      successCallback: handleSuccessStatusLicense
    });
  };

  const SignupSchema = Yup.object().shape({
    reason: Yup.string().required('Debe seleccionar un motivo'),
    dateEndContract: Yup.string().when('reason', {
      is: '2',
      then: Yup.string().required('El campo es requerido')
    }),
    otherReason: Yup.string().when('reason', {
      is: '5',
      then: Yup.string().required('El campo es requerido')
    })
  });

  const formReasonNotToReceive = () => (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          reason: '',
          dateEndContract: '',
          otherReason: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={async value => {
          const params = {
            num_license: numLicense,
            dv_license: dvLicense,
            reason_no_reception: value.reason,
            date_end_contract: value.dateEndContract,
            other_reason: value.otherReason
          };
          AwesomeDebouncePromise(
            lmeInfSeccCNotReciveRequest({
              dispatch,
              params,
              successCallback: response => {
                if (response.data.lme_inf_secc_c_response.estado === '1') {
                  dispatch(
                    sendAlert({ kind: 'error', message: 'La licencia que esta intentando rechazar ya fue tramitada.' })
                  );
                }
                if (response.data.lme_inf_secc_c_response.estado === '0') {
                  dispatch(sendAlert({ kind: 'success', message: 'La licencia fue rechazada con éxito' }));
                  handleReFilter();
                  setModalShow(false);
                  setEndContract('');
                  setVDateEndContract();
                }
              }
            }),
            500
          );
        }}
      >
        {({ handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {reasonNotToReceive.map(item => {
              return (
                <div key={item.value} className="mb-3">
                  <Form.Check type="radio">
                    <Form.Check.Input
                      type="radio"
                      name="reason"
                      value={item.value}
                      onChange={e => {
                        setEndContract(e.target.value);
                        handleChange(e);
                        setFieldValue('reason', e.target.value);
                      }}
                    />
                    <Form.Check.Label>{`${item.value}- ${item.label}`}</Form.Check.Label>
                    {errors.reason && <small className="text-danger form-text">{errors.reason}</small>}
                  </Form.Check>
                  {endContract === item.value.toString() && endContract === '2' && (
                    <Field name="dateEndContract">
                      {({ field }) => (
                        <FormikDatePicker
                          {...field}
                          abbr
                          value={vDateEndContract}
                          isOutsideRange={() => false}
                          label="Fecha término relación laboral"
                          onDateChange={date => {
                            setVDateEndContract(date);
                            setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : '');
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  )}
                  {endContract === item.value.toString() && endContract === '5' && (
                    <Field name="otherReason">
                      {({ field }) => (
                        <FormikInput
                          abbr
                          {...field}
                          label="Especifique el motivo"
                          placeholder=""
                          onChange={e => {
                            setFieldValue(field.name, e.target.value);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  )}
                </div>
              );
            })}
            <Button type="submit" disabled={isSubmitting}>
              Guardar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );

  const handleModalReasonNotToReceive = props => {
    setNumLicense(props.numLicense);
    setDvLicense(props.dvLicense);

    lmeEstLccRequest({
      dispatch,
      params: {
        num_license: props.numLicense,
        dv_license: props.dvLicense
      },
      successCallback: data => {
        const { item } = data.data?.lme_est_lcc_response?.lista_estados;
        let arraySort = [];
        if (Array.isArray(item)) {
          arraySort = item.sort().reverse();
        }
        if (!Array.isArray(item)) {
          arraySort = [item].sort().reverse();
        }
        if (arraySort[0].cod_estado === '1') {
          setSize('md');
          setTitleModal('No Recepcionar Licencia');
          setModalTitleBtn();
          const modal = formReasonNotToReceive;
          setModalBody(modal);
          setModalShow(true);
        }
        if (arraySort[0].cod_estado !== '1') {
          setTitleModal('No Recepcionar Licencia');
          setSize('md');
          setModalBody(`La licencia N° ${props.numLicense}-${props.dvLicense} ya fue tramitada y se encuentra en el estado 
          ${arraySort[0].cod_estado} - ${arraySort[0].glosa_estado}`);
          setModalShow(true);
        }
      }
    });
  };
  const employeeRequest = (item, acction) => {
    searchEmployeeByRutRequest({
      dispatch,
      params: { national_identification: `${item.rut_trabajador}${item.dig_trabajador}` },
      successCallback: response => {
        if (response.status === 200) {
          acction(item);
        }
        if (response.status === 204) {
          dispatch(sendAlert({ kind: 'error', message: 'Trabajador no encontrado' }));
        }
      }
    });
  };
  const fromProcess = item => {
    history.push(
      `/license/imed/${`${item.num_licencia}-${item.dig_licencia}`}/${`${item.rut_trabajador}-${item.dig_trabajador}`}/transact`
    );
  };
  const handleProcessLicenseForm = item => {
    employeeRequest(item, fromProcess);
  };
  const processLicenseRequest = item => {
    setIsDisabled(true);
    lmeInfSeccCAutoRequest({
      dispatch,
      params: {
        num_license: item.num_licencia,
        dv_license: item.dig_licencia,
        rut_employee: item.rut_trabajador,
        dv_employee: item.dig_trabajador,
        type_license: item.codigo_tipo_licencia,
        num_license_days: item.dias_licencia,
        sleep_start_date: item.fecha_inicio_reposo
      },
      successCallback: response => {
        if (typeof response.data.error !== 'undefined') {
          dispatch(sendAlert({ kind: 'error', message: response?.data.error }));
          setIsDisabled(false);
        }
        if (typeof response.data.error === 'undefined') {
          if (response.data.lme_inf_secc_c_response.estado === '1') {
            dispatch(sendAlert({ kind: 'error', message: response?.data.lme_inf_secc_c_response.glo_estado }));
            setIsDisabled(false);
          }
          if (response.data.lme_inf_secc_c_response.estado === '0') {
            dispatch(sendAlert({ kind: 'success', message: 'Licencia tramitada con exito' }));
            handleReFilter();
            setModalShowD(false);
            setIsDisabled(false);
          }
        }
      }
    });
  };
  const handleProcessLicenseRequest = item => {
    employeeRequest(item, processLicenseRequest);
  };

  const handleModalProcessLicenseModal = (props, data) => {
    const { item } = data.data?.lme_est_lcc_response?.lista_estados;

    let arraySort = [];
    if (Array.isArray(item)) {
      arraySort = item.sort().reverse();
    }
    if (!Array.isArray(item)) {
      arraySort = [item].sort().reverse();
    }

    if (arraySort[0].cod_estado === '1') {
      setTitleModal('Tramitar Licencia');
      setModalBody(`¿Estás seguro que desea traminar la licencia del colaborador ${props.nombre_trabajador}
            ${props.paterno_trabajador} ${props.paterno_trabajador}?`);
      setModalActionOne(() => handleProcessLicenseForm);
      setModalActionTwo(() => handleProcessLicenseRequest);
      setWithActionTow(true);
      setWithActionOne(true);
      setwithClose(false);
      setModalShowD(true);
      setModalItem(props);
    }
    if (arraySort[0].cod_estado !== '1') {
      setTitleModal('Tramitar Licencia');
      setSize('md');
      setModalBody(`La licencia N° ${props.num_licencia}-${props.dig_licencia} ya fue tramitada y se encuentra en el estado 
      ${arraySort[0].cod_estado} - ${arraySort[0].glosa_estado}`);
      setModalShow(true);
    }
  };

  const handleModalProcessLicense = async item => {
    lmeEstLccRequest({
      dispatch,
      params: {
        num_license: item.num_licencia,
        dv_license: item.dig_licencia
      },
      successCallback: data => {
        handleModalProcessLicenseModal(item, data);
      }
    });
  };

  const handleormReasonNotToReceive = () => {
    const modal = formReasonNotToReceive;
    setModalBody(modal);
  };
  useEffect(handleormReasonNotToReceive, [endContract, vDateEndContract]);

  const handleModalClose = () => {
    setModalShowD(false);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'document':
        handleRequestLicensePdf({
          num_license: item.num_licencia,
          dv_license: item.dig_licencia
        });

        break;
      case 'status-license':
        handleRequestStatusLicense({
          num_license: item.num_licencia,
          dv_license: item.dig_licencia
        });
        break;
      case 'transact':
        handleModalProcessLicense(item);
        break;
      case 'not_receive':
        handleModalReasonNotToReceive({
          numLicense: item.num_licencia,
          dvLicense: item.dig_licencia
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  return (
    <>
      {onRequest ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <ComponentDataTable
          onRequest={false}
          columns={columns(handleButtonClick)}
          data={license}
          withSearch={false}
          sortServer={false}
          paginationServer={false}
          onRowClicked={item => handleButtonClick(item, 'document')}
          pointerOnHover
        />
      )}

      <SimpleCenteredModal
        title={titleModal}
        titleBtn={modalTitleBtn}
        body={modalBody}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setEndContract('');
          setVDateEndContract();
        }}
        bodyClass="mx-3 mb-3"
        size={size}
      />

      <ModalTowActions
        title={titleModal}
        body={modalBody}
        show={modalShowD}
        handleConfirmActionOne={() => modalActionOne(modalItem)}
        handleConfirmActionTwo={() => modalActionTwo(modalItem)}
        withActionTow={withActionTow}
        withActionOne={withActionOne}
        withClose={withClose}
        handleClose={handleModalClose}
        titleBtnActionOne="Editar"
        titleBtnActionTwo="Tramitar"
        disabled={isDisabled}
      />
    </>
  );
};

export default LicenseImedDataTable;
