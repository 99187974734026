import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, ActiveDot } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DIRECCIÓN',
    selector: 'address',
    cell: item => item.full_address,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="BranchOffice" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
