import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const NonLoggedLayout = ({ component: Screen, ...props }) => {
  const pathName = () => {

    switch (localStorage.getItem('role')) {
      case 'dt':
        return '/dt/welcome';
      case 'admin':
        return '/admin/companies';
      default:
        return '/profile/dashboard';
    }
  };

  return (
    <Route
      {...props}
      render={rest =>
        localStorage.getItem('jwt') ? (
          <Redirect to={{ pathname: pathName(), state: { from: rest.location } }} />
        ) : (
          <>
            <Container fluid className="h-100">
              <Screen {...rest} />
            </Container>
          </>
        )
      }
    />
  );
}

export default NonLoggedLayout;

