const bankAccountTypes = [
  { label: 'Cuenta Corriente', value: 'current_acount' },
  { label: 'Cuenta de Ahorro', value: 'savings_account' },
  { label: 'Cuenta Vista', value: 'vista' }
];

const statusFun = [
  { label: 'Aceptado', value: 'AC' },
  { label: 'Rechazado', value: 'RE' },
  { label: 'Pendiente', value: 'PE' }
];

const paymentTypes = [
  { label: 'Cheque', value: 'paycheck' },
  { label: 'Efectivo', value: 'cash' },
  { label: 'Transferencia Bancaria', value: 'bank_transfer' },
  { label: 'Vale Vista', value: 'cashiers_check' }
];

const paymentTypesWithCompensation = [
  { label: 'Cheque', value: 'paycheck' },
  { label: 'Efectivo', value: 'cash' },
  { label: 'Transferencia Bancaria', value: 'bank_transfer' },
  { label: 'Vale Vista', value: 'cashiers_check' },
  { label: 'Caja de Compensación', value: 'compensation_fund_type' }
];

const countryCodes = [
  {
    countryName: 'Chile',
    value: '+56',
    label: '+56'
  },
  {
    countryName: 'Venezuela',
    value: '+58',
    label: '+58'
  },
  {
    countryName: 'Colombia',
    value: '+57',
    label: '+57'
  },
  {
    countryName: 'Haití',
    value: '+509',
    label: '+509'
  },
  {
    countryName: 'Argentina',
    value: '+54',
    label: '+54'
  },
  {
    countryName: 'Perú',
    value: '+51',
    label: '+51'
  },
  {
    countryName: 'Afganistán',
    value: '+93',
    label: '+93'
  },
  {
    countryName: 'Albania',
    value: '+355',
    label: '+355'
  },
  {
    countryName: 'Alemania',
    value: '+49',
    label: '+49'
  },
  {
    countryName: 'Andorra',
    value: '+376',
    label: '+376'
  },
  {
    countryName: 'Angola',
    value: '+244',
    label: '+244'
  },
  {
    countryName: 'Anguila',
    value: '1 264',
    label: '1 264'
  },
  {
    countryName: 'Antártida',
    value: '+672',
    label: '+672'
  },
  {
    countryName: 'Arabia Saudita',
    value: '+966',
    label: '+966'
  },
  {
    countryName: 'Argelia',
    value: '+213',
    label: '+213'
  },
  {
    countryName: 'Armenia',
    value: '+374',
    label: '+374'
  },
  {
    countryName: 'Aruba',
    value: '+297',
    label: '+297'
  },
  {
    countryName: 'Australia',
    value: '+61',
    label: '+61'
  },
  {
    countryName: 'Austria',
    value: '+43',
    label: '+43'
  },
  {
    countryName: 'Azerbaiyán',
    value: '+994',
    label: '+994'
  },
  // {
  //   countryName: 'Bahamas',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Bahréin',
    value: '+973',
    label: '+973'
  },
  {
    countryName: 'Bangladesh',
    value: '+880',
    label: '+880'
  },
  // {
  //   countryName: 'Barbados',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Bélgica',
    value: '+32',
    label: '+32'
  },
  {
    countryName: 'Belice',
    value: '+501',
    label: '+501'
  },
  {
    countryName: 'Benín',
    value: '+229',
    label: '+229'
  },
  // {
  //   countryName: 'Bermudas',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Bielorrusia',
    value: '+375',
    label: '+375'
  },
  {
    countryName: 'Birmania (Myanmar)',
    value: '+95',
    label: '+95'
  },
  {
    countryName: 'Bolivia',
    value: '+591',
    label: '+591'
  },
  {
    countryName: 'Bosnia-Herzegovina',
    value: '+387',
    label: '+387'
  },
  {
    countryName: 'Botsuana',
    value: '+267',
    label: '+267'
  },
  {
    countryName: 'Brasil',
    value: '+55',
    label: '+55'
  },
  {
    countryName: 'Brunéi',
    value: '+673',
    label: '+673'
  },
  {
    countryName: 'Bulgaria',
    value: '+359',
    label: '+359'
  },
  {
    countryName: 'Burkina Faso',
    value: '+226',
    label: '+226'
  },
  {
    countryName: 'Burundi',
    value: '+257',
    label: '+257'
  },
  {
    countryName: 'Bután',
    value: '+975',
    label: '+975'
  },
  {
    countryName: 'Cabo Verde',
    value: '+238',
    label: '+238'
  },
  {
    countryName: 'Camboya',
    value: '+855',
    label: '+855'
  },
  {
    countryName: 'Camerún',
    value: '+237',
    label: '+237'
  },
  // {
  //   countryName: 'Canadá',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'China',
    value: '+86',
    label: '+86'
  },
  {
    countryName: 'Chipre',
    value: '+357',
    label: '+357'
  },
  {
    countryName: 'Comoras',
    value: '+269',
    label: '+269'
  },
  {
    countryName: 'Corea del Norte',
    value: '+850',
    label: '+850'
  },
  {
    countryName: 'Corea del Sur',
    value: '+82',
    label: '+82'
  },
  {
    countryName: 'Costa de Marfil',
    value: '+225',
    label: '+225'
  },
  {
    countryName: 'Costa Rica',
    value: '+506',
    label: '+506'
  },
  {
    countryName: 'Croacia',
    value: '+385',
    label: '+385'
  },
  {
    countryName: 'Cuba',
    value: '+53',
    label: '+53'
  },
  {
    countryName: 'Curazao',
    value: '+599',
    label: '+599'
  },
  {
    countryName: 'Dinamarca',
    value: '+45',
    label: '+45'
  },
  {
    countryName: 'Djibuti',
    value: '+253',
    label: '+253'
  },
  // {
  //   countryName: 'Dominica',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Ecuador',
    value: '+593',
    label: '+593'
  },
  {
    countryName: 'Egipto',
    value: '+20',
    label: '+20'
  },
  {
    countryName: 'El Sáhara Español',
    value: '+212',
    label: '+212'
  },
  {
    countryName: 'El Salvador',
    value: '+503',
    label: '+503'
  },
  {
    countryName: 'El Vaticano',
    value: '+39',
    label: '+39'
  },
  {
    countryName: 'Emiratos Árabes Unidos',
    value: '+971',
    label: '+971'
  },
  {
    countryName: 'Eritrea',
    value: '+291',
    label: '+291'
  },
  {
    countryName: 'Eslovaquia',
    value: '+421',
    label: '+421'
  },
  {
    countryName: 'Eslovenia',
    value: '+386',
    label: '+386'
  },
  {
    countryName: 'España',
    value: '+34',
    label: '+34'
  },
  {
    countryName: 'Estados Unidos',
    value: '+1',
    label: '+1'
  },
  {
    countryName: 'Estonia',
    value: '+372',
    label: '+372'
  },
  {
    countryName: 'Etiopía',
    value: '+251',
    label: '+251'
  },
  {
    countryName: 'Filipinas',
    value: '+63',
    label: '+63'
  },
  {
    countryName: 'Finlandia',
    value: '+358',
    label: '+358'
  },
  {
    countryName: 'Fiyi',
    value: '+679',
    label: '+679'
  },
  {
    countryName: 'Francia',
    value: '+33',
    label: '+33'
  },
  {
    countryName: 'Gabón',
    value: '+241',
    label: '+241'
  },
  {
    countryName: 'Gambia',
    value: '+220',
    label: '+220'
  },
  {
    countryName: 'Georgia',
    value: '+995',
    label: '+995'
  },
  {
    countryName: 'Ghana',
    value: '+233',
    label: '+233'
  },
  {
    countryName: 'Gibraltar',
    value: '+350',
    label: '+350'
  },
  {
    countryName: 'Grecia',
    value: '+30',
    label: '+30'
  },
  {
    countryName: 'Groenlandia',
    value: '+299',
    label: '+299'
  },
  {
    countryName: 'Guadalupe',
    value: '+590',
    label: '+590'
  },
  {
    countryName: 'Guam',
    value: '1 671',
    label: '1 671'
  },
  {
    countryName: 'Guatemala',
    value: '+502',
    label: '+502'
  },
  {
    countryName: 'Guinea',
    value: '+224',
    label: '+224'
  },
  {
    countryName: 'Guinea Ecuatorial',
    value: '+240',
    label: '+240'
  },
  {
    countryName: 'Guinea-Bissáu',
    value: '+245',
    label: '+245'
  },
  {
    countryName: 'Guyana',
    value: '+592',
    label: '+592'
  },
  {
    countryName: 'Holanda',
    value: '+31',
    label: '+31'
  },
  {
    countryName: 'Honduras',
    value: '+504',
    label: '+504'
  },
  {
    countryName: 'Hong Kong',
    value: '+852',
    label: '+852'
  },
  {
    countryName: 'Hungría',
    value: '+36',
    label: '+36'
  },
  {
    countryName: 'India',
    value: '+91',
    label: '+91'
  },
  {
    countryName: 'Indonesia',
    value: '+62',
    label: '+62'
  },
  {
    countryName: 'Irak',
    value: '+964',
    label: '+964'
  },
  {
    countryName: 'Irán',
    value: '+98',
    label: '+98'
  },
  {
    countryName: 'Irlanda',
    value: '+353',
    label: '+353'
  },
  {
    countryName: 'Isla de Man',
    value: '+44',
    label: '+44'
  },
  {
    countryName: 'Isla Norfolk',
    value: '+672',
    label: '+672'
  },
  {
    countryName: 'Islandia',
    value: '+354',
    label: '+354'
  },
  {
    countryName: 'Islas Caimán',
    value: '+1,345',
    label: '+1,345'
  },
  {
    countryName: 'Islas Cook',
    value: '+682',
    label: '+682'
  },
  {
    countryName: 'Islas Feroe',
    value: '+298',
    label: '+298'
  },
  {
    countryName: 'Islas Malvinas',
    value: '+500',
    label: '+500'
  },
  {
    countryName: 'Islas Marianas del Norte',
    value: '1 670',
    label: '1 670'
  },
  {
    countryName: 'Islas Marshall',
    value: '+692',
    label: '+692'
  },
  {
    countryName: 'Islas Pitcairn',
    value: '+870',
    label: '+870'
  },
  {
    countryName: 'Islas Salomón',
    value: '+677',
    label: '+677'
  },
  {
    countryName: 'Islas Vírgenes Británicas',
    value: '1 284',
    label: '1 284'
  },
  {
    countryName: 'Israel',
    value: '+972',
    label: '+972'
  },
  {
    countryName: 'Italia',
    value: '+39',
    label: '+39'
  },
  // {
  //   countryName: 'Jamaica',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Japón',
    value: '+81',
    label: '+81'
  },
  {
    countryName: 'Jordania',
    value: '+962',
    label: '+962'
  },
  {
    countryName: 'Kazajistán',
    value: '+7',
    label: '+7'
  },
  {
    countryName: 'Kenia',
    value: '+254',
    label: '+254'
  },
  {
    countryName: 'Kirgizistán',
    value: '+996',
    label: '+996'
  },
  {
    countryName: 'Kiribati',
    value: '+686',
    label: '+686'
  },
  {
    countryName: 'Kosovo',
    value: '+381',
    label: '+381'
  },
  {
    countryName: 'Kuwait',
    value: '+965',
    label: '+965'
  },
  {
    countryName: 'Laos',
    value: '+856',
    label: '+856'
  },
  {
    countryName: 'Lesoto',
    value: '+266',
    label: '+266'
  },
  {
    countryName: 'Letonia',
    value: '+371',
    label: '+371'
  },
  {
    countryName: 'Líbano',
    value: '+961',
    label: '+961'
  },
  {
    countryName: 'Liberia',
    value: '+231',
    label: '+231'
  },
  {
    countryName: 'Libia',
    value: '+218',
    label: '+218'
  },
  {
    countryName: 'Liechtenstein',
    value: '+423',
    label: '+423'
  },
  {
    countryName: 'Lituania',
    value: '+370',
    label: '+370'
  },
  {
    countryName: 'Luxemburgo',
    value: '+352',
    label: '+352'
  },
  {
    countryName: 'Macao',
    value: '+853',
    label: '+853'
  },
  {
    countryName: 'Macedonia',
    value: '+389',
    label: '+389'
  },
  {
    countryName: 'Madagascar',
    value: '+261',
    label: '+261'
  },
  {
    countryName: 'Malasia',
    value: '+60',
    label: '+60'
  },
  {
    countryName: 'Malaui',
    value: '+265',
    label: '+265'
  },
  {
    countryName: 'Maldivas',
    value: '+960',
    label: '+960'
  },
  {
    countryName: 'Mali',
    value: '+223',
    label: '+223'
  },
  {
    countryName: 'Malta',
    value: '+356',
    label: '+356'
  },
  {
    countryName: 'Marruecos',
    value: '+212',
    label: '+212'
  },
  {
    countryName: 'Mauricio',
    value: '+230',
    label: '+230'
  },
  {
    countryName: 'Mauritania',
    value: '+222',
    label: '+222'
  },
  {
    countryName: 'México',
    value: '+52',
    label: '+52'
  },
  {
    countryName: 'Micronesia',
    value: '+691',
    label: '+691'
  },
  {
    countryName: 'Moldavia',
    value: '+373',
    label: '+373'
  },
  {
    countryName: 'Mónaco',
    value: '+377',
    label: '+377'
  },
  {
    countryName: 'Mongolia',
    value: '+976',
    label: '+976'
  },
  {
    countryName: 'Montenegro',
    value: '+382',
    label: '+382'
  },
  {
    countryName: 'Montserrat',
    value: '1 664',
    label: '1 664'
  },
  {
    countryName: 'Mozambique',
    value: '+258',
    label: '+258'
  },
  {
    countryName: 'Namibia',
    value: '+264',
    label: '+264'
  },
  {
    countryName: 'Nauru',
    value: '+674',
    label: '+674'
  },
  {
    countryName: 'Nepal',
    value: '+977',
    label: '+977'
  },
  {
    countryName: 'Nicaragua',
    value: '+505',
    label: '+505'
  },
  {
    countryName: 'Níger',
    value: '+227',
    label: '+227'
  },
  {
    countryName: 'Nigeria',
    value: '+234',
    label: '+234'
  },
  {
    countryName: 'Niue',
    value: '+683',
    label: '+683'
  },
  {
    countryName: 'Noruega',
    value: '+47',
    label: '+47'
  },
  {
    countryName: 'Nueva Caledonia',
    value: '+687',
    label: '+687'
  },
  {
    countryName: 'Nueva Zelanda',
    value: '+64',
    label: '+64'
  },
  {
    countryName: 'Omán',
    value: '+968',
    label: '+968'
  },
  {
    countryName: 'Pakistán',
    value: '+92',
    label: '+92'
  },
  {
    countryName: 'Palau',
    value: '+680',
    label: '+680'
  },
  {
    countryName: 'Panamá',
    value: '+507',
    label: '+507'
  },
  {
    countryName: 'Papúa Nueva Guinea',
    value: '+675',
    label: '+675'
  },
  {
    countryName: 'Paraguay',
    value: '+595',
    label: '+595'
  },
  {
    countryName: 'Polinesia Francesa',
    value: '+689',
    label: '+689'
  },
  {
    countryName: 'Polonia',
    value: '+48',
    label: '+48'
  },
  {
    countryName: 'Portugal',
    value: '+351',
    label: '+351'
  },
  // {
  //   countryName: 'Puerto Rico',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Qatar',
    value: '+974',
    label: '+974'
  },
  {
    countryName: 'Reino Unido',
    value: '+44',
    label: '+44'
  },
  {
    countryName: 'República Centroafricana',
    value: '+236',
    label: '+236'
  },
  {
    countryName: 'República Checa',
    value: '+420',
    label: '+420'
  },
  {
    countryName: 'República de Sudán del Sur',
    value: '+211',
    label: '+211'
  },
  {
    countryName: 'República del Congo',
    value: '+242',
    label: '+242'
  },
  {
    countryName: 'República Democrática del Congo',
    value: '+243',
    label: '+243'
  },
  // {
  //   countryName: 'República Dominicana',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Reunión',
    value: '+262',
    label: '+262'
  },
  {
    countryName: 'Ruanda',
    value: '+250',
    label: '+250'
  },
  {
    countryName: 'Rumanía',
    value: '+40',
    label: '+40'
  },
  {
    countryName: 'Rusia',
    value: '+7',
    label: '+7'
  },
  {
    countryName: 'Samoa',
    value: '+685',
    label: '+685'
  },
  {
    countryName: 'Samoa Americana',
    value: '1 684',
    label: '1 684'
  },
  {
    countryName: 'San Bartolomé',
    value: '+590',
    label: '+590'
  },
  // {
  //   countryName: 'San Cristóbal y Nevis',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'San Marino',
    value: '+378',
    label: '+378'
  },
  {
    countryName: 'San Martín',
    value: '1 599',
    label: '1 599'
  },
  {
    countryName: 'San Pedro y Miquelón',
    value: '+508',
    label: '+508'
  },
  // {
  //   countryName: 'San Vicente y las Granadinas',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Santa Elena',
    value: '+290',
    label: '+290'
  },
  // {
  //   countryName: 'Santa Lucía',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Santo Tomé y Príncipe',
    value: '+239',
    label: '+239'
  },
  {
    countryName: 'Senegal',
    value: '+221',
    label: '+221'
  },
  {
    countryName: 'Serbia',
    value: '+381',
    label: '+381'
  },
  {
    countryName: 'Seychelles',
    value: '+248',
    label: '+248'
  },
  {
    countryName: 'Sierra Leona',
    value: '+232',
    label: '+232'
  },
  {
    countryName: 'Singapur',
    value: '+65',
    label: '+65'
  },
  {
    countryName: 'Siria',
    value: '+963',
    label: '+963'
  },
  {
    countryName: 'Somalia',
    value: '+252',
    label: '+252'
  },
  {
    countryName: 'Sri Lanka',
    value: '+94',
    label: '+94'
  },
  {
    countryName: 'Sudáfrica',
    value: '+27',
    label: '+27'
  },
  {
    countryName: 'Sudán',
    value: '+249',
    label: '+249'
  },
  {
    countryName: 'Suecia',
    value: '+46',
    label: '+46'
  },
  {
    countryName: 'Suiza',
    value: '+41',
    label: '+41'
  },
  {
    countryName: 'Surinam',
    value: '+597',
    label: '+597'
  },
  {
    countryName: 'Swazilandia',
    value: '+268',
    label: '+268'
  },
  {
    countryName: 'Tailandia',
    value: '+66',
    label: '+66'
  },
  {
    countryName: 'Taiwán',
    value: '+886',
    label: '+886'
  },
  {
    countryName: 'Tanzania',
    value: '+255',
    label: '+255'
  },
  {
    countryName: 'Tayikistán',
    value: '+992',
    label: '+992'
  },
  {
    countryName: 'Timor Oriental',
    value: '+670',
    label: '+670'
  },
  {
    countryName: 'Togo',
    value: '+228',
    label: '+228'
  },
  {
    countryName: 'Tokelau',
    value: '+690',
    label: '+690'
  },
  // {
  //   countryName: 'Trinidad y Tobago',
  //   value: '+1',
  //   label: '+1'
  // },
  {
    countryName: 'Túnez',
    value: '+216',
    label: '+216'
  },
  {
    countryName: 'Turkmenistán',
    value: '+993',
    label: '+993'
  },
  {
    countryName: 'Turquía',
    value: '+90',
    label: '+90'
  },
  {
    countryName: 'Tuvalu',
    value: '+688',
    label: '+688'
  },
  {
    countryName: 'Ucrania',
    value: '+380',
    label: '+380'
  },
  {
    countryName: 'Uganda',
    value: '+256',
    label: '+256'
  },
  {
    countryName: 'Uruguay',
    value: '+598',
    label: '+598'
  },
  {
    countryName: 'Uzbekistán',
    value: '+998',
    label: '+998'
  },
  {
    countryName: 'Vanuatu',
    value: '+678',
    label: '+678'
  },

  {
    countryName: 'Vietnam',
    value: '+84',
    label: '+84'
  },
  {
    countryName: 'Yemen',
    value: '+967',
    label: '+967'
  },
  {
    countryName: 'Zambia',
    value: '+260',
    label: '+260'
  },
  {
    countryName: 'Zimbabue',
    value: '+263',
    label: '+263'
  }
];


export { bankAccountTypes, statusFun, paymentTypes, paymentTypesWithCompensation, countryCodes };
