import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const BookkeeperSimpleShow = ({ item, handleClose }) => (
  <>
    <Modal.Body>
      <div className="info-box">
        <p className="info">
          <span>Nombre:</span> {item.name}
        </p>
        <p className="info">
          <span>Sigla:</span> {item.acronym}
        </p>
        <p className="info">
          <span>Activo:</span> {item.is_active ? 'Sí' : 'No'}
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-info" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </>
);

export default BookkeeperSimpleShow;
