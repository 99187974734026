import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useHistory } from 'react-router-dom';
import { showEmployeeRequest } from '../../requests/employees';
import { useAuthorization } from '../../services/hooks';
import EmployeeTopView from '../../components/Employee/EmployeeShow/EmployeeTopView';
import EmployeeTabs from '../../components/Employee/EmployeeShow/EmployeeTabs';
import basicEmployee from './employee';
import { sendAlert } from '../../actions/utils';
import '../Profile/Dashboard/style.scss';

const EmployeeShow = ({ match }) => {
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState(basicEmployee);
  const { currentEmployee } = useSelector(state => state.auth);
  const history = useHistory();

  const getEmployee = id => {
    if (currentEmployee) {
      showEmployeeRequest(id, {
        dispatch,
        successCallback: response => setEmployee(camelCaseRecursive(response.data)),
        failureCallback: () => {
          history.push('/employees');
          dispatch(sendAlert({ kind: 'error', message: 'Colaborador no encontrado' }));
        }
      });
    }
  };

  const fetch = () => getEmployee(match.params.id);

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetch, [currentEmployee]);

  const canEdit = useAuthorization('update', 'Employee');
  return (
    <>
      <EmployeeTopView employee={employee.employeeCard} returnTo="/employees" editTo="/employees" canEdit={canEdit} />
      <EmployeeTabs employee={employee} getEmployee={getEmployee} currentEmployee={currentEmployee} />
    </>
  );
};

export default EmployeeShow;
