const basicContractVersion = {
  activeContract: true,
  versionNumber: '',
  employee: {
    email: '',
    fullName: '',
    jobTitle: ''
  },
  oldContract: {
    additionalHolidays: '',
    administrativeDays: '',
    bankAccount: '',
    bankAccountType: '',
    bankId: '',
    branchOfficeId: '',
    checkAssistance: false,
    contractType: '',
    costCenterId: '',
    disability: false,
    disabilityCertificate: {},
    disabilityFileInfo: {},
    disabilityNationalIdentification: '',
    documentTemplateId: '',
    employerSalary: false,
    endDate: '',
    fileInfo: {},
    friday: false,
    gratificationType: '',
    jacketSize: '',
    jobManagementId: '',
    jobTitleId: '',
    law19010: false,
    law19966: false,
    markingType: '',
    monday: false,
    pantsSize: '',
    parentId: '',
    payFirstLicenseDays: false,
    paymentSchedule: '',
    paymentType: '',
    presenceCheckAssistance: false,
    progressiveFileInfo: {},
    progressiveVacationsCertificate: {},
    recognizedMonths: '',
    recognizedMonthsFrom: '',
    salary: '',
    salaryWithLicense: false,
    saturday: false,
    senceFranchise: '',
    shiftIds: [],
    shifts: [],
    shirtSize: '',
    shoesSize: '',
    startDate: '',
    sunday: false,
    syndicateId: '',
    tShirtSize: '',
    thursday: false,
    tuesday: false,
    wednesday: false,
    weeklyHours: '',
    workEndTime: '',
    workSchedule: '',
    workStartTime: '',
    contractBalancesAttributes: [],
    employeePrevisionAttributes: []
  }
};

export default basicContractVersion;
