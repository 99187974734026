import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { ButtonTooltip, Icon } from '../../components';

const TerminatedContractsTable = ({ contracts, employeeId }) => {
  const history = useHistory();
  const showContract = contract => {
    history.push(`/contracts/${contract.id}?employee=${employeeId}`);
  };

  return (
    <div className="default-scroll-box negative-margin">
      {contracts.map((body, index) => (
        <Row className="version-index" key={`version-index-${index.toString()}`}>
          <Col xs={10} md={11}>
            <Row>
              <Col xs={1} className="text-center">
                <span className="dot dot-info" />
              </Col>
              <Col xs={9} md={6} className="pl-0">
                <p className="text-uppercase">Contrato {body.correlative_identification}</p>
              </Col>
              <Col />
              <Col xs={12} md={3}>
                <p>
                  {body.start_date} - {body.termination_date}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={2} md={1}>
            <ButtonTooltip
              onClick={() => showContract(body)}
              variant="circle-info"
              className="btn-circle"
              size="sm"
              text="Ver"
            >
              <Icon icon="eye" />
            </ButtonTooltip>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default TerminatedContractsTable;
