import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const updateSelectionProcessCandidateRequest = (selectionProcessId, selectionProcessCandidateId, { dispatch, params, formData, callback, successCallback}) =>
  ApiService.request('put', `/selection_processes/${selectionProcessId}/selection_process_candidates/${selectionProcessCandidateId}/`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const debounceUpdateSelectionProcessCandidateRequest = AwesomeDebouncePromise(updateSelectionProcessCandidateRequest, 300);
