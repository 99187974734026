import React, { Component } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

import {
  indexCertificationsRequest,
  deleteCertificationRequest,
  importCertificationRequest,
  preImportCertificationRequest,
  exportCertificationsRequest,
  importTemplateCertificationRequest
} from '../../requests/certifications';
import { sendAlert } from '../../actions/utils';
import {
  ComponentDataTable,
  DefaultModal,
  BasicActionBtns,
  SimpleCenteredModal,
  ImportActions,
  ImportModal
} from '../../components';
import { downloadFile } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';

const columns = memoize(clickHandler => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="Certification" clickHandler={clickHandler} withShow={false} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

class CertificationDataTable extends Component {
  state = {
    onRequest: true,
    certifications: [],
    amount: 0,
    modalShow: false,
    modalItem: {},
    modalBody: '',
    simpleModalShow: false,
    query: {},
    modalTitle: '',
    modalAction: () => null
  };

  handleRequest = async params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true,
      query: params
    });
    indexCertificationsRequest({
      dispatch,
      params,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const {
      data: certifications,
      metadata: { amount }
    } = response.data;
    this.setState({
      certifications,
      amount,
      onRequest: false
    });
  };

  removeCertification = certificationId => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    deleteCertificationRequest(certificationId, {
      dispatch,
      successCallback: this.handleSuccessRemove('Certificacion Eliminado Correctamente'),
      failureCallback: this.handleFailureRequest
    });
  };

  handleFailureRequest = error => {
    const { dispatch } = this.props;
    const { response } = error;
    this.handleModalClose();
    this.setState({ onRequest: false });
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  handleButtonClick = (item, action) => {
    const { editCertification } = this.props;
    const { context } = this;
    switch (action) {
      case 'edit':
        if (context.can('update', 'Certification')) editCertification(item);
        break;
      case 'destroy':
        this.setState({
          modalShow: true,
          modalItem: item,
          modalTitle: 'Eliminar Certificación',
          modalAction: this.handleModalConfirm,
          modalBody: '¿Estás seguro que deseas eliminar esta certificación?'
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleImportModalExceptions = error => {
    const { dispatch } = this.props;
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      this.setState({
        modalTitle: 'Información Relevante',
        modalShow: true,
        modalBody: alertInfo,
        modalAction: this.handleModalClose()
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  handleMassAction = action => {
    switch (action) {
      case 'import':
        this.setState({ simpleModalShow: true });
        break;
      case 'export':
        this.setState({
          modalShow: true,
          modalTitle: 'Exportar Certificaciones',
          modalAction: this.handleExportRequest,
          modalBody: '¿Estás seguro que deseas exportar certificaciones?'
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeCertification(modalItem.id);
  };

  handleSuccessRemove = message => {
    const { moreData, setMoreData, dispatch } = this.props;
    dispatch(sendAlert({ kind: 'success', message }));
    this.setState({
      onRequest: false
    });
    this.handleModalClose();
    setMoreData(!moreData);
  };

  handleExportRequest = () => {
    const { query } = this.state;
    const { dispatch } = this.props;
    this.setState({ onRequest: true });
    exportCertificationsRequest({
      dispatch,
      params: query,
      successCallback: response => {
        downloadFile(response);
        this.setState({ onRequest: false, modalShow: false });
      },
      failureCallback: this.handleFailureRequest
    });
  };

  onDropUploaded = document => {
    const { dispatch } = this.props;
    this.setState({ onRequest: true });
    preImportCertificationRequest({
      dispatch,
      params: { document_key: document[0].code },
      successCallback: () => this.setState({ onRequest: false }),
      failureCallback: this.handleFailureRequest
    });
  };

  handleTemplate = () => {
    const { dispatch } = this.props;
    this.setState({ onRequest: true });
    importTemplateCertificationRequest({
      dispatch,
      successCallback: response => {
        downloadFile(response);
        this.setState({ onRequest: false });
      },
      failureCallback: this.handleFailureRequest
    });
  };

  handleImport = document => {
    const { dispatch } = this.props;
    this.setState({ onRequest: true });
    importCertificationRequest({
      dispatch,
      params: { document_key: document[0].code },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Archivo importado con éxito' }));
        this.setState({ simpleModalShow: false });
        this.handleSuccessRemove();
      },
      failureCallback: this.handleFailureRequest
    });
  };

  render() {
    const {
      certifications,
      amount,
      onRequest,
      modalShow,
      modalBody,
      simpleModalShow,
      modalTitle,
      modalAction
    } = this.state;
    const { moreData, setMoreData } = this.props;
    const { context } = this;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          data={certifications}
          totalRows={amount}
          moreData={moreData}
          withMassActions
          massActions={<ImportActions model="Certification" handleClick={this.handleMassAction} />}
          resourceRequest={this.handleRequest}
          onRowClicked={item => this.handleButtonClick(item, 'edit')}
          pointerOnHover={context.can('update', 'Certification')}
        />
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={modalAction}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
          disabled={onRequest}
        />
        <SimpleCenteredModal
          title="Importar Certificaciones"
          body={
            <ImportModal
              onDropUploaded={preImportCertificationRequest}
              handleTemplate={importTemplateCertificationRequest}
              hideModal={() => this.setState({ simpleModalShow: false })}
              onHide={importCertificationRequest}
              updateData={() => setMoreData(!moreData)}
              handleExceptions={this.handleImportModalExceptions}
            />
          }
          show={simpleModalShow}
          onHide={() => this.setState({ simpleModalShow: false })}
        />
      </>
    );
  }
}

CertificationDataTable.contextType = AbilityContext;

export default connect()(CertificationDataTable);
