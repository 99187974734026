import { Field, getIn, withFormik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { FormikRangePicker } from '../../../components';

const AttendanceFilterForm = props => {
  const { errors, touched, values, setFieldValue, setFieldTouched, setParams } = props;

  return (
    <Form>
      <p>Debe seleccionar un rango de fechas</p>
      <Field name="range_date">
        {({ field }) => (
          <FormikRangePicker
            {...field}
            abbr
            startDateName="date_from"
            endDateName="date_to"
            startDate={values.date_from}
            endDate={values.date_to}
            showClearDates
            onDatesChange={({ startDate: startDateV, endDate: endDateV }) => {
              const startValue = moment.isMoment(startDateV) ? startDateV.format('DD/MM/YYYY') : '';
              const endValue = moment.isMoment(endDateV) ? endDateV.format('DD/MM/YYYY') : '';
              setFieldValue('date_from', startValue);
              setFieldValue('date_to', endValue);
              setFieldTouched(field.name);
              setParams({
                date_from: startValue,
                date_to: endValue
              });
              setFieldValue(field.name, [startValue, endValue]); // Important to be the last one
            }}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    date_from: '',
    date_to: ''
  };
};

const validationSchema = Yup.object().shape({
  range_date: Yup.array().rangedate(true)
});

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  enableReinitialize: true
})(AttendanceFilterForm);
