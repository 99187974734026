import React from 'react';
import { Switch } from 'react-router-dom';
import { LoggedLayout, NonLoggedLayout, PublicLayout, LandingLayout, LoggedSimpleLayout } from './layouts';
import {
  AbsenceIndex,
  AbsencesNew,
  AdvancePaymentIndex,
  AdvanceSettings,
  AnnouncementIndex,
  AnnouncementProfileIndex,
  AnnouncementTasksIndex,
  AssistanceIndex,
  AttendanceIndex,
  AttendanceProfileIndex,
  AttendanceReportIndex,
  AttendanceReportProfileIndex,
  AttendanceHashIndex,
  AttendanceHashProfileIndex,
  BalanceEdit,
  BalanceIndex,
  BalanceNew,
  BalanceShow,
  BenefitIndex,
  BenefitProfileIndex,
  CalendarAssistanceIndex,
  CalendarAssistanceUserIndex,
  CandidateIndex,
  ChangePassword,
  CompanyEdit,
  CompanyLogIndex,
  CompanyNew,
  CompanyRecords,
  CompanySettings,
  CompanyShow,
  CompanyShowProfile,
  Welcome,
  CompanyTaskEdit,
  CompanyTaskIndex,
  CompanyTaskNew,
  CompanyTaskNewProfile,
  Components,
  ContractVersionShow,
  CourseEdit,
  CourseIndex,
  CourseNew,
  CourseProfileIndex,
  CourseProfileShow,
  CourseShow,
  DataLoadIndex,
  DncEdit,
  DncIndex,
  DncNew,
  DncProfileEdit,
  DncProfileIndex,
  DocumentIndex,
  DocumentPreview,
  DocumentPreviewPublic,
  DocumentTemplateEdit,
  DocumentTemplateIndex,
  DocumentTemplateNew,
  DocumentTemplateShow,
  EmployeeEdit,
  EmployeeIndex,
  EmployeeNew,
  EmployeeShow,
  EmployeeSurveyEdit,
  EmployeeSurveyIndex,
  EmployeeSurveyShow,
  EnrollmentEdit,
  GroupEdit,
  GroupIndex,
  GroupNew,
  GroupingIndex,
  GroupingIndexProfile,
  HRDashboard,
  HomeContact,
  HomeHow,
  ImportantDatesIndex,
  ImportDocuments,
  ImportLogIndex,
  ImporterIndex,
  JobOffersIndexProfile,
  JobManagementIndex,
  JointCommitteeEdit,
  JointCommitteeNew,
  JointCommitteeShow,
  JointCommitteeShowProfile,
  LegalReports,
  LegalReportsProfile,
  LicenseImedIndex,
  LicenseImedTransact,
  RequestsDashboard,
  Login,
  NotificationPeriodIndex,
  OffdaysReportIndex,
  OffdaysReportProfileIndex,
  OrganizationChartIndex,
  OrganizationChartProfileIndex,
  OvertimeNew,
  OvertimeReportIndex,
  OvertimeReportProfileIndex,
  Page,
  BrandFullScreen,
  PayrollBalanceEdit,
  PayrollBalanceNew,
  PayrollGroupEdit,
  PayrollGroupNew,
  PayrollGroupShow,
  PayrollProcessIndex,
  PayrollProcessShow,
  PayrollSimulator,
  PerformanceEvaluationEdit,
  PerformanceEvaluationIndex,
  PerformanceEvaluationNew,
  PerformanceEvaluationProfileIndex,
  PerformanceEvaluationProfileShow,
  PerformanceEvaluationShow,
  ProfileDashboardIndex,
  ProfileDirectoryNodeIndex,
  ProfileIndex,
  RecoverPassword,
  RemunerationsSettings,
  ReportBank,
  ReportIndex,
  ReportBasicIndex,
  ReportCustomIndex,
  ReportLegal,
  RequestIndex,
  ResetPassword,
  RiskPreventionDocumentIndex,
  RiskPreventionInternalDocumentationIndex,
  RiskPreventionInternalDocumentationNew,
  RiskPreventionProfileIndex,
  RiskPreventionSupportingDocumentationIndex,
  RoleEdit,
  RoleIndex,
  RoleNew,
  RoleShow,
  SelectCurrentCompanyIndex,
  SelectionProcessEdit,
  SelectionProcessIndex,
  SelectionProcessNew,
  SelectionProcessShow,
  SelectionProcessShowPublic,
  SelectionProcessShowProfile,
  SettlementGroupNew,
  SettlementGroupShow,
  SettlementIndex,
  SettlementShow,
  ShiftEdit,
  ShiftIndex,
  ShiftMonth,
  ShiftNew,
  ShiftProfileShow,
  ShiftReportIndex,
  ShiftReportProfileIndex,
  SurveyEdit,
  SurveyIndex,
  SurveyNew,
  SurveyShow,
  SyndicateEdit,
  SyndicateNew,
  SyndicateShow,
  SyndicateShowProfile,
  TerminatedContractShow,
  TicketIndex,
  TicketShow,
  TrainingProgramIndex,
  VacationDashboard,
  WorkingdayReportIndex,
  WorkingdayReportProfileIndex,
  ShiftChangeDashboard,
  ConceptNew,
  ConceptEdit,
  ConceptShow,
  Auth,
  MarkingCorrectionIndex
} from '../screens';

import * as Admin from '../screens/Admin';

const routes = (
  <Switch>
    <LoggedLayout exact module="dt" action="index" resource="AttendanceReport" path="/dt/welcome" component={Welcome} />
    <LoggedLayout exact module="dt" action="read" resource="Attendance" path="/dt/attendances_hash" component={AttendanceHashIndex} />
    <LoggedLayout exact module="dt" action="index" resource="AttendanceReport" path="/dt/legal_reports" component={LegalReports} />
    <LoggedLayout exact module="dt" action="index" resource="OvertimeReport" path="/dt/overtime_report" component={OvertimeReportIndex} />
    <LoggedLayout exact module="dt" action="index" resource="AttendanceReport" path="/dt/attendance_report" component={AttendanceReportIndex} />
    <LoggedLayout exact module="dt" action="index" resource="HolidayReport" path="/dt/offdays_report" component={OffdaysReportIndex} />
    <LoggedLayout exact module="dt" action="index" resource="WorkdayReport" path="/dt/workdays_report" component={WorkingdayReportIndex} />
    <LoggedLayout exact module="dt" action="index" resource="ShiftReport" path="/dt/shift_report" component={ShiftReportIndex} />

    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/companies" component={Admin.CompanyIndex} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/companies/new" component={Admin.CompanyNew} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/companies/:id" component={Admin.CompanyShow} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/companies/:id/edit" component={Admin.CompanyEdit} />

    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/employees" component={Admin.EmployeeIndex} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/employees/new" component={Admin.EmployeeNew} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/employees/:id" component={Admin.EmployeeShow} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/employees/:id/edit" component={Admin.EmployeeEdit} />

    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/roles" component={Admin.RoleIndex} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/roles/new" component={Admin.RoleNew} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/roles/:id" component={Admin.RoleShow} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/roles/:id/edit" component={Admin.RoleEdit} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/tickets/" component={Admin.TicketIndex} />
    <LoggedLayout exact module="admin" action="manage" resource="admin" path="/admin/tickets/:id/" component={Admin.TicketShow} />

    <LoggedLayout exact module="basic" action="index" resource="Absence" path="/absences" component={AbsenceIndex} />
    <LoggedLayout exact module="basic" action="massive_create" resource="Absence" path="/absences/new" component={AbsencesNew} />
    <LoggedLayout exact module="basic" action="read_dashboard" resource="Attendance" path="/assistance" component={AssistanceIndex} />
    <LoggedLayout exact module="basic" action="read" resource="Attendance" path="/attendances" component={AttendanceIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/attendances" component={AttendanceProfileIndex} />
    <LoggedLayout exact module="basic" action="read" resource="Attendance" path="/attendances_hash" component={AttendanceHashIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/attendances_hash" component={AttendanceHashProfileIndex} />



    <LoggedLayout exact module="basic" action="index" resource="Absence" path="/calendar" component={CalendarAssistanceIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/calendar" component={CalendarAssistanceUserIndex} />

    <LoggedLayout exact module="basic" action="read_asset,read_discount" resource="Balance,Balance" path="/balances" component={BalanceIndex} />
    <LoggedLayout exact module="basic" action="create_asset" resource="Balance" path="/balances/assets/new" component={e => <BalanceNew asset {...e} />} />
    <LoggedLayout exact module="basic" action="update_asset" resource="Balance" path="/balances/assets/:id/edit" component={e => <BalanceEdit asset {...e} />} />
    <LoggedLayout exact module="basic" action="read_asset" resource="Balance" path="/balances/assets/:id" component={e => <BalanceShow asset {...e} />} />

    <LoggedLayout exact module="basic" action="create_discount" resource="Balance" path="/balances/discounts/new" component={BalanceNew} />
    <LoggedLayout exact module="basic" action="update_discount" resource="Balance" path="/balances/discounts/:id/edit" component={BalanceEdit} />
    <LoggedLayout exact module="basic" action="read_discount" resource="Balance" path="/balances/discounts/:id" component={BalanceShow} />

    <LoggedLayout exact module="basic" action="read" resource="Benefit" path="/benefits" component={BenefitIndex} />

    <LoggedLayout exact module="basic" action="read" resource="Announcement" path="/announcements" component={AnnouncementIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/importantdates" component={ImportantDatesIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/announcementstasks" component={AnnouncementTasksIndex} />

    <LoggedLayout exact module="basic" action="show" resource="Company" path="/company" component={CompanyShow} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/company" component={CompanyShowProfile} />
    <LoggedLayout exact module="basic" action="create" resource="Company" path="/companies/new" component={CompanyNew} />
    <LoggedLayout exact module="basic" action="show" resource="Company" path="/companies/:id" component={CompanyShow} />
    <LoggedLayout exact module="basic" action="update" resource="Company" path="/companies/:id/edit" component={CompanyEdit} />

    <LoggedLayout exact module="basic" action="index" resource="CompanyLog" path="/company_logs" component={CompanyLogIndex} />

    <LoggedLayout exact module="basic" action="index" resource="CompanyTask" path="/tasks" component={CompanyTaskIndex} />
    <LoggedLayout exact module="basic" action="create" resource="CompanyTask" path="/tasks/new" component={CompanyTaskNew} />
    <LoggedLayout exact module="basic" action="update" resource="CompanyTask" path="/tasks/:id/edit" component={CompanyTaskEdit} />

    <LoggedLayout exact module="profile" action="create" resource="CompanyTask" path="/profile/tasks/new" component={CompanyTaskNewProfile} />

    <LoggedLayout exact module="basic" action="index" resource="Course" path="/courses" component={CourseIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Course" path="/courses/new" component={CourseNew} />
    <LoggedLayout exact module="basic" action="show" resource="Course" path="/courses/:id" component={CourseShow} />
    <LoggedLayout exact module="basic" action="update" resource="Course" path="/courses/:id/edit" component={CourseEdit} />

    <LoggedLayout exact module="basic" action="read" resource="Xdata" path="/get_notifications_period" component={NotificationPeriodIndex} />

    <LoggedLayout exact module="basic" action="read" resource="Survey" path="/surveys" component={SurveyIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Survey" path="/surveys/new" component={SurveyNew} />
    <LoggedLayout exact module="basic" action="read" resource="Survey" path="/surveys/:id" component={SurveyShow} />
    <LoggedLayout exact module="basic" action="update" resource="Survey" path="/surveys/:id/edit" component={SurveyEdit} />
    <LoggedLayout exact module="basic" action="show" resource="EmployeeSurvey" path="/surveys/employee_surveys/:id" component={EmployeeSurveyShow} />

    <LoggedLayout exact module="profile" action="index" resource="Course" path="/profile/courses" component={CourseProfileIndex} />
    <LoggedLayout exact module="profile" action="show" resource="Course" path="/profile/courses/:id" component={CourseProfileShow} />

    <LoggedLayout exact module="basic" action="create" resource="DataLoad" path="/data_load" component={DataLoadIndex} />

    <LoggedLayout exact module="profile" action="index" resource="EmployeeSurvey" path="/profile/surveys" component={EmployeeSurveyIndex} />
    <LoggedLayout exact module="profile" action="index" resource="EmployeeSurvey" path="/profile/surveys/:id" component={EmployeeSurveyEdit} />

    <LoggedLayout exact module="basic" action="read" resource="Contract" path="/contracts/:id" component={TerminatedContractShow} />

    <LoggedLayout exact module="basic" action="read" resource="ContractVersion" path="/contract_versions/:id" component={ContractVersionShow} />

    <LoggedLayout exact module="basic" action="rrhh" resource="Employee" path="/dashboard" component={HRDashboard} />

    <LoggedLayout exact module="basic" action="massive_create" resource="Overtime" path="/assistance/overtimes/new" component={OvertimeNew} />

    <LoggedLayout exact module="basic" action="index" resource="Profile" path="/roles" component={RoleIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Profile" path="/roles/new" component={RoleNew} />
    <LoggedLayout exact module="basic" action="update" resource="Profile" path="/roles/:id/edit" component={RoleEdit} />
    <LoggedLayout exact module="basic" action="show" resource="Profile" path="/roles/:id" component={RoleShow} />

    <LoggedLayout exact module="basic" action="read" resource="Shift" path="/shifts" component={ShiftIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Shift" path="/shifts/new" component={ShiftNew} />
    <LoggedLayout exact module="basic" action="create" resource="Shift" path="/shifts/monthly" component={ShiftMonth} />
    <LoggedLayout exact module="basic" action="update" resource="Shift" path="/shifts/:id/edit" component={ShiftEdit} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/shifts" component={ShiftProfileShow} />

    <LoggedLayout exact module="basic" action="read" resource="ImportLog" path="/importers" component={ImporterIndex} />
    <LoggedLayout exact module="basic" action="read" resource="ImportLog" path="/import_documents" component={ImportDocuments} />
    <LoggedLayout exact module="basic" action="read" resource="ImportLog" path="/import_logs" component={ImportLogIndex} />

    <LoggedSimpleLayout exact module="basic" action="show" resource="Document" path="/document_preview/:id" component={DocumentPreview} />

    <LoggedLayout exact module="basic" action="index" resource="Dnc" path="/dnc" component={DncIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Dnc" path="/dnc/new" component={DncNew} />
    <LoggedLayout exact module="basic" action="update" resource="Dnc" path="/dnc/:id/edit" component={DncEdit} />

    <LoggedLayout exact module="basic" action="read" resource="Group" path="/groups" component={GroupIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Group" path="/groups/new" component={GroupNew} />
    <LoggedLayout exact module="basic" action="update" resource="Group" path="/groups/:id/edit" component={GroupEdit} />

    <LoggedLayout exact module="profile" action="index" resource="Dnc" path="/profile/dnc" component={DncProfileIndex} />
    <LoggedLayout exact module="profile" action="update" resource="Dnc" path="/profile/dnc/:id/edit" component={DncProfileEdit} />
    <LoggedLayout exact module="basic" action="read" resource="DocumentTemplate" path="/document_templates" component={DocumentTemplateIndex} />
    <LoggedLayout exact module="basic" action="create" resource="DocumentTemplate" path="/document_templates/new" component={DocumentTemplateNew} />
    <LoggedLayout exact module="basic" action="read" resource="DocumentTemplate" path="/document_templates/:id" component={DocumentTemplateShow} />
    <LoggedLayout exact module="basic" action="update" resource="DocumentTemplate" path="/document_templates/:id/edit" component={DocumentTemplateEdit} />

    <LoggedLayout exact module="basic" action="update" resource="Employee" path="/employees/:id/edit" component={EmployeeEdit} />
    <LoggedLayout exact module="basic" action="index" resource="Employee" path="/employees" component={EmployeeIndex} />
    <LoggedLayout exact module="basic" action="create" resource="Employee" path="/employees/new" component={EmployeeNew} />
    <LoggedLayout exact module="basic" action="show" resource="Employee" path="/employees/:id" component={EmployeeShow} />

    <LoggedLayout exact module="basic" action="read" resource="JobManagement" path="/job_managements" component={JobManagementIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/job_offers" component={JobOffersIndexProfile} />

    <LoggedLayout exact module="basic" action="manage" resource="basic" path="/groupings" component={GroupingIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/groupings" component={GroupingIndexProfile} />

    <LoggedLayout exact module="basic" action="update" resource="PerformanceEvaluation" path="/performance_evaluations/:id/edit" component={PerformanceEvaluationEdit} />
    <LoggedLayout exact module="basic" action="index" resource="PerformanceEvaluation" path="/performance_evaluations" component={PerformanceEvaluationIndex} />
    <LoggedLayout exact module="basic" action="create" resource="PerformanceEvaluation" path="/performance_evaluations/new" component={PerformanceEvaluationNew} />
    <LoggedLayout exact module="basic" action="show" resource="PerformanceEvaluation" path="/performance_evaluations/:id" component={PerformanceEvaluationShow} />

    <LoggedLayout exact module="basic" action="create" resource="JointCommittee" path="/joint_committees/new" component={JointCommitteeNew} />
    <LoggedLayout exact module="basic" action="read" resource="JointCommittee" path="/joint_committees/:id" component={JointCommitteeShow} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/joint_committees/:id" component={JointCommitteeShowProfile} />
    <LoggedLayout exact module="basic" action="update" resource="JointCommittee" path="/joint_committees/:id/edit" component={JointCommitteeEdit} />

    <LoggedLayout exact module="basic" action="create" resource="Syndicate" path="/syndicates/new" component={SyndicateNew} />
    <LoggedLayout exact module="basic" action="read" resource="Syndicate" path="/syndicates/:id" component={SyndicateShow} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/syndicates/:id" component={SyndicateShowProfile} />
    <LoggedLayout exact module="basic" action="update" resource="Syndicate" path="/syndicates/:id/edit" component={SyndicateEdit} />

    <LoggedLayout exact module="basic" action="read" resource="Report" path="/reports" component={ReportIndex} />
    <LoggedLayout exact module="basic" action="read" resource="Report" path="/basic" component={ReportBasicIndex} />
    <LoggedLayout exact module="basic" action="read" resource="Report" path="/custom" component={ReportCustomIndex} />
    <LoggedLayout exact module="basic" action="index" resource="RiskPreventionDocument" path="/risk_prevention" component={RiskPreventionDocumentIndex} />
    <LoggedLayout exact module="basic" action="index" resource="RiskPreventionDocument" path="/supporting_documentation" component={RiskPreventionSupportingDocumentationIndex} />
    <LoggedLayout exact module="basic" action="index" resource="RiskPreventionDocument" path="/internal_documentation" component={RiskPreventionInternalDocumentationIndex} />
    <LoggedLayout exact module="basic" action="index" resource="RiskPreventionDocument" path="/internal_documentation/new" component={RiskPreventionInternalDocumentationNew} />
    <LoggedLayout exact module="profile" action="index" resource="RiskPreventionDocument" path="/profile/risk_prevention" component={RiskPreventionProfileIndex} />

    <LoggedLayout exact module="basic" action="index" resource="Candidate" path="/candidates" component={CandidateIndex} />

    <LoggedLayout exact module="basic" action="index" resource="SelectionProcess" path="/selection_processes" component={SelectionProcessIndex} />
    <LoggedLayout exact module="basic" action="create" resource="SelectionProcess" path="/selection_processes/new" component={SelectionProcessNew} />
    <LoggedLayout exact module="basic" action="update" resource="SelectionProcess" path="/selection_processes/:id/edit" component={SelectionProcessEdit} />
    <LoggedLayout exact module="basic" action="show" resource="SelectionProcess" path="/selection_processes/:id" component={SelectionProcessShow} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/selection_processes/:id" component={SelectionProcessShowProfile} />

    <LoggedLayout exact module="basic" action="index,index" resource="Vacation,Permission" path="/vacations" component={VacationDashboard} />
    <LoggedLayout exact module="basic" action="index" resource="Document" path="/signature" component={DocumentIndex} />
    <LoggedLayout exact module="basic" action="index" resource="Loan,AdvancePayment,Vacation,Permission,ShiftChangeRequest" path="/requests" component={RequestsDashboard} />
    <LoggedLayout exact module="basic" action="index" resource="ShiftChangeRequest" path="/shift_changes" component={ShiftChangeDashboard} />

    <LoggedLayout exact module="basic" action="index" resource="OvertimeReport" path="/overtime_report" component={OvertimeReportIndex} />
    <LoggedLayout exact module="basic" action="index" resource="AttendanceReport" path="/attendance_report" component={AttendanceReportIndex} />
    <LoggedLayout exact module="basic" action="index" resource="HolidayReport" path="/offdays_report" component={OffdaysReportIndex} />
    <LoggedLayout exact module="basic" action="index" resource="WorkdayReport" path="/workdays_report" component={WorkingdayReportIndex} />
    <LoggedLayout exact module="basic" action="index" resource="ShiftReport" path="/shift_report" component={ShiftReportIndex} />
    <LoggedLayout exact module="basic" action="index" resource="AttendanceReport" path="/legal_reports" component={LegalReports} />
    <LoggedLayout exact module="basic" action="index" resource="AttendanceChangeRequest" path="/marking_correction" component={MarkingCorrectionIndex} />
    <LoggedLayout exact module="profile" action="index" resource="AttendanceReport" path="/profile/legal_reports" component={LegalReportsProfile} />

    <LoggedLayout exact module="profile" action="index" resource="OvertimeReport" path="/profile/overtime_report" component={OvertimeReportProfileIndex} />
    <LoggedLayout exact module="profile" action="index" resource="AttendanceReport" path="/profile/attendance_report" component={AttendanceReportProfileIndex} />
    <LoggedLayout exact module="profile" action="index" resource="HolidayReport" path="/profile/offdays_report" component={OffdaysReportProfileIndex} />
    <LoggedLayout exact module="profile" action="index" resource="WorkdayReport" path="/profile/workdays_report" component={WorkingdayReportProfileIndex} />
    <LoggedLayout exact module="profile" action="index" resource="ShiftReport" path="/profile/shift_report" component={ShiftReportProfileIndex} />

    <LoggedLayout exact module="basic" action="massive_create" resource="PayrollBalance" path="/payroll_processes/:payrollProcessId/payroll_groups/:payrollGroupId/payroll_balances/new" component={PayrollBalanceNew} />
    <LoggedLayout exact module="basic" action="massive_update" resource="PayrollBalance" path="/payroll_processes/:payrollProcessId/payroll_groups/:payrollGroupId/payroll_balances/edit" component={PayrollBalanceEdit} />
    <LoggedLayout exact module="basic" action="create" resource="PayrollGroup" path="/payroll_processes/:payrollProcessId/payroll_groups/new" component={PayrollGroupNew} />
    <LoggedLayout exact module="basic" action="update" resource="PayrollGroup" path="/payroll_processes/:payrollProcessId/payroll_groups/:id/edit" component={PayrollGroupEdit} />
    <LoggedLayout exact module="basic" action="read" resource="PayrollGroup" path="/payroll_processes/:payrollProcessId/payroll_groups/:id" component={PayrollGroupShow} />
    <LoggedLayout exact module="basic" action="salary_stunner" resource="Payroll" path="/simulators" component={PayrollSimulator} />

    <LoggedLayout exact module="basic" action="read" resource="PayrollProcess" path="/payroll_processes" component={PayrollProcessIndex} />
    <LoggedLayout exact module="basic" action="read" resource="PayrollProcess" path="/payroll_processes/:id" component={PayrollProcessShow} />
    <LoggedLayout exact module="basic" action="report" resource="PayrollProcess" path="/payroll_processes/:id/legal_reports" component={ReportLegal} />
    <LoggedLayout exact module="basic" action="report,generate_payment" resource="PayrollProcess,AdvancePayment" path="/bank_documents" component={ReportBank} />

    <LoggedLayout exact module="basic" action="generate_payment" resource="AdvancePayment" path="/advance_payments_process" component={AdvancePaymentIndex} />

    <LoggedLayout exact module="basic" action="read" resource="SettlementGroup" path="/settlements" component={SettlementIndex} />
    <LoggedLayout exact module="basic" action="read" resource="Settlement" path="/settlements/:id" component={SettlementShow} />

    <LoggedLayout exact module="basic" action="create" resource="SettlementGroup" path="/settlement_groups/new" component={SettlementGroupNew} />
    <LoggedLayout exact module="basic" action="read" resource="SettlementGroup" path="/settlement_groups/:id" component={SettlementGroupShow} />

    <LoggedLayout exact module="basic" action="organization_chart" resource="Company" path="/organization_charts" component={OrganizationChartIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/organization_charts" component={OrganizationChartProfileIndex} />
    <LoggedLayout exact module="basic" action="update" resource="AdvanceSetting" path="/advance_settings" component={AdvanceSettings} />
    <LoggedLayout exact module="basic" action="read_configurations" resource="Company" path="/records" component={CompanyRecords} />
    <LoggedLayout exact module="basic" action="read_configurations" resource="Company" path="/settings" component={CompanySettings} />

    <LoggedLayout exact module="basic" action="create_concept" resource="Balance" path="/remunerations_settings" component={RemunerationsSettings} />
    <LoggedLayout exact module="basic" action="create_concept" resource="Balance" path="/remunerations_settings/new" component={e => <ConceptNew  {...e} />} />
    <LoggedLayout exact module="basic" action="update_concept" resource="Balance" path="/remunerations_settings/:id/edit" component={e => <ConceptEdit  {...e} />} />
    <LoggedLayout exact module="basic" action="update_concept" resource="Balance" path="/remunerations_settings/:id/show" component={e => <ConceptShow  {...e} />} />

    <LoggedLayout exact module="basic" action="read" resource="Ticket" path="/tickets" component={TicketIndex} />
    <LoggedLayout exact module="basic" action="read" resource="Ticket" path="/tickets/:id/" component={TicketShow} />

    <LoggedLayout exact module="basic" action="index" resource="EmployeeDnc" path="/training_program" component={TrainingProgramIndex} />

    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/dashboard" component={ProfileDashboardIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile" component={ProfileIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/requests" component={RequestIndex} />
    <LoggedLayout exact module="profile" action="manage" resource="profile" path="/profile/documents" component={ProfileDirectoryNodeIndex} />
    <LoggedLayout exact module="profile" action="read" resource="Announcement" path="/profile/announcements" component={AnnouncementProfileIndex} />
    <LoggedLayout exact module="profile" action="index" resource="PerformanceEvaluation" path="/profile/performance_evaluations" component={PerformanceEvaluationProfileIndex} />
    <LoggedLayout exact module="profile" action="show" resource="PerformanceEvaluation" path="/profile/performance_evaluations/:id" component={PerformanceEvaluationProfileShow} />
    <LoggedSimpleLayout exact module="profile" action="manage" resource="profile" path="/profile/companies" component={SelectCurrentCompanyIndex} />

    <LoggedLayout exact module="profile" action="read" resource="Benefit" path="/profile/benefits" component={BenefitProfileIndex} />

    <LoggedLayout exact module="basic" action="index" resource="Profile" path="/license/imed" component={LicenseImedIndex} />
    <LoggedLayout exact module="basic" action="index" resource="Profile" path="/license/imed/:license/:rut/transact" component={LicenseImedTransact} />

    <LoggedLayout exact path="/change_password" action="manage" resource="profile" component={ChangePassword} />

    <NonLoggedLayout exact path="/login" component={Login} />
    <NonLoggedLayout exact path="/auth/:token" component={Auth} />

    <NonLoggedLayout exact path="/reset_password" component={ResetPassword} />
    <NonLoggedLayout exact path="/recover_password" component={RecoverPassword} />
    <NonLoggedLayout exact path="/:token?" component={BrandFullScreen} />
    <LandingLayout exact path="/como-funciona" component={HomeHow} />

    <PublicLayout exact path="/signed_documents/:id" component={DocumentPreviewPublic} />
    <PublicLayout exact path="/components" component={Components} />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout path="/selection_process/public/:token" component={SelectionProcessShowPublic} />

    <LoggedSimpleLayout path="/documents/enrollments/edit/:token" component={EnrollmentEdit} />
    <LandingLayout path="/:page" component={Page} />
  </Switch>
);

export default routes;
