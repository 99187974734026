import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { debounceIndexCostCentersRequest } from '../../requests/costCenters';
import { FormikInput, InputSelect, FormikCheckBox } from '../../components';

class CostCenterForm extends Component {
  fetchData = (inputValue, callback) => {
    const { dispatch, costCenterId } = this.props;
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        exclude_id: costCenterId,
        paginate: false
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  initialParentValue = () => {
    const { costCenter, values } = this.props;
    const parent = values.costCenter;
    const { dependFrom } = costCenter;
    let defaultValue;
    if (parent.parentId && dependFrom && parent.parentId === dependFrom.id) {
      defaultValue = { label: dependFrom.name, value: dependFrom.id };
    }
    return defaultValue;
  };

  render() {
    const { onHide, submitVariant, errors, touched, action, setFieldValue, isSubmitting } = this.props;
    const btnMessage = action === 'new' ? 'Crear' : 'Editar';
    const defaultValue = this.initialParentValue();

    return (
      <Form>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Field name="costCenter[parentId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Depende de"
                        placeholder="Seleccionar Centro de Costo"
                        value={defaultValue}
                        request={this.fetchData}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        isClearable
                      />
                    )}
                  </Field>
                </Col>
                <Col md={8}>
                  <Field name="costCenter[name]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Nombre"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="costCenter[code]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Código"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12}>
                  <Field name="costCenter[active]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" />}
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
            {btnMessage}
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { id, name, code, active, dependFrom } = props.costCenter;
  return {
    costCenter: {
      id,
      name,
      code,
      active,
      parentId: dependFrom?.id
    }
  };
};

const validationSchema = Yup.object().shape({
  costCenter: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    code: Yup.string()
      .required('Debes ingresar un código')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    active: Yup.boolean(),
    parentId: Yup.string().nullable(true)
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(connect()(CostCenterForm));
