import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import PermissionDataTable from '../Shared/Permission/PermissionDataTable';
import VacationDataTable from '../Shared/Vacation/VacationDataTable';
import Columns from '../Shared/Permission/approveColumns';
import vacationColumns from '../Shared/Vacation/approveColumns';
import { OvertimeDataTable } from '../../components/Overtime';
import { AbilityContext } from '../../config/abilityContext';
import {
  ApproveRejectActions,
  ApprovementStatus,
  BasicActionBtns,
  DashboardCard,
  DatatableEmployeeName,
  DatatableHourMinute
} from '../../components';
import { pendingAssistanceDashboardRequest } from '../../requests/dashboards';
import { useSetTab } from '../../services/hooks';
import { sendAlert } from '../../actions/utils';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'name',
    cell: item => (
      <DatatableEmployeeName
        item={item.employee}
        tooltipText={item.translated_status === 'Pendiente' ? item.translated_status : ''}
        tooltipClass="dot-danger"
        withDot
        onClick={() => clickHandler(item, 'show')}
      />
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'VALOR',
    selector: 'hour_value',
    cell: item => item.translated_hour_value,
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORAS EXTRA',
    selector: 'hours',
    cell: item => <DatatableHourMinute item={item} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    cell: item => (
      <ApproveRejectActions item={item} resource="Overtime" clickHandler={clickHandler} disabled={!item.review_turn} />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <BasicActionBtns resource="Overtime" item={item} clickHandler={clickHandler} withShow={false} />
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const PendingTab = ({ moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const { advanceSettings } = useSelector(state => state.utils);
  const location = useLocation();
  const [dashboardVacations, setDashboardVacations] = useState(0);
  const [dashboardPermissions, setDashboardPermissions] = useState(0);
  const [dashboardOvertimes, setDashboardOvertimes] = useState(0);
  const [key, setKey] = useSetTab('vacation-datatable', location, 'pending_tab');
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const handleSuccessRequest = response => {
    const { vacationsCount, permissionsCount, overtimesCount } = camelCaseRecursive(response.data);

    setDashboardVacations(vacationsCount);
    setDashboardPermissions(permissionsCount);
    setDashboardOvertimes(overtimesCount);
  };

  const dashboardRequest = () => {
    const handleFailureRequest = error => {
      stableDispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    };
    const request = async () => {
      await pendingAssistanceDashboardRequest({
        dispatch: stableDispatch,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest
      });
    };
    request();
  };

  useEffect(dashboardRequest, [moreData]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-uppercase margin-fix">Solicitudes</h4>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} md={4}>
          <DashboardCard
            title="Vacaciones"
            subtitle="Pendientes de aprobación"
            counter={dashboardVacations}
            colorCard="warning"
            colorText="white"
            colorBorder="warning"
            icon="sunny"
            onClick={() => setKey('vacation-datatable')}
          />
        </Col>
        {permissionsTab && (
          <Col xs={12} md={4}>
            <DashboardCard
              title="Permisos"
              subtitle="Pendientes de aprobación"
              counter={dashboardPermissions}
              colorCard="success"
              colorText="white"
              colorBorder="success"
              onClick={() => setKey('permission-datatable')}
            />
          </Col>
        )}
        <Col xs={12} md={4}>
          <DashboardCard
            title="Horas Extra"
            subtitle="Pendientes de aprobación"
            counter={dashboardOvertimes}
            colorCard="primary"
            colorText="white"
            colorBorder="primary"
            onClick={() => setKey('overtimes-datatable')}
            icon="time"
          />
        </Col>
      </Row>
      <Tab.Container id="assistance-datatables.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          {ability.can('index', 'Vacation') && vacationsTab && (
            <Nav.Item>
              <Nav.Link eventKey="vacation-datatable">Vacaciones</Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'Permission') && permissionsTab && (
            <Nav.Item>
              <Nav.Link eventKey="permission-datatable">Permisos</Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'Overtime') && (
            <Nav.Item>
              <Nav.Link eventKey="overtimes-datatable">Horas Extra</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          {ability.can('index', 'Vacation') && vacationsTab && (
            <Tab.Pane eventKey="vacation-datatable">
              <Row>
                <Col>
                  <VacationDataTable
                    moreData={moreData}
                    setMoreData={setMoreData}
                    columns={vacationColumns}
                    customParams={{ status: 'pending' }}
                    preName="pending-vacations"
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
          {ability.can('index', 'Permission') && permissionsTab && (
            <Tab.Pane eventKey="permission-datatable">
              <Row>
                <Col>
                  <PermissionDataTable
                    columns={Columns}
                    moreData={moreData}
                    setMoreData={setMoreData}
                    customParams={{ sort_permissions: 'start_date desc', status: 'pending' }}
                    preName="pending-permissions"
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
          {ability.can('index', 'Overtime') && (
            <Tab.Pane eventKey="overtimes-datatable">
              <Row>
                <Col>
                  <OvertimeDataTable
                    moreData={moreData}
                    setMoreData={setMoreData}
                    columns={columns}
                    customParams={{ status: 'pending' }}
                    preName="pending-overtimes"
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PendingTab;
