import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import organizationChartRequest from '../../../requests/organizationChart';
import { ChartTree, ChartNodeEmployee, Icon } from '../../../components';

const OrganizationChartChart = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [onFetch, setOnFetch] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [downloadAll, setDownloadAll] = useState(false);

  const { company } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const orgchart = useRef({});
  const addNestedElement = (employeesV, id, data) => {
    const employees2 = employeesV.map(item => {
      if (item.id !== id) {
        if (item.children && item.children.length > 0) {
          const newItem = item;
          newItem.children = addNestedElement(item.children, id, data);
          return newItem;
        }
        return item;
      }
      const newItem = item;
      newItem.children = camelCaseRecursive(data);
      return newItem;
    });
    return employees2;
  };

  const tryFocus = () => {
    const userNode = document.getElementsByClassName('isUser');
    if (userNode.length) {
      userNode[0].scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
      return true;
    }
    return false;
  };

  const handleSuccessIndex = (
    response,
    { successType = false, all = false, userFocus = false, first = false } = {}
  ) => {
    const { data } = response.data;
    let employeesResponse = [];
    if (successType) {
      setOnFetch(false);
      employeesResponse = addNestedElement(employees, successType, data);
    } else {
      employeesResponse = camelCaseRecursive(data);
    }
    setEmployees(employeesResponse);
    setOnRequest(false);
    if (all) setDownloadAll(true);
    if (userFocus) tryFocus();
    if (first) {
      const companyNode = document.getElementsByClassName('oc-node');
      if (companyNode.length)
        companyNode[0].scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
    }
  };

  const handleRequest = async (params, config = {}) => {
    organizationChartRequest({
      dispatch,
      params,
      successCallback: response => handleSuccessIndex(response, config)
    });
  };

  const fetchRequestWithId = item => {
    setOnFetch(item.id);
    handleRequest({ tree_data_from_id: item.id, depth: '4', sort_column: 'name' }, { successType: item.id });
  };

  const firstFetch = () => {
    handleRequest({ depth: '5', sort_column: 'name' }, { first: true });
  };

  const exportChart = () => orgchart.current.exportTo('organigrama');
  const exportAll = () => {
    if (downloadAll) {
      exportChart();
      setDownloadAll(false);
    }
  };

  const handleRequestAll = () => {
    orgchart.current.setExporting(true);
    orgchart.current.expandAllNodes();
    handleRequest({ depth: '99999', sort_column: 'name' }, { all: true });
  };

  const resetChart = () => {
    orgchart.current.expandAllNodes();
  };

  const findMe = () => {
    resetChart();
    const found = tryFocus();
    if (!found) {
      setOnRequest(true);
      handleRequest({ depth: '99999', sort_column: 'name' }, { userFocus: true });
    }
  };

  const zoomIn = () => orgchart.current.zoomIn();
  const zoomOut = () => orgchart.current.zoomOut();

  useEffect(firstFetch, []);
  useEffect(exportAll, [downloadAll]);

  const chart = { ...company, children: employees };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <h2 className="mb-3 m-top mt-3">Organigrama</h2>
            </Col>
            <Row className="mr-3 mt-3 mb-3">
              <Button variant="info" className="text-uppercase" onClick={exportChart}>
                Descargar
              </Button>
              <Button variant="info" className="text-uppercase ml-2" onClick={handleRequestAll}>
                Descargar todo
              </Button>
              <Button variant="info" className="text-uppercase ml-2" onClick={findMe}>
                Encuéntrame
              </Button>
            </Row>
          </Row>
        </Col>
      </Row>
      <>
        <div className="chart-tools-container">
          <Icon color="white" className="zoom-button" icon="plus" onClick={zoomIn} />
          <Icon color="white" className="zoom-button" icon="minus" onClick={zoomOut} />
        </div>
        <div className="chart-tools-container reset-tool">
          <Icon color="white" className="zoom-button" icon="enlarge" onClick={resetChart} />
        </div>
        <ChartTree
          loading={onRequest}
          loadingComponent={<Spinner animation="border" variant="primary" />}
          datasource={chart}
          parentRef={orgchart}
          node={e => <ChartNodeEmployee fetchRequest={fetchRequestWithId} onFetch={onFetch} {...e} />}
        />
      </>
    </>
  );
};

export default OrganizationChartChart;
