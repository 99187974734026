import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import FormStep1 from './FormStep1';
import FormStep2 from './FormStep2';
import '../../services/yupCustomMethods';

const PerformanceEvaluationForm = ({ performanceEvaluation, values, step, setStep }) => {
  const { evaluationStepsAttributes } = values.performanceEvaluation;

  const withObjective = () => evaluationStepsAttributes.find(item => item.stepType === 'objective')?.active;

  return (
    <Form>
      {step === 1 ? (
        <FormStep1 performanceEvaluation={performanceEvaluation} setStep={setStep} />
      ) : (
        <FormStep2 objective={withObjective()} setStep={setStep} />
      )}
    </Form>
  );
};

const setInitialValues = props => {
  const { performanceEvaluation } = props;
  return { performanceEvaluation, rangeDate: [performanceEvaluation.startDate, performanceEvaluation.endDate] };
};

const validationSchema = Yup.object().shape({
  performanceEvaluation: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    startDate: Yup.date().formatdate(),
    endDate: Yup.date().formatdate(),
    evaluatorEmployeeIds: Yup.array().min(1, 'Debes escoger al menos un evaluador.'),
    description: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos'),
    startText: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos'),
    endText: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos'),
    reminderDays: Yup.number(),
    skillsAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debes ingresar un nombre'),
        competenceType: Yup.string(),
        requireAmount: Yup.number()
          .positive('Debe ser mayor a 0')
          .required('Debes ingresar un cumplimiento requerido'),
        description: Yup.string(),
        skillType: Yup.string(),
        requireAmountType: Yup.string(),
        endDate: Yup.date().formatdate()
      })
    ),
    evaluationStepsAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debes ingresar un nombre de etapa'),
        stepType: Yup.string(),
        active: Yup.boolean()
      })
    )
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PerformanceEvaluationForm);
