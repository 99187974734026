export default {
  name: '',
  code: '',
  address: '',
  addressFloor: '',
  addressOffice: '',
  communeId: '',
  regionId: '',
  phoneCountryCode: '+56',
  phone: '',
  email: '',
  active: true
};
