const daysOfWeek = [
  { label: 'Lunes', value: 'monday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' },
  { label: 'Martes', value: 'tuesday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' },
  { label: 'Miércoles', value: 'wednesday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' },
  { label: 'Jueves', value: 'thursday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' },
  { label: 'Viernes', value: 'friday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' },
  { label: 'Sábado', value: 'saturday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' },
  { label: 'Domingo', value: 'sunday', send: '', startTime: '09:00', endTime: '18:00', weekDay: '' }
];

export default daysOfWeek;
