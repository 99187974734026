import ApiService from '../services/apiService';

export const workdaysBetweenHolidayRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('get', '/holidays/workdays_between', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const reincorporationDateHolidayRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('get', '/holidays/reincorporation_date', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
