import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { useSetTab, useAuthorization } from '../../services/hooks';
import { sendAlert } from '../../actions/utils';
import AbsenceDataTable from '../Absence/AbsenceDataTable';
import AttendanceDataTable from '../../components/Attendance/AttendanceDataTable';
import DailyAttendanceDataTable from '../../components/Attendance/DailyAttendanceDataTable';
import PermissionDataTable from '../Shared/Permission/PermissionDataTable';
import VacationDataTable from '../Shared/Vacation/VacationDataTable';
import AttendanceChangeRequestDataTable from '../Shared/AttendanceChangeRequest/AttendanceChangeRequestDataTable';

import absenceColumns from '../Absence/Columns';
import attendanceColumns from '../../components/Attendance/Columns';
import dailyColumns from '../../components/Attendance/DailyColumns';
import permissionColumns from '../Shared/Permission/watchColumns';
import vacationColumns from '../Shared/Vacation/watchColumns';
import { DashboardCard } from '../../components';
import { assistanceDashboardRequest } from '../../requests/dashboards';
import attendanceChangeColumns from '../Shared/AttendanceChangeRequest/Columns';

const AssistanceTab = ({ moreData, setMoreData, location }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const [key, setKey] = useSetTab('vacation-datatable', location, 'assistance-tab');
  const [dashboardVacations, setDashboardVacations] = useState(0);
  const [dashboardPermissions, setDashboardPermissions] = useState(0);
  const [dashboardJustifiedLicenses, setDashboardJustifiedLicenses] = useState(0);
  const [dashboardAttendances, setDashboardAttendances] = useState(0);
  const [dashboardAttendanceChanges, setDashboardAttendanceChanges] = useState(0);

  const [dashboardUnjustifiedLicenses, setDashboardUnjustifiedLicenses] = useState(0);
  const [onlyUnjustifiedAbsences, setOnlyUnjustifiedAbsences] = useState(undefined);
  const [todayMoment, setTodayMoment] = useState('');
  const dispatch = useDispatch();
  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const numCards = vacationsTab && permissionsTab ? 5 : 4;

  const handleSuccessRequest = response => {
    const {
      todayJustifiedLicensesCount,
      todayPermissionsCount,
      todayAttendancesCount,
      todayUnjustifiedLicensesCount,
      todayVacationsExceptCount,
      todayAttendanceChangeRequests
    } = camelCaseRecursive(response.data);

    setDashboardVacations(todayVacationsExceptCount);
    setDashboardPermissions(todayPermissionsCount);
    setDashboardJustifiedLicenses(todayJustifiedLicensesCount);
    setDashboardUnjustifiedLicenses(todayUnjustifiedLicensesCount);
    setDashboardAttendances(todayAttendancesCount);
    setDashboardAttendanceChanges(todayAttendanceChangeRequests);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchDashboardAssistance = () => {
    const request = async () => {
      await assistanceDashboardRequest({
        dispatch,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest
      });
    };
    request();
  };

  const handleCardSelected = (keyType, unjustifiedAbsence) => {
    if (keyType === 'absent-datatable') {
      setOnlyUnjustifiedAbsences(unjustifiedAbsence);
    }
    setTodayMoment(moment());
    setKey(keyType);
  };

  useEffect(fetchDashboardAssistance, [moreData]);

  const absenceType = onlyUnjustifiedAbsences
    ? { filter_type: 'unjustified_absence' }
    : { filter_absence_exception: 'unjustified_absence' };
  const absenceFilters = onlyUnjustifiedAbsences === undefined ? '' : absenceType;

  const authChange = useAuthorization('index', 'AttendanceChangeRequest');

  const renderCardAttendanceHchange = () => (
    <Col xs={12} md={3}>
      <DashboardCard
        colorBorder="info"
        colorCard="primary"
        colorText="white"
        onClick={() => handleCardSelected('attendance-changes-datatable')}
        counter={dashboardAttendanceChanges}
        icon="backspace"
        subtitle="Hoy"
        title="Correciones de Marcación Pendientes"
      />
    </Col>
  );
  return (
    <>
      <Row>
        <Col>
          <h4 className="text-uppercase margin-fix">Hoy</h4>
        </Col>
        <Col md={3}>
          <h4 className="text-uppercase margin-fix">Total Presentes Hoy: {dashboardAttendances}</h4>
        </Col>
      </Row>
      <Row className="mb-4">
        {vacationsTab && (
          <Col xs={12} md={3}>
            <DashboardCard
              colorBorder="warning"
              colorCard="warning"
              colorText="white"
              counter={dashboardVacations}
              icon="sunny"
              onClick={() => handleCardSelected('vacation-datatable')}
              subtitle="Hoy"
              title="Vacaciones"
            />
          </Col>
        )}
        {permissionsTab && (
          <Col xs={12} md={3}>
            <DashboardCard
              colorBorder="success"
              colorCard="success"
              colorText="white"
              counter={dashboardPermissions}
              onClick={() => handleCardSelected('permission-datatable')}
              subtitle="Hoy"
              title="Permisos"
            />
          </Col>
        )}
        <Col xs={12} md={3}>
          <DashboardCard
            colorBorder="primary"
            colorCard="primary"
            colorText="white"
            onClick={() => handleCardSelected('absent-datatable', false)}
            counter={dashboardJustifiedLicenses}
            icon="medkit"
            subtitle="Hoy"
            title="Licencias Médicas"
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            colorBorder="info"
            colorCard="info"
            colorText="white"
            onClick={() => handleCardSelected('absent-datatable', true)}
            counter={dashboardUnjustifiedLicenses}
            icon="backspace"
            subtitle="Hoy"
            title="Faltas Injustificadas"
          />
        </Col>
        {numCards === 4 && authChange && renderCardAttendanceHchange()}
      </Row>
      {numCards === 5 && authChange && <Row>{renderCardAttendanceHchange()}</Row>}
      <Tab.Container id="assistance-datatables.tabs" activeKey={key} mountOnEnter onSelect={k => setKey(k)}>
        <Nav variant="pills" className="ab-pills">
          {useAuthorization('index', 'Vacation') && vacationsTab && (
            <Nav.Item>
              <Nav.Link eventKey="vacation-datatable">Vacaciones</Nav.Link>
            </Nav.Item>
          )}
          {useAuthorization('index', 'Permission') && permissionsTab && (
            <>
              <Nav.Item>
                <Nav.Link eventKey="permission-datatable">Permisos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="adm-permission-datatable">Días Administrativos</Nav.Link>
              </Nav.Item>
            </>
          )}
          {useAuthorization('read', 'Attendance') && (
            <Nav.Item>
              <Nav.Link eventKey="attendance-datatable">Asistencia</Nav.Link>
            </Nav.Item>
          )}
          {useAuthorization('read', 'Attendance') && (
            <Nav.Item>
              <Nav.Link eventKey="daily-attendance-datatable">Asistencia del día</Nav.Link>
            </Nav.Item>
          )}
          {useAuthorization('index', 'Absence') && (
            <Nav.Item>
              <Nav.Link eventKey="absent-datatable">Ausencia</Nav.Link>
            </Nav.Item>
          )}

          {useAuthorization('index', 'AttendanceChangeRequest') && (
            <Nav.Item>
              <Nav.Link eventKey="attendance-changes-datatable">Correcciones de Marcación Pendientes </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          {useAuthorization('index', 'Vacation') && (
            <Tab.Pane eventKey="vacation-datatable">
              <Row>
                <Col>
                  <VacationDataTable
                    moreData={moreData}
                    setMoreData={setMoreData}
                    defaultStartDate={todayMoment}
                    defaultEndDate={todayMoment}
                    columns={vacationColumns}
                    customParams={{
                      status: 'approved',
                      filter_vacation_exception:
                        'legal_openning_balance, progressive_openning_balance, additional_days_openning_balance, administrative_days, administrative_days_openning_balance'
                    }}
                    withImport
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
          {useAuthorization('index', 'Permission') && (
            <>
              <Tab.Pane eventKey="permission-datatable">
                <Row>
                  <Col>
                    <PermissionDataTable
                      columns={permissionColumns}
                      moreData={moreData}
                      defaultStartDate={todayMoment}
                      defaultEndDate={todayMoment}
                      setMoreData={setMoreData}
                      customParams={{ status: 'approved' }}
                    />
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="adm-permission-datatable">
                <Row>
                  <Col>
                    <VacationDataTable
                      columns={permissionColumns}
                      customParams={{
                        sort_direction: 'desc',
                        sort_column: 'start_date',
                        status: 'approved',
                        filter_vacation_type: 'administrative_days'
                      }}
                      defaultStartDate={todayMoment}
                      defaultEndDate={todayMoment}
                      moreData={moreData}
                      preName="administrative_days"
                      setMoreData={setMoreData}
                    />
                  </Col>
                </Row>
              </Tab.Pane>
            </>
          )}
          {useAuthorization('read', 'Attendance') && (
            <Tab.Pane eventKey="attendance-datatable">
              <Row>
                <Col>
                  <AttendanceDataTable
                    defaultStartDate={todayMoment}
                    defaultEndDate={todayMoment}
                    columns={attendanceColumns}
                    moreData={moreData}
                    setMoreData={setMoreData}
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
          {useAuthorization('read', 'Attendance') && (
            <Tab.Pane eventKey="daily-attendance-datatable">
              <Row>
                <Col>
                  <DailyAttendanceDataTable columns={dailyColumns} moreData={moreData} setMoreData={setMoreData} />
                </Col>
              </Row>
            </Tab.Pane>
          )}
          {useAuthorization('index', 'Absence') && (
            <Tab.Pane eventKey="absent-datatable">
              <Row>
                <Col>
                  <AbsenceDataTable
                    defaultStartDate={todayMoment}
                    defaultEndDate={todayMoment}
                    columns={absenceColumns}
                    moreData={moreData}
                    setMoreData={setMoreData}
                    reloadData={onlyUnjustifiedAbsences}
                    customParams={absenceFilters}
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
          {useAuthorization('index', 'AttendanceChangeRequest') && (
            <Tab.Pane eventKey="attendance-changes-datatable">
              <Row>
                <Col>
                  <AttendanceChangeRequestDataTable
                    defaultStartDate={todayMoment}
                    defaultEndDate={todayMoment}
                    columns={attendanceChangeColumns}
                    moreData={moreData}
                    setMoreData={setMoreData}
                    // reloadData={onlyUnjustifiedAbsences}
                  />
                </Col>
              </Row>
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default AssistanceTab;
