import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexShiftsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/shifts', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createShiftRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/shifts', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const updateShiftRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/shifts/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteShiftRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/shifts/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const showShiftRequest = (id, { dispatch, params = {}, callback, successCallback }) =>
  ApiService.request('get', `/shifts/${id}`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const preImportShiftRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/shifts/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const employeeShiftRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/shifts/find_employee', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const exportShiftRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/shifts/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const importShiftRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/shifts/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateShiftRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/shifts/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const massiveDestroyShiftsRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/shifts/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexShiftsRequest = AwesomeDebouncePromise(indexShiftsRequest, 300);
