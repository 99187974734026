import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn, MainLogo } from '../../../components';
import './style.scss';

const Welcome = () => {
  return (
    <>
      <Row className="justify-content-center img-welcome">
        <Col className="mr-4 welcome-dt">
          <MainLogo style={{ maxWidth: '70%' }} className="mx-auto d-block" />
          <h1 className="d-flex justify-content-center mt-3 mb-4 text-center">Bienvenido Fiscalizador</h1>
        </Col>

        <Col sm={3} md={2} xl={2} className="mr-4 mt-4">
          <LinkBtn
            variant="primary"
            style={{ position: 'fixed', top: '70px', right: '1%', width: 200 }}
            className="btn-login"
            size="lg"
            block
            to="/dt/legal_reports"
          >
            Fiscalización DT
          </LinkBtn>
        </Col>
      </Row>
    </>
  );
};

export default Welcome;
