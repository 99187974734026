import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';

import { DatatableEmployeeName, FormikNumber } from '../../components';

const PayrollBalanceTable = ({ action, balances, dataNotFound, payrolls, scheduledBalances, startIndex }) => {
  const { setFieldValue } = useFormikContext();

  const handleFieldValues = (balance, denominationAmount, index, payrollBalance, payrollId, payrollIndex) => {
    const id = Object.keys(payrollBalance).length > 0 ? payrollBalance.id : false;
    const fieldValue = `payrolls[${payrollIndex}][payrollBalances][${index}]`;
    if (id) setFieldValue(`${fieldValue}[id]`, id);
    setFieldValue(`${fieldValue}[balanceId]`, balance.id);
    setFieldValue(`${fieldValue}[name]`, balance.name);
    setFieldValue(`${fieldValue}[origin]`, 'prenomine');
    setFieldValue(`${fieldValue}[denominationAmount]`, denominationAmount);
    setFieldValue(`${fieldValue}[payrollId]`, payrollId);
  };

  const findPayrollBalance = (payroll, balance) => {
    const payrollBalanceIndex = payroll.payrollBalancesAttributes.findIndex(pb => pb.balanceId === balance.id);
    return payroll.payrollBalancesAttributes[payrollBalanceIndex] || {};
  };

  const handleScheduledBalances = (balance, index, payroll, payrollBalance, payrollIndex) => {
    scheduledBalances.forEach(scheduledBalance => {
      if (scheduledBalance.employeeId !== payroll.employeeId || scheduledBalance.balanceId !== balance.id) return;
      let amount = scheduledBalance.parsedAmount;
      if (scheduledBalance.installments) amount = scheduledBalance.amount / scheduledBalance.installments;
      const fieldValue = `payrolls[${payrollIndex}][payrollBalances][${index}]`;
      setFieldValue(`${fieldValue}[parsedDenominationAmount]`, amount);
      handleFieldValues(balance, amount, index, payrollBalance, payroll.id, payrollIndex);
    });
  };

  const assignInitialValues = (balance, index, payroll, payrollBalance, payrollIndex) => {
    if (balance.amount && scheduledBalances.length === 0) {
      const fieldValue = `payrolls[${payrollIndex}][payrollBalances][${index}]`;
      setFieldValue(`${fieldValue}[parsedDenominationAmount]`, balance.parsedAmount);
      handleFieldValues(balance, balance.amount, index, payrollBalance, payroll.id, payrollIndex);
    } else {
      handleScheduledBalances(balance, index, payroll, payrollBalance, payrollIndex);
    }
  };

  const handleInitialValues = () => {
    payrolls.forEach((payroll, payrollIndex) => {
      balances.forEach((balance, balanceIndex) => {
        const index = startIndex + balanceIndex;
        const payrollBalance = findPayrollBalance(payroll, balance);
        if (action === 'new') assignInitialValues(balance, index, payroll, payrollBalance, payrollIndex);
        else setFieldValue(`payrolls[${payrollIndex}][payrollBalances][${index}]`, payrollBalance);
      });
    });
  };

  const handleHeadItems = () => {
    const mdCol = 5 - balances.length;
    const xlCol = 8 - balances.length;
    return (
      <tr>
        <th className="align-middle custom-employee-width sticky-column">Nombre del Trabajador</th>
        {balances.map((balance, index) => (
          <th key={`pb-head-${index.toString()}`} className="text-center align-middle custom-balance-width">
            {balance.name}
          </th>
        ))}
        <th className={`empty-col-${mdCol > 0 ? mdCol : 0} empty-col-xl-${xlCol > 0 ? xlCol : 0}`} />
      </tr>
    );
  };

  const handleBodyItems = (payroll, payrollIndex) => {
    return balances.map((balance, balanceIndex) => {
      const index = startIndex + balanceIndex;
      const addon = { pesos: '$', uf: 'UF', utm: 'UTM' };
      const leftAddon = addon[balance.unitOfAccount] || '%';
      const payrollBalance = findPayrollBalance(payroll, balance);
      return (
        <td key={`pb-body-${balanceIndex.toString()}`} className="text-center align-middle">
          <Field name={`payrolls[${payrollIndex}][payrollBalances][${index}][parsedDenominationAmount]`}>
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon={leftAddon}
                decimalScale={leftAddon === '$' ? 0 : 2}
                onValueChange={e =>
                  handleFieldValues(balance, e?.value, index, payrollBalance, payroll.id, payrollIndex)
                }
                fieldName={field.name}
                margin="mb-0"
              />
            )}
          </Field>
        </td>
      );
    });
  };

  useEffect(handleInitialValues, [payrolls, scheduledBalances]);

  return (
    <Table responsive>
      {balances.length === 0 || payrolls.length === 0 ? (
        dataNotFound()
      ) : (
        <>
          <thead className="text-uppercase">{handleHeadItems()}</thead>
          <tbody>
            {payrolls.map((payroll, index) => (
              <tr key={`payroll-balance-${index.toString()}`}>
                <td className="employee-name sticky-column">
                  <DatatableEmployeeName
                    item={payroll.employee}
                    fileName="fileInfo"
                    name="fullName"
                    fileUrl="fileUrl"
                  />
                </td>
                {handleBodyItems(payroll, index)}
                <td />
              </tr>
            ))}
          </tbody>
        </>
      )}
    </Table>
  );
};

export default PayrollBalanceTable;
