import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../config/abilityContext';

import './style.scss';

const approveOrSign = (document, workflowRequest) => {
  if ((workflowRequest && document?.status === 'pending') || workflowRequest?.status === 'pending') {
    const { employeeRequestReviewer } = workflowRequest;
    if (employeeRequestReviewer.status === 'pending') {
      return [employeeRequestReviewer.approval, employeeRequestReviewer.signature, true, false];
    }
    if (employeeRequestReviewer.status === 'waiting_another') {
      return [false, false, false, true];
    }
  }
  return [false, false, false, false];
};

const WorkflowRequestActionBtns = ({ document, file, handleActions, workflowRequest }) => {
  const ability = useAbility(AbilityContext);
  const [approve, sign, reject] = approveOrSign(document, workflowRequest);
  const enrolled = localStorage.getItem('enrolled');
  const canApprove = ability.can('approve_document', 'WorkflowRequest');
  const canReject = ability.can('reject_document', 'WorkflowRequest');
  const canCreate = ability.can('create_document', 'WorkflowRequest');

  return (
    <Col md={5} lg={4} className="workflow-actions">
      {file?.fileUrl && (
        <Button
          variant="info"
          className={`mx-sm-2 ${['approved', 'rejected'].includes(document?.status) ? 'mr-auto' : ''}`}
          href={file?.fileUrl}
          target="_blank"
        >
          Descargar
        </Button>
      )}
      {workflowRequest?.status === 'pending' &&
        (enrolled === 'false' ? (
          <p className="status status-warning text-center mt-1 ml-sm-2 mb-2">
            Necesitas estar enrolado para firmar o aprobar
          </p>
        ) : (
          <>
            {reject && canReject && (
              <Button variant="danger" className="mx-sm-2" onClick={() => handleActions('reject', workflowRequest)}>
                Rechazar
              </Button>
            )}
            {approve && canApprove && (
              <Button variant="primary" className="mx-sm-2" onClick={() => handleActions('approve', workflowRequest)}>
                Aprobar
              </Button>
            )}
            {sign && canApprove && (
              <Button variant="primary" className="mx-sm-2" onClick={() => handleActions('sign', workflowRequest)}>
                Firmar
              </Button>
            )}
          </>
        ))}
      {document?.status === 'not_signed' && !workflowRequest && canCreate && (
        <Button variant="primary" className="mx-sm-2" onClick={() => handleActions('sign_request')}>
          Enviar a Firmar/Aprobar
        </Button>
      )}
    </Col>
  );
};

export default WorkflowRequestActionBtns;
