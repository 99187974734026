import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCompanyTaskRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/company_tasks', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showCompanyTaskRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/company_tasks/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createCompanyTaskRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', '/company_tasks', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const updateCompanyTaskRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/company_tasks/${id}/`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const deleteCompanyTaskRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/company_tasks/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexCompanyTaskRequest = AwesomeDebouncePromise(indexCompanyTaskRequest, 300);
