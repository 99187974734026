import React, { useEffect } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { LinkBtn, Icon } from '../../components';
import { useAuthorization } from '../../services/hooks';
import DocumentTemplateDataTable from './DocumentTemplateDataTable';
import { documentGroups, findDocumentTab } from './documentTemplate';

const DocumentTemplateIndex = ({ location }) => {
  const { certificateTypes, letterTypes, otherDocuments } = documentGroups;
  const anexoTypes = ['contract_annex', 'overtime_agreements'];

  let activeKey = 'work-contract';
  if (location.state) {
    activeKey = findDocumentTab(location.state.activeTab);
  }
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Plantillas</h2>
        </Col>
        <Col md={3}>
          {useAuthorization('create', 'DocumentTemplate') && (
            <LinkBtn variant="primary" block to="/document_templates/new" className="mb-3">
              Nuevo
            </LinkBtn>
          )}
        </Col>
      </Row>
      <Tab.Container id="document-templates-index.tabs" defaultActiveKey={activeKey} mountOnEnter>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="work-contract">
              <Icon icon="document" />
              Contratos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="contract-annex">
              <Icon icon="document-attach" />
              Anexos de Contrato
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="work-settlement">
              <Icon icon="exit" />
              Finiquito
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="letters">
              <Icon icon="mail" />
              Cartas
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="certificate">
              <Icon icon="school" />
              Certificados
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="other-documents">
              <Icon icon="grid" />
              Otros Documentos
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="contract-annex">
            <DocumentTemplateDataTable templateType={anexoTypes} />
          </Tab.Pane>
          <Tab.Pane eventKey="work-contract">
            <DocumentTemplateDataTable templateType="work_contract" />
          </Tab.Pane>
          <Tab.Pane eventKey="certificate">
            <DocumentTemplateDataTable templateType={certificateTypes} />
          </Tab.Pane>
          <Tab.Pane eventKey="work-settlement">
            <DocumentTemplateDataTable templateType="work_settlement" />
          </Tab.Pane>
          <Tab.Pane eventKey="letters">
            <DocumentTemplateDataTable templateType={letterTypes} />
          </Tab.Pane>
          <Tab.Pane eventKey="other-documents">
            <DocumentTemplateDataTable templateType={otherDocuments} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default DocumentTemplateIndex;
