import ApiService from '../services/apiService';

export const showMonthOfProcessActiveRequest = ({ dispatch, formData, successCallback, callback }) => {
  ApiService.request('get', '/month_of_processes/month_of_process_active', {
    dispatch,
    formData,
    successCallback,
    callback
  });
};

export default showMonthOfProcessActiveRequest;
