import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const getMeRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/me`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const companiesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/users/companies`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const selectCompanyRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('put', `/users/select_company`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceCompaniesRequest = AwesomeDebouncePromise(companiesRequest, 300);
