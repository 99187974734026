import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Icon } from '../..';
import LinkBtn from '../../Utils/Button/LinkBtn';
import { showEmployeeCardsRequest } from '../../../requests/vacations';

const EmployeeData = ({ user }) => {
  const dispatch = useDispatch();
  const employee = user.employeeCard;
  const boss = user.directBoss;
  const { fileInfo = {} } = employee;
  const currentEmployee = employee.id;
  const [legalVacationCard, setLegalVacationCard] = useState({});
  const avalaibleVacations = legalVacationCard.vacationsAvailable;

  const handleSuccessShow = response => {
    const { data } = response;
    setLegalVacationCard(camelCaseRecursive(data.legal_vacation_card));
  };

  const fetchEmployeeCards = () => {
    if (currentEmployee) {
      showEmployeeCardsRequest({
        dispatch,
        params: {
          employee_id: currentEmployee
        },
        successCallback: handleSuccessShow
      });
    }
  };
  useEffect(fetchEmployeeCards, [currentEmployee]);

  return (
    <Card className="mb-1">
      <Col className="top-header-green">
        <Col className="card-header-title text-center">MIS DATOS</Col>
      </Col>
      {!currentEmployee ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            <Col sm={3} className="mt-2 mb-3" style={{ height: 115 }}>
              <div className="d-flex align-items-center mt-profile-edit">
                <>
                  <div className="ml-1 ml-md-3 ml-xl-4 avatar employee">
                    <div className={fileInfo.fileUrl ? 'content' : 'empty content'}>
                      {fileInfo.fileUrl ? <Image src={fileInfo.fileUrl} /> : <Icon icon="camera" />}
                    </div>
                    <h3 className="show-mobile mb-1">{fileInfo.fullName}</h3>
                  </div>
                </>
              </div>
            </Col>
            <Col sm={9} className="mt-2 mb-3">
              <Row>
                <Col md={6} className={`${boss ? 'col-sm-5 col-xs-5' : 'col-sm-12'}`}>
                  <p className="text-dark-bold mb-0 font-size-responsive position-top-box employee-edit-top-box">
                    {employee.shortName}
                  </p>
                </Col>
                <Col md={6} className={`${boss ? 'col-sm-7 col-xs-7' : 'col-sm-12'}`}>
                  {boss && (
                    <p className="text-green mb-0 mt-0" title="Jefe a Cargo">
                      <Icon icon="people" color="#F7F7F7" className="rounded-icon green icon-15" />
                      <b>&nbsp;{`${boss.name} ${boss.firstLastName}`}</b>
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {employee.jobTitle && (
                    <p className="text-dark mb-0 font-size-responsive position-top-box employee-edit-top-box">
                      <Icon icon="user-tie" className="icon-15" /> {employee.jobTitle}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={5} md={6} xs={12}>
                  {employee.parsedPhone && (
                    <p className="text-dark mt-1 font-size-responsive mb-0">
                      <Icon icon="call" className="icon-15" /> {employee.parsedPhone}
                    </p>
                  )}
                </Col>
                <Col sm={7} md={6} xs={12}>
                  <p className="text-orange mb-0 mt-0">
                    <Icon icon="calendar" color="#F7F7F7" className="rounded-icon orange icon-15" />
                    &nbsp;<b>Días disponibles: {avalaibleVacations || '0'}</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {employee.email && (
                    <p className="text-dark mb-1 font-size-responsive position-top-box employee-edit-top-box">
                      <Icon icon="mail" className="icon-15" /> {employee.email}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={8}>&nbsp;</Col>
                <Col sm={4}>
                  <LinkBtn variant="light-gray" className="mt-1" block size="sm" to="/profile">
                    Ver más
                  </LinkBtn>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Card>
  );
};

export default EmployeeData;
