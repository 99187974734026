import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { LinkBtn, Icon } from '../../../../components';

const SelectionProcessInfoPublic = ({ selectionProcess, setStep }) => (
  <>
    <Row className="selection-show mt-3">
      <Col md={8} lg={9}>
        <Row className="selection-row">
          <LinkBtn variant="circle-dark" className="btn-circle mt-3 mb-1" to="/profile/courses">
            <Icon icon="chevron-back" />
          </LinkBtn>
          <Col md={9}>
            <h2 className="text-uppercase mt-3 mb-2">{selectionProcess.name}</h2>
            <pre>
              <p className="text-justify">{selectionProcess.description}</p>
            </pre>
          </Col>
        </Row>
        <Row className="selection-row mt-2">
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Cargo:</p>
            <p>{selectionProcess.jobTitle?.label}</p>
          </Col>
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Área:</p>
            <p>{selectionProcess.jobManagement?.label}</p>
          </Col>
        </Row>
        <Row className="selection-row">
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Fecha estimada de inicio:</p>
            <p>{selectionProcess.startDate}</p>
          </Col>
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Vacantes:</p>
            <p>{selectionProcess.vacancies}</p>
          </Col>
        </Row>
        <Row className="selection-row">
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Jornada:</p>
            <p>{selectionProcess.translatedWorkSchedule}</p>
          </Col>
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Experiencia:</p>
            <p>{selectionProcess.translatedExpertiseLevel}</p>
          </Col>
        </Row>
        <Row className="selection-row">
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Funcionalidades del Cargo:</p>
            <pre>
              <p className="text-justify">{selectionProcess.jobDescription}</p>
            </pre>
          </Col>
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Conocimientos deseables:</p>
            <pre>
              <p className="text-justify">{selectionProcess.desiredKnowledge}</p>
            </pre>
          </Col>
        </Row>
        <Row className="selection-row">
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Modalidad:</p>
            <p>{selectionProcess.translatedRemote}</p>
          </Col>
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Estado:</p>
            <p>{selectionProcess.translatedStatus}</p>
          </Col>
        </Row>
        <Row className="selection-row">
          <Col md={6}>
            <p className="text-uppercase font-weight-bold">Tipo:</p>
            <p>{selectionProcess.translatedProcessType}</p>
          </Col>
        </Row>
      </Col>
      <Col md={4} lg={3} className="selection-container">
        <div className="mt-4">
          <p> Publicado desde {selectionProcess.startDate}</p>
        </div>
        <div className="mt-auto d-flex justify-content-center">
          {selectionProcess.status !== 'active' ? (
            <div>
              <p>Lo sentimos, este proceso no está disponible.</p>
              <LinkBtn variant="primary" className="d-flex justify-content-center" to="/">
                Conoce más sobre PeopleWork
              </LinkBtn>
            </div>
          ) : (
            <Button variant="primary" className="mt-3" onClick={() => setStep(1)}>
              Postular a este cargo
            </Button>
          )}
        </div>
      </Col>
    </Row>
  </>
);

export default SelectionProcessInfoPublic;
