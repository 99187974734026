import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tab, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';
import { createLoanRequest } from '../../../requests/loans';
import { approveWorkflowRequestRequest } from '../../../requests/workflowRequests';
import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal } from '../../../components/index';
import { useSetTab } from '../../../services/hooks';
import AdvancePaymentDataTable from '../../Shared/AdvancePayment/AdvancePaymentDataTable';
import LoanDataTable from '../../Shared/Loan/LoanDataTable';
import advancePaymentsColumns from '../../Profile/AdvancePayment/Columns';
import LoanForm from '../../Shared/Loan/LoanForm';
import loanColumns from '../../Profile/Loan/Columns';
import AdvancePaymentNew from '../../Profile/AdvancePayment/AdvancePaymentNew';
import { AbilityContext } from '../../../config/abilityContext';

const ItemsIndex = ({ employeeId }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const { advanceSettings } = useSelector(state => state.utils);
  const advancePaymentsTab = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue === 'true');
  const loanTab = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');
  const reqAmountLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_amount_limit')[0]?.integerValue;

  const defaultKey = () => {
    if (loanTab) return 'requests';
    if (advancePaymentsTab) return 'advance_payments';
    return 'advance_payments';
  };

  const [key, setKey] = useSetTab(defaultKey(), location);
  const updateDefaultKey = () => setKey(defaultKey());

  useEffect(updateDefaultKey, [advanceSettings]);

  const handleModalClose = () => {
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleSuccessAction = message => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const handleCreateRequest = loan => {
    createLoanRequest({
      dispatch,
      params: snakeCaseKeys(loan),
      formData: true,
      successCallback: () => handleSuccessAction('Préstamo creado correctamente')
    });
  };

  const handleCreateLoan = {
    amount: 0,
    bookkeeperId: '',
    installments: 0,
    name: '',
    parsedAmount: '',
    reason: '',
    startDate: '',
    status: '',
    directApprove: true,
    employeeId
  };

  const handleApproveWorkflow = item => {
    approveWorkflowRequestRequest(item.data.workflow_request_id, {
      dispatch,
      successCallback: () => handleSuccessAction('Anticipo asignado con éxito')
    });
  };

  const handleOpenModal = modalType => {
    switch (modalType) {
      case 'advancePayment':
        setModalTitle('Asignar Anticipo');
        setModalBody(
          <AdvancePaymentNew
            employeeId={employeeId}
            updateWorkflowStatus={handleApproveWorkflow}
            onSuccess={handleModalClose}
            directApprove
          />
        );
        setModalShow(true);
        break;
      case 'loan':
        setModalTitle('Nuevo Préstamo');
        setModalBody(
          <LoanForm
            newLoan
            loan={handleCreateLoan}
            formRequest={handleCreateRequest}
            handleModalClose={handleModalClose}
            reqAmountLimit={reqAmountLimit}
          />
        );
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const dropdownItems = [];
  if (ability.can('create', 'AdvancePayment') && advancePaymentsTab) {
    dropdownItems.push({
      key: 'assign-advance-payment',
      title: 'Asignar Anticipo',
      props: {
        onClick: () => handleOpenModal('advancePayment')
      }
    });
  }
  if (ability.can('create', 'Loan') && loanTab) {
    dropdownItems.push({
      key: 'new-loan',
      title: 'Nuevo Préstamo',
      props: {
        onClick: () => handleOpenModal('loan')
      }
    });
  }

  return (
    <Row className="mt-3 mb-3 ml-2 mr-2">
      <Col>
        <Row className="mt-3 mb-4 mr-2">
          {ability.can('create', 'Loan') && (
            <Col md={{ span: 2, offset: 10 }}>
              <Button variant="primary" block onClick={() => handleOpenModal('loan')}>
                Nuevo
              </Button>
            </Col>
          )}
        </Row>
        <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)}>
          <Tab.Content>
            <Tab.Pane eventKey="advance_payments">
              {advancePaymentsTab && (
                <AdvancePaymentDataTable
                  columns={advancePaymentsColumns}
                  moreData={moreData}
                  setMoreData={setMoreData}
                  customParams={{ filter_employee: employeeId }}
                />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="requests">
              {loanTab && (
                <LoanDataTable
                  columns={loanColumns}
                  moreData={moreData}
                  setMoreData={setMoreData}
                  customParams={{ filter_employee: employeeId }}
                />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={handleModalClose} />
    </Row>
  );
};

export default ItemsIndex;
