import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { BasicTextArea } from '../../components';

const WorkflowRequestRejectMasiveForm = props => {
  const { errors, touched, handleModalClose, onHide, isSubmitting } = props;

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Field name="rejectObservation">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    abbr
                    label="Observacion"
                    maxLength={300}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-center" variant="outline-info" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button type="submit" variant="danger" disabled={isSubmitting} onClick={onHide}>
            Rechazar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = () => {
  return {
    rejectObservation: ''
  };
};

const validationSchema = Yup.object().shape({
  rejectObservation: Yup.string()
    .required('No puede estar en blanco')
    .min(5, 'Debe tener minimo 5 caracteres')
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(WorkflowRequestRejectMasiveForm);
