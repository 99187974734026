import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showAdminEmployeeRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/admin/employees/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminEmployeesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/admin/employees`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createAdminEmployeeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/admin/employees', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminEmployeeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/employees/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deactivateAdminEmployeeRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/admin/employees/status_change', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const sendActivationEmailsRequestAdmin = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/admin/employees/send_activation_emails/', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const sendActivationEmailRequestAdmin = (id, { dispatch, params, callback, successCallback }) =>
  ApiService.request('get', `/admin/employees/${id}/send_activation_email`, {
    dispatch,
    params,
    callback,
    successCallback
  });

export const debounceIndexAdminEmployeesRequest = AwesomeDebouncePromise(indexAdminEmployeesRequest, 300);
