import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { showProfileRequest, updateProfileRequest } from '../../../requests/adminProfiles';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import RoleForm from './RoleForm';
import basicProfile from '../../Role/role';

const RoleEdit = ({ match }) => {
  const [profile, setProfile] = useState(basicProfile);
  const dispatch = useDispatch();
  const history = useHistory();
  const profileId = match.params.id;

  const requestRole = () => {
    const request = async () => {
      showProfileRequest(profileId, {
        dispatch,
        successCallback: response => setProfile(camelCaseRecursive(response.data))
      });
    };
    request();
  };

  const handleUpdate = values => {
    updateProfileRequest(profileId, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: `Perfil actualizado con éxito` }));
        history.push(`/admin/roles/${profileId}`);
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(requestRole, []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/admin/roles">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Editar Perfil</h2>
        </Col>
      </Row>
      <RoleForm profile={profile} action="edit" submitVariant="success" formRequest={handleUpdate} />
    </>
  );
};

export default RoleEdit;
