// import React from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';
import { BasicActionBtns, ButtonTooltip, Icon } from '../../components';
import DatatableEmployeeNameXdata from './DatatableEmployeeNameXdata';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE Y RUT',
    selector: 'fun_nom_afi_cr',
    cell: item => <DatatableEmployeeNameXdata item={item} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'FUN',
    selector: 'fun_fol_fun_nn',
    sortable: true,
    grow: '0.5',
    width: '50px'
  },
  {
    name: 'PERIODO',
    selector: 'period',
    cell: item => moment(item.period).format('MM/yyyy'),
    sortable: true,
    grow: '1',
    width: '70px'
  },
  {
    name: 'TIPO DE FUN',
    selector: 'fun_ori_fun_cr_desc',
    cell: item => <span>{item.fun_ori_fun_cr_desc}</span>,
    sortable: true,
    center: true,
    grow: '2'
  },
  {
    name: '%',
    selector: 'fun_mon_por_nn',
    sortable: true,
    center: true,
    grow: '0.5',
    width: '53px'
  },
  {
    name: 'UF',
    selector: 'fun_mon_cot_uf',
    cell: item => item.fun_mon_cot_uf.replace('.', ','),
    sortable: true,
    center: true,
    grow: '0.5',
    width: '53px'
  },
  {
    name: '$',
    selector: 'fun_mon_cot__x0024__x0024_',
    cell: item => (
      <NumberFormat
        value={item.fun_mon_cot__x0024__x0024_}
        displayType="text"
        thousandSeparator
        renderText={(value, props) => <div {...props}>{value.replace(',', '.')}</div>}
      />
    ),
    sortable: true,
    center: true,
    grow: '0.5',
    width: '53px'
  },
  {
    name: 'ISAPRE',
    selector: 'cis_cod_prd_cr_desc',
    cell: item => <span>{item.cis_cod_prd_cr_desc}</span>,
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'ESTADO',
    selector: 'fun_est_not_re_desc',
    sortable: true,
    center: true,
    grow: '0.5'
  },
  {
    name: 'MOTIVO',
    selector: 'fun_mot_rec_cr',
    cell: item => <span>{item.fun_mot_rec_cr}</span>,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'status',
    cell: item => (
      <>
        {item.status === 'active' && <span className="text-success">Activo</span>}
        {item.status === 'unregistred' && <span className="text-danger">Sin Registro</span>}
        {item.status === 'without_contract' && <span className="text-danger">Sin Contrato</span>}
        {item.status === 'finished' && <span className="text-danger">Finiquitado</span>}
        {item.status === 'inactive' && <span className="text-danger">Inactivo</span>}
      </>
    ),
    sortable: true,
    center: true,
    ignoreRowClick: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <BasicActionBtns
          item={item}
          resource="Company"
          clickHandler={clickHandler}
          extraIcon="document"
          extraIconAction="document"
          extraIconColor="circle-primary"
          extraIconText="Ver documento"
          withShow={false}
          withEdit={false}
          withDestroy={false}
        />
        <ButtonTooltip
          variant="circle-warning"
          className="btn-circle"
          onClick={() => clickHandler(item, 'process')}
          disabled={item.fun_not_aut_re === 'ON'}
          text="Procesar Fun"
        >
          <Icon icon="settings-outline" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    grow: '1'
  }
]);

export default columns;
