import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { updateGroupRequest, showGroupRequest } from '../../requests/groups';
import { sendAlert } from '../../actions/utils';
import GroupForm from './GroupForm';
import basicGroup from './group';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const GroupEdit = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const [group, setGroup] = useState(basicGroup);
  const history = useHistory();

  const fetchGroup = () => {
    const request = async () => {
      showGroupRequest(id, {
        dispatch,
        successCallback: response => setGroup(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleUpdateRequest = (groupValues, setSubmitting) => {
    updateGroupRequest(id, {
      dispatch,
      params: snakeCaseKeys(groupValues, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Grupo actualizado con éxito' }));
        history.push(`/groups`);
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(fetchGroup, []);

  return (
    <>
      <Row className="my-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/groups">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">{group.name}</h2>
      </Row>
      <GroupForm group={group} action="edit" formRequest={handleUpdateRequest} />
    </>
  );
};

export default GroupEdit;
