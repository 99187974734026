/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { DatatableEmployeeName } from '../../../components';

const availableVacationColumns = memoize(() => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    cell: item => item.job_title,
    sortable: false,
    grow: '2'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    cell: item => item.job_management,
    sortable: false,
    grow: '2'
  },
  {
    name: 'TIPO DE VACACIÓN',
    selector: 'vacation_type',
    cell: item => item.vacation_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'DÍAS DISPONIBLES',
    selector: 'days',
    cell: item => item.available_days,
    sortable: true,
    grow: '1'
  }
]);
export default availableVacationColumns;
