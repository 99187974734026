import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Tab, Nav } from 'react-bootstrap';

import { Icon, EmployeeInfo, EmployeeTopView } from '../../components';
import { useSetTab, useAuthorization } from '../../services/hooks';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { showEmployeeRequest } from '../../requests/employees';
import { getMeRequest } from '../../requests/auth';
import { validateToken } from '../../actions/auth';

import WorkflowRequestDataTable from '../WorkflowRequest/WorkflowRequestDataTable';
import columns from '../WorkflowRequest/Columns';
import basicEmployee from '../Employee/employee';

const basicUser = {
  avatar: [],
  email: '',
  id: '',
  name: '',
  nationalIdentification: '',
  role: '',
  employeeCard: {
    fileInfo: {}
  }
};

const ProfileIndex = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState(basicEmployee);
  const [user, setUser] = useState(basicUser);
  const [key, setKey] = useSetTab('employee', location);
  const { currentEmployee } = useSelector(state => state.auth);
  const canRecordCard = useAuthorization('record_card', 'Employee');
  const isProfile = location.pathname === '/profile';

  const getEmployee = () => {
    if (currentEmployee && canRecordCard) {
      showEmployeeRequest(currentEmployee, {
        dispatch,
        successCallback: response => setEmployee(camelCaseEmptyStringRecursive(response.data))
      });
    }
  };

  const dispatchGetMe = () => {
    getMeRequest({
      dispatch,
      successCallback: response => {
        if (response.data.logged_in) {
          setUser(camelCaseEmptyStringRecursive(response.data.user_info));
        } else {
          dispatch(validateToken());
        }
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(dispatchGetMe, []);
  useEffect(getEmployee, [currentEmployee, canRecordCard]);

  return (
    <Row className="mb-3">
      <Col>
        <h2 className={`${isProfile ? 'd-none' : 'text-uppercase mt-4'}`}>Bienvenido {user.fullName}</h2>
        <EmployeeTopView employee={user.employeeCard} />
        <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="employee">
                <Icon icon="person" />
                Ficha
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="signature">
                <Icon icon="finger-print" />
                Firma
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="employee">
              {currentEmployee && canRecordCard && <EmployeeInfo employee={employee} />}
            </Tab.Pane>
            <Tab.Pane eventKey="signature">
              {currentEmployee && (
                <WorkflowRequestDataTable customFilter={{ document_employee: currentEmployee }} columns={columns} />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default ProfileIndex;
