import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import snakecaseKeys from 'snakecase-keys';
import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../../components';
import {
  approvedShiftChangeRequest,
  deleteShiftChangeRequest,
  debounceIndexShiftChangeRequest,
  rejectShiftChangeRequest
} from '../../../requests/shiftChange';
import ItemsInfo from './Items/ItemsInfo';
import { AbilityContext } from '../../../config/abilityContext';
import ShiftChageRejectForm from './ShiftChageRejectForm';

const ShiftChangeDataTable = ({
  columns,
  customParams,
  defaultStartDate = null,
  defaultEndDate = null,
  moreData,
  preName,
  setMoreData,
  rrhh,
  setWorkflowIds = () => null,
  withDateSelect
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setWorkflowRequests(data);
    setWorkflowIds(data.map(element => element.id));
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    debounceIndexShiftChangeRequest({
      dispatch,
      params: { ...params, ...customParams },
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de cambio de turno eliminada correctamente' }));
    setMoreData(!moreData);
  };

  const handleSuccessApprove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de cambio de turno aprobada correctamente' }));
    setMoreData(!moreData);
  };

  const handleSuccessReject = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de cambio de turno rechazada correctamente' }));
    setMoreData(!moreData);
  };

  const removeShiftChange = item => {
    setOnRequest(true);
    deleteShiftChangeRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const approvedShiftChange = item => {
    setOnRequest(true);
    approvedShiftChangeRequest(item.id, {
      dispatch,
      successCallback: handleSuccessApprove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const rejectShiftChange = (params, setSubmitting) => {
    setOnRequest(true);
    const { id } = params.shiftChangeRequest;
    rejectShiftChangeRequest(id, {
      dispatch,
      params: snakecaseKeys(params),
      successCallback: handleSuccessReject,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleActions = (item, action) => {
    setModalItem({});
    switch (action) {
      case 'destroy':
        setModalTitle('Eliminar Cambio de Turno');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud de cambio de turno?');
        setModalAction(() => removeShiftChange);
        setModalItem(item);
        break;
      case 'show':
        setModalTitle('Cambio de Turno');
        setModalBody(<ItemsInfo item={item} rrhh={rrhh} />);
        setModalItem(item);
        setCenterModalShow(true);
        break;
      case 'approved':
        setModalTitle('Aprobar Cambio de Turno');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas aprobar la solicitud de cambio de turno?');
        setModalItem(item);
        setModalAction(() => approvedShiftChange);
        break;
      case 'rejected':
        setModalTitle('Rechazar Cambio de Turno');
        setModalBody(<ShiftChageRejectForm shiftChange={item} formRequest={rejectShiftChange} />);
        setModalItem(item);
        setCenterModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'shift':
        return { sort_shifts: name };
      case 'employee':
        return { sort_employees: name };
      case 'employee_replacement':
        return { sort_employee_replacements: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability, rrhh)}
        handleSortCase={sortColumnCase}
        data={workflowRequests}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        withStartDate
        withEndDate
        rangePicker
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        preName={preName || 'shiftChange'}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        withDateSelect={withDateSelect}
      />
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
    </>
  );
};

export default ShiftChangeDataTable;
