import React, { useState, useEffect } from 'react';
import { Tab, Nav, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from 'react-contextmenu';
import { useAbility } from '@casl/react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import Icon from '../Icons';
import BasicDropdown from '../Utils/Dropdown';
import { AbilityContext } from '../../config/abilityContext';
import { ButtonTooltip } from '../Utils/Tooltips';
import { sideScroll } from '../../services/utils';
import getFolderAbility from './ability';
import './style.scss';

const ActionButtons = ({ permitedActions, item, handleButtonClick, parentId }) => {
  if (!permitedActions) {
    return <></>;
  }
  return (
    <>
      {permitedActions.find(e => e === 'show') && (
        <ButtonTooltip
          toolbarVariant="ml-auto"
          variant="circle-info"
          className="btn-circle circle-hide"
          size="sm"
          text="Ver"
          onClick={() => handleButtonClick(item, 'show', parentId)}
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      )}
      {permitedActions.find(e => e === 'show') && (
        <ButtonTooltip
          toolbarVariant={permitedActions.find(e => e === 'show') ? '' : 'ml-auto'}
          variant="circle-secondary"
          className="btn-circle circle-hide"
          size="sm"
          text="Descargar"
          onClick={() => handleButtonClick(item, 'download')}
        >
          <Icon icon="cloud-download" />
        </ButtonTooltip>
      )}
      {permitedActions.find(e => e === 'remove') && (
        <ButtonTooltip
          variant="circle-danger"
          className="btn-circle circle-hide"
          size="sm"
          text="Eliminar"
          onClick={() => handleButtonClick(item, 'remove', parentId)}
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      )}
    </>
  );
};

const tooltipHover = (children, text, direction = 'top') => {
  return (
    <OverlayTrigger
      key={direction}
      placement={direction}
      delay={{ show: 250, hide: 0 }}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

const truncateString = (str, num) => {
  if (str?.length <= num) {
    return <span>{str}</span>;
  }
  return tooltipHover(<span className="mr-n3">{`${str?.slice(0, num)}...`}</span>, str);
};

const FolderItem = ({ folder, parentId, handleButtonClick, ability, canMoveFile, directoryNodes, createSubFolder }) => {
  const permissions = getFolderAbility(camelCaseRecursive(folder), ability);
  const dropDownItems = [];

  if (permissions[5]) {
    dropDownItems.push({
      key: 'download',
      title: 'Descargar plantilla de actualización',
      props: {
        onClick: () => handleButtonClick(folder, 'download-folder', parentId),
        className: 'text-capitalize'
      }
    });
  }
  if (permissions[5]) {
    dropDownItems.push({
      key: 'import',
      title: 'Importar plantilla de actualización',
      props: {
        onClick: () => handleButtonClick(folder, 'import-folder', parentId),
        className: 'text-capitalize'
      }
    });
  }
  if (permissions[1]) {
    dropDownItems.push({
      key: 'export',
      title: 'Exportar Carpeta',
      props: {
        onClick: () => handleButtonClick(folder, 'export-folder', parentId),
        className: 'text-capitalize'
      }
    });
  }

  if (permissions[6] && parentId !== undefined) {
    dropDownItems.push({
      key: 'update',
      title: 'Cambiar nombre',
      props: {
        onClick: () => handleButtonClick(folder, 'change-name', parentId),
        className: 'text-capitalize'
      }
    });
  }

  if (folder.destroyable && permissions[2]) {
    dropDownItems.push({
      key: 'delete',
      title: 'Eliminar carpeta',
      props: {
        onClick: () => handleButtonClick(folder, 'remove-folder', parentId),
        className: 'text-danger text-capitalize'
      }
    });
  }

  const autoScroll = () => {
    const boxes = document.getElementById('box');
    sideScroll(boxes, 'right');
  };

  return (
    <>
      <ContextMenuTrigger id={`folder-${folder.id}`}>
        <Nav.Item className="folder-box">
          <Nav.Link onClick={autoScroll} eventKey={`folder-${folder.id}`}>
            <Icon icon={folder.icon ? folder.icon : 'folder-open'} />
            {truncateString(folder.title, 21)}
          </Nav.Link>
          {dropDownItems.length > 0 && (
            <BasicDropdown
              variant="no-border"
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  <Icon icon="ellipsis-vertical" height="20px" width="20px" />
                </span>
              }
              items={dropDownItems}
            />
          )}
        </Nav.Item>
      </ContextMenuTrigger>
      {canMoveFile && parentId !== undefined && (
        <ContextMenu id={`folder-${folder.id}`}>
          <SubMenu
            className="width-submenu"
            title={
              <>
                <span>Mover Hacia</span>
                <Icon className="float-right mt-1" height={15} icon="chevron-forward" />
              </>
            }
          >
            {directoryNodes.map(directoryNode => createSubFolder(directoryNode, folder.id))}
          </SubMenu>
        </ContextMenu>
      )}
    </>
  );
};

const IterateArray = ({
  canMoveFile,
  childrenArray,
  directoryNodes,
  documentsArray = [],
  handleButtonClick,
  handleMoveDocument,
  handleMoveFolder,
  handleOpenCenterModal,
  maxDepth,
  parentName,
  parentId,
  permitedActions,
  permitedCreations,
  root,
  ability
}) => {
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => setCurrentTab(''), [parentId]);

  const createSubMenus = (directoryNode, documentId) => {
    const { id: directoryNodeId } = directoryNode;

    return (
      <div key={`directory-${directoryNode.id}-document-${documentId}`}>
        {directoryNode.hasChildren ? (
          <SubMenu
            title={
              <>
                <span>{directoryNode.name}</span>
                <Icon className="float-right mt-1" height={15} icon="chevron-forward" />
              </>
            }
            onClick={() => handleMoveDocument(directoryNodeId, documentId)}
          >
            {directoryNode.hasChildren && directoryNode.children.map(children => createSubMenus(children, documentId))}
          </SubMenu>
        ) : (
          <MenuItem onClick={() => handleMoveDocument(directoryNodeId, documentId)}>{directoryNode.name}</MenuItem>
        )}
      </div>
    );
  };

  const createSubFolder = (directoryNode, folderId) => {
    const { id: directoryNodeId } = directoryNode;

    return (
      <div key={`directory-${directoryNode.id}-folder-${folderId}`}>
        {directoryNodeId !== folderId && directoryNode.hasChildren ? (
          <SubMenu
            title={
              <>
                <span>{directoryNode.name}</span>
                <Icon className="float-right mt-1" height={15} icon="chevron-forward" />
              </>
            }
            onClick={() => handleMoveFolder(directoryNodeId, folderId)}
          >
            {directoryNode.hasChildren &&
              directoryNode.children.map(children =>
                children.id !== folderId ? (
                  createSubFolder(children, folderId)
                ) : (
                  <MenuItem key={folderId} disabled>
                    {children.name}
                  </MenuItem>
                )
              )}
          </SubMenu>
        ) : (
          <MenuItem onClick={() => handleMoveFolder(directoryNodeId, folderId)}>{directoryNode.name}</MenuItem>
        )}
      </div>
    );
  };

  const folderContent = () => {
    return (
      <>
        <Nav variant="pills" className="flex-column dropdown-responsive">
          {childrenArray.map((folder, index) => (
            <FolderItem
              folder={folder}
              parentId={parentId}
              handleButtonClick={handleButtonClick}
              ability={ability}
              key={`folder-${folder.id}-${index.toString()}`}
              canMoveFile={canMoveFile}
              directoryNodes={directoryNodes}
              createSubFolder={createSubFolder}
            />
          ))}
          <div className="files">
            {documentsArray.map(document => {
              return tooltipHover(
                <div key={`file-${document.fileInfo.fileSignature}`}>
                  <ContextMenuTrigger id={`contextmenu-document-${document.id}`}>
                    <Nav.Item className="document-box">
                      <span className="text-truncate">
                        <Icon icon="document" className="icon-size" />
                        {document.fileInfo.filename}
                      </span>
                      <ActionButtons
                        permitedActions={permitedActions}
                        item={document}
                        handleButtonClick={handleButtonClick}
                        parentId={parentId}
                      />
                    </Nav.Item>
                  </ContextMenuTrigger>
                  {canMoveFile && (
                    <ContextMenu id={`contextmenu-document-${document.id}`}>
                      <SubMenu
                        className="width-submenu"
                        title={
                          <>
                            <span>Mover Hacia</span>
                            <Icon className="float-right mt-1" height={15} icon="chevron-forward" />
                          </>
                        }
                      >
                        {directoryNodes.map(folder => createSubMenus(folder, document.id))}
                      </SubMenu>
                    </ContextMenu>
                  )}
                </div>,
                document.fileInfo.filename
              );
            })}
          </div>
        </Nav>
        {!root && permitedCreations.find(e => e === 'node' || e === 'file') && (
          <Row>
            <Button
              onClick={() => handleOpenCenterModal(parentId, parentName, 'file')}
              className="mr-2"
              variant="outline-primary"
              size="sm"
            >
              Agregar archivos
            </Button>
            <Button
              onClick={() => handleOpenCenterModal(parentId, parentName, 'folder')}
              variant="outline-primary"
              size="sm"
            >
              Crear Carpeta
            </Button>
          </Row>
        )}
      </>
    );
  };

  const nextFolder = () => {
    const folderId = Number(currentTab.split('-')[1]);
    const folder = childrenArray.find(e => e.id === folderId);
    if (!folder || maxDepth <= 0) return '';
    const permissions = getFolderAbility(folder, ability);
    return (
      <Tab.Content>
        <Tab.Pane eventKey={`folder-${folder.id}`}>
          <IterateArray
            childrenArray={folder.children}
            documentsArray={folder.documents}
            handleOpenCenterModal={handleOpenCenterModal}
            parentName={folder.name}
            parentId={folder.id}
            permitedActions={permissions}
            permitedCreations={permissions}
            handleButtonClick={handleButtonClick}
            handleMoveDocument={handleMoveDocument}
            handleMoveFolder={handleMoveFolder}
            maxDepth={maxDepth - 1}
            directoryNodes={directoryNodes}
            canMoveFile={canMoveFile}
            ability={ability}
          />
        </Tab.Pane>
      </Tab.Content>
    );
  };

  return (
    <Tab.Container
      id={`documents-form-tabs${parentId ? `-${parentId}` : ''}`}
      activeKey={currentTab}
      onSelect={k => setCurrentTab(k)}
      mountOnEnter
    >
      <div id="box" className="grid-content mt-1 mb-4">
        <div className="docs-box mb-3">{folderContent()}</div>
        <div>{nextFolder()}</div>
      </div>
    </Tab.Container>
  );
};

const DocumentsTabContainer = ({
  items,
  handleOpenCenterModal,
  handleButtonClick,
  permitedActions,
  permitedCreations,
  fetchRequestWithId,
  handleMoveDocument,
  handleMoveFolder,
  maxDepth
}) => {
  const ability = useAbility(AbilityContext);
  const canMoveFile = ability.can('update_location', 'Document');
  return (
    <IterateArray
      childrenArray={items}
      handleOpenCenterModal={handleOpenCenterModal}
      handleButtonClick={handleButtonClick}
      handleMoveDocument={handleMoveDocument}
      handleMoveFolder={handleMoveFolder}
      permitedActions={permitedActions}
      permitedCreations={permitedCreations}
      fetchRequestWithId={fetchRequestWithId}
      maxDepth={maxDepth}
      directoryNodes={items}
      root
      canMoveFile={canMoveFile}
      ability={ability}
    />
  );
};

export default DocumentsTabContainer;
