import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize((clickHandler, canDup) => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'PARTICIPANTES',
    selector: 'employee_count',
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Survey"
        clickHandler={clickHandler}
        extraIcon={canDup && 'copy'}
        extraIconAction="copy"
        extraIconColor="circle-primary"
        extraIconText="Duplicar"
      />
    ),
    ignoreRowClick: true,
    grow: '1.5',
    minWidth: '185px',
    right: true
  }
]);

export default columns;
