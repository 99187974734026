import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { createBenefitsRequest } from '../../requests/benefits';
import { sendAlert } from '../../actions/utils';
import benefit from './benefit';
import BenefitForm from './BenefitForm';

const BenefitNew = ({ hideModal, setOnRequest, moreData, setMoreData }) => {
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    hideModal();
    dispatch(sendAlert({ kind: 'success', message: 'Beneficio creado con éxito' }));
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreateRequest = (benefitValues, setSubmitting) => {
    setOnRequest(true);
    const { benefitsImage } = benefitValues.benefit;
    const myParams = snakeCaseKeys(benefitValues);
    myParams.benefit.image = benefitsImage;

    createBenefitsRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: error => {
        setSubmitting(false);
        handleFailureRequest(error);
      },
      callback: () => {
        setOnRequest(false);
        setMoreData(!moreData);
      }
    });
  };

  return (
    <>
      <BenefitForm benefit={benefit} action="new" formRequest={handleCreateRequest} hideModal={hideModal} />
    </>
  );
};

export default BenefitNew;
