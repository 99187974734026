import React from 'react';
import PropTypes from 'prop-types';

const DatatableContractType = ({ item, errorColor }) => {
  return Object.keys(item).length > 0 ? (
    <div className="d-flex flex-column" role="presentation">
      <span className={`${errorColor || 'text-muted'}`} data-tag="allowRowEvents">
        {item.startDate} {item.endDate}
      </span>
      <span data-tag="allowRowEvents">{item.translatedContractType}</span>
    </div>
  ) : (
    <span className={`my-2 ${errorColor}`} data-tag="allowRowEvents">
      Sin contrato
    </span>
  );
};

DatatableContractType.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default DatatableContractType;
