import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import PendingDocuments from '../../../components/Employee/Dashboard/PendingDocuments';
import MyTeamRequests from '../../../components/Employee/Dashboard/MyTeamRequests';
import TeamAbsences from '../../../components/Employee/Dashboard/TeamAbsences';
import TeamTasks from '../../../components/Employee/Dashboard/TeamTasks';
import EmployeeData from '../../../components/Employee/Dashboard/EmployeeData';
import CompanyTaskDatatable from '../../CompanyTask/CompanyTaskDatatable';
import AbsenceDataTable from '../../Absence/AbsenceDataTable';
import absenceColumns from '../../Absence/Columns';
import WorkflowRequestDataTable from '../../WorkflowRequest/WorkflowRequestDataTable';
import WorkflowColumns from '../../Dashboard/WorkflowColumns';
import { indexEmployeesRequest } from '../../../requests/employees';

const TeamDashboardIndex = ({ user }) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState('');
  const [employees, setEmployees] = useState([]);
  const employee = user.employeeCard;
  const currentEmployee = employee.id;
  const [moreData, setMoreData] = useState(false);
  const today = moment().format('YYYY-MM-DD');

  const fetchEmployees = () => {
    if (currentEmployee) {
      indexEmployeesRequest({
        dispatch,
        params: {
          employees_belong_boss: currentEmployee,
          is_dt: false
        },
        successCallback: res => {
          const ids = [];
          res.data.data.map(item => ids.push(item.id));
          setEmployees(ids);
          setMoreData(!moreData);
        }
      });
    }
  };
  useEffect(fetchEmployees, []);

  const dataTableHead = () => {
    switch (key) {
      case 'absences-datatable':
        return 'Ausentismo';
      case 'tasks-datatable':
        return 'Tareas';
      case 'workflows':
        return 'Documentos pendientes de firma';
      default:
        return '';
    }
  };

  const setDatatable = () => {
    switch (key) {
      case 'tasks-datatable':
        return <CompanyTaskDatatable />;
      case 'absences-datatable':
        return (
          <AbsenceDataTable
            columns={absenceColumns}
            moreData={moreData}
            customParams={{
              filter_except_employee: currentEmployee,
              filter_by_employee: employees,
              date_range: today
            }}
            setMoreData={setMoreData}
          />
        );
      case 'workflows':
        return (
          <WorkflowRequestDataTable
            columns={WorkflowColumns}
            withMassActions
            customFilter={{
              not_document_employee: currentEmployee,
              status: 'pending',
              pending_review: currentEmployee
            }}
            refreshDashboard={() => setMoreData(!moreData)}
          />
        );
      default:
        return '';
    }
  };

  return (
    <Row className="main-div sm-12 mb-3">
      <Col sm={12}>
        <Row>
          <Col md={7} sm={7} className="mt-2 mb-1">
            <EmployeeData user={user} />
          </Col>

          <Col sm={5} className="mb-1 mt-2">
            <PendingDocuments
              handleMoreInfoButton={() => setKey('workflows')}
              topClass="top-header-green"
              moreData={moreData}
              tab="team"
              employees={employees}
            />
          </Col>
        </Row>

        {key === 'workflows' && (
          <Row>
            <Col sm={12} md={12} lg={12}>
              <h4 className="mb-0">{dataTableHead()}</h4>
              {setDatatable(key)}
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={12}>
            <p className="title mb-1">
              <b>Acciones sobre mi equipo</b>
            </p>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="mb-1">
            <MyTeamRequests currentEmployee={currentEmployee} topClass="top-header-green" />
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="mb-1">
            <TeamAbsences handleMoreInfoButton={() => setKey('absences-datatable')} employees={employees} />
          </Col>
          <Col sm={6} className="mb-1">
            <TeamTasks handleMoreInfoButton={() => setKey('tasks-datatable')} />
          </Col>
        </Row>

        {key !== '' && key !== 'workflows' && (
          <Row>
            <Col sm={12} md={12} lg={12}>
              <h4 className="mb-0">{dataTableHead()}</h4>
              {setDatatable(key)}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default TeamDashboardIndex;
