import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, ButtonToolbar } from "react-bootstrap";

const CenteredModal = ({
  header,
  title,
  body,
  titlebtn,
  size,
  onClickHide,
  ...props
}) => (
  <Modal
    {...props}
    size={size}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>{title}</h4>
      <p>{body}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClickHide}>{titlebtn}</Button>
    </Modal.Footer>
  </Modal>
);

CenteredModal.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  titlebtn: PropTypes.string.isRequired,
  size: PropTypes.string
};

CenteredModal.defaultProps = {
  header: null,
  size: "lg"
};

class ModalCenter extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = { modalShow: false };
  }

  modalClose = () => this.setState({ modalShow: false });

  render() {
    const { header, title, body, titlebtn, size, titlebtnmodal } = this.props;
    const { modalShow } = this.state;
    return (
      <ButtonToolbar>
        <Button
          variant="primary"
          onClick={() => this.setState({ modalShow: true })}
        >
          {titlebtnmodal}
        </Button>

        <CenteredModal
          show={modalShow}
          onHide={this.modalClose}
          header={header}
          title={title}
          body={body}
          titlebtn={titlebtn}
          size={size}
          titlebtnmodal={titlebtnmodal}
        />
      </ButtonToolbar>
    );
  }
}

export default ModalCenter;
