import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCandidatesRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/candidates', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showCandidatesRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/candidates/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createPublicCandidateRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('post', `/public/selection_processes/${id}/candidates`, {
    dispatch,
    params,
    formData: true,
    failureCallback,
    successCallback
  });

export const createCandidateRequest = (id, { dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', `/selection_processes/${id}/candidates`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const updateCandidatesRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/candidates/${id}/`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const deleteCandidateRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/candidates/${id}/`, {
    dispatch,
    successCallback,
    callback
  });

export const sendNotificationCandidatesRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('post', `/candidates/${id}/send_notification`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexCandidatesRequest = AwesomeDebouncePromise(indexCandidatesRequest, 300);
