import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'correlative_identification',
    sortable: true,
    grow: '0.1'
  },
  {
    name: 'NOMBRE DEL CURSO',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA TERMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'PARTICIPANTES',
    selector: 'employee_count',
    cell: item => item.employee_count || 0,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TEMA',
    selector: 'theme',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MODALIDAD',
    selector: 'course_modality',
    cell: item => item.translated_course_modality,

    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      switch (item.status) {
        case 'published':
          itemColor = 'primary';
          break;
        case 'no_published':
          itemColor = 'warning';
          break;
        case 'in_process':
          itemColor = 'info';
          break;
        case 'finished':
          itemColor = 'black';
          break;
        default:
          itemColor = '';
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Course"
        clickHandler={clickHandler}
        withShow
        disableDestroy={!!item.employee_count}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
