import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import {
  BasicTooltip,
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikMaterialUiTimePicker,
  FormikNumber,
  Icon
} from '../../components';
import ShiftContractsForm from './ShiftContractsForm';
import './style.scss';

const ShiftRotative = ({ errors, touched, setFieldValue, isSubmitting, onHide, shift, action, values }) => {
  const [activeDays, setActiveDays] = useState([]);
  const [initialActiveDays, setInitialActiveDays] = useState([]);

  const setInitialShiftDaysAttributes = () => {
    let vinitialActiveDays = [];
    if (action === 'new') {
      vinitialActiveDays = [...Array(7)].map(() => ({
        startTime: '09:00',
        endTime: '18:00',
        send: true,
        active: true
      }));
    } else {
      vinitialActiveDays = shift.shiftDaysAttributes.map(item => ({ ...item, send: true }));
    }
    setInitialActiveDays(vinitialActiveDays);
    setActiveDays(vinitialActiveDays);
    setFieldValue('shift[activeDays]', vinitialActiveDays.length);
  };

  useEffect(setInitialShiftDaysAttributes, [shift]);

  const onClickDayCard = (item, index, element) => {
    if (element._dispatchListeners[0] === undefined) {
      const vActiveDays = activeDays;
      vActiveDays[index] = { ...activeDays[index], active: !item.active };
      setFieldValue(`shift[shiftDaysAttributes][${index}]`, vActiveDays[index]);
      setActiveDays(vActiveDays);
    }
  };

  const handleDaysChange = daysCount => {
    setFieldValue('shift[activeDays]', daysCount);
    let daysValues = [];
    let daysArray = initialActiveDays.slice(0, daysCount);
    if (daysCount > initialActiveDays.length) {
      daysArray = daysArray.concat([...Array(daysCount - initialActiveDays.length)]);
    }
    if (daysCount === 0) {
      setFieldValue(`shift[shiftDaysAttributes]`, []);
    } else {
      daysValues = daysArray.map(item => {
        let dayOutput = [];
        if (item) {
          dayOutput = { ...item };
        } else {
          const fieldVal = { startTime: '09:00', endTime: '18:00', send: true, active: true };
          dayOutput = fieldVal;
        }
        return dayOutput;
      });
    }
    setFieldValue(`shift[shiftDaysAttributes]`, daysValues);
    setActiveDays(daysValues);
    initialActiveDays.slice(daysCount).map((item, index) => {
      const vValues = values.shift.shiftDaysAttributes;
      vValues[daysValues.length + index] = { ...item, send: false };
      return true;
    });
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="shift[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                placeholder="NOMBRE DE TURNO"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2}>
          <Field name="shift[startLunch]">
            {({ field }) => (
              <FormikMaterialUiTimePicker
                {...field}
                abbr
                timeSelector
                label="Inicio de Colacion"
                onChange={time => setFieldValue(field.name, time)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2}>
          <Field name="shift[endLunch]">
            {({ field }) => (
              <FormikMaterialUiTimePicker
                {...field}
                abbr
                timeSelector
                label="Termino de Colación"
                onChange={time => setFieldValue(field.name, time)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2}>
          <Field name="shift[parsedTolerance]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                label="Tolerancia"
                rightAddon="min"
                placeholder="0"
                fieldName="shift[tolerance]"
                value={values.shift.tolerance}
                tooltipSize="21"
                tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6} md={3} className="d-flex align-items-center">
          <Field name="shift[workHolidays]">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                custom
                field={field}
                label="TRABAJA FESTIVOS"
                tooltipSize="23"
                tooltipText="Turno con días festivos incluídos"
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={3} className="d-flex align-items-center">
          <Field name="shift[lunchCheck]">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                custom
                field={field}
                label="MARCAR COLACIÓN"
                tooltipSize="23"
                tooltipText="Indica si deben marcar el ingreso y salida de colación"
              />
            )}
          </Field>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4} className="rotative-proportion-card">
          <Row>
            <Col md={12}>
              <p className="text-uppercase text-center">Días</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <Icon
                width={24}
                className="as-button mt-1"
                icon="remove"
                onClick={() => values.shift.activeDays > 0 && handleDaysChange(values.shift.activeDays - 1)}
              />
            </Col>
            <Col xs={8}>
              <Field name="shift[activeDays]" style={{ textAlign: 'center' }}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label={false}
                    readOnly
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xs={2}>
              <Icon
                width={24}
                className="as-button mt-1"
                icon="add"
                onClick={() => handleDaysChange(values.shift.activeDays + 1)}
              />
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <BasicTooltip text="Número de días totales en este turno rotativo">
            <Icon icon="help-circle" width={22} color="grey" />
          </BasicTooltip>
        </Col>
        <Col md={6}>
          <Field name="shift[startDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={() => false}
                label="Fecha de Inicio"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {activeDays.length > 0 && <h3 className="text-uppercase mt-4 mb-3">Días Rotativos</h3>}
      <Row>
        {activeDays.map((item, index) =>
          item.active ? (
            <Col
              xs={5}
              sm={2}
              md={2}
              key={`day-${index + 1}`}
              className="shift-card-container-rotative mt-3"
              onClick={e => onClickDayCard(item, index, e)}
            >
              <Row>
                <Col md={12}>
                  <Icon
                    className="text-primary"
                    style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                    width={18}
                    icon="checkmark-circle"
                  />
                  <p className="text-uppercase shift-card-title">Día {index + 1}</p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 align-items-center" md={12}>
                  <p className="text-uppercase shift-card-label">Desde</p>
                  <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        defaultValue="09:00"
                        onChange={time => setFieldValue(field.name, time)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md={12}>
                  <p className="text-uppercase shift-card-label">Hasta</p>
                  <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        defaultValue="18:00"
                        onChange={time => setFieldValue(field.name, time)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              key={`day-${activeDays.length + index + 1}`}
              xs={5}
              sm={2}
              md={2}
              className="shift-card-container-rotative-free mt-3"
              onClick={e => onClickDayCard(item, index, e)}
            >
              <p className="text-uppercase shift-card-title text-center mt-4">Día {index + 1}</p>
              <p className="text-uppercase text-center mb-4">Libre</p>
            </Col>
          )
        )}
      </Row>
      <hr />
      <ShiftContractsForm shift={shift} />
      <Row className="d-flex justify-content-end my-3">
        <Col md={3}>
          <Button type="submit" disabled={isSubmitting} variant="primary" block onClick={onHide}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = [];
  if (shiftDaysAttributes.length) {
    shiftDays = shiftDaysAttributes.map(item => ({ ...item, send: true }));
  } else {
    shiftDays = [...Array(7)].map(() => ({
      startTime: '09:00',
      endTime: '18:00',
      send: true,
      active: true
    }));
  }
  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'rotative'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape({
    lunchCheck: Yup.boolean(),
    workHolidays: Yup.boolean(),
    tolerance: Yup.number()
      .required('Debes ingresar una tolerancia')
      .min(0, 'Debe ser mayor o igual a 0'),
    startLunch: Yup.string().required('Debes ingresar una hora de inicio'),
    endLunch: Yup.mixed()
      .required('Debes ingresar una hora de término')
      .test('from-less-than-to', 'Hora de término no puede ser inferior al horario de inicio', function(value) {
        const dayStr = moment().format('DD/MM/YYYY');
        return moment(`${dayStr} ${value}`, 'DD/MM/YYYY HH:mm').isAfter(
          moment(`${dayStr} ${this.parent.startLunch}`, 'DD/MM/YYYY HH:mm')
        );
      }),
    name: Yup.string().required('Debes ingresar un nombre'),
    startDate: Yup.string().required('Debes ingresar fecha de inicio'),
    shiftDaysAttributes: Yup.array().of(
      Yup.object().shape({
        groups: Yup.array().notRequired(),
        endTime: Yup.string().when('active', {
          is: val => val,
          then: Yup.string()
            .required('Debes ingresar hora de término')
            .notOneOf([Yup.ref('startTime'), null], 'Las horas de inicio y termino no pueden ser iguales'),
          otherwise: Yup.string().nullable()
        }),
        startTime: Yup.string().when('active', {
          is: val => val,
          then: Yup.string().required('Debes ingresar hora de inicio'),
          otherwise: Yup.string().nullable()
        }),
        send: Yup.boolean()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftRotative);
