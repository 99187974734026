import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import moment from 'moment';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useHistory } from 'react-router-dom';
import { lmeDetLccZaRequest, lmeInfSeccCRequest } from '../../requests/licenseImed';
import { Icon, LinkBtn, DefaultModal } from '../../components';
import initialLicenseImed from './licenseImed';
import LicenseImedTransactForm from './LicenseImedTransactForm';
import { showCompanyRequest } from '../../requests/companies';
import { indexRegionsRequest } from '../../requests/regions';
import { sendAlert } from '../../actions/utils';
import { payrollsRequest, searchEmployeeByRutRequest } from '../../requests/employees';
import { typeLicense } from './FormOptions';
import { indexAllAbsenceRequest } from '../../requests/absences';

const LicenseImedTransact = ({ match }) => {
  const numLicense = match.params.license;
  const rutEmployee = match.params.rut;
  const transact = initialLicenseImed;
  const [onRequest, setOnRequest] = useState(true);
  const [company, setCompany] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [sleepStartDate, setSleepStartDate] = useState([]);
  const [listRegion, setListRegion] = useState([]);
  const listLicenseType = typeLicense;
  const [typeLicenseId, setTypeLicenseId] = useState('');
  const [anotherOccupation, setAnotherOccupation] = useState(true);
  const [isInp, setIsInp] = useState(true);
  const [absences, setAbsences] = useState([]);
  const [payrolls, setPayrolls] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [body, setBody] = useState('');
  const [modalItem, setModalItem] = useState('');
  const [modalAction, setmodalAction] = useState('');
  const [licenseBack, setLicenseBack] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = localStorage.getItem('currentCompany');

  const [lemZa, setLemZa] = useState({
    rutWorkerZa: '',
    paternalSurnameWorkerZA: '',
    maternalSurnameWorkerZA: '',
    namesWorkerZA: '',
    sleepStartDate: '',
    dayRest: '',
    codeLicense: ''
  });

  const handleSuccessAction = response => {
    if (response.estado === '0') {
      dispatch(sendAlert({ kind: 'success', message: 'Licencia tramitada con exito' }));
      history.push(`/license/imed`);
    }
    if (response.estado === '1') {
      dispatch(sendAlert({ kind: 'error', message: response.glo_estado }));
    }
  };

  const handleModalClose = () => {
    const { setSubmitting } = modalItem;
    setModalShow(false);
    setSubmitting(false);
  };

  const handleProcessLicenseRequest = item => {
    const { dateLicence, setSubmitting } = item;
    dateLicence.transact.typeLicense = typeLicenseId;
    dateLicence.transact.employeeId = employee.id;
    dateLicence.transact.startDate = lemZa.sleepStartDate;
    dateLicence.transact.endDate = lemZa.sleepEndDate;
    lmeInfSeccCRequest({
      dispatch,
      params: dateLicence,
      successCallback: response => handleSuccessAction(response?.data?.lme_inf_secc_c_response),
      callback: () => setSubmitting(false)
    });
  };

  const handleConfirmProcessLicense = (dateLicence, setSubmitting) => {
    setTitleModal('Tramitar Licencia');
    setBody(`¿Estás seguro que desea traminar la licencia del colaborador ${lemZa.namesWorkerZA}
            ${lemZa.paternalSurnameWorkerZA} ${lemZa.maternalSurnameWorkerZA}?`);
    setModalShow(true);
    setModalItem({ dateLicence, setSubmitting });
    setmodalAction(() => handleProcessLicenseRequest);
  };
  const region = () => {
    indexRegionsRequest({
      dispatch,
      params: { paginate: false },
      successCallback: response => {
        setListRegion(camelCaseRecursive(response.data.data));
      }
    });
  };

  const payrollEmployee = (codigoTipoLicencia, fechaInicioReposo) => {
    let startTo;
    let endTo;

    if (codigoTipoLicencia === '3') {
      startTo = moment(fechaInicioReposo)
        .subtract(7, 'months')
        .format('MM/YYYY');
      endTo = moment(fechaInicioReposo)
        .subtract(2, 'months')
        .format('MM/YYYY');
    }
    if (codigoTipoLicencia !== '3') {
      startTo = moment(fechaInicioReposo)
        .subtract(3, 'months')
        .format('MM/YYYY');
      endTo = moment(fechaInicioReposo)
        .subtract(1, 'months')
        .format('MM/YYYY');
    }
    const rut = rutEmployee.replace('-', '');

    payrollsRequest({
      dispatch,
      params: {
        national_identification: rut,
        start_date: startTo,
        end_date: endTo
      },
      successCallback: response => setPayrolls(response.data)
    });
  };

  const handleSuccessZa = response => {
    if (response.data.lme_det_lcc_za_response.estado === '0') {
      const { trabajador, fechaInicioReposo, traNdias } = camelCaseRecursive(
        response.data.lme_det_lcc_za_response.dcto_lme.ZONA_A.ZONA_A1
      );
      const { codigoTipoLicencia } = camelCaseRecursive(response.data.lme_det_lcc_za_response.dcto_lme.ZONA_A.ZONA_A3);

      setTypeLicenseId(codigoTipoLicencia);
      setSleepStartDate(fechaInicioReposo);
      setLemZa({
        rutWorkerZa: trabajador.rut,
        paternalSurnameWorkerZA: trabajador.apellidoPaterno,
        maternalSurnameWorkerZA: trabajador.apellidoMaterno,
        namesWorkerZA: trabajador.nombres,
        sleepStartDate: moment(fechaInicioReposo).format('DD/MM/YYYY'),
        dayRest: traNdias,
        codeLicense: codigoTipoLicencia,
        sleepEndDate: moment(fechaInicioReposo)
          .add(traNdias - 1, 'd')
          .format('DD/MM/YYYY')
      });
      payrollEmployee(codigoTipoLicencia, fechaInicioReposo);
    }
  };

  const fetchAbsence = employeeId => {
    indexAllAbsenceRequest({
      dispatch,
      params: {
        filter_by_employee: employeeId,
        date_range: `${moment(sleepStartDate)
          .subtract(6, 'months')
          .startOf('month')
          .format('DD/MM/YYYY')},${moment(sleepStartDate)
          .subtract(1, 'months')
          .endOf('month')
          .format('DD/MM/YYYY')}`
      },
      successCallback: response => {
        setAbsences(response.data);
      }
    });
  };
  const employeeRequest = () => {
    searchEmployeeByRutRequest({
      dispatch,
      params: { national_identification: rutEmployee.replace('-', '') },
      successCallback: response => {
        const { data } = response;
        setEmployee(data);
        setOnRequest(false);
        fetchAbsence(response.data.id);
      }
    });
  };

  const currentCompany = () => {
    showCompanyRequest(companyId, {
      dispatch,
      successCallback: response => {
        const { data } = response;
        setCompany(camelCaseRecursive(data));
      }
    });
  };

  const lemDetLccZa = () => {
    const license = numLicense.split('-');

    lmeDetLccZaRequest({
      dispatch,
      params: { num_license: license[0], dv_license: license[1] },
      successCallback: handleSuccessZa
    });
  };

  const handleInitialState = () => {
    const typeContract = employee.contract ? employee.contract.contract_type : '';
    const healthPrevision = employee.contract?.employee_prevision_attributes?.health_prevision_id;
    transact.numLicense = numLicense;
    transact.companyName = company.businessName;
    transact.companyRut = company.nationalIdentification;
    transact.companyPhone.phone = company.phoneCountryCode
      ? company.phoneCountryCode.replace('+', '') + company.phone
      : '';

    transact.codeWorkActivity = company.workActivityId;
    transact.companyFullAddress = company.fullAddress;
    transact.companyAddress.street = company.address;
    transact.companyAddress.number = company.addressNumber;
    transact.companyAddress.commune = company.communeCode;
    transact.companyAddress.country = 'CHL';
    transact.codeOcupation = employee.careers?.length > 0 ? 19 : '';
    setAnotherOccupation(false);

    transact.otherOcupation = employee.careers?.length > 0 ? employee.careers[0].label : '';
    const pesionFundCode = employee.contract?.employee_prevision_attributes?.pension_fund?.params;
    const pensionFundImed = pesionFundCode ? JSON.parse(pesionFundCode) : '';

    if (typeLicenseId === '3') {
      for (let index = 0; index < 6; index += 1) {
        if (payrolls.length > 0) {
          let dayOfLicense = 0;
          let workedDays = 30;
          let assetsTaxableTotal = 0;
          let pensionFund = pensionFundImed;
          payrolls.forEach(e => {
            const monthYear = e.month_year;
            const formatSleepStartDate = moment(sleepStartDate)
              .subtract(index + 1, 'months')
              .format('M/YYYY');

            if (monthYear === formatSleepStartDate) {
              pensionFund = JSON.parse(e.payroll_info.prevision.pension_fund.params);
              dayOfLicense = parseInt(e.payroll_info.license_days, 10);
              workedDays = parseInt(e.payroll_info.worked_days, 10);
              assetsTaxableTotal = parseInt(e.assets_taxable_total, 10);
            }
          });

          transact.remuneration[index] = {
            PreviousIncomeForecastCode: pensionFund.imed,
            yearMonthPrevRent: moment(sleepStartDate)
              .subtract(index + 1, 'months')
              .format('MM/YYYY'),
            nDaysPrevRem: workedDays,
            taxableAmountPrevRent: assetsTaxableTotal,
            totalAmountPrevRent: 0,
            amountDisabilityPrevRem: 0,
            ndaysDisabilityPrevRem: dayOfLicense
          };
        } else {
          transact.remuneration[index] = {
            PreviousIncomeForecastCode: pensionFundImed.imed,
            yearMonthPrevRent: moment(sleepStartDate)
              .subtract(index + 1, 'months')
              .format('MM/YYYY'),
            nDaysPrevRem: 30,
            taxableAmountPrevRent: 0,
            totalAmountPrevRent: 0,
            amountDisabilityPrevRem: 0,
            ndaysDisabilityPrevRem: 0
          };
        }
      }
    }

    if (typeLicenseId !== '3') {
      for (let index = 0; index < 3; index += 1) {
        if (payrolls.length > 0) {
          let dayOfLicense = 0;
          let workedDays = 30;
          let assetsTaxableTotal = 0;
          let pensionFund = pensionFundImed;
          payrolls.forEach(e => {
            const monthYear = e.month_year;
            const formatSleepStartDate = moment(sleepStartDate)
              .subtract(index + 1, 'months')
              .format('M/YYYY');

            if (monthYear === formatSleepStartDate) {
              pensionFund = JSON.parse(e.payroll_info.prevision.pension_fund.params);
              dayOfLicense = parseInt(e.payroll_info.license_days, 10);
              workedDays = parseInt(e.payroll_info.worked_days, 10);
              assetsTaxableTotal = parseInt(e.assets_taxable_total, 10);
            }
          });
          transact.remuneration[index] = {
            PreviousIncomeForecastCode: pensionFund.imed,
            yearMonthPrevRent: moment(sleepStartDate)
              .subtract(index + 1, 'months')
              .format('MM/YYYY'),
            nDaysPrevRem: workedDays,
            taxableAmountPrevRent: assetsTaxableTotal,
            totalAmountPrevRent: 0,
            amountDisabilityPrevRem: 0,
            ndaysDisabilityPrevRem: dayOfLicense
          };
        } else {
          transact.remuneration[index] = {
            PreviousIncomeForecastCode: pensionFundImed.imed,
            yearMonthPrevRent: moment(sleepStartDate)
              .subtract(index + 1, 'months')
              .format('MM/YYYY'),
            nDaysPrevRem: 30,
            taxableAmountPrevRent: 0,
            totalAmountPrevRent: 0,
            amountDisabilityPrevRem: 0,
            ndaysDisabilityPrevRem: 0
          };
        }
      }
    }

    switch (employee.contract?.employee_prevision_attributes?.pension_scheme) {
      case 'afp':
        transact.codeTypePensionScheme = 2;
        transact.codePensionScheme = pensionFundImed.imed;
        transact.previsionName = employee.contract?.employee_prevision_attributes?.pension_fund.name;
        transact.affiliationDate = employee.contract?.employee_prevision_attributes?.pension_affiliation_date;
        break;
      case 'ips':
        transact.codeTypePensionScheme = 1;
        setIsInp(false);
        break;
      case 'not_listed':
        transact.codeTypePensionScheme = 2;
        transact.codePensionScheme = -1;
        break;
      default:
        transact.codeTypePensionScheme = '';
        transact.codePensionScheme = '';
        break;
    }

    transact.havePreviousLicense = absences.length > 0;
    setLicenseBack(absences.length > 0);
    absences.forEach((element, index) => {
      transact.previousLicense[index] = {
        lmaNdays: moment(element.calendar_format.end).diff(moment(element.calendar_format.start), 'days') + 1,
        lmaDateFrom: element.start_date,
        lmaDateTo: element.end_date
      };
    });
    transact.codeWorkerQuality = employee.contract?.worker_quality_id;
    transact.dateContract = employee.contract ? employee.contract.start_date : '';
    transact.codeSafeUndefined = typeContract === 'undefined_term';
    transact.codeSafeAfc = company.compensationFundId !== null;

    if ((typeLicenseId >= '1' && typeLicenseId <= '4') || typeLicenseId === '7') {
      if (healthPrevision === 5) {
        transact.codePayingEntity = 'C';
        transact.processingCodeCCAF = company.compensationFund?.params?.imed;
      }

      if (healthPrevision !== 5 && healthPrevision !== 12) {
        transact.codePayingEntity = 'B';
        transact.processingCodeCCAF = 10100;
      }

      if (healthPrevision === 12) {
        transact.codePayingEntity = 'A';
        transact.processingCodeCCAF = 10100;
      }
    }

    if (typeLicenseId === '5' || typeLicenseId === '6') {
      if (company?.safety_organization_id !== null) {
        transact.codePayingEntity = 'F';
        transact.processingCodeCCAF = company.safetyOrganization?.params?.imed;
      }

      if (company?.safety_organization_id === null) {
        transact.codePayingEntity = 'E';
        transact.processingCodeCCAF = 10100;
      }
    }
  };

  const initRequest = () => {
    currentCompany();
    lemDetLccZa();
    region();
    employeeRequest();
  };

  useEffect(initRequest, []);
  useEffect(handleInitialState, [absences, company]);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle ml-n2 ml-md-2 mt-md-3" to="/license/imed">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-2">Tramitar Licencia N° {numLicense}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
          {!onRequest && (
            <LicenseImedTransactForm
              transact={transact}
              lemZa={lemZa}
              listLicenseType={listLicenseType}
              numLicense={numLicense}
              employee={employee}
              listRegion={listRegion}
              formRequest={handleConfirmProcessLicense}
              action="edit"
              submitVariant="success"
              rutEmployee={rutEmployee}
              setAnotherOccupation={setAnotherOccupation}
              anotherOccupation={anotherOccupation}
              setIsInp={setIsInp}
              isInp={isInp}
              payrolls={payrolls}
              setLicenseBack={setLicenseBack}
              licenseBack={licenseBack}
            />
          )}
        </Col>
      </Row>
      <DefaultModal
        title={titleModal}
        body={body}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default LicenseImedTransact;
