import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexQueryOptionsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/query_options/', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexQueryOptionsRequest = AwesomeDebouncePromise(indexQueryOptionsRequest, 300);
