import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput, Icon } from '../../components';
import { signUpRequest } from '../../actions/auth';
import PasswordChecklist from './PasswordChecklist';

const Register = props => {
  const { onHide, isValid, submitVariant, errors, touched, setFieldValue } = props;

  const [newPassword, setNewPassword] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const handleInputPassword = e => {
    setNewPassword(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const handleDisableButton = toogle => {
    setIsDisable(!toogle);
  };
  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={4}>
        <Form className="d-flex flex-column justify-content-end mt-5">
          <h3 className="text-uppercase">Crear cuenta</h3>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                placeholder="Email"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
                onChange={e => handleInputPassword(e)}
                error={errors[field.name]}
                touched={touched[field.name]}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowPassword(!showPassword)}
              />
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={showPasswordConfirmation ? 'text' : 'password'}
                placeholder="Confirmar Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
                rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                rightBtnClickHandler={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              />
            )}
          </Field>
          <PasswordChecklist password={newPassword} achieveRequirements={handleDisableButton} />
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4 ml-auto"
            type="submit"
            disabled={!isValid && isDisable}
            onClick={onHide}
          >
            CREAR CUENTA
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Obligatorio'),
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test('password-confirmation-test', 'Las contraseñas no coinciden', function equalTo(value) {
      if (value && value === this.parent.password) {
        return true;
      }
      return false;
    })
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    signUpRequest({
      user: {
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Register)
  )
);
