import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { showPayrollGroupRequest, updatePayrollGroupRequest } from '../../requests/payrollGroups';
import PayrollGroupForm from './PayrollGroupForm';

const basicPayrollGroup = {
  annualGratification: false,
  earningsOfPeriod: '',
  limitFifteenPercentage: true,
  month: '',
  name: '',
  payrollProcessId: '',
  payrollsAttributes: [],
  year: ''
};

const PayrollGroupEdit = ({ match }) => {
  const { id, payrollProcessId } = match.params;
  const [cancelSubmit, setCancelSubmit] = useState(false);
  const [payrollGroup, setPayrollGroup] = useState(basicPayrollGroup);
  const dispatch = useDispatch();
  const history = useHistory();

  const successCallback = useCallback(response => setPayrollGroup(camelCaseRecursive(response.data)), []);

  const fetchPayrollGroup = () => {
    showPayrollGroupRequest(payrollProcessId, id, { dispatch, successCallback });
  };

  const handleSuccessRequest = () => {
    history.push(`/payroll_processes/${payrollProcessId}/payroll_groups/${id}/payroll_balances/edit`);
  };

  const handleUpdateRequest = (params, setSubmitting) => {
    if (cancelSubmit) return;
    updatePayrollGroupRequest(payrollProcessId, id, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchPayrollGroup, []);

  return (
    <PayrollGroupForm
      action="edit"
      payrollGroup={payrollGroup}
      formRequest={handleUpdateRequest}
      setCancelSubmit={setCancelSubmit}
    />
  );
};

export default PayrollGroupEdit;
