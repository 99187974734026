import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';
import { debounceIndexEmployeesRequest } from '../../requests/employees';
import {
  createEmployeeCourseRequest,
  deleteEmployeeCourseRequest,
  updateEmployeeCourseRequest,
  massCreateEmployeeCourseRequest
} from '../../requests/employeeCourses';
import {
  ButtonTooltip,
  FormikSelect,
  Icon,
  SimpleCenteredModal,
  EmployeeSearchModal,
  ComponentDataTable,
  DefaultModal
} from '../../components';
import columnEmployee from './columnEmployee';
import { sendAlert } from '../../actions/utils';
import EmployeeCourseForm from './EmployeeCourseForm';
import { filterArray, sortByAttribute } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import EmployeeCourseRejectForm from './EmployeeCourseRejectForm';

const EmployeeCourseIndex = ({ onRequest, course, successCallback }) => {
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [filterEmployeeCourses, setFilterEmployeeCourses] = useState([]);
  const [modalBody, setModalBody] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalSubtitle, setModalSubtitle] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [participantRequest, setParticipantRequest] = useState(false);
  const [selector, setSelector] = useState([]);
  const [textInput, setTextInput] = useState('');
  const { employeeCourses } = course;
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const fetchEmployees = () => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        exclude_id: employeeCourses.map(item => item.employeeId),
        paginate: false,
        active: true,
        is_dt: false,
        active_contracts: true
      },
      successCallback: response => setSelector(response.data.data)
    });
  };

  const handleSelector = () => {
    setParticipantRequest(true);
    createEmployeeCourseRequest(course.id, {
      dispatch,
      params: { employee_course: { employee_id: employeeId, admission: 'approved' } },
      successCallback,
      callback: () => setParticipantRequest(false)
    });
  };

  const handleDestroy = item => {
    setParticipantRequest(true);
    deleteEmployeeCourseRequest(course.id, item.id, {
      dispatch,
      successCallback: response => {
        successCallback(response);
        setDefaultModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Participante eliminado con éxito.' }));
      },
      callback: () => setParticipantRequest(false)
    });
  };

  const handleEdit = ({ employeeCourse }) => {
    setParticipantRequest(true);
    updateEmployeeCourseRequest(course.id, employeeCourse.id, {
      dispatch,
      params: snakeCaseKeys({ employeeCourse }),
      successCallback: response => {
        successCallback(response);
        setSimpleModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Participante actualizado con éxito.' }));
      },
      callback: () => setParticipantRequest(false)
    });
  };

  const handleSearch = selectedEmployees => {
    setParticipantRequest(true);
    massCreateEmployeeCourseRequest(course.id, {
      dispatch,
      params: {
        employee_course: {
          employee_courses: selectedEmployees.map(id => ({ employee_id: id, admission: 'approved' }))
        }
      },
      successCallback: response => {
        successCallback(response);
        setModalShow(false);
      },
      callback: () => setParticipantRequest(false)
    });
  };

  const admissionEmployeeCourse = item => {
    setParticipantRequest(true);
    updateEmployeeCourseRequest(course.id, item.id, {
      dispatch,
      params: { employee_course: { admission: item.newAdmission, observation: item.observation } },
      successCallback: response => {
        successCallback(response);
        setDefaultModalShow(false);
        setSimpleModalShow(false);
        const admissionMsg = item.newAdmission === 'approved' ? 'admitido' : 'rechazado';
        dispatch(sendAlert({ kind: 'success', message: `Participante ${admissionMsg} con éxito.` }));
      },
      callback: () => setParticipantRequest(false)
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'approved':
        setModalTitle('Admitir Participante');
        setModalBody(`¿Estás seguro que deseas admitir al participante ${item.fullName}?`);
        setModalItem({ id: item.id, newAdmission: 'approved', observation: '' });
        setModalAction(() => admissionEmployeeCourse);
        setDefaultModalShow(true);
        break;
      case 'rejected':
        setModalTitle('Rechazar Participante');
        setModalSubtitle(item.fullName);
        setModalBody(
          <EmployeeCourseRejectForm
            employeeCourse={{ id: item.id, newAdmission: 'rejected', observation: '' }}
            handleClose={() => setSimpleModalShow(false)}
            formRequest={admissionEmployeeCourse}
          />
        );
        setSimpleModalShow(true);
        break;
      case 'show':
        setModalTitle('Motivo de Rechazo');
        setModalSubtitle(item.fullName);
        setModalBody(<p className="mx-3">{item.observation}</p>);
        setSimpleModalShow(true);
        break;
      case 'edit':
        setModalTitle('Actualizar Participante');
        setModalSubtitle(item.fullName);
        setModalBody(
          <EmployeeCourseForm
            employeeCourse={item}
            handleClose={() => setSimpleModalShow(false)}
            formRequest={handleEdit}
          />
        );
        setModalItem(item);
        setSimpleModalShow(true);
        break;
      case 'destroy':
        setModalTitle('Eliminar Participante');
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar al participante ${item.fullName}?`);
        setModalAction(() => handleDestroy);
        setDefaultModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const subHeaderComponent = () => (
    <Form.Group className="ml-auto w-25">
      <FormControl
        placeholder="Buscar"
        name="textInput"
        value={textInput}
        onChange={e => setTextInput(e.target.value)}
      />
    </Form.Group>
  );

  const filteredArray = () => {
    setFilterEmployeeCourses(
      filterArray(employeeCourses, textInput, [
        'fullName',
        'nationalIdentification',
        'jobTitle',
        'jobManagement',
        'translatedStatus',
        'admission'
      ])
    );
  };

  useEffect(fetchEmployees, [employeeCourses]);
  useEffect(filteredArray, [textInput, employeeCourses]);

  return (
    <>
      <Row className="my-4 align-items-center">
        <Col md={4} xl={5}>
          <h4 className="text-uppercase">Participantes</h4>
        </Col>
        {ability.can('create', 'EmployeeCourse') && (
          <>
            <Col md={4} xl={4}>
              <FormikSelect
                abbr
                label="Agregar trabajadores"
                placeholder="Seleccionar Trabajador"
                options={sortByAttribute(selector, 'full_name')}
                defaultValue="all_employees"
                onChange={data => setEmployeeId(data ? data.value : '')}
              />
            </Col>
            <Col md={2} xl={2}>
              {course.discountAvailability <= 0 && (
                <p className="text-danger text-center font-weight-bold">Superaste el límite de cupos</p>
              )}
              <Button
                variant="primary"
                block
                onClick={handleSelector}
                style={{ marginTop: '5px' }}
                disabled={participantRequest || !employeeId || course.discountAvailability <= 0}
              >
                Agregar
              </Button>
            </Col>
            <Col md={2} xl={1}>
              <ButtonTooltip
                variant="circle-primary"
                className="advance-search ml-auto"
                disabled={course.discountAvailability <= 0}
                text="Búsqueda avanzada de empleados mediante filtros de cargos, lugares de Prestación de Servicios, otros"
                onClick={() => setModalShow(true)}
              >
                <Icon className="w-100 h-100" icon="people-circle" />
              </ButtonTooltip>
            </Col>
            <SimpleCenteredModal
              title="Buscar Empleados"
              body={
                <EmployeeSearchModal
                  customParams={{ active: true }}
                  handleClose={() => setModalShow(false)}
                  formRequest={handleSearch}
                />
              }
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        )}
      </Row>
      <ComponentDataTable
        columns={columnEmployee(handleActions)}
        data={filterEmployeeCourses}
        onRequest={onRequest || participantRequest}
        totalRows={employeeCourses.length}
        defaultSortField="fullName"
        pointerOnHover
        sortServer={false}
        onRowClicked={item =>
          handleActions(item, (item.admission === 'approved' && 'edit') || (item.admission === 'rejected' && 'show'))
        }
        subHeaderComponent={subHeaderComponent()}
        paginationServer={false}
        pagination
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={participantRequest}
      />
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        subtitle={modalSubtitle}
        body={modalBody}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default EmployeeCourseIndex;
