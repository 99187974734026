import React from 'react';

const slideInfo = props => {
  const { slide } = props;
  const { position } = slide;
  const { caption } = slide;
  const active = slide.active ? 'Activo' : 'Inactivo';

  return (
    <>
      <h2>
        <strong>Titulo:</strong> {caption}
      </h2>
      <h3>
        <strong>Posición:</strong> {position}
      </h3>
      <p>
        <strong>Estado</strong> {active}
      </p>
    </>
  );
};

export default slideInfo;
