import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCertificationsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/certifications`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showCertificationRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/certifications/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createCertificationRequest = ({ dispatch, params, callback, formData, successCallback }) =>
  ApiService.request('post', '/certifications', {
    dispatch,
    params,
    callback,
    formData,
    successCallback
  });

export const updateCertificationRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/certifications/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteCertificationRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/certifications/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const exportCertificationsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/certifications/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportCertificationRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/certifications/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importCertificationRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/certifications/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateCertificationRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/certifications/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const debounceIndexCertificationsRequest = AwesomeDebouncePromise(indexCertificationsRequest, 300);
