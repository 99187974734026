import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCountriesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/countries', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexCountriesRequest = AwesomeDebouncePromise(indexCountriesRequest, 300);
