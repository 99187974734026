import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tab, Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useSetTab } from '../../../services/hooks';
import { createAnnouncementRequest } from '../../../requests/announcements';
import { SimpleCenteredModal } from '../../../components';
import AnnouncementFeed from './AnnouncementTasksFeed';
import { sendAlert } from '../../../actions/utils';
import AnnouncementForm from '../../Announcement/AnnouncementForm';
import announcement from '../../Announcement/announcement';

const AnnouncementIndex = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [key, setKey] = useSetTab('posts', location);
  const { currentEmployee } = useSelector(state => state.auth);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleCreateRequest = (announcementValues, setSubmitting) => {
    const { image } = announcementValues.announcement;
    const myParams = snakeCaseKeys(announcementValues);
    myParams.announcement.image = image;
    createAnnouncementRequest({
      dispatch,
      params: myParams,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Anuncio creado con éxito' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      callback: () => setSubmitting(false)
    });
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <Row className="m-top mb-3">
        <Col>
          <h2>Anuncios/Tareas</h2>
        </Col>
      </Row>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter unmountOnExit>
        <AnnouncementFeed moreData={moreData} currentEmployee={currentEmployee} />
      </Tab.Container>
      <SimpleCenteredModal
        title="Nueva Fecha Destacada"
        body={
          <AnnouncementForm
            announcement={{ ...announcement, employeeId: currentEmployee, status: 'visible' }}
            action="new"
            boss
            formRequest={handleCreateRequest}
          />
        }
        show={modalShow}
        onHide={toggleModal}
      />
    </>
  );
};

export default AnnouncementIndex;
