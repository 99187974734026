import React from 'react';
import PropTypes from 'prop-types';
import { useAbility } from '@casl/react';
import { ButtonTooltip } from '../Utils/Tooltips';
import { AbilityContext } from '../../config/abilityContext';
import Icon from '../Icons';

const ShiftChangeActions = ({ item, clickHandler, resource, approveText, withDestroy }) => {
  const ability = useAbility(AbilityContext);

  const hasPermission = ability.can('shift_change_action', resource);

  const canDestroy = ability.can('destroy', resource);

  if (item.status === 'pending') {
    if (hasPermission) {
      return (
        <>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'approved')}
            variant="circle-success"
            className="btn-circle"
            size="sm"
            text={approveText}
          >
            <Icon icon="checkmark-circle" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'rejected')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Rechazar"
          >
            <Icon icon="close-circle" />
          </ButtonTooltip>
        </>
      );
    }
    return 'No tienes permisos para esta acción';
  }
  if (canDestroy && withDestroy && (item.status === 'approved' || item.status === 'rejected')) {
    return (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'destroy')}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar"
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    );
  }
  return '';
};

ShiftChangeActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  approveText: PropTypes.string,
  disabled: PropTypes.bool,
  withDestroy: PropTypes.bool
};

ShiftChangeActions.defaultProps = {
  approveText: 'Aceptar',
  disabled: false,
  withDestroy: false
};

export default ShiftChangeActions;
