import React from 'react';
import PropTypes from 'prop-types';
import { useAbility } from '@casl/react';
import { ButtonTooltip } from '../Utils/Tooltips';
import { AbilityContext } from '../../config/abilityContext';
import Icon from '../Icons';

const DocumentActions = ({ handleClick, disabled }) => {
  const ability = useAbility(AbilityContext);
  return (
    <>
      {ability.can('approve_document', 'WorkflowRequest') && (
        <ButtonTooltip
          variant="circle-primary"
          className="mr-2 btn-circle mb-3 mr-2"
          text="Firmar o Aprobar"
          disabled={disabled}
          onClick={() => handleClick('massAprove')}
        >
          <Icon icon="create" />
        </ButtonTooltip>
      )}
      {ability.can('reject_document', 'WorkflowRequest') && (
        <ButtonTooltip
          variant="circle-danger"
          className="mr-2 btn-circle mb-3 mr-2"
          text="Rechazar"
          disabled={disabled}
          onClick={() => handleClick('massReject')}
        >
          <Icon icon="close-circle" />
        </ButtonTooltip>
      )}
    </>
  );
};

DocumentActions.propTypes = {
  handleClick: PropTypes.func.isRequired
};

DocumentActions.defaultProps = {};

export default DocumentActions;
