import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner } from 'react-bootstrap';

import { massiveCreateAttendanceRequest } from '../../requests/attendances';
import AttendanceNestedForm from './AttendanceNestedForm';

const basicAttendance = employeeId => [
  {
    date: '',
    time: '',
    timeType: '',
    parsedTime: '',
    employeeId
  }
];

const AttendanceNew = props => {
  const { handleSuccessAction, handleModalClose, employeeId } = props;
  const [onRequest, setOnRequest] = useState(false);
  const [attendances, setAttendances] = useState(basicAttendance(employeeId));
  const dispatch = useDispatch();

  useEffect(() => {
    setAttendances(basicAttendance(employeeId));
  }, [employeeId]);

  const handleSuccessCreate = () => {
    const message = 'Asistencia creada con éxito';
    setOnRequest(false);
    handleSuccessAction(message);
  };

  const handleFailureRequest = error => {
    const { handleFailureAction } = props;
    setOnRequest(false);
    handleFailureAction(error.response?.data?.message);
    handleModalClose();
  };

  const handleCreate = attendanceValues => {
    setOnRequest(true);
    const params = snakeCaseKeys(attendanceValues);

    massiveCreateAttendanceRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <AttendanceNestedForm
        attendances={attendances}
        formRequest={handleCreate}
        employeeId={employeeId}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default AttendanceNew;
