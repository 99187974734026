import React, { useState, useEffect } from 'react';
import { Row, Col, Tab, Nav, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useSetTab } from '../../../services/hooks';
import { indexCourseRequest } from '../../../requests/courses';
import { InfiniteScroll } from '../../../components';
import CardImage from '../../../components/Utils/Cards/CardImage';
import './style.scss';

const CourseProfileIndex = ({ location }) => {
  const [courses, setCourses] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [key, setKey] = useSetTab('all-course', location, 'course-tab');
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const employeeId = user.employeeCard?.id;

  const handleRequest = () => {
    setOnRequest(true);
    const request = async () =>
      indexCourseRequest({
        dispatch,
        params: {
          display_start: page * 15,
          display_length: 15,
          is_public: 'published',
          filter_my_courses: checked ? employeeId : []
        },
        successCallback: response => {
          if (response.data.data.length + courses.length === response.data.metadata.amount) {
            setHasMore(false);
          }
          setPage(page + 1);
          setCourses([...courses, ...camelCaseRecursive(response.data.data)]);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleRefresh = () => {
    setHasMore(true);
    setPage(0);
    setCourses([]);
    setRefresh(!refresh);
  };

  useEffect(handleRefresh, [checked]);
  useEffect(handleRequest, [refresh]);

  const showInfo = item => history.push(`courses/${item.id}`);

  return (
    <>
      <Row className="my-4">
        <Col>
          <h2 className="m-top mb-3 mt-3">Cursos</h2>
        </Col>
      </Row>
      <div className="profile-show">
        <Tab.Container id="course.tabs" activeKey={key} mountOnEnter onSelect={k => setKey(k)}>
          <Row>
            <Col>
              <Nav variant="pills" className="ab-pills">
                <Nav.Item>
                  <Nav.Link eventKey="all-course">Todos los Cursos</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={3} className="d-flex justify-content-end mr-2 mt-3">
              <Form.Group onClick={() => setChecked(!checked)}>
                <Form.Check
                  checked={checked}
                  className="custom-checkbox"
                  label="Ver solo mis cursos"
                  type="checkbox"
                  readOnly
                  custom
                />
              </Form.Group>
            </Col>
          </Row>
          <Tab.Content className={`${onRequest && 'bg-opacity'}`}>
            <Tab.Pane eventKey="all-course">
              <InfiniteScroll dataLength={courses.length} next={handleRequest} hasMore={hasMore} label="cursos">
                <Row className="mt-4">
                  {courses.map(item => (
                    <Col key={item.id} xs={12} md={6}>
                      <CardImage
                        item={item}
                        image={item.courseImage.fileUrl}
                        showInfo={showInfo}
                        nameShow="Ver"
                        className="card-size"
                        classNameNoImage="card-size-noimage"
                        classNameDescription="box-description-height"
                      />
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default CourseProfileIndex;
