import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { AbilityContext } from '../../../config/abilityContext';

import { createLoanRequest } from '../../../requests/loans';
import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal } from '../../../components';
import LoanDataTable from '../../Shared/Loan/LoanDataTable';
import LoanRequestForm from '../../Shared/Loan/LoanRequestForm';
import columns from './Columns';

class LoanIndex extends Component {
  state = {
    modalShow: false,
    moreData: false,
    formRef: React.createRef(),
    formikRef: React.createRef(),
    currentLoan: {},
    newLoan: false
  };

  handleCreate = () => {
    const { formRef, formikRef } = this.state;

    formRef.current.props = { ...formRef.current.props, formRequest: this.handleCreateRequest };
    formikRef.current.submitForm();
  };

  handleSuccessRequest = () => {
    const { moreData } = this.state;
    const { dispatch } = this.props;

    dispatch(sendAlert({ kind: 'success', message: 'Solicitud creada correctamente' }));
    this.setState({ moreData: !moreData });
    this.handleModalClose();
  };

  handleCreateRequest = (loan, setSubmitting) => {
    const { dispatch } = this.props;
    const myParams = snakeCaseKeys(loan);

    createLoanRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: this.handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  handleFailureRequest = error => {
    const { response } = error;
    const { dispatch } = this.props;
    this.handleModalClose();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  handleModalClose = () => {
    this.setState({ modalShow: false });
  };

  handleUpdateModal = loan => {
    this.setState({ newLoan: false, currentLoan: loan, modalShow: true });
  };

  handleCreateModal = () => {
    const { employeeId } = this.props;
    const currentLoan = {
      amount: 0,
      installments: 0,
      parsedAmount: '',
      reason: '',
      grantDate: '',
      startDate: '',
      resolvedDate: '',
      employeeId
    };
    this.setState({ currentLoan, newLoan: true, modalShow: true });
  };

  render() {
    const { advanceSettings, employeeId } = this.props;
    const { modalShow, moreData, currentLoan, newLoan } = this.state;
    const canNew = advanceSettings?.filter(asItem => asItem.code === 'loan_request')[0]?.textValue === 'true';
    const reqAmountLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_amount_limit')[0]?.integerValue;
    const { context } = this;

    return (
      <Row className="mt-1 mb-3">
        <Col>
          {canNew && (
            <Row className="border-responsive">
              <Col xs={{ offset: 6 }} md={{ span: 3, offset: 9 }}>
                {context.can('create', 'Loan') && (
                  <Button variant="primary" block onClick={this.handleCreateModal}>
                    Nuevo
                  </Button>
                )}

                <SimpleCenteredModal
                  title="Solicitar Préstamo"
                  body={
                    <LoanRequestForm
                      newLoan={newLoan}
                      formRequest={this.handleCreateRequest}
                      handleModalClose={this.handleModalClose}
                      loan={currentLoan}
                      reqAmountLimit={reqAmountLimit}
                    />
                  }
                  show={modalShow}
                  onHide={this.handleModalClose}
                />
              </Col>
            </Row>
          )}
          <LoanDataTable
            columns={columns}
            moreData={moreData}
            setMoreData={() => this.setState({ moreData: !moreData })}
            customParams={{ filter_employee: employeeId }}
            handleUpdateModal={this.handleUpdateModal}
            handleFailureRequest={this.handleFailureRequest}
            employee
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const employeeId = state.auth.currentEmployee;
  const { advanceSettings } = state.utils;
  return { employeeId, advanceSettings };
};

LoanIndex.contextType = AbilityContext;

export default connect(mapStateToProps)(LoanIndex);
