import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icons';
import { ButtonTooltip } from '../Utils/Tooltips';
import { useAuthorization } from '../../services/hooks';

const AttendanceUpdateActionBtns = ({
  item,
  clickHandler,
  editColor,
  editIcon,
  destroyText,
  withShow,
  withEdit,
  withDestroy,
  disableDestroy,
  resource,
  archiveText,
  archiveColor,
  withArchive,
  extraIcon,
  extraIconAction,
  extraIconColor,
  extraIconText
}) => {
  const canShow = useAuthorization('show', resource);
  const canRead = useAuthorization('read', resource);
  return (
    <>
      {extraIcon && (
        <ButtonTooltip
          onClick={() => clickHandler(item, extraIconAction)}
          variant={extraIconColor}
          className="btn-circle"
          size="sm"
          text={extraIconText}
        >
          <Icon icon={extraIcon} />
        </ButtonTooltip>
      )}
      {(canShow || canRead) && withShow && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      )}
      {useAuthorization('update', resource) && withEdit && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Reemplazar"
        >
          <Icon style={editColor && { color: editColor }} icon={editIcon} />
        </ButtonTooltip>
      )}
      {useAuthorization('deactivate', resource) && withArchive && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'archive')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text={archiveText}
        >
          <Icon style={archiveColor && { color: archiveColor }} icon="archive" />
        </ButtonTooltip>
      )}
      {useAuthorization('destroy', resource) && withDestroy && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text={destroyText}
          disabled={disableDestroy}
        >
          <Icon style={archiveColor && { color: archiveColor }} icon="archive" />
        </ButtonTooltip>
      )}
    </>
  );
};

AttendanceUpdateActionBtns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  destroyText: PropTypes.string,
  archiveColor: PropTypes.string,
  archiveText: PropTypes.string,
  withShow: PropTypes.bool,
  withEdit: PropTypes.bool,
  withArchive: PropTypes.bool,
  withDestroy: PropTypes.bool,
  disableDestroy: PropTypes.bool,
  editIcon: PropTypes.string,
  editColor: PropTypes.string,
  resource: PropTypes.string.isRequired
};

AttendanceUpdateActionBtns.defaultProps = {
  destroyText: 'Desactivar/Activar',
  archiveText: 'Desactivar/Activar',
  withShow: true,
  withArchive: false,
  withEdit: true,
  withDestroy: true,
  disableDestroy: false,
  editIcon: 'refresh',
  editColor: '',
  archiveColor: ''
};

export default AttendanceUpdateActionBtns;
