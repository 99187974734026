import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createDirectoryNodeRequest, createDirectoryFilesRequest } from '../../../requests/directoryNodes';
import { sendAlert } from '../../../actions/utils';
import DirectoryNodeFormFiles from './DirectoryNodeFormFiles';
import DirectoryNodeFormFolder from './DirectoryNodeFormFolder';

const DirectoryNodeNew = ({ onSuccess, onHide, employeeId, parentId, parentName, fileType }) => {
  const dispatch = useDispatch();

  const handleSuccessCreate = type => {
    const message = type ? 'Archivo creado con éxito' : 'Carpeta creada con éxito';
    dispatch(sendAlert({ kind: 'success', message }));
    onSuccess();
    onHide();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const createNodeRequest = directoryNode => {
    const myParams = snakeCaseKeys(directoryNode);
    myParams.directory_node.parent_id = parentId;
    createDirectoryNodeRequest({
      employeeId,
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessCreate(),
      failureCallback: handleFailureRequest
    });
  };

  const createFileRequest = directoryNode => {
    const {
      directoryNode: { file }
    } = directoryNode;
    const myParams = snakeCaseKeys(directoryNode);
    myParams.directory_node.file = file;

    createDirectoryFilesRequest(parentId, {
      employeeId,
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessCreate(true)
    });
  };

  return fileType === 'folder' ? (
    <DirectoryNodeFormFolder
      formRequest={createNodeRequest}
      subtitle={<p>Estás creando una carpeta dentro de {parentName}</p>}
      onHide={onHide}
    />
  ) : (
    <DirectoryNodeFormFiles action="new" submitVariant="primary" formRequest={createFileRequest} onHide={onHide} />
  );
};

export default DirectoryNodeNew;
