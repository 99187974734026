import React from 'react';

const jobTitleInfo = ({ jobTitle }) => {
  const { active, name, code, description, jobManagements, translatedIneGroup } = jobTitle;
  const activeMessage = active ? 'Sí' : 'No';
  const jobManagementsInfo = jobManagements.map(jobManagement => {
    return (
      <p key={`jobManagement-${jobManagement.value}`} className="info">
        <span>Nombre:</span> {jobManagement.label}
      </p>
    );
  });

  return (
    <>
      <div className="info-box">
        <h4 className="text-uppercase">Información Cargo</h4>
        <p className="info">
          <span>Nombre:</span> {name}
        </p>
        <p className="info">
          <span>Código:</span> {code}
        </p>
        <p className="info">
          <span>Descripción:</span> {description}
        </p>
        <p className="info">
          <span>Agrupación INE:</span> {translatedIneGroup}
        </p>
        <p className="info">
          <span>Activo:</span> {activeMessage}
        </p>
      </div>
      {jobManagementsInfo.length > 0 && (
        <div className="info-box">
          <h4 className="text-uppercase">Áreas</h4>
          {jobManagementsInfo}
        </div>
      )}
    </>
  );
};

export default jobTitleInfo;
