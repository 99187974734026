import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ComponentDataTable, DefaultModal } from '../../components';
import { debounceIndexCourseRequest, deleteCourseRequest } from '../../requests/courses';
import { sendAlert } from '../../actions/utils';
import { delayMethod } from '../../services/utils';
import columnCourse from './columnCourse';

const CourseDatatable = () => {
  const [course, setCourse] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexCourseRequest({
        dispatch,
        params: { ...params },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setCourse(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleDeleteRequest = id => {
    setOnRequest(true);
    deleteCourseRequest(id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Curso eliminado con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => delayMethod(() => setModalShow(false))
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'edit':
        history.push(`/courses/${item.id}/edit`);
        break;
      case 'show':
        history.push(`/courses/${item.id}`);
        break;
      case 'destroy':
        setModalItem(item);
        setModalShow(true);
        setModalBody(`¿Estás seguro que deseas eliminar ${item.name}?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'course_modality':
        return { sort_course_modality: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };
  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columnCourse(handleActions)}
        handleSortCase={sortColumnCase}
        data={course}
        moreData={moreData}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        withStartDate
        withEndDate
        rangePicker
        onRowClicked={row => handleActions(row, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title="Eliminar Curso"
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => handleDeleteRequest(modalItem.id)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default CourseDatatable;
