import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'N°',
    selector: 'correlative_identification',
    sortable: true,
    minWidth: '40px',
    maxWidth: '60px'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: 1
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: 1
  },
  {
    name: 'CARGO',
    selector: 'job_title_name',
    cell: item => item.job_title.label,
    sortable: true,
    grow: 1
  },
  {
    name: 'ÁREA',
    selector: 'job_management_name',
    cell: item => item.job_management.label,
    sortable: true,
    grow: 1
  },
  {
    name: 'VACANTES',
    selector: 'vacancies',
    sortable: true,
    grow: 1
  },
  {
    name: 'TIPO',
    selector: 'process_type',
    cell: item => item.translated_process_type,
    sortable: true,
    grow: 1
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      let itemColor = '';
      switch (item.status) {
        case 'deserted':
          itemColor = 'info';
          break;
        case 'suspended':
          itemColor = 'warning';
          break;
        case 'active':
          itemColor = 'primary';
          break;
        case 'closed':
          itemColor = 'danger';
          break;
        default:
          itemColor = '';
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p data-tag="allowRowEvents" onClick={() => clickHandler(item, 'show')} className={`mt-3 text-${itemColor}`}>
          {item.translated_status}
        </p>
      );
    },
    center: true,
    sortable: true,
    grow: 1
  },
  {
    name: 'CANTIDAD DE POSTULANTES',
    selector: 'candidates_count',
    sortable: true,
    grow: 1
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="SelectionProcess"
        clickHandler={clickHandler}
        withShow
        withEdit
        disableDestroy={item.candidates_count !== 0}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    grow: 1,
    minWidth: '123px'
  }
]);

export default columns;
