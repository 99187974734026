import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import DirectoryNodeIndex from '../../Shared/DirectoryNode/DirectoryNodeIndex';

const ProfileDirectoryIndex = () => {
  const { currentEmployee } = useSelector(state => state.auth);
  return (
    <>
      <Row className="mt-3 mb-3">
        <Col xs={12} className="mb-3">
          <h2 className="m-top mb-3 mt-4">Documentos</h2>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={12} className="mb-3">
          {currentEmployee && <DirectoryNodeIndex employeeId={currentEmployee} />}
        </Col>
      </Row>
    </>
  );
};

export default ProfileDirectoryIndex;
