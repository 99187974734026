import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, DatatableEmployeeName } from '../../components';
import { handleDaysShow } from '../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'MOTIVO',
    selector: 'absence_type',
    cell: item => item.translated_absence_type,
    sortable: true,
    grow: '2'
  },
  {
    name: 'FORMATO',
    selector: 'absence_type_format',
    cell: item => item.translated_absence_type_format,
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RETORNO',
    selector: 'reincorporation_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns resource="Absence" item={item} clickHandler={clickHandler} withShow={false} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
