import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { indexJointCommitteeRequest, deleteJointCommitteeRequest } from '../../../requests/jointCommittees';
import { ComponentDataTable, DefaultModal } from '../../../components';

const columns = memoize(() => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DIRECCIÓN',
    selector: 'address',
    cell: item => item.full_address,
    sortable: true,
    grow: '1'
  }
]);

class JointCommitteeDataTable extends Component {
  state = {
    onRequest: true,
    jointCommittees: [],
    amount: 0,
    moreData: false,
    modalShow: false,
    modalItem: null,
    modalBody: ''
  };

  handleRequest = async params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    indexJointCommitteeRequest({
      dispatch,
      params,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const jointCommittees = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      jointCommittees,
      amount,
      onRequest: false
    });
  };

  removeJointCommittee = jointCommitteeId => {
    const { dispatch } = this.props;
    deleteJointCommitteeRequest(jointCommitteeId, {
      dispatch,
      successCallback: this.handleSuccessRemove
    });
  };

  handleButtonClick = (item, action) => {
    const { history } = this.props;
    switch (action) {
      case 'show':
        history.push(`/profile/joint_committees/${item.id}`);
        break;
      case 'edit':
        history.push(`/joint_committees/${item.id}/edit`);
        break;
      case 'destroy':
        this.setState({
          modalShow: true,
          modalItem: item,
          modalBody: '¿Estás seguro que deseas eliminar este comité?'
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeJointCommittee(modalItem.id);
  };

  handleSuccessRemove = () => {
    this.handleModalClose();
    const { moreData } = this.state;
    this.setState({ moreData: !moreData });
  };

  render() {
    const { jointCommittees, amount, onRequest, moreData, modalShow, modalBody } = this.state;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns()}
          data={jointCommittees}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
          onRowClicked={item => this.handleButtonClick(item, 'show')}
          pointerOnHover
        />
        <DefaultModal
          title="Eliminar Comité"
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      </>
    );
  }
}

export default withRouter(connect()(JointCommitteeDataTable));
