import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';
import { handleDaysShow } from '../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'MOTIVO',
    selector: 'absence_type',
    cell: item => item.translated_absence_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RETORNO',
    selector: 'reincorporation_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="Absence" clickHandler={clickHandler} withShow={false} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
