/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ActiveDotAttendance, BasicActionBtns, DatatableEmployeeName } from '../index';

const columns = memoize((clickHandler = true) => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE HOY',
    selector: 'date',
    sortable: false,
    grow: '1',
    center: true
  },
  {
    name: 'ASISTENCIA INGRESADA',
    selector: 'attendance',
    cell: item => <ActiveDotAttendance item={item} />,
    sortable: false,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns
        item={item}
        resource="Employee"
        clickHandler={clickHandler}
        withEdit={false}
        withDestroy={false}
        withShow
      />
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '123px'
  }
]);

export default columns;
