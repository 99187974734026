import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, ApprovementStatus, DatatableEmployeeName } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'employee[fullName]',
    cell: item => (
      <DatatableEmployeeName
        fileName="fileInfo"
        fileUrl="fileUrl"
        name="fullName"
        item={item.employee}
        onClick={() => clickHandler(item, 'show')}
      />
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'HABERES',
    selector: 'assetsTotal',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DESCUENTOS',
    selector: 'discountsTotal',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL LÍQUIDO',
    selector: 'liquid',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FIRMA',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);
