import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';

import { FormikInput, InputSelect, NestedAttributes, RegionCommune, PhoneWithCode } from '../../components';
import { debounceIndexEmployeesRequest, indexEmployeesRequest } from '../../requests/employees';

const EmployeeJointCommitteeInput = ({
  defaultOptions,
  defaultValue,
  employeesRequest,
  errors,
  touched,
  index,
  setFieldValue
}) => (
  <>
    <Col md={6}>
      <Field name={`jointCommittee[employeeJointCommitteesAttributes][${index}][employeeId]`}>
        {({ field }) => (
          <InputSelect
            {...field}
            abbr
            label="Empleado"
            placeholder="Seleccionar Empleado"
            defaultOptions={defaultOptions}
            value={defaultValue}
            request={employeesRequest}
            onChange={data => setFieldValue(field.name, data.value)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
    <Col md={5}>
      <Field name={`jointCommittee[employeeJointCommitteesAttributes][${index}][jobTitle]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            label="Cargo"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
  </>
);

class JointCommitteeForm extends Component {
  state = {
    initialEmployees: []
  };

  componentDidMount() {
    this.fetchInitialEmployees();
  }

  addEmployee = () => {
    const { initialEmployees } = this.state;
    const { errors, touched, values, setFieldValue } = this.props;
    const { jointCommittee } = values;
    const { employeeJointCommitteesAttributes } = jointCommittee;

    const mapResults = employeeJointCommitteesAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      const defaultValue = this.initialEmployeeValue(body);
      return (
        <EmployeeJointCommitteeInput
          key={`employee-${index.toString()}`}
          defaultOptions={initialEmployees}
          defaultValue={defaultValue}
          employeesRequest={this.fetchEmployees}
          errors={errors}
          touched={touched}
          index={index}
          setFieldValue={setFieldValue}
        />
      );
    });

    mapResults.push(
      <>
        <Col md={6} className="sample-row">
          <InputSelect label="Empleado" placeholder="Seleccionar Empleado" isDisabled />
        </Col>
        <Col md={5} className="sample-row">
          <FormikInput label="Cargo" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={employeeJointCommitteesAttributes}
          setFieldValue={setFieldValue}
          valuePath="jointCommittee[employeeJointCommitteesAttributes]"
          newAttributes={{ employeeId: '', jobTitle: '' }}
        />
      </>
    );
  };

  fetchInitialEmployees = () => {
    const { dispatch } = this.props;
    indexEmployeesRequest({
      dispatch,
      params: {
        sort_column: 'name',
        paginate: false,
        is_dt: false,
        active: true
      },
      successCallback: response => {
        this.setState({ initialEmployees: response.data.data });
      }
    });
  };

  fetchEmployees = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        query: inputValue,
        is_dt: false,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  initialEmployeeValue = value => {
    const { jointCommittee } = this.props;
    const { employeeJointCommitteesAttributes: attributes } = jointCommittee;

    if (value && value.id) {
      const vAttribute = attributes.filter(e => {
        return value.employeeId === e.employeeId;
      });
      if (vAttribute.length) {
        return { label: vAttribute[0].fullName, value: value.employeeId };
      }
    }
    return undefined;
  };

  render() {
    const { onHide, submitVariant, errors, touched, action, values, setFieldValue, isSubmitting } = this.props;
    const {
      jointCommittee: { phoneCountryCode }
    } = values;
    const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

    return (
      <Form>
        <Row>
          <Col md={10}>
            <Field name="jointCommittee[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  onBlur={() => setFieldValue(field.name, values.jointCommittee.name.trim())}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="jointCommittee[code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Código"
                  onBlur={() => setFieldValue(field.name, values.jointCommittee.code.trim())}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="jointCommittee[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Dirección"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="jointCommittee[addressNumber]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Número"
                  inputType="number"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="jointCommittee[addressFloor]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Piso"
                  inputType="number"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="jointCommittee[addressOffice]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Oficina"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <RegionCommune modelKey="jointCommittee" />
          <Col md={6}>
            <PhoneWithCode
              label="Teléfono"
              countryCodeField="jointCommittee[phoneCountryCode]"
              phoneField="jointCommittee[phone]"
              workPhoneCountryCode={phoneCountryCode}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
        <h4 className="text-uppercase">Miembros</h4>
        {this.addEmployee()}
        <Row className="d-flex justify-content-end my-3">
          <Col md={3}>
            <Button
              type="submit"
              className="mb-5"
              disabled={isSubmitting}
              variant={submitVariant}
              block
              onClick={onHide}
            >
              {btnMessage}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { jointCommittee } = props;
  return { jointCommittee };
};

const validationSchema = Yup.object().shape({
  jointCommittee: Yup.object().shape(
    {
      address: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      addressFloor: Yup.number().nullable(),
      addressNumber: Yup.number().nullable(),
      addressOffice: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      code: Yup.string()
        .required('Debes ingresar un código')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      communeId: Yup.string().when('regionId', {
        is: val => val,
        then: Yup.string().required('Debes seleccionar una comuna'),
        otherwise: Yup.string().nullable()
      }),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      employeeJointCommitteesAttributes: Yup.array().of(
        Yup.object().shape({
          jobTitle: Yup.string()
            .max(120, 'Deben ser menos que 120 caracteres')
            .alphanumeric('Deben ser caracteres alfanuméricos')
            .nullable(),
          employeeId: Yup.string().required('Debes ingresar un empleado')
        })
      ),
      phone: Yup.string()
        .when('phoneCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .nullable(),
      phoneCountryCode: Yup.string().when('phone', {
        is: val => val,
        then: Yup.string().required('Debes seleccionar un código'),
        otherwise: Yup.string().nullable()
      }),
      regionId: Yup.number().nullable()
    },
    [['phone', 'phoneCountryCode']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(connect()(JointCommitteeForm));
