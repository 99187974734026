import React, { useState, useEffect } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { AbilityContext } from '../../config/abilityContext';
import { Icon, LinkBtn } from '../../components';

import { useSetTab } from '../../services/hooks';
import ConceptsIndex from '../Concepts/ConceptsIndex';

const RemunerationsSettings = ({ location }) => {
  const [moreData, setMoreData] = useState(false);
  const [key, setKey] = useSetTab('concepts-tab', location);
  const [keyModel, setKeyModel] = useState('Balance');
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    switch (key) {
      case 'concepts-tab':
        setKeyModel('Balance');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  }, [key]);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Configuraciones</h2>
        </Col>
        {ability.can('create_concept', keyModel) && (
          <Col md={2}>
            <LinkBtn variant="primary" className="mt-3" block to="/remunerations_settings/new">
              Nuevo
            </LinkBtn>
          </Col>
        )}
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('read_concept', 'Balance') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="concepts-tab">
                <Icon icon="cube" />
                Mantenedor de conceptos
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="concepts-tab">
            <ConceptsIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default RemunerationsSettings;
