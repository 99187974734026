import React, { useState, useEffect } from 'react';
import { withFormik, Form, getIn } from 'formik';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Row, Col, Tab, Nav } from 'react-bootstrap';
import ProfileTab from './ProfileTab';
import { Icon, FocusError } from '../../../components';
import ContractTab, { yupContract } from './ContractTab';
import { yupFamilyGroup } from './FamilyGroupTab';
import { useScrollPosition } from '../../../services/hooks';

const EmployeeForm = props => {
  const {
    action,
    admin,
    adminNew,
    newEmployee,
    canCreateAsset,
    canCreateDiscount,
    canDestroyAsset,
    canDestroyDiscount,
    canFamilyGroup,
    canPrevision,
    contractTabActive,
    currentCompany,
    employee,
    signSubstitutes,
    errors,
    isSubmitting,
    onHide,
    setFieldTouched,
    setFieldValue,
    submitVariant,
    touched,
    values,
    withContract
  } = props;

  const [showTabErrors, setShowTabErrors] = useState(false);
  const [employeeTab, setEmployeeTab] = useState(contractTabActive ? 'contract' : 'profile');
  const [accordion, setAccordion] = useState('profile');
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const { employee: employeeErrors = {} } = errors;
  const errorKeys = Object.keys(employeeErrors);
  const location = useLocation();
  const [scrollPos] = useScrollPosition('y');
  const [localSignSubstitutes, setLocalSignSubstitutes] = useState([]);
  const employeeErrorKeys = errorKeys.filter(x => x !== 'contract' && x !== 'employeePrevisionAttributes');

  const syncSignSubtitutes = () => {
    if (localSignSubstitutes !== signSubstitutes) {
      setLocalSignSubstitutes(signSubstitutes);
    }
  };

  useEffect(syncSignSubtitutes, [signSubstitutes]);

  return (
    <Form>
      <FocusError setEmployeeTab={setEmployeeTab} setAccordion={setAccordion} errorKeys={errorKeys} />
      <Tab.Container id="employee-form-tabs" activeKey={employeeTab} onSelect={k => setEmployeeTab(k)}>
        <Nav
          variant="pills"
          fill
          className={`employee ${admin && 'nav-admin'} 
                      ${scrollPos > 5 && admin ? 'hidden-nav-admin' : ''} 
                      ${newEmployee && 'employee-new'} ${scrollPos >= 35 && !newEmployee && 'shadow-bottom'}`}
        >
          <Nav.Item>
            <Nav.Link eventKey="profile" location={location}>
              <Icon
                icon={showTabErrors && employeeErrorKeys.length ? 'alert-circle' : 'person'}
                style={{ color: showTabErrors && employeeErrorKeys.length ? 'red' : 'white' }}
              />
              Ficha
            </Nav.Link>
          </Nav.Item>
          {!admin && (
            <>
              {withContract && (
                <Nav.Item>
                  <Nav.Link eventKey="contract" onClick={() => setFieldValue('employee[newContract]', true)}>
                    <Icon
                      icon={showTabErrors && errorKeys.includes('contract') ? 'alert-circle' : 'document'}
                      style={{ color: showTabErrors && errorKeys.includes('contract') ? 'red' : 'white' }}
                    />
                    Contractual
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="signature" disabled>
                  <Icon icon="create" />
                  Firma
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="assistance" disabled>
                  <Icon icon="finger-print" />
                  Asistencias
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="documents" disabled>
                  <Icon icon="folder-open" />
                  Documentos
                </Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>
        <Tab.Content className="main-div mt-3">
          <Tab.Pane eventKey="profile" mountOnEnter>
            <ProfileTab
              currentCompany={currentCompany}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              employee={employee}
              signSubstitutes={localSignSubstitutes}
              values={values}
              action={action}
              admin={admin}
              adminNew={adminNew}
              setEmployeeTab={setEmployeeTab}
              setAccordion={setAccordion}
              accordion={accordion}
              canFamilyGroup={canFamilyGroup}
              showTabErrors={showTabErrors}
              errorKeys={errorKeys}
            />
          </Tab.Pane>
          {!admin && withContract && (
            <Tab.Pane eventKey="contract" mountOnEnter>
              <ContractTab
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                contract={employee.contract}
                employee={employee}
                canCreateAsset={canCreateAsset}
                canCreateDiscount={canCreateDiscount}
                canDestroyAsset={canDestroyAsset}
                canDestroyDiscount={canDestroyDiscount}
                canPrevision={canPrevision}
                setAccordion={setAccordion}
                accordion={accordion}
              />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
      <Row className="d-flex justify-content-end mt-3 mb-5">
        <Col md={3}>
          <Button
            type="submit"
            variant={submitVariant}
            block
            disabled={isSubmitting}
            onClick={() => {
              setShowTabErrors(true);
              return onHide;
            }}
          >
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    active,
    address,
    addressNumber,
    addressApt,
    birthdate,
    careerIds,
    civilState,
    collaborator,
    communeId,
    communeName,
    company,
    contract,
    countryId,
    educationLevel,
    email,
    emergencyContactCountryCode,
    emergencyContactName,
    emergencyContactPhone,
    emergencyContactRelation,
    employeeCertificationsAttributes,
    familyGroupsAttributes,
    fileInfo,
    firstLastName,
    genderId,
    identificationType,
    languageIds,
    name,
    nationalIdentification,
    nationalIdExpirationDate,
    phone,
    phoneCountryCode,
    profileIds,
    regionId,
    secondLastName,
    senceLevel,
    signSubstituteId,
    workEmail,
    workPhone,
    workPhoneCountryCode
  } = props.employee;

  let setEmployeeCertifications = employeeCertificationsAttributes;
  const employeeCertifications = [];
  if (employeeCertificationsAttributes.length > 0) {
    employeeCertificationsAttributes.map(employeeCertification => {
      return employeeCertifications.push({
        ...employeeCertification,
        certificate: getIn(employeeCertification.certificateInfo, 'fileSignature')
      });
    });
    setEmployeeCertifications = employeeCertifications;
  }

  const { syndicateId } = contract;
  let currentContract = contract;
  if (contract.id) {
    currentContract = {
      ...contract,
      syndicateId: syndicateId || '',
      progressiveVacationsCertificate: getIn(contract.progressiveFileInfo, 'fileSignature'),
      disabilityCertificate: getIn(contract.disabilityFileInfo, 'fileSignature')
    };
  }

  let familyGroupValue = false;
  if (familyGroupsAttributes.length > 0) {
    familyGroupValue = familyGroupsAttributes[0].id !== undefined;
  }

  return {
    employee: {
      id,
      active,
      address,
      addressNumber,
      addressApt,
      birthdate,
      careerIds,
      civilState,
      collaborator,
      communeId,
      communeName,
      companyId: props.currentCompany ? props.currentCompany : company.value,
      contract: currentContract,
      countryId,
      educationLevel,
      email,
      emergencyContactCountryCode,
      emergencyContactName,
      emergencyContactPhone,
      emergencyContactRelation,
      employeeCertificationsAttributes: setEmployeeCertifications,
      familyGroupsAttributes,
      firstLastName,
      genderId,
      identificationType,
      image: getIn(fileInfo, 'fileSignature'),
      languageIds,
      name,
      nationalIdentification,
      nationalIdExpirationDate,
      newContract: currentContract.id,
      newFamilyGroup: familyGroupValue,
      phone,
      phoneCountryCode: phoneCountryCode || '+56',
      profileIds,
      regionId,
      secondLastName,
      senceLevel,
      signSubstituteId,
      workEmail,
      workPhone,
      workPhoneCountryCode: workPhoneCountryCode || '+56'
    }
  };
};

const validationSchema = props => {
  return Yup.object().shape({
    employee: Yup.object().shape({
      image: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          'FILE_SIZE',
          'La imagen cargada excede el tamaño maximo permitido (5mb).',
          value => !value?.size || (value && value?.size <= 5242880)
        )
        .test(
          'FILE_FORMAT',
          'El archivo cargado tiene un formato no compatible.',
          value =>
            (!value && !value?.type) ||
            (value && ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(value?.type))
        )
        .test(
          'REPLACE_LETTER',
          'El archivo no debe tener la  letra Ñ en su nombre',
          value => (!value && typeof (value?.name === 'undefined')) || (value && !value?.name.includes('ñ'))
        ),
      active: Yup.boolean(),
      address: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      addressNumber: Yup.number().nullable(),
      addressApt: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      birthdate: Yup.string().nullable(),
      civilState: Yup.string().nullable(),
      communeId: Yup.number().nullable(),
      companyId: Yup.string().required('Debes ingresar una empresa'),
      countryId: Yup.string().nullable(),
      identificationType: Yup.string().required('Debes seleccionar un tipo de documento'),
      educationLevel: Yup.string().nullable(),
      email: Yup.string()
        .required('Debes ingresar un e-mail personal')
        .email('Debes ingresar un e-mail válido')
        .notOneOf([Yup.ref('workEmail'), null], 'Email personal debe ser distinto al email laboral'),
      emergencyContactCountryCode: Yup.string(),
      emergencyContactName: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      emergencyContactPhone: Yup.string()
        .when('emergencyContactCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .when('phone', {
          is: val => val,
          then: Yup.string().notOneOf(
            [Yup.ref('phone'), null],
            'Teléfono de emergencia debe ser distinto al número personal'
          )
        }),
      emergencyContactRelation: Yup.string().nullable(),
      firstLastName: Yup.string()
        .required('Debes ingresar un apellido')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      gender: Yup.string().nullable(),
      languageIds: Yup.array().nullable(),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      nationalIdentification: Yup.string()
        .required('Debes ingresar un número de identificación')
        .when('identificationType', (identificationType, schema) =>
          identificationType === 'rut'
            ? schema.rut('Debe ingresar un RUT válido')
            : schema.alphanumeric('Deben ser caracteres alfanuméricos')
        ),
      nationalIdExpirationDate: Yup.string().nullable(),
      phone: Yup.string()
        .when('phoneCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .when('workPhone', {
          is: val => val,
          then: Yup.string().notOneOf(
            [Yup.ref('workPhone'), null],
            'Teléfono personal debe ser distinto al teléfono laboral'
          )
        }),
      phoneCountryCode: Yup.string(),
      postTitle: Yup.string().nullable(),
      regionId: Yup.number().nullable(),
      profileIds: Yup.array().nullable(),
      secondLastName: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      senceLevel: Yup.string().nullable(),
      signSubstituteId: Yup.string()
        .test({
          name: 'selectedSubstituteCantSing',
          exclusive: true,
          message: 'el colaborador seleccionado no posee permisos para firmar solicitudes de otros colaboradores',
          test: value => {
            const testSignSubstitutes = props.signSubstitutes;
            if (typeof value !== 'undefined' && typeof testSignSubstitutes !== 'undefined') {
              const selectedSignSubstitute = testSignSubstitutes.find(element => element.id === parseInt(value, 10));
              return selectedSignSubstitute.canSign;
            }
            return true;
          }
        })
        .nullable(),
      workEmail: Yup.string()
        .email('Debes ingresar un e-mail válido')
        .nullable(),
      workPhone: Yup.string().when('workPhoneCountryCode', {
        is: val => val === '+56',
        then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
        otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
      }),
      workPhoneCountryCode: Yup.string(),
      contract: Yup.object()
        .shape()
        .when('newContract', {
          is: val => val,
          then: yupContract
        }),
      employeeCertificationsAttributes: Yup.array().of(
        Yup.object().shape({
          certificationId: Yup.number().nullable(true),
          certificate: Yup.mixed().when('certificationId', {
            is: val => val,
            then: Yup.mixed()
              .nullable()
              .notRequired()
              .test(
                'FILE_SIZE',
                'El archivo cargado excede el tamaño maximo permitido (5mb).',
                value => !value?.size || (value && value?.size <= 5242880)
              )
              .test(
                'FILE_FORMAT',
                'El archivo cargado tiene un formato no compatible.',
                value => (!value?.type && value?.type !== '') || (value && ['application/pdf'].includes(value?.type))
              )
          }),
          endDate: Yup.string().nullable()
        })
      ),
      familyGroupsAttributes: Yup.array().when('newFamilyGroup', {
        is: val => val,
        then: Yup.array().of(yupFamilyGroup)
      })
    })
  });
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(EmployeeForm);
