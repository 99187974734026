import React from 'react';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';

import { ButtonTooltip, Icon } from '../../components';
import { AbilityContext } from '../../config/abilityContext';

export default memoize(clickHandler => {
  const ability = useAbility(AbilityContext);
  return [
    {
      name: 'NOMBRE',
      selector: 'name',
      sortable: true,
      grow: '3'
    },
    {
      name: 'TIPO',
      selector: 'scheduled_type',
      cell: item => item.translated_scheduled_type,
      sortable: true,
      grow: '2'
    },
    {
      name: 'TRABAJADORES',
      selector: 'employees_count',
      center: true,
      sortable: true,
      grow: '2'
    },
    {
      name: 'FECHA INICIO',
      selector: 'start_date',
      center: true,
      sortable: true,
      grow: '2'
    },
    {
      name: 'FECHA TÉRMINO',
      selector: 'end_date',
      center: true,
      sortable: true,
      grow: '2'
    },
    {
      name: 'ACCIONES',
      cell: item => (
        <>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
            disabled={ability.cannot('read', 'ScheduledGroup')}
          >
            <Icon icon="eye" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'edit')}
            variant="circle-warning"
            className="btn-circle"
            size="sm"
            text="Editar"
            disabled={ability.cannot('update', 'ScheduledGroup')}
          >
            <Icon icon="pencil" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
            disabled={ability.cannot('destroy', 'ScheduledGroup')}
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      right: true,
      grow: '1',
      minWidth: '123px'
    }
  ];
});
