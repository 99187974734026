import React from 'react';
import { Col } from 'react-bootstrap';

import { InputSelect } from '../Utils/Select';
import { ButtonTooltip } from '../Utils/Tooltips';
import NestedAttributes from '../Utils/NestedAttributes';
import Icon from '../Icons';
import ReviewerNestedFields from './ReviewerNestedFields';

const ReviewerFields = props => {
  const mapChilds = reviewerAttributes => {
    const { attributeEmployeeReviewers, attributeReviewerIds, attributesPath, setFieldValue, errors, touched } = props;
    const mapResults = reviewerAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <ReviewerNestedFields
          attributeReviewerIds={attributeReviewerIds}
          attributeEmployeeReviewers={attributeEmployeeReviewers}
          attributesPath={attributesPath}
          setFieldValue={setFieldValue}
          reviewerTemplate={body}
          index={index}
          key={`reviewer-field-${index.toString()}`}
          errors={errors}
          touched={touched}
        />
      );
    });

    mapResults.push(
      <>
        <Col md={1} className="sample-row d-flex justify-content-center align-items-center">
          <span className="dot-workflow dot-dark" />
        </Col>
        <Col md={1} className="sample-row d-flex justify-content-center align-items-center">
          <ButtonTooltip variant="circle-dark" className="mr-2 mt-3 btn-circle" text="Aprobar">
            <Icon icon="checkmark-circle" />
          </ButtonTooltip>
        </Col>
        <Col md={1} className="sample-row d-flex justify-content-center align-items-center">
          <ButtonTooltip variant="circle-dark" className="mr-2 mt-3 btn-circle" text="Firmar">
            <Icon icon="create" />
          </ButtonTooltip>
        </Col>
        <Col md={7} className="sample-row">
          <InputSelect label="Responsables" placeholder="Seleccionar Responsables" isDisabled />
        </Col>
        <Col md={1} className="sample-row ml-0 pl-0 mt-3">
          <ButtonTooltip variant="circle-primary" className="advance-search" text="Búsqueda Avanzada">
            <Icon className="w-100 h-100" icon="people-circle" />
          </ButtonTooltip>
        </Col>
      </>
    );
    return mapResults;
  };

  const { attributesPath, defaultReviewer, reviewerAttributes, setFieldValue } = props;
  const mapInputs = mapChilds(reviewerAttributes);

  return (
    <NestedAttributes
      deleteLastOne={false}
      mapInputs={mapInputs}
      arrayValues={reviewerAttributes}
      setFieldValue={setFieldValue}
      valuePath={attributesPath}
      newAttributes={defaultReviewer}
    />
  );
};

export default ReviewerFields;
