import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import './style.scss';

const BasicDropdown = ({ variant, titleDrop, items, noArrow, right, responsive }) => (
  <Dropdown>
    <Dropdown.Toggle
      className={`${noArrow ? 'dropdown-no-arrow' : ''} ${right ? 'float-right' : ''} ${responsive &&
        'mb-4 mt-1 mr-2'}`}
      variant={variant}
      id="dropdown-basic"
    >
      {titleDrop}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {items.map(item => (
        <Dropdown.Item href={item.path} key={item.key} {...item.props}>
          {item.icon} {item.title}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

BasicDropdown.propTypes = {
  variant: PropTypes.string,
  titleDrop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  noArrow: PropTypes.bool
};

BasicDropdown.defaultProps = {
  variant: 'info',
  noArrow: false
};

export default BasicDropdown;
