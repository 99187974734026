import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexGroupRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/groups', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showGroupRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/groups/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createGroupRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', '/groups', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const updateGroupRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/groups/${id}/`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const deleteGroupRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/groups/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexGroupRequest = AwesomeDebouncePromise(indexGroupRequest, 300);
