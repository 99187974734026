import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../../actions/utils';

import { createVacationRequest } from '../../../requests/vacations';
import VacationForm from './VacationForm';

const basicVacation = {
  endDate: '',
  middayEnd: false,
  middayStart: false,
  startDate: '',
  vacationType: '',
  quantityDaysAvailable: '-'
};

const VacationNew = ({ onSuccess, employeeId, rrhh = false }) => {
  const dispatch = useDispatch();

  const handleCreateRequest = (vacation, setSubmitting = () => null) => {
    createVacationRequest({
      dispatch,
      params: snakeCaseKeys(vacation),
      successCallback: () => {
        onSuccess();
        dispatch(sendAlert({ kind: 'success', message: 'Solicitud creada con éxito' }));
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Row>
      <Col>
        <VacationForm
          vacation={{ ...basicVacation, employeeId, directApprove: rrhh }}
          action="new"
          formRequest={handleCreateRequest}
          rrhh={rrhh}
        />
      </Col>
    </Row>
  );
};

export default VacationNew;
