import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import Icon from '../../Icons';
import './style.scss';

const handleDefaultMultiValue = (value, defaultMultiValues) => {
  if (defaultMultiValues && value && value === defaultMultiValues) return defaultMultiValues;
  return undefined;
};

const findDefaultValue = (value, defaultValue, options, props) => {
  const { isMulti, defaultMultiValues } = props;
  if (isMulti) {
    return handleDefaultMultiValue(value, defaultMultiValues);
  }
  if (defaultValue && value && defaultValue !== '' && value !== '' && value === defaultValue) {
    return options.map(item => {
      const vAttribute = item.options.filter(e => defaultValue === e.value);
      return vAttribute[0];
    });
  }
  if (value === '') return null;
  return undefined;
};

const FormikSelectMOptions = ({
  abbr,
  customOption,
  defaultValue,
  delayHide,
  delayShow,
  direction,
  error,
  helpText,
  isDisabled,
  label,
  margin,
  name,
  options,
  tooltipText,
  touched,
  value,
  setFieldTouched,
  labelClass,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group
      controlId={label}
      className={`${isDisabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}
    >
      {label && (
        <Form.Label className={`d-flex align-items-center ${labelClass}`}>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon="help-circle" height="15px" width="15px" />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <Select
        className={`${isOpen ? 'border-on ' : ''}`}
        placeholder="Buscar..."
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => {
          if (setFieldTouched) setFieldTouched();
          setIsOpen(false);
        }}
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay opciones'}
        options={options}
        isSearchable
        name={name}
        value={findDefaultValue(value, defaultValue, options, props)}
        isDisabled={isDisabled}
        {...props}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelectMOptions.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string,
  labelClass: PropTypes.string
};

FormikSelectMOptions.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: '',
  labelClass: ''
};

export default FormikSelectMOptions;
