import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexDocumentTemplateRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/document_templates', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showDocumentTemplateRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/document_templates/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createDocumentTemplateRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/document_templates', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateDocumentTemplateRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/document_templates/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteDocumentTemplateRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/document_templates/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexDocumentTemplatesRequest = AwesomeDebouncePromise(indexDocumentTemplateRequest, 300);
