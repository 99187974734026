import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexDncRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/dncs', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showDncRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/dncs/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createDncRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', '/dncs', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const updateDncRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/dncs/${id}/`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const deleteDncRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/dncs/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const dncCardsRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/dncs/dnc_cards', { dispatch, successCallback });

export const debounceIndexDncRequest = AwesomeDebouncePromise(indexDncRequest, 300);
