import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal } from '../../components';
import { indexPayrollGroupsRequest, deletePayrollGroupRequest } from '../../requests/payrollGroups';
import columns from './Columns';

const PayrollGroupDataTable = ({ payrollProcessId }) => {
  const [payrollGroups, setPayrollGroups] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRequest = async params => {
    setOnRequest(true);
    indexPayrollGroupsRequest(payrollProcessId, {
      dispatch,
      params,
      successCallback: response => setPayrollGroups(response.data.data),
      callback: () => setOnRequest(false)
    });
  };

  const handleDeleteRequest = payrollGroupId => {
    setOnRequest(true);
    deletePayrollGroupRequest(payrollProcessId, payrollGroupId, {
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Prenómina eliminada con éxito' }));
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleModalConfirm = () => handleDeleteRequest(modalItem.id);

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        if (item.status === 'pending') return;
        history.push(`/payroll_processes/${payrollProcessId}/payroll_groups/${item.id}`);
        break;
      case 'edit':
        history.push(`/payroll_processes/${payrollProcessId}/payroll_groups/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar el grupo de liquidaciones '${item.name}'?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        pointerOnHover
        preName="payroll_groups"
        resourceRequest={handleRequest}
        columns={columns(handleActions)}
        onRowClicked={item => handleActions(item, 'show')}
        handleSortCase={sortColumnCase}
        data={payrollGroups}
        moreData={moreData}
        onRequest={onRequest}
      />
      <DefaultModal
        title="Eliminar Grupo de Liquidaciones"
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default PayrollGroupDataTable;
