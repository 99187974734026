import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikSelect, FormikTimePicker, FormikDatePicker } from '../index';
import timeTypes from './formOptions';
import { delayMethod } from '../../services/utils';

const AttendanceForm = props => {
  const { errors, touched, setFieldValue, setFieldTouched, values, handleModalClose, onHide } = props;
  const { attendance: vAttendance } = values;
  const { timeType: vTimeType } = vAttendance;

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <Field name="attendance[date]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={() => false}
                  label="Fecha"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[parsedTime]">
              {({ field }) => (
                <FormikTimePicker
                  {...field}
                  abbr
                  label="Hora"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeFormat="h:mm aa"
                  placeholder="9:00 AM"
                  onChange={date => {
                    setFieldValue(
                      `attendance[time]`,
                      date.toLocaleTimeString('en-ES', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })
                    );
                    setFieldValue(
                      field.name,
                      date.toLocaleTimeString('en-ES', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })
                    );
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[timeType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Hora"
                  placeholder="Seleccionar Tipo de Hora"
                  options={timeTypes}
                  defaultValue={vTimeType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" onClick={onHide}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { attendance } = props;

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    date: Yup.string().required('Debes escoger una fecha'),
    time: Yup.string().required('Debes escoger la hora'),
    timeType: Yup.string().required('Debes escoger el tipo de hora'),
    employeeId: Yup.string().required('Debe haber un empleado asociado')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(AttendanceForm);
