import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexScheduledGroupsRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/scheduled_groups', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showScheduledGroupRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/scheduled_groups/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createScheduledGroupRequest = ({ dispatch, params, formData, successCallback, callback }) => {
  ApiService.request('post', '/scheduled_groups', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });
};

export const updateScheduledGroupRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/scheduled_groups/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const deleteScheduledGroupRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/scheduled_groups/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexScheduledGroupsRequest = AwesomeDebouncePromise(indexScheduledGroupsRequest, 300);
