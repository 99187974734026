export const modality = [
  { label: 'Remoto', value: 'remote' },
  { label: 'Presencial', value: 'on_site' },
  { label: 'Semi Presencial', value: 'mixed' },
  { label: 'E-Learning', value: 'e_learning' }
];

export const activity = [
  { label: 'Franquicia Normal', value: 'normal_franchise' },
  { label: 'Pre Contrato', value: 'pre_contract' },
  { label: 'Post Contrato', value: 'post_contract' }
];

export const statuses = [
  { label: 'No publicado', value: 'no_published' },
  { label: 'Publicado', value: 'published' },
  { label: 'En curso ', value: 'in_process' },
  { label: 'Terminado', value: 'finished' }
];

export const identificationTypes = [
  { label: 'RUT', value: 'rut' },
  { label: 'Pasaporte', value: 'passport' },
  { label: 'Otra Identificación', value: 'other_id' }
];
