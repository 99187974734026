import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Form as BSForm } from 'react-bootstrap';
import * as Yup from 'yup';
import { useAbility } from '@casl/react';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { delayMethod } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import categories from './FormOptions';
import { FormikSelect, FormikInput, UploadImage, InputSelect, FormikRangePicker } from '../../components';
import '../../services/yupCustomMethods';

const BenefitForm = ({
  benefit,
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  hideModal,
  isSubmitting,
  action,
  handleDelete,
  isDelete,
  handleDeleteRequest
}) => {
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const { startDate, endDate } = values.benefit;

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: element.name,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const handleNewJobManagement = (data, field) => {
    const newData = data.map(element => element.value);
    if (!newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const scrollToBottom = () => {
    const scrollDelete = document.getElementsByClassName('modal-body');
    scrollDelete[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(scrollToBottom, [isDelete]);

  return (
    <Form className="form-benefit">
      <Row>
        <Col md={6}>
          <Field name="benefit[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre del beneficio"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="benefit[jobManagementIds]">
            {({ field }) => (
              <InputSelect
                {...field}
                isMulti
                label="Área del Beneficio"
                placeholder="Todas"
                values={values.benefit}
                model={[benefit, 'jobManagement']}
                request={fetchJobManagments}
                onChange={data => handleNewJobManagement(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3 mb-md-0">
          <Field name="benefit[benefitsImage]">
            {({ field }) => (
              <>
                <BSForm.Label className="d-md-block d-none">Imagen</BSForm.Label>
                <UploadImage
                  {...field}
                  name="Agregar imagen"
                  imageUrl={getIn(benefit.benefitsImage, 'fileUrl')}
                  onChange={image => setFieldValue(field.name, image)}
                  helpText="Formatos compatibles: jpg, png, jpeg, gif. Tamaño máximo 5mb."
                  fileAccept=".png, .jpg, .gif, .jpeg"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              </>
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="benefit[link]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Link"
                placeholder="https://peoplework.cl"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                abbr
                startDateName="benefit[startDate]"
                endDateName="benefit[endDate]"
                startDate={startDate}
                endDate={endDate}
                minCurrentDate
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="benefit[position]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Posición"
                inputType="number"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="benefit[category]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Categoría"
                placeholder="Seleccionar categoría"
                options={categories}
                defaultValue={benefit.category}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name="benefit[description]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                abbr
                label="Descripción"
                minInputHeight="120"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-2">
        {action === 'edit' && ability.can('destroy', 'Benefit') && (
          <Col md={3} className="mb-2 mb-md-0 mr-auto">
            <Button block variant="danger" onClick={handleDelete}>
              Eliminar
            </Button>
          </Col>
        )}
        <Col md={3} lg={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
          <Button block variant="outline-info" onClick={() => delayMethod(hideModal)}>
            Cancelar
          </Button>
        </Col>
        <Col md={5} lg={3}>
          <Button type="submit" disabled={isSubmitting} block>
            Guardar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {isDelete && (
            <div className="animation mt-4 mt-md-5">
              <p className="text-center mb-lg-n1">
                ¿Estás seguro que deseas eliminar el beneficio <span className="font-weight-bold">{benefit.name}</span>?
              </p>
              <div className="d-flex justify-content-end">
                <Button className="btn" variant="success" onClick={handleDeleteRequest}>
                  confirmar
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    category,
    benefitsImage,
    description,
    endDate,
    jobManagementIds,
    jobManagements,
    link,
    name,
    position,
    startDate
  } = props.benefit;

  return {
    benefit: {
      category,
      benefitsImage,
      description,
      endDate,
      jobManagementIds,
      jobManagements,
      link,
      name,
      position,
      startDate
    },
    rangeDate: [startDate, endDate]
  };
};

const validationSchema = Yup.object().shape({
  benefit: Yup.object().shape({
    category: Yup.string().required('Debes seleccionar una categoría'),
    description: Yup.string().required('Debes ingresar una descripción'),
    endDate: Yup.date().formatdate(),
    link: Yup.string().url('Debes ingresar una URL válida'),
    name: Yup.string().required('Debes ingresar un nombre'),
    position: Yup.number()
      .required('Debes ingresar una posición')
      .typeError('Debes ingresar un número')
      .positive('Debe ser mayor a 0'),
    startDate: Yup.date().formatdate(),
    benefitsImage: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'La imagen cargada excede el tamaño maximo permitido (5mb).',
        value => !value?.size || (value && value?.size <= 5242880)
      )
      .test(
        'FILE_FORMAT',
        'El archivo cargado tiene un formato no compatible.',
        value => !value?.type || (value && ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(value?.type))
      )
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BenefitForm);
