import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon, DefaultModal } from '../../../components';
import { showSurveyRequest } from '../../../requests/surveys';
import { showEmployeeSurveyRequest, updateEmployeeSurveyRequest } from '../../../requests/employeeSurveys';
import { sendAlert } from '../../../actions/utils';
import basicSurvey from '../../Survey/survey';
import SurveyPreview from '../../Survey/SurveyPreview';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const EmployeeSurveyEdit = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(true);
  const [survey, setSurvey] = useState(basicSurvey);
  const [employeeSurvey, setEmployeeSurvey] = useState({ employeeQuestionChoicesAttributes: [] });
  const [modalShow, setModalShow] = useState(false);
  const [surveyItem, setSurveyItem] = useState({});
  const outOfDate = !survey.sendSurvey;
  const history = useHistory();

  const fetchEmployeeSurvey = () => {
    setOnRequest(true);
    const request = async () => {
      showEmployeeSurveyRequest(id, {
        dispatch,
        successCallback: response => setEmployeeSurvey(camelCaseEmptyStringRecursive(response.data)),
        callback: () => setOnRequest(false)
      });
    };
    request();
  };

  const fetchSurvey = () => {
    if (employeeSurvey.survey?.value) {
      setOnRequest(true);
      const request = async () => {
        showSurveyRequest(employeeSurvey.survey.value, {
          dispatch,
          successCallback: response => setSurvey(camelCaseEmptyStringRecursive(response.data)),
          callback: () => setOnRequest(false)
        });
      };
      request();
    }
  };

  const handleUpdateRequest = () => {
    setOnRequest(true);
    updateEmployeeSurveyRequest(id, {
      dispatch,
      params: snakeCaseKeys(surveyItem.surveyValues, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta respondida con éxito' }));
        history.push(`/profile/surveys`);
      },
      callback: () => {
        surveyItem.setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  const handleSendSurvey = (surveyValues, setSubmitting) => {
    setModalShow(true);
    setSurveyItem({
      surveyValues,
      setSubmitting
    });
  };

  useEffect(fetchEmployeeSurvey, [id]);
  useEffect(fetchSurvey, [employeeSurvey]);

  return (
    <div className={`${onRequest && 'bg-opacity'}`}>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4">
        <LinkBtn variant="circle-dark" className="btn-circle mt-3" to="/profile/surveys">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">{survey.name}</h2>
      </Row>
      <SurveyPreview
        survey={survey}
        outOfDate={outOfDate}
        employeeSurvey={employeeSurvey}
        sendSurvey
        formRequest={handleSendSurvey}
      />
      <DefaultModal
        title="Enviar Encuesta"
        body="¿Estás seguro que deseas enviar la encuesta?"
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          surveyItem.setSubmitting(false);
        }}
        handleConfirm={() => handleUpdateRequest()}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </div>
  );
};

export default EmployeeSurveyEdit;
