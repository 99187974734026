import React, { useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';

import { BasicTextArea, FormikDatePicker, FormikInput, FormikNumber, FormikSelect } from '../../../components';
import terminationReasonTypes from '../../SettlementGroup/Form/FormOptions';

const SettlementGeneralForm = ({ settlement }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row>
        <Col md={6} lg={4}>
          <Field name="settlement[contract][employeeId]">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                label="Trabajador"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} lg={2}>
          <Field name="settlement[contract][employee][nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                label="Tipo de Identificación"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} lg={2}>
          <Field name="settlement[contract][employee][nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                label="Nº de Identificación"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4}>
          <Field name="settlement[contract][jobTitleId]">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                label="Cargo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} lg={2}>
          <Field name="settlement[contract][contractType]">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                label="Tipo de Contrato"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} lg={2}>
          <Field name="settlement[contract][startDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                disabled
                isOutsideRange={() => false}
                label="Fecha de Ingreso"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4} lg={3}>
          <Field name="settlement[timeWorked]">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                label="Tiempo Trabajado"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <h4 className="my-4">Definiciones para el Cálculo</h4>
      <Row>
        <Col md={3}>
          <Field name="settlement[parsedUf]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="UF"
                label="Valor UF"
                fieldName="settlement[parsedUf]"
                value={settlement.uf}
                decimalScale={2}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="settlement[parsedUfLimit]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="UF"
                label="Tope 90 UF"
                fieldName="settlement[ufLimit]"
                value={settlement.uf * 90}
                decimalScale={2}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="settlement[terminationReason]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Causal de Término"
                placeholder="Seleccionar Causal de Término"
                options={terminationReasonTypes}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="settlement[terminationDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={() => false}
                label="Fecha de Término"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="settlement[paymentDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={() => false}
                label="Fecha de Pago"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={12}>
          <Field name="settlement[justification]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                label="Justificación de la Causal"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default SettlementGeneralForm;
