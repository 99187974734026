import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createBalanceRequest } from '../../requests/balances';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import BalanceForm from './Form/BalanceForm';
import { emptyBalance } from './balance';

const BalanceNew = ({ asset }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = asset ? 'assets' : 'discounts';
  const model = asset ? 'Haber' : 'Descuento';
  const balanceType = asset ? 'asset' : 'discount';

  const handleSuccessCreate = response => {
    const { data: balance } = response;
    dispatch(sendAlert({ kind: 'success', message: `${model} creado con éxito` }));
    history.push(`/balances/${path}/${balance.id}`);
  };

  const handleCreateRequest = (params, setSubmitting) => {
    createBalanceRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: response => handleSuccessCreate(response),
      callback: () => setSubmitting(false)
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to={`/balances?tab=${balanceType}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Nuevo {model}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <BalanceForm
            balance={emptyBalance(balanceType)}
            action="new"
            submitVariant="success"
            formRequest={handleCreateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default BalanceNew;
