import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Modal, Spinner } from 'react-bootstrap';

import { createMassiveWorkflowRequestRequest } from '../../requests/workflowRequests';
import WorkflowRequestForm from './WorkflowRequestForm';

const basicDocumentRequest = documents => ({
  documents,
  recordType: 'Document',
  requestReviewersAttributes: [
    {
      approval: false,
      signature: false,
      position: 1,
      employeeReviewerIds: []
    }
  ]
});

const WorkflowRequestMassiveNew = ({ ...props }) => {
  const { handleModalClose, handleSuccessWorkflowRequest, handleErrorRequest, documents, documentWithWorkflow } = props;
  const [onRequest, setOnRequest] = useState(false);
  const workflowRequest = basicDocumentRequest(documents);
  const nameDocuments = documentWithWorkflow.map(document => document.file_info.filename);
  const dispatch = useDispatch();

  const handleCreate = workflowRequestValue => {
    setOnRequest(true);
    const params = snakeCaseKeys(workflowRequestValue);

    if (documents.length > 0) {
      createMassiveWorkflowRequestRequest({
        dispatch,
        params,
        formData: true,
        successCallback: () => {
          const message = 'Documentos enviado flujo con éxito';
          setOnRequest(false);
          handleSuccessWorkflowRequest(message, true);
        },
        callback: () => {
          setOnRequest(false);
          handleModalClose();
        }
      });
    } else {
      const message = 'No se encontraron documentos que enviar a flujo';
      setOnRequest(false);
      handleErrorRequest(message);
    }
  };

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {documentWithWorkflow.length > 0 && (
        <Modal.Body>
          Los documentos <strong>{nameDocuments.join(', ')},</strong> no serán enviados a flujo. Ya tienen un flujo
          asignado.
        </Modal.Body>
      )}
      <WorkflowRequestForm
        workflowRequest={workflowRequest}
        action="new"
        formRequest={handleCreate}
        cancelAction={handleModalClose}
      />
    </>
  );
};

export default WorkflowRequestMassiveNew;
