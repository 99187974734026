import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const PermissionCard = ({ permissionCard }) => {
  const {
    daysWithPay,
    daysWithoutPay,
    hoursWithPay,
    hoursWithoutPay,
    totalWeeklyDays,
    totalWeeklyHours
  } = permissionCard;

  return (
    <Card>
      <Card.Body>
        <Card.Header>Permisos</Card.Header>
        <Row className="mt-3">
          <Col md={12}>
            <Row>
              <Col>
                <Card.Title className="text-uppercase">Tipo</Card.Title>
                <p>(-) Permiso con Goce</p>
                <p>(-) Permiso sin Goce</p>
                <br />
                <br />
              </Col>
              <Col className="attendance-card">
                <Card.Title className="text-uppercase attendance-card-title">Cantidad</Card.Title>
                <Row>
                  <Col className="attendance-card-hours">
                    <p>{hoursWithPay} horas</p>
                    <p>{hoursWithoutPay} horas</p>
                  </Col>
                  <Col>
                    <p>{daysWithPay} días</p>
                    <p>{daysWithoutPay} días</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="mt-0 mb-3" />
          </Col>
          <Col md={12}>
            <Row>
              <Col>
                <p>Total</p>
              </Col>
              <Col className="attendance-card">
                <Row>
                  <Col className="attendance-card-hours">
                    <p>{totalWeeklyHours} horas</p>
                  </Col>
                  <Col>
                    <p>{totalWeeklyDays} días</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PermissionCard;
