import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPermissionsRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', '/permissions', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexUserPermissionsRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', '/permissions/user_index', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showPermissionRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', `/permissions/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createPermissionRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/permissions', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const approvePermissionRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/permissions/${id}/approve`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const rejectPermissionRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/permissions/${id}/reject`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const deletePermissionRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/permissions/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const exportPermissionsRequest = ({ dispatch, params, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/permissions/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const preImportPermissionRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/permissions/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importPermissionRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/permissions/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplatePermissionRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/permissions/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const showEmployeePermissionsCardsRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('get', '/permissions/employee_card', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexPermissionsRequest = AwesomeDebouncePromise(indexPermissionsRequest, 300);
