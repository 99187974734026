export default {
  category: '',
  companyId: '',
  benefitsImage: {},
  description: '',
  endDate: '',
  jobManagementIds: [],
  jobManagements: [],
  link: '',
  name: '',
  position: '',
  startDate: ''
};
