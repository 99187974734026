import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const SimpleShow = ({ item, handleClose, withActive }) => (
  <>
    <Modal.Body>
      <div className="info-box">
        {item.name && (
          <p className="info">
            <span>Nombre:</span> {item.name}
          </p>
        )}
        {item.dependFrom?.name && (
          <p className="info">
            <span>Depende de:</span> {item.dependFrom?.name}
          </p>
        )}
        {item.code && (
          <p className="info">
            <span>Código:</span> {item.code}
          </p>
        )}
        {withActive && (
          <p className="info">
            <span>Activo:</span> {item.active ? 'Sí' : 'No'}
          </p>
        )}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-info" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </>
);

export default SimpleShow;
