import React, { useState } from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { reminderNotificationRequest } from '../../requests/benefits';


const BenefitReminder = ({ benefit, hideModal }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false)
  const handleReminderNotification =  ()  => {
    setSubmitting(true)
    reminderNotificationRequest(benefit.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Recordatorio enviado con éxito.' }));
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Beneficio no encontrado'}));
      },
      callback: () => {
        hideModal()
        setSubmitting(false)
      }
    });
    
  };

  return (
    <section className="px-3 mt-n3 pb-3">
    <pre>
        <p className="text-justify">¿Estas seguro que quieres enviar un recordatorio del beneficio
                                    #{benefit.name}?</p>
    </pre>
    <div className="d-flex flex-row-reverse mt-4">
        <Button variant="primary" onClick={handleReminderNotification} disabled={isSubmitting}>
          Confirmar
        </Button>
    </div>
  </section>

  );
}



export default BenefitReminder;