import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { ComponentDataTable, ButtonTooltip, Icon, SimpleCenteredModal } from '../../components';
import { indexPayrollProcessesRequest, budgetPayrollProcessRequest } from '../../requests/payrollProcesses';
import { downloadFile } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import PayrollProcessBudgetForm from './PayrollProcessBudgetForm';
import columns from './Columns';

const PayrollProcessDataTable = ({ moreData, customParams }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [payrollProcesses, setPayrollProcesses] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRequest = async params => {
    setOnRequest(true);
    indexPayrollProcessesRequest({
      dispatch,
      params: {
        ...customParams,
        ...params
      },
      successCallback: response => setPayrollProcesses(response.data.data),
      callback: () => setOnRequest(false)
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/payroll_processes/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleBudgetReport = (values, setSubmitting) => {
    budgetPayrollProcessRequest({
      dispatch,
      params: snakecaseKeys(values),
      successCallback: response => {
        downloadFile(response);
        setModalShow(false);
      },
      callback: () => {
        setSubmitting(false);
      }
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        pointerOnHover
        preName="payroll_processes"
        resourceRequest={handleRequest}
        columns={columns(handleButtonClick)}
        onRowClicked={item => handleButtonClick(item, 'show')}
        data={payrollProcesses}
        totalRows={0}
        handleSortCase={sortColumnCase}
        onRequest={onRequest}
        withMassActions={ability.can('report', 'PayrollProcess')}
        massActions={
          <ButtonTooltip
            className="mr-3 btn-circle"
            variant="circle-primary"
            text="Generar Presupuesto"
            onClick={() => setModalShow(true)}
          >
            <Icon icon="document" />
          </ButtonTooltip>
        }
        moreData={moreData}
      />
      <SimpleCenteredModal
        title="Presupuesto de Remuneraciones"
        body={<PayrollProcessBudgetForm formRequest={handleBudgetReport} cancelAction={() => setModalShow(false)} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default PayrollProcessDataTable;
