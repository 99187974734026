import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { delayMethod } from '../../../services/utils';

const DefaultModal = ({
  body,
  closeButton,
  handleClose,
  handleConfirm,
  modalSize,
  show,
  title,
  titleBtnClose,
  titleBtnSave,
  variantBtnClose,
  variantBtnSave,
  withClose,
  withConfirm,
  disabled,
  ...props
}) => {
  return (
    <Modal {...props} size={modalSize} show={show} onHide={handleClose}>
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {(withClose || withConfirm) && (
        <Modal.Footer>
          {withClose && (
            <Button
              className="btn"
              variant={variantBtnClose}
              onClick={() => delayMethod(handleClose)}
              disabled={disabled}
            >
              {titleBtnClose}
            </Button>
          )}

          {withConfirm && (
            <Button className="btn" variant={variantBtnSave} onClick={handleConfirm} disabled={disabled}>
              {titleBtnSave}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
  variantBtnClose: PropTypes.string,
  titleBtnClose: PropTypes.string,
  variantBtnSave: PropTypes.string,
  titleBtnSave: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
  withConfirm: PropTypes.bool,
  withClose: PropTypes.bool,
  modalSize: PropTypes.string,
  disabled: PropTypes.bool
};

DefaultModal.defaultProps = {
  closeButton: true,
  handleConfirm: () => null,
  modalSize: '',
  titleBtnClose: 'Cancelar',
  titleBtnSave: 'Confirmar',
  variantBtnClose: 'outline-info',
  variantBtnSave: 'primary',
  withClose: true,
  withConfirm: true,
  disabled: false
};

export default DefaultModal;
