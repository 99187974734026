import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import moment from 'moment';
import { useAbility } from '@casl/react';

import { debounceIndexImportLogsRequest, exportImportLogsRequest } from '../../requests/importLogs';
import { ComponentDataTable, DatatableEmployeeName, ButtonTooltip, Icon, DefaultModal } from '../../components';
import { downloadFile } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';

const columns = memoize(() => [
  {
    name: 'FECHA',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RESPONSABLE',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE ACCION',
    selector: 'message',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CANTIDAD DE ARCHIVOS',
    selector: 'file_count',
    sortable: true,
    grow: '1',
    center: true
  }
]);

const ImportLogDataTable = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [importLogs, setImportLogs] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [query, setQuery] = useState({});
  const defaultStartDate = useMemo(moment, []);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleExportRequest = () => {
    setOnRequest(true);
    exportImportLogsRequest({
      dispatch,
      params: query,
      successCallback: downloadFile,
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setQuery(params);
    debounceIndexImportLogsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data, metadata } = response.data;
        setImportLogs(data);
        setAmount(metadata.amount);
      },
      callback: () => setOnRequest(false)
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employee: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        handleSortCase={sortColumnCase}
        columns={columns()}
        data={importLogs}
        totalRows={amount}
        withMassActions={ability.can('export', 'ImportLog')}
        massActions={
          <ButtonTooltip onClick={() => setModalShow(true)} variant="circle-primary" className="mr-3" text="Exportar">
            <Icon icon="download-2" />
          </ButtonTooltip>
        }
        resourceRequest={handleRequest}
        withStartDate
        withEndDate
        defaultStartDate={defaultStartDate}
        rangePicker
      />
      <DefaultModal
        title="Exportar historial"
        body="¿Estás seguro que quieres exportar el historial?"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={handleExportRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ImportLogDataTable;
