import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendAlert } from '../../../actions/utils';

import { indexCompanyRequest, changeActiveCompanyRequest } from '../../../requests/companies';
import { indexAdminCompanyRequest, changeActiveAdminCompanyRequest } from '../../../requests/adminCompanies';
import { ComponentDataTable, DefaultModal } from '../../../components';

const CompanyDataTable = ({ columns, admin }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessRemove = () => {
    setOnRequest(false);
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleFailure = error => {
    const { response } = error;
    let errorMessage = response.statusText;
    if (response.data && response.data.message) {
      errorMessage = response.data.message;
    }
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setOnRequest(false);
    setModalShow(false);
  };

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setCompanies(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);

    const allParams = {
      dispatch,
      params,
      successCallback: handleSuccessIndex
    };
    if (admin) indexAdminCompanyRequest(allParams);
    else indexCompanyRequest(allParams);
  };

  const archiveCompany = companyId => {
    const allParams = {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailure
    };
    if (admin) changeActiveAdminCompanyRequest(companyId, allParams);
    else changeActiveCompanyRequest(companyId, allParams);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`${admin ? '/admin' : ''}/companies/${item.id}`);
        break;
      case 'edit':
        history.push(`${admin ? '/admin' : ''}/companies/${item.id}/edit`);
        break;
      case 'archive':
        setModalTitle(`${item.active ? 'desactivar' : 'activar'} empresa`);
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas ${item.active ? 'desactivar' : 'activar'} esta empresa?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={companies}
        totalRows={amount}
        moreData={moreData}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        resourceRequest={handleRequest}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => archiveCompany(modalItem.id)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default CompanyDataTable;
