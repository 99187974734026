import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import GoogleApiWrapper from '../../../components/Map/GoogleMap';
import { FormikSelect } from '../../../components/index';
import { delayMethod } from '../../../services/utils';

const DashboardAttendance = props => {
  const { errors, touched, setFieldValue, handleModalClose } = props;
  const [geolocalitation, setGeolocalitation] = useState(null);

  const timeTypes = [
    { label: 'Entrada', value: 'arrival' },
    { label: 'Salida', value: 'departure' },
    { label: 'Entrada Colación', value: 'arrival_break_time' },
    { label: 'Salida Colación', value: 'departure_break_time' }
  ];

  useEffect(() => {
    let coordenates = null;
    async function fetchMyAPI() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
          coordenates = { lat, lng };
          setGeolocalitation(coordenates);
        });
      } else {
        setGeolocalitation(null);
      }
    }
    fetchMyAPI();
  }, []);

  const CoordenatesForBackEnd = data => {
    if (geolocalitation) {
      const coordenates = data;
      coordenates.field.value.lat = geolocalitation.lat;
      coordenates.field.value.lng = geolocalitation.lng;
    }
    return null;
  };

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Field name="attendance[timeType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Hora"
                  placeholder="Seleccionar Tipo de Hora"
                  options={timeTypes}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[coordenates]">{({ field }) => <CoordenatesForBackEnd field={field} />}</Field>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ height: '200px' }}>
            {geolocalitation != null ? (
              <GoogleApiWrapper coordenates={geolocalitation} />
            ) : (
              <p>Debe Aceptar la Geolocalizacion. De lo contrario, no se puede registrar su asistencia</p>
            )}
          </Col>
          <Col>*La dirección es referencial y no refleja la ubicación exacta</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        {geolocalitation != null ? (
          <Button type="submit" variant="primary" onClick={() => delayMethod(handleModalClose)}>
            Guardar
          </Button>
        ) : (
          <Button type="submit" variant="primary" onClick={() => delayMethod(handleModalClose)} disabled>
            Guardar
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { attendances } = props;
  const attendance = attendances[0];

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    date: Yup.string().required('Debes escoger una fecha'),
    time: Yup.string().required('Debes escoger la hora'),
    timeType: Yup.string().required('Debes escoger el tipo de hora'),
    employeeId: Yup.string().required('Debe haber un empleado asociado')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(DashboardAttendance);
