import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import BasicCard from './BasicCard';
import Icon from '../../Icons';

const JobLongCard = ({
  item,
  image,
  rrhh,
  canEdit,
  showInfo,
  nameShow,
  showEdit,
  className,
  classNameNoImage,
  classNameDescription,
  nameKey,
  employeeRut
}) => (
  <div className="card-image">
    <BasicCard
      classNameCard={`card-box ${className} ${!image && `bg-card-colors ${classNameNoImage}`}`}
      classNameImg={`img-size ${rrhh && canEdit && 'img-size-edit'}`}
      src={image}
      title={item[nameKey]}
      size="100%"
      variantBtn="primary"
      divBody
      text={
        image ? (
          <div className="container-buttons">
            <div className="text-justify box-size-description mr-xl-n3">
              <p className={`${classNameDescription || 'b-description'}`}>{item.description}</p>
            </div>
            <div className={rrhh && canEdit ? 'container-btn-edit' : 'container-btn'}>
              {rrhh && canEdit && (
                <Button block className="margin-sm" variant="outline-info" onClick={() => showEdit(item)}>
                  Editar
                </Button>
              )}
              <Button
                block
                className={rrhh && canEdit ? 'mb-0' : 'mb-2'}
                variant="primary"
                onClick={() => showInfo(item)}
              >
                {nameShow}
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p className={`b-description-multiline ${rrhh && canEdit && 'multiline-edit'} text-justify`}>
              {item.description}
            </p>
            <div className="container-flex">
              <div />
              <div className={rrhh && canEdit ? 'container-btn-edit' : 'd-flex justify-content-end'}>
                {rrhh && canEdit && (
                  <Button className="btn-size margin-btn" variant="outline-info" onClick={() => showEdit(item)}>
                    Editar
                  </Button>
                )}
                <p className="b-description-multiline text-justify" style={{ marginRight: '50px' }}>
                  <strong>
                    {' '}
                    <Icon icon="file-tray-full-outline" width={20} /> Cupos Disponibles:{' '}
                  </strong>{' '}
                  {item.vacancies}
                </p>
                <p className="b-description-multiline text-justify" style={{ marginRight: '50px' }}>
                  <strong>
                    {' '}
                    <Icon icon="home-outline" width={20} /> Modalidad:{' '}
                  </strong>{' '}
                  {item.translatedRemote}
                </p>
                <p className="b-description-multiline text-justify" style={{ marginRight: '50px' }}>
                  <strong>
                    {' '}
                    <Icon icon="document-text" width={20} /> Nivel:{' '}
                  </strong>{' '}
                  {item.translatedExpertiseLevel}
                </p>
                <p className="b-description-multiline text-justify" style={{ marginRight: '50px' }}>
                  <Icon icon="time" width={20} /> {item.translatedWorkSchedule}
                </p>
                {item.candidates.some(candidate => candidate.nationalIdentification === employeeRut) ? (
                  <Button
                    className={rrhh && canEdit ? 'btn-size mb-0' : 'mt-md-2 mt-lg-1 mb-3'}
                    variant="primary"
                    onClick={() => showInfo(item)}
                    disabled
                  >
                    {'Ya Postulaste'}
                  </Button>
                ) : (
                  <Button
                    className={rrhh && canEdit ? 'btn-size mb-0' : 'mt-md-2 mt-lg-1 mb-3'}
                    variant="primary"
                    onClick={() => showInfo(item)}
                  >
                    {nameShow}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )
      }
    />
  </div>
);

JobLongCard.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  rrhh: PropTypes.bool,
  canEdit: PropTypes.bool,
  showEdit: PropTypes.func,
  showInfo: PropTypes.func.isRequired,
  nameShow: PropTypes.string,
  className: PropTypes.string,
  classNameNoImage: PropTypes.string,
  classNameDescription: PropTypes.string,
  nameKey: PropTypes.string
};

JobLongCard.defaultProps = {
  item: null,
  nameKey: 'name',
  image: '',
  rrhh: false,
  canEdit: false,
  nameShow: 'Más info',
  className: '',
  classNameNoImage: '',
  classNameDescription: '',
  showEdit: () => null
};

export default JobLongCard;
