import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Tab, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import {
  ComponentDataTable,
  FormikCheckBox,
  FormikInput,
  FormikNumber,
  FormikSelect,
  InputSelect,
  SimpleCenteredModal,
  Icon,
  ButtonTooltip,
  EmployeeSearchModal,
  Genders
} from '../../components';
import { debounceIndexBranchOfficesRequest } from '../../requests/branchOffices';
import { indexEmployeesRequest, debounceIndexEmployeesRequest } from '../../requests/employees';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { debounceIndexJobTitleRequest } from '../../requests/jobTitles';
import { debounceIndexSyndicatesRequest } from '../../requests/syndicates';
import { camelCaseEmptyStringRecursive, delayMethod } from '../../services/utils';

import columns from './EmployeeColumns';
import columnsWithActions from './EmployeeColumnsWithActions';
import '../../services/yupCustomMethods';

const GroupForm = ({ group, errors, isSubmitting, setFieldValue, touched, values }) => {
  const dispatch = useDispatch();
  const [filtersRequest, setFiltersRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [searchModalShow, setSearchModalShow] = useState(false);
  const [employee, setEmployee] = useState('');
  const [selectorEmployees, setSelectorEmployees] = useState([]);
  const [selector, setSelector] = useState([]);
  const {
    branchOfficeIds,
    customGroup,
    employeeIds,
    jobManagementIds,
    jobTitleIds,
    maxAge,
    minAge,
    syndicateIds
  } = values.group;
  const [queryEmployees, setQueryEmployees] = useState([]);

  const resultFetchData = response => {
    const { data } = response.data;
    return data.map(element => ({
      label: element.name,
      value: element.id
    }));
  };

  const fetchJobManagments = (inputValue, callback) => {
    const request = async () => {
      debounceIndexJobManagementsRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const fetchJobTitles = (inputValue, callback) => {
    const request = async () => {
      debounceIndexJobTitleRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const fetchBranchOffices = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBranchOfficesRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const fetchSyndicates = (inputValue, callback) => {
    const request = async () => {
      debounceIndexSyndicatesRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const handleNewMulti = (data, field) => {
    const newData = data.map(element => element.value);
    if (!newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const fetchEmployees = () => {
    setFiltersRequest(true);
    const request = async () =>
      debounceIndexEmployeesRequest({
        dispatch,
        params: {
          active: true,
          sort_column: 'name',
          paginate: false,
          is_dt: false,
          filter_active_job_managements: jobManagementIds,
          filter_active_job_titles: jobTitleIds,
          filter_active_branch_offices: branchOfficeIds,
          filter_active_syndicates: syndicateIds,
          min_age: minAge,
          max_age: maxAge
        },
        successCallback: response => setQueryEmployees(camelCaseEmptyStringRecursive(response.data.data)),
        callback: () => setFiltersRequest(false)
      });
    request();
  };

  const fetchInitialEmployees = () => {
    const request = async () =>
      indexEmployeesRequest({
        dispatch,
        params: {
          active: true,
          sort_column: 'name',
          is_dt: false,
          paginate: false
        },
        successCallback: response => setSelectorEmployees(camelCaseEmptyStringRecursive(response.data.data))
      });
    if (!filtersRequest) {
      request();
    }
  };

  const filterSelector = () => {
    const filter = selectorEmployees.filter(item => !employeeIds.includes(item.id));
    setSelector(filter);
  };

  const handleSelector = () => {
    const employeeSelector = selector.find(item => item.id === employee);
    setFieldValue('group[employeeIds]', [...employeeIds, employee]);
    setFieldValue('group[employees]', [...values.group.employees, employeeSelector]);
    setEmployee('');
  };

  const handleClick = (item, action) => {
    let filterIds;
    switch (action) {
      case 'destroy':
        filterIds = employeeIds.filter(employeeId => employeeId !== item.id);
        if (filterIds.length > 0) {
          setFieldValue('group[employeeIds]', filterIds);
        } else {
          setFieldValue('group[employeeIds]', ['']);
        }
        setFieldValue(
          'group[employees]',
          values.group.employees.filter(employeeItem => employeeItem.id !== item.id)
        );
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Action not found');
    }
  };

  const handleSearch = selectedEmployeeIds => {
    const newEmployees = selectorEmployees.filter(
      item => selectedEmployeeIds.includes(item.id) && !employeeIds.includes(item.id)
    );
    setFieldValue('group[employeeIds]', [...employeeIds, ...newEmployees.map(item => item.id)]);
    setFieldValue('group[employees]', [...values.group.employees, ...newEmployees]);
    setSearchModalShow(false);
  };

  const resetEmployees = () => {
    if (!customGroup) {
      setFieldValue('group[employeeIds]', ['']);
      setFieldValue('group[employees]', []);
    }
  };

  useEffect(filterSelector, [employeeIds, selectorEmployees]);
  useEffect(fetchEmployees, [
    employeeIds,
    branchOfficeIds,
    jobManagementIds,
    jobTitleIds,
    syndicateIds,
    minAge,
    maxAge
  ]);
  useEffect(fetchInitialEmployees, [filtersRequest]);
  useEffect(resetEmployees, [customGroup]);

  return (
    <Form>
      <>
        <Row>
          <Col md={3}>
            <Field name="group[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field name="group[customGroup]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  margin="mt-4"
                  label="Grupo Específico"
                  custom
                  type="switch"
                  tooltipClass="ml-2 mt-2"
                  tooltipText="Al marcar esta casilla tendrás la opción de agregar usuarios de manera específica. Los 
                               trabajadores añadidos de esta manera no se actualizarán de manera automática en base a
                               los filtros."
                />
              )}
            </Field>
          </Col>
          {customGroup && (
            <>
              <Col md={3}>
                <FormikSelect
                  label="Agregar a"
                  placeholder="Seleccionar Trabajador"
                  options={selector}
                  onChange={data => setEmployee(data.id || '')}
                />
              </Col>
              <Col md={3} className="d-flex flex-row">
                <Button
                  variant="primary"
                  className="my-4"
                  onClick={handleSelector}
                  style={{ marginTop: '5px' }}
                  disabled={!employee}
                >
                  Agregar
                </Button>
                <ButtonTooltip
                  variant="circle-primary"
                  className="ml-5 mt-2 advance-search"
                  text="Búsqueda avanzada de trabajadores mediante filtros de cargos, lugares de Prestación de Servicios, otros"
                  onClick={() => setSearchModalShow(true)}
                >
                  <Icon className="w-100 h-100" icon="people-circle" />
                </ButtonTooltip>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col md={3}>
            <Field name="group[jobManagementIds]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  isMulti
                  placeholder="Todas"
                  label="Áreas"
                  request={fetchJobManagments}
                  values={values.group}
                  model={[group, 'jobManagement']}
                  onChange={data => handleNewMulti(data || [], field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="group[jobTitleIds]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  isMulti
                  placeholder="Todos"
                  label="Cargo"
                  request={fetchJobTitles}
                  values={values.group}
                  model={[group, 'jobTitle']}
                  onChange={data => handleNewMulti(data || [], field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="group[branchOfficeIds]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  isMulti
                  placeholder="Todas"
                  label="Lugar de Prestación de Servicios"
                  request={fetchBranchOffices}
                  values={values.group}
                  model={[group, 'branchOffice']}
                  onChange={data => handleNewMulti(data || [], field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Button variant="warning" className="mt-4" block onClick={() => setModalShow(true)}>
              Filtros Avanzados
            </Button>
          </Col>
        </Row>
        <hr />
        {customGroup ? (
          <Tab.Container id="groups-datatables.tabs" defaultActiveKey="filters">
            <Nav variant="pills" className="ab-pills">
              <Nav.Item>
                <Nav.Link eventKey="filters">Por Filtros</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="custom">Personalizados</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="filters">
                <ComponentDataTable
                  columns={columns()}
                  data={queryEmployees}
                  onRequest={filtersRequest}
                  totalRows={queryEmployees.length}
                  pointerOnHover
                  sortServer={false}
                  subHeaderComponent={false}
                  paginationServer={false}
                  pagination
                />
              </Tab.Pane>
              <Tab.Pane eventKey="custom">
                <ComponentDataTable
                  columns={columnsWithActions(handleClick)}
                  data={values.group.employees}
                  onRequest={false}
                  totalRows={values.group.length}
                  pointerOnHover
                  sortServer={false}
                  subHeaderComponent={false}
                  paginationServer={false}
                  pagination
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <ComponentDataTable
            columns={columns()}
            data={queryEmployees}
            onRequest={filtersRequest}
            totalRows={queryEmployees.length}
            defaultSortField="fullName"
            pointerOnHover
            sortServer={false}
            subHeaderComponent={false}
            paginationServer={false}
            pagination
          />
        )}
      </>
      <Row className="d-flex justify-content-end my-5">
        <Col md={2}>
          <Button type="submit" block disabled={isSubmitting}>
            Guardar
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Filtros Avanzados"
        show={modalShow}
        body={
          <>
            <Row>
              <Col md={6}>
                <Field name="group[syndicateIds]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      isMulti
                      placeholder="Todas"
                      label="Sindicatos"
                      request={fetchSyndicates}
                      values={values.group}
                      model={[group, 'syndicate']}
                      onChange={data => handleNewMulti(data || [], field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Genders modelKey="group" />
              <Col md={6}>
                <Field name="group[parsedMinAge]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      rightAddon="años"
                      leftAddon={false}
                      fieldName="group[minAge]"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      label="Mayores de"
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="group[parsedMaxAge]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      rightAddon="años"
                      leftAddon={false}
                      fieldName="group[maxAge]"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      label="Menores de"
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col md={4}>
                <Button variant="primary" onClick={() => delayMethod(() => setModalShow(false))} className="mb-3" block>
                  Cerrar
                </Button>
              </Col>
            </Row>
          </>
        }
        size="lg"
        onHide={() => setModalShow(false)}
      />
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setSearchModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={searchModalShow}
        onHide={() => setSearchModalShow(false)}
      />
    </Form>
  );
};

const setInitialValues = props => {
  const { group } = props;
  return { group };
};

const validationSchema = Yup.object().shape({
  group: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    gender: Yup.string(),
    minAge: Yup.number().min(0, 'Debe ser mayor a 0'),
    maxAge: Yup.number().min(0, 'Debe ser mayor a 0')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(GroupForm);
