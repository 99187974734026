import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';

import { sendAlert } from '../../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { LinkBtn, Icon, DefaultModal, FormikSelect, FormikInput } from '../../../components';
import {
  showTicketAdminRequest,
  updateTicketAdminRequest,
  assignUserTicketAdminRequest,
  impersonateAccountAdminRequest
} from '../../../requests/adminTickets';
import { indexUserAdminRequest } from '../../../requests/adminUsers';
import { validateToken } from '../../../actions/auth';
import { InfoShow, InfoTicketComments } from '../../Shared/Ticket';
import { clasificationTypes, criticalityTypes } from './FormOptions';

const TicketShow = ({ match }) => {
  const { user } = useSelector(state => state.auth);
  const [ticket, setTicket] = useState({ ticketComments: [] });
  const [users, setUsers] = useState({});

  const [updateStatus, setUpdateStatus] = useState(false);
  const ability = useAbility(AbilityContext);

  const [comment, setComment] = useState('');
  const [responsable, setResponsable] = useState('');
  const [accessRequestReason, setAccessRequestReason] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalConfirmBtn, setModalConfirmBtn] = useState('');
  const [modalConfirmBtnColor, setModalConfirmBtnColor] = useState('primary');
  const [modalWithClose, setModalWithClose] = useState(false);
  const ticketId = match.params.id;
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchUsers = params => {
    indexUserAdminRequest({
      dispatch,
      params,
      successCallback: response => setUsers(response.data.data)
    });
  };

  const fetchTicket = params => {
    showTicketAdminRequest(ticketId, {
      dispatch,
      params,
      successCallback: response => {
        const ticketInfo = camelCaseEmptyStringRecursive(response.data);
        setTicket(ticketInfo);
        const closedTicket = ticketInfo.translatedStatus === 'Cerrado' || ticketInfo.translatedStatus === 'Cancelado';
        if (ticketInfo.translatedStatus === 'Ingresado' && !closedTicket && ticketInfo.user?.value === user.id) {
          setUpdateStatus(true);
        }
      }
    });
  };

  const handleSuccessAction = (message, redirect = false) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    if (redirect) {
      history.push(`/admin/tickets/`);
    }
    setComment('');
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setModalShow(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const updateTicket = (params, withRedirect = false, message = 'Ticket modificado con éxito') => {
    updateTicketAdminRequest(ticketId, {
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        handleSuccessAction(message, withRedirect);
        fetchTicket();
      },
      failureCallback: handleFailureRequest
    });
  };

  const impersonateAccount = params => {
    impersonateAccountAdminRequest(ticketId, {
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        handleSuccessAction('Cambio de sesión con éxito');
        dispatch(validateToken());
        updateAbility(ability);
        history.push('/profile/dashboard');
      }
    });
  };

  const updateStatusRequest = () => {
    if (updateStatus) {
      updateTicket({ ticket: { status: 'pending' } }, false, 'Ticket actualizado a pendiente');
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchUsers, []);
  useEffect(fetchTicket, []);
  useEffect(updateStatusRequest, [updateStatus]);

  const assignUser = (params, withRedirect = false, message = 'Ticket modificado con éxito') => {
    assignUserTicketAdminRequest(ticketId, {
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        handleSuccessAction(message, withRedirect);
        fetchTicket();
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleAddResponse = () => {
    updateTicket({ ticket: { ticket_comments_attributes: [{ comment }] } }, false, 'Comentario agregado con éxito');
    setModalShow(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
    if (modalWithClose) {
      setModalWithClose(false);
    }
  };

  const displayModal = (title, body, closeBtn, closeBtnColor) => {
    setModalTitle(title);
    setModalBody(body);
    setModalConfirmBtn(closeBtn);
    setModalConfirmBtnColor(closeBtnColor);
    setModalShow(true);
  };

  const modalHandleConfirm = () => {
    switch (modalTitle) {
      case 'Cerrar Ticket':
        updateTicket({ ticket: { status: 'closed' } }, true);
        setModalShow(false);
        break;
      case 'Asignar Responsable':
        assignUser(snakeCaseKeys({ ticket: { userId: responsable } }));
        setModalShow(false);
        break;
      case 'Solicitar Acceso':
        setModalWithClose(false);
        setModalShow(false);
        updateTicket({ ticket: { access_motive: accessRequestReason, account_access: 'awaiting' } });
        // assignUser(snakeCaseKeys({ ticket: { reason: accessRequestReason } }))
        break;
      case 'Observación Ticket':
        setModalShow(false);
        break;
      case 'Agregar Comentario':
        handleAddResponse();
        break;
      case 'Acceder Cuenta':
        impersonateAccount();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const accessRequestForm = () => (
    <>
      <p>
        Al solicitar acceso, se le enviará una notificación al usuario. Una vez que se autorice el acceso, recibirás un
        link para ingresar a su cuenta.
      </p>
      <FormikInput as="textarea" label="Motivo" onChange={data => setAccessRequestReason(data.target.value)} />
    </>
  );

  const isOpen = ticket.translatedStatus !== 'Cerrado' && ticket.translatedStatus !== 'Cancelado';
  const itemColorNormal = ticket.translatedStatus === 'Ingresado' ? 'primary' : '';
  const ticketStatusColor = ticket.translatedStatus === 'Pendiente' ? 'warning' : itemColorNormal;
  const accessRequest = ticket.translatedAccountAccess;
  const designatedUser = ticket.user?.value === user.id;

  return (
    <>
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to="/admin/tickets">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={11} className="mt-3">
          <h2 className="text-uppercase my-1">Ticket #{ticket.code}</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={11} className="mx-auto">
          <Row>
            <Col md={8} className="ticket-card card-left">
              <InfoShow displayModal={displayModal} ticket={ticket} />
            </Col>
            <Col className="ticket-card card-right">
              <h3 className="my-4 text-uppercase">Acciones</h3>
              {isOpen && (
                <>
                  <FormikSelect
                    label="Responsables"
                    options={users}
                    placeholder={ticket.user?.label || 'Seleccionar Responsable'}
                    defaultValue={ticket.user?.value}
                    onChange={data => {
                      displayModal(
                        'Asignar Responsable',
                        '¿Está seguro que desea asignar este responsable?',
                        'Asignar',
                        'primary'
                      );
                      setResponsable(data?.value);
                    }}
                  />
                  <FormikSelect
                    label="Clasificación"
                    options={clasificationTypes}
                    placeholder={ticket.translatedClasification || 'Seleccionar Clasificación'}
                    defaultValue={ticket.clasification}
                    onChange={data => assignUser({ ticket: { clasification: data.value } })}
                  />
                  <FormikSelect
                    label="Criticidad"
                    placeholder={ticket.translatedCriticality || 'Seleccionar Criticidad'}
                    options={criticalityTypes}
                    defaultValue={ticket.criticality}
                    onChange={data => assignUser({ ticket: { criticality: data.value } })}
                  />
                  {accessRequest === 'Sin Registro' && designatedUser ? (
                    <>
                      <Button
                        block
                        variant="warning"
                        className="my-4"
                        onClick={() => {
                          setModalWithClose(true);
                          displayModal('Solicitar Acceso', accessRequestForm(), 'Confirmar Solicitud', 'primary');
                        }}
                      >
                        Solicitar Acceso
                      </Button>
                      <p className="mt-4 show-info-title">Estado:</p>
                      <p className={`ml-3 text-${ticketStatusColor}`}>{ticket.translatedStatus}</p>
                    </>
                  ) : (
                    <>
                      <p className="mt-4 show-info-title">Estado:</p>
                      <p className={`ml-3 text-${ticketStatusColor}`}>{ticket.translatedStatus}</p>
                      <p className="mt-4 show-info-title">Solicitud de Acceso:</p>
                      <p className={`ml-3 text-${accessRequest === 'Denegado' ? 'warning' : 'primary'}`}>
                        {accessRequest}
                      </p>
                    </>
                  )}
                  {accessRequest === 'Otorgado' && designatedUser && (
                    <Button
                      block
                      variant="warning"
                      className="my-4"
                      onClick={() => {
                        setModalWithClose(true);
                        displayModal(
                          'Acceder Cuenta',
                          '¿Está seguro que desea acceder a esta cuenta?',
                          'Acceder',
                          'primary'
                        );
                      }}
                    >
                      Acceder Cuenta
                    </Button>
                  )}
                  <Button
                    block
                    variant="danger"
                    className="mt-4"
                    onClick={() =>
                      displayModal(
                        'Cerrar Ticket',
                        '¿Está seguro que desea cerrar este ticket?',
                        'Cerrar Ticket',
                        'danger'
                      )
                    }
                  >
                    Cerrar Ticket
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <InfoTicketComments
        ticketComments={ticket.ticketComments}
        displayModal={displayModal}
        setComment={setComment}
        isOpen={isOpen}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={modalHandleConfirm}
        titleBtnSave={modalConfirmBtn}
        variantBtnSave={modalConfirmBtnColor}
        withClose={modalWithClose}
        titleBtnClose="Cancelar"
        variantBtnClose="outline-info"
      />
    </>
  );
};

export default TicketShow;
