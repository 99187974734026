import React, { useState, useEffect } from 'react';
import { Tab, Nav, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { useSetTab, useAuthorization } from '../../services/hooks';
import { Icon } from '../../components';
import SyndicateDataTable from '../Syndicate/SyndicateDataTable';
import JointCommitteeDataTable from '../JointCommittee/JointCommitteeDataTable';

const GroupingIndex = ({ location }) => {
  const history = useHistory();
  const [keyModel, setKeyModel] = useState('Syndicate');
  const [key, setKey] = useSetTab('syndicates', location);

  const handleClick = () => {
    switch (key) {
      case 'syndicates':
        return history.push('/syndicates/new');
      case 'joint-committees':
        return history.push('/joint_committees/new');
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        return '';
    }
  };

  useEffect(() => {
    switch (key) {
      case 'syndicates':
        setKeyModel('Syndicate');
        break;
      case 'joint-committees':
        setKeyModel('JointCommittee');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        setKeyModel('');
    }
  }, [key]);

  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="mb-3 mt-3">Agrupaciones</h2>
        </Col>
        <Col xs={12} md={3}>
          {useAuthorization('create', keyModel) && (
            <Button variant="primary" className="mt-2" block onClick={handleClick}>
              Nuevo
            </Button>
          )}
        </Col>
      </Row>
      <Tab.Container id="grouping-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="syndicates">
              <Icon icon="people-circle" />
              Sindicatos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="joint-committees">
              <Icon icon="chatbubbles" />
              Comités
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="syndicates">
            <SyndicateDataTable />
          </Tab.Pane>
          <Tab.Pane eventKey="joint-committees">
            <JointCommitteeDataTable />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default GroupingIndex;
