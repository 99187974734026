import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { FormikCheckBox, FormikInput, FormikSelect, InputSelect, LinkBtn, FormikNumber } from '../../../components';
import { debounceIndexBookkeepersRequest } from '../../../requests/bookkeepers';
import {
  conceptTypeEnum,
  classificationTypeEnum,
  ineCodeEnum,
  remunerationTypeEnum,
  emptyConcept
} from './FormOptions';

import validationSchema from './formValidationSchema';

const ConceptForm = props => {
  const dispatch = useDispatch();
  const {
    balance,
    errors,
    onHide,
    setFieldValue,
    setFieldTouched,
    submitVariant,
    touched,
    values,
    isSubmitting,
    action
  } = props;

  const [balanceType, setBalanceType] = useState('');

  const resultFetchDataBookkeeper = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: element.name,
      value: element.id
    }));
  };

  const fetchDataBookkeeper = (inputValue, callback) => {
    debounceIndexBookkeepersRequest({
      dispatch,
      params: {
        query: inputValue,
        paginate: false
      },
      successCallback: data => callback(resultFetchDataBookkeeper(data))
    });
  };

  useEffect(() => {
    setBalanceType(balance.balanceType);
  }, [balance]);

  const onchangeconceptType = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');
    setBalanceType(data.value);

    if (action === 'new') {
      Object.keys(emptyConcept).forEach(key => {
        if (!['code', 'balanceType'].includes(key)) {
          setFieldValue(`balance[${key}]`, emptyConcept[key]);
        }
      });
    }
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="balance[balanceType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Concepto"
                placeholder="Seleccionar Tipo de Concepto"
                options={conceptTypeEnum}
                defaultValue={balance.balanceType}
                onChange={data => onchangeconceptType(field, data)}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isDisabled={action === 'edit'}
              />
            )}
          </Field>
        </Col>

        <Col md={6}>
          <Field name="balance[code]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Código"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                defaultValue={balance.code}
                disabled={action === 'edit'}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Field name="balance[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Descripción"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                defaultValue={balance.name}
              />
            )}
          </Field>
        </Col>

        {['asset', 'discount'].includes(balanceType) && (
          <Col md={6}>
            <Field name="balance[remunerationType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Remuneración"
                  placeholder="Seleccionar Tipo de Remuneración"
                  tooltipText="Para efectos del cálculo del finiquito, considerar el concepto como ocasional (no se incluye),
                                  fijo (último mes), variable (promedio últimos 3 meses)."
                  options={remunerationTypeEnum}
                  defaultValue={balance.remunerationType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      {balanceType === 'input_parameters' && (
        <Row>
          <Col md={6}>
            <Field name="balance[maxInput]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  label="Máximo"
                  fieldName="balance[maxInput]"
                  errors={errors}
                  touched={touched}
                  defaultValue={balance.maxInput}
                  thousandSeparator=""
                />
              )}
            </Field>
          </Col>
        </Row>
      )}
      {balanceType === 'asset' && (
        <Row>
          <Col md={6}>
            <Field name="balance[taxable]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Imponible" custom type="switch" />}
            </Field>
          </Col>

          <Col md={6}>
            <Field name="balance[tributable]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Tributable" custom type="switch" />}
            </Field>
          </Col>
        </Row>
      )}
      {balanceType === 'discount' && (
        <Row>
          <Col md={6}>
            <Field name="balance[legal]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Legal" custom type="switch" />}
            </Field>
          </Col>
        </Row>
      )}
      {['asset', 'discount'].includes(balanceType) && (
        <Row>
          <Col md={6}>
            <Field name="balance[formulation]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Formulación" custom type="switch" />}
            </Field>
          </Col>

          <Col md={6}>
            <Field name="balance[proportional]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Proporcional" custom type="switch" />}
            </Field>
          </Col>
        </Row>
      )}
      <Row>
        {balanceType === 'asset' && (
          <Col md={6}>
            <Field name="balance[companyCost]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Costo Empresa" custom type="switch" />}
            </Field>
          </Col>
        )}
        {['asset', 'discount'].includes(balanceType) && (
          <Col md={6}>
            <Field name="balance[printOnLiquidation]">
              {({ field }) => (
                <FormikCheckBox {...field} field={field} label="Se imprime en liquidación" custom type="switch" />
              )}
            </Field>
          </Col>
        )}
      </Row>
      {balanceType === 'asset' && (
        <Row>
          <Col md={6}>
            <Field name="balance[compensationBase]">
              {({ field }) => (
                <FormikCheckBox {...field} field={field} label="Base de indemnización" custom type="switch" />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="balance[showZeroValue]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Muestra valor cero" type="switch" />}
            </Field>
          </Col>
        </Row>
      )}

      <Row>
        {['asset', 'discount'].includes(balanceType) && (
          <Col md={6}>
            <Field name="balance[bookkeeperId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  isClearable
                  label="Cuenta Contable"
                  placeholder="Seleccionar Cuenta Contable"
                  values={values.balance}
                  model={[balance, 'bookkeeper']}
                  request={fetchDataBookkeeper}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}

        {balanceType === 'asset' && (
          <Col md={6}>
            <Field name="balance[ineCode]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Agrupación INE"
                  placeholder="Seleccionar Agrupación INE"
                  tooltipText="Código de agrupación de cargos para las empresas que deben responder la
                               Encuesta INE Mensual de Remuneraciones y Costo de la Mano de Obra.
                               Si completa este campo podrá generar automáticamente dicho informe."
                  options={ineCodeEnum}
                  defaultValue={balance.ineCode}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      {['asset', 'discount'].includes(balanceType) && (
        <Row>
          <Col md={6}>
            <Field name="balance[classificationType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Clasificación"
                  placeholder="Seleccionar Tipo de Clasificación"
                  options={classificationTypeEnum}
                  defaultValue={balance.classificationType}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="balance[calculationOrder]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  label="Orden en la liquidación"
                  tooltipText="Define el orden en que se mostrará en la liquidación."
                  fieldName="balance[calculationOrder]"
                  errors={errors}
                  touched={touched}
                  defaultValue={balance.calculationOrder}
                  thousandSeparator=""
                />
              )}
            </Field>

            {/* <Field name="loan[parsedAmount]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    leftAddon="$"
                    fieldName="loan[amount]"
                    value={amount}
                    setFieldValue={setFieldValue}
                    label="Monto Préstamo"
                    errors={errors}
                    touched={touched}
                    disabled={isResolved}
                  />
                )}
              </Field> */}
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12}>
          <Field name="balance[observation]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                rows={3}
                label="Observación"
                placeholder="Cualquier información adicional que desee agregar al concepto."
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                defaultValue={balance.observation}
              />
            )}
          </Field>
        </Col>
      </Row>

      {action === 'edit' && (
        <Row>
          <Col md={6}>
            <Field name="balance[active]">
              {({ field }) => <FormikCheckBox {...field} field={field} custom label="Activo" type="switch" />}
            </Field>
          </Col>
        </Row>
      )}

      <Row className="d-flex justify-content-end mt-1 mb-3">
        <Col md={2}>
          <LinkBtn variant="dark" className="btn-secondary btn-block" block to="/remunerations_settings">
            Cancelar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <Button type="submit" className="btn-block" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { balance } = props;
  return { balance };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: params => params.action !== 'new'
})(ConceptForm);
