import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTicketsAdminRequest = ({
  dispatch,
  params = {},
  responseType,
  format,
  callback,
  successCallback
}) => {
  ApiService.request('get', `/admin/tickets${format || ''}`, {
    dispatch,
    params,
    responseType,
    callback,
    successCallback
  });
};

export const showTicketAdminRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/admin/tickets/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createTicketAdminRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/admin/tickets', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateTicketAdminRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/tickets/${id}/`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const assignUserTicketAdminRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/tickets/${id}/assign_user`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const impersonateAccountAdminRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('post', `/admin/tickets/${id}/impersonate`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexTicketsRequest = AwesomeDebouncePromise(indexTicketsAdminRequest, 300);
