import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const EmptyCard = ({ title, text, colorText, colorCard, colorBorder, ...props }) => (
  <Card bg={colorCard} text={colorText} border={colorBorder} {...props}>
    <Card.Body>
      {title && <Card.Title>{title}</Card.Title>}
      {text}
    </Card.Body>
  </Card>
);

EmptyCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

EmptyCard.defaultProps = {
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null,
  title: ''
};

export default EmptyCard;
