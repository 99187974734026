import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSettlementsRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', '/settlements', {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showSettlementRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/settlements/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const updateSettlementRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/settlements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const debounceIndexSettlementsRequest = AwesomeDebouncePromise(indexSettlementsRequest, 300);
