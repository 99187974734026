/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon, DatatableEmployeeName } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MONTO',
    selector: 'amount',
    cell: item => item.parsed_amount,
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO DOCUMENTO',
    selector: 'workflow_request_status',
    sortable: false,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          variant="circle-info"
          className="btn-circle"
          onClick={() => clickHandler(item, 'show')}
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          variant="circle-primary"
          className="btn-circle"
          onClick={() => clickHandler(item, 'payment_document')}
          text="Generar documento"
        >
          <Icon icon="document" />
        </ButtonTooltip>
        <ButtonTooltip
          variant="circle-success"
          className="btn-circle"
          disabled={item.workflow_request_status !== 'No Emitido'}
          onClick={() => clickHandler(item, 'sign_request')}
          text="Enviar a Firmar"
        >
          <Icon icon="finger-print" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '100px'
  }
]);

export default columns;
