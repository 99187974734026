import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Icon, LinkBtn } from '../../components';
import internalDocumentation from './internalDocumentation';
import createTemplatePdfRequest from '../../requests/templatePdfs';
import RiskPreventionInternalDocumentationForm from './RiskPreventionInternalDocumentationForm';
import { sendAlert } from '../../actions/utils';

const RiskPreventionInternalDocumentationNew = () => {
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = (values, setSubmitting) => {
    setOnRequest(true);
    createTemplatePdfRequest({
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documento creado con éxito' }));
        history.push('/internal_documentation');
      },
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  return (
    <>
      <Row className="m-top mb-5">
        <LinkBtn variant="circle-dark" className="btn-circle" to="/internal_documentation">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <Col>
          <h2 className="text-uppercase mt-1">Nuevo documento interno</h2>
        </Col>
      </Row>
      <RiskPreventionInternalDocumentationForm
        onRequest={onRequest}
        templatePdf={internalDocumentation}
        action="new"
        formRequest={handleCreateRequest}
      />
    </>
  );
};

export default RiskPreventionInternalDocumentationNew;
