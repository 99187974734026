import React from 'react';
import Board from '@lourenci/react-kanban';
import '@lourenci/react-kanban/dist/styles.css';
import './style.scss';

const Kanban = ({ board, ...props }) => {
  return (
    <Board allowRenameColumn={false} disableColumnDrag {...props}>
      {board}
    </Board>
  );
};

export default Kanban;
