import React, { useState } from 'react';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { withFormik, Form } from 'formik';
import EmployeeDncDatatable from '../../DNC/EmployeeDncDatatable';
import { LinkBtn, SimpleCenteredModal } from '../../../components';

const EmployeeDncDatatableProfile = ({ values, isSubmitting, onRequest }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const { employeeDncsAttributes } = values.dnc;

  const showCurrentEmployeeDnc = (employee, show, showStatus) => {
    setModalShow(show);
    setModalBody(
      <div className="mx-3 mt-n2">
        <div className="d-flex text-uppercase">Estado: {showStatus(employee.status, 'ml-2 mt-0 font-weight-bold')}</div>
        <pre>
          <p className="text-justify">{employee.observation !== '' ? employee.observation : 'Sin observaciones'}</p>
        </pre>
      </div>
    );
  };

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad mt-2 center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Form className={`${onRequest && 'bg-opacity'}`}>
        <EmployeeDncDatatable
          boss
          employees={employeeDncsAttributes.filter(item => item.active)}
          showCurrentEmployeeDnc={showCurrentEmployeeDnc}
        />
        <Row className="d-flex justify-content-end my-5">
          <Col md={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
            <LinkBtn block variant="outline-info" className="btn-circle" to="/profile/dnc">
              Cancelar
            </LinkBtn>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting} block>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <SimpleCenteredModal
        title="Observación"
        show={modalShow}
        body={modalBody}
        size="lg"
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

const setInitialValues = props => {
  const { dnc } = props;

  const setDate = date => {
    if (date === '') {
      return '';
    }
    const [month, year] = date.split('/');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, 1);
  };

  return {
    dnc: {
      ...dnc,
      employeeDncsAttributes: dnc.employeeDncsAttributes.map(item => ({
        ...item,
        printableDate: item.suggestedDate,
        suggestedDate: setDate(item.suggestedDate)
      }))
    }
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(EmployeeDncDatatableProfile);
