import { defineAbility, AbilityBuilder } from '@casl/ability';
import jwt from 'jwt-decode';
import camelCaseRecursive from 'camelcase-keys-recursive';

const userRoleAbility = (role, can, cannot) => {
  if (role === 'admin') can('manage', 'admin');
  else cannot('manage', 'admin');
  if (role === 'basic') can('manage', 'basic');
  else cannot('manage', 'basic');
  if (role === 'dt') can('manage', 'dt');
  else cannot('manage', 'dt');
};

const companyModulesAbility = (modules, can, cannot) => {
  if (modules.includes('people_management')) can('manage', 'people_management');
  else cannot('manage', 'people_management');
  if (modules.includes('signature')) can('manage', 'signature');
  else cannot('manage', 'signature');
  if (modules.includes('attendance_management')) can('manage', 'attendance_management');
  else cannot('manage', 'attendance_management');
  if (modules.includes('consultative_dt_access')) can('manage', 'consultative_dt_access');
  else cannot('manage', 'consultative_dt_access');
};

const setUpAbility = (can, cannot) => {
  const payload = localStorage.getItem('jwt');
  const roles = localStorage.getItem('currentRoles');
  if (payload) {
    const currentRoles = roles ? camelCaseRecursive(jwt(roles)) : [];
    const { role, companyModules } = camelCaseRecursive(jwt(payload));
    userRoleAbility(role, can, cannot);
    can('manage', 'profile');
    companyModulesAbility(companyModules, can, cannot);
    currentRoles.forEach(currentRole => can(currentRole[0], currentRole[1]));
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = ability => {
  const { can, cannot, rules } = new AbilityBuilder();
  setUpAbility(can, cannot);
  ability.update(rules);
};
