import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Icon from '../../Icons';
import './style.scss';

const Creatable = ({
  abbr,
  delayHide,
  delayShow,
  defaultValue,
  direction,
  isDisabled,
  error,
  helpText,
  label,
  margin,
  request,
  tooltipText,
  touched,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group className={`${isDisabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon="help-circle" height="15px" width="15px" />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <CreatableSelect
        className={`${isOpen ? 'border-on ' : ''}`}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        placeholder="Buscar..."
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay opciones'}
        maxMenuHeight={115}
        defaultOptions
        formatCreateLabel={inputValue => `Crear "${inputValue}"`}
        createOptionPosition="first"
        isDisabled={isDisabled}
        {...props}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

Creatable.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string
};

Creatable.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: ''
};

export default Creatable;
