import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import { FormikDatePicker, FormikSelect, FormikInput } from '../../components/index';
import { absenceTypes, absenceTypeFormats } from './FormOptions';
import '../../services/yupCustomMethods';

const licenseTypeValues = () => {
  const licenseRegex = new RegExp(/Licencia/);
  return absenceTypes.filter(type => licenseRegex.test(type.label)).map(type => type.value);
};

const AbsenceForm = props => {
  const [typeFormatDisabled, setTypeFormatDisabled] = useState(true);
  const [fieldStartDate, setFieldStartDate] = useState('');
  const [fieldEndDate, setFieldEndDate] = useState('');
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    absence
  } = props;
  const { absenceType, absenceTypeFormat } = absence;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  const handleAbsenceTypeFormat = absenceTypeValue => {
    const typeValue = absenceTypeValue || absenceType;
    if (!licenseTypeValues().includes(typeValue)) {
      setFieldValue(`absence[absenceTypeFormat]`, '');
      setTypeFormatDisabled(true);
    } else {
      setTypeFormatDisabled(false);
    }
  };

  useEffect(handleAbsenceTypeFormat, []);

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Field name="absence[absenceType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Motivo"
                  placeholder="Seleccionar Motivo de Ausencia"
                  options={absenceTypes}
                  defaultValue={absenceType}
                  onChange={data => {
                    handleAbsenceTypeFormat(data.value);
                    setFieldValue(field.name, data ? data.value : '');
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="absence[absenceTypeFormat]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Formato"
                  placeholder="Seleccionar Formato"
                  options={absenceTypeFormats}
                  defaultValue={absenceTypeFormat}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  margin="mb-0"
                  isDisabled={typeFormatDisabled}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="absence[startDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={day => day.isAfter(fieldEndDate)}
                  label="Fecha de Inicio"
                  placeholder="dd/mm/aaaa"
                  onDateChange={date => {
                    setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : '');
                    setFieldStartDate(date !== null ? date : '');
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="absence[endDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={day => day.isBefore(fieldStartDate)}
                  label="Fecha de Término"
                  placeholder="dd/mm/aaaa"
                  onDateChange={date => {
                    setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : '');
                    setFieldEndDate(date !== null ? date : '');
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="absence[observation]">
              {({ field }) => <FormikInput {...field} as="textarea" label="Observaciones" />}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" disabled={isSubmitting} variant={submitVariant} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { employeeId, endDate, observation, startDate, absenceType, absenceTypeFormat } = props.absence;
  return {
    absence: {
      employeeId,
      endDate,
      observation,
      startDate,
      absenceType,
      absenceTypeFormat: absenceTypeFormat || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  absence: Yup.object().shape(
    {
      endDate: Yup.date()
        .required('Debes seleccionar una fecha de término')
        .formatdate()
        .when(
          'startDate',
          (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual a la fecha de inicio')
        ),
      observation: Yup.string().nullable(),
      startDate: Yup.date()
        .required('Debes seleccionar una fecha de inicio')
        .formatdate()
        .when(
          'endDate',
          (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
        ),
      absenceType: Yup.string().required('Debes seleccionar un tipo de ausencia'),
      absenceTypeFormat: Yup.string().when('absenceType', {
        is: val => licenseTypeValues().includes(val),
        then: Yup.string().required('Debes escoger el formato de la licencia'),
        otherwise: Yup.string().nullable()
      })
    },
    [['endDate', 'startDate']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AbsenceForm);
