import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { LongCard, ComponentDataTable, SimpleCenteredModal, DefaultModal } from '../../components'; // CustomGraph
import SubHeader from '../../components/Utils/DataTable/SubHeader';
import { filterArray } from '../../services/utils';
import columns from './EmployeeColumns';
import EmployeeTracingForm from '../EmployeeTracing/EmployeeTracingForm';
import { updateEmployeeEvaluationRequest } from '../../requests/employeeEvaluations';
import { sendAlert } from '../../actions/utils';

const PerformanceEvaluationDashboard = ({
  performanceEvaluation,
  onRequest,
  id,
  setOnRequest,
  setMoreData,
  moreData,
  setKey,
  setDefaultId
}) => {
  const [textInput, setTextInput] = useState('');
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const dispatch = useDispatch();

  const filteredArray = () => {
    setFilterEmployees(
      filterArray(performanceEvaluation.toEvaluate, textInput, [
        'employee[fullName]',
        'employee[nationalIdentification]',
        'empoyee[jobTitle]',
        'employee[jobManagement]',
        'translatedStatus'
      ])
    );
  };

  const clickHandler = (item, action) => {
    switch (action) {
      case 'feedback':
        setModalItem(item);
        setModalShow(true);
        break;
      case 'evaluate':
        setDefaultId(item.id);
        setKey('evaluations');
        break;
      case 'self_evaluation':
        setDefaultModalShow(true);
        setModalItem(item);
        setModalBody(`¿Quieres habilitar la autoevaluación para el trabajador ${item.employee?.fullName}?`);
        break;
      case 'destroy':
        // eslint-disable-next-line no-console
        console.warn('destroy');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleUpdate = (values, setSubmitting) => {
    updateEmployeeEvaluationRequest(id, modalItem.id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Seguimiento ingresado con éxito.' }));
        setMoreData(!moreData);
      },
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  const handleSelfEvaluation = () => {
    setOnRequest(true);
    updateEmployeeEvaluationRequest(id, modalItem.id, {
      dispatch,
      params: { employee_evaluation: { self_evaluation: 'sent' } },
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Autoevaluación enviada con éxito.' }));
        setMoreData(!moreData);
        setDefaultModalShow(false);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  useEffect(() => setFilterEmployees(performanceEvaluation.toEvaluate), [performanceEvaluation.toEvaluate]);
  useEffect(filteredArray, [textInput, performanceEvaluation.toEvaluate]);

  return (
    <>
      <hr />
      <Row>
        <Col md={6}>
          <h4 className="text-uppercase mb-1">Desde - Hasta</h4>
          {performanceEvaluation.startDate} - {performanceEvaluation.endDate}
        </Col>
        <Col md={6}>
          <h4 className="text-uppercase mb-1">Mensaje de Bienvenida</h4>
          {performanceEvaluation.startText}
        </Col>
        <Col md={6}>
          <h4 className="text-uppercase mb-1">Descripción</h4>
          {performanceEvaluation.description}
        </Col>
        <Col md={6}>
          <h4 className="text-uppercase mb-1">Mensaje de Despedida</h4>
          {performanceEvaluation.endText}
        </Col>
      </Row>
      <hr />
      <Row className="mb-4">
        <Col xs={12} md={3}>
          <LongCard variant="warning" title={0} label="Trabajadores Pendientes" />
        </Col>
        <Col xs={12} md={3}>
          <LongCard variant="primary" title={0} label="Evaluaciones completadas" />
        </Col>
      </Row>
      <h4 className="text-uppercase">Trabajadores</h4>
      <ComponentDataTable
        columns={columns(
          clickHandler,
          performanceEvaluation.withSelfEvaluation,
          performanceEvaluation.currentReviewerId > 0
        )}
        data={filterEmployees}
        totalRows={performanceEvaluation.toEvaluate.length}
        pointerOnHover
        onRequest={onRequest}
        sortServer={false}
        subHeaderComponent={<SubHeader setTextInput={setTextInput} textInput={textInput} massAction={false} />}
        paginationServer={false}
        pagination
      />
      <SimpleCenteredModal
        title="Seguimiento"
        size="lg"
        body={
          <EmployeeTracingForm
            employeeEvaluation={modalItem}
            evaluatorId={performanceEvaluation.currentReviewerId}
            formRequest={handleUpdate}
            handleModalClose={() => setModalShow(false)}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <DefaultModal
        title="Autoevaluación"
        body={modalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        handleConfirm={handleSelfEvaluation}
        titleBtnSave="Enviar"
        withClose={false}
        disabled={onRequest}
      />
    </>
  );
};

export default PerformanceEvaluationDashboard;
