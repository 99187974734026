import React, { useEffect, useState } from 'react';
import memoize from 'memoize-one';
import { useDispatch } from 'react-redux';
import { Spinner, Card, Row, Col, Form, FormControl } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { showProfileRequest } from '../../requests/profiles';
import { compare, filterArray } from '../../services/utils';
import { LinkBtn, Icon, ComponentDataTable, DatatableEmployeeName } from '../../components';
import basicProfile from './role';

const columns = memoize(() => [
  {
    name: 'NOMBRE DEL EMPLEADO',
    selector: 'fullName',
    cell: item => <DatatableEmployeeName item={item} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    grow: '1',
    sortable: true
  },
  {
    name: 'ÁREA',
    selector: 'jobManagement',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CARGO',
    selector: 'jobTitle',
    sortable: true,
    grow: '1'
  }
]);

const RoleShow = ({ match }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [textInput, setTextInput] = useState('');
  const [profile, setProfile] = useState(basicProfile);
  const [filterEmployees, setFilterEmployees] = useState(basicProfile.employees);
  const profileId = match.params.id;
  const dispatch = useDispatch();

  const fetchRole = () => {
    const request = async () => {
      setOnRequest(true);
      await showProfileRequest(profileId, {
        dispatch,
        successCallback: response => {
          setProfile(camelCaseRecursive(response.data));
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const subHeaderComponent = () => (
    <Row className="justify-content-end">
      <Col xs={3}>
        <Form.Group>
          <FormControl
            placeholder="Buscar"
            name="textInput"
            value={textInput}
            onChange={e => setTextInput(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );

  const filteredArray = () => {
    setFilterEmployees(filterArray(profile.employees, textInput, ['fullName', 'jobTitle', 'jobManagement']));
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(filteredArray, [textInput, profile]);
  useEffect(fetchRole, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/roles">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">{profile.name}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="warning" className="mt-3" block to={`/roles/${profile.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12} className="d-flex">
          <Card className="flex-fill">
            <Card.Body>
              <Card.Title>Información</Card.Title>
              <div className="info">
                <span className="type">Nombre:</span>
                <span className="line" />
                <span className="answer">{profile.name}</span>
              </div>
              <Card.Title>Áreas {profile.exclude ? 'excluídas' : 'incluídas'}</Card.Title>
              {profile.jobManagements.length > 0 ? (
                <div className="default-scroll-box lower-height">
                  {profile.jobManagements.map(jobManagement => (
                    <div className="info" key={`job-management-${jobManagement.value}`}>
                      {jobManagement.label}
                    </div>
                  ))}
                </div>
              ) : (
                <span>No hay áreas asignadas</span>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xs={12} className="d-flex">
          <Card className="flex-fill">
            <Card.Body>
              <Card.Title>Permisos</Card.Title>
              <div className="default-scroll-box lower-height">
                {profile.roles.map(role => (
                  <div className="info" key={`role-${role.value}`}>
                    {role.label}
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h4 className="text-uppercase mb-3">Trabajadores Asignados</h4>
      <Card>
        <Card.Body>
          <ComponentDataTable
            onRequest={onRequest}
            columns={columns()}
            data={filterEmployees}
            totalRows={filterEmployees.length}
            defaultSortField="fullName"
            pointerOnHover
            sortServer={false}
            onSort={(column, direction) => compare(column, direction, filterEmployees)}
            subHeaderComponent={subHeaderComponent()}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default RoleShow;
