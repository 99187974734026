const basicSettlementGroup = {
  id: '',
  active: true,
  grossAmount: 0,
  justification: '',
  liquidAmount: 0,
  name: '',
  noticeDate: '',
  paymentDate: '',
  settlementsAttributes: [],
  status: '',
  terminationDate: '',
  terminationReason: '',
  uf: ''
};

export default basicSettlementGroup;
