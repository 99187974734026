import React, { useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';

import { FormikNumber, FormikSelect } from '../../../components';

const SettlementAssetForm = ({ settlement }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <h4 className="my-4">Conceptos</h4>
      <Row>
        <Col sm={6} md={9}>
          <p className="font-weight-normal my-2">Sueldo Base</p>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedSalaryAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[salaryAmount]"
                value={settlement.salaryAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={9}>
          <p className="font-weight-normal my-2">Gratificación</p>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedSalaryAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[salaryAmount]"
                value={settlement.salaryAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={9}>
          <p className="font-weight-normal my-2">Bono Fijo Imponible</p>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedSalaryAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[salaryAmount]"
                value={settlement.salaryAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={9}>
          <p className="font-weight-normal my-2">Semana Corrida</p>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedSalaryAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[salaryAmount]"
                value={settlement.salaryAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>

      <h4 className="my-4">Otros Conceptos</h4>
      <Row>
        <Col sm={6} md={9}>
          <Field name="settlement[otherAsset]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label={false}
                placeholder="Seleccionar Concepto"
                options={[]}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedOtherAssetAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                label={false}
                fieldName="settlement[otherAssetAmount]"
                value={settlement.otherAssetAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={9}>
          <Field name="settlement[otherAsset]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label={false}
                placeholder="Seleccionar Concepto"
                options={[]}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedOtherAssetAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                label={false}
                fieldName="settlement[otherAssetAmount]"
                value={settlement.otherAssetAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={9}>
          <Field name="settlement[otherAsset]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label={false}
                placeholder="Seleccionar Concepto"
                options={[]}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedOtherAssetAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                label={false}
                fieldName="settlement[otherAssetAmount]"
                value={settlement.otherAssetAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={9}>
          <Field name="settlement[otherAsset]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label={false}
                placeholder="Seleccionar Concepto"
                options={[]}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedOtherAssetAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                label={false}
                fieldName="settlement[otherAssetAmount]"
                value={settlement.otherAssetAmount}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={6} md={{ span: 3, offset: 6 }} lg={{ span: 2, offset: 7 }}>
          <h5 className="text-uppercase font-weight-bold my-2">Total Haberes</h5>
        </Col>
        <Col sm={6} md={3}>
          <Field name="settlement[parsedTotalAsset]">
            {({ field }) => (
              <FormikNumber
                {...field}
                disabled
                leftAddon="$"
                label={false}
                fieldName="settlement[totalAsset]"
                value={settlement.totalAsset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default SettlementAssetForm;
