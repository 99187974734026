import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn } from '../../components';
import { useAuthorization } from '../../services/hooks';
import PerformanceEvaluationDataTable from './PerformanceEvaluationDataTable';
import columns from './Columns';

const PerformanceEvaluationIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="mt-2 mb-1">Evaluación del Talento</h2>
        </Col>
        {useAuthorization('create', 'PerformanceEvaluation') && (
          <Col md={2}>
            <LinkBtn variant="primary" className="mt-2" block to="/performance_evaluations/new">
              Nuevo
            </LinkBtn>
          </Col>
        )}
      </Row>
      <PerformanceEvaluationDataTable columns={columns} />
    </>
  );
};

export default PerformanceEvaluationIndex;
