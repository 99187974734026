import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';

import {
  indexEmployeeReviewerTemplatesRequest,
  debounceIndexEmployeeReviewerTemplatesRequest
} from '../../requests/employeeReviewerTemplates';
import SimpleCenteredModal from '../Utils/Modal/SimpleCenteredModal';
import EmployeeSearchModal from '../Employee/EmployeeSearchModal';
import { InputSelect } from '../Utils/Select';
import { ButtonTooltip } from '../Utils/Tooltips';
import Icon from '../Icons';

const ReviewerNestedFields = ({
  attributesPath,
  setFieldValue,
  index,
  attributeEmployeeReviewers,
  attributeReviewerIds,
  errors,
  touched
}) => {
  const [employeeReviewerTemplates, setEmployeeReviewerTemplates] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const fetchEmployeeReviewers = () => {
    setFieldValue(`${attributesPath}[${index}][position]`, index + 1);
    indexEmployeeReviewerTemplatesRequest({
      dispatch,
      params: { sort_column: 'name', active: true, paginate: false },
      successCallback: response => setEmployeeReviewerTemplates(camelCaseRecursive(response.data))
    });
  };

  const updatePositionValue = () => {
    setFieldValue(`${attributesPath}[${index}][position]`, index + 1);
  };

  useEffect(fetchEmployeeReviewers, []);
  useEffect(updatePositionValue, [index]);

  const fetchReviewers = (inputValue, callback) => {
    debounceIndexEmployeeReviewerTemplatesRequest({
      dispatch,
      params: { filter_name: inputValue, sort_column: 'name', sort_direction: 'asc', active: true, paginate: false },
      successCallback: data => callback(camelCaseRecursive(data.data))
    });
  };

  const mapEmployeeReviewers = (field, data) => {
    setFieldValue(`${field}[${attributeEmployeeReviewers}]`, []);
    data.forEach((selected, i) => {
      setFieldValue(`${field}[${attributeEmployeeReviewers}][${i}][employeeId]`, selected.value);
      setFieldValue(`${field}[${attributeEmployeeReviewers}][${i}][id]`, selected.id);
      setFieldValue(`${field}[${attributeEmployeeReviewers}][${i}][employeeType]`, selected.employeeType);
    });
    setFieldValue(`${field}[${attributeReviewerIds}]`, data);
  };

  const handleSearch = selectedEmployees => {
    const field = `${attributesPath}[${index}]`;
    const data = employeeReviewerTemplates.filter(item => selectedEmployees.includes(item.value));
    setFieldValue(`${field}[${attributeEmployeeReviewers}]`, []);
    data.forEach((selected, i) => {
      setFieldValue(`${field}[${attributeEmployeeReviewers}][${i}][employeeId]`, selected.value);
      setFieldValue(`${field}[${attributeEmployeeReviewers}][${i}][id]`, selected.id);
      setFieldValue(`${field}[${attributeEmployeeReviewers}][${i}][employeeType]`, selected.employeeType);
    });
    setFieldValue(`${field}[${attributeReviewerIds}]`, data);
    setModalShow(false);
  };

  const approvalPath = `${attributesPath}[${index}][approval]`;
  const noAction = getIn(errors, approvalPath) && getIn(touched, approvalPath);

  return (
    <>
      <Col md={3}>
        <Row style={{ marginTop: '30px' }}>
          <Col className="d-flex justify-content-center align-items-center">
            <span className="dot-workflow dot-info">{index + 1}</span>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <Field name={`${attributesPath}[${index}][approval]`}>
              {({ field }) => (
                <ButtonTooltip
                  variant={`${field.value ? 'circle-primary' : 'circle-dark'}`}
                  className="btn-circle"
                  text="Aprobar"
                  onClick={() => {
                    setFieldValue(field.name, !field.value);
                    if (!field.value) setFieldValue(`${attributesPath}[${index}][signature]`, false);
                  }}
                >
                  <Icon icon="checkmark-circle" />
                </ButtonTooltip>
              )}
            </Field>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <Field name={`${attributesPath}[${index}][signature]`}>
              {({ field }) => (
                <ButtonTooltip
                  variant={`${field.value ? 'circle-warning' : 'circle-dark'}`}
                  className="btn-circle"
                  text="Firmar"
                  onClick={() => {
                    setFieldValue(field.name, !field.value);
                    if (!field.value) setFieldValue(`${attributesPath}[${index}][approval]`, false);
                  }}
                >
                  <Icon icon="create" />
                </ButtonTooltip>
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Form.Group className={`${noAction ? 'is-invalid' : ''}`}>
              {noAction && <Form.Text className="text-danger">{getIn(errors, approvalPath)}</Form.Text>}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col md={7}>
        <Field name={`${attributesPath}[${index}][${attributeReviewerIds}]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr
              label="Responsables"
              isMulti
              defaultOptions={employeeReviewerTemplates}
              placeholder="Seleccionar Responsables"
              request={fetchReviewers}
              onChange={data => mapEmployeeReviewers(`${attributesPath}[${index}]`, data || [])}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={1} className="ml-0 pl-0 mt-3">
        <ButtonTooltip
          variant="circle-primary"
          className="advance-search"
          text="Búsqueda Avanzada"
          onClick={() => setModalShow(true)}
        >
          <Icon className="w-100 h-100" icon="people-circle" />
        </ButtonTooltip>
      </Col>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ReviewerNestedFields;
