const amountTypeEnum = [
  { label: 'Igual para todos', value: 'distribute' },
  { label: 'Distinto por persona', value: 'different' }
];

const calculationTypeAssetEnum = [
  { label: 'Fijo', value: 'fixed' },
  { label: 'Proporcional Ausencia', value: 'proportional_absence' }
];

const calculationTypeDisEnum = [
  { label: 'Fijo', value: 'fixed' },
  { label: 'Proporcional Ausencia', value: 'proportional_absence' }
];

const defaultToShowEnum = [
  { label: 'Contrato', value: 'work_contract' },
  { label: 'Prenómina', value: 'prenomine' }
];

const discountTypeEnum = [
  { label: 'Descuento Voluntario', value: 'voluntary' },
  { label: 'Retención Judicial', value: 'judicial_retention' },
  { label: 'Cuota Sindical', value: 'sindical_quota' }
];

const ineCodeEnum = [
  { label: 'Sueldos Base: Fijo y por Semana Corrida', value: 'base_salary' },
  { label: 'Otros Sueldos y Salarios básicos y permanentes', value: 'other_salary' },
  { label: 'Sueldos y Salarios por Funciones ocasionales', value: 'occasional_salary' },
  { label: 'Incentivos y Premios Pagados al Trabajador', value: 'incentive' },
  { label: 'Pagos por trato', value: 'treatment_pay' },
  { label: 'Pagos por Horas Extraordinarias', value: 'overtime_pay' },
  { label: 'Comisiones pagadas por Ventas', value: 'commissions_paid' },
  { label: 'Reembolsos de gastos del trabajador por causas del trabajo', value: 'refund' },
  { label: 'Participaciones, gratificaciones y pagos adicionales NO mensuales', value: 'additional_pay' },
  { label: 'Pagos en especies y gastos de vivienda del trabajador', value: 'living_expenses' },
  { label: 'Pagos directos al trabajador de Servicio de Bienestar', value: 'welfare_service' },
  { label: 'Aportes Patronales por Seguros obligatorios para sus trabajadores', value: 'employer_insurance_pay' },
  { label: 'Gastos de los Serv. de bienestar y de capacitación del trabajador', value: 'training_expense' },
  { label: 'Indemnizaciones por término de la relación de trabajo', value: 'finished_compensation' },
  { label: 'Otros costos de la Mano de Obra', value: 'manpower_expense' }
];

const remunerationTypeEnum = [
  { label: 'Fijo', value: 'permanent' },
  { label: 'Variable', value: 'variable' },
  { label: 'Ocasional', value: 'occasional' },
  { label: 'Variable + Afecto a Semana Corrida', value: 'variable_whole_week' }
];

const unitOfAccountEnum = [
  { label: 'UF', value: 'uf' },
  { label: 'Pesos', value: 'pesos' }
];

const judicialRetentionEnum = [
  { label: 'Alcance Líquido', value: 'liquid_reach' },
  { label: 'Sueldo Mínimo', value: 'min_wage' },
  { label: 'No Remuneracional', value: 'no_remunerational' },
  { label: 'Pesos', value: 'pesos' },
  { label: 'UF', value: 'uf' },
  { label: 'UTM', value: 'utm' }
];

export {
  amountTypeEnum,
  calculationTypeAssetEnum,
  calculationTypeDisEnum,
  defaultToShowEnum,
  discountTypeEnum,
  ineCodeEnum,
  remunerationTypeEnum,
  unitOfAccountEnum,
  judicialRetentionEnum
};
