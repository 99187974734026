import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { Field, getIn, useFormikContext, withFormik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { FormikSelect, FormikNumber, SimpleCenteredModal, FormikInput, ButtonTooltip, Icon } from '../../components';
import { showEmployeeEvaluationRequest } from '../../requests/employeeEvaluations';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const binaryRequirements = [
  { label: 'Sí', value: 2 },
  { label: 'No', value: 1 }
];

const RequireAmountField = ({ skill, index, disabled }) => {
  const { setFieldValue, setFieldTouched, errors, touched, values } = useFormikContext();
  const path = `employeeEvaluation[employeeEvaluationSkillsAttributes][${index}]`;
  const likertOptions = skill.questionChoicesAttributes.map(item => ({ label: item.label, value: item.position }));

  switch (skill.requireAmountType) {
    case 'binary':
      return (
        <Field name={`employeeEvaluation[employeeEvaluationSkillsAttributes][${index}][grade]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              label=""
              placeholder="Seleccionar"
              options={binaryRequirements}
              defaultValue={getIn(values, `${path}[grade]`)}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              isDisabled={disabled}
            />
          )}
        </Field>
      );
    case 'likert':
      return (
        <Field name={`employeeEvaluation[employeeEvaluationSkillsAttributes][${index}][grade]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              label=""
              placeholder="Seleccionar"
              options={likertOptions}
              defaultValue={getIn(values, `${path}[grade]`)}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              isDisabled={disabled}
            />
          )}
        </Field>
      );
    default:
      return (
        <Field name={`employeeEvaluation[employeeEvaluationSkillsAttributes][${index}][parsedGrade]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              label=""
              fieldName={`employeeEvaluation[employeeEvaluationSkillsAttributes][${index}][grade]`}
              setFieldValue={setFieldValue}
              leftAddon={skill.requireAmountType === 'percentage' ? '%' : false}
              defaultValue={getIn(values, `${path}[grade]`)}
              errors={errors}
              touched={touched}
              disabled={disabled}
              isAllowed={data => {
                if (skill.requireAmountType === 'percentage') {
                  return data.value <= 100;
                }
                return true;
              }}
            />
          )}
        </Field>
      );
  }
};

const EmployeeEvaluationSkillForm = ({
  performanceEvaluation,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  values,
  selfEvaluation,
  defaultId
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { id, employeeEvaluationSkillsAttributes } = values.employeeEvaluation;
  const dispatch = useDispatch();
  const { skillsAttributes, toEvaluate, currentReviewerId } = performanceEvaluation;
  const options = toEvaluate.map(item => ({ value: item.id, label: item.employee?.fullName }));

  const refreshValues = response => {
    const { employeeEvaluationSkillsAttributes: fetchedSkills } = camelCaseEmptyStringRecursive(response.data);
    setFieldValue('employeeEvaluation[employeeEvaluationSkillsAttributes]', []);
    const newSkills = [];
    let filtered = fetchedSkills.filter(skill => skill.evaluatorId === currentReviewerId);
    if (selfEvaluation) {
      filtered = fetchedSkills.filter(skill => skill.selfEvaluation);
    }
    if (fetchedSkills.length === 0 || filtered.length === 0) {
      skillsAttributes.forEach(skill => {
        newSkills.push({
          id: '',
          grade: '',
          observation: '',
          evaluatorId: selfEvaluation ? defaultId : currentReviewerId,
          skillId: skill.id
        });
      });
    } else {
      filtered.forEach(skill => {
        newSkills.push({
          id: skill.id,
          grade: skill.grade,
          observation: skill.observation,
          evaluatorId: skill.evaluatorId,
          skillId: skill.skillId
        });
      });
    }
    setFieldValue('employeeEvaluation[employeeEvaluationSkillsAttributes]', newSkills);
  };

  const fetchEmployeeEvaluation = () => {
    if (id !== '') {
      setOnRequest(true);
      showEmployeeEvaluationRequest(performanceEvaluation.id, id, {
        dispatch,
        successCallback: refreshValues,
        callback: () => setOnRequest(false)
      });
    }
  };

  useEffect(fetchEmployeeEvaluation, [id]);

  const disabled = onRequest || isSubmitting || id === '';

  return (
    <Form>
      {onRequest && (
        <div className="containerSpinnerLoad center-spinner ml-n2 ml-lg-n0 w-100">
          <Spinner className="mb-4" animation="border" variant="primary" />
        </div>
      )}
      <hr />
      <div className={`${onRequest && 'bg-opacity'}`}>
        {!selfEvaluation && (
          <Row className="mb-3">
            <Col md={4}>
              <Field name="employeeEvaluation[id]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Trabajador"
                    placeholder="Seleccionar Trabajador"
                    defaultValue={getIn(values, field.name)}
                    options={options}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    isClearable
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col className="ml-auto" xs={6} md={2} xl={2}>
              <Button type="submit" variant="primary" className="mt-4" disabled={disabled}>
                Evaluar
              </Button>
            </Col>
          </Row>
        )}
        {id !== '' && (
          <>
            <Row className="mb-1">
              <Col md={6}>
                <p className="font-weight-bold text-uppercase mb-1">Competencia / Objetivo</p>
              </Col>
              <Col md={4}>
                <p className="font-weight-bold text-uppercase mb-1">% de Aprobación</p>
              </Col>
              {!selfEvaluation && (
                <Col md={2}>
                  <p className="font-weight-bold text-uppercase mb-1">Observaciones</p>
                </Col>
              )}
            </Row>
            <hr className="mt-1" />

            {employeeEvaluationSkillsAttributes.map((skillEvaluation, index) => {
              const skill = skillsAttributes.find(item => item.id === skillEvaluation.skillId);
              return (
                <>
                  <Row>
                    <Col md={6}>
                      <p>
                        {skill?.name}
                        {skill?.description.length > 0 ? ` - ${skill.description}` : ''}
                      </p>
                    </Col>
                    <Col md={4}>
                      <RequireAmountField skill={skill} index={index} disabled={disabled} />
                    </Col>
                    {!selfEvaluation && (
                      <Col>
                        <ButtonTooltip
                          onClick={() => {
                            setModalShow(true);
                            setCurrentIndex(index);
                          }}
                          variant="circle-warning"
                          className="btn-circle"
                          text="Agregar Observación"
                          disabled={disabled}
                        >
                          <Icon icon="pencil" />
                        </ButtonTooltip>
                      </Col>
                    )}
                  </Row>
                  <hr />
                </>
              );
            })}
          </>
        )}
        <SimpleCenteredModal
          title="Agregar Observación"
          body={
            <Modal.Body>
              <Field name={`employeeEvaluation[employeeEvaluationSkillsAttributes][${currentIndex}][observation]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    as="textarea"
                    label="Observación"
                    minInputHeight="150"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Modal.Body>
          }
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
      {selfEvaluation && (
        <Row className="d-flex justify-content-end mt-5 mt-md-3 mb-3">
          <Col md={3} xl={2}>
            <Button type="submit" disabled={disabled} block>
              Enviar
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const setInitialValues = props => {
  const { defaultId } = props;
  return {
    employeeEvaluation: {
      id: defaultId,
      employeeEvaluationSkillsAttributes: []
    }
  };
};

const validationSchema = Yup.object().shape({
  employeeEvaluation: Yup.object().shape({
    id: Yup.string().required('Debes ingresar un trabajador'),
    employeeEvaluationSkillsAttributes: Yup.array().of(
      Yup.object().shape({
        skillId: Yup.string().required('Debes ingresar una pregunta'),
        grade: Yup.number().required('Debes ingresar un valor de aprobación'),
        observation: Yup.string().max(3000, 'Máximo 3000 caracteres permitidos')
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting, resetForm }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting, resetForm);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(EmployeeEvaluationSkillForm);
