import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { showPerformanceEvaluationRequest } from '../../requests/performanceEvaluations';
import basicPerformanceEvaluation from './performanceEvaluation';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { useSetTab } from '../../services/hooks';
import { updateEmployeeEvaluationRequest } from '../../requests/employeeEvaluations';
import PerformanceEvaluationDashboard from './PerformanceEvaluationDashboard';
import PerformanceEvaluationDetails from './PerformanceEvaluationDetails';
import EmployeeEvaluationSkillForm from '../EmployeeEvaluationSkill/EmployeeEvaluationSkillForm';
import { sendAlert } from '../../actions/utils';
import './style.scss';

const PerformanceEvaluationShow = ({ match, location }) => {
  const { id } = match.params;
  const [key, setKey] = useSetTab('dashboard', location);
  const [defaultId, setDefaultId] = useState('');
  const [performanceEvaluation, setPerformanceEvaluation] = useState(basicPerformanceEvaluation);
  const [onRequest, setOnRequest] = useState(true);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const fetchPerformanceEvaluation = () => {
    const request = async () => {
      setOnRequest(true);
      showPerformanceEvaluationRequest(id, {
        dispatch,
        successCallback: response => setPerformanceEvaluation(camelCaseEmptyStringRecursive(response.data)),
        callback: () => setOnRequest(false)
      });
    };
    request();
  };

  const handleUpdate = (values, setSubmitting, resetForm) => {
    updateEmployeeEvaluationRequest(id, values.employeeEvaluation.id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Evaluación ingresada con éxito.' }));
        setMoreData(!moreData);
        setDefaultId('');
        resetForm();
        setDefaultId(values.employeeEvaluation.id);
      },
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  useEffect(fetchPerformanceEvaluation, [id, moreData]);

  return (
    <>
      <Row className="m-top mb-4">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" to="/performance_evaluations">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase">{performanceEvaluation.name}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn block variant="warning" to={`/performance_evaluations/${id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="dashboard">
              Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="details">
              Detalles
            </Nav.Link>
          </Nav.Item>
          {performanceEvaluation.currentReviewerId > 0 && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="evaluations">
                Evaluar
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="results" disabled>
              Resultados
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="dashboard">
            <PerformanceEvaluationDashboard
              performanceEvaluation={performanceEvaluation}
              id={id}
              onRequest={onRequest}
              setOnRequest={setOnRequest}
              moreData={moreData}
              setMoreData={setMoreData}
              setKey={setKey}
              setDefaultId={setDefaultId}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="details">
            <PerformanceEvaluationDetails performanceEvaluation={performanceEvaluation} />
          </Tab.Pane>
          {performanceEvaluation.currentReviewerId > 0 && (
            <Tab.Pane eventKey="evaluations">
              <EmployeeEvaluationSkillForm
                performanceEvaluation={performanceEvaluation}
                formRequest={handleUpdate}
                defaultId={defaultId}
              />
            </Tab.Pane>
          )}
          <Tab.Pane eventKey="results"></Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PerformanceEvaluationShow;
