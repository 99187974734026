import React, { useState, useMemo, useEffect } from 'react';
import memoize from 'memoize-one';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { ComponentDataTable, ButtonTooltip, Icon, LinkBtn } from '../../components';
import {
  exportBankTransferRequest,
  paymentTypeSummaryRequest,
  transferenceSummaryRequest
} from '../../requests/bankTransfers';
import { downloadFile } from '../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'DOCUMENTO',
    selector: 'name',
    sortable: true,
    grow: '5'
  },
  {
    name: 'GENERAR',
    cell: item => (
      <ButtonTooltip
        onClick={() => clickHandler(item)}
        variant="circle-info"
        className="btn-circle"
        size="sm"
        text="Descargar"
      >
        <Icon icon="cloud-download" />
      </ButtonTooltip>
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true
  }
]);

const ReportBank = ({ history }) => {
  const { ids, backUrl, type, date } = history.location.state;
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const reports = useMemo(
    () => [
      { name: 'Banco BBVA', code: 'bbva', format: '.text' },
      { name: 'Banco BCI', code: 'bci', format: '.text' },
      { name: 'Banco Bice', code: 'bice', format: '.text' },
      { name: 'Banco Corpbanca', code: 'corpbanca', format: '.text' },
      { name: 'Banco Estado (ancho fijo)', code: 'estado', format: '.text' },
      { name: 'Banco Itau', code: 'itau', format: '.text' },
      { name: 'Banco Santander (versión texto)', code: 'santander', format: '.text' },
      { name: 'Banco Security', code: 'security', format: '.text' },
      { name: 'Banco de Chile', code: 'chile', format: '.text' },
      { name: 'Cheque', code: 'checks' },
      { name: 'Citibank (TXT 1024 caracteres)', code: 'citibank', format: '.text' },
      { name: 'Resumen de transferencias', code: 'transference_summary' },
      { name: 'Resumen por forma de pago', code: 'payment_type_summary' },
      { name: 'Scotiabank (estandar)', code: 'scotiabank', format: '.text' },
      { name: 'Scotiabank (bdd)', code: 'scotiabank2', format: '.text' }
    ],
    []
  );

  const successCallback = response => {
    downloadFile(response);
  };

  const callback = () => {
    setOnRequest(false);
  };

  const handleActions = async item => {
    const bankTransferParams = { bank: item.code, type, ids, date };
    setOnRequest(true);
    switch (item.code) {
      case 'transference_summary':
        transferenceSummaryRequest({ dispatch, params: { ...bankTransferParams }, successCallback, callback });
        break;
      case 'payment_type_summary':
        paymentTypeSummaryRequest({ dispatch, params: { ...bankTransferParams }, successCallback, callback });
        break;
      case 'checks':
        paymentTypeSummaryRequest({ dispatch, params: { ...bankTransferParams }, successCallback, callback });
        break;
      default:
        exportBankTransferRequest({
          dispatch,
          params: { ...bankTransferParams },
          format: item.format,
          successCallback,
          callback
        });
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="m-top mb-2">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to={backUrl}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase">Documentos Bancarios</h2>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={reports}
        subHeaderComponent={false}
        sortServer={false}
        defaultSortField="name"
        onRowClicked={item => handleActions(item)}
        pointerOnHover
        preName="reports"
      />
    </>
  );
};

export default ReportBank;
