import { withFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Nav, Tab } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { BasicCard, FocusErrorProcessLicense, Icon } from '../../components';

import { indexCommunesWithCodeRequest } from '../../requests/communes';
import RemunerationsTab, {
  remunerationsAttribute
} from './LicenseImedTransactForm/TabLicenseTransact/RemunerationsTab';
import CompanyTab from './LicenseImedTransactForm/TabLicenseTransact/CompanyTab';
import EmployeeTab from './LicenseImedTransactForm/TabLicenseTransact/EmployeeTab';
import PreviousLicensesTab from './LicenseImedTransactForm/TabLicenseTransact/PreviousLicensesTab';
import { letterBox, payingEntityCode, socialSecurityCode, typeLicense, typePensionScheme } from './FormOptions';

const LicenseImedTransactForm = props => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    numLicense,
    lemZa,
    handleSubmit,
    listRegion,
    listWorkQuality,
    rutEmployee,
    anotherOccupation,
    setAnotherOccupation,
    setIsInp,
    isInp,
    isSubmitting,
    employee,
    payrolls,
    setLicenseBack,
    licenseBack
  } = props;
  const [nameLicenseType, setNameLicenseType] = useState('');
  const [listCommuneCompin, setListCommuneCompin] = useState([]);
  const [listPayingEntityCode, setListPayingEntityCode] = useState([]);
  const dispatch = useDispatch();
  const [tab, setTab] = useState('company');
  const [listPensionInstitution, setListPensionInstitution] = useState([]);
  const currentValues = values.transact;
  const [listLetterBox, setListLetterBox] = useState([]);
  const {
    codeTypePensionScheme,
    codeWorkActivity,
    codeRegionCompinId,
    codeCommuneCompin,
    codePensionScheme,
    codePayingEntity,
    codeOcupation,
    codeWorkerQuality,
    processingCodeCCAF
  } = currentValues;

  useEffect(() => {
    if (typeLicense.length > 0 && lemZa.codeLicense !== '') {
      const filterStatus = typeLicense.filter(element => {
        return element.value.toString() === lemZa.codeLicense.toString();
      });
      setNameLicenseType(filterStatus[0].label);
    }
  }, [lemZa]);

  const Commune = region => {
    indexCommunesWithCodeRequest({
      dispatch,
      params: region,
      successCallback: response => {
        setListCommuneCompin(camelCaseRecursive(response.data.data));
      }
    });
  };

  const filterSocialSecurityCode = value => {
    const filterPensionInstitution = socialSecurityCode.filter(element => {
      return element.type_p_s.toString() === value.toString();
    });
    setListPensionInstitution(filterPensionInstitution);
  };

  const filterPayingEntityCode = value => {
    let result = [];
    if (value === '5' || value === '6') {
      result = payingEntityCode.filter(element => {
        return element.type.toString() === '1';
      });
    }

    if ((value >= '1' && value <= '4') || value === '7') {
      result = payingEntityCode.filter(element => {
        return element.type.toString() === '2';
      });
    }

    setListPayingEntityCode(result);
  };

  const handlePensionInstitution = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');
    setFieldValue('transact[codePensionScheme]', '');
    filterSocialSecurityCode(data.value);
    if (data.value === 1) {
      setIsInp(false);
    }
    if (data.value !== 1) {
      setIsInp(true);
    }
  };

  const filterLetterBoxCode = value => {
    const filterLetterBox = letterBox.filter(element => {
      return element.s_s_code.toString() === value.toString();
    });
    setListLetterBox(filterLetterBox);
  };
  const handleCodePensionInstitution = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');
    setFieldValue('transact[codeLetterBox]', '');
    filterLetterBoxCode(data.value);
  };

  const handleRegionChange = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');
    setFieldValue('transact[codeCommuneCompin]', '');
    Commune({
      region: data.value,
      paginate: false
    });
  };

  const handleOccuppation = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');

    if (data) {
      if (data.value === 19) {
        setAnotherOccupation(false);
      }

      if (data.value !== 19) {
        setFieldValue('transact[otherOcupation]', '');
        setAnotherOccupation(true);
      }
    }
  };
  const handleTypePensionScheme = () => {
    if (codeTypePensionScheme) filterSocialSecurityCode(codeTypePensionScheme);
  };
  const handleCodePensionScheme = () => {
    if (codePensionScheme) filterLetterBoxCode(codePensionScheme);
  };

  const handleRegionCompinId = () => {
    if (codeRegionCompinId)
      Commune({
        region: codeRegionCompinId,
        paginate: false
      });
  };

  const handlePayingEntityCode = () => {
    if (lemZa.codeLicense) filterPayingEntityCode(lemZa.codeLicense);
  };

  useEffect(handleTypePensionScheme, [codeTypePensionScheme]);
  useEffect(handleCodePensionScheme, [codePensionScheme]);
  useEffect(handleRegionCompinId, [codeRegionCompinId]);
  useEffect(handlePayingEntityCode, [lemZa]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={12}>
          <BasicCard
            divBody
            text={`Licencia médica electrónica con folio ${numLicense} de tipo "${nameLicenseType}"
                  otorgada para el trabajador (${lemZa.rutWorkerZa}) ${lemZa.namesWorkerZA} ${
              lemZa.paternalSurnameWorkerZA
            }
                  ${lemZa.maternalSurnameWorkerZA || ''} el día ${lemZa.sleepStartDate},  que inicia reposo total por
                  ${lemZa.dayRest} ${lemZa.dayRest === 1 ? 'día' : 'días'} desde el ${lemZa.sleepStartDate}
                  hasta el ${lemZa.sleepEndDate}`}
            subtitle=""
            size="100%"
            title="Licencia"
          />
        </Col>
      </Row>

      <FocusErrorProcessLicense setTab={setTab} />
      <Tab.Container id="transact-form-tabs" activeKey={tab} onSelect={k => setTab(k)}>
        <Nav variant="pills" fill className="nav-admin mb-5 mt-5">
          <Nav.Item>
            <Nav.Link eventKey="company">
              <Icon icon="briefcase-outline" />
              EMPLEADOR
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="employee">
              <Icon icon="people-outline" />
              TRABAJADOR
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="remuneration">
              <Icon icon="wallet-outline" />
              REMUNERACIONES RENTAS Y/O SUBSIDIOS
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="previous_licenses">
              <Icon icon="wallet-outline" />
              LICENCIAS ANTERIORES
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="company">
            <CompanyTab
              values={values}
              errors={errors}
              touched={touched}
              codeWorkActivity={codeWorkActivity}
              listRegion={listRegion}
              codeRegionCompinId={codeRegionCompinId}
              handleRegionChange={handleRegionChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              listCommuneCompin={listCommuneCompin}
              codeCommuneCompin={codeCommuneCompin}
            />
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="employee">
            <EmployeeTab
              handleOccuppation={handleOccuppation}
              setFieldTouched={setFieldTouched}
              errors={errors}
              touched={touched}
              values={values}
              anotherOccupation={anotherOccupation}
              listTypePensionScheme={typePensionScheme}
              codeTypePensionScheme={codeTypePensionScheme}
              codePensionScheme={codePensionScheme}
              handlePensionInstitution={handlePensionInstitution}
              listPensionInstitution={listPensionInstitution}
              listLetterBox={listLetterBox}
              listPayingEntityCode={listPayingEntityCode}
              codePayingEntity={codePayingEntity}
              codeOcupation={codeOcupation}
              codeWorkerQuality={codeWorkerQuality}
              handleCodePensionInstitution={handleCodePensionInstitution}
              setFieldValue={setFieldValue}
              isInp={isInp}
              listWorkQuality={listWorkQuality}
              processingCodeCCAF={processingCodeCCAF}
            />
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="remuneration">
            <RemunerationsTab
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              listTypePensionScheme={typePensionScheme}
              listPensionInstitution={listPensionInstitution}
              rutEmployee={rutEmployee}
              numLicense={numLicense}
              employee={employee}
              payrolls={payrolls}
            />
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="previous_licenses">
            <PreviousLicensesTab
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              rutEmployee={rutEmployee}
              numLicense={numLicense}
              setLicenseBack={setLicenseBack}
              licenseBack={licenseBack}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <Row className="justify-content-end mb-5">
        <Button type="submit" disabled={isSubmitting}>
          Tramitar Licencia
        </Button>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { transact } = props;
  return { transact };
};

const validationSchema = Yup.object().shape({
  transact: Yup.object().shape({
    companyName: Yup.string()
      .required('Debes ingresar la razón social')
      .max(120, 'Deben ser menos que 120 caracteres'),

    companyRut: Yup.string()
      .required('Debes ingresar el rut')
      .required('Debes ingresar un rut valido'),
    companyPhone: Yup.object().shape({
      phone: Yup.string().required('Debes ingresar el teléfono')
    }),
    codeTypePensionScheme: Yup.string()
      .nullable()
      .required('Debes seleccionar un régimen previsional'),
    codeOcupation: Yup.string()
      .nullable()
      .required('Debes seleccionar una ocupación'),
    codePensionScheme: Yup.string()
      .nullable()
      .required('Debes seleccionar una institución previsional'),
    codeWorkerQuality: Yup.string()
      .nullable()
      .required('Debes seleccionar calidad del trabajador'),
    affiliationDate: Yup.date()
      .required('Debes ingresar la fecha de afiliación previsional')
      .formatdate(),
    dateContract: Yup.date()
      .required('Debes ingresar la fecha del contrato')
      .formatdate(),
    codePayingEntity: Yup.string().required('Debes seleccionar una entidad pagadora'),
    remuneration: Yup.array().of(remunerationsAttribute)
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};
export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(LicenseImedTransactForm);
