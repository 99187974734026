import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form as BSForm } from 'react-bootstrap';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

const SurveyPreview = ({ survey, sendSurvey, isSubmitting, outOfDate }) => (
  <Container className="my-3">
    <pre>
      <p className="text-muted text-justify">{survey.description}</p>
    </pre>
    <pre>
      <p className="text-muted text-justify">{survey.introText}</p>
    </pre>
    <Form>
      {survey.questionsAttributes.map((question, index) => {
        return (
          <QuestionPreview
            question={question}
            index={index}
            key={`question-${question.id}`}
            sendSurvey={sendSurvey}
            outOfDate={outOfDate}
          />
        );
      })}
      <pre>
        <p className="text-muted text-justify my-3">{survey.endText}</p>
      </pre>
      {sendSurvey && (
        <Row className="d-flex justify-content-end my-5">
          <Col md={2}>
            {outOfDate && <p className="text-danger text-center font-weight-bold">Encuesta fuera de plazo</p>}
            <Button type="submit" variant="primary" block disabled={isSubmitting || outOfDate}>
              Enviar
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  </Container>
);

const QuestionPreview = ({ question, index, sendSurvey, outOfDate }) => {
  const { setFieldValue, errors, touched } = useFormikContext();
  const fieldName = `employeeSurvey[employeeQuestionChoicesAttributes][${index}]`;
  const error = getIn(errors, `${fieldName}[questionChoiceId]`);
  const touch = getIn(touched, `${fieldName}[questionId]`);
  const isError = error && touch;

  const setInitialValues = () => {
    setFieldValue(`${fieldName}[questionId]`, question.id);
  };

  useEffect(setInitialValues, []);
  return (
    <div className={`mb-4 ${isError ? 'card-error' : ''}`}>
      <h4 className="mb-2 mt-3">{`${index + 1}. ${question.label}`}</h4>
      <p>
        Selecciona una opción <span className="text-danger">*</span>
      </p>
      <Field name={`${fieldName}[questionChoiceId]`}>
        {({ field }) =>
          question.questionType === 'likert' ? (
            <div className="likert">
              {question.questionChoicesAttributes.map(choice => (
                <ChoicePreview
                  choice={choice}
                  field={field}
                  checkClass="likert-choice"
                  index={index}
                  sendSurvey={sendSurvey}
                  outOfDate={outOfDate}
                />
              ))}
            </div>
          ) : (
            question.questionChoicesAttributes.map(choice => (
              <ChoicePreview
                choice={choice}
                field={field}
                checkClass="question-choice"
                index={index}
                sendSurvey={sendSurvey}
                outOfDate={outOfDate}
              />
            ))
          )
        }
      </Field>
      {isError && <BSForm.Text className="text-danger mb-3">{error}</BSForm.Text>}
    </div>
  );
};

const ChoicePreview = ({ choice, field, checkClass, index, sendSurvey, outOfDate }) => {
  const choiceRef = useRef();
  const { setFieldValue, values, setFieldTouched } = useFormikContext();

  const setChoice = () => {
    setFieldTouched(`employeeSurvey[employeeQuestionChoicesAttributes][${index}][questionId]`);
    setFieldValue(`employeeSurvey[employeeQuestionChoicesAttributes][${index}][questionChoiceId]`, choice.id);
  };

  const selected = () => {
    const value = getIn(values, `employeeSurvey[employeeQuestionChoicesAttributes][${index}][questionChoiceId]`);
    return value && value === choice.id ? 'selected' : '';
  };

  const triggerClick = () => {
    if (sendSurvey) {
      choiceRef.current.click();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={triggerClick} className="mr-3 my-3 py-0">
      <BSForm.Check type="radio" className={`${checkClass} ${selected()}`} name={field.name}>
        <BSForm.Check.Input
          ref={choiceRef}
          name={field.name}
          onClick={setChoice}
          type="radio"
          disabled={!sendSurvey || outOfDate}
        />
        <BSForm.Check.Label className="font-weight-bold dark">{choice.label}</BSForm.Check.Label>
      </BSForm.Check>
    </div>
  );
};

const setInitialValues = props => {
  const { employeeSurvey } = props;
  return { employeeSurvey: { ...employeeSurvey, status: 'answered' } };
};

const validationSchema = Yup.object().shape({
  employeeSurvey: Yup.object().shape({
    employeeQuestionChoicesAttributes: Yup.array().of(
      Yup.object().shape({
        questionChoiceId: Yup.string().required('Debes seleccionar al menos una opción'),
        questionId: Yup.string().nullable()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, sendSurvey } = props;
  if (sendSurvey) {
    formRequest(values, setSubmitting);
  }
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(SurveyPreview);
