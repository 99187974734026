import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { Icon, LinkBtn } from '../../components';
import { showSettlementGroupRequest } from '../../requests/settlementGroups';
import SettlementDataTable from '../Settlement/SettlementDataTable';
import basicSettlementGroup from './basicSettlementGroup';

const SettlementGroupShow = ({ match }) => {
  const { id: settlementGroupId } = match.params;
  const [settlementGroup, setSettlementGroup] = useState(basicSettlementGroup);
  const dispatch = useDispatch();

  const successCallback = useCallback(response => setSettlementGroup(camelCaseRecursive(response.data)), []);

  const fetchSettlementGroup = () => {
    showSettlementGroupRequest(settlementGroupId, { dispatch, successCallback });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchSettlementGroup, []);

  return (
    <>
      <Row className="my-4">
        <Col xs={2} md={1} className="mt-3">
          <LinkBtn block variant="circle-dark" to="/settlements">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col xs={10} md={9} className="mt-3">
          <h2 className="text-uppercase mt-1">{settlementGroup.name}</h2>
        </Col>
      </Row>
      <SettlementDataTable fromGroup settlementGroupId={settlementGroupId} />
    </>
  );
};

export default SettlementGroupShow;
