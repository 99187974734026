import ApiService from '../services/apiService';

export const indexReportRequest = ({ dispatch, params = {}, successCallback }) =>
  ApiService.request('get', '/reports/', {
    dispatch,
    params,
    successCallback
  });

export const showReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/reports/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const handleCustomReportRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', '/reports/handle_custom.xlsx', {
    dispatch,
    params,
    formData: true,
    responseType: 'text',
    successCallback,
    callback
  });

export const downloadReportRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', '/reports/download.xlsx', {
    dispatch,
    params,
    formData: true,
    responseType: 'text',
    successCallback,
    callback
  });

export const deleteReportRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/reports/${id}`, {
    dispatch,
    successCallback,
    callback
  });
