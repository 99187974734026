import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { FormikInput, FormikRangePicker, FormikSelect } from '../../components';
import { civilStateTypes, contractTypes, dateTypes } from './EmployeeForm/FormOptions';

import { searchEmployeeRequest } from '../../requests/employees';

const EmployeeFilter = props => {
  const { errors, touched, setFieldValue, setFieldTouched, values } = props;
  const dispatch = useDispatch();
  const [selector, setSelector] = useState([]);
  const [searchDates, setSearchDates] = useState();

  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          advance: true
        },
        successCallback: response => setSelector(camelCaseRecursive(response.data.data))
      });
    request();
  };

  const fetchEmployeesFilter = searchValues => {
    const params = {
      filter_job_titles: [],
      filter_branch_offices: [],
      filter_job_managements: [],
      filter_syndicates: [],
      filter_cost_centers: [],
      filter_pension_fun: [],
      filter_health_previsions: [],
      filter_countries: [],
      filter_regions: [],
      filter_communes: []
    };
    searchValues.forEach(value => {
      params[value.queryAdvanceValue].push(value.value.split('-')[1]);
    });
    setFieldValue('filter_active_job_titles', params.filter_job_titles);
    setFieldValue('filter_active_branch_offices', params.filter_branch_offices);
    setFieldValue('filter_active_job_managements', params.filter_job_managements);
    setFieldValue('filter_active_syndicates', params.filter_syndicates);
    setFieldValue('filter_active_cost_centers', params.filter_cost_centers);
    setFieldValue('filter_pension_fun', params.filter_pension_fun);
    setFieldValue('filter_health_previsions', params.filter_health_previsions);
    setFieldValue('filter_countries', params.filter_countries);
    setFieldValue('filter_regions', params.filter_regions);
    setFieldValue('filter_communes', params.filter_communes);
  };

  const clearValues = () => {
    setFieldValue('dateStartContractFrom', '');
    setFieldValue('dateStartContractTo', '');
    setFieldValue('dateEndContractFrom', '');
    setFieldValue('dateEndContractTo', '');
    setFieldValue('dniExpirationDateFrom', '');
    setFieldValue('dniExpirationDateTo', '');
  };

  const initialFetch = () => {
    searchEmployeesParams();
  };
  useEffect(initialFetch, []);
  return (
    <Form>
      <Row>
        <Col md={2}>
          <Field name="filterNationalId">
            {({ field }) => (
              <FormikInput
                {...field}
                label="N° de Identificación:"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="filterName">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Nombre:"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="filterCivilState">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Estado Civil"
                placeholder="Seleccionar Estado Civil"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                options={civilStateTypes}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isClearable
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="filterContractType">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Tipo de Contracto"
                placeholder="Tipo de Contrato"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                options={contractTypes}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isClearable
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field name="filterAdvance">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Filtro Avanzado"
                isMulti
                options={selector}
                onChange={data => {
                  fetchEmployeesFilter(data || []);
                  setFieldValue(field.name, data ? data.value : '');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="filterDates">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Fechas"
                placeholder="Seleccione Fecha"
                options={dateTypes}
                onChange={data => {
                  setSearchDates(data?.value);
                  clearValues();
                  setFieldValue(field.name, data ? data.value : '');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isClearable
              />
            )}
          </Field>
        </Col>
        {searchDates === 'contract_start_date' && (
          <Col xs={8} md={8}>
            <Field name="rangeDate">
              {({ field }) => (
                <FormikRangePicker
                  {...field}
                  startDateName="dateStartContractFrom"
                  endDateName="dateStartContractTo"
                  startDate={values.dateStartContractFrom}
                  endDate={values.dateStartContractTo}
                  showClearDates
                />
              )}
            </Field>
          </Col>
        )}
        {searchDates === 'contract_end_date' && (
          <Col xs={8} md={8}>
            <Field name="rangeDate">
              {({ field }) => (
                <FormikRangePicker
                  {...field}
                  startDateName="dateEndContractFrom"
                  endDateName="dateEndContractTo"
                  startDate={values.dateEndContractFrom}
                  endDate={values.dateEndContractTo}
                  showClearDates
                />
              )}
            </Field>
          </Col>
        )}
        {searchDates === 'dni_expiration_date' && (
          <Col xs={8} md={8}>
            <Field name="rangeDate">
              {({ field }) => (
                <FormikRangePicker
                  {...field}
                  startDateName="dniExpirationDateFrom"
                  endDateName="dniExpirationDateTo"
                  startDate={values.dniExpirationDateFrom}
                  endDate={values.dniExpirationDateTo}
                  showClearDates
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      <Row className="d-flex justify-content-end mt-4">
        <Col md={2}>
          <Button className="btn-block" type="submit">
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => ({
    filterNationalId: '',
    filterName: '',
    filterCivilState: '',
    filterGender: '',
    filterContractType: '',
    jobManagement: '',
    filterAdvance: '',
    filterJobTitle: '',
    filterDateFrom: '',
    filterDateTo: '',
    filterDates: '',
    dateStartContractFrom: '',
    dateStartContractTo: '',
    dateEndContractFrom: '',
    dateEndContractTo: '',
    dniExpirationDateFrom: '',
    dniExpirationDateTo: ''
  }),
  handleSubmit,
  enableReinitialize: true
})(EmployeeFilter);
