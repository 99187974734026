import React from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import Icon from '../../Icons/Icon';
import './style.scss';

const EmployeeCard = ({ employee, selected, onClick }) => (
  <Card onClick={onClick} border={selected ? 'primary' : ''} className={`employee-card ${selected ? '' : 'disabled'}`}>
    <Card.Body className="pb-3 pt-1">
      <Row className="align-items-center">
        <Col md={12}>
          <Icon
            className={`text-${selected ? 'primary' : 'dark'}`}
            width={15}
            icon={selected ? 'checkmark-circle' : 'close'}
          />
        </Col>
        <Col md={3}>
          <span className="avatar employee-card">
            <span className={employee.file_info.file_url ? 'content' : 'empty content'}>
              {employee.file_info.file_url ? (
                <Image src={employee.file_info.file_url} fluid />
              ) : (
                <Icon icon="camera" width="60px" height="60px" />
              )}
            </span>
          </span>
        </Col>
        <Col className="ml-2">
          <Card.Subtitle className="text-gray">{employee.full_name}</Card.Subtitle>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default EmployeeCard;
