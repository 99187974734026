import React from 'react';
import PropTypes from 'prop-types';
import BasicDropdown from '../Utils/Dropdown/BasicDropdown';
import Icon from '../Icons';

const EnrollmentActions = ({ handleClick, disabled, filters }) => (
  <>
    <BasicDropdown
      variant="outline-primary"
      responsive
      noArrow
      block
      right
      titleDrop={
        <span className="align-middle">
          Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
        </span>
      }
      items={[
        {
          key: 'mass-accept',
          title: 'Aprobar seleccionados',
          props: {
            onClick: () => handleClick('mass-accept'),
            disabled
          }
        },
        {
          key: 'filter-pending',
          title: 'Filtrar pendientes',
          props: {
            onClick: () => handleClick('filter-pending'),
            active: filters.status === 'pending'
          }
        }
      ]}
    />
  </>
);

EnrollmentActions.propTypes = {
  handleClick: PropTypes.func.isRequired
};

EnrollmentActions.defaultProps = {};

export default EnrollmentActions;
