import React, { useState, useMemo } from 'react';
import memoize from 'memoize-one';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { ComponentDataTable, ButtonTooltip, Icon, SimpleCenteredModal, LinkBtn } from '../../components';
import {
  remunerationsReportRequest,
  summarizedRemunerationsReportRequest,
  remunerationsSummaryReportRequest,
  singleRemunerationsReportRequest,
  senceReportRequest
} from '../../requests/payrollProcesses';
import { downloadFile } from '../../services/utils';
import ReportSelectEmployee from './ReportSelectEmployee';

const columns = memoize(clickHandler => [
  {
    name: 'REPORTE',
    selector: 'name',
    sortable: true,
    grow: '5'
  },
  {
    name: 'GENERAR',
    cell: item => (
      <ButtonTooltip
        onClick={() => clickHandler(item)}
        variant="circle-info"
        className="btn-circle"
        size="sm"
        text="Descargar"
      >
        <Icon icon="cloud-download" />
      </ButtonTooltip>
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true
  }
]);

const ReportLegal = ({ match, location }) => {
  const { id } = match.params;
  const [onRequest, setOnRequest] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();
  const reports = useMemo(
    () => [
      { name: 'Declaración anual SII', code: 'anual_ine' },
      { name: 'Declaración jurada masiva', code: 'massive_dj' },
      { name: 'Informe INE (mensual, trimestral, anual)', code: 'ine' },
      { name: 'Informe impuestos pagados', code: 'tax_report' },
      { name: 'Libro de remuneraciones completo', code: 'remunerations' },
      { name: 'Libro de remuneraciones individual', code: 'single_remunerations' },
      { name: 'Libro de remuneraciones resumido', code: 'summarized_remunerations' },
      { name: 'Resumen libro de remuneraciones', code: 'remunerations_summary' },
      { name: 'Reporte Sence', code: 'sence' }
      // { name: 'Informe de pagos a terceros, por ejemplo, PREVIRED, caja de compensación.' },
    ],
    []
  );

  const successCallback = response => {
    downloadFile(response);
    setModalShow(false);
  };

  const callback = () => {
    setOnRequest(false);
  };

  const singleRemunerations = params => {
    singleRemunerationsReportRequest(id, { dispatch, params, successCallback, callback });
  };

  const handleActions = async item => {
    setOnRequest(true);
    switch (item.code) {
      case 'remunerations':
        remunerationsReportRequest(id, { dispatch, successCallback, callback });
        break;
      case 'summarized_remunerations':
        summarizedRemunerationsReportRequest(id, { dispatch, successCallback, callback });
        break;
      case 'remunerations_summary':
        remunerationsSummaryReportRequest(id, { dispatch, successCallback, callback });
        break;
      case 'single_remunerations':
        setOnRequest(false);
        setModalShow(true);
        setModalTitle(item.name);
        setModalBody(
          <ReportSelectEmployee
            employeeIds={location.state.employeeIds}
            handleModalClose={() => setModalShow(false)}
            formRequest={singleRemunerations}
          />
        );
        break;
      case 'sence':
        senceReportRequest(id, { dispatch, successCallback, callback });
        break;
      default:
        callback();
    }
  };

  const Modal = useMemo(
    () => (
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    ),
    [modalTitle, modalShow, modalBody]
  );

  return (
    <>
      <Row className="m-top mb-2">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to={`/payroll_processes/${id}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="m-top mb-3">Reportes Legales</h2>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={reports}
        subHeaderComponent={false}
        sortServer={false}
        defaultSortField="name"
        onRowClicked={item => handleActions(item)}
        pointerOnHover
        preName="reports"
      />
      {Modal}
    </>
  );
};

export default ReportLegal;
