import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import FormikInput from '../../components/Utils/Input/FormikInput';
import { delayMethod } from '../../services/utils';

const ContactForm = props => {
  const { onHide, submitVariant, errors, touched } = props;

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Field name="fullName">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombres y Apellidos"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} xs={12}>
            <Field name="phone">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Número de Teléfono"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} xs={12}>
            <Field name="email">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Correo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} xs={12}>
            <Field name="companyName">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Nombre de la Empresa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} xs={12}>
            <Field name="employeeCount">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Cantidad de Trabajadores"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={12}>
            <Field name="message">
              {({ field }) => (
                <FormikInput
                  {...field}
                  as="textarea"
                  rows={5}
                  label="Mensaje"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" className="mr-3" onClick={() => delayMethod(onHide)}>
          Cancelar
        </Button>
        <Button type="submit" variant={submitVariant}>
          Enviar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    fullName: '',
    phone: '',
    email: '',
    employeeCount: '',
    companyName: '',
    message: ''
  };
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Debes ingresar nombre y apellidos'),
  phone: Yup.string().required('Debes ingresar un número de teléfono'),
  email: Yup.string()
    .email('Debes ingresar un correo válido')
    .required('Debes ingresar un correo'),
  employeeCount: Yup.number()
    .min(0, 'Debes ingresar un valor mayor o igual a 0')
    .required('Debes ingresar el número de trabajadores'),
  companyName: Yup.string().nullable(),
  message: Yup.string().nullable()
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit
})(ContactForm);
