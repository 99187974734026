import React from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useLocation } from 'react-router-dom';

import { LinkBtn } from '../../components';
import { useSetTab } from '../../services/hooks';
import { AbilityContext } from '../../config/abilityContext';
import BalanceDataTable from './BalanceDataTable';

const BalanceIndex = () => {
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const canAsset = ability.can(`read_asset`, 'Balance');
  const canDiscount = ability.can(`read_discount`, 'Balance');
  const [key, setKey] = useSetTab(canAsset ? 'asset' : 'discount', location);

  const title = `${canAsset ? 'Haberes' : ''}${canAsset && canDiscount ? ' y ' : ''}${canDiscount ? 'Descuentos' : ''}`;
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="m-top mt-3 mb-1">{title}</h2>
        </Col>
        {ability.can(`create_${key}`, 'Balance') && (
          <Col md={3}>
            <LinkBtn variant="primary" className="mt-3" block to={`/balances/${key}s/new`}>
              Nuevo
            </LinkBtn>
          </Col>
        )}
      </Row>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can(`read_asset`, 'Balance') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="asset">
                Haberes
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can(`read_discount`, 'Balance') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="discount">
                Descuentos
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          {ability.can(`read_asset`, 'Balance') && (
            <Tab.Pane eventKey="asset">
              <BalanceDataTable asset />
            </Tab.Pane>
          )}
          {ability.can(`read_discount`, 'Balance') && (
            <Tab.Pane eventKey="discount">
              <BalanceDataTable />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default BalanceIndex;
