import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon, ApprovementStatus } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'DOCUMENTO',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        variant="circle-info"
        onClick={() => clickHandler(item)}
        className="btn-circle"
        size="sm"
        text="Ver"
      >
        <Icon icon="eye" />
      </ButtonTooltip>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);
