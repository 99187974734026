import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';
import { deleteDropZoneFileRequest } from '../../../requests/dropzone';
import './style.scss';

import Dropzone from '../Upload/Dropzone';

const FormikDropzone = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  error,
  helpText,
  label,
  tooltipIcon,
  tooltipSize,
  tooltipText,
  ...props
}) => {
  const dispatch = useDispatch();
  return (
    <Form.Group className={`${error ? 'is-invalid' : ''}`}>
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1 mr-1">*</abbr>}
        {tooltipText && (
          <ButtonToolbar className="ml-2">
            <OverlayTrigger
              key={direction}
              placement={direction}
              delay={{ show: delayShow, hide: delayHide }}
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <Icon icon={tooltipIcon} width={tooltipSize} />
            </OverlayTrigger>
          </ButtonToolbar>
        )}
      </Form.Label>
      <Dropzone
        onDelete={file => {
          deleteDropZoneFileRequest(file.id, {
            dispatch,
            params: { dropZoneFile: { documentKey: file.code } }
          });
        }}
        {...props}
      />
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikDropzone.propTypes = {
  abbr: PropTypes.bool,
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipSize: PropTypes.string,
  tooltipText: PropTypes.string
};

FormikDropzone.defaultProps = {
  abbr: false,
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  helpText: '',
  label: '',
  tooltipIcon: 'help-circle',
  tooltipSize: '15',
  tooltipText: ''
};

export default FormikDropzone;
