import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';

import { debounceIndexCompanyLogRequest } from '../../requests/companyLogs';
import { ComponentDataTable, DatatableEmployeeName, ButtonTooltip, Icon, DefaultModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { downloadFile } from '../../services/utils';

const columns = memoize(() => [
  {
    name: 'FECHA',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RESPONSABLE',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'MENSAJE',
    selector: 'message',
    sortable: true,
    grow: '3'
  }
]);

const CompanyLogDataTable = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [companyLogs, setCompanyLogs] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [query, setQuery] = useState({});
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleRequest = async (params, format, responseType = 'json') => {
    setOnRequest(true);
    setQuery(params);
    debounceIndexCompanyLogRequest({
      dispatch,
      params,
      format,
      responseType,
      successCallback: response => {
        if (format === '.xlsx') {
          downloadFile(response);
        } else {
          const { data, metadata } = response.data;
          setCompanyLogs(data);
          setAmount(metadata.amount);
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employee: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        handleSortCase={sortColumnCase}
        columns={columns()}
        data={companyLogs}
        totalRows={amount}
        withMassActions={ability.can('index', 'CompanyLog')}
        massActions={
          <ButtonTooltip
            onClick={() => setModalShow(true)}
            variant="circle-primary"
            toolbarVariant="ml-auto mr-3"
            className="btn-circle"
            size="sm"
            text="Exportar"
          >
            <Icon icon="download-2" />
          </ButtonTooltip>
        }
        resourceRequest={handleRequest}
        withStartDate
        withEndDate
        rangePicker
      />
      <DefaultModal
        title="Exportar historial"
        body="¿Estás seguro que quieres exportar el historial de cambios?"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => {
          handleRequest(query, '.xlsx', 'text');
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default CompanyLogDataTable;
