import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { ComponentDataTable, DefaultModal } from '../../components';
import { debounceIndexSurveyRequest, deleteSurveyRequest, duplicateSurveyRequest } from '../../requests/surveys';
import { delayMethod } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import { AbilityContext } from '../../config/abilityContext';

const SurveyDataTable = ({ columns }) => {
  const ability = useAbility(AbilityContext);
  const [surveys, setSurveys] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalAction, setModalAction] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleIndexRequest = async params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexSurveyRequest({
        dispatch,
        params: { ...params },
        successCallback: res => {
          const { amount: responseAmount } = res.data.metadata;
          setAmount(responseAmount);
          setSurveys(res.data.data);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleModalConfirm = item => {
    deleteSurveyRequest(item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta eliminada con éxito.' }));
        setMoreData(!moreData);
      }
    });
  };

  const dupSurvey = item => {
    duplicateSurveyRequest(item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta duplicada con éxito.' }));
        setMoreData(!moreData);
      }
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/surveys/${item.id}`);
        break;
      case 'edit':
        history.push(`/surveys/${item.id}/edit`);
        break;
      case 'destroy':
        setModalTitle('Eliminar Encuesta');
        setModalBody(`¿Estás seguro que deseas eliminar la encuesta ${item.name}?`);
        setModalShow(true);
        setModalItem(item);
        setModalAction(() => handleModalConfirm);
        break;
      case 'copy':
        setModalShow(true);
        setModalTitle('Duplicar Encuesta');
        setModalItem(item);
        setModalAction(() => dupSurvey);
        setModalBody(`¿Estás seguro que deseas duplicar la encuesta '${item.name}'?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability.can('create', 'Survey'))}
        data={surveys}
        totalRows={amount}
        resourceRequest={handleIndexRequest}
        withStartDate
        withEndDate
        rangePicker
        onRowClicked={row => handleActions(row, 'show')}
        pointerOnHover
        moreData={moreData}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default SurveyDataTable;
