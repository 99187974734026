import React, { useState } from 'react';
import memoize from 'memoize-one';
import snakeCaseKeys from 'snakecase-keys';

import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import {
  indexJobManagementsRequest,
  importJobManagementRequest,
  preImportJobManagementRequest,
  exportJobManagementsRequest,
  importTemplateJobManagementRequest,
  updateJobManagementRequest,
  deleteJobManagementRequest,
  activeJobManagementRequest,
  deactivateJobManagementRequest
} from '../../requests/jobManagements';
import { downloadFile, camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import {
  ActiveDot,
  ComponentDataTable,
  DefaultModal,
  SimpleCenteredModal,
  ImportActions,
  ImportModal,
  SimpleShow,
  SettingsMassiveActions,
  BasicActionBtns
} from '../../components';

import JobManagementForm from './JobManagementForm';
import { AbilityContext } from '../../config/abilityContext';

const columns = memoize(clickHandler => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DEPENDE DE',
    selector: 'parent_name',
    cell: item => item.depend_from?.name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns item={item} withArchive={false} resource="JobManagement" clickHandler={clickHandler} />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const JobManagementDataTable = ({ moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [jobManagements, setJobManagements] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [query, setQuery] = useState({});

  const dispatch = useDispatch();

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setJobManagements(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    handleModalClose();
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Área eliminada correctamente' }));
    handleModalClose();
    setOnRequest(false);
    setMoreData(!moreData);
  };

  const removeJobManagement = item => {
    setOnRequest(true);
    deleteJobManagementRequest({
      params: { ids: item.id },
      dispatch,
      successCallback: handleSuccessRemove('Área Eliminada correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setQuery(params);
    indexJobManagementsRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleUpdate = jobManagementValue => {
    const {
      jobManagement: { id: jobManagementId }
    } = jobManagementValue;
    updateJobManagementRequest(jobManagementId, {
      dispatch,
      params: snakeCaseKeys(jobManagementValue),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Unidad Organizacional actualizada con éxito' }));
        setSimpleModalShow(false);
        setMoreData(!moreData);
      }
    });
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
    setMoreData(!moreData);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setSimpleModalShow(true);
        setSimpleModalTitle('Unidad organizacional');
        setSimpleModalBody(
          <SimpleShow
            item={camelCaseEmptyStringRecursive(item)}
            handleClose={() => setSimpleModalShow(false)}
            withActive
          />
        );
        break;
      case 'edit':
        if (ability.can('update', 'JobManagement')) {
          setSimpleModalShow(true);
          setSimpleModalTitle('Editar unidad organizacional');
          setSimpleModalBody(
            <JobManagementForm
              jobManagementId={item.id}
              jobManagement={camelCaseEmptyStringRecursive(item)}
              action="edit"
              formRequest={handleUpdate}
            />
          );
        }
        break;

      case 'destroy':
        setModalTitle('Eliminar área');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar esta área?');
        setModalItem(item);
        setModalAction(() => removeJobManagement);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parent_name':
        return { sort_by_parent_name: name };
      default:
        return { sort_column: name };
    }
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    exportJobManagementsRequest({
      dispatch,
      params: query,
      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
        setModalShow(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const massiveDeleteJobManagement = () => {
    const jobManagementSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deleteJobManagementRequest({
      params: { ids: jobManagementSelected },
      dispatch,
      successCallback: handleSuccessAction('Areas Eliminadas correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveActiveBookkeeper = () => {
    const bookkeepersSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    activeJobManagementRequest({
      params: { ids: bookkeepersSelected },
      dispatch,
      successCallback: handleSuccessAction('Areas Activadas correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveDeactivateBookkeeper = () => {
    const bookkeepersSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deactivateJobManagementRequest({
      params: { ids: bookkeepersSelected },
      dispatch,
      successCallback: handleSuccessAction('Areas Desactivadas correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setSimpleModalTitle('Importar áreas');
        setSimpleModalBody(
          <ImportModal
            onDropUploaded={preImportJobManagementRequest}
            handleTemplate={importTemplateJobManagementRequest}
            hideModal={() => setSimpleModalShow(false)}
            onHide={importJobManagementRequest}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setSimpleModalShow(true);
        break;
      case 'export':
        setModalTitle('Exportar áreas');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar áreas?');
        setModalAction(() => handleExportRequest);
        break;
      case 'massive_destroy':
        setModalTitle('Eliminar Areas');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar las areas seleccionadas?');
        setModalAction(() => massiveDeleteJobManagement);
        break;
      case 'massive_active':
        setModalTitle('Activar áreas');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas activar las áreas seleccionadas?');
        setModalAction(() => massiveActiveBookkeeper);
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar áreas');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar las áreas seleccionadas?');
        setModalAction(() => massiveDeactivateBookkeeper);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={jobManagements}
        totalRows={amount}
        moreData={moreData}
        selectableRows
        withMassActions
        massActions={
          <>
            <ImportActions model="JobManagement" handleClick={handleMassAction} />
            <SettingsMassiveActions model="JobManagement" handleClick={handleMassAction} disabled={!selectedCount} />
          </>
        }
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover={ability.can('update', 'JobManagement')}
        preName="job_managements"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default JobManagementDataTable;
