import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Icon from '../../Icons/Icon';

const DashboardCard = ({
  colorBorder,
  colorCard,
  colorCounter,
  colorText,
  counter,
  icon,
  onClick,
  size,
  subtitle,
  title
}) => (
  <Card
    border={colorBorder}
    bg={colorCard}
    onClick={onClick}
    style={{ height: size, cursor: onClick && 'pointer' }}
    text={colorText}
  >
    <Card.Body className="flex-row d-flex">
      <Icon width={50} icon={icon} className="my-auto" />
      <div className="flex-col my-auto ml-3 mr-3">
        <Card.Title className={`text-${colorText}`}>{title}</Card.Title>
        {subtitle && <Card.Subtitle className={`text-${colorText}`}>{subtitle}</Card.Subtitle>}
      </div>
      <Card.Title style={{ fontSize: '1.85rem' }} className={` my-auto ml-auto text-${colorCounter || colorText}`}>
        {counter}
      </Card.Title>
    </Card.Body>
  </Card>
);

DashboardCard.propTypes = {
  colorBorder: PropTypes.string,
  colorCard: PropTypes.string,
  colorCounter: PropTypes.string,
  colorText: PropTypes.string,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  size: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string.isRequired
};

DashboardCard.defaultProps = {
  colorBorder: null,
  colorCard: 'light',
  colorCounter: '',
  colorText: 'black',
  counter: 0,
  icon: 'checkbox',
  size: '7rem',
  subtitle: null
};

export default DashboardCard;
