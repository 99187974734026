import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import ComponentDataTable from '../Utils/DataTable';
import FormikSelect from '../Utils/Select/FormikSelect';
import Icon from '../Icons/Icon';
import ButtonTooltip from '../Utils/Tooltips/ButtonTooltip';
import SubHeader from '../Utils/DataTable/SubHeader';
import SimpleCenteredModal from '../Utils/Modal/SimpleCenteredModal';
import EmployeeSearchModal from './EmployeeSearchModal';
import { debounceIndexEmployeesRequest } from '../../requests/employees';
import DatatableEmployeeName from '../DatatableColumns/DatatableEmployeeName';
import { filterArray, sortByAttribute } from '../../services/utils';

const defaultColumns = memoize(handleClick => [
  {
    name: 'NOMBRE',
    selector: 'fullName',
    cell: item => <DatatableEmployeeName item={item} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    grow: '3',
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => handleClick(item, 'destroy')}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar"
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    ),
    sortable: false,
    grow: '1',
    right: true
  }
]);

const EmployeeSearchDataTable = ({
  baseModel,
  columns,
  employees,
  handleActions,
  handleMassAction,
  handleSearch,
  handleSelectedRows,
  handleSelector,
  handleSuccessEmployees,
  options,
  selectedCount,
  advanceSearch,
  customParams
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [textInput, setTextInput] = useState('');
  const dispatch = useDispatch();
  const { errors, touched, setFieldTouched, setFieldValue } = useFormikContext();

  const fetchEmployees = () => {
    setOnRequest(true);
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        paginate: false,
        is_dt: false,
        active: true,
        ...customParams
      },
      successCallback: handleSuccessEmployees,
      callback: () => setOnRequest(false)
    });
  };

  const filteredArray = () => {
    setFilterEmployees(filterArray(employees, textInput, ['fullName', 'nationalIdentification']));
  };

  useEffect(filteredArray, [textInput, employees]);
  useEffect(fetchEmployees, [baseModel.id, customParams]);
  useEffect(() => setFilterEmployees(baseModel.employees), [baseModel.employees]);

  return (
    <>
      <Row className="align-items-center">
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Asignar a"
                placeholder="Seleccionar Trabajador"
                options={sortByAttribute(options, 'fullName')}
                defaultValue="all_employees"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} xl={2}>
          <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
            Agregar
          </Button>
        </Col>
        {advanceSearch && (
          <Col className="ml-2">
            <ButtonTooltip
              variant="circle-primary"
              className="advance-search"
              text="Búsqueda avanzada de empleados mediante filtros de cargos, Lugar de Prestación de Servicios, otros"
              onClick={() => setModalShow(true)}
            >
              <Icon className="w-100 h-100" icon="people-circle" />
            </ButtonTooltip>
          </Col>
        )}
      </Row>
      <ComponentDataTable
        columns={columns(handleActions)}
        data={filterEmployees}
        totalRows={employees.length}
        defaultSortField="fullName"
        pointerOnHover
        onRequest={onRequest}
        sortServer={false}
        subHeaderComponent={
          <SubHeader
            setTextInput={setTextInput}
            textInput={textInput}
            selectedCount={selectedCount}
            handleMassAction={handleMassAction}
          />
        }
        onSelectedRowsChange={handleSelectedRows}
        selectableRows
        paginationServer={false}
        pagination
      />
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setModalShow(false)}
            formRequest={selectedEmployees => {
              handleSearch(selectedEmployees);
              setModalShow(false);
            }}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

EmployeeSearchDataTable.propTypes = {
  columns: PropTypes.func,
  handleSelector: PropTypes.func.isRequired,
  handleSuccessEmployees: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  handleActions: PropTypes.func,
  employees: PropTypes.arrayOf(PropTypes.shape()),
  handleSelectedRows: PropTypes.func,
  handleSearch: PropTypes.func,
  baseModel: PropTypes.shape(),
  advanceSearch: PropTypes.bool,
  customParams: PropTypes.shape()
};

EmployeeSearchDataTable.defaultProps = {
  columns: defaultColumns,
  options: [],
  handleActions: () => {},
  employees: [],
  handleSelectedRows: () => {},
  handleSearch: () => {},
  baseModel: {},
  advanceSearch: true,
  customParams: {}
};

export default EmployeeSearchDataTable;
