import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';

import {
  FormikInput,
  InputSelect,
  FormikDatePicker,
  FormikSelect,
  Dropzone,
  RegionCommune,
  PhoneWithCode,
  UploadFile,
  Genders
} from '../../components';
import { debounceIndexCareersRequest } from '../../requests/careers';
import { debounceIndexCountriesRequest } from '../../requests/countries';
import { createDropZoneFileRequest } from '../../requests/dropzone';
import { debounceIndexLanguagesRequest } from '../../requests/languages';
import { rutFormat, validRutInput } from '../../services/utils';
import { civilStates, educationalLevels, identificationTypes } from '../Public/FormOptions';

const CandidateForm = ({ values, errors, touched, setFieldValue, setFieldTouched, candidate: basicCandidate }) => {
  const { countryName, documentKeys, identificationType, removeFileIds = [], careers } = values.candidate;
  const isChile = countryName === 'Chile';
  const [candidate, setCandidate] = useState({});
  const otherCareer = careers.some(career => career.label === 'Otro');
  const dispatch = useDispatch();

  const handleCandidateData = () => {
    setCandidate({ ...basicCandidate, persistedFiles: basicCandidate.filesInfo });
  };

  useEffect(handleCandidateData, [basicCandidate]);

  const handleRegionCommuneDisabled = () => {
    if (isChile) return;
    setFieldValue('candidate[regionId]', '');
    setFieldValue('candidate[communeId]', '');
  };

  useEffect(handleRegionCommuneDisabled, [isChile]);

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: element.label,
      value: element.value
    }));
  };

  const fetchCountries = (inputValue, callback) => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'position',
        display_length: 250
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchCareers = (inputValue, callback) => {
    debounceIndexCareersRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 100
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchLanguages = (inputValue, callback) => {
    debounceIndexLanguagesRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const onDropUploadedFile = documents => {
    documents.map((doc, index) => setFieldValue(`candidate[documentKeys][${index}]`, doc.code));
  };

  const handleDestroyFile = ({ code }) => {
    const newDocumentKeys = documentKeys.filter(dk => dk !== code);
    setFieldValue('candidate[documentKeys]', newDocumentKeys);
  };

  const handleDeletePersistedFiles = ({ id: fileId }) => {
    const newPersistedFiles = candidate.persistedFiles.filter(file => file.id !== fileId);
    setFieldValue('candidate[removeFileIds]', [...removeFileIds, fileId]);
    setCandidate({ ...candidate, persistedFiles: newPersistedFiles });
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => response
    });
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const handleNationalIdentificationFormat = (e, isRut) => {
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  return (
    <>
      <Form className="container">
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4}>
                  <Field name="candidate[name]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Nombre"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="candidate[firstLastName]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Primer Apellido"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="candidate[secondLastName]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Segundo Apellido"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Field name="candidate[identificationType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        defaultValue={candidate.identificationType}
                        label="Tipo de Identificación"
                        options={identificationTypes}
                        placeholder="Seleccionar Tipo de Identificación"
                        tooltipText=""
                        onChange={data => {
                          setFieldValue('candidate[nationalIdentification]', '');
                          setFieldValue(field.name, data ? data.value : 'RUT');
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="candidate[nationalIdentification]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label={identificationType === 'rut' ? 'RUT' : 'Nº de Identificación'}
                        onChange={e => handleNationalIdentificationFormat(e, identificationType === 'rut')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <PhoneWithCode
                    abbr
                    label="Teléfono"
                    countryCodeField="candidate[phoneCountryCode]"
                    phoneField="candidate[phone]"
                    workPhoneCountryCode={candidate.phoneCountryCode}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col md={4}>
                  <Field name="candidate[email]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Email"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="candidate[birthdate]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        abbr
                        isOutsideRange={day => moment().diff(day) < 0}
                        label="Fecha de Nacimiento"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4}>
                  <Field name="candidate[countryId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        abbr
                        label="País"
                        placeholder="Seleccionar País"
                        values={values.candidate}
                        model={[candidate, 'country']}
                        request={fetchCountries}
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                          setFieldValue('candidate[countryName]', data ? data.label : '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={8}>
                  <Row>
                    <RegionCommune
                      regionAbbr={isChile}
                      communeAbbr={isChile}
                      modelKey="candidate"
                      allDisabled={!isChile}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4}>
                  <Field name="candidate[address]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Dirección"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="candidate[addressNumber]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Número"
                        inputType="number"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="candidate[addressApt]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Departamento"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Genders modelKey="candidate" />
                <Col md={4}>
                  <Field name="candidate[civilState]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Estado Civil"
                        placeholder="Seleccionar Estado Civil"
                        options={civilStates}
                        defaultValue={candidate.civilState}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="candidate[educationLevel]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Nivel Educacional"
                        placeholder="Seleccionar Nivel Educacional"
                        options={educationalLevels}
                        defaultValue={candidate.educationLevel}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="candidate[languageIds]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Idiomas"
                    isMulti
                    placeholder="Seleccionar Idiomas"
                    values={values.candidate}
                    model={[candidate, 'language']}
                    request={fetchLanguages}
                    onChange={data => handleNewMulti(data || [], field.name, true)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="candidate[careerIds]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Profesión u Oficio"
                    isMulti
                    placeholder="Seleccionar Profesión u Oficio"
                    values={values.candidate}
                    model={[candidate, 'career']}
                    request={fetchCareers}
                    onChange={data => {
                      handleNewMulti(data || [], field.name, true);
                      setFieldValue('candidate[careers]', data || []);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Field name="candidate[resume]">
                {({ field }) => (
                  <UploadFile
                    {...field}
                    label="Curriculum"
                    name={candidate?.resumeInfo?.filename || 'Adjuntar Curriculum'}
                    onChange={file => setFieldValue(field.name, file)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    formats={['.pdf']}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="candidate[observation]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    as="textarea"
                    label="Observación"
                    minInputHeight="150"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {otherCareer && (
              <Col md={6}>
                <Field name="candidate[otherCareer]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Otra Profesión u Oficio"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={3}>
              <p>Adjuntar Documentos</p>
            </Col>
            <Col md={9}>
              <Dropzone
                fileAccept=".pdf .xls .doc .docx .rtf .xlsx .ppt .pptx .jpg .jpeg .gif .mp3 .mp4 .wmv .avi .png"
                maxSize={50000000}
                multiple
                customClass="long-dropzone"
                onDelete={handleDestroyFile}
                onDrop={handleOnDrop}
                onDropUploaded={onDropUploadedFile}
                persistedFiles={candidate.persistedFiles}
                onDeletePersistedFiles={handleDeletePersistedFiles}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = ({ candidate }) => {
  return { candidate: { ...candidate, candidateType: 'external' } };
};

const validationSchema = Yup.object().shape({
  candidate: Yup.object().shape({
    address: Yup.string()
      .required('Debes ingresar una dirección')
      .max(60, 'Máximo 60 caracteres permitidos'),
    addressNumber: Yup.number()
      .required('Debes ingresar un número')
      .typeError('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0'),
    birthdate: Yup.string()
      .required('Debes seleccionar una fecha de nacimiento')
      .typeError('Debes seleccionar una fecha de nacimiento'),
    civilState: Yup.string().required('Debes seleccionar un estado civil'),
    communeId: Yup.string().when('countryName', {
      is: 'Chile',
      then: Yup.string().required('Debes seleccionar una comuna'),
      otherwise: Yup.string().nullable()
    }),
    countryId: Yup.string().required('Debes seleccionar un país'),
    educationLevel: Yup.string().required('Debes seleccionar un nivel educacional'),
    email: Yup.string()
      .required('Debes ingresar un email')
      .email('Debes ingresar un email válido')
      .max(50, 'Máximo 50 caracteres permitidos'),
    firstLastName: Yup.string()
      .required('Debes ingresar un apellido')
      .max(50, 'Máximo 50 caracteres permitidos'),
    identificationType: Yup.string().required('Debes seleccionar un tipo de documento'),
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(50, 'Máximo 50 caracteres permitidos'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un número de identificación')
      .when('identificationType', (identificationType, schema) =>
        identificationType === 'rut'
          ? schema.rut('Debes ingresar un RUT válido')
          : schema.alphanumeric('Deben ser caracteres alfanuméricos')
      ),
    resume: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'El archivo cargado excede el tamaño maximo permitido (5mb).',
        value => !value?.size || (value && value?.size <= 5242880)
      )
      .test(
        'FILE_FORMAT',
        'El archivo cargado tiene un formato no compatible.',
        value => !value?.type || (value && ['application/pdf'].includes(value?.type))
      ),
    otherCareer: Yup.string().when('careers', {
      is: val => val.some(career => career.label === 'Otro'),
      then: Yup.string()
        .required()
        .required('Debes ingresar una profesión u oficio'),
      otherwise: Yup.string().nullable()
    }),
    phone: Yup.string()
      .required('Debes ingresar un número telefónico')
      .when('phoneCountryCode', {
        is: val => val === '+56',
        then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
        otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
      }),
    regionId: Yup.string().when('countryName', {
      is: 'Chile',
      then: Yup.string().required('Debes seleccionar una región'),
      otherwise: Yup.string().nullable()
    }),
    secondLastName: Yup.string().max(50, 'Máximo 50 caracteres permitidos')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CandidateForm);
