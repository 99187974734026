import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../components';

import RoleDataTable from './RoleDataTable';

const RoleIndex = () => (
  <>
    <Row className="mt-4 mb-1">
      <Col>
        <h2 className="mb-3 mt-3">Perfiles</h2>
      </Col>
      <Col md={3} xl={2}>
        <LinkBtn variant="primary" className="mt-3" block to="/roles/new">
          Nuevo Perfil
        </LinkBtn>
      </Col>
    </Row>
    <RoleDataTable />
  </>
);

export default RoleIndex;
