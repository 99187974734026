export default {
  workflowType: '',
  documentTemplateId: '',
  reviewerTemplatesAttributes: [
    {
      approval: false,
      signature: false,
      reviewerTemplateIds: []
    }
  ]
};
