import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexBenefitsRequest } from '../../../requests/benefits';
import BenefitCard from '../../../screens/Shared/Benefit/BenefitCard';
import { LinkBtn } from '../../Utils/Button';

const Benefits = () => {
  const [benefits, setBenefits] = useState([]);
  const dispatch = useDispatch();
  const firstBenefit = benefits[0];
  const secondBenefit = benefits[1];
  const [showSpinner, setShowSpinner] = useState(1);
  const handleSuccessIndex = response => {
    setBenefits([...benefits, ...camelCaseRecursive(response.data.data)]);
    setShowSpinner(0);
  };
  const handleRequest = () => {
    indexBenefitsRequest({
      dispatch,
      params: {
        active: moment().format('DD/MM/YYYY'),
        my_job_management: true,
        display_length: 2,
        sort_column: 'id',
        sort_direction: 'desc'
      },
      successCallback: handleSuccessIndex,
      failureCallback: () => setShowSpinner(0)
    });
  };

  useEffect(handleRequest, []);

  return (
    <Card className="mb-1">
      <Col className="top-header-yellow">
        <Col className="card-header-title">MIS BENEFICIOS Y COMUNICACIONES</Col>
      </Col>
      {showSpinner ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            {firstBenefit ? (
              <Col key={firstBenefit.id} xs={12} md={6} className="mt-2">
                <BenefitCard benefits={firstBenefit} location="dashboard" />
              </Col>
            ) : (
              <Col className="div-content">
                <Row>
                  <Col className="mb-2 mt-2">
                    <div style={{ height: 177, textAlign: 'center' }} className="div-center">
                      No existen registros...
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
            {secondBenefit && (
              <Col key={secondBenefit.id} xs={12} md={6} className="mt-2">
                <BenefitCard benefits={secondBenefit} location="dashboard" />
              </Col>
            )}
          </Row>
          {firstBenefit && (
            <Row>
              <Col sm={7} className="mb-2 mt-1" />
              <Col sm={4} className="mb-2 mt-1">
                <LinkBtn variant="light-gray" block to="/profile/benefits" size="sm">
                  Ver más
                </LinkBtn>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Card>
  );
};

export default Benefits;
